import React, { useContext, Suspense, createContext, useState, lazy } from "react";
import { makeStyles } from "@mui/styles";
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
  NavLink,
  Link,
  Outlet,
  useNavigate,
  useLocation,
  useRoutes,
} from "react-router-dom";

import { withAuthentication } from 'Session';

import {
  withThemeProvider,
  withAnimation,
} from 'HOC';

import {
  Loader,
  //PrivateRoute
} from 'routes';

import {
  Grid,
  Fade,
  Zoom,
  CircularProgress
} from '@mui/material';

import {
  ProvideAuth,
  PhoneProvider,
  useAuth
} from 'Providers';

import {
  AuthLayout,
  MainLayout
} from 'Layouts';

import {
  routes,
  mainRoutes,
  authRoutes
} from 'routes';

//auth
const Login = lazy(() => import('views/Pages/Login.js'));
const ForgotPassword = lazy(() => import('views/Pages/ForgotPassword.js'));
const LogoutPage = lazy(() => import('views/Pages/Logout.js'));

//main
const MissedCalls = lazy(() => import('views/MissedCalls/MissedCalls.js'));
const Contacts = lazy(() => import('views/Contacts/Contacts.js'));
const Contact = lazy(() => import('views/Contacts/Contact.js'));
const Organization = lazy(() => import('views/Organizations/Organization.js'));
const Organizations = lazy(() => import('views/Organizations/Organizations.js'));

function getRoutes(routes = []) {
  return routes.map(({ route }, index) => {
    console.log(route)
    return <Route key={index} {...route} />
  })
}

const RoutesCollection = (props) => {
  const { user } = useAuth(); // update in user will allow rerender in app root

  return useRoutes([
    {
      element: <AuthLayout />,
      children: [
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "/logout",
          element: <LogoutPage />,
        }
      ]
    },
    {
      element: <MainLayout />,
      children: [
        {
          index: true,
          //path: "/",
          element: <MissedCalls />,
        },
        {
          path: "/contacts/:id",
          element: <Contact />,
        },
        {
          path: "/contacts",
          element: <Contacts />,
        },
        {
          path: "/organizations/:id",
          element: <Organization />,
        },
        {
          path: "/organizations",
          element: <Organizations />,
        },
      ]
    },
    { path: "*", element: <Navigate to="/" replace /> },
  ]);
}



const App = (props) => {
  return (
    <ProvideAuth>
      <Router>
        <RoutesCollection/>
      </Router>
    </ProvideAuth>
  );
}


// const WizardLayout = withAnimation(({ children, ...props }) => {
//   console.error('render WizardLayout')
//   return (
//     <div>
//       <div style={{ backgroundColor: 'pink', color: 'white', textAlign: 'center', height: '200px' }}>
//         Wizard layout:
//         {children}
//       </div>
//     </div>
//   );
// })

// const AuthLayout = withAnimation(({ children, ...props }) => {
//   console.error('render AuthLayout')
//   return (
//     <div>
//       <header>
//         <AuthButton />
//       </header>
//       <AuthNavigation />
//       <div style={{ backgroundColor: '#656565', color: 'white', textAlign: 'center', height: '200px' }}>
//         Auth layout:
//         {children}
//       </div>
//     </div>
//   );
// })

// const MainLayout = withAnimation(({ children, ...props }) => {
//   console.error('render MainLayout')
//   return (
//     <div>
//       <header>
//         <AuthButton />
//       </header>
//       <MainNavigation />
//       <div style={{ backgroundColor: 'grey', textAlign: 'left', height: '200px' }}>
//         Main layout:
//
//         {children}
//       </div>
//     </div>
//   );
// })

// const MainNavigation = withAnimation((props) => {
//   console.warn('render MainNavigation')
//   return (
//     <nav>
//       <ul>
//         <li>
//           <NavLink
//             to="/about"
//             strict
//             exact
//             style={isActive => ({
//               color: !isActive ? "blue" : "green"
//             })}
//           >
//             About
//           </NavLink>
//         </li>
//
//         <li>
//           <NavLink
//             to="/MyProfile/Janne"
//             strict
//             exact
//             style={isActive => ({
//               color: !isActive ? "blue" : "green"
//             })}
//           >
//             My Profile
//           </NavLink>
//         </li>
//
//         <li>
//           <NavLink
//             to="/users"
//             strict
//             exact
//             style={isActive => ({
//               color: !isActive ? "blue" : "green"
//             })}
//           >
//             Users
//           </NavLink>
//         </li>
//
//         <li>
//           <NavLink
//             to="/users/Janne"
//             strict
//             exact
//             style={isActive => ({
//               color: !isActive ? "blue" : "green"
//             })}
//           >
//             Users (Janne)
//           </NavLink>
//         </li>
//
//         <li>
//           <NavLink
//             to="/users/Tomi"
//             strict
//             exact
//             style={isActive => ({
//               color: !isActive ? "blue" : "green"
//             })}
//           >
//             Users (Tomi)
//           </NavLink>
//         </li>
//
//         <li>
//           <NavLink
//             to="/login"
//             strict
//             exact
//             style={isActive => ({
//               color: !isActive ? "blue" : "green"
//             })}
//           >
//             logout
//           </NavLink>
//         </li>
//       </ul>
//     </nav>
//   )
// }, ({children, ...props}) => {
//   return (
//     <Zoom {...props} style={{ transitionDelay: '300ms' }}>
//       {children}
//     </Zoom>
//   )
// });

// const AuthNavigation = withAnimation((props) => {
//   console.warn('render AuthNavigation')
//   return (
//     <nav>
//       <ul>
//         <li>
//           <NavLink
//             to="/users"
//             strict
//             exact
//             style={isActive => ({
//               color: !isActive ? "blue" : "green"
//             })}
//           >
//             Login
//           </NavLink>
//         </li>
//       </ul>
//     </nav>
//   )
// }, ({children, ...props}) => {
//   return (
//     <Zoom {...props} style={{ transitionDelay: '300ms' }}>
//       {children}
//     </Zoom>
//   )
// });

const About = (props) => {
  console.log('render About')
  return <h2> About</h2>;
}
//
// const Users = (props) => {
//   console.log('render Users')
//   return <h2> Users</h2>;
// }
//
// const UsersProfile = (props) => {
//   console.log('render UsersProfile', props.match.params.id)
//   return <h1>Hello, User: {props.match.params.id}!</h1>;
// }
//
// const MyProfile = (props) => {
//   let navigate = useNavigate();
//   console.log('render MyProfile', props.match.params.id)
//   return (
//     <div>
//       <h1>Hello, UPDATE User: {props.match.params.id}! </h1>
//       <nav>
//         <ul>
//           <li>
//             <button
//               onClick={() => setTimeout(navigate(-1))}
//             >
//               Apply
//             </button>
//           </li>
//         </ul>
//       </nav>
//     </div>
//   );
// }

// function AuthButton() {
//   let navigate = useNavigate();
//   let auth = useAuth();
//
//   return auth.user ? (
//     <p>
//       Welcome!{" "}
//       <button
//         onClick={() => {
//           auth.signout(() => navigate("/"));
//         }}
//       >
//         Sign out
//       </button>
//     </p>
//   ) : (
//     <p>You are not logged in.</p>
//   );
// }
//
// function MyComponent() {
//   console.warn('render my Compoent')
//   return <h2> my Compoent</h2>;
// }

export default withThemeProvider(App);
