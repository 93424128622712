import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@mui/styles';

import { FormControlLabel, Switch, Dialog, DialogActions, DialogContent, DialogTitle, ListItemText, ListItem, List, Button } from '@mui/material';

import Moment from 'devtools/momentjs';

const ConfirmationDialogRaw = (props) => {
  const { onClose, values, open, ...other } = props;
  const [value, setValue] = useState(values);

  useEffect(() => {
    if (!open) {
      setValue(values);
    }
  }, [values, open]);

  // const handleEntering = () => {
  //   if (radioGroupRef.current != null) {
  //     radioGroupRef.current.focus();
  //   }
  // };

  const handleCancel = () => onClose();

  const handleOk = () => onClose(value);

  const handleChange = (event, item) => {
    let arr = value;
    if(isSelected(item)) {
      arr.splice(arr.indexOf(item), 1);
      setValue([...arr]);
    } else {
      setValue(arr.concat(item));
    }
  };

  const isSelected = item => value.indexOf(item) !== -1 ? true : false;

  return (
    <Dialog
      disableBackdropClick={false}
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onClose={onClose}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Select Days</DialogTitle>
      <DialogContent dividers>
        <ul style={{ listStyle: 'none' }}>
          {Moment.WeekDays().map((option, index) => (
            <li key={option}>
              <FormControlLabel value={option} control={<Switch color='primary' onClick={(e) => handleChange(e, index)} checked={isSelected(index)}/>} label={option} />
            </li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  open: PropTypes.bool.isRequired,
  values: PropTypes.array.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
}));

const CheckboxSelectionModal = ({ selected, onCloseModal, ...props}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [value] = React.useState(selected || []);

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    onCloseModal(newValue)
    ReactDOM.unmountComponentAtNode(document.getElementById('modal'));
    // if (newValue) {
    //   setValue(newValue);
    // }
  };

  return (
    <div className={classes.root}>
      <List component="div" role="list">
        <ListItem button divider disabled role="listitem">
          <ListItemText primary="Interruptions" />
        </ListItem>
        <ListItem
          button
          divider
          aria-haspopup="true"
          aria-controls="ringtone-menu"
          aria-label="phone ringtone"
          onClick={handleClickListItem}
          role="listitem"
        >
          <ListItemText primary="Phone ringtone" secondary={value} />
        </ListItem>
        <ListItem button divider disabled role="listitem">
          <ListItemText primary="Default notification ringtone" secondary="Tethys" />
        </ListItem>
        <ConfirmationDialogRaw
          classes={{
            paper: classes.paper,
          }}
          id="ringtone-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          values={value}
        />
      </List>
    </div>
  );
}
export default CheckboxSelectionModal;
