import React, { createContext, useCallback, useReducer, useContext, useEffect, useState } from 'react';

import { Drawers } from 'components/CustomDrawers';
import ErrorBoundary from 'components/ErrorBoundary';

const drawersContext = createContext();

const INITIALSTATE = [
  {
    open: false,
    component: null,
    drawerProps: {},
    variant: "temporary",
    anchor: "right",
  },
  {
    open: false,
    component: null,
    drawerProps: {},
    variant: "temporary",
    anchor: "right",
  },
  {
    open: false,
    component: null,
    drawerProps: {},
    variant: "temporary",
    anchor: "right",
  }
];

function reducer(state = INITIALSTATE, action) {
  switch (action.type) {
    case 'OPEN': {
      const index = state.findIndex((item) => item.open === false);
      state[index] = {
        ...Object.assign(
          state[index],
          {
            open: true,
            component: action.component,
            drawerProps: action.drawerProps
          }
        )
      };
      return [...state];
    }
    case 'CLOSE': {
      const index = state.findLastIndex((item) => item.open === true);
      if(index >= 0)  {
        state[index] = {
          ...Object.assign(
            state[index],
            {
              open: false,
              component: null,
              drawerProps: {},
            }
          )
        };
      }
      return [...state];
    }
    default:
      return state
  }
}

export function useDrawersProvider() {
  let mounted = true;
  const [drawers, dispatch] = useReducer(reducer, [...INITIALSTATE]);

  useEffect(() => {
    return () => mounted = false;
  }, []);

  const drawerAction = useCallback(
    (type = "CLOSE", newProps, component) => (event) => {
      if(!mounted) return;
      if(type === 'OPEN')
        dispatch({ type: type, drawerProps: newProps, component });
      else
        dispatch({ type: type });
    },
    [],
  );

  return {
    drawers,
    drawerAction
  }
}

export function useDrawers() {
  return useContext(drawersContext);
}

export function DrawersProvider({ children }) {
  const { drawers, drawerAction } = useDrawersProvider();
  // const { open, drawerAction, state, ...rest } = props;

  return (
    <drawersContext.Provider value={{ drawers, drawerAction }}>
      { children }
      <Drawers drawers={drawers} drawerAction={drawerAction} />
    </drawersContext.Provider>
  )
}

export const withDrawers = (Component) => (props) => {
  return (
    <DrawersProvider>
      <Component {...props} />
    </DrawersProvider>
  )
}
