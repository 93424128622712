import React from 'react';
import { connect } from 'react-redux';
import { TextField, ListItemText, Checkbox, Avatar, ListItemAvatar, Autocomplete } from '@mui/material';

import CheckBoxOutlineBlankIcon from  '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from  '@mui/icons-material/CheckBox';
import DialerSipIcon from  '@mui/icons-material/DialerSip';
import PhoneAndroidIcon from  '@mui/icons-material/PhoneAndroid';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function arrayFilter(arr = []) {
  return arr.length > 0 ? arr.map(({ id, extension, description, sip_client, extension_uuid }) => ({ id, extension, description, sip_client, extension_uuid })) : [];
}

const FormProfileExtensionsAutoComplete = (
  { input: { onChange, value, onClose, name}, label, meta: { touched, error }, customTextField, data, ...custom },
) => {
  data = arrayFilter(data).sort(function(a, b){return a.extension-b.extension});
  value = arrayFilter(value);

  return (
    <Autocomplete
      multiple
      onChange={(event, value, reason) => {
        onChange(value)
      }}
      name={name}
      value={value}
      options={data}
      disableCloseOnSelect
      getOptionLabel={(option) => option.description || String(option)}
      renderInput={(params) => <TextField {...params} label={label} {...customTextField} />}
      getOptionSelected={(option, val) => JSON.stringify(option) === JSON.stringify(val)}
      ChipProps={{
        color:"primary"
      }}
      renderOption={(option, { selected }) => (
        <>
          <ListItemAvatar>
            <Avatar>
              { option.sip_client ? <DialerSipIcon /> : <PhoneAndroidIcon /> }
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={option.description} secondary={option.extension} />
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
        </>
      )}
      {...custom}
    />
  );
}

export default FormProfileExtensionsAutoComplete;
