import React from 'react';
import { createTheme } from '@mui/material/styles';

import { ISINTERGER_REGEX } from '../Regex';

export const DEMO_DATA = [
  {
    name: "janne hailikari",
    title: "Business Analyst",
    location: "Minneapolis",
    age: 30,
    salary: "$100,000",
    tags: [
      { name: "nice" }
    ]
  },
  {
    name: "Gabby George",
    title: "Business Analyst",
    location: "Minneapolis",
    age: 30,
    salary: "$80,000",
    tags: [
      { name: "frequently-busy" },
    ]
  },
  {
    name: "Gabby George",
    title: "Business Analyst senior",
    location: "Minneapolis",
    age: 30,
    salary: "$90,000",
    tags: [
      { name: "frequently-busy" },
      { name: "nice" },
      { name: "leave-message" }
    ]
  },
  {
    name: "Gabby George",
    title: "Business Analyst junior",
    location: "Jacksonville",
    age: 30,
    salary: "$100,000",
    tags: [
      { name: "busy" },
      { name: "leave-message" }
    ]
  },
  {
    name: "Gabby George",
    title: "Analyst",
    location: "Jacksonville",
    age: 30,
    salary: "$100,000",
    tags: [
      { name: "nice" }
    ]
  }
];

//https://github.com/gregnb/mui-datatables

export const TABLE_OPTIONS = {
  elevation: 0,
  type: "search",
  searchPlaceholder: "Search",
  print: false,
  rowHover: true,
  searchProps: {
    size: 'small',
    variant: 'outlined'
    //label: 'search',
  }
  //
  // filterType: 'checkbox',
  // searchAlwaysOpen: true,
  // // customSearchRender: function (searchText, handleSearch, hideSearch, options) {
  // //  return
  // // }
  // //customTableBodyFooterRender
  // rowsPerPage: 50,
  // rowsPerPageOptions: [50, 100, 250],
};

export const getMUIDataTableOptions = (layout = 'default', datasetCount = null) => {
  return {
    default: {
      ...TABLE_OPTIONS,
      filterType: 'checkbox',
      searchAlwaysOpen: true,
      // customSearchRender: function (searchText, handleSearch, hideSearch, options) {
      //  return
      // }
      //customTableBodyFooterRender
      rowsPerPage: 50,
      rowsPerPageOptions: [50, 100, 250],
      pagination: ISINTERGER_REGEX.test(datasetCount) && datasetCount > 50 ? true : false,
      textLabels: {
        body: {
          noMatch: "No Records Found!",
        },
      }
    },
    missedcalls: {
      ...TABLE_OPTIONS,
      download: false,
      viewColumns: false,
      filterType: 'checkbox',
      rowsPerPage: 25,
      rowsPerPageOptions: [25, 50, 100],
      pagination: ISINTERGER_REGEX.test(datasetCount) && datasetCount > 25 ? true : false,
      textLabels: {
        body: {
          noMatch: "List Is Empty!",
        },
      }
    }
  }[layout]
}

export const getMUIDataTableTheme = (layout = 'default') => createTheme({
  default: {
    components: {
      th: {
        fontSize: '10px'
      },
      td: {
        fontSize: '10px'
      },
      MUIDataTableBodyCell: {
        styleOverrides:{
          root: {
            fontSize: '12px'
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides:{
          root: {
            '& div': {
              fontSize: '12px'
            }
          },
        }
      },
      MuiTableCell: {
        styleOverrides:{
          root: {
            padding: '2px'
          },
        }
      },
      MUIDataTable: {
        styleOverrides:{
          root: {
            padding: "10px 0px 0px 0px !important"
          },
        }
      },
      MUIDataTableToolbarSelect: {
        styleOverrides:{
          root: {
            margin: "8px !important",
            backgroundColor: "#d7d7d7",
          },
        }
      },
      MUIDataTablePagination: {
        styleOverrides:{
          toolbar: {
            p: {
              margin: 0,
            }
          },
        }
      }
    }
  },
  missedcalls: {
    components: {
      th: {
        fontSize: '10px'
      },
      td: {
        fontSize: '10px'
      },
      MUIDataTableBodyCell: {
        styleOverrides:{
          root: {
            fontSize: '12px',
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides:{
          root: {
            '& div': {
              fontSize: '12px'
            }
          },
        }
      },
      MuiTableCell: {
        styleOverrides:{
          root: {
            padding: '4px'
          },
        }
      },
      MUIDataTable: {
        styleOverrides:{
          root: {
            padding: "8px 0px 0px 0px !important"
          },
        }
      },
      MUIDataTableToolbarSelect: {
        styleOverrides:{
          root: {
            margin: "8px !important",
            backgroundColor: "#d7d7d7",
          },
        }
      },
      MUIDataTablePagination: {
        styleOverrides:{
          toolbar: {
            p: {
              margin: 0,
            }
          },
        }
      }
    }
  }
}[layout]);
