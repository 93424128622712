import React, { Component, forwardRef } from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import { Grid, TextField, InputAdornment, IconButton, Typography, Collapse } from '@mui/material';

import SearchIcon from  '@mui/icons-material/Search';

import SearchBar from './SearchBar';

import { Card, CardBody } from 'components/Card';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          // backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  margin: {
    margin: theme.spacing(1),
  }
}));

let selected;

const EnhancedTableToolbar = forwardRef((props, ref) => {
  const classes = useStyles();
  const { clearSelected, selected: selectedProp, onSearchChange, components, options, searchPortalRef } = props;
  const { checkboxCollumn, searchComponent, title, searchText } = options;
  const { toolbarComponent, selectedToolbar } = components;

  selected = selectedProp; // track actual selected items

  const showSeletedToolbar = selected.length > 0;

  function renderBody() {
    if (showSeletedToolbar) {
      return (
        <Card well color="rose" noMargin >
          <CardBody>
            <Grid container justifyContent="flex-end" alignItems="center" direction="row" spacing={2}>
              <Grid item xs>
                 <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">{selected.length} selected</Typography>
              </Grid>
              <Grid item>
                {
                  typeof selectedToolbar === 'function' && selectedToolbar(() => selected, clearSelected)
                }
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      )
    } else if ((Boolean(toolbarComponent) || Boolean(searchComponent)) && !Boolean(searchPortalRef)) {
      return (
        <Card well color="default" noMargin >
          <CardBody>
            <Grid container justifyContent="flex-start" alignItems="center" direction="row" spacing={2}>
              {
                typeof toolbarComponent === 'function' &&
                 <Grid item>
                   { toolbarComponent() }
                 </Grid>
              }

              {
                // !Boolean(searchPortalRef)
                //   ? <Grid item style={{ marginLeft: 'auto' }}>
                //        <SearchBar {...props} />
                //     </Grid>
                //   : ReactDOM.createPortal(
                //       <Grid item style={{ marginLeft: 'auto' }}>
                //         <SearchBar {...props} />
                //       </Grid>,
                //       searchPortalRef?.current
                //     )
              }

              {
                searchComponent &&
                  <Grid item style={{ marginLeft: 'auto' }}>
                     <SearchBar {...props} />
                  </Grid>
              }
            </Grid>
          </CardBody>
        </Card>
      )
    } else if (Boolean(searchPortalRef)) {
      return ReactDOM.createPortal(
        <Grid item style={{ marginLeft: 'auto' }}>
          <SearchBar {...props} />
        </Grid>,
        searchPortalRef?.current
      )
    } else {
      return null;
    }
  }

  return (
    <div>
      {
        renderBody()
      }
    </div>
  )

});

EnhancedTableToolbar.propTypes = {
  selected: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  // components: PropTypes.object.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  clearSelected: PropTypes.func.isRequired
};

export default EnhancedTableToolbar;
