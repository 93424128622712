import React from 'react';
import { withStyles } from '@mui/styles';
import { grey, green } from '@mui/material/colors';
import { Badge } from '@mui/material';

const Light = withStyles({
  anchorOriginTopLeftRectangle: {
    transform: 'none',
    top: '-10px'
  },
  colorPrimary: {
    backgroundColor: grey[400],
    color: grey[900]
  },
})(Badge);

const Dark = withStyles({
  anchorOriginTopLeftRectangle: {
    transform: 'none',
    top: '-10px'
  },
  colorPrimary: {
    backgroundColor: grey[700],
  },
})(Badge);

const InputBadge = ({ children, theme, ...props}) => {
  const Component = {
    Badge: Badge,
    Light: Light,
    Dark: Dark
  }[theme || Badge];

  return (
    <Component
      color="primary"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    >
      {children}
    </Component>
  )
}

export default InputBadge;
