import React from 'react';
import { Badge, Avatar } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import classNames from "classnames";

import styles from "assets/jss/components/badgeStyle";
const useStyles = makeStyles(createStyles(styles));


const CustomAvatarWithBadge = ({ tooltipProps, badgeProps, avatarProps, defaultColor, size, user, altAvatar, ...props}) => {
  const classes = useStyles();
  //const {} = props;

  const avatarClasses = classNames({
    [classes.avatar]: true,
    [classes['avatar-small']]: size === 'small',
    [classes['avatar-small']]: size === 'medium',
    [classes['avatar-large']]: size === 'large',
    [classes['avatar-profile']]: size === 'profile'
  });

  return (
    <HelperTooltipBootstrap {...tooltipProps} arrow>
      <div>
        <Avatar
          className={avatarClasses}
          style={{
            backgroundColor: '#bdbdbd',
            color: '#fff'
          }}
          { ...user?.image && { src: user?.image } }
          {...avatarProps}
        >
          {user?.altName || user?.username?.substring(0, 1) || '?' }
        </Avatar>
      </div>
    </HelperTooltipBootstrap>
  );
}

CustomAvatarWithBadge.defaultProps = {
  size: '',
  tooltipProps: {
    title: 'User Unknown',
    placement: 'right'
  }
};

export default CustomAvatarWithBadge;

// const CustomAvatarWithBadge = ({ tooltipProps, badgeProps, avatarProps, defaultColor, size, user, altAvatar, ...props}) => {
//   const classes = useStyles();
//
//   const avatarClasses = classNames({
//     [classes.avatar]: true,
//     [classes['avatar-small']]: size === 'small',
//     [classes['avatar-small']]: size === 'medium',
//     [classes['avatar-large']]: size === 'large',
//     [classes['avatar-profile']]: size === 'profile'
//   });
//
//   const badgeClasses = classNames({
//     [classes.badge]: true,
//     [classes.success]: new RegExp('^PAIKALLA$').test(user?.line),
//     [classes.danger]: new RegExp('^(POISSA|LOMA|KOKOUS|LOUNAS)$').test(user?.line)
//   });
//
//   const userMissing =
//     <Avatar
//       className={avatarClasses}
//       style={{
//         backgroundColor: '#bdbdbd',
//         color: '#fff'
//       }}
//     >
//       { altAvatar }
//     </Avatar>
//
//   const withUser =
//       <Badge
//         className={badgeClasses}
//         overlap="circular"
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'right',
//         }}
//         variant="dot"
//         {...badgeProps}
//       >
//         <Avatar
//           className={avatarClasses}
//           style={{
//             backgroundColor: user?.avatarColor || '#bdbdbd',
//             color: '#fff'
//           }}
//           { ...user?.image && { src: user?.image } }
//           {...avatarProps}
//         >
//           {user?.altName || user?.username?.substring(0, 1) || '?' }
//         </Avatar>
//       </Badge>
//
//   return user && Object.keys(user).length > 0 && user.line
//     ? <HelperTooltipBootstrap {...tooltipProps} arrow>
//         {withUser}
//       </HelperTooltipBootstrap>
//     : <HelperTooltipBootstrap title='User Unknown' placement='right' arrow>
//         {userMissing}
//       </HelperTooltipBootstrap>
// }
//
// CustomAvatarWithBadge.defaultProps = {
//   altAvatar: '?',
//   size: ''
// };
//
// export default CustomAvatarWithBadge;
