import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import {
  Divider,
} from '@mui/material';

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // padding: theme.spacing(0, 1),
  padding: 0,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const DrawerSection = ({ divider, props}) => {
  const theme = useTheme();
  return <StyledDiv {...props} />
}

DrawerSection.defaultProps = {
  divider: false
}

export default DrawerSection;
