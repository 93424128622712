import React, { Component } from 'react';

import { Skeleton } from '@mui/material';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  handleRefreshClick(e) {
    this.setState({ hasError: false })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(this.state.hasError && nextProps.value) {
      // reload component
      console.log('RENDER')
    }
    return true;
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    const { fallback } = this.props;
    return this.state.hasError
      ? fallback(this.handleRefreshClick.bind(this)) || <div>Error!</div>
      : this.props.children
  }
}

export {
  ErrorBoundary as default,
}
