import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Select, TextField, MenuList, MenuItem, ListSubheader, ListItemText, ListItemIcon, Box, InputLabel, FormHelperText, FormControl, Chip, Divider } from '@mui/material';

import MusicNoteIcon from  '@mui/icons-material/MusicNote';

import { NewAnnouncementButton } from 'components/Announcements';

const mapStateToProps = state => ({
  announcements: state.announcements.announcements,
  loading: state.announcements.loading,
});

const FormSelectGreetings = connect(
  mapStateToProps,
  null
)((
  { input: { onChange, value: valueProp, name }, label, meta: { touched, error }, dispatch, additionals, defaultValue, formControlProps, dense, id, helperText, variant, menuItemProps, InputLabelProps, data, announcements, loading, ...custom },
) => {
  if(loading) return null;

  const { voicemail_id, domain_uuid } = additionals;
  // value needs to be (data) array element!

  const LAST_GREETING_ID_ARRAY = data.map(item => item.greeting_id);
  const LAST_GREETING_ID = LAST_GREETING_ID_ARRAY.length > 0 ? Math.max(...LAST_GREETING_ID_ARRAY) : 0;

  const GLOBAL_GREETINGS = [...announcements].map(({ audio, recording_description, ...item}, index) => {
    const greeting_id = LAST_GREETING_ID + 1 + index;
    return {
      id: uuidv4(),
      greeting_id: greeting_id,
      greeting_base64: null,
      greeting_description: recording_description,
      greeting_filename: `greeting_${greeting_id}.wav`,
      greeting_name: `Greeting ${greeting_id}`,
      voicemail_greeting_uuid: uuidv4(),
      voicemail_id: voicemail_id,
      domain_uuid: domain_uuid,
    }
  })

  const value = [...data, ...GLOBAL_GREETINGS].find(obj => {
    return obj.greeting_id === valueProp;
  }) || defaultValue;

  const handleChange = event => {
    if(event.target.value !== undefined) onChange(event.target.value)
  }

  return (
    <TextField
      id={id}
      select
      label={label}
      onChange={handleChange}
      value={value}
      helperText={ helperText || null}
      variant={variant}
      {...custom}
    >
      <ListSubheader component="div" id="nested-list-subheader" style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', backgroundColor: 'white' }}>
        Other
        <NewAnnouncementButton color='success' float='right' />
      </ListSubheader>

      <MenuItem value={null}><em>None</em></MenuItem>

      <Divider />

      <ListSubheader component="div" id="nested-list-subheader" style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', backgroundColor: 'white' }}>
        Local System Greetings
        <Chip size='small' label={data.length} style={{ marginLeft: 'auto' }}/>
      </ListSubheader>

      {
        data.map((item, index) => {
          const { greeting_id, greeting_description, greeting_filename, greeting_name } = item;
          return (
            <MenuItem key={greeting_filename} name={greeting_description} value={item} {...menuItemProps} selected={JSON.stringify(item) === JSON.stringify(value)}>
              <ListItemIcon>
                <MusicNoteIcon />
              </ListItemIcon>
              <ListItemText
                primary={<small><i>{greeting_description}</i></small>}
                secondary={<small>{greeting_filename}</small>}
              />
            </MenuItem>
          )
        })
      }

      <Divider />

      <ListSubheader component="div" id="nested-list-subheader" style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', backgroundColor: 'white' }}>
        Global System Greetings
        <Chip size='small' label={GLOBAL_GREETINGS.length} style={{ marginLeft: 'auto' }}/>
      </ListSubheader>

      {
        GLOBAL_GREETINGS.map((item, index) => {
          //console.log(JSON.stringify(item), value)
          const { greeting_id, greeting_description, greeting_filename, greeting_name } = item;
          return (
            <MenuItem key={`${index}`} name={greeting_description} value={item} {...menuItemProps} selected={false}>
              <ListItemIcon>
                <MusicNoteIcon />
              </ListItemIcon>
              <ListItemText
                primary={greeting_description}
                secondary={<small>{greeting_filename}</small>}
              />
            </MenuItem>
          )
        })
      }
    </TextField>
  )
})

FormSelectGreetings.defaultProps = {
  id: 'demo-simple-select-outlined',
  variant: 'outlined',
  data: [],
  dense: true,
  defaultValue: 'null',
  additionals: {}
};

export { FormSelectGreetings };
