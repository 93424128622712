import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { grey } from '@mui/material/colors';
// import { makeStyles, createStyles } from '@mui/styles';
import { Avatar, Checkbox, TextField, ListItemText, ListItemAvatar, InputAdornment, Autocomplete } from '@mui/material';

import { RenderGroup } from './AutoComplete';

import AccountCircle from  '@mui/icons-material/AccountCircle';
import ArrowForwardIcon from  '@mui/icons-material/ArrowForward';
import ExtensionIcon from  '@mui/icons-material/Extension';
import MusicNoteIcon from  '@mui/icons-material/MusicNote';
import AccountTreeIcon from  '@mui/icons-material/AccountTree';
import CheckBoxOutlineBlankIcon from  '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from  '@mui/icons-material/CheckBox';

import { OpenModalButton } from 'components/CustomButtons';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import { partition, orderBy } from 'lodash';
import { isLoading } from 'devtools/Helpers';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const areEqual = (prevProps, nextProps) => {
  return prevProps.loading === nextProps.loading || JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  //return false;
};

const FormExtensionsAvailableAutocompelete = ({ input: { onChange, name }, options, dispatch, textFieldProps, ...props}) => {
  options = options.map(item => ({ ...item, category: Boolean(item?.userid) ? 'Not Available' : 'Available'}))
  options = orderBy(options, 'category', 'asc')

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect // dont close on item select
      name={name}
      onChange={(event, value, reason) => onChange(value)}
      size="small"
      getOptionLabel={(option) => String(option?.description || option?.extension)}
      defaultValue={[]}
      limitTags={3}
      ChipProps={{
        color: "primary"
      }}
      options={[...options]}
      getOptionDisabled={(option) => Boolean(option?.userid)}
      groupBy={(option) => option?.category}
      getOptionSelected={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
      renderGroup={(options) => {
        return (
          <RenderGroup
            {...options}
            additionals={{
              'Available': <div id="FormExtensionsAvailableAutocompeleteInputAdornment" />
            }}
          />
        )
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <ListItemText
            primary={option?.extension}
            secondary={option?.description}
          />
        </React.Fragment>
      )}
      renderInput={({ InputProps: inputProps, ...params}) => (
        <TextField
          {...params}
          {...textFieldProps}
          InputProps={{
            ...inputProps,
            startAdornment: (
              <React.Fragment>
                <InputAdornment position="start">
                  <ExtensionIcon style={{ color: grey[500] }}/>
                </InputAdornment>
                { inputProps.startAdornment }
              </React.Fragment>
            )
          }}
        />
      )}
      {...props}
    />
  );
}

const mapStateToProps = state => ({
  loading: isLoading([state.extensions.loading,state.firebase.loading]),
  options: state.extensions.extensions
  // data: [
  //   { description: 'The Shawshank Redemption', userid: null, extension: 1994 },
  //   { description: 'The Godfather', userid: '34gfdrer5tgsd', extension: 1972 },
  //   { description: 'The Godfather: Part II', userid: null, extension: 1974 },
  //   { description: 'The Dark Knight', userid: null, extension: 2008 },
  //   { description: '12 Angry Men', userid: 'frtgty676tgbf', extension: 1957 },
  // ],
});

FormExtensionsAvailableAutocompelete.defaultProps = {
  id: "autocomplete-" + uuidv4(),
};

export default connect(
  mapStateToProps,
  null
)(FormExtensionsAvailableAutocompelete);

// endAdornment: (
//   <React.Fragment>
//     {
//       inputProps.endAdornment
//     }
//     <InputAdornment position="start">
//       <div id='FormExtensionsAvailableAutocompeleteInputAdornment' />
//     </InputAdornment>
//   </React.Fragment>
// )

// <OpenModalButton
//   color='primary'
//   size='sm'
//   type='button'
//   round
//   template="ExtensionsCreatorModal"
// >
//   Create New Extensions
// </OpenModalButton>
