import React from "react";
import { Box, CircularProgress } from '@mui/material';

const SimpleLoader = ({ label, ...props }) => {
  return (
    <Box display="flex" justifyContent='center' {...props}>
      <CircularProgress color="secondary" />
      { label && <Box pl={2}>{label}</Box> }
    </Box>
  );
}

SimpleLoader.defaultProps = {
  alignItems: 'center'
}

export default SimpleLoader;
