import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { reduxForm, formValueSelector } from 'redux-form';

import { SimpleLoader } from 'components/Loaders';

import { isEmpty } from 'lodash';

import { isPromise } from 'Helpers';

import { useFormValues } from './State';

import { setFormValues } from 'store/form/actions';

const CustomForm = ({ data, children, dispatch, content, getInitialValues, _getFormValues, ...props }) => {
  const { handleSubmit, change, submitting, form } = props;
  let mounted = true;

  useEffect(() => {
    (async() => {
      const values = typeof data === 'function' ? await data() : data;
      if(!isEmpty(values) && JSON.stringify(getInitialValues(form)) !== JSON.stringify(values)) {
        if(mounted) await dispatch(setFormValues(form, values))
      }
    })();

    return () => {
      mounted = false;
    }
  }, [data])

  const initialValues = getInitialValues(form);

  function getFormValues(values) {
    return _getFormValues(form, values)
  }

  return form && <form onSubmit={handleSubmit}>
      {
        initialValues && content({...props, getFormValues, initialValues })
        // submitting
        //   ? <SimpleLoader label='Processing File' alignItems='center' p={5} />
        //   : <div>
        //
        //     </div>
      }
    </form>
};

const mapStateToProps = state => {
  return {
    getInitialValues: (form) => state.formState[form],
    _getFormValues: (form, values) => {
      const selector = formValueSelector(form)
      return selector(state, ...values);
    }
  }
};

export default connect(
  mapStateToProps,
  null
)(reduxForm({
  enableReinitialize: true,
})(CustomForm));

export { useFormValues };
