import React, { memo, Suspense, useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';

import { makeStyles, withStyles, createStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

import { Extension } from 'Helpers/Extensions';

import { DEFAULT_SAMPLE, ANNOUNCEMENT_SAMPLES } from 'constants/Announcements';

import { Field, FieldArray, Form, change, reduxForm, SubmissionError, formValueSelector } from 'redux-form';
import { FormTextField, FormCalleridAutoComplete, FormPhoneNumberTextField, FormExtensionTextField, FormCreateExtensionTableFieldArray } from 'components/Forms/Components';
import { required, asyncExtensionCreatorCheck } from 'components/Forms/Validators';

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import { CustomTextField } from 'components/CustomInput';
import { UploadCard, SimpleDropZone } from 'components/Announcements';

import { Card, CardIcon, CardHeader, CardBody, CardFooter } from 'components/Card';

import { Grid, Badge, Checkbox, Tab, Tabs, Box, Chip, FormControlLabel, Switch, CircularProgress, Dialog, DialogContentText, ListItemText, MenuItem, List, InputAdornment, IconButton, Button, Divider, ListSubheader, ListItemIcon, ListItemAvatar, Avatar, ListItemSecondaryAction } from '@mui/material';
import { CardActions, CardActionArea, CardMedia, CardContent, Typography, TableRow, TableCell } from '@mui/material';
import image from 'assets/img/logo-microsoft.png';

import RegularButton from "components/CustomButtons";

import { TTSform, TTSHeader, TTSsample, TTSAudioList, SimpleTTS } from "components/TTS";

import { AudioControls } from 'components/Custom';

import DeleteIcon from  '@mui/icons-material/Delete';
import CloseIcon from  '@mui/icons-material/Close';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExtensionIcon from  '@mui/icons-material/Extension';
import InboxIcon from  '@mui/icons-material/MoveToInbox';
import DraftsIcon from  '@mui/icons-material/Drafts';
import SendIcon from  '@mui/icons-material/Send';
import ExpandLess from  '@mui/icons-material/ExpandLess';
import ExpandMore from  '@mui/icons-material/ExpandMore';
import StarBorder from  '@mui/icons-material/StarBorder';
import CancelIcon from  '@mui/icons-material/Cancel';
import CloudUploadIcon from  '@mui/icons-material/CloudUpload';
import MusicNoteIcon from  '@mui/icons-material/MusicNote';
import AddIcon from  '@mui/icons-material/Add';
import SettingsIcon from  '@mui/icons-material/Settings';
import KeyboardArrowUpIcon from  '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowLeftIcon from  '@mui/icons-material/KeyboardArrowLeft';
import ExpandMoreIcon from  '@mui/icons-material/ExpandMore';
import GetAppIcon from  '@mui/icons-material/GetApp';

import { SimpleLoader } from 'components/Loaders';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'components/Modals';

import { keyBy } from 'lodash';

import { setFormValues } from 'store/form/reducer';
import { extensions_update_Actions } from 'store/extensions/actions';

import { getAvailableExtension } from 'Helpers/Extensions';

import styles from "assets/jss/views/dashboardStyle.js";
const useStyles = makeStyles(createStyles(styles));

const FORM = 'ExtensionsCreator';

function areEqual(prevProps, nextProps) {
  return JSON.stringify(prevProps) !== JSON.stringify(nextProps)
}

const ExtensionsCreator = ({ dispatch, organization: { domain, domain_uuid }, ...props}) => {
  const classes = useStyles();
  const { handleClose, open, generateAvailableExtension } = props;
  const { handleSubmit, pristine, dirty, reset, submitting, change, initialValues, form, error } = props;

  const [loading, setLoading] = useState(false);
  const fetchAvailable = (reset) => reset ? generateAvailableExtension.next(reset).value : generateAvailableExtension.next().value;


  const addRow = () => new Extension({ extension: generateAvailableExtension.next(false).value });
  const removeRow = (extension) => generateAvailableExtension.next(extension);

  useEffect(() => {
    dispatch(setFormValues(FORM, { extensions: [addRow()]})) // initiate first available!
  }, [])

  const onSubmit = async (newValues) => {
    const obj = {};
    try {
      console.log(newValues)

      if(newValues.extensions) {
        for(let {id, ...extension} of newValues.extensions) {
          obj[id] = { user_context: domain, domain_uuid, ...extension };
        }
        await dispatch(extensions_update_Actions(obj));
      }
      handleClose();
    } catch (err) {
      throw new SubmissionError({
        submit: 'Submiting Error',
        _error: err,
      });
    }
  }

  return (
    <div style={{ display: open ? 'block' : 'none' }}>
      {
        initialValues &&
          <form onSubmit={handleSubmit(onSubmit)}>
            <FieldArray
              name="extensions"
              component={FormCreateExtensionTableFieldArray}
              actions={{
                addRow: addRow,
                removeRow: removeRow
              }}
              {...props}
            />
            <DialogActions>
              <Button disabled={pristine || submitting} type='submit' color="primary">
                { loading && <CircularProgress color="secondary" size={26} style={{ marginRight: '6px' }} />}
                Save
              </Button>
              <Button onClick={handleClose} color="secondary">
                Close
              </Button>
            </DialogActions>
          </form>
      }
    </div>
  );
}



const mapStateToProps = state => ({
  initialValues: state.formState[FORM],  // pull initial values from form reducer
  organization: state.currentUser.organization,
  generateAvailableExtension: state.extensions.generateAvailableExtension,
  getFormValues: (values = []) => {
    const selector = formValueSelector(FORM);
    return selector(state, ...values)
  }
});

export default memo(
  connect(
    mapStateToProps,
    null
  )(
    reduxForm({
      form: FORM,
      enableReinitialize: true,
      destroyOnUnmount: true, // <------ preserve form data
      forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
      asyncValidate: asyncExtensionCreatorCheck,
      asyncBlurFields:['extensions[].extension', 'extensions[].number_alias']
    })(ExtensionsCreator)
  ),
  areEqual
);
