import {
  whiteColor,
  blackColor,
  hexToRgb
} from "assets/jss/default.js";

const authStyle = theme => {
  console.log(theme)
  return ({
    wrapper: {
      height: "auto",
      minHeight: "100vh",
      position: "relative",
      top: "0"
    },
    active: {
      background: 'rgba(200, 200, 200, 0.2)'
    },
    fullPage: {
      padding: "120px 0",
      position: "relative",
      minHeight: "100vh",
      display: "flex !important",
      margin: "0",
      border: "0",
      color: whiteColor,
      alignItems: "center",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      height: "100%",
      // [theme.breakpoints.down("sm")]: {
      //   //minHeight: "fit-content!important",
      //   padding: "30px 0",
      // },
      "& footer": {
        position: "absolute",
        bottom: "0",
        width: "100%",
        border: "none !important"
      },
      "&:before": {
        backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)"
      },
      "&:before,&:after": {
        display: "block",
        content: '""',
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        zIndex: "2"
      }
    }
  })
};

export default authStyle;
