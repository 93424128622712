import { firebase, store } from 'index.js';

import { isEmpty } from 'lodash';

import { onError, enqueueSnackbar } from 'store/Notifier/actions';

import { uniqBy, groupBy } from "lodash";

import { ReplaySubject, combineLatest } from "rxjs";
import { map, distinctUntilChanged } from 'rxjs/operators';

import { makeCollection, sortCollection, sortByTime, parseTypes, compare } from 'devtools/Helpers';

import { sweetalerts } from 'sweetalerts';

import { serviceCleanup } from './index';

// tämä toimii hakuna!
// firebase.contacts().child("telephones")
// .orderByChild('+358931521916')
// .startAt("+358931521916")
// .once("value")
// .then((snapshot) => console.log('math orderByChild +358931521916', snapshot.val()))



// Contacts page
export const service_contacts = function () {
  const observable_general$ = new ReplaySubject();
  const observable_emails$ = new ReplaySubject();
  const observable_telephones$ = new ReplaySubject();
  const observable_search_tags$ = new ReplaySubject();

  const subscriptions = [];

  const refs = [
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("general"), "on", "value", (snapshot) => observable_general$.next(makeCollection(parseTypes(snapshot.val(), true)))),
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("emails"), "on", "value", (snapshot) => observable_emails$.next(snapshot.toJSON())),
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("telephones"), "on", "value", (snapshot) => observable_telephones$.next(groupBy(makeCollection(parseTypes(snapshot.val(), true)), "contact"))),
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("search_tags"), "on", "value", (snapshot) => observable_search_tags$.next(snapshot.toJSON())),
  ];

  const observable = combineLatest(observable_general$, observable_emails$, observable_telephones$, observable_search_tags$)
    .pipe(
      map(([general, emails, telephones, search_tags]) => {
        return general.map(item => {
          return {
            ...item,
            emails: Object.values(emails?.[item.id] ?? {}) ?? [],
            telephones: Object.values(telephones?.[item.id] ?? {}) ?? [],
            search_tags: Object.values(search_tags?.[item.id] ?? {}) ?? [],
          }
        })
      }),
      distinctUntilChanged(),
    );

  function add_subscriber(callback) {
    return subscriptions.push(
      observable.subscribe(
        callback,
        err => console.warn('service_contacts', err)
      )
    ); // add to array and return subscription!
  }


  return {
    add_subscriber: add_subscriber,
    remove_subscriber: (subscription) => serviceCleanup(subscription, refs, subscriptions),
    observable,
  }
}


export const action_remove_contacts = async (datasets = [], bulkUpdates = {}) => {
  try {
    await sweetalerts.confirm();
    let loadingKey = enqueueSnackbar(store.dispatch, 'LOADING');

    try {
      //telephones
      for(let { id, ...rest } of datasets) {
        bulkUpdates['/telephones/' + id] = null;
        bulkUpdates['/emails/' + id] = null;
        bulkUpdates['/general/' + id] = null;
        bulkUpdates['/search_tags/' + id] = null;
      };

      //console.log(JSON.stringify(bulkUpdates))

      await firebase.contacts().update(bulkUpdates);
      enqueueSnackbar(store.dispatch, 'SUCCESS', loadingKey);
    } catch (err) {
      onError(store.dispatch, err)
    }
  } catch (e) {
    // cancel on confirm
  }
}
