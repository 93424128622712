import React from 'react';
import { styled } from '@mui/material/styles';
import { red, green } from '@mui/material/colors';

// https://mui.com/system/styled/#main-content

// import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

export const CalllogListItemButton = styled(ListItemButton)`
  min-height: 62px;
  border-left: 4px solid transparent;

  &.Mui-selected {
    border-left: 4px solid ${red[600]};
    background-color: #ff00001c;
  }
`;
// (({ theme }) => ({
//
// }));


//border-left: 4px solid ${green[600]};
