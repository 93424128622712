import {
  somicHeader,
  somicYellow,
  somicGrey
} from '../somicStyle';

import {
  containerFluid,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor
} from "assets/jss/default.js";

const headerStyle = () => ({
  appBar: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute !important",
    width: "100%",
    paddingTop: "10px",
    zIndex: "1029",
    color: grayColor[6],
    border: "0",
    borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block !important"
  },
  somicHeader: {
    minHeight: "72px",
    ...somicHeader
  },
  somicTabsIndicatorPill: {
    height: '40px',
    bottom: '16px',
    borderRadius: '20px',
    backgroundColor: somicYellow + " !important"
  },
  somicTab: {
    color: "white",
    fontFamily: 'Roboto',
    textTransform: 'inherit',
  },
  somicTabsIndicatorPillselected: {
    color: "black !important",
    fontFamily: "Roboto",
    fontWeight: 600,
    zIndex: 10
  },
  somicTabsIndicator: {
    backgroundColor: somicYellow + " !important"
  },
  somicTabSelected: {
    color: somicYellow + " !important",
    '& .MuiTouchRipple-root': {
      //backgroundColor: somicYellow + " !important"
    }
  },
  somicButton: {
    fontFamily: "Bell Gothic Black",
    ...somicGrey
  },
  container: {
    ...containerFluid,
    minHeight: "50px",
    paddingRight: "50px !important",
    paddingLeft: "50px !important"
  },
  flex: {
    flex: 1
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    margin: "0 !important",
    letterSpacing: "unset",
    "&:hover,&:focus": {
      background: "transparent"
    }
  },
  primary: {
    backgroundColor: primaryColor[0] + " !important",
    color: whiteColor,
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor[0]  + " !important",
    color: whiteColor,
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor[0]  + " !important",
    color: whiteColor,
    ...defaultBoxShadow
  },
  warning: {
    backgroundColor: warningColor[0]  + " !important",
    color: whiteColor,
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor[0]  + " !important",
    color: whiteColor,
    ...defaultBoxShadow
  },
  sidebarMinimize: {
    float: "left",
    display: "block",
    color: grayColor[6]
  },
  sidebarMiniIcon: {
    width: "20px",
    height: "17px"
  },
  fullHeight: {
    height: "100%"
  }
});

export default headerStyle;
