import React, { createRef, useCallback } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import Button from "components/CustomButtons/Button.js";
import { InputBase } from '@mui/material';

import { debounce } from 'lodash';

const options = Array.from(Array(301).keys()); // 0 - 300

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'inherit',
  },
  input: {
    padding: '0 !important',
    width: '50px',
    [theme.breakpoints.down("md")]: {
      width: '40px',
    },
  }
}));

const CustomRinggroupNumberInput = ({ onChange, name, debounce: debounceProp, ...custom }) => {
  const classes = useStyles();
  const inputRef = createRef();

  const handler = useCallback(debounce(value => onChange(value), debounceProp), []);

  return (
    <Button color="github" round {...custom} aria-controls="simple-menu" size='sm' aria-haspopup="true" onClick={(event) => inputRef.current.focus()}>
      <InputBase
        inputRef={inputRef}
        inputProps={{
          type: 'number',
          min: 0,
          max: 300
        }}
        name={name}
        classes={{ input: classes.input }}
        margin='none'
        type='number'
        defaultValue="0"
        style={{ color: 'white', maxWidth: '80px' }}
        onChange={e => {
          const value = e.currentTarget.value;
          e.persist();
          handler(value);
        }}
        {...custom}
      />
    </Button>
  );
}

CustomRinggroupNumberInput.defaultProps = {
  debounce: 600
};

export default CustomRinggroupNumberInput;
