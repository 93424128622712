import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { TextField, ListItemIcon, ListItemText, Checkbox } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { Card, CardBody } from 'components/Card';

import { uniqWith, isEqual } from 'lodash';

import CheckBoxOutlineBlankIcon from  '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from  '@mui/icons-material/CheckBox';

import { isLoading } from 'devtools/Helpers';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const filter = createFilterOptions();

const FormCardDatasetAutoComplete = (
  { input: { onChange, onClose, name, value }, label, meta: { touched, error }, customTextField, dispatch, loading, data, size, fullWidth, ...custom },
) => {
  const options = uniqWith([...data, ...value], isEqual); // add custom objects to options list and remove duplicates!

  const cardBodyStyle = size === 'small' ? { style: { padding: 4 }} : {};

  return (
    <Card noMargin>
      <CardBody {...cardBodyStyle}>
        <Autocomplete
          size={size}
          style={{ width: !fullWidth ? 340 : 'auto' }}
          onChange={(event, arrayValue, reason) => {
            onChange(
              arrayValue.map(value => {
                if (value?.inputValue) {
                  return { label: { primary: value.inputValue }, category: 'Custom' }
                } else if (typeof value === 'string') {
                  return { label: { primary: value }, category: 'Custom' }
                } else {
                  return value;
                }
              })
            )
          }}
          loading={loading}
          name={name}
          value={value}
          groupBy={(option) => option.category}
          options={[...options]}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          getOptionLabel={(option) => {
            if (option.inputValue) return option.inputValue;
            return option?.label?.primary || String(option)
          }}
          renderInput={(params) =>
            <TextField
              {...params}
              label={label}
              {...customTextField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
          fullWidth={Boolean(fullWidth)}
          getOptionSelected={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
          renderOption={(option, { selected }) => {
            if (option.inputValue) {
              return option.title; // add ...
            } else {
              return (
                <React.Fragment>
                  <ListItemIcon>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                  </ListItemIcon>
                  <ListItemText primary={option?.label?.primary} secondary={option?.label?.secondary} />
                </React.Fragment>
              )
            }
          }}
          {...custom}
        />
      </CardBody>
    </Card>
  );
}

FormCardDatasetAutoComplete.defaultProps = {
  size: 'medium'
};

const mapStateToProps = state => ({
  data: state.additionals.available_datasets,
  loading: isLoading([state.additionals.loading,state.firebase.loading]),
});

export default connect(
  mapStateToProps,
  null
)(FormCardDatasetAutoComplete);
