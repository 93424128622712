import React, { forwardRef, useState, useEffect, useContext, useCallback } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { groupBy, sortBy, isEmpty } from 'lodash';

import { useNavigate } from "react-router-dom";

//import 'assets/calllog.css';

import { grey } from '@mui/material/colors';

import { usePhone } from 'Providers';

import { calllog_action, CalllogItems } from 'WEBRTC/calllogManager';

import { TransitionGroup } from 'react-transition-group';

// import { withCallogContext } from 'Contexts/CalllogContext';

import cx from "classnames";

import {
  Box,
  Button,
  Grid,
  Divider,
  Stack,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  ListSubheader,
  ListItemAvatar,
  Avatar,
  Chip,
  Fab,
  Fade,
  Collapse,
  Typography,
  Badge,
  Alert,
  AlertTitle,
} from '@mui/material';


import { CustomAvatar } from "components/CustomAvatars";

import RegularButton from "components/CustomButtons";
import { ListItemButtonCalllog } from "components/CustomLists";

import DeleteIcon from '@mui/icons-material/Delete';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallIcon from '@mui/icons-material/Call';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import ContactsIcon from '@mui/icons-material/Contacts';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import EmailIcon from '@mui/icons-material/Email';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';

// import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
// import CallMissedIcon from '@mui/icons-material/CallMissed';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';
//import AvatarWithLineState from 'components/CustomAvatars';

//import ErrorBoundary from 'components/ErrorBoundary';
import {ErrorBoundary} from 'react-error-boundary'
import DrawerSection from '../DrawerSection';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { CalllogListItemButton } from 'components/CustomLists';

import useSingleAndDoubleClick from 'Contexts/useSingleAndDoubleClick';
import CalllogContext from 'Contexts/CalllogContext';

import { ContactPopoverClass } from 'Classes';
import { ContactPopover } from '../Popovers';

import { useDropDown, withDropDown } from 'Providers';

import moment from 'Helpers/Momentjs';

const useStyles = makeStyles(createStyles(theme => ({
  colorWhite: {
    color: 'white',
  },
  success: {
    background: 'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))',
    boxShadow: 'rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.62',
  },
  danger: {
    boxShadow: '0 2px 2px 0 rgb(244 67 54 / 14%), 0 3px 1px -2px rgb(244 67 54 / 20%), 0 1px 5px 0 rgb(244 67 54 / 12%)',
    backgroundColor: '#f44336',
  },
  warning: {
    boxShadow: '0 2px 2px 0 rgb(255 152 0 / 14%), 0 3px 1px -2px rgb(255 152 0 / 20%), 0 1px 5px 0 rgb(255 152 0 / 12%)',
    backgroundColor: '#ff9800',
  }
})));

function parseItems(array) {
  return groupBy(array, function (o) {
    // return moment(o.time).calendar();
    return moment(o.time).format("YYYY-MM-DD");
  });
}

function format(seconds) {
  return seconds > 3600 ? "H[h ]m[min ]s[s]" : "m[min ]s[s]";
}

const startOfDay = moment().startOf("day");

const ListSectionComponent = ({  data, subheader, section, items, session, ...props }) => {
  let mounted = true;
  const ref = React.createRef();
  const { handleClick } = useDropDown();
  const { invite, transfer, hasActiveSession } = usePhone();
  const navigate = useNavigate();

  useEffect(() => {
    return () => mounted = false
  }, [])

  const handleClicks = useSingleAndDoubleClick(onSingleClick, onDoubleClick);

  function onSingleClick(event, item, elementId) {
    if(!item || !mounted) return;
    //console.log('onSingleClick', item)
    if(typeof handleClick === 'function') handleClick(item)(document.getElementById(elementId)); // opens dropdownmenu
  }

  function onDoubleClick(event, item) {
    if(!item || !mounted) return;
    const { contact, caller_id_name, caller_id_number } = item;

    //console.log('onDoubleClick', item)

    const mediaConstaits = undefined;

    if(hasActiveSession()) {
      transfer(caller_id_number)(event);
    } else {
      //invite(item.remote_identity._uri._user, mediaConstaits) // make new call
      invite(caller_id_number, contact, mediaConstaits) // make new call
    }
  }

  return (
    <TransitionGroup>
      <Collapse>
        <React.Fragment>
          {
            //subheader
          }
          <Divider sx={{ fontWeight: 600, margin: '10px 0px' }}>{subheader}</Divider>
          {
            items.map((item, index) => {

              let contactItem = new ContactPopoverClass({ caller_id_name: item.remote_identity._display_name, caller_id_number: item.remote_identity._uri._user , ...item });
              const { contact, caller_id_name, caller_id_number } = contactItem;

              const user = {};

              const { time, remote_identity, local_identity, disposition, duration, direction, start_time, end_time } = item;
              const { _display_name = null, _uri = {}} = remote_identity || {};

              const elementId = `CallLogComponent-${section}-ListItem-${index}`;

              /*
                hmm, saataa bugaa avatar!
              */

              return (
                <React.Fragment key={index}>
                  <ListItem id={elementId} alignItems="flex-start" dense disablePadding>
                    <CalllogListItemButton selected={disposition !== 'Answered'} onClick={event => handleClicks(event, contactItem, elementId)}>
                      <ListItemAvatar style={{ minWidth: '40px' }}>
                        <IconButton aria-label="add" size="small" onClick={(e) => navigate(`/contacts/${contact.id}`, { state: contact })}>
                          <CustomAvatar name={caller_id_name} useDefault={contact ? true : false} src={contact?.image} />
                        </IconButton>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                            {
                              caller_id_name
                                ? caller_id_name
                                : caller_id_number
                            }
                          </Typography >
                        }
                        secondary={caller_id_name ? caller_id_number : undefined}
                      />
                      <Stack direction="row" spacing={2} flexDirection='column' justifyContent='flex-end'>
                        <Box display='flex' justifyContent='flex-end'>
                          <small>{moment(time).format('HH:mm')}</small>
                          <span style={{ marginLeft: "4px" }}>
                            {
                              direction === 'outgoing'
                                ? <CallMadeIcon
                                    fontSize="13"
                                    sx={{
                                      color: disposition === 'Answered' ? '#66bb6a' : '#ef5350'
                                    }}
                                  />
                                : <CallReceivedIcon
                                    fontSize="13"
                                    sx={{
                                      color: disposition === 'Answered' ? '#66bb6a' : '#ef5350'
                                    }}
                                  />
                            }
                          </span>
                        </Box>
                        {
                          (start_time && end_time) && <small>{moment(moment(end_time).diff(moment(start_time))).format(format(Math.floor(duration / 1000)))}</small>
                        }
                      </Stack>
                    </CalllogListItemButton>
                  </ListItem>
                </React.Fragment>
              )
            })
          }
        </React.Fragment>
      </Collapse>
    </TransitionGroup>
  )
};


const ErrorFallback = ({error, resetErrorBoundary}) => {
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      <small>error in display of this component </small>
      — <Button
          color="error"
          onClick={(event) => {
            calllog_action({ type: 'CALLLOG_FIX' })
            resetErrorBoundary(event)
          }}
        >
          <strong>Reset!</strong>
        </Button>
    </Alert>
  )
}

const CallLogComponent = (props) => {
  const classes = useStyles();

  return (
    <CalllogItems>
      {
        (calls) => {
          const data = Object.entries(parseItems(sortBy(calls, ['time']).reverse()));
          return (
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              resetKeys={[data]}
            >
              <div>
                <List
                  sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    //maxHeight: '88vh',
                    '& ul': { padding: 0 },
                  }}
                  dense
                  disablePadding
                  subheader={
                    <ListSubheader disableGutters style={{ padding: '4px', display: 'flex', justifyContent: 'center' }}>
                      {
                        data.length > 0
                          ? <HelperTooltipBootstrap
                              title="Removes all"
                              placement='bottom'
                              arrow
                            >
                              <span>
                                <RegularButton color='github' round size='sm' onClick={(event) => calllog_action({ type: 'CALLLOG_CLEAR' })}>
                                  <DeleteIcon />
                                </RegularButton>
                              </span>
                            </HelperTooltipBootstrap>
                          : 'Call Log Empty!'
                      }
                    </ListSubheader>
                  }
                >

                    {
                      data.map(([key, values], index) => {
                        return (
                          <ListSectionComponent
                            key={index}
                            section={key}
                            subheader={moment(key).calendar()}
                            items={values}
                            {...props}
                          />
                        )
                      })
                    }
                </List>
                <Divider />
              </div>
            </ErrorBoundary>
          )
        }
      }
    </CalllogItems>
  );
}

export default withDropDown(CallLogComponent, ContactPopover);
