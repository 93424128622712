import xml2js from 'xml2js';
import _ from 'lodash';
var parser2 = new xml2js.Parser({ parseBooleans: true, mergeAttrs: false });
var builder = new xml2js.Builder({ headless: true });

export const parse2ObjectPromise = async (string) => {
  try {
    let json = await parser2.parseStringPromise(string);
    json.extension.condition =  _.chunk(json.extension.condition, 2);
    return json;
  } catch (err) {
    console.log(err);
  }
};

export const parse2XMLPromise = async (json) => {
  try {
    json.extension.condition = _.flatten(json.extension.condition);
    return builder.buildObject(json);
  } catch (err) {
    console.log(err);
  }
};


export const parse2Object = async (xml) => {
  try {
    return await parser2.parseStringPromise(xml);
  } catch (err) {
    console.log(err);
  }
};

export const parse2XML = async (json) => {
  try {
    return builder.buildObject(json);
  } catch (err) {
    console.log(err);
  }
};

export function parse2XMLSyncronous(json) {
  try {
    return builder.buildObject(json); // output xml
  } catch (err) {
    console.log(err);
  }
  return;
};

export function parse2ObjectSyncronous(str, cb) {
  try {
    parser2.parseString(str, function (err, result) {
      if(err) console.log(err);
      cb(result); // output object
    });
  } catch (err) {
    console.log(err);
  }
  return;
};

export async function parse2ObjectAsync(string) {
  try {
    return await parser2.parseStringPromise(string);
  } catch (err) {
    console.log(err);
  }
  return;
};
