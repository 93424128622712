import {
  EXTENSIONS_PENDING,
  EXTENSIONS_SUCCESS,
  RINGGROUP_EXTENSIONS_PENDING,
  RINGGROUP_EXTENSIONS_SUCCESS
} from 'store/types';

import { onError, enqueueSnackbar } from 'store/Notifier/actions';

import { combineLatest, Subject } from "rxjs";
import { isEmpty } from "lodash";

import { checkNull, firebaseNulls } from 'devtools/Helpers';
import { sweetalerts } from 'sweetalerts';

// export const extensions_Actions = () => {
//   return async (dispatch, getState, firebase) => {
//     try {
//       await firebase.authStatePromise;
//       dispatch({ type:  EXTENSIONS_PENDING });
//
//       firebase.extensions().on('value',
//         snapshot => {
//           dispatch({ type: EXTENSIONS_SUCCESS, payload: asCollection(snapshot.val()) });
//         },
//         err => {
//           throw err;
//         }
//       );
//     } catch (err) {
//       console.log(err);
//       onError(dispatch, err);
//     };
//   }
// }

// export const extensions_with_user_Actions = () => {
//   return async (dispatch, getState, firebase) => {
//
//     const observable1 = new Subject();
//     const observable2 = new Subject();
//
//     try {
//       const joined = combineLatest(observable1, observable2);
//
//       joined.subscribe(
//         value => {
//           dispatch({ type:  EXTENSIONS_PENDING });
//
//           const rest = value[0].filter(item => !item.userid)
//           const values = _.values(_.merge(_.keyBy(value[0], 'userid'), _.keyBy(value[1], 'userid')));
//
//           const dataaa = _.uniqBy([...values, ...rest], function (e) {
//             return e.extension;
//           });
//
//           dispatch({ type: EXTENSIONS_SUCCESS, payload: dataaa });
//         },
//         err => {
//           throw err;
//         }
//       );
//
//       await firebase.authStatePromise;
//
//       firebase.extensions().on('value',
//         snapshot => {
//           observable1.next(asCollection(snapshot.val(), 'id'));
//         },
//         err => {
//           throw err;
//         }
//       );
//
//       firebase.users().on('value',
//         snapshot => {
//           observable2.next(asCollection(snapshot.val(), 'userid'));
//         },
//         err => {
//           throw err;
//         }
//       );
//     } catch (err) {
//       onError(dispatch, err)
//     }
//   };
// };

// export const firestore_extensions_Actions = () => {
//   return async (dispatch, getState, firebase) => {
//     dispatch({ type: FIRESTORE_EXTENSIONS_PENDING });
//
//     try {
//       await firebase.authStatePromise;
//       await firebase.firestore_extensions().onSnapshot(
//         async snapshots => {
//           let snapshot = await Promise.all(snapshots.docs.map(
//             async a => {
//               const data = a.data();
//               const id = a.id;
//               const ref = a.ref;
//
//               const getChildDocument = async function (childRef) {
//                 if(!childRef) return {};
//
//                 let snapshot = await childRef.get();
//                 const data = snapshot.data();
//                 const id = snapshot.id;
//                 const ref = snapshot.ref;
//                 return { id, ref, ...data };
//               }
//
//               const user = await getChildDocument(data?.userRef);
//               return { id, ref, ...data, user };
//             }
//           ));
//
//           console.log(snapshot);
//
//           dispatch({ type: FIRESTORE_EXTENSIONS_SUCCESS, payload: snapshot });
//         },
//         err => {
//           throw err;
//         }
//         // snapshots => {
//         //   let snapshot = snapshots.docs.map(
//         //     a => {
//         //       const data = a.data();
//         //       const id = a.id;
//         //       const ref = a.ref;
//         //       return { id, ref, ...data };
//         //     }
//         //   );
//         //
//         //   dispatch({ type: FIRESTORE_EXTENSIONS_SUCCESS, payload: snapshot });
//         // },
//         // err => {
//         //   throw err;
//         // }
//       );
//     } catch (err) {
//       onError(dispatch, err);
//     };
//   }
// };

// export const firestore_ringgroup_extensions_Actions = id => {
//   return async (dispatch, getState, firebase) => {
//     dispatch({ type:  FIRESTORE_RINGGROUP_EXTENSIONS_PENDING });
//
//     try {
//       await firebase.authStatePromise;
//       await firebase.firestore_ringgroups().doc(id).collection('extensions').onSnapshot(
//         async snapshots => {
//           let snapshot = await Promise.all(snapshots.docs.map(
//             async a => {
//               const data = a.data();
//               const id = a.id;
//               const ref = a.ref;
//
//               const getChildDocument = async function (childRef) {
//                 if(!childRef) return {};
//
//                 let snapshot = await childRef.get();
//                 const data = snapshot.data();
//                 const id = snapshot.id;
//                 const ref = snapshot.ref;
//                 return { id, ref, ...data };
//               }
//
//               const extension = await getChildDocument(data?.extensionRef);
//               const user = await getChildDocument(extension?.userRef);
//               return { id, ref, ...data, extension, user };
//             }
//           ));
//
//           dispatch({ type:  FIRESTORE_RINGGROUP_EXTENSIONS_SUCCESS, payload: snapshot });
//         },
//         err => {
//           throw err;
//         }
//       );
//     } catch (err) {
//       console.log(err);
//       onError(dispatch, err);
//     };
//   }
// };

// export const ringgroup_extensions_Actions = uid => {
//   return async (dispatch, getState, firebase) => {
//     let observable1 = new Subject();
//     let observable2 = new Subject();
//     let observable3 = new Subject();
//
//     try {
//       const joined = combineLatest(observable1, observable2, observable3);
//
//       joined.subscribe(
//         value => {
//           dispatch({ type:  RINGGROUP_EXTENSIONS_PENDING });
//
//           // const mergedWithExtension = _.values(_.merge(_.keyBy(value[0], 'extension_uuid'), _.keyBy(value[1], 'extension_uuid'))).filter(item => item.rgextId);
//           // const mergedWithUser = _.values(_.merge(_.keyBy(mergedWithExtension, 'userid'), _.keyBy(value[2], 'accountId')));
//
//           function customizer(objValue, srcValue) {
//             if(!objValue) return false;
//             return {...Object.assign(objValue, srcValue)};
//           }
//
//           const mergedWithExtension = _.compact(_.values(_.mergeWith(_.keyBy(value[0], 'extension_uuid'), _.keyBy(value[1], 'extension_uuid'), customizer))).filter(item => item.rgextId);
//           const nouserid = mergedWithExtension.filter(item => !item.hasOwnProperty('userid'));
//           const mergedWithUser = _.compact(_.values(_.mergeWith(_.keyBy(mergedWithExtension, 'userid'), _.keyBy(value[2], 'accountId'), customizer)));
//
//           const dataaa = _.uniqBy([...mergedWithUser, ...nouserid], function (e) {
//             return e.extension;
//           });
//
//           dispatch({ type:  RINGGROUP_EXTENSIONS_SUCCESS, payload: dataaa });
//         },
//         err => {
//           throw err;
//         }
//       );
//
//       await firebase.authStatePromise;
//       firebase.ringgroup_extensions().orderByChild('ring_group_uuid').equalTo(uid).on('value',
//         snapshot => {
//           observable1.next(asCollection(snapshot.val(), 'rgextId'));
//         },
//         err => {
//           throw err;
//         }
//       );
//
//       firebase.extensions().on('value',
//         snapshot => {
//           observable2.next(asCollection(snapshot.val(), 'extId'));
//         },
//         err => {
//           throw err;
//         }
//       );
//
//       firebase.users().on('value',
//         snapshot => {
//           observable3.next(asCollection(snapshot.val(), 'accountId'));
//         },
//         err => {
//           throw err;
//         }
//       );
//
//     } catch (err) {
//       console.log(err);
//       onError(dispatch, err);
//     };
//   }
// };

// export const firestore_ringgroup_extension_toggle_Actions = (ringgroup, state, extensionsArray, cb) => {
//   return async (dispatch, getState, firebase) => {
//     const collectionRef = ringgroup.ref.collection('extensions');
//
//     const { ring_group_uuid, ring_group_call_timeout, domain_uuid } = ringgroup;
//     let loadingKey;
//
//     try {
//       await Promise.all(
//         extensionsArray.map(async(extension) => {
//           if(state) {
//             loadingKey = enqueueSnackbar(dispatch, 'LOADING')
//
//             await firebase.firestoreTransaction(collectionRef.doc(), (t, doc) => {
//               t.set(doc.ref, {
//                 destination_delay: 0,
//                 destination_number: extension.extension,
//                 destination_prompt: null,
//                 destination_timeout: ring_group_call_timeout || 60,
//                 domain_uuid: domain_uuid,
//                 extensionRef: extension.ref,
//                 extension_uuid: extension.extension_uuid,
//                 isActive: false,
//                 ring_group_uuid: ring_group_uuid,
//                 ...firebase.customFirestoreData
//               })
//               return;
//             });
//
//           } else {
//             await sweetalerts.confirm({ text: "Hiding extensions will result in lost Ring Group settings for these extensions!", confirmButtonText: 'Yes, execute!' });
//
//             loadingKey = enqueueSnackbar(dispatch, 'LOADING')
//
//             await firebase.firestoreTransaction(extension.ref, async (t, doc) => {
//               await collectionRef.where('extension_uuid', '==', extension.extension_uuid).get().then(async(querySnapshot) => {
//                 if(querySnapshot.size === 0) throw '0 queries found';
//                 let docs = querySnapshot.docs;
//                 for (let doc of docs) {
//                   console.log(doc.ref);
//                   await t.delete(doc.ref);
//                 }
//                 return;
//               });
//               return;
//             });
//           }
//
//         })
//       );
//
//       enqueueSnackbar(dispatch, 'SUCCESS', loadingKey)
//       cb();
//     } catch (err) {
//       if (err !== 'undefined') onError(dispatch, err);
//     }
//   }
// }

export const ringgroup_extension_toggle_Actions = (ringgroup, state, extensionsArray, cb) => {
  return async (dispatch, getState, firebase) => {
    const { ring_group_uuid, ring_group_call_timeout, domain_uuid } = ringgroup;
    let loadingKey;

    try {
      if(state) {
        loadingKey = enqueueSnackbar(dispatch, 'LOADING')
        await Promise.all(
          extensionsArray.map(async(extension) => {
            const key = firebase.ringgroup_extensions().push().key;
            console.log(key)
            await firebase.transaction(firebase.ringgroup_extensions().child(key), (data) => {
              return { ...Object.assign(data || {}, {
                destination_delay: 0,
                destination_number: Number(extension.extension),
                destination_prompt: 'null',
                destination_timeout: checkNull(ring_group_call_timeout) || 60,
                domain_uuid: domain_uuid,
                extension_uuid: extension.extension_uuid,
                isActive: false,
                ring_group_uuid: ring_group_uuid
              })}
            });
          })
        )
      } else {
        await sweetalerts.confirm({ text: "Hiding extensions will result in lost Ring Group settings for these extensions!", confirmButtonText: 'Yes, execute!' });
        loadingKey = enqueueSnackbar(dispatch, 'LOADING')

        await Promise.all(
          extensionsArray.map(async(extension) => {
            await firebase.transaction(firebase.ringgroup_extensions().child(extension.rgextId), (data) => {
              return null;
            });
          })
        )
      }

      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey)
      cb();
    } catch (err) {
      if (err !== undefined) onError(dispatch, err);
    }
  }
}

export const updateExtensionsAction = (newData) => {
  return async (dispatch, getState, firebase) => {
    const loadingKey = enqueueSnackbar(dispatch, 'LOADING');

    try {
      if(!newData) return;
      await firebase.authStatePromise;
      await firebase.transaction(firebase.extensions(), (data) => {
        return {...Object.assign(data || {}, firebaseNulls(newData, true))};
      });
      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey)
    } catch (err) {
      console.log(err);
      onError(dispatch, err);
    }
  }
};

export const deleteExtensionsAction = (dataArray) => {
  return async (dispatch, getState, firebase) => {
    const object = {};

    try {
      await sweetalerts.confirm();

      const loadingKey = enqueueSnackbar(dispatch, 'LOADING');

      for(const { id, ...value } of dataArray) {
        object[id] = null;
      }

      await firebase.authStatePromise;
      await firebase.transaction(firebase.extensions(), (data) => {
        return {...Object.assign(data || {}, object)};
      });
      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey);
      return true;
    } catch (err) {
      if(err) {
        console.log(err);
        onError(dispatch, err);
      }
      return false;
    }
  }
};


export const ringgroup_extension_update_Actions = (id, newData, callback) => {
  return async (dispatch, getState, firebase) => {
    const loadingKey = enqueueSnackbar(dispatch, 'LOADING')

    // console.log(id, newData, firebase.ringgroup_extensions().child(id))

    try {
      await firebase.authStatePromise;
      await firebase.transaction(firebase.ringgroup_extensions().child(id), (data) => {
        return {...Object.assign(data || {}, newData)};
      });
      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey)
    } catch (err) {
      onError(dispatch, err);
    } finally {
      if(callback) setTimeout(() => callback(), 1000);
    };
  }
};

// export const firestore_ringgroup_extension_update_Actions = (ref, data, cb) => {
//   return async (dispatch, getState, firebase) => {
//
//     const loadingKey = enqueueSnackbar(dispatch, 'LOADING')
//
//     try {
//       await firebase.firestoreTransaction(ref, async (t, doc) => {
//         await t.update(doc.ref, Object.assign(doc.data() || {}, { ...data, ...firebase.customFirestoreData }));
//         return;
//       });
//
//       enqueueSnackbar(dispatch, 'SUCCESS', loadingKey)
//     } catch (err) {
//       onError(dispatch, err);
//     } finally {
//       setTimeout(() => cb(), 1000);
//     };
//   }
// };
//
// export const firestore_extensions_update_Actions = (id, data) => {
//   return async (dispatch, getState, firebase) => {
//     const loadingKey = enqueueSnackbar(dispatch, 'LOADING')
//     const ref = firebase.firestore_extensions().doc(id);
//
//     try {
//       await firebase.firestoreTransaction(ref, async (t, doc) => {
//         await t.update(doc.ref, Object.assign(doc.data() || {}, { ...data, ...firebase.customFirestoreData }));
//         return;
//       });
//
//       enqueueSnackbar(dispatch, 'SUCCESS', loadingKey)
//     } catch (err) {
//       onError(dispatch, err);
//     };
//   }
// };


// export const extensions_update_Actions = (id, data) => {
export const extensions_update_Actions = (data) => {
  return async (dispatch, getState, firebase) => {
    const loadingKey = enqueueSnackbar(dispatch, 'LOADING')

    try {
      if(isEmpty(data)) throw 'Property empty!';
      // await firebase.transaction(firebase.extensions().child(id), (snapshot) => {
      //   return {...Object.assign(snapshot || {}, data)};
      // });
      await firebase.authStatePromise;
      await firebase.transaction(firebase.extensions(), (oldData) => {
        return {...Object.assign(oldData || {}, firebaseNulls(data, true))};
      });
      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey)
    } catch (err) {
      onError(dispatch, err);
    };
  }
};

//const asCollection = (object, idProp = 'id') => Object.keys(object || {}).map(key => ({ ...object[key], [idProp]: key}));
