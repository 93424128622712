import React, { createContext, useEffect, useReducer } from 'react';
// import { makeCollection } from 'devtools/Helpers';
//import { friends_observable } from '../Observables';
import { friends_observable } from 'Observables';

const FriendsContext = createContext();

const INITIALSTATE = {};

function reducer(state = INITIALSTATE, action) {
  switch (action.type) {
    case 'FRIENDS_ADD': {
      return Object.assign(state, action.payload);
    }
    case 'FRIENDS_UPDATE': {
      Object.assign(state, action.payload);
      // const index = state.findIndex((element) => element.id === action.payload.id);
      // state.splice(index, 1, action.payload);
      return state;
    }
    default:
      return state;
  }
}



// rest sessions setSessions
const withFriendsContext = (Component) => (props) => {
  const [friends, dispatch] = useReducer(reducer, INITIALSTATE);
  let mounted = true, subscription;

  useEffect(() => {
    if(mounted && friends_observable) {
      subscription = friends_observable.subscribe(
        value => {
          dispatch({ type: 'FRIENDS_UPDATE', payload: value})
        },
        err => console.log(err)
      );
    }
    return () => {
      if(mounted && friends_observable) {
        subscription.unsubscribe();
        mounted = false;
      }
    }
  }, [])


  return (
    <FriendsContext.Provider value={{ friends, dispatch }}>
      <Component {...props} />
    </FriendsContext.Provider>
  )
};

export {
  FriendsContext as default,
  withFriendsContext
}
