let notifycations = [];
let hasPermissions = getPermissions();

export let WINDOW_FOCUSED = false;
export let WINDOW_HAS_HAD_FOCUS = false;

class DesktopNotifycation {
  //https://developer.mozilla.org/en-US/docs/Web/API/Notification
  constructor(title = "Title", options = {}, onclick) {
    if (WINDOW_FOCUSED) {
      return;
    } else if (!Notification) {
      alert('Desktop notifications not available in your browser. Try Chromium.');
      return;
    }

    if (!hasPermissions && Notification.permission !== "granted") {
      hasPermissions = getPermissions();
    } else {
      this.notification = new Notification(title, options);

      if(onclick instanceof Function) {
        this.notification.onclick = onclick
      }
    }
    return this;
  }
}

export function notifyer(title = "Title", options = {}, onclick) {
  let notification;
  if (WINDOW_FOCUSED) {
    return;
  } else if (!Notification) {
    console.error('Desktop notifications not available in your browser. Try Chromium.');
    //alert('Desktop notifications not available in your browser. Try Chromium.');
    return;
  }

  if (!hasPermissions && Notification.permission !== "granted") hasPermissions = getPermissions();

  notification = new Notification(title, options);
  if(onclick instanceof Function) notification.onclick = onclick;
  return notification;
}


function getPermissions() {
  if (Notification.permission !== "granted") {
    Notification.requestPermission();
    return true;
  } else {
    return true;
  }
}

export const createDesktopNotifycation = (title = 'Anonymous', options = {}, onclick) => {
  if(!hasPermissions) getPermissions();
  let instance = new DesktopNotifycation(
    title,
    Object.assign(
      options,
      {
        icon: 'img/Call-Center-Icon.png',
        body: "Saapuva puhelu, vastaa painamalla tätä",
      }
    ),
    onclick
  )

  if(instance?.notification) notifycations.push(instance);
  return;
}

export const closeDesktopNotifycation = () => {
  try {
    if(notifycations && Array.isArray(notifycations) && notifycations.length > 0) {
      let item = notifycations.shift();
      item.notification.close();
    }
  } catch (err) {
    console.warn(err);
  }
}

window.addEventListener('focus', () => [WINDOW_FOCUSED, WINDOW_HAS_HAD_FOCUS] = [true, true]);
window.addEventListener('blur', () => WINDOW_FOCUSED = false);
