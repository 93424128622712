import * as React from 'react';

import { v4 as uuidv4 } from 'uuid';

import {
  Fade,
  Chip,
  Button,
  Grid,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CallIcon from '@mui/icons-material/Call';
import ExtensionIcon from '@mui/icons-material/Extension';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import {
  useUusiDialog
} from 'Providers';

import { sleep } from 'Helpers';

import { useForm, Controller, FormProvider, useFormContext, useFieldArray } from "react-hook-form";

const INITIALVALUES = Object.freeze({
  id: uuidv4(),
  number: '',
  type: 'mobile',
})

const TelephoneFormDialogNew = (props) => {
  const { data: { number, submitCallback, ...data }, open, handleClose } = useUusiDialog();

  const methods = useForm({
    defaultValues: INITIALVALUES
  });

  const { handleSubmit, control, watch, reset, setValue, formState: { errors, isDirty, isSubmitting }} = methods;

  const onSubmit = async (data, e) => {
    try {
      console.log('onSubmit', data);
      await sleep(300); // display loader
      submitCallback(data);
      reset(INITIALVALUES) // reset form state!
      handleClose(e); // close on success!
    } catch (e) {
      console.warn('onSubmit', e);
    }
  };

  return open && (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>
            Telephone Number <Chip size='small' color='primary' label='New' />
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container alignItems='center' justifyContent='center' spacing={4}>
              <Grid item xs={3}>
                <Controller
                  name="type"
                  control={control}
                  rules={{
                    required: true
                  }}
                  render={({ field }) => {
                    return (
                      <TextField
                        id="type"
                        select
                        label="Device Type"
                        variant="standard"
                        fullWidth
                        helperText=" "
                        {...field}
                        SelectProps={{
                          renderValue: (value) => value // removes icon
                        }}
                        //helperText="Please select your currency"
                      >
                        <MenuItem value="extension">
                          <ListItemIcon>
                            <ExtensionIcon fontSize="inherit" />
                          </ListItemIcon>
                          <ListItemText primary="Extension" />
                        </MenuItem>
                        <MenuItem value="mobile">
                          <ListItemIcon>
                            <PhoneAndroidIcon fontSize="inherit" />
                          </ListItemIcon>
                          <ListItemText primary="Mobile" />
                        </MenuItem>
                        <MenuItem value="business">
                          <ListItemIcon>
                            <CallIcon fontSize="inherit" />
                          </ListItemIcon>
                          <ListItemText primary="Business" />
                        </MenuItem>
                      </TextField>
                    )
                  }}
                />
              </Grid>

              <Grid item xs={8}>
                <Controller
                  name="number"
                  control={control}
                  rules={{
                    required: true
                  }}
                  render={({ field }) => (
                    <TextField
                      autoFocus
                      margin="dense"
                      label="Telephone Number"
                      type="text"
                      fullWidth
                      helperText="+358441234567"
                      variant="standard"
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton type='submit' loading={isSubmitting} onClick={handleSubmit(onSubmit)}>
              Save
            </LoadingButton>
            <Button variant="text" size='small' color="error" onClick={handleClose} >Cancel</Button>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  )
}

export default TelephoneFormDialogNew
