import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import PropTypes from "prop-types";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import MusicNoteIcon from  '@mui/icons-material/MusicNote';
import AccountTreeIcon from  '@mui/icons-material/AccountTree';

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Divider, Box, Button, Select, InputLabel, FormControl, FormControlLabel, Radio, RadioGroup, InputAdornment, TextField, ListItemAvatar, ListItemSecondaryAction, Avatar } from '@mui/material';

import { getType } from 'devtools/helper';

// import { announcementsActions } from 'store/announcements/actions';
import { ringgroups_Actions } from 'store/ringgroups/actions';

const DialogRaw = ({ announcementsLoading, ringgroupsLoading, announcements, ringgroups, getAnnouncements, getRinggroups, ...props}) => {
  const { onClose, classes, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp !== 'undefined' ? valueProp : '');
  const radioGroupRef = useRef(null);

  useEffect(() => {
    if (announcementsLoading === null) {
      getAnnouncements();
    }
  }, [announcements, announcementsLoading]);

  useEffect(() => {
    if (ringgroupsLoading === null) {
      getRinggroups();
    }
  }, [ringgroups, ringgroupsLoading]);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = event => onClose(event);

  const handleOk = event => onClose(event, value);

  const handleChange = event => setValue(event.target.value);

  return (
    <Dialog
      disableBackdropClick={false}
      disableEscapeKeyDown
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onClose={(e) => onClose(e)}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Select Destination</DialogTitle>
      <DialogContent dividers>
        <Box paddingX={5}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <MusicNoteIcon />
              </CardIcon>
              <Box color="text.primary"><h5 className={classes?.cardIconTitle}>Announcements</h5></Box>
            </CardHeader>
            <CardBody>
              <RadioGroup
                ref={radioGroupRef}
                aria-label="jointradio"
                name="jointradio"
                value={value}
                onChange={handleChange}
              >
                <List dense>
                  {
                  //   announcements ? announcements.map(option => (
                  //   <ListItem button key={option.recording_uuid}>
                  //     <ListItemAvatar>
                  //       <Avatar>
                  //         <MusicNoteIcon />
                  //       </Avatar>
                  //     </ListItemAvatar>
                  //     <ListItemText
                  //       primary={<span>{option.recording_description}</span>}
                  //       secondary={<small>{option.recording_filename}</small>}
                  //     />
                  //     <ListItemSecondaryAction>
                  //       <FormControlLabel
                  //         value={option.recording_filename}
                  //         key={option.recording_filename}
                  //         control={<Radio />}
                  //       />
                  //     </ListItemSecondaryAction>
                  //   </ListItem>
                  // )) : <></>
                }
                </List>
              </RadioGroup>
            </CardBody>
          </Card>
        </Box>

        <Box paddingX={5}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AccountTreeIcon />
              </CardIcon>
              <Box color="text.primary"><h5 className={classes?.cardIconTitle}>Ring Groups</h5></Box>
            </CardHeader>
            <CardBody>
              <RadioGroup
                ref={radioGroupRef}
                aria-label="jointradio"
                name="jointradio"
                value={value}
                onChange={handleChange}
              >
                <List dense>
                  {ringgroups.map(ringgroup => (
                    <ListItem button key={ringgroup.ring_group_uuid}>
                      <ListItemAvatar>
                        <Avatar>
                          <AccountTreeIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={<span>{ringgroup.ring_group_name}</span>}
                        secondary={<small>{ringgroup.ring_group_extension}</small>}
                      />
                      <ListItemSecondaryAction>
                        <FormControlLabel
                          value={ringgroup.dialplan_destination}
                          key={ringgroup.dialplan_destination}
                          control={<Radio />}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </RadioGroup>
            </CardBody>
          </Card>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

const DestinationPicker = ({ onChange, fullHeight, selected, ...props }) => {
  const classes = {};
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(selected !== 'undefined' ? selected : '');

  useEffect(() => {
    if(selected) setValue(selected);
  }, [selected]);

  const openDialog = () => {
    setOpen(true);
  };

  const handleClose = (event, newValue) => {
    setOpen(false);
    if (newValue) {
      setValue(newValue);
      onChange(newValue);
    }
  };

  return (
    <>
      <Card>
        <CardHeader color="info" icon style={{ alignSelf: 'center' }}>
          <CardIcon color="info">
            <Box>
              Destination Picker
            </Box>
          </CardIcon>
        </CardHeader>
        <CardBody>
          <FormControl fullWidth>
            <TextField
              label={getType(value)}
              className={classes?.textField}
              margin="normal"
              variant="outlined"
              value={value || ''}
              onClick={openDialog}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {
                      {
                        'Ringgroup': <AccountTreeIcon />,
                        'Announcement': <MusicNoteIcon />,
                        'Unknown': <MusicNoteIcon />
                      }[getType(value) || 'Unknown']
                    }
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </CardBody>
      </Card>

      <ConnectedDialogRaw
        classes={{
          paper: classes?.paper
        }}
        id="jointradio-menu"
        keepMounted
        open={open}
        onClose={handleClose}
        value={value || ''}
      />
    </>
  );
}

const mapStateToProps = state => ({
  announcements: state.announcements_State.announcements || [],
  announcementsLoading: state.announcements_State.loading,
  ringgroups: state.ringgroups_State.ringgroups || [],
  ringgroupsLoading: state.ringgroups_State.loading
});

const mapDispatchToProps = dispatch => ({
  getAnnouncements: () => {
    //dispatch(announcementsActions()) 
  },
  getRinggroups: () => { dispatch(ringgroups_Actions()) }
});

// first call: returns a hoc that you can use to wrap any component
const connectStore = connect(
  mapStateToProps,
  mapDispatchToProps
);

//const DestinationPicker = connectStore(Picker)
const ConnectedDialogRaw = connectStore(DialogRaw)
export default DestinationPicker;
