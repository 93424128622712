import {
  PASSWORD_RESET_ACTIONS_IDLE,
  PASSWORD_RESET_ACTIONS_PENDING,
  PASSWORD_RESET_ACTIONS_SUCCESS,
  PASSWORD_RESET_ACTIONS_FAILURE,
} from 'store/types';

import { onError, enqueueSnackbar } from 'store/Notifier/actions';

export const passwordResetAction = email => {
  return async (dispatch, getState, firebase) => {
    if(!email) return;

    dispatch({ type: PASSWORD_RESET_ACTIONS_PENDING });

    try {
      await firebase.authStatePromise;
      await firebase.auth.sendPasswordResetEmail(email);
      dispatch({ type: PASSWORD_RESET_ACTIONS_SUCCESS, payload: 'Email has been send, if the account exist!' });

      enqueueSnackbar(dispatch, {
        message: 'Email has been send!',
        options: {
          variant: 'success'
        }
      })
    } catch (err) {
      onError(dispatch, err);
      dispatch({ type: PASSWORD_RESET_ACTIONS_FAILURE, payload: err });
    } finally {
      dispatch({ type: PASSWORD_RESET_ACTIONS_IDLE });
    }
  }
};
