import {
  FILTERS_DATA,
  CALLS_CONNECTING,
  CALLS_DATA,
} from 'store/types';

import {
  filters_observable,
} from 'store/firebase/Observables';

import Moment from "devtools/momentjs";

import { setFormValues } from 'store/form/reducer';

import { makeCollection, sortByTime, checkNull, parseTypes, compare } from 'devtools/Helpers';

import { onError, enqueueSnackbar } from 'store/Notifier/actions';

export const applyFilter = (filter, data) => {
  return async (dispatch, getState, firebase) => {
    try {
      const newData = {
        [filter]: {
          ...getState().filters[filter],
          ...data
        }
      }

      dispatch({ type: FILTERS_DATA, payload: newData });
      filters_observable.next(newData); // saattaa bugaa

      // reset filter forms
      switch (filter) {
        case 'calllog':
          const { charts, table, dirty, ...rest } = newData.calllog;
          dispatch(setFormValues('CalllogTableFilters', { ...table, ...rest }))
          dispatch(setFormValues('CalllogChartsFilters', { ...charts, ...rest }))
          break;
        case 'missedcalls':

          dispatch(setFormValues('MissedCallsChartsFilters', newData.missedcalls))
          break;
        default:
      }
    } catch (err) {
      console.log(err);
      onError(dispatch, err);
    };
  }
}

export const sendSigninLink = email => {
  return async (dispatch, getState, firebase) => {
    if(!email) throw 'email address missing';

    try {
      await firebase.authStatePromise;
      const response = await firebase.httpsApiSendSigninLink({ email: email }); // send http api
      const result = await response.json();
      enqueueSnackbar(dispatch, 'SUCCESS');
    } catch (err) {
      console.log(err);
      onError(dispatch, err?.response ? await err.response.text() : err);
    }
  }
}

export const passwordReset = email => {
  return async (dispatch, getState, firebase) => {
    if(!email) throw 'email address missing';

    try {
      await firebase.authStatePromise;
      await firebase.auth.sendPasswordResetEmail(email);

      enqueueSnackbar(dispatch, {
        message: 'Email has been send!',
        options: {
          variant: 'success'
        }
      })
    } catch (err) {
      onError(dispatch, err);
    }
  }
};


export const register_calls_action = (filters) => {
  return async (dispatch, getState, firebase) => {
    dispatch({ type: CALLS_CONNECTING });

    let timestamp;
    let { range: { start, end }, limit, direction } = filters;

    try {
      await firebase.authStatePromise;
      let ref = firebase.calllog();

      ref = ref.where("start_stamp", ">=", start.startOf('day').format());
      ref = ref.where("start_stamp", "<=", end.endOf('day').format());

      if(direction === 'inbound' || direction === 'outbound') ref = ref.where("direction", "==", direction)

      // if(limit) ref = ref.orderBy("start_stamp", "desc").limit(limit)
      ref = ref.orderBy("start_stamp", "desc"); // fetch with order

      let unsubscribe = ref.onSnapshot(
        snapshots => {
          const snapshot = snapshots.docs.map(
            a => {
              const data = a.data();
              const id = a.id;
              return { id, ...data };
            }
          );

          dispatch({ type:  CALLS_DATA, payload: { calls: snapshot }});
        },
        error => {
          throw error;
        }
      );

      return () => {
        //cleanup listeners
        if(unsubscribe) unsubscribe();
      }
    } catch (err) {
      onError(dispatch, err);
    }
  };
};

export const multiAction = (cbs = []) => {
  return async (dispatch, getState, firebase) => {
    try {
      if(!cbs || cbs.length < 1) throw 'cbs missing';
      await firebase.authStatePromise;
      const loadingKey = enqueueSnackbar(dispatch, 'LOADING')
      await Promise.all([
        ...cbs.map(cb => cb())
      ]);

      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey);
    } catch (err) {
      onError(dispatch, err);
    }
  };
}

export const newLineState = (state = 'PAIKALLA') => {
  return async (dispatch, getState, firebase) => {
    try {
      await firebase.authStatePromise;
      await firebase.transaction(firebase.user(), (snapshot) => {
        return {...Object.assign(snapshot || {}, { line: state })};
      })
        .then(() => console.log('firebase new linestate:', state))

      return;
    } catch (err) {
      onError(dispatch, err);
    }
  };
}
