import React from 'react';

import InputBadge from './InputBadge';
import HelperBadge from './HelperBadge';

import Badge from '@mui/material/Badge';
import { styled } from '@mui/system';


export const MissedCallsBadge = styled(Badge)(({ theme, offset = 0 }) => ({
  '& .MuiBadge-badge': {
    color: 'rgb(255, 255, 255)',
    background: 'linear-gradient(60deg, #ef5350, #e53935)',
    boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(244 67 54 / 40%)',
    top: `-${offset}px`,
    right: `-${offset}px`,
  }
}));

export const LinkedBadge = styled(Badge)(({ theme, color, backgroundcolor }) => ({
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 2px',
    bottom: '6px',
    right: '10px',
    backgroundColor: backgroundcolor || theme.palette.background.badge,
    color: color || theme.palette.color.badge
  },
}));


export {
  HelperBadge,
  InputBadge
}
