import React, { useState, useEffect, useCallback, useReducer } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  Card,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Switch,
  Stack,
  Button,
  IconButton
} from '@mui/material';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import { VisibilitySwitch } from 'components/CustomSwitch';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ExtensionIcon from '@mui/icons-material/Extension';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import StarIcon from '@mui/icons-material/Star';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountTreeIcon from '@mui/icons-material/AccountTree';


import FriendsOptionsDialog from './FriendsOptionsDialog';

import { useFormContext, Controller } from "react-hook-form";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  // padding: "6px",
  // margin: `0 0 6px 0`,
  margin: '6px 3px',
  // change background colour if dragging
  background: isDragging ? "lightgrey" : "inherit",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "inherit",
  padding: "2px",
});

const ICONS = {
  'Ring Groups': <AccountTreeIcon />,
  'Colleagues': <PeopleAltIcon />,
  'Extensions': <ExtensionIcon />,
}

const ListItemComponent = ({ index, content: name, display = false, handleClick, provided, snapshot, openDialog }) => {
  const { control } = useFormContext(); // retrieve all hook methods

  return (
    <Card
      raised
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(
        snapshot.isDragging,
        provided.draggableProps.style
      )}
    >
      <ListItem
        secondaryAction={
          <HelperTooltipBootstrap title="Visibility" placement='left' arrow>
            <span>
              <Controller
                name={`friendsOrderAndDisplay.${index}.display`}
                control={control}
                render={({ field: { value, ...field}}) => {
                  return <Switch checked={value} {...field} />
                }}
              />
            </span>
          </HelperTooltipBootstrap>
        }
        // secondaryAction={
        //   <IconButton aria-label="MoreVert" onClick={openDialog({ title: name, controllerName: `friendsOrderAndDisplay.${index}` })}>
        //     <MoreVertIcon />
        //   </IconButton>
        // }
      >
        <ListItemIcon>
          {ICONS[name]}
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    </Card>
  )
}


const INITIALSTATE = {
  open: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'OPEN':
      return {
        ...state,
        open: true,
        data: action.payload,
      };
    case 'CLOSE':
      return {
        ...INITIALSTATE
      };
    default:
      return state
  }
}

const FriendsOptionsList = ({ handleClose, ...props }) => {
  const name = "friendsOrderAndDisplay";
  const { setValue, watch } = useFormContext(); // retrieve all hook methods
  const [dialog, dispatchDialog] = useReducer(reducer, INITIALSTATE);

  const items = watch(name) // data for ...

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    //setState({ items: items});
    setValue(name, newItems, { shouldDirty: true, shouldTouch: true }); // update react hook form
  }


  const updateFormValue = useCallback(
    (index, newValue) => event => {
      items[index].display = newValue;
      setValue(name, [...items], { shouldDirty: true, shouldTouch: true }); // update react hook form
    },
    [],
  );

  const openDialog = useCallback(
    (data) => (event) => {
      dispatchDialog({ type: 'OPEN', payload: data });
    },
    [dialog],
  );

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      subheader={<ListSubheader>Drag & Drop Order</ListSubheader>}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {
                    (provided, snapshot) => <ListItemComponent index={index} {...item} provided={provided} snapshot={snapshot} handleClick={updateFormValue} openDialog={openDialog} />
                  }
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>


      <FriendsOptionsDialog {...dialog} handleClose={(e) => dispatchDialog({ type: "CLOSE" })} />
    </List>
  );
}

export default FriendsOptionsList;
