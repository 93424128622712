import React from "react";
//import classNames from "classnames";
//import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";
import cx from "classnames";
import { NavLink } from "react-router-dom";
// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';
// @mui/icons-material
import LockIcon from  '@mui/icons-material/Lock';

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

//import { withFirebase } from '../../Firebase';

import styles from "assets/jss/components/mainNavbarLinksStyle.js";

const useStyles = makeStyles(createStyles(styles));

// export default function HeaderLinks(props) {
const HeaderLinks = ({ firebase }) => {
  //const [openNotification, setOpenNotification] = React.useState(null);
  // const handleClickNotification = event => {
  //   if (openNotification && openNotification.contains(event.target)) {
  //     setOpenNotification(null);
  //   } else {
  //     setOpenNotification(event.currentTarget);
  //   }
  // };
  // const handleCloseNotification = () => {
  //   setOpenNotification(null);
  // };
  //const [openProfile, setOpenProfile] = React.useState(null);
  // const handleClickProfile = event => {
  //   if (openProfile && openProfile.contains(event.target)) {
  //     setOpenProfile(null);
  //   } else {
  //     setOpenProfile(event.currentTarget);
  //   }
  // };
  // const handleCloseProfile = () => {
  //   setOpenProfile(null);
  // };
  const classes = useStyles();

  const activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };

  // const searchButton = classes.top + " " + classes.searchButton;
  // const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);

  // const managerClasses = classNames({
  //   [classes.managerClasses]: true
  // });
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <NavLink
          to={"/auth/logout"}
          className={cx(classes.navLink, {
            [classes.navLinkActive]: activeRoute("/auth/logout")
          })}
        >
          <LockIcon className={classes.listItemIcon} />
          <ListItemText
            primary={"Logout"}
            disableTypography={true}
            className={classes.listItemText}
          />
        </NavLink>
      </ListItem>
    </List>
  );
}

// <Link to='/auth/logout'>
//   <Fab onClick={firebase.doSignOut} size="medium" color="primary" aria-label="Log Out" className={classes.margin}>
//     <LockIcon />
//   </Fab>
// </Link>

export default HeaderLinks;
