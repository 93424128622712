import React, { Suspense, useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { grey } from '@mui/material/colors';

import { transform, isEqual, isObject } from 'lodash';

import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import { makeStyles, withStyles, createStyles } from '@mui/styles';

//import { withScrollBar } from 'HOC';

import { DEFAULT_SAMPLE, ANNOUNCEMENT_SAMPLES } from 'constants/Announcements';

import { Field, change, reduxForm, SubmissionError } from 'redux-form';
import { FormTextField } from 'components/Forms/Components';
import { required, minWavLength3 } from 'components/Forms/Validators';

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import { CustomTextField } from 'components/CustomInput';

import { Card, CardIcon, CardHeader, CardBody, CardFooter } from 'components/Card';

import { Grid, Badge, Checkbox, Tab, Tabs, Box, Chip, FormControlLabel, Switch, CircularProgress, Dialog, DialogContentText, ListItemText, MenuItem, List, InputAdornment, IconButton, Button, Divider, ListSubheader, ListItemIcon, ListItemAvatar, Avatar, ListItemSecondaryAction } from '@mui/material';
import { CardActions, CardActionArea, CardMedia, CardContent, Typography } from '@mui/material';
import image from 'assets/img/logo-microsoft.png';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import RegularButton from "components/CustomButtons/Button";

import { TTSform, TTSHeader, TTSsample, TTSAudioList, SimpleTTS } from "components/TTS";

import CustomForm from 'components/Forms';
import CustomList from 'components/CustomLists';
import { AudioControls } from 'components/Custom';

import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import InboxIcon from  '@mui/icons-material/MoveToInbox';
import DraftsIcon from  '@mui/icons-material/Drafts';
import SendIcon from  '@mui/icons-material/Send';
import ExpandLess from  '@mui/icons-material/ExpandLess';
import ExpandMore from  '@mui/icons-material/ExpandMore';
import StarBorder from  '@mui/icons-material/StarBorder';
import CancelIcon from  '@mui/icons-material/Cancel';
import MusicNoteIcon from  '@mui/icons-material/MusicNote';
import AddIcon from  '@mui/icons-material/Add';
import SettingsIcon from  '@mui/icons-material/Settings';
import KeyboardArrowUpIcon from  '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowLeftIcon from  '@mui/icons-material/KeyboardArrowLeft';
import ExpandMoreIcon from  '@mui/icons-material/ExpandMore';
import GetAppIcon from  '@mui/icons-material/GetApp';

import { SimpleLoader } from 'components/Loaders';

import AudioSample, { FileSaver, filenameSplitter } from 'Helpers/Announcements';

import { fileMetadata } from 'devtools/helper';

import { setFormValues } from 'store/form/reducer';

import { parse2ObjectAsync, parse2XMLSyncronous } from 'devtools/xml2js';

import {
  create_sample,
  upload_samples,
} from 'store/announcements/actions';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'components/Modals';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function difference(object, base) {
	function changes(object, base) {
		return transform(object, function(result, value, key) {
			if (!isEqual(value, base[key])) {
				result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
			}
		});
	}
	return changes(object, base);
} // return "object" with the difference

function arraysAreSame(a, b) {
  return isEqual(a.sort(), b.sort());
} // true : false


const FORM = 'AnnouncementCreator';
const AnnouncementCreator = ({ dispatch, handleComplete, uploadFiles, createDraft, ...props}) => {
  const classes = useStyles();
  const { handleClose, open, scroll } = props;
  const [advancedMode, setAdvancedMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [samples, setSamples] = useState([DEFAULT_SAMPLE]);

  const getLastestSample = samples[samples.length - 1];

  const previous = () => setSamples(prev => {
    if(samples.length > 1) prev.pop(); // removes newest if more than 1
    return [...prev];
  });

  const toggleChecked = () => setAdvancedMode((prev) => !prev)

  const onSubmit = event => handleComplete(dispatch(upload_samples([getLastestSample])));

  async function formSubmit(sample) {
    let { filename, ssml, text } = sample;

    // audio: null
    // content: "text-to-speech"
    // createdAt: "2021-11-17T12:02:17+02:00"
    // file: null
    // filename: "AudioFile12.wav"
    // sampleid: "4e581d1e-1ff4-47d5-bf45-c1afc8f2d761"
    // selected: false
    // ssml: "<speak xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xmlns:emo=\"http://www.w3.org/2009/10/emotionml\" version=\"1.0\" xml:lang=\"en-GB\">\n    <voice name=\"fi-FI-HeidiRUS\">\n      <prosody rate=\"0%\" pitch=\"0%\">Hei, Olemme juuri nyt varattuja. Odottakaa pieni hetki, palvelemme teitä mahdollisimman pian.</prosody>\n    </voice>\n  </speak>"
    // static: true
    // text: {speak: {…}}

    try {
      if(sample.content === 'text-to-speech') {

        if(arraysAreSame(Object.keys(difference(sample, getLastestSample)), ["filename"])) {
          // check if filename only changed!
          setSamples(prev => [...prev.slice(0, -1), sample]); // update the newest item!
        } else {
          // upload new sample
          if(!advancedMode) ssml = parse2XMLSyncronous(text);
          //console.log('new audio', filename, ssml)
          const file = await dispatch(create_sample({ filename, ssml }));
          const newSample = new AudioSample({ ...sample, file: file, ssml: ssml, content: 'text-to-speech' });

          setSamples(prev => [...prev, newSample]); // create new ref for rendering
        }
      } else {
        // update sample
      }
      return true;
    } catch (err) {
      throw new SubmissionError({
        submit: 'Submiting Error',
        _error: err,
      });
    } finally {

    }
  }

  return (
    <div style={{ display: open ? 'block' : 'none' }}>
      <DialogTitle id="scroll-dialog-title" onClose={handleClose}>
        <Grid item xs container justifyContent='flex-end' spacing={2} style={{ color: 'black' }}>
          <Grid item xs>
            <Box p={2}>
              <CardIcon color="success">
                <MusicNoteIcon style={{ color: 'white' }}/>
              </CardIcon>
              <h4 className={classes.cardTitleWhite} style={{ color: 'black' }}>New Announcement Creator</h4>
            </Box>
          </Grid>

          {
            <Grid item xs="auto">
              <FormControlLabel
                value="start"
                control={<Switch color="secondary" checked={advancedMode} onChange={toggleChecked} />}
                label="Advanced Form"
                labelPlacement="start"
              />
            </Grid>
          }
        </Grid>
      </DialogTitle>

      <DialogContent dividers style={{ backgroundColor: grey[400] }}>
        <Grid container justifyContent="flex-start">
          <Grid item xs={12}>
            {
              loading
                ? <SimpleLoader p={5} label='Processing File' />
                : <TTSform sample={{...getLastestSample}} advancedMode={advancedMode} onSubmit={formSubmit} previous={previous} {...props} />
            }

          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button disabled={!Boolean(getLastestSample?.file)} onClick={onSubmit} color="primary">
          {
            loading && <CircularProgress color="secondary" size={26} style={{ marginRight: '6px' }} />
          }
          <Box px={1}>
            Save
          </Box>
        </Button>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </div>
  );
}

export default connect(null,null)(AnnouncementCreator);
