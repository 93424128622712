import React, { useEffect, useState } from 'react';

import { omit } from 'lodash';

import {
  Chip,
} from '@mui/material';

import { ErrorBoundary } from 'react-error-boundary'
import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import { CustomAvatar } from 'components/CustomAvatars';

import { service_contact_class } from 'Services';


const ErrorFallback = ({error, resetErrorBoundary}) => {
  return (
    <Chip
      avatar={
        <CustomAvatar useDefault />
      }
      sx={{
        fontSize: 10,
        '.MuiChip-label': {
          paddingLeft: "6px"
        },
      }}
      label="Failed to Load"
      onClick={(event) => resetErrorBoundary(event)}
    />
  )
}

const ContactChip = React.memo(({ id, isLoading, contact, title, children, placement = "bottom", onAvatarClick = undefined }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      resetKeys={[contact]}
    >
      <HelperTooltipBootstrap
        title={typeof title === 'function' ? title(contact) : title}
        placement={placement}
        aria-label="aika"
        arrow
      >
        <Chip
          avatar={
            <CustomAvatar
              name={contact?.fullName}
              src={contact?.image}
              bgcolor={contact?.avatar?.bgcolor}
              onClick={typeof onAvatarClick === 'function' ? (event) => onAvatarClick(event, contact) : undefined}
            />
          }
          sx={{
            fontSize: 10,
            '.MuiChip-label': {
              paddingLeft: "6px"
            },
          }}
          label={
            typeof children === 'function'
              ? children(contact, isLoading)
              : children
          }
        />
      </HelperTooltipBootstrap>
    </ErrorBoundary>
  )
}, (prevProps, nextProps) => {
  return JSON.stringify(omit(prevProps, ['id', 'isLoading', 'contact'])) !== JSON.stringify(omit(nextProps, ['id', 'isLoading', 'contact']))
})


const logicalComponent = (Component) => (props) => {
  let mounted = true, subscription;
  const { id, contact } = props;
  const [data, setData] = useState(() => undefined);
  const dataset = data ?? contact;

  const dataHandler = new service_contact_class(id) // new / uid / contact id

  useEffect(() => {
    if(!mounted) return;
    
    if(typeof dataHandler?.add_subscriber === 'function') {
      subscription = dataHandler.add_subscriber(
  			value => {
          if(mounted && value) setData(value)
  			},
  			err => console.warn('HandledCalls', err)
  		)
    }

    return () => {
      mounted = false;
      if(typeof dataHandler?.remove_subscriber === 'function') dataHandler.remove_subscriber(subscription);
    }
  }, [])

  return <Component key={id} contact={dataset} isLoading={Boolean(dataset === undefined && id)} {...props} />
}

export default logicalComponent(ContactChip);
