import React, { createContext, useReducer, useContext, useEffect } from 'react';
import JsSIP from 'jssip';

import localStorage from 'localStorage';

const debuggerContext = createContext();

const STORAGE_NAME = "debugging";
const INITIALSTATE = (() => localStorage.get(STORAGE_NAME))();

function reducer(state, { type, payload}) {
  switch (type) {
    case 'GET':
      return {
        ...state,
      };
    case 'SET': {
      const data = Object.assign(state, payload);
      localStorage.set(STORAGE_NAME, data);
      return {...data}
    }
    default:
      return state
  }
}

export function useDebuggerProvider() {
  const [state, setDebugger] = useReducer(reducer, {...INITIALSTATE});

  useEffect(() => {
    // run when value changes
    state?.jssip ? JsSIP.debug.enable("JsSIP:*") : JsSIP.debug.disable("JsSIP:*");
  }, [state.jssip])

  return {
    ...state,
    setDebugger
  }
}

export function useDebugger() {
  return useContext(debuggerContext);
}

export function DebuggerProvider({ children }) {
  const state = useDebuggerProvider();

  return (
    <debuggerContext.Provider value={state}>
      { children }
    </debuggerContext.Provider>
  )
}

export const withDebugger = (Component) => (props) => {
  return (
    <DebuggerProvider>
      <Component {...props} />
    </DebuggerProvider>
  )
}
