import { v4 as uuidv4 } from 'uuid';
import { sortBy, compact, values, mergeWith, keyBy } from 'lodash';

function* propertyValueGenerator(array, value) {
	const regex = new RegExp(`${value}*`);
  for (const propValue of array) {
    if(regex.test(propValue)) return true;
  }
  return false;
}

function escapeStringRegExp(str) {
	return str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
}

function makeCollection(object, idProp = 'id') {
  return Object.keys(object !== null ? object : {}).map(key => ({ ...object[key], [idProp]: key}));
}

function sortByTime(collection = []) {
  return sortBy(collection, function(dateObj) {
    return new Date(dateObj.timestamp);
  }).reverse();
}

function searchCollection(rows = [], value) {
	try {
		if(!value) return rows;
	  return rows.filter(item => {
	  	const gen = propertyValueGenerator(Object.values(item), value)
	  	return gen.next().value;
	  })
	} catch (e) {
		console.log(e);
	}
}

function splitCollectionByCondition(arr = [], callback) {
  return arr.reduce((arr, el) => {
    if (callback(arr, el)) {
      arr[0].push(el);
    } else {
      arr[1].push(el);
    }

    return arr;
  }, [[],[]]);
}

function checkNull(value) {
  return `${value}` === 'null' ? null : value;
}

function sortCollection(array = [], property) {
	switch (property) {
		case 'CUSTOM':
			// return array.sort((a,b) => {
			//   if (a is less than b by some ordering criterion) {
			// 		// less than
			//     return -1;
			//   }
			//   if (a is greater than b by the ordering criterion) {
			// 		// greater than
			//     return 1;
			//   }
			//   return 0;
			// })
			break;
		case 'OrderByRinggroup':
			return array.sort((a,b) => parseFloat(a.ringgroup.ring_group_extension) - parseFloat(b.ringgroup.ring_group_extension))
			break;
		default:
			return array.sort((a,b) => parseFloat(a[property]) - parseFloat(b[property]))
	}
}


function indexOfCollection(array = [], item) {
  return array.findIndex((element, index) => JSON.stringify(element) === JSON.stringify(item))
}

function isJSON(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

function parseLabel (selected, selectedText = '') {
  if(!checkNull(selected)) return selectedText;
  let variable = selected.split(' ').shift();

  switch (variable) {
    case 'null':
      selectedText = 'None';
      break;
    case 'hangup':
      selectedText = 'hangup';
      break;
    default:
      selectedText = selected;
  }
  return selectedText;
}


function generateVoicemailsUpdateData(voicemails, values) {
	let voicemailsData = {};
	for (const { id, greetings, ...voicemail } of voicemails) {
		Object.assign(voicemailsData, {
			[id]: {
				...voicemail,
				...values
			}
		})
	}

	return voicemailsData;
}

function isAdmin(role) {
	return role === 'admin' || role === 'heavyadmin' ? true : false;
}

function generateVoicemailGreeting(voicemails, { uids, greeting_id: greetingObject, ...values }) {
	let voicemailsData = {};
	let greetingsData = {};

	for (const { id, greetings, ...voicemail } of voicemails) {
		const selectedGreeting = greetings.filter((element) => element.greeting_description === greetingObject).pop();
		const availableGreetingNumber = Math.max(...greetings.map(element => element?.greeting_id || 0), 0) + 1;

		if(greetingObject != undefined && selectedGreeting == undefined) {
			// add new greeting;
			Object.assign(greetingsData, {
				[uuidv4()]: {
					domain_uuid: voicemail.domain_uuid,
					greeting_base64: "null",
					greeting_description: greetingObject,
					greeting_filename: `greeting_${availableGreetingNumber}.wav`,
					greeting_id: availableGreetingNumber,
					greeting_name: `Greeting ${availableGreetingNumber}`,
					voicemail_greeting_uuid: uuidv4(),
					voicemail_id: voicemail.voicemail_id
				}
			})
		}

		Object.assign(voicemailsData, {
			[id]: {
				...voicemail,
				greeting_id: selectedGreeting?.greeting_id || availableGreetingNumber,
				...values
			}
		})
	}

	return {
		greetingsData: greetingsData,
		voicemailsData: voicemailsData
	}
}

function setNulls(obj = {}, boolean = true){
	Object.keys(obj).map((el, i ) => {
    try {
			if(boolean) {
				if(obj[el] === 'null') {
	        obj[el] = null;
	      }
			} else {
				if(obj[el] === null) {
					obj[el] = 'null';
				}
			}
    } catch (e) {}
  })
	return obj;
}

function firebaseNulls(obj = {}, boolean = false){
	Object.keys(obj).map((el, i ) => {
    try {
			if(!boolean) {
				if(obj[el] === 'null') {
	        obj[el] = null;
	      }
			} else {
				if(obj[el] === null) {
					obj[el] = 'null';
				} else if (typeof obj[el] === 'object') {
	        firebaseNulls(obj[el], boolean);
	      } else { }
			}
    } catch (e) {}
  })
	return obj;
}


function isLoading(array = []) {
	function* checkingGenerator(arr) {
	  for (const propValue of arr) {
			if(propValue === 'null' || propValue === true) return true;
	  }
	  return false;
	}

	const gen = checkingGenerator(array)
	return gen.next().value;
}

function parseTypes(obj = {}, boolean = true) {
	if(obj === null) return {};
  Object.keys(obj).map((el, i ) => {
    try {
      if(boolean && obj[el] === 'null') {
        obj[el] = null;
      } else if (!boolean && obj[el] === null) {
        obj[el] = ''+obj[el]+''
      } else {
      	if(typeof obj[el] === 'object') {
        	parseTypes(obj[el])
        } else {
        	obj[el] = JSON.parse(obj[el]);
        }
      }
    } catch (e) {}
  })
  return obj;
};

function customizer(objValue, srcValue) {
  if(!objValue) return false;
  return {...Object.assign(objValue, srcValue)};
}

function collectionMerger(collections, unionProp) {
	return compact(values(mergeWith(...collections.map((item, index) => keyBy(item, Array.isArray(unionProp) ? unionProp[index] : unionProp ))), customizer));
}

export function applyNull(value) {
	try {
	  return value || null;
	} catch (e) {
		return null;
	}
}

export function compare(a,b) {
  if (a < b) {
    return -1;
  } else if  (a > b) {
    return 1;
  } else {
    return 0;
  }
}


export {
	isAdmin,
	escapeStringRegExp,
  searchCollection,
	makeCollection,
	sortByTime,
	splitCollectionByCondition,
	parseLabel,
	checkNull,
	sortCollection,
	indexOfCollection,
	setNulls,
	firebaseNulls,
	isJSON,
	generateVoicemailsUpdateData,
	generateVoicemailGreeting,
	isLoading,
	parseTypes,
	collectionMerger
};
