import React, { useState, useCallback } from 'react';
// import { connect } from 'react-redux';
import MoreIcon from "@mui/icons-material/MoreVert";
import { Fab, Menu, MenuItem } from '@mui/material';

const FabPicker = ({ options, onChange, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = useCallback(e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      <Fab
        {...props}
        onClick={handleMenuOpen}
        aria-label="picker"
        aria-owns={anchorEl ? "locale-menu" : undefined}
      >
        <MoreIcon />
      </Fab>

      <Menu
        id="locale-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          options.map(item => (
            <MenuItem
              key={item.label}
              onClick={() => {
                onChange(item.value);
                handleClose();
              }}
            >
              {item.label}
            </MenuItem>
          ))
       }
      </Menu>
    </div>
  )
}

FabPicker.defaultProps = {
  size: 'small',
  color: 'primary',
  options: []
};


// const mapStateToProps = state => ({
//   loading: state.additionals.loading,
//   protocol: state.additionals.phone_protocol,
// });

export default FabPicker;
