import React, { Component, Suspense, lazy, useEffect, useState, useCallback } from "react";
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';
import { createTheme, ThemeProvider} from '@mui/material/styles';

import MUIDataTable, { TableFilterList } from "mui-datatables";
import { getMUIDataTableTheme, getMUIDataTableOptions } from 'constants/MUIDataTable';

// @mui/material components
// core components

import {
  useNavigate,
  useLocation
} from "react-router-dom";

import useMediaQuery from '@mui/material/useMediaQuery';

import { withMuiMediaQuery, withSuspenseLoader } from 'HOC';

import moment, { timestamp } from 'Helpers/Momentjs';

import { merge, pullAt, zipObject } from 'lodash';


import { MissedCallsCardWithCount, MissedCallsTimeline } from 'components/MissedCalls';
import { UsersCardWithCount } from 'components/Users';

import { COUNTRIES } from 'constants';

import { Card, CardHeader, CardIcon, CardBody, CardFooter } from 'components/Card';

import NavPills from "components/NavPills/NavPills.js";
import {
  Fade,
  Box,
  Grid,
  Container,
  Menu,
  Button,
  MenuItem,
  ListItemText,
  ListItemIcon,
  TableRow,
  Paper,
  Stack,
  IconButton,
  InputAdornment,
  Chip,
  Avatar,
  Typography
} from '@mui/material';

import { HelperTooltipBootstrap } from "components/CustomTooltips";

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExtensionIcon from '@mui/icons-material/Extension';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CallIcon from '@mui/icons-material/Call';
import EditIcon from '@mui/icons-material/Edit';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SettingsIcon from '@mui/icons-material/Settings';
import Icon from "@mui/material/Icon";

import TelephoneChip from 'WEBRTC/components/TelephoneChip';
import { CustomAvatar } from 'components/CustomAvatars';
import { SimpleLoader } from 'components/Loaders';
import { ContactsToolBar } from 'components/CustomToolBars';

// import WebRTCApp from 'JSSIP/components/WebRTCApp';

import { service_contacts, action_remove_contacts } from 'Services';
import { usePhone } from 'Providers';
import useSingleAndDoubleClick from 'Contexts/useSingleAndDoubleClick';

import { useDropDown, withDropDown } from 'Providers';

import styles from "assets/jss/views/dashboardStyle.js";
const useStyles = makeStyles(styles);

class Contact {
  constructor(data, { ...props}) {
    return {
      ...data
    }
  }
}

// const options = {
//   elevation: 0,
//   filterType: 'checkbox',
//   searchAlwaysOpen: true,
//   type: "search",
//   searchPlaceholder: "Search",
//   print: false,
//   // customSearchRender: function (searchText, handleSearch, hideSearch, options) {
//   //  return
//   // }
//   //customTableBodyFooterRender
//   rowHover: true,
//   rowsPerPage: 50,
//   rowsPerPageOptions: [50, 100, 250],
//   searchProps: {
//     size: 'small',
//     //label: 'search',
//   },
// };

const Contacts = ({ dispatch, mediaQuery, ...props }) => {
  //return <Test/>
  let mounted = true;
  const location = useLocation();

  const { isXlScreen } = mediaQuery;
  const queryString = new URLSearchParams(location.search); // esim query.get('token')

  const navigate = useNavigate();
  const { data, isLoading } = props;

  const { handleClick } = useDropDown();
  const handleClicks = useSingleAndDoubleClick(onSingleClick, onDoubleClick);
  const { invite } = usePhone();

  const materialTableRef = React.createRef();

  function onSingleClick(event, number) {
    return;
  }

  function onDoubleClick(event, number) {
    invite(number); // try call number
  }

  const actionCall = value => e => {
    console.log('call ', value)
  }

  function getDataset({ rowIndex, currentTableData}) {
    return zipObject(column_keys, currentTableData[rowIndex].data);
  }

  const columns = [
    {
     name: "id",
     options: {
       filter: false,
       display: false,
     }
    },
    {
     name: "image",
     options: {
       filter: false,
       display: 'excluded',
     }
    },
    {
     name: "actions",
     label: "Actions",
     filter: false, // Display column in filter list.
     options: {
       setCellHeaderProps: function (columnMeta) {
         return {
           style: {
             textAlign: 'center'
           }
         }
       },
       customBodyRender: (value, tableMeta, updateValue) => {
         const dataset = data[tableMeta.currentTableData[tableMeta.rowIndex].index];
         return (
           <Stack direction="row" justifyContent="center" alignItems="center" spacing={0}>
             <HelperTooltipBootstrap title="Duplicate" placement='top'>
               <IconButton
                 aria-label="Duplicate"
                 size={isXlScreen ? 'medium' : 'small'}
                 onClick={(event, rowData) => {
                   navigate(`/contacts/new`, { replace: true, state: {
                     ...dataset,
                     id: 'new'
                   }});
                 }}
               >
                 <LibraryAddIcon fontSize="inherit" />
               </IconButton>
             </HelperTooltipBootstrap>
             <HelperTooltipBootstrap title="Edit" placement='top'>
               <IconButton
                 aria-label="Edit"
                 size={isXlScreen ? 'medium' : 'small'}
                 onClick={(event, rowData) => {
                   navigate(`/contacts/${dataset.id}`);

                   /* alempi bugaa */
                   // navigate(`/contacts/${dataset.id}`, {
                   //   state: dataset
                   // });
                 }}
                >
                 <EditIcon fontSize="inherit" />
               </IconButton>
             </HelperTooltipBootstrap>
           </Stack>
         )
       },
       filter: false,
       sort: false,
     }
    },
    {
     name: "fullName",
     label: "Full Name",
     options: {
       filter: true,
       sort: true,
       customBodyRender: (value, tableMeta, updateValue) => {
         const dataset = data[tableMeta.currentTableData[tableMeta.rowIndex].index];
         const { image } = dataset;
         return value && <Chip avatar={<CustomAvatar name={value} src={image} />} label={value} />
       },
       // customBodyRender: (dataIndex, rowIndex) => {
       //   const { fullName, image } = data[rowIndex];
       //   return fullName && <Chip avatar={<CustomAvatar name={fullName} src={image} />} label={fullName} />
       // },

     }
    },
    // {
    //  name: "fullName",
    //  label: "Full Name",
    //  options: {
    //    filter: true,
    //    sort: true,
    //  }
    // },
   // {
   //  name: "firstName",
   //  label: "First Name",
   //  options: {
   //    filter: true,
   //    sort: false,
   //    display: 'excluded',
   //  }
   // },
   // {
   //  name: "lastName",
   //  label: "Last Name",
   //  options: {
   //    filter: true,
   //    sort: false,
   //    display: 'excluded',
   //  }
   // },
   {
    name: "language",
    label: "Language",
    options: {
      setCellHeaderProps: function (columnMeta) {
        return {
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          }
        }
      },
      setCellProps: function (cellValue, rowIndex, columnIndex) {
        return {
          style: {
            textAlign: 'center'
          },
        }
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        const code = COUNTRIES.find(element => element.code === value)
        return code && (
          <HelperTooltipBootstrap title={code.label} placement='right'>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${code.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${code.code.toLowerCase()}.png 2x`}
              alt=""
            />
          </HelperTooltipBootstrap>
        )
      },
      filter: false,
      sort: false,
    }
   },
   {
    name: "emails",
    label: "Email Addresses",
    options: {
      setCellHeaderProps: function (columnMeta) {
        return {
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          }
        }
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        return value && <Button size='small' style={{ textTransform: 'inherit', textAlign: 'left' }} onClick={(event) => window.location.href='mailto:'+value+'?subject=Soittopyyntö'}>{value}</Button>
      },
      filter: true,
      sort: true,
    }
   },
   {
    name: "job_title",
    label: "Job Title",
    options: {
      setCellHeaderProps: function (columnMeta) {
        return {
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          }
        }
      },
      filter: true,
      sort: true,
    }
   },
   {
    name: "organization",
    label: "Organization",
    options: {
      filterList: queryString.has('organization')
        ? [queryString.get('organization')]
        : []
      , // apply item for filtering!
      setCellHeaderProps: function (columnMeta) {
        return {
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          }
        }
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        const dataset = data[tableMeta.currentTableData[tableMeta.rowIndex].index];
        const { organization_uuid } = dataset;
        const id = `comapny-button-${tableMeta.dataIndex}${tableMeta.rowIndex}`;
        return value && (
          <Button
            id={id}
            variant="text"
            size='small'
            style={{ fontSize: 12, textTransform: 'none', textAlign: 'left' }}
            aria-haspopup="true"
            onClick={handleMenuClick({ name: value, id: organization_uuid })}
          >
            {value}
          </Button>
        )
      },
      filter: true,
      sort: true,
    }
   },
   {
    name: "telephones",
    label: "Telephone Numbers",
    options: {
      setCellHeaderProps: function (columnMeta) {
        return {
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          }
        }
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        return value && (
          <Stack direction="row" spacing={1}>
            {
              value.map(({number, contact, type}, index) => {
                return (
                  <HelperTooltipBootstrap key={`${index}`} title="Doubleclick for Call" placement='bottom'>
                    <span>
                      <TelephoneChip
                        color="primary"
                        icon={type}
                        label={`${number}`}
                        onClick={event => handleClicks(event, number)}
                      />
                    </span>
                  </HelperTooltipBootstrap>
                )
              })
            }
          </Stack>
        )
      },
      filter: true,
      sort: true,
    }
   },
   {
    name: "search_tags",
    label: "Search Tags",
    options: {
      setCellHeaderProps: function (columnMeta) {
        return {
          style: {
            textAlign: 'center'
          }
        }
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        const dataset = data[tableMeta.currentTableData[tableMeta.rowIndex].index];
        const { search_tags } = dataset;
        return search_tags && (
          <Stack direction="row" spacing={1}>
            {
              search_tags.map((search_tag, index) => {
                return <Chip key={index} label={search_tag} size='small' />
              })
            }
          </Stack>
        )
      },
      filter: true,
      sort: true,
    }
   },
   {
    name: "website",
    label: "Website",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return value && <Button size='small' style={{ textTransform: 'inherit' }} target="_blank" href={value}>{value}</Button>
      },
      display: false,
      filter: true,
      sort: true,
    }
   },
   {
    name: "street_address",
    label: "Street Address",
    options: {
      display: false,
      filter: true,
      sort: true,
    }
   },
   {
    name: "Notes",
    label: "notes",
    options: {
      display: false,
      filter: true,
      sort: true,
    }
   },
  ];

  const column_keys = columns.map(column => column.name);

  const handleMenuClick = (organization) => (event) => {
    if(typeof handleClick === 'function') handleClick(organization)(event.currentTarget); // opens dropdownmenu
  };

  const handleDeleteRowsAction = (selectedRows, displayData, setSelectedRows) => async (event) => {
    const deleteRows = pullAt([...data], Object.keys(selectedRows.lookup));
    console.log(deleteRows)
    await action_remove_contacts(deleteRows);
    setTimeout(() => setSelectedRows([]))
  };

  // const handleDeleteRowsAction = useCallback(
  //   (selectedRows, displayData, setSelectedRows) => async (event) => {
  //     const deleteRows = pullAt([...data], Object.keys(selectedRows.lookup));
  //     console.log(deleteRows)
  //     await action_remove_contacts(deleteRows);
  //     setTimeout(() => setSelectedRows([]))
  //   },
  //   [],
  // );


  return (
    <Container sx={{ maxWidth: "2200px !important" }}>
      <Grid container justifyContent='flex-start' spacing={3}>
        <Grid item xs>
          <Card elevation={6}>
            <CardHeader color="info">
              Contacts
            </CardHeader>
            <CardBody noPadding>
              <ThemeProvider theme={getMUIDataTableTheme()}>
                <MUIDataTable
                  data={data}
                  columns={columns}
                  options={
                    merge(getMUIDataTableOptions(), {
                      customToolbar: (props) => {
                        return (
                          <ContactsToolBar mediaQuery={mediaQuery} {...props} />
                        );
                      },
                      onRowsDelete: function({ lookup, data }) {
                         console.log(lookup, data)
                         return false;
                      },
                      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                         <Box px={2}>
                           <Stack direction="row" spacing={2}>
                             <IconButton aria-label="delete" size="large" onClick={handleDeleteRowsAction(selectedRows, displayData, setSelectedRows)}>
                               <DeleteOutlineIcon />
                              </IconButton>
                           </Stack>
                        </Box>
                      ),
                    }
                  )}
                  components={{
                    TableFilterList: CustomFilterList,
                  }}
                />
              </ThemeProvider>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

const CustomChip = ({ label, onDelete }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleDelete = (e) => {
    const queryString = new URLSearchParams(location.search); // esim query.get('token')
    queryString.delete("organization"); // add state

    navigate(`${window.location.pathname}?${queryString.toString()}`, {
      replace: true
    })
    onDelete(e);
  }

  return (
    <Chip
      label={label}
      onDelete={handleDelete}
    />
  );
};

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

const DropDownMenu = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { anchorEl, handleClose, handleMenuItemClick, open, state, ...rest } = props;

  const { name, id } = state ?? {};

  function stopPropagation(e) {
    e.push();
  }

  return open &&  state && (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      TransitionComponent={Fade}
      PaperProps={{
        elevation: 8,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          minWidth: anchorEl.clientWidth,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem
        sx={{ minWidth: "200px" }}
        onClick={(e) => {
          navigate(`/organizations/${id}`);
        }}
        divider
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText
          primary={`Open '${name}'`}
        />
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          setTimeout(() => {
            const queryString = new URLSearchParams(location.search); // esim query.get('token')
            queryString.set("organization", name); // add state

            navigate(`${window.location.pathname}?${queryString.toString()}`, { replace: true })
            // on chip close remove serach params if exists
          })
        }}
      >
        <ListItemText
          primary="Filter By"
          secondary={(
            <small>
              Organization
              '<b><u>{name}</u></b>'
            </small>
          )}
        />
      </MenuItem>
    </Menu>
  )
}

const logicalComponent = (Component) => (props) => {
  let mounted = true, subscription;
  const [data, setData] = useState(null);
  const { add_subscriber, remove_subscriber } = service_contacts();

  useEffect(() => {
    if(mounted) {
      subscription = add_subscriber(
  			value => {
  				if(value && mounted) {
            setData(
              value.map(item => {
                return new Contact(item, props);
              })
            )
  				}
  			}
  		)
    }
    return () => {
      mounted = false;
      remove_subscriber(subscription);
    }
  }, [])

  return <Component {...props} data={data ?? []} isLoading={!Array.isArray(data)} />
}

export default withSuspenseLoader(withMuiMediaQuery(withDropDown(logicalComponent(Contacts), DropDownMenu)))
