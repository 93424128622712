import React, { createContext, useEffect, useReducer } from 'react';
import { take, uniqBy } from 'lodash';
import { makeCollection } from 'devtools/Helpers';

import moment from 'Helpers/Momentjs';

const CallingListContext = createContext();

function sortByTime(values, prop = "time") {
  return values.sort(function(x, y){
    return x[prop] - y[prop];
  })
}

const STORAGE_NAME = 'calling-list';
const STORAGE_COUNT = 50;

const localstorage = {
  "get": () => JSON.parse(window.localStorage.getItem(STORAGE_NAME)) || {},
  "set": (values) => window.localStorage.setItem(STORAGE_NAME, JSON.stringify(take(values, STORAGE_COUNT))),
  "delete": () => window.localStorage.removeItem(STORAGE_NAME),
}


const INITIALSTATE = sortByTime(makeCollection(localstorage.get())); // object --> array

function reducer(state = INITIALSTATE, action) {
  switch (action.type) {
    case 'ADD': {
      const items = uniqBy(sortByTime([...state, new ListItem(action.payload)]), 'title');
      localstorage.set(items)
      return items;
    }
    case 'REMOVE': {
      const items = state.filter(item => item !== action.payload);
      localstorage.set(items)
      return items;
    }
    case 'REMOVEALL': {
      localstorage.delete(); // store remove items
      return [];
    }
    default:
      return state;
  }
}

class ListItem {
  constructor(value) {
    return {
      title: `${value}`,
      time: new Date(),
    }
  }
}

// rest sessions setSessions
const withCallingListContext = (Component) => (props) => {
  const [list, setList] = useReducer(reducer, INITIALSTATE);
  let mounted = true;

  return (
    <CallingListContext.Provider value={[list, setList]}>
      <Component {...props} />
    </CallingListContext.Provider>
  )
};

export {
  CallingListContext as default,
  withCallingListContext
}
