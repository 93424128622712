import { v4 as uuidv4 } from 'uuid';
import Moment from 'devtools/momentjs';
import { isEmpty } from 'lodash';
import { parse2ObjectAsync, parse2XMLSyncronous } from 'devtools/xml2js';

// audio: null,
// createdAt: null,
// file: null,
// filename: 'AudioFile1.wav',
// sampleid: "aeac0f31-4902-4795-8bad-ed6601d3a276",
// selected: false,
// static: true,
// text: `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-GB">
//   <voice name="fi-FI-HeidiRUS">
//     <prosody rate="0%" pitch="0%">Hei, Olemme juuri nyt varattuja. Odottakaa pieni hetki, palvelemme teitä mahdollisimman pian.</prosody>
//   </voice>
// </speak>`

export function FileSaver(blob, filename) {
  blob = new File([blob], filename, { type: "audio/wav" });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0)
  }
}

export function filenameSplitter(filename = "") {
  const extension = filename.split('.').pop();
	const chunks = filename.split("."+ extension);

  return {
    name: chunks[0],
    extension: extension,
  }
}


class AudioSample {
  constructor(data) {
    this.data = {
      ...Object.assign(
        data,
        {
          sampleid: uuidv4(),
          createdAt: Moment.timestamp(),
          content: data.content || 'text-to-speech',
          audio: data?.audio || null,
          file: data?.file || null,
          filename: data?.filename || null,
          text: data?.text || null,
          ssml: data?.ssml || data?.text || null,
        }
      )
    }
    return this.data;
  }
}

export default AudioSample;
