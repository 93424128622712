import React from "react";
import { Card } from 'components/Card';
import { Grid, Box, CircularProgress } from '@mui/material';

const SimpleLoader = ({ label, ...props }) => {
  return (
    <Grid container justifyContent='center'>
      <Grid item>
        <Card noMargin style={{ zIndex: 9000, boxShadow: 'none', width: 'auto' }}>
          <Box display="flex" justifyContent='center' p={5} {...props}>
            <CircularProgress color="secondary" />
            { label && <Box pl={2}>{label}</Box> }
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

SimpleLoader.defaultProps = {
  alignItems: 'center'
}

export default SimpleLoader;
