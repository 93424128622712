import React from 'react';
import { Checkbox, FormGroup, FormControl, FormLabel, FormControlLabel, FormHelperText } from '@mui/material';

const FormCheckBox = (
  { input: { value, ...rest}, label, meta: { touched, error }, useTheme, formControlLabelProps, ...custom },
) => {
  const Component = useTheme || Checkbox;
  return (
    <FormControl error={Boolean(error)} component="fieldset">
      {
        label && <FormLabel component="legend">{label}</FormLabel>
      }
      <FormGroup>
        <FormControlLabel
          {...formControlLabelProps}
          control={
            <Component
              checked={Boolean(value)}
              {...rest}
              {...custom}
            />
          }
        />
      </FormGroup>
      {
        touched && Boolean(error) && <FormHelperText>{ error }</FormHelperText>
      }
    </FormControl>
  );
}

export default FormCheckBox
