import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export function sweetalertPromptReload(props = {}) {
  let options = {
    title: 'Headset Disconnected',
    text: 'page reloading required to take full effect!',
    icon: 'info',
    showCancelButton: true,
    allowEscapeKey: false,
    allowOutsideClick: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Reload'
  };

  return new Promise(
    (resolve, reject) => {
      Swal.fire(Object.assign(options, props)).then((result) => {
        switch (result.dismiss) {
          case Swal.DismissReason.cancel:
            reject();
            break;
          default:
            resolve();
        }
      })
    }
  )
    .then(() => window.location.reload());
}






// export default class Sweetalerts {
//   createSwal = (options) => {
//     try {
//       if(Swal.isVisible()) Swal.close();
//       Swal.fire(options)
//     } catch (e) { console.log(e); }
//   }
//
//   // *** User API ***
//
//   success = ({title, text}) => Swal.fire({
//     icon: 'success',
//     title: title,
//     text: text || '',
//     timer: 3000
//   })
//
//   error = ({title, text}) => Swal.fire({
//     icon: 'error',
//     title: title,
//     text: text || '',
//     showConfirmButton: false
//   })
//
//   confirm = (customOptions) => new Promise(
//     (resolve, reject) => {
//       let options = {
//         title: 'Are you sure?',
//         text: "You won't be able to revert this!",
//         icon: 'warning',
//         showCancelButton: true,
//         allowEscapeKey: false,
//         allowOutsideClick: false,
//         confirmButtonColor: '#3085d6',
//         cancelButtonColor: '#d33',
//         confirmButtonText: 'Yes, execute delete!'
//       };
//
//       if(typeof customOptions === 'object') {
//         options = Object.assign(options, customOptions);
//       }
//
//       Swal.fire(options).then((result) => {
//         switch (result.dismiss) {
//           case Swal.DismissReason.cancel:
//             reject();
//             break;
//           default:
//             resolve();
//         }
//       })
//     }
//   )
// }
