export function total(array = []) {
  return array.reduce((a, c) => a + c, 0) || 0;
}

export function avarage(array = [], useZore = true) {
  if(!useZore) array = array.filter(item => item > 0);
  return total(array) === 0 ? 0 : (total(array) / array.length).toFixed(1); // rounded number
}

export function max(array = []) {
  return Math.max(...array) || 0;
}

export function percentage(full, count, decimals = 0) {
  return ((full/count) * 100).toFixed(decimals);
}
