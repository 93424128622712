import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  Popover,
} from '@mui/material';

import ErrorBoundary from 'components/ErrorBoundary';

const popoverContext = createContext();

export function usePopoverProvider() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handlePopoverClick = (newState) => (event) => {
    // handlePopoverClick(item)(event);
    const element = event?.tagName
      ? event
      : (event?.currentTarget || event.target)

    setAnchorEl(element);
    //console.log("newState", newState)
    setState(newState);
  };

  const handleMenuItemClick = (callback) => async (event) => {
    await callback(event);
    handleClose(event)
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    setState(null);
  };

  return {
    anchorEl,
    state,
    open,
    handlePopoverClick,
    handleClose,
    handleMenuItemClick,
  }
}

export function usePopover() {
  return useContext(popoverContext);
}

export function PopoverProvider({ children, PopoverComponent, popoverProps }) {
  const { handlePopoverClick, ...props} = usePopoverProvider();
  const { id, open, handleClose, anchorEl } = props;
  return (
    <popoverContext.Provider value={{ handlePopoverClick }}>
      {children}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        {...popoverProps}
      >
        <ErrorBoundary>
          <PopoverComponent {...props} />
        </ErrorBoundary>
      </Popover>
    </popoverContext.Provider>
  )
}

export const withPopover = (Component, { component, ...rest }) => (props) => {
  return (
    <PopoverProvider PopoverComponent={component} popoverProps={rest}>
      <Component {...props} />
    </PopoverProvider>
  )
}
