import React from 'react';
import { withStyles } from '@mui/styles';
import { red, orange } from '@mui/material/colors';

import { Tooltip, IconButton, Typography } from '@mui/material';

// import PhoneAndroidIcon from  '@mui/icons-material/PhoneAndroid';
// import DialerSipIcon from  '@mui/icons-material/DialerSip';
// import MoreVertIcon from  '@mui/icons-material/MoreVert';
// import DeleteIcon from  '@mui/icons-material/Delete';
// import ArrowForwardIosIcon from  '@mui/icons-material/ArrowForwardIos';
import PhoneForwardedIcon from  '@mui/icons-material/PhoneForwarded';
// import KeyboardArrowDownIcon from  '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from  '@mui/icons-material/KeyboardArrowUp';
import BlockIcon from  '@mui/icons-material/Block';
// import NewReleasesIcon from  '@mui/icons-material/NewReleases';

const DND_Icon = withStyles({
  root: {
    color: red[400]
  }
})(BlockIcon);

const CallForward_Icon = withStyles({
  root: {
    color: orange[600]
  }
})(PhoneForwardedIcon);


const DNDIcon = (props) => {
  return (
    <Tooltip title="Do Not Disturb" placement='top' arrow>
      <IconButton aria-label="Do Not Disturb" component="span" {...props} size="small">
        <DND_Icon />
      </IconButton>
    </Tooltip>
  )
};

const CallForwardIcon = ({data, ...props}) => {
  return (
    <Tooltip
      title={
        <React.Fragment>
          Call Forwading Enabled { data?.forward_all_destination }
        </React.Fragment>
      }
      placement='top'
      arrow
    >
      <IconButton aria-label="Call Forwarding" component="span" {...props} size="small">
        <CallForward_Icon />
      </IconButton>
    </Tooltip>
  )
};

export {
  DNDIcon,
  CallForwardIcon,
}
