import React from 'react';
import classNames from "classnames";
import { makeStyles, createStyles } from '@mui/styles';

import { WEBRTC_PHONE_DRAWER_WIDTH } from 'constants/Widths';

import { Card, CardBody, CardFooter } from 'components/Card';

import {
  Grid,
  Paper,
  Avatar,
  Typography,
  Box,
  Chip,
  TextField,
  Collapse
} from "@mui/material";

const useStyles = makeStyles(
  createStyles(theme => ({
    fixed: {
      width: '400px',
      position: 'fixed',

      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      float: 'right',

      // height: '200px',
      // maxWidth: '400px',
      // width: 'auto',
      //backgroundColor: '',
      zIndex: 1200,
      display: 'flex',
      alignItems: 'flex-end',
    },
    bottomRight: {
      bottom: (15) + 'px',
      right: (WEBRTC_PHONE_DRAWER_WIDTH + 15) + 'px'
    },
    container: {
      marginTop: '0px'
    }
  })
));

const FixedContainer = ({ children, ...props}) => {
  const classes = useStyles();

  const fixedClasses = classNames({
    [classes.fixed]: true,
    [classes.bottomRight]: true,
  });

  return (
    <div className={fixedClasses}>
      { children }
    </div>
  )

  // return (
  //   <div className={fixedClasses}>
  //     <Grid
  //       container
  //       spacing={1}
  //       // direction="column-reverse"
  //       direction="row"
  //       justifyContent="flex-end"
  //       alignItems="center"
  //       className={classes.container}
  //     >
  //       { children }
  //     </Grid>
  //   </div>
  // );
}

export default FixedContainer;
