import React, { Suspense } from 'react';

import { SimpleLoader } from 'components/Loaders';

export default (Component) => (props) => {
  return (
    <Suspense fallback={<SimpleLoader />}>
      <Component {...props} />
    </Suspense>
  )
};
