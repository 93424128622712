import React, { useState, useEffect } from 'react';

import {
  useNavigate,
  useLocation
} from "react-router-dom";

import { isEmpty, merge } from "lodash";

import CallIcon from '@mui/icons-material/Call';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ContactsIcon from '@mui/icons-material/Contacts';

import { usePhone, SessionsComponent } from 'Providers';

import { Contact_Telephone } from 'Classes';

import {
  Paper,
  Box,
  Typography,
  BottomNavigation,
  BottomNavigationAction
} from '@mui/material/';

//import _package from '../../../package.json';


// const navigationActions = [
//   { label: "Contacts", pathname: "/contacts", icon: <FormatListBulletedIcon />},
//   { label: "New Contact", pathname: "/contacts/new", icon: <AddBoxIcon /> },
// ]

const BottomNavigationBar = ({...props}) => {
  let mounted = true;
  const location = useLocation();
  const navigate = useNavigate();
  const { sessions } = usePhone();
  
  const [value, setValue] = useState(location?.pathname);

  useEffect(() => {
    return () => mounted = false;
  }, [])


  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 100 }} elevation={16}>
      <Box sx={{ width: '100%' }}>
        <BottomNavigation
          showLabels
          value={location.pathname}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <BottomNavigationAction label="Contacts" icon={<FormatListBulletedIcon />} value="/contacts" onClick={(e) => navigate("/contacts")} />
          <BottomNavigationAction label="New Contact" icon={<AddBoxIcon />} value="/contacts/new" onClick={(e) => navigate("/contacts/new")} />
          {
            sessions.map((session, index) => {
              const display_name = session?.remote_identity?._display_name;
              const number = session?.remote_identity?._uri?._user;

              let { id = "new", ...state } = session?.data?.contact ?? {};
              const pathname = `/contacts/${id}`;


              if(id === 'new') {
                state = merge(state, { telephones: [new Contact_Telephone({ number: number, type: 'business' })] });
              }

              return (
                <BottomNavigationAction key={index} label={display_name || number} icon={<ContactsIcon />} value={pathname} onClick={(e) => navigate(pathname, { state: isEmpty(state) ? null : state })} />
              )
            })
          }
        </BottomNavigation>
      </Box>
    </Paper>
  );
}

export default BottomNavigationBar;
//background: linear-gradient(60deg, rgb(38, 198, 218), rgb(0, 151, 167));
