import React, { useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import { Menu, Button, MenuItem, ListItemText, ListSubheader, Tabs, Tab } from '@mui/material';

import { Card, CardBody } from 'components/Card';

import { RANGE_OPTIONS } from 'constants/index.js';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 6px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  tab: {
    minWidth: 'inherit !important'
  }
}));

const RangeSelectionBar = ({ onChange, values, isTabletOrMobile, ...props}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleChange = (event, newValue) => onChange(event, newValue);

  const handleClose = (event, objValue) => {
    setAnchorEl(null);
    handleChange(event, objValue)
  };

  const largeScreens =
    <Tabs
      value={values.range}
      indicatorColor="primary"
      textColor="primary"
      onChange={(event, value) => handleChange(event, { range: value })}
      aria-label="disabled tabs example"
    >
      {
        RANGE_OPTIONS.map((item, index) => (
          <Tab key={index} component={Button} classes={{ root: classes.tab }} size='small' value={JSON.stringify(item.value)} label={item.label} />
        ))
      }
    </Tabs>

  const smallScreens =
    <>
      <Button aria-controls="range" aria-haspopup="true" onClick={handleClick} style={{ textTransform: 'capitalize' }}>
        { [...RANGE_OPTIONS].filter(item => values.range === JSON.stringify(item.value)).pop()?.label || '?' }
      </Button>
      <Menu
        id="range"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl) && anchorEl.getAttribute("aria-controls") === 'range'}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ListSubheader component="li">Duration</ListSubheader>
        {
          RANGE_OPTIONS.map((item, index) => {
            return (
              <MenuItem key={index} selected={values.range === JSON.stringify(item.value)} onClick={(event) => handleChange(event, { range: JSON.stringify(item.value) })} value={item.value}>
                {
                  // <ListItemIcon>
                  //   <Icon>{item.icon}</Icon>
                  // </ListItemIcon>
                }
                <ListItemText primary={item.label} />
              </MenuItem>
            )
          })
        }
      </Menu>
    </>


  return (
    <Card style={{ width: 'max-content', marginBottom: 0 }}>
      <CardBody style={{ padding: '8px 4px' }}>
        {
          isTabletOrMobile
          ? smallScreens
          : largeScreens
        }
      </CardBody>
    </Card>
  );
}

export default RangeSelectionBar;
