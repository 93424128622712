import React, { forwardRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { makeStyles } from '@mui/styles';
// @mui/icons-material

import MuiCard from '@mui/material/Card';

// core components
import styles from "assets/jss/components/cardStyle.js";

const useStyles = makeStyles(styles);

const Card = forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    className,
    listStyle,
    children,
    plain,
    profile,
    blog,
    raised,
    selected,
    background,
    pricing,
    well,
    color,
    product,
    testimonial,
    chart,
    fullHeight,
    listMargin,
    noMargin,
    login,
    container,
    style = {},
    ...rest
  } = props;

  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardListStyle]: listStyle,
    [classes.cardSelected]: selected,
    [classes.cardPlain]: plain,
    [classes.cardWell]: well,
    [classes.cardProfile]: profile || testimonial,
    [classes.cardBlog]: blog,
    [classes.cardRaised]: raised,
    [classes.cardBackground]: background,
    [classes.cardPricingColor]:
      (pricing && color !== undefined) || (pricing && background !== undefined),
    [classes[color]]: color,
    [classes.cardPricing]: pricing,
    [classes.cardProduct]: product,
    [classes.cardChart]: chart,
    [classes.cardLogin]: login,
    [classes.cardFullHeight]: fullHeight,
    [classes.cardNoMargin]: noMargin,
    [classes.cardListMargin]: listMargin,
    [classes.cardContainer]: container,
    [className]: className !== undefined
  });

  return (
    <MuiCard className={cardClasses} ref={ref} {...rest} style={Object.assign(style, { overflow: 'inherit' })}>
      {children}
    </MuiCard>
  );
});

Card.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  blog: PropTypes.bool,
  raised: PropTypes.bool,
  background: PropTypes.bool,
  pricing: PropTypes.bool,
  testimonial: PropTypes.bool,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "default"
  ]),
  product: PropTypes.bool,
  chart: PropTypes.bool,
  login: PropTypes.bool,
  children: PropTypes.node
};

export default Card;
