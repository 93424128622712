import React, { lazy, Suspense, useState, useEffect } from "react";
import { connect } from 'react-redux';

//import { sessions, phone, invite, PhoneStatusComponent, SessionsComponent } from 'WEBRTC/phoneManager';

import { Card, CardBody, CardHeader, CardFooter } from 'components/Card';
// import ErrorBoundary from 'components/ErrorBoundary';


import {
  ButtonGroup,
  Button,
  Grid
} from '@mui/material';

import Dialpad from 'WEBRTC/components/Dialpad.js';

import CallSplitIcon from '@mui/icons-material/CallSplit';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DialpadIcon from '@mui/icons-material/Dialpad';

import { ProgressbarSimple } from 'components/Loaders';

import { newLineState } from 'store/actions';
import audioPlayer from 'WEBRTC/audioPlayer';

const Pallet = ({ dispatch, children, ...props}) => {
  //const [state, setState] = useState(111);
  // const { sessions, add, remove, states  } = sessionStore;
  //
  // console.log('render', sessions);

  // const handleClick = (status) => async (event) => {
  //   await dispatch(newLineState(status))
  // }

  return (
    <Grid container justifyContent='center' spacing={2}>
      {
        // <Grid item xs={7}>
        //   <Button onClick={handleClick("PAIKALLA")}>
        //   PAIKALLA
        //   </Button>
        // </Grid>
        // <Grid item xs={7}>
        //   <Button onClick={handleClick("VARATTU")}>
        //   VARATTU
        //   </Button>
        // </Grid>
        // <Grid item xs={7}>
        //   <Button onClick={handleClick("RINGING")}>
        //   RINGING
        //   </Button>
        // </Grid>
      }


      {
        // <Grid item xs={7}>
        //   <Button style={{ backgroundColor: '#f44336' }} onClick={(e) => audioPlayer.play('dtmf')}>
        //   dtmf
        //   </Button>
        // </Grid>
      }

      {
        // <Grid item xs={7}>
        //   <ButtonGroup>
        //     <Button style={{ backgroundColor: '#f44336' }} onClick={(e) => remove()}>
        //       REMVE
        //     </Button>
        //     <Button color='success' variant="contained" onClick={(e) => {
        //       add(Math.random().toString())
        //     }}>
        //       ADD
        //     </Button>
        //   </ButtonGroup>
        // </Grid>
        // <Grid item xs={7}>
        //   sessions: { sessions.length }
        // </Grid>
      }
    </Grid>
  )
}

export default connect()(Pallet);
