import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// import CopyToClipboard from 'react-copy-to-clipboard';
import JsSIP from 'jssip';

import { firebase } from 'index.js';

// import UrlParse from 'url-parse';
import Logger from '../Logger';
import audioPlayer from '../audioPlayer';

import DialerBar from './DialerBar';

// import SessionRinging2 from './SessionRinging2';
import SessionRinging from './SessionRinging';
import SessionActive from './SessionActive';
import SessionDummy from './SessionDummy';

import FixedContainer from './FixedContainer';
import TabsComponent from './TabsComponent';
import MainDrawer from './MainDrawer';

import {
	FriendsComponent,
	CallLogComponent,
} from './Tabs';

import { Card } from 'components/Card';

// import {
// 	sessions,
// 	SessionsComponent,
// 	//DialerComponent,
// } from '../phoneManager';

import {
	usePhone,
	useSessions,
	SessionsComponent
} from 'Providers';


// import {
// 	DialerComponent,
// } from 'Providers';


import Notifycations from '../desktopNotifycations';

import { withFriendsContext } from '../Contexts/FriendsContext';
import CalllogContext, { withCallogContext } from 'Contexts/CalllogContext';
import { withSessionsContext } from 'Contexts/SessionsContext';
import PhoneContext, { withPhoneContext } from 'Contexts/PhoneContext';

import PhoneIcon from '@mui/icons-material/Phone';
import StarIcon from '@mui/icons-material/Star';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import AttendedTransferButton from './AttendedTransferButton';

import {
	Grid,
	TextField,
	Badge,
	Fade,
	Button,
	IconButton,
	MenuItem,
	Chip,
	Stack
} from '@mui/material';

// // TODO: For testing.
// window.jssip = JsSIP;

const MEDIA_CONSTRAITS = { audio: true, video: false };
const SESSION_DEFAULTS = {
	localHasVideo  : false,
	remoteHasVideo : false,
	localMuted		 : false,
	remoteMuted    : false,
	localHold      : false,
	remoteHold     : false,
	canHold        : false,
	ringing        : false,
	hasAnswer			 : false,
	hasStream			 : false
}


const logger = new Logger('Phone');

const SessionsContainer = (props) => {
	const { transferMode } = useSessions();

	return (
		<SessionsComponent>
			{
				(sessions) => {
					return sessions.map((session, index) => {
						return !session.data.hasAnswer
							? (
									<Grid item xs={12} key={index}>
										<SessionRinging
											session={session}
											onNotify={() => {}}
										/>
									</Grid>
								)
							: (
									<React.Fragment key={index}>
										{
											(index + 1) === sessions.length && sessions.length > 1 &&
												(
													<Grid item xs={12}>
														<AttendedTransferButton sessions={sessions} />
													</Grid>
												)
										}

										<Grid item xs={12}>
											<SessionActive
												session={session}
												sessionCount={sessions.length}
												transferMode={transferMode}
												onNotify={() => {}}
											/>
										</Grid>
									</React.Fragment>
								)
					})
				}
			}
		</SessionsComponent>
	)
}


const Phone = (props) => {
	let mounted = true;
	const { settings, account } = props;
  const { doUnregister } = usePhone();



	useEffect(() => {
		if(mounted) {
			setupBeforeUnloadListener();
			updateLineState("PAIKALLA");
		}
		return () => {
			mounted = false;
		}
	}, [])

	function setupBeforeUnloadListener() {
    window.addEventListener("beforeunload", async (ev) => {
			if(mounted) return;
      ev.preventDefault();
			// return ev.returnValue = "Are you sure you want to exit?";
			updateLineState('POISSA');
			doUnregister();
      return;
    });
  };

	function updateLineState(status = 'PAIKALLA') {
		window.dispatchEvent(new CustomEvent('update-line-state', { detail: status }));
	}

	return (
		<FixedContainer>
			<Grid container direction="column-reverse" id='janne'>
				<SessionsContainer />
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<DialerBar  />
				</Grid>
			</Grid>
		</FixedContainer>
	)
}

export default Phone;
