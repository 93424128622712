import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';
import {
  TextField,
  ListItemText,
  ListItemAvatar,
  Avatar,
  InputAdornment,
  Autocomplete
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 12
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));


const UserAccountAutoComplete = ({ data, loading, label, value, textFieldProps, onChange, ...props}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      onChange={onChange}
      value={value}
      options={[...data]}
      loading={loading}
      getOptionLabel={(option) => option?.username || String(option)}
      renderInput={(params) =>
        <TextField
          label={label}
          {...params}
          {...textFieldProps}
        />
      }
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <ListItemAvatar>
            <Avatar
              className={classes.small}
              style={{
                backgroundColor: option?.avatarColor || '#bdbdbd',
                color: '#fff'
              }}
              { ...option?.image && { src: option?.image } }
            >
              {option?.altName || '?' }
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={option.username} />
        </React.Fragment>
      )}
      {...props}
    />
  );
}

UserAccountAutoComplete.defaultProps = {
  value: null,
  data: [],
  loading: false,
};

export default UserAccountAutoComplete;
