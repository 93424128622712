import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
// import { purple } from '@mui/material/colors';

export const DarkerButton = styled(Button)({
  backgroundColor: 'rgba(0, 0, 0, 0.18)',
  textTransform: "none"
  // fontFamily: [
  //   '-apple-system',
  //   'BlinkMacSystemFont',
  //   '"Segoe UI"',
  //   'Roboto',
  //   '"Helvetica Neue"',
  //   'Arial',
  //   'sans-serif',
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(','),
  // '&:hover': {
  //   backgroundColor: '#0069d9',
  //   borderColor: '#0062cc',
  //   boxShadow: 'none',
  // },
  // '&:active': {
  //   boxShadow: 'none',
  //   backgroundColor: '#0062cc',
  //   borderColor: '#005cbf',
  // },
  // '&:focus': {
  //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  // },
});
