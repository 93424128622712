import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';

import { v4 as uuidv4 } from 'uuid';

import { Input, Box, Zoom, InputLabel, FormControl, FormHelperText, InputAdornment } from '@mui/material';

import CheckIcon from  '@mui/icons-material/Check';

import styles from "assets/jss/components/customInputStyle.js";

const useStyles = makeStyles(createStyles(styles));

const FormCreativeTextField = (
    { input: { onDragStart, onDrop, ...input }, meta: { asyncValidating, touched, dirty, error, warning, valid, ...custom }, ...props }
) => {
  const classes = useStyles();
  let inputProps;
  const {
    dragable,
    formControlProps,
    label,
    labelProps,
    white,
    inputRootCustomClasses,
    helperText,
    endAdornment,
    disableCheck,
    ...rest
  } = props;

  const id = `FormCreativeTextField-input-${uuidv4()}`;

  const showError = dirty && error;
  const success = valid && dirty && !showError ? true : false;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: showError,
    [" " + classes.labelRootSuccess]: success && !showError
  });
  const underlineClasses = classNames({
    [classes.underlineError]: showError,
    [classes.underlineSuccess]: success && !showError,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !showError
  });

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {
        label && <InputLabel
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {label}
        </InputLabel>
      }
      <Input
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        id={id}
        onDragStart={dragable ? onDragStart : null}
        onDrop={dragable ? onDrop : null}
        {...input}
        {...rest}
        endAdornment={
          <Box display="flex">
            { endAdornment }
            {
              !disableCheck && valid && touched &&
                <Zoom in>
                  <InputAdornment position='end'>
                    <CheckIcon className={classes.labelRootSuccess} />
                  </InputAdornment>
                </Zoom>
            }
          </Box>
        }
      />
      {
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          { helperText && (!warning && !error) && <span>{helperText}</span>}
          { touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </FormHelperText>
      }
    </FormControl>
  );
}

FormCreativeTextField.defaultProps = {
  dragable: false,
  disableCheck: false
};

FormCreativeTextField.propTypes = {
  label: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node
};

export default FormCreativeTextField;
