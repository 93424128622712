import React from "react";
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';

import {
  CircularProgress,
  IconButton
} from "@mui/material";

import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import AssignmentTurnedInIcon from  '@mui/icons-material/AssignmentTurnedIn';

import { missedCallAction } from 'store/missedcalls/actions';
import { LoadingStateComponent } from 'components/LoadingStates';
import { HelperTooltipBootstrap } from 'components/CustomTooltips';

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

const useStyles = makeStyles(defaultToolbarSelectStyles);


const MissedCallsCustomToolBarSelect = ({ dispatch, ...props }) => {
  const classes = useStyles();
  const { data, selectedRows, displayData, setSelectedRows } = props;
  const selectedData = selectedRows.data.map(row => data[row.dataIndex]);
  // handleClickInverseSelection = () => {
  //   const nextSelectedRows = this.props.displayData.reduce((nextSelectedRows, _, index) => {
  //     if (!this.props.selectedRows.data.find(selectedRow => selectedRow.index === index)) {
  //       nextSelectedRows.push(index);
  //     }
  //
  //     return nextSelectedRows;
  //   }, []);
  //
  //   this.props.setSelectedRows(nextSelectedRows);
  // };
  //
  // handleClickDeselectAll = () => {
  //   this.props.setSelectedRows([]);
  // };

  // const handleClickBlockSelected = (handleClickPromise) => (event) => {
  //   console.log(handleClickPromise)
  //   selectedRows.map((item) => {
  //     console.log(`item: ${item}`);
  //   })
  //   // console.log(`block users with dataIndexes: ${this.props.selectedRows.data.map(row => row.dataIndex)}`);
  //   // this.props.dispatch(missedCallAction(id, { handled: 0, ok: 'Odottaa' }))
  // };

  return (
    <div className={classes.iconContainer}>
      <HelperTooltipBootstrap title="Mark As Handled" placement='bottom' arrow>
        <span>
          <LoadingStateComponent>
            {
              ({ ref, loading, handleClickPromise, ...rest }) => {
                return (
                  <IconButton
                    ref={ref}
                    className={classes.iconButton}
                    onClick={
                      handleClickPromise(async(e) => {
                        await dispatch(missedCallAction(selectedData, { handled: 1, ok: 'Poistettu' }));
                        return true;
                      })
                    }
                  >
                    {
                        loading
                          ? <CircularProgress color="inherit" size={16} />
                          : <AssignmentTurnedInIcon />
                    }
                  </IconButton>
                )
              }
            }
          </LoadingStateComponent>
        </span>
      </HelperTooltipBootstrap>
    </div>
  );

}


export default connect(
  null,
  null,
)(MissedCallsCustomToolBarSelect);
//withStyles(MissedCallsCustomToolBarSelect, defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })
