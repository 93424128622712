import { firebase, store } from 'index.js';

import localstorage from 'localStorage';

// tabs friends ringgoups list
class Service_localstorage {
  constructor(storageName) {
    this.storageName = storageName;
  }

  subscribe(successCallback) {
    window.addEventListener(this.storageName, () => successCallback(localstorage.get(this.storageName)));
    successCallback(localstorage.get(this.storageName)); // iniate value!
  }

  unsubscribe(cb) {
    window.removeEventListener(this.storageName, () => cb());
  }
}

export {
  Service_localstorage
};
