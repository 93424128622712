import React from 'react';
import { withStyles } from '@mui/styles';
import { TableRow } from '@mui/material';

const OrangeSelectedTableRow = withStyles({
  selected: {
    backgroundColor: '#ffd9a269 !important'
  }
})(TableRow);

export {
  OrangeSelectedTableRow
};
