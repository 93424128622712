import { firebase, store } from 'index.js';

import { isEmpty } from 'lodash';

import { onError, enqueueSnackbar } from 'store/Notifier/actions';

import { uniqBy } from "lodash";

import { ReplaySubject, combineLatest } from "rxjs";
import { map, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { makeCollection, sortCollection, sortByTime, parseTypes, compare } from 'devtools/Helpers';

import { serviceCleanup } from './index';

const organizationsRef = function () {
  return firebase.contacts().child('organizations');
}

export const service_organization = function (id) {
  const observable = new ReplaySubject();

  let subscriptions = [];

  const refs = [
    firebase.doFirebaseObservableQuery(organizationsRef().child(id), "on", "value", observable, false),
  ];

  const observable$ = observable
    .pipe(
      distinctUntilChanged(),
    );

  function add_subscriber(callback) {
    return subscriptions.push(
      observable$.subscribe(
        callback,
        err => console.error('service_organization', err)
      )
    ); // add to array and return subscription!
  }

  return {
    add_subscriber: add_subscriber,
    remove_subscriber: (subscription) => serviceCleanup(subscription, refs, subscriptions),
  }
}

// actions --> service_organizations
