import React, { useState, useEffect, Suspense } from 'react';
import { connect } from 'react-redux';

import { Field, change, reduxForm, SubmissionError } from 'redux-form';

import CloseIcon from  '@mui/icons-material/Close';
import PhoneAndroidIcon from  '@mui/icons-material/PhoneAndroid';
import DialerSipIcon from  '@mui/icons-material/DialerSip';
//import MoreVertIcon from  '@mui/icons-material/MoreVert';
//import DeleteIcon from  '@mui/icons-material/Delete';
//import ArrowForwardIosIcon from  '@mui/icons-material/ArrowForwardIos';
//import PhoneForwardedIcon from  '@mui/icons-material/PhoneForwarded';
import ExtensionIcon from  '@mui/icons-material/Extension';
//import KeyboardArrowDownIcon from  '@mui/icons-material/KeyboardArrowDown';
//import KeyboardArrowUpIcon from  '@mui/icons-material/KeyboardArrowUp';

import vaihdeLogoXS from "assets/img/Icon-Small.png";

import RegularButton from 'components/CustomButtons';
import CustomTable from 'components/CustomTable';

import { Card, CardIcon, CardHeader, CardBody, CardFooter } from 'components/Card';

//import UserCallForwardingTable from './UserCallForwardingTable';
//import { ExtensionCallForwardingTable } from 'components/Extensions';

import { CalleridAutoComplete } from 'components/CustomAutoComplete';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import { AvatarLight, AvatarDark } from 'components/CustomAvatar';

import { FormCalleridAutoComplete, FormTextField } from 'components/Forms/Components';

import { Collapse, Chip, Box,  Badge, TableCell, TableRow, Checkbox, Divider, Avatar, Grid } from '@mui/material';

import { updateExtensionsAction } from 'store/extensions/actions';
import { setFormValues } from 'store/form/reducer';

import { chain, keyBy, value } from 'lodash';

const headCells = [
  // { id: 'collapse', alignment: 'center', display: true, padding: 'checkbox', sort: false, label: <Tooltip title="Call Forwarding Options" placement='top' arrow><PhoneForwardedIcon /></Tooltip> },
  { id: 'extension', alignment: 'left', display: true, padding: 'normal', sort: true, label: 'Extension' },
  { id: 'Description', alignment: 'left', display: true, padding: 'none', sort: false, label: 'Description' },
  { id: 'Outbound_callerid', alignment: 'left', display: true, padding: 'normal', sort: false, label: 'Outbound CallerID' },
  // { id: 'actions', alignment: 'center', display: true, padding: 'none', sort: false, label: 'Actions' },
];

const FORM = 'UserExtensionsTable';

const UserExtensionsTable = ({ profile, updateExtensions, classes, initialValues, setFormValues, ...props }) => {
  const { handleSubmit, pristine, dirty, reset, submitting, change } = props;
  const [data, setData] = useState([]);
  const extensions = profile?.extensions || [];

  useEffect(() => {
    setFormValues(chain(extensions).keyBy('id').value());
    setData(extensions)
  }, [profile])

  async function submit(formvalues) {
    try {
      let obj = {};
    	for(let [key, {id, ...value}] of Object.entries(formvalues)) {
      	obj[key] = value;
      }

      await updateExtensions(obj);
      return true;
    } catch (err) {
      throw new SubmissionError({
        submit: 'Submiting Error',
        _error: err,
      });
    } finally {
      //setTimeout(() => reset(), 500);
    }
  }

  return initialValues &&
    <form onSubmit={handleSubmit(submit)} autoComplete='off'>
      <Card>
        <CardHeader color="success" icon style={{ paddingBottom: 20 }}>
          <CardIcon color="success">
            <ExtensionIcon />
          </CardIcon>
          <div className={classes.cardCategory}>
            Extensions
          </div>
        </CardHeader>
        <Divider />
        <CardBody noPadding>
          <CustomTable
            data={data}
            headCells={headCells}
            options={{
              id: 'UserExtensionsTable',
              dense: false,
              order: 'asc',
              orderBy: '',
              searchComponent: false,
              checkboxCollumn: {
                index: 0,
                display: true
              },
            }}
            components={{
              defaultRow: <Chip label="No Extensions Found!" />,
              selectedToolbar: function(selected, clearSelected) {
                return (
                  <Grid container justifyContent="center" alignItems="center" direction="row" spacing={1}>
                    <Grid item>
                      <HelperTooltipBootstrap title="Outbound CallerID" placement='top' arrow>
                        <div>
                          <Card container>
                            <CalleridAutoComplete
                              label="Outbound CallerID"
                              onChange={(event, value, reason) => {
                                try {
                                  for(let { id, ...item} of selected()) {
                                    change(`${id}.outbound_caller_id_number`, value.cc_number);
                                  }
                                  clearSelected()
                                } catch (e) {
                                  console.log(e);
                                }
                              }}
                              style={{ minWidth: 240 }}
                              textFieldProps={{
                                placeholder: "-",
                                variant: "outlined",
                                size: "small",
                                InputLabelProps: {
                                  shrink: true,
                                }
                              }}
                            />
                          </Card>
                        </div>
                      </HelperTooltipBootstrap>
                    </Grid>

                    <Grid item>
                      <HelperTooltipBootstrap title="Unassign Selected Extensions" placement='top' arrow>
                        <RegularButton
                          justIcon
                          round
                          color="white"
                          onClick={(event) => {
                            let data = {};
                            for(let { id, userid, ...item } of selected()) {
                              // removing userid from object
                              data[id] = item;
                            }
                            updateExtensions(data)
                              .then(() => clearSelected())
                          }}
                        >
                          <CloseIcon style={{ color: 'black' }}/>
                        </RegularButton>
                      </HelperTooltipBootstrap>
                    </Grid>
                  </Grid>
                );
              },
              rowCells: function(row, index, rowClick, isRowSelected, labelId, disableRowSelectOnClick, collapse, toggleCollapse) {
                return (
                  <React.Fragment key={labelId}>
                    <TableRow
                      hover
                      onClick={(event) => rowClick(event, row)}
                      role="checkbox"
                      aria-checked={isRowSelected}
                      tabIndex={-1}
                      selected={isRowSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isRowSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>

                      {
                        // <TableCell padding="checkbox" align="center" onClick={disableRowSelectOnClick}>
                        //   <IconButton
                        //     aria-label="expand row"
                        //     onClick={(e) => {
                        //       e.stopPropagation();
                        //       toggleCollapse(index);
                        //     }}
                        //   >
                        //     {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        //   </IconButton>
                        // </TableCell>
                      }

                      <TableCell padding="normal" align="left">
                        <Box display='flex' justifyContent='flex-start' alignItems='center'>
                          <Box>
                            {
                              Number(profile?.dialer?.extension) === Number(row?.extension) && vaihdeLogoXS
                                ? <Suspense fallback={<div />}>
                                    <HelperTooltipBootstrap title="VaihdeApp" placement="bottom">
                                      <div>
                                        <Badge
                                          overlap="circular"
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                          }}
                                          badgeContent={
                                            <Box fontSize={12} borderRadius="50%" overflow="hidden">
                                              { vaihdeLogoXS && <img alt='' style={{ height: '20px' }} src={vaihdeLogoXS} /> }
                                            </Box>
                                          }
                                        >
                                          <AvatarDark>
                                            <PhoneAndroidIcon />
                                          </AvatarDark>
                                        </Badge>
                                      </div>
                                    </HelperTooltipBootstrap>
                                  </Suspense>
                                : row?.sip_client
                                    ? <AvatarLight>
                                        <DialerSipIcon />
                                      </AvatarLight>
                                    : <AvatarDark>
                                        <PhoneAndroidIcon />
                                      </AvatarDark>
                            }
                          </Box>
                          <Box px={2} display='flex' justifyContent='flex-start' flexDirection="column">
                            {row?.extension}
                            { row?.number_alias && <Chip size='small' label={<small>{row?.number_alias}</small>} /> }
                          </Box>
                        </Box>


                      </TableCell>

                      <TableCell padding="none" align="left" onClick={disableRowSelectOnClick}>
                        <Field
                          component={FormTextField}
                          label="Description"
                          name={`${row?.id}.description`}
                          fullWidth
                          placeholder="-"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </TableCell>

                      <TableCell padding="normal" align="center" onClick={disableRowSelectOnClick}>
                        <Field
                          component={FormCalleridAutoComplete}
                          label="Outbound CallerID"
                          name={`${row?.id}.outbound_caller_id_number`}
                          fullWidth
                          customTextField={{
                            placeholder: "-",
                            variant: "outlined",
                            size: "small",
                            InputLabelProps: {
                              shrink: true,
                            }
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              }
            }}
          />
        </CardBody>
        <Collapse in={dirty && !pristine} timeout="auto" unmountOnExit>
          <CardFooter>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <RegularButton
                  type="submit"
                  size='sm'
                  color="success"
                  round
                  disabled={pristine || submitting}
                >
                  <Box pr={1} display={submitting ? 'block' : 'none' }>
                    <i className={"fas fa-spinner fa-spin"} />
                  </Box>
                  Apply
                </RegularButton>

              </Grid>
              <Grid item>
                <RegularButton
                  type="button"
                  size='sm'
                  color="warning"
                  round
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Undo
                </RegularButton>
              </Grid>
            </Grid>
          </CardFooter>
        </Collapse>
      </Card>
    </form>
}

const mapStateToProps = state => ({
  initialValues: state.formState[FORM] || {},  // pull initial values from form reducer
});

const mapDispatchToProps = dispatch => ({
  updateExtensions: (data) => dispatch(updateExtensionsAction(data)),
  setFormValues: (data) => dispatch(setFormValues(FORM, data)),
  change,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM, // a unique identifier for this form
    enableReinitialize: true,
    // onChange: (values) => console.log(values)
    // validate,
    // asyncValidate,
  })(UserExtensionsTable)
)
