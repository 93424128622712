import React from 'react';
import { Tooltip, Avatar } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

import classNames from "classnames";

import styles from "assets/jss/components/badgeStyle";
const useStyles = makeStyles(createStyles(styles));

const COLORS = {
  danger: {
    color: '#fff',
    background: 'linear-gradient(60deg, #ef5350, #e53935)',
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(244, 67, 54,.4)'
  },
  success: {
    color: '#fff',
    background: 'linear-gradient(60deg, #66bb6a, #43a047)',
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(76, 175, 80,.4)'
  },
  default: {
    background: '#bdbdbd',
    color: '#fff'
  }
}

const NewCustomAvatar = ({ tooltipProps, avatarProps, children, color, size, ...props}) => {
  const classes = useStyles();

  const avatarClasses = classNames({
    [classes.avatar]: true,
    [classes['avatar-small']]: size === 'small',
    [classes['avatar-large']]: size === 'large',
    [classes['avatar-profile']]: size === 'profile'
  });

  return (
    <Tooltip {...tooltipProps}>
      <Avatar
        className={avatarClasses}
        style={COLORS[color || 'default']}
        {...avatarProps}
      >
        {children}
      </Avatar>
    </Tooltip>
  )
}
export default NewCustomAvatar;
