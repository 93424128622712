import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  Menu
} from '@mui/material';

const dropdownContext = createContext();


const DropDownMenu = (props) => {
  const { anchorEl, handleClose, handleMenuItemClick, open, state, children, ...rest } = props;

  // function stopPropagation(e) {
  //   e.push();
  // }

  return open &&  state && (
    <Menu
      anchorEl={anchorEl}
      id="dropdown-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      MenuListProps={{
        sx: {
          padding: 0
        }
      }}
      PaperProps={{
        elevation: 8,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          minWidth: anchorEl.clientWidth,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {children}
    </Menu>
  )
}

export function useDropDownProvider() {
  let mounted = true;
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    return () => {
      mounted = false;
    }
  }, [])

  const handleClick = (newState) => (event) => {
    if(!mounted) return;
    // handleClick(item)(event);
    const element = event?.tagName
      ? event
      : (event?.currentTarget || event.target)

    setAnchorEl(element);
    setState(newState);
  };

  const handleMenuItemClick = (callback) => async (event) => {
    if(!mounted) return;
    await callback(event);
    handleClose(event)
  };

  const handleClose = (event) => {
    if(!mounted) return;
    setAnchorEl(null);
    setState(null);
  };

  return {
    anchorEl,
    state,
    open,
    handleClick,
    handleClose,
    handleMenuItemClick,
    DropDownMenu,
  }

}

export function useDropDown() {
  return useContext(dropdownContext);
}

export function DropDownProvider({ children, PopperComponent }) {
  const { handleClick, ...props} = useDropDownProvider();

  return (
    <dropdownContext.Provider value={{ handleClick }}>
      {children}
      <DropDownMenu {...props}>
        <PopperComponent {...props} />
      </DropDownMenu>
    </dropdownContext.Provider>
  )
}

export const withDropDown = (Component, PopperComponent) => (props) => {
  return (
    <DropDownProvider PopperComponent={PopperComponent}>
      <Component {...props} />
    </DropDownProvider>
  )
}
