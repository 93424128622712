import { firebase, store } from 'index.js';

import { isEmpty, uniqBy, keyBy, merge } from 'lodash';

import { v4 as uuidv4 } from 'uuid';

import { onError, enqueueSnackbar } from 'store/Notifier/actions';

import { ReplaySubject, combineLatest } from "rxjs";
import { map, distinctUntilChanged } from 'rxjs/operators';

import { makeCollection, sortCollection, sortByTime, parseTypes, compare } from 'devtools/Helpers';

import { serviceCleanup } from './index';

// tämä toimii hakuna!
// firebase.contacts().child("telephones")
// .orderByChild('+358931521916')
// .startAt("+358931521916")
// .once("value")
// .then((snapshot) => console.log('math orderByChild +358931521916', snapshot.val()))



// Contact page
export const service_contact_class = class {
  #observable_general$;
  #observable_emails$;
  #observable_telephones$;
  #observable_search_tags$;
  #subscriptions;
  #observable;
  #refs;

  constructor(id) {
    if(!id) return {}; // return no instance!
    
    this.#observable_general$ = new ReplaySubject();
    this.#observable_emails$ = new ReplaySubject();
    this.#observable_telephones$ = new ReplaySubject();
    this.#observable_search_tags$ = new ReplaySubject();

    this.#subscriptions = [];

    this.#observable = combineLatest(this.#observable_general$, this.#observable_emails$, this.#observable_telephones$, this.#observable_search_tags$)
      .pipe(
        map(([general, emails, telephones, search_tags]) => {
          return {
            id: id,
            ...general,
            emails: Object.values(emails ?? {}) ?? [],
            telephones: Object.values(telephones ?? {}) ?? [],
            search_tags: Object.values(search_tags ?? {}) ?? [],
          }
        }),
        distinctUntilChanged(),
      );

    this.#refs = [
      firebase.doFirebaseObservableQuery2(firebase.contacts().child("general").child(id), "on", "value", (snapshot) => this.#observable_general$.next(snapshot.toJSON())),
      firebase.doFirebaseObservableQuery2(firebase.contacts().child("emails").child(id), "on", "value", (snapshot) => this.#observable_emails$.next(snapshot.toJSON())),
      firebase.doFirebaseObservableQuery2(firebase.contacts().child("telephones").orderByChild('contact').equalTo(id), "on", "value", (snapshot) => this.#observable_telephones$.next(snapshot.toJSON())),
      firebase.doFirebaseObservableQuery2(firebase.contacts().child("search_tags").child(id), "on", "value", (snapshot) => this.#observable_search_tags$.next(snapshot.toJSON())),
    ];

    return this;
  }

  add_subscriber(cbSucess, cbError) {
    return this.#subscriptions.push(
      this.#observable.subscribe(
        cbSucess,
        err => {
          console.warn('service_contact', err)
          cbError(err)
        }
      )
    ); // add to array and return subscription!
  }

  remove_subscriber(subscription) {
    serviceCleanup(subscription, this.#refs, this.#subscriptions)
    return;
  }
}
