import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';

import MusicNoteIcon from  '@mui/icons-material/MusicNote';
import AccountTreeIcon from  '@mui/icons-material/AccountTree';
// import ExpandLess from  '@mui/icons-material/ExpandLess';
// import ExpandMore from  '@mui/icons-material/ExpandMore';

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { Chip, Typography, ListSubheader, ListItemAvatar, Avatar, Menu, MenuItem, MenuList, ListItemSecondaryAction } from '@mui/material';

//import { announcementsActions } from 'store/announcements/actions';
import { ringgroups_Actions } from 'store/ringgroups/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  secondaryAction: {
    paddingRight: '80px'
  },
  primary: {
    fontSize: '12px'
  }
}));

const DestinationMenu = ({ announcementsLoading, ringgroupsLoading, announcements, ringgroups, getAnnouncements, getRinggroups, listItemSecondaryAction, useNone, value: valueProp, ...props}) => {
  let { onChange, customProps, listItemProps, chipProps } = props;
  const classes = useStyles();

  const [selected, setSelected] = useState(valueProp || "");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (announcementsLoading === null) {
      getAnnouncements();
    }
  }, [announcements, announcementsLoading]);

  useEffect(() => {
    if (ringgroupsLoading === null) {
      getRinggroups();
    }
  }, [ringgroups, ringgroupsLoading]);

  useEffect(() => {
    if(valueProp) setSelected(valueProp);
  }, [valueProp]);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, selectedValue) => {
    setAnchorEl(null);
    setSelected(selectedValue)
    onChange(selectedValue);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showText = () => {
    const arr = selected.split('=');
    return arr.length > 1 ? arr[1] : selected;
  };

  const noMenuItem =
    <MenuList>
      <MenuItem
        selected={"" === selected}
        onClick={(event) => handleMenuItemClick(event, "")}
      >
        None
      </MenuItem>
    </MenuList>

  return (
    <>
      <ListItem
        button
        classes={{
          secondaryAction: classes.secondaryAction
        }}
        aria-haspopup="true"
        aria-controls="lock-menu"
        name='ListItem'
        aria-label={customProps.label}
        onClick={handleClickListItem}
        {...listItemProps}
      >
        <ListItemText
          disableTypography={true}
          primary={<Typography>{customProps.label}</Typography>}
          secondary={
            <Chip {...chipProps} label={<small>{ selected === '' ? 'None' : showText() }</small> } />
          }
        />

        { listItemSecondaryAction
          ?
            <ListItemSecondaryAction>
              { listItemSecondaryAction }
            </ListItemSecondaryAction>
          : ''
        }

      </ListItem>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          useNone
          ? noMenuItem
          : ''
        }
        <MenuList
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">Announcements</ListSubheader>
          }
        >
          { announcements ? announcements.map((option, index) => {
            const optionValue = 'streamfile.lua=' + option.recording_filename;
            return (
              <MenuItem
                style={{ minWidth: '300px '}}
                key={option.recording_uuid}
                selected={optionValue === selected}
                onClick={(event) => handleMenuItemClick(event, optionValue)}
              >
                <ListItemAvatar>
                  <Avatar>
                    <MusicNoteIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<span>{option.recording_description}</span>}
                  secondary={<small>{option.recording_filename}</small>}
                />
              </MenuItem>
            )
          }) : <></>}
        </MenuList>
        <MenuList
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">Ring Groups</ListSubheader>
          }
        >
          { ringgroups ? ringgroups.map(option => {
            const optionValue = 'transfer=' + option.dialplan_destination;
            return (
              <MenuItem
                key={option.ring_group_uuid}
                selected={optionValue === selected}
                onClick={(event) => handleMenuItemClick(event, optionValue)}
              >
                <ListItemAvatar>
                  <Avatar>
                    <AccountTreeIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<span>{option.ring_group_name}</span>}
                  secondary={<small>{option.ring_group_extension}</small>}
                />
              </MenuItem>
            )
          }) : <></>}
        </MenuList>
      </Menu>
    </>
  );
}

const mapStateToProps = state => ({
  announcements: state.announcements_State.announcements || [],
  announcementsLoading: state.announcements_State.loading,
  ringgroups: state.ringgroups_State.ringgroups || [],
  ringgroupsLoading: state.ringgroups_State.loading
});

const mapDispatchToProps = dispatch => ({
  getAnnouncements: () => {
    //dispatch(announcementsActions())
  },
  getRinggroups: () => { dispatch(ringgroups_Actions()) }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DestinationMenu);
