import React, { Component } from "react";
import { compose } from 'redux';
import { connect } from 'react-redux';
// @mui/material components
import { withStyles } from '@mui/styles';
// core components
import { ProfileStateDropDown } from "components/Dropdowns";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import FingerprintIcon from  '@mui/icons-material/Fingerprint';
import { Grid, Typography, Box, Chip, CardContent, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import AddCircleIcon from  '@mui/icons-material/AddCircle';
import CustomAvatar from "components/CustomAvatar";
import { SimpleLoader } from 'components/Loaders';
//import ProfileWizard from 'components/Wizards/ProfileWizard';
import MailIcon from  '@mui/icons-material/Mail';

//import avatar from "assets/img/faces/marc.jpg";
// import vaihdeLogoXL from "assets/img/Icon-60@87.png";
// import vaihdeLogoMD from "assets/img/Icon-Notification@3x.png";
import vaihdeLogoXS from "assets/img/Icon-Small.png";

import styles from "assets/jss/views/dashboardStyle.js";

//import { profile_Actions } from 'store/profile/actions';
import { passwordResetAction } from 'store/passwordreset/actions';

class UserPriveligedProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      callerid: props.profile?.callerID ? props.profile.callerID : '-'
    };
  }

  handleChange(event) {
    this.setState({callerid: event.target.value});
  };

  render() {
    const { currentUser, profile, classes } = this.props;
    //const { callerid } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          { currentUser && profile ? (
            <div style={{ padding: 20 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4}>
                  <Card profile>
                    <CardAvatar profile>
                      <CustomAvatar defaultColor={profile?.avatarColor} src={profile?.image} size='profile'>{this.state.altName}</CustomAvatar>
                    </CardAvatar>
                    <CardBody profile>
                      <h3 className={classes.cardCategory}>{profile?.username}</h3>
                      <Box p={0}>
                        <small className={classes.cardCategoryGray}>{profile?.email ? profile.email : 'example@example.fi' }</small>
                      </Box>
                      <Box pt={4} px={4} pb={1} >
                        <ProfileStateDropDown user={profile} />
                      </Box>
                      <CardContent>
                        <List
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                          className={classes.root}
                        >
                          <Divider />
                          { currentUser?.uid === profile?.uid ? (
                            <ListItem button onClick={() => this.props.history.push('/wizard/changePassword')}>
                              <ListItemIcon>
                                <FingerprintIcon />
                              </ListItemIcon>
                              <ListItemText primary="Change Password" />
                            </ListItem>
                          ) : (
                            <ListItem button onClick={(e) => profile?.email ? this.props.sendResetMail(profile.email) : e.preventDefault() }>
                              <ListItemIcon>
                                <MailIcon />
                              </ListItemIcon>
                              <ListItemText primary="Send Passsword Reset" />
                            </ListItem>
                          )}
                          <Divider />
                        </List>
                      </CardContent>
                    </CardBody>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  { profile?.extension ? (
                    <GridContainer>
                      { !profile?.number ? (
                        <GridItem xs={12}>
                          <Card>
                            <CardHeader color="info" stats icon>
                              <CardIcon color="rose">
                                <Box px={2} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                                  <Typography variant="body1" gutterBottom>Extension</Typography>
                                  <Chip label={profile.extension} />
                                </Box>
                              </CardIcon>
                              <div className={classes.cardCategory}>
                                <Box p={1}><Chip label={profile?.number} /></Box>
                              </div>
                            </CardHeader>
                            <CardContent>
                              <Divider />
                              <ListItem button>
                                <ListItemIcon>
                                  <AddCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Setup Vaihde App" />
                                <img alt='' src={vaihdeLogoXS} />
                              </ListItem>
                              <Divider />
                            </CardContent>
                          </Card>
                        </GridItem>
                      ) : (
                        <GridItem xs={12}>
                          <Card>
                            <CardHeader color="info" stats icon>
                              <CardIcon color="rose">
                                <Box px={2} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                                  <Typography variant="body1" gutterBottom>Extension</Typography>
                                  <Chip label="3614" />
                                </Box>
                              </CardIcon>
                              <div className={classes.cardCategory}>
                                <Box p={1}><Chip label="0447645443" /></Box>
                              </div>
                            </CardHeader>
                            <CardContent>
                              <Divider />
                              <ListItem button>
                                <ListItemIcon>
                                  <img alt='' src={vaihdeLogoXS} />
                                </ListItemIcon>
                                <ListItemText primary="Vaihde App" />
                                <small>{profile?.callerID}</small>
                                {/*
                                <TextField
                                  display="flex"
                                  id="timeout"
                                  select
                                  label="CallerID"
                                  value={profile.callerID}
                                  onChange={this.handleChange.bind(this)}
                                  variant="outlined"
                                  size='small'
                                  disabled={true}
                                >
                                  {callerids.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.value}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                */}
                              </ListItem>
                              <Divider />
                            </CardContent>
                          </Card>
                        </GridItem>
                      ) }

                    </GridContainer>
                  ) : '' }

                </Grid>
              </Grid>
            </div>
          ) : (
            <SimpleLoader />
          )}
        </GridItem>
      </GridContainer>
    );
  };
}


const mapStateToProps = state => ({
  currentUser: state.sessionState.authUser,
  profile: state.profile_State.profile,
  loading: state.profile_State.loading
});


const mapDispatchToProps = dispatch => ({
  //fetchProfile: (id) => { dispatch(profile_Actions(id)) },
  fetchProfile: (id) => { },
  sendResetMail: (mail) => { dispatch(passwordResetAction(mail)) }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withStyles(styles),
    UserPriveligedProfile
  )
);
