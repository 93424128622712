import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';
// @mui/icons-material

import CardContent from '@mui/material/CardContent';

// core components
import styles from "assets/jss/components/cardBodyStyle.js";

const useStyles = makeStyles(createStyles(styles));

export default function CardBody(props) {
  const classes = useStyles();
  const {
    className,
    children,
    background,
    plain,
    formHorizontal,
    pricing,
    signup,
    color,
    profile,
    calendar,
    paddingTop,
    noPadding,
    paddingSm,
    ...rest
  } = props;
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardPaddingTop]: paddingTop,
    [classes.cardNoPadding]: noPadding,
    [classes.cardPaddingSm]: paddingSm,
    [classes.cardBodyBackground]: background,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyFormHorizontal]: formHorizontal,
    [classes.cardPricing]: pricing,
    [classes.cardSignup]: signup,
    [classes.cardBodyColor]: color,
    [classes.cardBodyProfile]: profile,
    [classes.cardBodyCalendar]: calendar,
    [className]: className !== undefined
  });
  return (
    <CardContent className={cardBodyClasses} {...rest}>
      {children}
    </CardContent>
  );
}

CardBody.propTypes = {
  className: PropTypes.string,
  background: PropTypes.bool,
  plain: PropTypes.bool,
  formHorizontal: PropTypes.bool,
  pricing: PropTypes.bool,
  signup: PropTypes.bool,
  color: PropTypes.bool,
  profile: PropTypes.bool,
  calendar: PropTypes.bool,
  children: PropTypes.node
};
