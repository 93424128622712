import React from 'react';
import { Switch, FormGroup, FormControl, FormLabel, FormControlLabel, FormHelperText } from '@mui/material';

const FormSwitch = (
  { input: { value, ...rest}, label, meta: { touched, error }, useTheme, color, formControlLabelProps, ...custom },
) => {
  const Component = useTheme || Switch;

  return (
    <FormControl error={Boolean(error)} component="fieldset">
      {
        label && <FormLabel component="legend" style={{ margin: 0 }}>{label}</FormLabel>
      }
      <FormGroup>
        <FormControlLabel
          {...formControlLabelProps}
          control={<Component checked={Boolean(value)} color={color} {...rest} />}
        />
      </FormGroup>
      {
        touched && Boolean(error) && <FormHelperText>{ error }</FormHelperText>
      }
    </FormControl>
  );
}

export default FormSwitch;
