import React, { createContext, useContext, useEffect, useState } from 'react';
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

const DEFAULT_OPTIONS = {
  suppressScrollX: true,
  suppressScrollY: false,
  wheelPropagation: true,
  minScrollbarLength: 30,
  wheelSpeed: 1.3,
}

const scrollbarContext = createContext();

export function useScrollbarProvider(options, scrollRef) {
  let mounted = true, ps;

  useEffect(() => {
    if(mounted && navigator.platform.indexOf("Win") > -1 && scrollRef?.current) {
      //console.log('scrollRef', scrollRef?.current)
      if(!ps) {
        ps = new PerfectScrollbar(scrollRef?.current, options);
      } else {
        ps.update(options);
      }
    }

    return () => {
      if (ps && navigator.platform.indexOf("Win") > -1) ps.destroy();
      mounted = false;
    }
  }, [])

  return {
    options
  }
}

export function useScrollbar() {
  return useContext(scrollbarContext);
}

export function ScrollbarProvider({ children, options: _options, scrollRef }) {
  const { options } = useScrollbarProvider(_options, scrollRef);

  return (
    <scrollbarContext.Provider value={options}>
      {children}
    </scrollbarContext.Provider>
  )
}

export const withScrollBar = (Component, options = DEFAULT_OPTIONS) => (props) => {
  const scrollRef = React.useRef();

  return (
    <ScrollbarProvider options={options} scrollRef={scrollRef}>
      <Component {...props} scrollRef={scrollRef} />
    </ScrollbarProvider>
  );
}
