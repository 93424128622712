import React from "react";
import { Box, Chip, Divider } from '@mui/material';

import HelpIcon from  '@mui/icons-material/Help';

const CustomDivider = ({label, boxProps, chipProps, ...props}) => {
  return (
    <Box {...boxProps}>
      <Divider {...props} />
      {label && <Chip {...chipProps} label={<b style={{ padding: '0px 10px' }}>{label}</b>} style={{ marginTop: '-24px' }} />}
    </Box>
  )
}

CustomDivider.defaultProps = {
  variant: "inset",
  chipProps: {
    size: 'small',
    icon: <HelpIcon />
  },
  boxProps: {}
}

export * from './styled';

export {
  CustomDivider as default
}
