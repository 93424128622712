import React, { useEffect, lazy, Suspense, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import LinearChart from './LinearChart';

import {
  Grid,
  Alert
} from '@mui/material';

import { MissedCallsChartOptions } from 'Helpers/Charts';

import { ErrorBoundaryChart } from 'components/ErrorBoundary';
import ErrorBoundary from 'components/ErrorBoundary';

// import { Badge, Box, Tooltip } from '@mui/material';

function getOptions(filters, data) {
  const { view } = filters;
  const charts = [];

  // if(view === 'Hourly View' || view === 'All Charts') {
  //   charts.push([
  //     {...filters, view: 'Hourly View', state: 'All', filterData: [...data.handledCalls,...data.unhandledCalls]},
  //     {...filters, view: 'Hourly View', state: 'Handled', filterData: [...data.handledCalls]},
  //     {...filters, view: 'Hourly View', state: 'Unhandled', filterData: [...data.unhandledCalls]}
  //   ])
  // }
  //
  // if(view === 'Daily View' || view === 'All Charts') {
  //   charts.push([
  //     {...filters, view: 'Daily View', state: 'All', filterData: [...data.handledCalls,...data.unhandledCalls]},
  //     {...filters, view: 'Daily View', state: 'Handled', filterData: [...data.handledCalls]},
  //     {...filters, view: 'Daily View', state: 'Unhandled', filterData: [...data.unhandledCalls]}
  //   ])
  // }
  //
  // if(view === 'Monthly View' || view === 'All Charts') {
  //   charts.push([
  //     {...filters, view: 'Monthly View', state: 'All', filterData: [...data.handledCalls,...data.unhandledCalls]},
  //     {...filters, view: 'Monthly View', state: 'Handled', filterData: [...data.handledCalls]},
  //     {...filters, view: 'Monthly View', state: 'Unhandled', filterData: [...data.unhandledCalls]}
  //   ])
  // }
  //
  // if(view === 'Handled By' || view === 'All Charts') {
  //   charts.push([
  //     {...filters, view: 'Handled By', state: 'Handled', filterData: [...data.handledCalls]},
  //   ])
  // }

  if(view === 'Hourly View' || view === 'All Charts') {
    charts.push([
      //{...filters, view: 'Hourly View', state: 'All', filterData: [...data.handledCalls,...data.unhandledCalls]},
      {...filters, view: 'Hourly View', state: 'Handled', filterData: [...data.handledCalls]},
      {...filters, view: 'Hourly View', state: 'Unhandled', filterData: [...data.handledCalls,...data.unhandledCalls]}
    ])
  }

  if(view === 'Daily View' || view === 'All Charts') {
    charts.push([
      //{...filters, view: 'Daily View', state: 'All', filterData: [...data.handledCalls,...data.unhandledCalls]},
      {...filters, view: 'Daily View', state: 'Handled', filterData: [...data.handledCalls]},
      {...filters, view: 'Daily View', state: 'Unhandled', filterData: [...data.handledCalls,...data.unhandledCalls]}
    ])
  }

  if(view === 'Monthly View' || view === 'All Charts') {
    charts.push([
      //{...filters, view: 'Monthly View', state: 'All', filterData: [...data.handledCalls,...data.unhandledCalls]},
      {...filters, view: 'Monthly View', state: 'Handled', filterData: [...data.handledCalls]},
      {...filters, view: 'Monthly View', state: 'Unhandled', filterData: [...data.handledCalls,...data.unhandledCalls]}
    ])
  }

  if(view === 'Handled By' || view === 'All Charts') {
    charts.push([
      {...filters, view: 'Handled By', state: 'Handled', filterData: [...data.handledCalls]},
    ])
  }
  return charts.flat();
}

const mapStateToProps = state => ({
  text_labels: state.additionals.search_options,
});

const ChartGrid = connect(
  mapStateToProps,
  null
)((props) => {
 const { filters, data, text_labels } = props;
 const [isLoading, setLoading] = useState(true);
 const [hasError, setError] = useState(false);
 const [charts, setCharts] = useState([]);
 // const [chartProps, setChartProps] = useState({});
 let componentMounted = true;

 useEffect(() => {
   //console.log(filters) bugaa emittaa muutoksista heti uuden filters

   const fetchChartOptions = async () => {
     setError(false);
     setLoading(true);
     try {
       const array = getOptions(filters, data); // return chart instances

       const results = await Promise.all(
         array.map(({ filterData, ...options}) => new MissedCallsChartOptions(options, filterData, text_labels))
       );
       if(componentMounted) setCharts(results); // prevent setState fireing on unmounts!
     } catch(e) {
       //console.log(e)
       if(componentMounted) setError(true);
     } finally {
       if(componentMounted) setLoading(false); // prevent setState fireing on unmounts!
     }
   };
   fetchChartOptions();

   return () => { // This code runs when component is unmounted
     componentMounted = false; // (4) set it to false if we leave the page
   }
 }, [filters]);


 return !isLoading
   ? !hasError
     ? <Grid container justifyContent={charts.length === 1 || filters.gridLayout > 6 ? 'center' : 'flex-start'} spacing={3}>
         {
           charts.map((chartOptions, index) => {
             return (
               <Grid item key={index} xs={charts.length > 1 ? filters.gridLayout : 10}>
                 <ErrorBoundary>
                   <LinearChart {...chartOptions} {...props} />
                 </ErrorBoundary>
               </Grid>
             )
           })
         }
       </Grid>
     : <Grid container justifyContent="center">
         <Grid item xs={6}>
           <Alert variant="filled" severity="warning">No chart available with this configuration!</Alert>
         </Grid>
       </Grid>
   : null;
})

export default ChartGrid;
