import ExtensionIcon from '@mui/icons-material/Extension';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import StarIcon from '@mui/icons-material/Star';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

const DEFAULT_VALUES = {
  "default": false,
  "dnd": false,
  "friends_options": {
    friendsOrderAndDisplay: [
      { id: 'item-0', content: 'Ring Groups', display: true, icon: <AccountTreeIcon /> },
      // { id: 'item-1', content: 'Favorites', display: true, icon: <StarIcon /> },
      { id: 'item-2', content: 'Colleagues', display: true, icon: <PeopleAltIcon  /> },
      { id: 'item-3', content: 'Extensions', display: true, icon: <ExtensionIcon  /> },
    ],
    sortBy: 'Name',
  }
}

const localstorage = {
  "get": (name) => JSON.parse(window.localStorage.getItem(name)) || DEFAULT_VALUES[name || 'default'],
  "set": (name, values) => window.localStorage.setItem(name, typeof values !== 'string' ? JSON.stringify(values) : values),
  "delete": (name) => window.localStorage.removeItem(name),
}

// initial Default casts!
if(typeof localstorage.get('dnd') !== 'boolean') localstorage.set('dnd', DEFAULT_VALUES.dnd);
if(!localstorage.get('friends_options')) localstorage.set('dnd', DEFAULT_VALUES.friends_options);

export default localstorage;
