import React, { useContext, useEffect, createContext, useState } from "react";

import { v4 as uuidv4 } from 'uuid';

import { render$ } from 'WEBRTC/phoneManager';

const forceRenderContext = createContext();

export function useForceRender() {
  return useContext(forceRenderContext);
}

export function ForceRenderProvider({ children, observable = render$ }) {
	let mounted = true, subscription;
  const [state, setState] = useState(uuidv4());

	useEffect(() => {
		if(mounted) {
			subscription = observable.subscribe((value) => {
	      setState(uuidv4())
	    })
		}

		return () => {
			subscription.unsubscribe();
			mounted = false;
		}
  }, [])

  return (
    <forceRenderContext.Provider value={[state]}>
      {children}
    </forceRenderContext.Provider>
  )
}
