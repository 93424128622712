import React, { Component } from 'react';
import { Badge, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';

//import styles from "assets/jss/components/badgeStyle";

// const StyledBadge = withStyles(theme => ({
//   badge: {
//     backgroundColor: '#44b700',
//     color: '#44b700',
//     boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
//     padding: '6px',
//     borderRadius: '50%',
//     '&::after': {
//       position: 'absolute',
//       top: 0,
//       left: 0,
//       width: '100%',
//       height: '100%',
//       borderRadius: '50%',
//       animation: '$ripple 3s infinite ease-in-out',
//       border: '1px solid currentColor',
//       content: '""',
//     },
//     '.danger': {
//       backgroundColor: '#44b700',
//       color: '#44b700',
//     },
//   },
//   '@keyframes ripple': {
//     '0%': {
//       transform: 'scale(.1)',
//       opacity: 1,
//     },
//     '80%': {
//       transform: 'scale(.1)',
//       opacity: 1,
//     },
//     '100%': {
//       transform: 'scale(2.4)',
//       opacity: 0,
//     },
//   },
// }))(Badge);

// const DoNotDisturb = () =>
//   <SnackbarContent
//     message='Do Not Disturb Enabled!'
//     icon={WarningIcon}
//     color="danger"
//   />

const withBadge = (WrappedComponent) => {
  class HOC extends Component {
    // constructor(props) {
    //   super(props);
    // }

    render() {
      const { classes, tooltipProps, badgeProps } = this.props;

      return (
        <Tooltip {...tooltipProps}>
          <Badge
            className={classes.badge}
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
            {...badgeProps}
          >
            <WrappedComponent {...this.props}/>
          </Badge>
        </Tooltip>
      );
    }
  }

  return HOC;
}

export default withStyles(withBadge);
