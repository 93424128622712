import React from "react";
import MuiTextField from "./MuiTextField";

const OPTIONS = Array.from({length: 53}, (_, i) => i + 1).map((item) => ({ value: `${item}`, title: `${item}` })); // [1 - 53]

const FormSelectWeekOfYear = (props) => {
  return (
    <MuiTextField
      select
      label="Week Of Year"
      options={OPTIONS}
      style={{ minWidth: 100 }}
      {...props}
    />
  )
}

export default FormSelectWeekOfYear;
