import React, { useState } from 'react';

import { withModal } from 'HOC';

import {
  useNavigate
} from "react-router-dom";

import { styled, alpha } from '@mui/material/styles';

import CloseIcon from  '@mui/icons-material/Close';
import PersonAddIcon from  '@mui/icons-material/PersonAdd';
import AddCircleIcon from  '@mui/icons-material/AddCircle';
import ListIcon from  '@mui/icons-material/List';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { ExtensionsCreatorModal } from 'components/Modals';

import { passwordReset, sendSigninLink } from 'store/actions';
import { deleteProfilesAction } from 'store/profile/actions';
import { modal_events_observable } from 'store/Observables';

import {
  Menu,
  MenuItem,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

const StyledMenu = styled((props) => (
  <Menu
    elevation={8}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
  },
}));

const actions = {
  'Send Signin Link': sendSigninLink,
  'Send Password Reset': passwordReset
}

const DashboardUsersActionsMenu = ({ dispatch, profile, ...props }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  return (
    <div>
      <IconButton aria-label="menu" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={(e) => navigate('/wizard/AddUserAccountWizard')}>
          <ListItemIcon>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText primary="User Account" />
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            modal_events_observable.next(event)
            handleClose(); // close menu!
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <AddCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Extension" />
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

export default withModal(DashboardUsersActionsMenu, [<ExtensionsCreatorModal />])
