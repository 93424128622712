import React, { useState, forwardRef } from 'react';
import classNames from "classnames";
import { makeStyles, createStyles } from '@mui/styles';

import CallEndIcon from  '@mui/icons-material/CallEnd';
import CloseIcon from  '@mui/icons-material/Close';

import { ListSubheader, ListItemAvatar, Avatar, MenuItem, MenuList, ListItemText } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  secondaryAction: {
    paddingRight: '80px'
  },
  primary: {
    fontSize: '12px'
  },
  normal: {
    display: 'flex',
    width: theme.spacing(4),
    height: theme.spacing(4),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  }
}));

function getItem(item) {
  let obj = {};
  switch (item) {
    case 'none':
      obj = { value: 'null', label: 'None', icon: <CloseIcon /> };
      break;
    case 'hangup':
      obj = { value: 'hangup null', label: 'Hangup', icon: <CallEndIcon /> };
      break
    default:

  }
  return obj;
}


const OthersMenuItems = ({ others: othersProp, ...props }) => {
  const classes = useStyles();
  let options = [];

  for (const [key, value] of Object.entries(othersProp)) {
    if(value) {
      options.push(getItem(key));
    }
  }

  const CustomMenuItem = React.useMemo(
    () =>
      ({option, handleMenuItemClick, selected, ...props}) => {
        return (
          <MenuItem
            button
            style={{ minWidth: '300px '}}
            key={option.value}
            selected={option.value === selected}
            onClick={(event) => handleMenuItemClick(event, option.value)}
          >
            <ListItemAvatar>
              <Avatar classes={{ root: classes.normal }}>
                {option.icon}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={<span>{option.label}</span>}
            />
          </MenuItem>
        )
      },
    [],
  );

  return (
    <>
      <ListSubheader component="div" id="nested-list-subheader" style={{ backgroundColor: 'white'}}>Others</ListSubheader>
      {
        options.map((option, index) => <CustomMenuItem key={index} option={option} {...props}/>)
      }
    </>
  )
}

export default OthersMenuItems;
