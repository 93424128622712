import React from "react";
import { grey } from '@mui/material/colors';
import { withStyles } from '@mui/styles';

import { Card, CardBody } from 'components/Card';
import { Grid, Chip, InputAdornment } from '@mui/material';

import { AudioControls } from 'components/Custom';

import { CustomTextField } from 'components/CustomInput';

const DarkChip = withStyles({
  root: {
    backgroundColor: grey[400]
  }
})(Chip);

const TTSHeader = ({ sample, onChange, ...props }) => {
  return (
    <Card style={{ marginTop: 0 }}>
      <CardBody>
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item>
            <CustomTextField
              style={{ width: '50%' }}
              helperText='Invalid filename!'
              onChange={(event) => {
                if(event.target.value !== '') {
                  onChange('filename', event.target.value + '.wav')}
                }
              }
              value={sample?.filename.split('.').shift()}
              customProps={{
                label: "Filename",
                variant: "filled",
                InputLabelProps: {
                  shrink: true
                },
                inputProps: {
                  spellCheck: false
                },
                InputProps:{
                  endAdornment: (
                    <InputAdornment position="end"><DarkChip label='.wav' /></InputAdornment>
                  )
                }
              }}
            />
          </Grid>

          {
            sample.file &&
              <Grid item style={{ marginLeft: 'auto' }}>
                <AudioControls audio={sample.file} />
              </Grid>
          }
        </Grid>
      </CardBody>
    </Card>
  );
}

export default TTSHeader;
