import React, { useEffect, useCallback } from 'react';
import { useStopwatch } from 'react-timer-hook';

import { Chip } from '@mui/material';

import moment from 'Helpers/Momentjs';

const TimerComponent = ({ start_time, style }) => {
  let mounted = true;
  const {
    hours,
    minutes,
    seconds,
    start,
    // pause,
    // reset,
  } = useStopwatch({ autoStart: false, format: '24-hour' });

  useEffect(() => {
    return () => mounted = false;
  }, []);

  useEffect(() => {
    if(start_time && mounted) start(); // start when answered
  }, [start_time]);

  const formatTime = useCallback((time) => {
    if(!mounted) return;
    return String(time).padStart(2, "0");
  }, [hours, minutes, seconds]);

  return start_time && <Chip size='small' style={style} sx={{ padding: '6px', fontWeight: 800 }} label={<small>{formatTime(hours)}:{formatTime(minutes)}:{formatTime(seconds)}</small>} />
}



export default TimerComponent;
