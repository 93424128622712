import React, { useState, useEffect, forwardRef } from 'react';
//import { makeStyles, createStyles } from '@mui/styles';

//import "react-perfect-scrollbar/dist/css/styles.css";
//import PerfectScrollbar from "react-perfect-scrollbar";

import { parseLabel } from 'devtools/Helpers';

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { Chip, Divider, Typography, ListItemAvatar, ListItemIcon, Menu, MenuList, ListItemSecondaryAction } from '@mui/material';

import OthersMenuItems from './OthersMenuItems';
import AnnouncementsMenuItems from './AnnouncementsMenuItems';
import ExtensionsMenuItems from './ExtensionsMenuItems';
import RinggroupsMenuItems from './RinggroupsMenuItems';

function getValues(values) {
  if(!values || values === 'null') return ['null', 'null']
  const arr = values.split(' ');
  const [app, ...data] = arr;
  return [
    app,
    data.join(' ')
  ]
};


const CustomMenuPickerWithObject = forwardRef(({ listItems, ...props}, ref) => {
  const { onChange, useApp, customProps, announcements, ringgroups, extensions, others, listItemSecondaryAction, listItemIcon, listItemAvatar, value: valueProp } = props;
  const { announcementProps, extensionsProps, ringgroupProps, othersProps, chipProps } = props;
  // const classes = useStyles();

  const [selected, setSelected] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const selectedAsString = Object.values(selected).join(' ') === 'null null' ? 'null' : Object.values(selected).join(' ');

  useEffect(() => {
    if(valueProp && JSON.stringify(valueProp) !== JSON.stringify(selected)) setSelected(valueProp);
  }, [valueProp])

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, selectedValue) => {
    setAnchorEl(null);
    let values = getValues(selectedValue);

    let dataMap = new Map();
    Object.keys(valueProp).map((key, index) => {
      const value = Boolean(useApp) ? values[index] : values[1]
      dataMap.set(key, value);
    })

    let data = Object.fromEntries(dataMap);
    setSelected(data)
    onChange(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const itemTextProps = () => {
    let selectedText

    if(Boolean(useApp)) {
      let chunks = selectedAsString.split(' ');
      if(chunks.length > 2) {
        const [app, ...data] = chunks;
        selectedText = parseLabel(data.join(' '));
      } else {
        selectedText = parseLabel(chunks.join(' '));
      }
    } else {
      selectedText = parseLabel(selectedAsString);
    }

    return  customProps && customProps.label
      ? {
          primary: <Typography>{customProps.label}</Typography>,
          secondary: <Chip {...chipProps} label={selectedText} />
        }
      : {
          primary: <Chip {...chipProps} label={selectedText} />,
        }
  };

  return (
    <>
      <ListItem
        button
        aria-haspopup="true"
        aria-controls="lock-menu"
        aria-label={customProps &&customProps.label ? customProps?.label : '' }
        onClick={handleClickListItem}
      >
        {
          listItemAvatar
          ? <ListItemAvatar>
              { listItemAvatar }
            </ListItemAvatar>
          : null
        }

        {
          listItemIcon
          ? <ListItemIcon>
              { listItemIcon }
            </ListItemIcon>
          : null
        }

        <ListItemText
          disableTypography={true}
          { ...itemTextProps() }
        />

        {
          listItemSecondaryAction &&
           <ListItemSecondaryAction>
             { listItemSecondaryAction }
           </ListItemSecondaryAction>
        }
      </ListItem>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          dense: true,
          disablePadding: true,
        }}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'right',
        // }}
        // getContentAnchorEl={null}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'right',
        // }}
        // PaperProps={{
        //   style: {
        //     width: '12ch',
        //   },
        // }}
      >

        <div style={{ maxHeight: "50vh", width: '100%' }}>
          <MenuList
            aria-labelledby="nested-list-subheader"
            dense
            disablePadding={true}
          >
            {
              //useNoneItem && <NoMenuItem handleMenuItemClick={handleMenuItemClick} selected={selected} />
            }
            { others && <OthersMenuItems handleMenuItemClick={handleMenuItemClick} others={others} selected={selectedAsString} {...othersProps} />}
            <Divider />
            { extensions && <ExtensionsMenuItems handleMenuItemClick={handleMenuItemClick} selected={selectedAsString} {...extensionsProps} />}
            <Divider />
            { announcements && <AnnouncementsMenuItems handleMenuItemClick={handleMenuItemClick} selected={selectedAsString} {...announcementProps} />}
            <Divider />
            { ringgroups && <RinggroupsMenuItems handleMenuItemClick={handleMenuItemClick} selected={selectedAsString} {...ringgroupProps} />}
          </MenuList>
        </div>
      </Menu>
    </>
  );
});

export default CustomMenuPickerWithObject;
