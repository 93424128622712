import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { TextField, ListItemIcon, ListItemText } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { Card, CardBody } from 'components/Card';

import { uniqWith, isEqual } from 'lodash';

import CallIcon from  '@mui/icons-material/Call';
import ExtensionIcon from  '@mui/icons-material/Extension';

import { isLoading } from 'devtools/Helpers';

const filter = createFilterOptions();

function getIcon(category) {
  return {
    'Extensions': <ExtensionIcon />,
    'Inbound Routes': <CallIcon />,
    'default': <></>,
  }[category || 'default'];
}

const FormCardSearchAutoComplete = (
  { input: { onChange, onClose, name, value }, label, meta: { touched, error }, customTextField, dispatch, loading, data, size, multiple, ...custom },
) => {
  const options = uniqWith([...data, value], isEqual); // add custom objects to options list and remove duplicates!
  const cardBodyStyle = size === 'small' ? { style: { padding: 4 }} : {};

  return (
    <Card noMargin>
      <CardBody {...cardBodyStyle}>
        <Autocomplete
          size={size}
          style={{ width: 200 }}
          fullWidth
          onChange={(event, value, reason) => {
            //console.log(value)
            if(Boolean(multiple)) {
              // array value
              onChange(
                value.map(_value => {
                  if (_value.inputValue) {
                    return { label: { primary: _value.inputValue }, value: _value.inputValue, category: 'Custom' }
                  } else {
                    return _value;
                  }
                })
              )
              return;
            } else {
              // single value
              if (!value) {
                onChange(null);
              } else if (value.inputValue) {
                onChange({ label: { primary: value.inputValue }, value: value.inputValue, category: 'Custom' });
              } else {
                onChange(value);
              }
              return;
            }
          }}
          disableCloseOnSelect={Boolean(multiple)}
          loading={loading}
          name={name}
          value={value}
          groupBy={(option) => option.category}
          options={options}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          getOptionLabel={(option) => {
            if (option.inputValue) return option.inputValue;
            return option?.label?.primary || String(option)
          }}
          renderInput={(params) =>
            <TextField
              {...params}
              label={label}
              {...customTextField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
          getOptionSelected={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
          renderOption={(option, { selected }) => {
            if (option.inputValue) {
              return option.title; // add ...
            } else {
              return (
                <React.Fragment>
                  <ListItemIcon>
                    { getIcon(option.category) }
                  </ListItemIcon>
                  <ListItemText primary={option?.label?.primary} secondary={option?.label?.secondary} />
                </React.Fragment>
              )
            }
          }}
          multiple={multiple}
          {...custom}
        />
      </CardBody>
    </Card>
  );
}

FormCardSearchAutoComplete.defaultProps = {
  size: 'medium',
  multiple: false,
};

const mapStateToProps = state => ({
  data: state.additionals.search_options,
  loading: isLoading([state.additionals.loading,state.firebase.loading]),
});

export default connect(
  mapStateToProps,
  null
)(FormCardSearchAutoComplete);
