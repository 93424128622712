import React from 'react';

import CustomAvatar from './CustomAvatar';
import CustomIcon from './CustomIcon';
import ProfileAvatar from './ProfileAvatar';

import { styled } from '@mui/material/styles';

import {
  Badge,
  Avatar,
  Grow,
  Fade,
  Zoom,
} from '@mui/material';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import StyledBadge from './StyledBadge';

import cx from "classnames";

const WithTooltip = ({
  tooltip,
  title,
  placement,
  transitioncomponent,
  transitionprops,
  children,
  ...props
}) => {
  return tooltip
    ? (
        <HelperTooltipBootstrap
          title={title}
          placement={placement}
          TransitionComponent={{
            'Zoom': Zoom,
            'Grow': Grow,
            'Fade': Fade,
          }[transitioncomponent]}
          TransitionProps={transitionprops}
          arrow
        >
          <span>
            { children }
          </span>
        </HelperTooltipBootstrap>
      )
    : children
}

const WithBadge = ({ children, ...props}) => {
  const {
    //avatar
    alt,
    src,
    height,
    width,
    backgroundcolor,
    transitioncomponent,
    transitionprops,
    //tooltip
    title,
    placement,
    //other
    linestate,
  } = props;

  const avatarprops = {
    sx: {
      width: width,
      height: height,
      backgroundColor: backgroundcolor,
      fontSize: Math.ceil(Number(height) / 2)
    },
    alt,
    src: `${src}`,
    ...props
  }


  const badgeClasses = cx({
    ["success"]: linestate === 'PAIKALLA',
    ["warning"]: linestate === 'RINGING',
    ["danger"]: linestate === 'BUSY',
  });

  return linestate !== null
    ? (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
          className={badgeClasses}
        >
          <Avatar {...avatarprops}>{alt}</Avatar>
        </StyledBadge>
      )
    : <Avatar {...avatarprops}>{alt}</Avatar>
}



const AvatarWithLineState = ({
  tooltip,
  title,
  placement,
  transitioncomponent,
  transitionprops,
  ...props
}) => {
  return tooltip
    ? (
        <WithTooltip
          tooltip={tooltip}
          title={title}
          placement={placement}
          transitioncomponent={transitioncomponent}
          transitionprops={transitionprops}
        >
          <WithBadge {...props} />
        </WithTooltip>
      )
    : <WithBadge {...props} />
}

AvatarWithLineState.defaultProps = {
  height: 30,
  width: 30,
  backgroundcolor: null,
  tooltip: true,
  title: 'User Unknown',
  placement: 'bottom',
  transitioncomponent: 'Zoom',
  transitionprops: {
    timeout: {
      appear: 300,
      enter: 300,
      exit: 100
    }
  },
  alt: null,
  src: null,
  linestate: null
}

export {
  CustomAvatar,
  CustomIcon,
  ProfileAvatar
}

export default AvatarWithLineState;
