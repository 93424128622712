import Moment from "devtools/momentjs";

export const MISSEDCALLS_DATASET_COLORS_PRIMARY = {
  'info': {
    backgroundColor: '#187E8B',
    borderColor: '#187E8B',
  },
  'success': {
    backgroundColor: '#407743',
    borderColor: '#407743',
  },
  'danger': {
    backgroundColor: '#B50E0B',
    borderColor: '#B50E0B',
  }
};

export const MISSEDCALLS_DATASET_COLORS_ADDITIONALS = [
  {
    backgroundColor: "#f44336",
    borderColor: "#f44336",
  },
  {
    backgroundColor: "#e91e63",
    borderColor: "#e91e63",
  },
  {
    backgroundColor: "#9c27b0",
    borderColor: "#9c27b0",
  },
  {
    backgroundColor: "#673ab7",
    borderColor: "#673ab7",
  },
  {
    backgroundColor: "#3f51b5",
    borderColor: "#3f51b5",
  },
  {
    backgroundColor: "#2196f3",
    borderColor: "#2196f3",
  },
  {
    backgroundColor: "#03a9f4",
    borderColor: "#03a9f4",
  },
  {
    backgroundColor: "#00bcd4",
    borderColor: "#00bcd4",
  },
  {
    backgroundColor: "#009688",
    borderColor: "#009688",
  },
  {
    backgroundColor: "#4caf50",
    borderColor:  "#4caf50",
  },
  {
    backgroundColor: "#8bc34a",
    borderColor: "#8bc34a",
  },
  {
    backgroundColor: "#cddc39",
    borderColor: "#cddc39",
  },
  {
    backgroundColor: "#e1b000",
    borderColor: "#e1b000",
  },
  {
    backgroundColor: "#ffc107",
    borderColor: "#ffc107",
  },
  {
    backgroundColor: "#ff9800",
    borderColor: "#ff9800",
  },
  {
    backgroundColor: "#ff5722",
    borderColor: "#ff5722",
  },
  {
    backgroundColor: "#795548",
    borderColor: "#795548",
  },
  {
    backgroundColor: "#607d8b",
    borderColor: "#607d8b",
  }
];

// const COLORS = ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#e1b000", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"];


export const MISSEDCALLS_CHART_LABELS = {
  'Hourly View': Moment.hoursAsLabels('HH'),
  'Daily View': Moment.chartLabels([6, 'days'], 'MMM[,] DD dd'),

  ChartToday: {
    'Hourly View': Moment.hoursAsLabels('HH'),
  },
  ChartYesterday: {
    'Hourly View': Moment.hoursAsLabels('HH'),
  },
  Chart7Days: {
    'Hourly View': Moment.hoursAsLabels('HH'),
    'Daily View': Moment.chartLabels([6, 'days'], 'MMM[,] DD dd'),
  },
  Chart30Days: {
    'Hourly View': Moment.hoursAsLabels('HH'),
    'Daily View': Moment.chartLabels([29, 'days'], 'MMM[,] DD dd'),
  },
  Chart3Months: {
    'Hourly View': Moment.hoursAsLabels('HH'),
    'Monthly View': Moment.chartLabels([3, 'months'], 'MMM'),
  },
  Chart6Months: {
    'Hourly View': Moment.hoursAsLabels('HH'),
    'Monthly View': Moment.chartLabels([6, 'months'], 'MMM'),
  }
}

export const MISSEDCALLS_CHART_TITLES = {
  ChartToday: {
    'Hourly View': {
      'All': 'Todays All Calls Hourly',
      'Handled': 'Todays Handled Calls Hourly',
      'Unhandled': 'Todays Missed Calls Hourly',
    },
  },
  ChartYesterday: {
    'Hourly View': {
      'All': 'Yesterdays All Calls Hourly',
      'Handled': 'Yesterdays Handled Calls Hourly',
      'Unhandled': 'Yesterdays Missed Calls Hourly',
    },
  },
  Chart7Days: {
    'Hourly View': {
      'All': 'All Calls Hourly for 7 Days',
      'Handled': 'Handled Calls Hourly for 7 Days',
      'Unhandled': 'Missed Calls Hourly for 7 Days',
    },
    'Daily View': {
      'All': 'All Calls Daily for 7 Days',
      'Handled': 'Handled Calls Daily for 7 Days',
      'Unhandled': 'Missed Calls Daily for 7 Days',
    },
  },
  Chart30Days: {
    'Hourly View': {
      'All': 'All Calls Hourly for 30 Days',
      'Handled': 'Handled Calls Hourly for 30 Days',
      'Unhandled': 'Missed Calls Hourly for 30 Days',
    },
    'Daily View': {
      'All': 'All Calls Daily for 30 Days',
      'Handled': 'Handled Calls Daily for 30 Days',
      'Unhandled': 'Missed Calls Daily for 30 Days',
    },
  },
  Chart3Months: {
    'Hourly View': {
      'All': 'All Calls Hourly for 3 Months',
      'Handled': 'Handled Calls Hourly for 3 Months',
      'Unhandled': 'Missed Calls Hourly for 3 Months',
    },
    'Monthly View': {
      'All': 'All Calls Monthly',
      'Handled': 'Handled Calls Monthly',
      'Unhandled': 'Missed Calls Monthly',
    },
  },
  Chart6Months: {
    'Hourly View': {
      'All': 'All Calls Hourly for 6 Months',
      'Handled': 'Handled Calls Hourly for 6 Months',
      'Unhandled': 'Missed Calls Hourly for 6 Months',
    },
    'Monthly View': {
      'All': 'All Calls Monthly',
      'Handled': 'Handled Calls Monthly',
      'Unhandled': 'Missed Calls Monthly',
    },
  }
}

// export const MISSEDCALLS_FORMATS = {
//   'Hourly View': 'HH'
//   'Daily View': 'MMM[,] DD dd'
//   'Monthly View': 'MMM'
// }

// export const MISSEDCALLS_DATASET_SEARCH_PARAMS = {
//   'Hourly View': {
//     'All': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//     'Handled': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'handledtime' },
//     'Unhandled': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//   },
//
//
//   ChartToday: {
//     'Hourly View': {
//       'All': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//       'Handled': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'handledtime' },
//       'Unhandled': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//     },
//   },
//   ChartYesterday: {
//     'Hourly View': {
//       'All': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//       'Handled': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'handledtime' },
//       'Unhandled': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//     },
//   },
//   Chart7Days: {
//     'Hourly View': {
//       'All': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//       'Handled': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'handledtime' },
//       'Unhandled': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//     },
//     'Daily View': {
//       'All': { format: 'MMM[,] DD dd', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//       'Handled': { format: 'MMM[,] DD dd', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'handledtime' },
//       'Unhandled': { format: 'MMM[,] DD dd', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//     },
//   },
//   Chart30Days: {
//     'Hourly View': {
//       'All': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//       'Handled': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'handledtime' },
//       'Unhandled': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//     },
//     'Daily View': {
//       'All': { format: 'MMM[,] DD dd', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//       'Handled': { format: 'MMM[,] DD dd', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'handledtime' },
//       'Unhandled': { format: 'MMM[,] DD dd', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//     },
//   },
//   Chart3Months: {
//     'Hourly View': {
//       'All': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//       'Handled': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'handledtime' },
//       'Unhandled': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//     },
//     'Monthly View': {
//       'All': { format: 'MMM', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//       'Handled': { format: 'MMM', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'handledtime' },
//       'Unhandled': { format: 'MMM', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//     },
//   },
//   Chart6Months: {
//     'Hourly View': {
//       'All': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//       'Handled': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'handledtime' },
//       'Unhandled': { format: 'HH', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//     },
//     'Monthly View': {
//       'All': { format: 'MMM', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//       'Handled': { format: 'MMM', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'handledtime' },
//       'Unhandled': { format: 'MMM', searchFormat: 'DD-MM-YYYY HH:mm:ss', prop: 'aika' },
//     },
//   }
// }
