import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Grid } from '@mui/material';

import ScreenRotationIcon from  '@mui/icons-material/ScreenRotation';

const withMediaQuery = (Component, hocProps) => ({ ...props }) => {
  const mediaQuery ={
    isPortrait: useMediaQuery('(orientation: portrait)'),
    isBigScreen: useMediaQuery('(min-device-width: 1824px)'),
    isTabletOrMobile: useMediaQuery('(max-width: 1224px)'),
    isDesktopOrLaptop: useMediaQuery('(min-width: 1224px)'),
    isLargeScreen: useMediaQuery('(min-width: 1420px)'),
    isXlScreen: useMediaQuery('(min-width: 1920px)'),
  }

  return mediaQuery.isPortrait && hocProps?.forcePortrait
    ? <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ textAlign: 'center' }}
      >
        <ScreenRotationIcon style={{ fontSize: 80 }} />
        <h5>Rotate Screen!</h5>
      </Grid>
    : <Component mediaQuery={mediaQuery} {...props} />
};


export default withMediaQuery;
