import React, { Suspense, forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import * as locales from '@mui/material/locale';

import Paginations from "components/Pagination/Pagination.js";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';

//import FilterListIcon from  '@mui/icons-material/FilterList';
//import DeleteIcon from  '@mui/icons-material/Delete';

import { Card, CardBody } from 'components/Card';
import RegularButton from "components/CustomButtons";
import CustomTablePagination from './Components/CustomTablePagination';

import { searchCollection, indexOfCollection } from 'devtools/Helpers';

import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import { OrangeSelectedTableRow } from './CustomTableRow';

import { Box, Grid, CircularProgress, TextField, InputAdornment } from '@mui/material';


const enhancedTableDefaultProps = {
  data: [],
  loading: false,
  title: false,
  searchText: '',
  order: 'asc',
  orderBy: '',
  page: 0,
  rowsPerPage: 20,
  rowsPerPageOptions: [20,50,100],
  searchComponent: false,
  searchPortalRef: null,
  collapseMultiple: false,
  dense: {
    forced: false,
    scaleAt: 5
  },
  checkboxCollumn: {
    index: 0,
    display: false,
    size: 'medium'
  },
  components: {
    defaultRow: false,
    toolbarComponent: false,
    selectedToolbar: function(){},
    rowCells: function(){},
    tableRows: null,
  }
};


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const EnhancedTable = forwardRef(({ data, headCells, loading, components: componentsProp, options: optionsProp, ...props }, ref) => {
  const classes = useStyles();
  const [state, setState] = useState(enhancedTableDefaultProps);
  const [collapse, setCollapse] = useState({});
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);

  const { components, ...options } = state;
  const { selectedToolbar, defaultRow, rowCells, tableRows } = components;

  useEffect(() => {
    if(optionsProp) setState(Object.assign({...enhancedTableDefaultProps}, { ...optionsProp, loading: loading, components: componentsProp}));
  }, [optionsProp]);

  useEffect(() => {
    if(rows !== data && data && Array.isArray(data)) setRows(data)
  }, [data]);

  const updateState = (obj) => setState({...Object.assign(state, obj)});

  const onSearchChange = (event) => {
    const value = event.currentTarget.value;
    setRows(searchCollection(data, value));
    updateState({ searchText: value })
    // setSearchText(value)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = options.orderBy === property && options.order === 'asc';
    updateState({ order: isAsc ? 'desc' : 'asc', orderBy: property })
    // setOrder(isAsc ? 'desc' : 'asc');
    // setOrderBy(property);
  };

  const handleSelectAllClick = (e) => {
    if(typeof options.checkboxCollumn.onSelectAll === 'function') options.checkboxCollumn.onSelectAll(e)
    if (e.target.checked) {
      // const newSelecteds = rows.map((n) => n.id);
      setSelected([...rows]);
      return;
    }
    setSelected([]);
  };

  const clearSelected = () => setSelected([]);

  const rowClick = (event, row) => {
    let newSelected = [];
    const selectedIndex = indexOfCollection(selected, row)

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => updateState({ page: newPage });

  const handleChangeRowsPerPage = (event) => {
    updateState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 })
  };

  const isSelected = (row) => indexOfCollection(selected, row) !== -1;

  const emptyRows = (options.rowsPerPage - Math.min(options.rowsPerPage, rows.length - options.page * options.rowsPerPage)) || 0;

  const disableRowSelectOnClick = (event) => event.stopPropagation();

  const toggleCollapse = (index) => {
    if(options.collapseMultiple) {
      setCollapse({...Object.assign(collapse, { [index]: !collapse[index] })});
    } else {
      setCollapse({ [index]: !collapse[index] });
    }
  }


  const constuctTableRows = (rows) => {
    return rows.length > 0
      ? rows.map((row, index) => {
          const isItemSelected = isSelected(row);
          const labelId = `enhanced-table-checkbox-${index}`;

          return rowCells(row, index, rowClick, isItemSelected, labelId, disableRowSelectOnClick, collapse, toggleCollapse)
        })
      : <TableRow>
          <TableCell style={{ textAlign: 'center' }} colSpan={headCells.length + 1}>{defaultRow}</TableCell>
        </TableRow>
  }

  // const constuctTableRows = (rows) => {
  //   if(tableRows && typeof tableRows === 'function') {
  //     return tableRows(rows)
  //   } else {
  //     return rows.length > 0
  //       ? rows.map((row, index) => {
  //           const isItemSelected = isSelected(row);
  //           const labelId = `enhanced-table-checkbox-${index}`;
  //
  //           return rowCells(row, index, rowClick, isItemSelected, labelId, disableRowSelectOnClick, collapse, toggleCollapse)
  //         })
  //       : <TableRow>
  //           <TableCell style={{ textAlign: 'center' }} colSpan={headCells.length + 1}>{defaultRow}</TableCell>
  //         </TableRow>
  //   }
  // }

  return (
    <div className={classes.root}>
      {
        <EnhancedTableToolbar selected={selected} options={state} searchPortalRef={state.searchPortalRef} components={components} onSearchChange={onSearchChange} clearSelected={clearSelected} />
      }
      {
        <TableContainer>
          <Table
            id={options?.id || null}
            className={classes.table}
            aria-labelledby="tableTitle"
            size={options.dense.forced || rows.length > Number(options.dense.scaleAt) ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              checkboxCollumn={options?.checkboxCollumn}
              numSelected={selected.length}
              options={options}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {
                loading === false
                  ? constuctTableRows(stableSort(rows, getComparator(options.order, options.orderBy)).slice(options.page * options.rowsPerPage, options.page * options.rowsPerPage + options.rowsPerPage))
                  : <TableRow style={{ height: (options.dense.forced ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={headCells.length + 1}>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                          <CircularProgress color="secondary" />
                          <Box pl={3}>Loading...</Box>
                        </Box>
                      </TableCell>
                    </TableRow>
              }

              {
                // options?.pagination && emptyRows > 0 &&
                //   <TableRow style={{ height: (options.dense.forced ? 33 : 53 ) * emptyRows }}>
                //     <TableCell colSpan={headCells.length + 1} />
                //   </TableRow>
              }
            </TableBody>
            {
              rows.length > options.rowsPerPage &&
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      style={{ border: 0 }}
                      colSpan={headCells.length + 1}
                      rowsPerPageOptions={options.rowsPerPageOptions}
                      count={rows.length}
                      rowsPerPage={options.rowsPerPage}
                      page={options.page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={CustomTablePagination}
                    />
                  </TableRow>
                </TableFooter>
            }
          </Table>
        </TableContainer>
      }
    </div>
  );
})


EnhancedTable.defaultProps = enhancedTableDefaultProps;

EnhancedTable.propTypes = {
  options: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  components: PropTypes.object.isRequired,
};

export {
  EnhancedTable as default,
  OrangeSelectedTableRow,
};
