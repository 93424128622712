import { firebase, store } from 'index.js';

import { isEmpty, uniqBy, keyBy, merge } from 'lodash';

//import { v4 as uuidv4 } from 'uuid';

import { onError, enqueueSnackbar } from 'store/Notifier/actions';

import { ReplaySubject, combineLatest } from "rxjs";
import { map, distinctUntilChanged } from 'rxjs/operators';

import { makeCollection, sortCollection, sortByTime, parseTypes, compare } from 'devtools/Helpers';

import CleanupService from './service_cleanup';

// tabs friends ringgoups list
class Service_ringgroups extends CleanupService {
  constructor() {
    super();
    this.observable_ringgroups$ = new ReplaySubject();

    this.observable = this.observable_ringgroups$
      .pipe(
        distinctUntilChanged(),
      );

    this.firebaseRefs([
    	firebase.doFirebaseObservableQuery(firebase.ringgroups(), "on", "value", this.observable_ringgroups$, true), // true for collection
    ])
  }

  add_subscriber(cbSucess, cbError) {
    return this.subscriptions.push(
      this.observable.subscribe(
        cbSucess,
        err => {
          console.warn('service_ringgroups', err)
          if(typeof cbError === "function") cbError(err);
        }
      )
    ); // add to array and return subscription!
  }
}


export {
  Service_ringgroups
};
