import React, { useEffect } from 'react';

import {
  useNavigate,
  //useLocation
} from "react-router-dom";

import {
  Grid,
  Stack,
  Button
} from '@mui/material';

import UndoIcon from '@mui/icons-material/Undo';

const TopActionBar = () => {
  let mounted = true;
  const navigate = useNavigate();

  useEffect(() => {
    return () => mounted = false;
  }, [])

  return (
    <Grid container justifyContent='flex-start' alignItems='center' spacing={2}>
      <Grid item xs>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color='info'
            onClick={(e) => navigate(-1)}
            startIcon={<UndoIcon />}
            sx={{
              borderRadius: 20,
              color: '#FFF',
              boxShadow: '0 2px 2px 0 rgb(85 85 85 / 14%), 0 3px 1px -2px rgb(85 85 85 / 20%), 0 1px 5px 0 rgb(85 85 85 / 12%)',
              backgroundColor: '#333',
            }}
          >
            Back
          </Button>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default TopActionBar;
