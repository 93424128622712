import React from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm, SubmissionError } from 'redux-form';

import { Card, CardBody, CardFooter } from 'components/Card';
import RegularButton from 'components/CustomButtons';

import { FormCalleridAutoComplete, FormCallerIDSelect } from 'components/Forms/Components';

import { Box, Grid, Collapse } from '@mui/material';

import vaihdeLogo from "assets/img/Icon-60@87.png";
import playstore from "assets/img/playstore.png";
import appstore from "assets/img/appstore.gif";

import { updateUserAction } from 'store/profile/actions';

// style={{ color: grey[500] }}

const FORM = 'VaihdeAppOptionsCard';

const VaihdeAppOptionsCard = ({ dispatch, profile, updateUser, initialValues, ...props}) => {
  const { handleSubmit, pristine, dirty, reset, submitting, change, form } = props;

  async function submit(formvalues) {
    const values = {...formvalues};
    try {
      for(const [userid, data] of Object.entries(values)){
        if(data.dialer.callerID) {
          await dispatch(updateUserAction(userid, { callerID: data.dialer.callerID }));
          return true;
        }
      }
    } catch (err) {
      throw new SubmissionError({
        submit: 'Submiting Error',
        _error: err,
      });
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)} autoComplete='off'>
      <Card>
        <CardBody>
          <Grid container>
            <Grid item xs>
              { vaihdeLogo && <img src={vaihdeLogo} /> }
            </Grid>
            <Grid item xs={9}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {
                    <Field
                      component={FormCallerIDSelect}
                      label="Outbound CallerID"
                      name={`${profile.id}.dialer.callerID`}
                      fullWidth
                      placeholder="-"
                      variant="outlined"
                      size="small"
                      updateOnChange
                    />
                  }
                  {
                    // <Field
                    //   component={FormCalleridAutoComplete}
                    //   label="Outbound CallerID"
                    //   name={`${profile.id}.dialer.callerID`}
                    //   fullWidth
                    //   customTextField={{
                    //     placeholder: "-",
                    //     variant: "outlined",
                    //     size: "small",
                    //     InputLabelProps: {
                    //       shrink: true,
                    //     }
                    //   }}
                    // />
                  }

                </Grid>

                <Grid item xs>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.laru&hl=fi">
                    { playstore && <img style={{ height: '40px' }} src={playstore} /> }
                  </a>
                </Grid>
                <Grid item xs>
                  <a target="_blank" href="https://itunes.apple.com/td/app/vaihde/id1347060577?mt=8">
                    { appstore && <img style={{ height: '40px' }} src={appstore} /> }
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardBody>
        <Collapse in={dirty && !pristine} timeout="auto" unmountOnExit>
          <CardFooter>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <RegularButton
                  type="submit"
                  size='sm'
                  color="success"
                  round
                  disabled={pristine || submitting}
                >
                  <Box pr={1} display={submitting ? 'block' : 'none' }>
                    <i className={"fas fa-spinner fa-spin"} />
                  </Box>
                  Apply
                </RegularButton>

              </Grid>
              <Grid item>
                <RegularButton
                  type="button"
                  size='sm'
                  color="warning"
                  round
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Undo
                </RegularButton>
              </Grid>
            </Grid>
          </CardFooter>
        </Collapse>
      </Card>
    </form>
  );
}

const mapStateToProps = state => ({
  initialValues: state.formState[FORM] || {},  // pull initial values from form reducer
});

// const mapDispatchToProps = dispatch => ({
//   updateUser: (id, data) => dispatch(updateUserAction(id, data)),
// });

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: FORM, // a unique identifier for this form
    enableReinitialize: true,
    // onChange: (values) => console.log(values)
    // validate,
    // asyncValidate,
  })(VaihdeAppOptionsCard)
)
