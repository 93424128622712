import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Grid } from '@mui/material';

import ScreenRotationIcon from  '@mui/icons-material/ScreenRotation';

const withMediaQuery = (Component, hocProps) => ({ ...props }) => {
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  const mediaQuery = {
    isMobile: useMediaQuery({ maxWidth: 767 }),
    isBigScreen: useMediaQuery({ query: '(min-device-width: 1824px)' }),
    isTabletOrMobile: useMediaQuery({ query: '(max-width: 1224px)' }),
    isDesktopOrLaptop: useMediaQuery({ query: '(min-width: 1224px)' }),
    isLargeScreen: useMediaQuery({ query: '(min-width: 1420px)' }),
    isXlScreen: useMediaQuery({ query: '(min-width: 1920px)' }),
    isPortrait,
  }



  return isPortrait && hocProps?.forcePortrait
    ? <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ textAlign: 'center' }}
      >
        <ScreenRotationIcon style={{ fontSize: 80 }} />
        <h5>Rotate Screen!</h5>
      </Grid>
    : <Component mediaQuery={mediaQuery} {...props} />
};


export default withMediaQuery;
