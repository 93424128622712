import React from "react";
import PhoneIphoneIcon from  '@mui/icons-material/PhoneIphone';

import { Chip, InputAdornment } from '@mui/material';

import FormCreativeTextField from './FormCreativeTextField';

const FormCreativePhoneNumberTextField = ({ chipProps, startAdornment, endAdornment, ...props}) => {

  return (
    <FormCreativeTextField
      startAdornment={
        <InputAdornment position="start">
          <Chip {...chipProps} />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <PhoneIphoneIcon />
        </InputAdornment>
      }
      {...props}
    />
  )
}

FormCreativePhoneNumberTextField.defaultProps = {
  chipProps: {
    label: '+358',
    size: 'small'
  }
};

export default FormCreativePhoneNumberTextField;
