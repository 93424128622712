import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';

import { withMediaQuery } from 'HOC';

import { Link } from "react-router-dom";

import { Card, CardHeader, CardIcon, CardFooter } from 'components/Card';
import { Box, Fade, Grid, Button } from '@mui/material';

import RegularButton, { OpenModalButton } from 'components/CustomButtons';

import GroupIcon from  '@mui/icons-material/Group';
import PersonAddIcon from  '@mui/icons-material/PersonAdd';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
  IconButton
} from '@mui/material';

import { DashboardUsersActionsMenu } from 'components/CustomMenus';
import { UsersAvatarGroupUser } from 'components/CustomAvatar';

//import { SimpleLoader } from 'components/Loaders';
import styles from "assets/jss/views/dashboardStyle.js";
const useStyles = makeStyles(createStyles(styles));

const UsersCardWithCount = ({ dispatch, ...props}) => {
  const classes = useStyles();
  const { mediaQuery: { isMobile }, loading, users, isAdministator } = props;

  return !loading &&
    <Fade in timeout={{ appear: 50, enter: 1000, exit: 1000}}>
      <Card>
        <CardHeader color="info" stats={isMobile} icon>
          <CardIcon color="info">
            <GroupIcon />
          </CardIcon>
          <Box textAlign='right'>
            <p className={classes.cardCategory}>Users</p>
            <h3 className={classes.cardTitle}>{ loading === false ? users.length : '?' }</h3>
          </Box>

          <IconButton style={{ color: 'black' }} aria-label="options">
            <MoreVertIcon />
          </IconButton>

        </CardHeader>

        <CardFooter stats>
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item xs container justifyContent='flex-start' direction="row" alignItems="center">
              {
                // <UsersAvatarGroupUser navlinks users={users} />
                // <Button
                //   component={Link}
                //   to='./profiles'
                // >
                //   More
                // </Button>
              }
            </Grid>

            <Grid item xs container spacing={1} justifyContent='flex-end' alignItems='center'>
              {
                isAdministator &&
                  <Grid item>
                    <DashboardUsersActionsMenu />
                  </Grid>
              }
            </Grid>
          </Grid>
        </CardFooter>
      </Card>
    </Fade>
}

const mapStateToProps = state => ({
  loading: state.users.loading,
  users: state.users.users,
  isAdministator: state.currentUser.isAdministator,
});

export default connect(
  mapStateToProps,
  null
)(
  withMediaQuery(UsersCardWithCount)
);
