import React, { lazy, Suspense } from "react";
import { isAdmin } from 'devtools/Helpers';

import { useAuth } from 'Providers';

import {
  Navigate,
  Route,
} from "react-router-dom";

import {
  Grid,
  Fade,
  CircularProgress
} from '@mui/material';

import { CustomAvatarWithBadge } from 'components/CustomAvatar';

import DashboardIcon from "@mui/icons-material/Dashboard";
import PhoneMissedIcon from  '@mui/icons-material/PhoneMissed';
import ContactsIcon from '@mui/icons-material/Contacts';
import DomainIcon from '@mui/icons-material/Domain';

import LiveHelpIcon from  '@mui/icons-material/LiveHelp';
import Fingerprint from "@mui/icons-material/Fingerprint";


// import GroupIcon from  '@mui/icons-material/Group';
// import AccountTreeIcon from  '@mui/icons-material/AccountTree';
// import AccountBoxIcon from  '@mui/icons-material/AccountBox';
// import MusicNoteIcon from  '@mui/icons-material/MusicNote';
// import AccessAlarmsIcon from  '@mui/icons-material/AccessAlarms';
// import AssignmentIcon from  '@mui/icons-material/Assignment';
// import VoicemailIcon from  '@mui/icons-material/Voicemail';
//import ExtensionIcon from  '@mui/icons-material/Extension';



import fusionImage from "assets/img/fusionpbx-logo.png";
import fusionImageMini from "assets/img/fusionpbx-logo-mini.png";

import { sleep } from 'Helpers';

const Connect = lazy(() => import('views/Connect/Connect.js'));
const Login = lazy(() => import('views/Pages/Login.js'));
const ForgotPassword = lazy(() => import('views/Pages/ForgotPassword.js'));
const LogoutPage = lazy(() => import('views/Pages/Logout.js'));
const MissedCalls = lazy(() => import('views/MissedCalls/MissedCalls.js'));

const Organization = lazy(() => import('views/Organizations/Organization.js'));
const Organizations = lazy(() => import('views/Organizations/Organizations.js'));

const Contacts = lazy(() => import('views/Contacts/Contacts.js'));
const Contact = lazy(() => import('views/Contacts/Contact.js'));


const Example = lazy(() => import('views/Example/Example.js'));

//const PasswordUpdate = lazy(() => import('views/Wizards/PasswordUpdate'));

// const TestPage = lazy(() => import('views/Custom/TestPage'));

const ADMIN_PRIVELEGES = ['admin', 'timeconditions', 'superadmin', 'cdr', 'Voicemail'];


const routes = [
  // {
  //   layout: "main",
  //   name: "Example",
  //   icon: PhoneMissedIcon,
  //   badge: true,
  //   route: {
  //     component: Example,
  //     path: "/example",
  //     exact: true,
  //   },
  //   display: {
  //     MainNavbar: true
  //   },
  // },
  {
    layout: "main",
    name: "Missed Calls",
    icon: PhoneMissedIcon,
    badge: true,
    route: {
      component: MissedCalls,
      path: "/",
      exact: true,
    },
    display: {
      MainNavbar: true
    },
  },
  {
    // child routes first fucks up display otherwise
    layout: "main",
    name: "Contact",
    icon: ContactsIcon,
    badge: false,
    route: {
      component: Contact,
      path: "/contacts/:id",
      exact: true,
    },
    display: {
    },
  },
  {
    layout: "main",
    name: "Contacts",
    icon: ContactsIcon,
    badge: false,
    route: {
      component: Contacts,
      path: "/contacts",
      exact: true,
    },
    display: {
      MainNavbar: true
    },
  },
  {
    layout: "main",
    name: "Organization",
    icon: ContactsIcon,
    badge: false,
    route: {
      component: Organization,
      path: "/organizations/:id",
      exact: true,
    },
    display: {
      MainNavbar: false
    },
  },
  {
    layout: "main",
    name: "Organizations",
    icon: DomainIcon,
    badge: false,
    route: {
      component: Organizations,
      path: "/organizations",
      exact: true,
    },
    display: {
      MainNavbar: true
    },
  },
  {
    layout: "auth",
    name: "Forgot Passsword",
    icon: LiveHelpIcon,
    mini: "F",
    route: {
      component: ForgotPassword,
      path: "/forgot-password",
      exact: true,
    },
    display: {
      AuthNavbar: true
    },
  },
  {
    layout: "auth",
    name: "Login",
    icon: Fingerprint,
    mini: "L",
    route: {
      component: Login,
      path: "/login",
      exact: true,
    },
    display: {
      AuthNavbar: true,

    },
  },
  {
    layout: "auth",
    name: "Logout",
    mini: "L",
    route: {
      path: "/logout",
      component: LogoutPage,
      exact: true,
    },
    display: {
      MainNavbar: true,
      AuthNavbar: false
    },
  },
];

const getActiveRoute = (routes) => {
  let activeRoute = "";
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse) {
      let collapseActiveRoute = getActiveRoute(routes[i].views);
      if (collapseActiveRoute !== activeRoute) {
        return collapseActiveRoute;
      }
    } else {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
  }
  return activeRoute;
};

export function Loader() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <Fade in>
          <CircularProgress size={70} thickness={2} color="secondary" />
        </Fade>
      </Grid>
    </Grid>
  )
}


function PrivateRoute({ children, component: Component, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        console.log(location, auth.user)

        if(auth.user === null) {
          //console.log(1, children, Component)
        	return <Loader />
        } else if (Boolean(auth.user)) {
          //console.log(2, children, Component)
        	return (children || <Component {...rest} />);
        } else {
          //console.log(3, children, Component)
        	return (
            <Navigate
              to={"/login"}
              options={{
                state: { from: location }
              }}
            />
          )
        }
      }}
    />
  );
}

const [mainRoutes, authRoutes] = [[],[]]
for(let route of routes) {
  switch (route.layout) {
    case 'main':
      mainRoutes.push(route);
      break;
    case 'auth':
      authRoutes.push(route);
      break;
  }
}

export {
  routes,
  mainRoutes,
  authRoutes,
  getActiveRoute,
  PrivateRoute
}
