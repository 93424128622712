import Logger from './Logger';

const FILES = require('./sounds.json');

const logger = new Logger('audioPlayer');

const SOUNDS = new Map(
	[
		[ 'dtmf', { audio: new Audio(FILES['dtmf']), volume: 0.5 } ],
		[ 'ringback', { audio: new Audio(FILES['ringback']), volume: 1.0 } ],
		[ 'ringing', { audio: new Audio(FILES['ringing']), volume: 1.0 } ],
		[ 'answered', { audio: new Audio(FILES['answered']), volume: 1.0 } ],
		[ 'rejected', { audio: new Audio(FILES['rejected']), volume: 0.5 } ]
	]);

let initialized = false;

// SOUNDS.get("ringback").audio.addEventListener("canplay", () => console.log("ringback ready"));


const context = new AudioContext();
// const preload = SOUNDS.get("ringing").audio;
// preload.addEventListener('loadeddata', () => {
//   if (preload.readyState >= 2) {
// 		setTimeout(async() => {
// 			await context.resume();
// 			preload.currentTime = 0.0;
// 			preload.volume = 0.1;
// 			preload.loop = false;
// 			preload.play();
// 			console.warn('MEDIA READY!')
// 		}, 1000);
//   }
// });


// setTimeout(async() => {
//
// 	console.log(SOUNDS.get("ringback").audio)
// 	SOUNDS.get("ringback").audio.play();
// }, 1000)


export default {
	/**
	 * Play all the sounds so they will play in mobile browsers at any time
	 */
	initialize()
	{
		if (initialized)
			return;

		logger.debug('initialize()');

		for (const sound of SOUNDS.values())
		{
			sound.audio.volume = 0;
			try {

				sound.audio.play();
			} catch (error) {

			}
		}

		initialized = true;
	},

	/**
	 * Play a sound
	 * @param {String} name - Sound name
	 * @param {[Float]} relativeVolume - Relative volume (0.0 - 1.0)
	 */
	async play(name, options = {}) {
		//await this.initialize();
		const { relativeVolume = 1.0, loop = false } = options;

		if (typeof relativeVolume !== 'number') relativeVolume = 1.0;

		logger.debug('play() [name:%s, relativeVolume:%s]', name, relativeVolume);

		const	sound = SOUNDS.get(name);

		if (!sound)
			throw new Error(`unknown sound name "${name}"`);

		try {
			await context.resume();
			sound.audio.pause();
			sound.audio.currentTime = 0.0;
			sound.audio.volume = (sound.volume || 1.0) * relativeVolume;
			sound.audio.loop = loop;
			sound.audio.play();
		}	catch (error) {
			logger.warn('play() | error: %o', error);
		}
	},

	stop(name)
	{
		logger.debug('stop() [name:%s]', name);

		const sound = SOUNDS.get(name);

		if (!sound)
			throw new Error(`unknown sound name "${name}"`);

		sound.audio.pause();
		sound.audio.currentTime = 0.0;
	}
};
