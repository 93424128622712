import React from 'react';
import cx from "classnames";
import { makeStyles, withStyles, createStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';

//import picture from 'assets/img/faces/avatar.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    color: '#fff',
    display: 'flex',
    backgroundColor: props => props.avatarColor,
    //boxShadow: props => 'inset 1px 1px 5px ' + darken(props.avatarColor, '20%'),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  tiny: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  medium: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  profile: {
    height: '120px',
    width: '120px',
    fontSize: '40px'
  }
}));

const CustomAvatarz = (props) => {
  const classes = useStyles(props); // send props!

  const {
    size,
    image,
    avatarColor,
    altName,
    children,
    ...rest
  } = props;

  const avatarClasses = cx(
    {[classes.root]: true },
    {[classes.tiny]: size === 'tiny' },
    {[classes.small]: size === 'small' },
    {[classes.medium]: size === 'medium' },
    {[classes.large]: size === 'large' },
    {[classes.profile]: size === 'profile' }
  );

  // tooltip + badge lisäys!!!

  return <Avatar className={avatarClasses} src={image} {...rest}>{children || altName}</Avatar>
}

CustomAvatarz.defaultProps = {
  avatarColor: '#bdbdbd',
  image: null,
  altName: null,
  size: 'normal'
}

export default CustomAvatarz;
