import React from 'react';
import { connect } from 'react-redux';
import { TextField, Checkbox, Autocomplete } from '@mui/material';

import CheckBoxOutlineBlankIcon from  '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from  '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

//  { label: 'All Calls', value: 'All Calls', category: 'Any' }

const FormDatasetAutoComplete = (
  { input: { onChange, onClose, name, ...input}, label, meta: { touched, error }, customTextField, phonenumbers, dispatch, loading, data, ...custom },
) => {
  return (
    <Autocomplete
      onChange={(event, value, reason) => {
        onChange(event, value)
      }}
      {...input}
      loading={loading}
      name={name}
      groupBy={(option) => option.category}
      options={phonenumbers}
      getOptionLabel={(option) => option?.cc_number || String(option)}
      renderInput={(params) => <TextField {...params} {...input} label={label} {...customTextField} />}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </React.Fragment>
      )}
      {...custom}
    />
  );
}

const mapStateToProps = state => ({
  data: state.calls.calls,
  loading: state.calls.loading
});

export default connect(
  mapStateToProps,
  null
)(FormDatasetAutoComplete);
