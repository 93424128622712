import React from 'react';
import { connect } from 'react-redux';
import { grey } from '@mui/material/colors';
import { TextField, ListItemText, InputAdornment, Divider, ListSubheader, Box, Autocomplete } from '@mui/material';

import RenderGroup from './RenderGroup';

import PhoneForwardedIcon from  '@mui/icons-material/PhoneForwarded';

import { isLoading } from 'devtools/Helpers';

const FormCallForwardAutoComplete = (
  { input: { onChange, onClose, name, value, ...input}, label, meta: { touched, error }, customTextField: { InputProps = {}, ...customTextField}, loading, data, dispatch, ...custom },
) => {
  const { endAdornment, ...inputProps} = InputProps;
  return (
    <Autocomplete
      freeSolo
      onChange={(event, newValue, reason) => {
        console.log(value, newValue)
        if(value !== newValue) onChange(event, newValue)
      }}
      {...input}
      name={name}
      value={value}
      groupBy={(option) => option.category}
      options={data}
      getOptionLabel={(option) => option?.cc_number || String(option)}
      renderInput={({ InputProps: { endAdornment: _endAdornment, ..._inputProps}, inputProps: inputPropsParams, ...params}) => {
        Object.assign(inputPropsParams, { style: {
          fontSize: params.size === 'small' ? '12px' : 'inherit'
        }})
        return (
          <TextField
            inputProps={inputPropsParams}
            {...params}
            label={label}
            {...customTextField}
            InputProps={{
              ..._inputProps,
              ...inputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneForwardedIcon style={{ color: grey[500] }} />
                </InputAdornment>
              ),
              endAdornment: (
                <Box display='flex'>
                  { endAdornment && <Box>{endAdornment}</Box> }
                  { _endAdornment && <Box>{_endAdornment}</Box> }
                </Box>
              )
            }}
          />
        )
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <ListItemText primary={option.description} secondary={option.cc_number} />
        </React.Fragment>
      )}
      renderGroup={RenderGroup}
      {...custom}
    />
  );
}

const mapStateToProps = state => ({
  loading: isLoading([state.numbers.loading,state.firebase.loading]),
  data: state.numbers.phonenumbers
});

export default connect(
  mapStateToProps,
  null
)(FormCallForwardAutoComplete);
