import React, { createContext, useCallback, useReducer, useContext, useEffect, useState } from 'react';

import {
  init,
  RequestedBrowserTransport,
  CallControlFactory,
  //EasyCallControlFactory,
  TransportContext,
  webHidPairing
} from '@gnaudio/jabra-js';

// import { ErrorBoundary } from 'react-error-boundary'
// import { DialogErrorFallback } from 'components/CustomErrors';
import { sweetalertPromptReload } from 'sweetalertsNew';

import localStorage from 'localStorage';

import { useAudioProtection } from 'Providers';


const webHIDContext = createContext();


const JABRA_INITIALSTATE = (() => localStorage.get('jabraConfig'))();

function jabraReducer(state, { type, payload}) {
  switch (type) {
    case 'GET':
      return {
        ...state,
      };
    case 'SET': {
      const data = Object.assign(state, payload);
      localStorage.set('jabraConfig', data);
      return {...data}
    }
    default:
      return state
  }
}


const INITIALSTATE = {
  disabled: true,
  devices: [],
  selectedDevice: null,
  shouldPrompt: false,
  ignoreSignals: false,
  callControl: null,
  //state
  hasCallLock: false,
  callActive: false,
  muted: false,
  ringing: false,
  lineBusy: false,
  online: false,
};

function reducer(state, { type, ...rest}) {
  switch (type) {
    case 'READY':
      return {
        ...state,
        disabled: false,
        ...rest,
      };
    case 'UPDATE':
      return {
        ...state,
        disabled: false,
        selectedDevice: null,
        shouldPrompt: true,
        ...rest,
      };
    default:
      return state
  }
}

const CONFIG = {
  transport: RequestedBrowserTransport.WEB_HID // or CHROME_EXTENSION_WITH_WEB_HID_FALLBACK
}


// function handleIncomingSignals(signal, device, value) {
//   console.log("DEVICE SIGNAL DEVICE SIGNAL DEVICE SIGNAL")
//   console.log(signal, device, value)
// }


export function useWebHIDProvider() {
  let mounted = true, subscription, subscriptions = [], jabra;
  const { isAudioEnabled, enableAudioAndClearAlerts } = useAudioProtection();

  const [state, dispatch] = useReducer(reducer, INITIALSTATE);
  const [jabraConfig, dispatchJabraConfig] = useReducer(jabraReducer, JABRA_INITIALSTATE);

  useEffect(() => {
    if(!mounted) return;
    doInitialMount();
    return () => {
      if(state.callControl) webhidActions?.releaseCallLock(); // should release if phone unmounts
      mounted = false;
    }
  }, []);


  // useEffect(() => {
  //   if(!mounted) return;
  //   else if (!subscription) {
  //     subscription = state.callControl?.deviceSignals?.subscribe(handleIncomingSignals)
  //   }
  //   return () => {
  //     if(subscription && subscription?.unsubscribe === 'function') subscription?.unsubscribe();
  //   };
  // }, [state.callControl]);


  async function doInitialMount() {
    if(!mounted) return;

    try {
      jabra = await init(CONFIG);
      if(!jabra) throw "The Jabra SDK failed to initialize. See error above for more details.";

      const ccFactory = new CallControlFactory(jabra);

      if (jabra.transportContext === TransportContext.WEB_HID) {
        dispatch({ type: 'READY' }) // Enable consent button for selecting
      }

      let deviceList = jabra.deviceList.subscribe({
        next: (devices) => {
          if (devices.length >= 1) {
            // Find the first available device which supports call control
            let indexToUse = 0;
            while (
              indexToUse < devices.length &&
              !ccFactory.supportsCallControl(devices[indexToUse])
            ) {
              indexToUse++;
            }

            ccFactory
              .createCallControl(devices[indexToUse])
              .then((callControl) => {
                // Jabra is Connected
                dispatch({ type: 'UPDATE', callControl })
                if (!isAudioEnabled) enableAudioAndClearAlerts(); // if using webhid then audio will work
              })
              .catch((err) => console.log("deviceList catch", err));
          }
        },
        error: (err) => {
          console.log('deviceList', err);
        },
      });

      let deviceRemoved = jabra.deviceRemoved.subscribe((removed) => {
        console.log("deviceRemoved", removed)
        dispatch({ type: 'UPDATE', callControl: null });
      });

      subscriptions.push(deviceList);
      subscriptions.push(deviceRemoved);
    } catch (e) {
      console.warn(e);
    }
  }

  async function handlePairingDevice (e) {
    if (!mounted) return;
    await webHidPairing();
  }

  async function handleDisconnect(e) {
    if (!mounted) return;
    // feature not supported by jabra
    try {
      let devices = await navigator.hid.getDevices(), promise;
      devices.forEach((device) => {
        console.log(`HID: ${device.productName}`);
        promise = device?.forget();
      });

      await promise;
      sweetalertPromptReload({
        title: 'Headset Disconnected',
        text: 'Reload page to take full effect!',
      });
    } catch (err) {
      console.warn('Disconnect()', err)
    }
  }

  const withProtection = (callback) => (...args) => {
    if (!mounted || !state?.callControl) return;
    callback(...args);
  }

  const webhidActions = {
    takeCallLock: async() => {
      try {
        console.log("Take call lock", "await", state?.callControl);
        const gotLock = await state?.callControl?.takeCallLock();

        if (gotLock) {
          console.log("Got the lock", "success");
          // toggleButtonState([takeCallLock, releaseCallLock, startRinger, startCall]);
        } else {
          console.log("Unable to get the lock", "error");
        }
      } catch (e) {
        console.warn('takeCallLock', e)
      }
    },
    releaseCallLock: withProtection(() => {
      if (!mounted || !state?.callControl) return;
      try {
        state?.callControl?.releaseCallLock();
        console.error("releaseCallLock");
      } catch (e) {
        console.warn("releaseCallLock", e)
      }
    }),
    startRinger: withProtection(() => {
      if (!mounted || !state?.callControl) return;
      try {
        state?.callControl?.ring(true);
        console.log("Start ringer");
      } catch (e) {
        console.warn("startRinger", e)
      }
    }),
    stopRinger: withProtection(() => {
      if (!mounted || !state?.callControl) return;
      try {
        state?.callControl?.ring(false);
        console.log("Stop ringer");
      } catch (e) {
        console.warn("stopRinger", e)
      }
    }),
    startCall: withProtection(() => {
      if (!mounted || !state?.callControl) return;
      try {
        console.log("Start call");
        state?.callControl?.offHook(true);
      } catch (e) {
        console.warn("startCall", e)
      }
    }),
    stopCall: withProtection(() => {
      if (!mounted || !state?.callControl) return;
      try {
        state?.callControl?.offHook(false);
        console.log("Stop call");
      } catch (e) {
        console.warn("stopCall", e)
      }
    }),
    mute: withProtection(() => {
      if (!mounted || !state?.callControl) return;
      try {
        state?.callControl?.mute(true);
        console.log("Mute");
      } catch (e) {
        console.warn("mute", e)
      }
    }),
    unmute: withProtection(() => {
      if (!mounted || !state?.callControl) return;
      try {
        state?.callControl?.mute(false);
        console.log("Unmute");
      } catch (e) {
        console.warn("unmute", e)
      }
    }),
    holdCall: withProtection(() => {
      if (!mounted || !state?.callControl) return;
      try {
        state?.callControl?.hold(true);
        console.log("Hold Call");
      } catch (e) {
        console.warn("holdCall", e)
      }
    }),
    resumeCall: withProtection(() => {
      if (!mounted || !state?.callControl) return;
      try {
        state?.callControl?.hold(false);
        console.log("Resume Call");
      } catch (e) {
        console.warn("resumeCall", e)
      }
    })
  };

  return {
    ...state ?? {},
    ...jabraConfig ?? {},
    webhidState: state,
    handleDisconnect,
    handlePairingDevice,
    //jabra

    dispatchJabraConfig,

    //ACTIONS
    webhidActions,
  }
}

export function useWebHID() {
  return useContext(webHIDContext);
}

export function WebHIDProvider({ children }) {
  const state = useWebHIDProvider();
  return (
    <webHIDContext.Provider value={state}>
      { children }
    </webHIDContext.Provider>
  )
}

export const withWebHID = (Component) => (props) => {
  return (
    <WebHIDProvider>
      <Component {...props} />
    </WebHIDProvider>
  )
}
