import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import JsSIP from 'jssip';
import Logger from '../Logger';
// import TransitionAppear from './TransitionAppear';

import { CustomAvatar } from "components/CustomAvatars";

import { v4 as uuidv4 } from 'uuid';

import {
	Fade,
	Typography,
	Grid,
	Avatar,
	Box,
	Collapse,
	TextField
} from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import CallEndIcon from '@mui/icons-material/CallEnd';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

import { grey } from '@mui/material/colors';

import { RingingButtons } from './ActionButtons';

import { Card, CardBody, CardFooter, CardHeader } from 'components/Card';

const SessionRinging = (props) => {
	const { session } = props;
	const display_name = session.remote_identity?._display_name;
	const uri = session.remote_identity?._uri;

	//<Avatar style={{ border: '2px solid white' }}>{display_name ? display_name.charAt(0) : null}</Avatar>

	// console.log(session.data)

	return (
		<Card color="warning" elevation={16} listMargin>
			<CardBody style={{ padding: '8px' }}>
				<List dense disablePadding sx={{ width: '100%' }}>
					<ListItem dense disableGutters disablePadding>
						<ListItemAvatar>
							<CustomAvatar name={session?.data?.contact?.fullName} src={session?.data?.contact?.image} bgcolor={session?.data?.contact?.avatarColor} sx={{ height: "40px", width: "40px" }} />
						</ListItemAvatar>
						<ListItemText
							primary={<small>{display_name || uri.user}</small>}
							secondary={!display_name ? null : uri.user}
						/>
						<RingingButtons
							session={session}
							direction={session.direction}
						/>
					</ListItem>
				</List>

				{
					// <Grid container wrap="nowrap"  spacing={2}>
					//  <Grid item>
					//  	 <CustomAvatar name={session?.data?.contact?.fullName} src={session?.data?.contact?.image} bgcolor={session?.data?.contact?.avatarColor} sx={{ height: "40px", width: "40px" }} />
					//  </Grid>
					//  <Grid item xs>
					// 	 <Typography noWrap variant="subtitle2" gutterBottom component="div" style={{ margin: 0 }}>{display_name || uri.user}</Typography>
					// 	 <Typography noWrap variant="caption" display="block" gutterBottom style={{ margin: 0 }}>{!display_name ? null : uri.user}</Typography>
					//  </Grid>
					//  <Grid item xs="auto">
					//    <RingingButtons
					// 		 session={session}
					// 		 direction={session.direction}
					// 	 />
					//  </Grid>
				  // </Grid>
				}



				<small>{session.data.state}</small>
		  </CardBody>
		</Card>
	);
}

export default SessionRinging;
