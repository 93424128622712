import React from 'react';
import { grey } from '@mui/material/colors';
import { connect } from 'react-redux';
import {
  TextField,
  ListItemText,
  InputAdornment,
  Autocomplete
} from '@mui/material';

import PhoneForwardedIcon from  '@mui/icons-material/PhoneForwarded';

import { isLoading } from 'devtools/Helpers';

const CallForwardAutoComplete = ({ onChange, loading, label, customTextField, data, dispatch, ...props }) => {
  return (
    <Autocomplete
      freeSolo
      name="CallForwardAutoComplete"
      onChange={onChange}
      groupBy={(option) => option.category}
      options={data}
      loading={loading}
      getOptionLabel={(option) => option?.cc_number || option}
      //renderInput={(params) => <TextField {...params} label={label} {...customTextField} />}
      renderInput={({ InputProps: inputProps, ...params}) => {
        return (
          <TextField
            {...params}
            label={label}
            {...customTextField}
            InputProps={{
              ...inputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneForwardedIcon style={{ color: grey[500] }} />
                </InputAdornment>
              ),
            }}
          />
        )
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <ListItemText primary={option.description} secondary={option.cc_number} />
        </React.Fragment>
      )}
      {...props}
    />
  );
}

const mapStateToProps = state => ({
  loading: isLoading([state.numbers.loading,state.firebase.loading]),
  data: state.numbers.phonenumbers
});

export default connect(
  mapStateToProps,
  null
)(CallForwardAutoComplete);
