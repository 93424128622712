import app from 'firebase/app';

//import { initializeApp } from "firebase/app"

// import firebase from "firebase/compat/app";
//import { getAuth, onAuthStateChanged } from "firebase/auth";

// import { getFirestore } from "firebase/firestore";
// import { getDatabase, ref, set, update, remove, onValue } from "firebase/database";
// import { getStorage } from "firebase/storage";

// import "firebase/compat/firestore"
// import "firebase/compat/storage"

import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';

import { Subject, ReplaySubject, combineLatest } from "rxjs";
//import { map, distinctUntilChanged } from 'rxjs/operators';

import { isAdmin } from 'devtools/Helpers';

import nodefetch from 'node-fetch';

import HTTPQuery from './HTTPRequests';

import Moment from "devtools/momentjs";

import {
  RINGGROUPS_CONNECTING,
  RINGGROUPS_DATA,
  VOICEMAILS_CONNECTING,
  VOICEMAILS_DATA,
  MISSEDCALLS_CONNECTING,
  MISSEDCALLS_DATA,
  ADDITIONALS_DATA,
  USERS_CONNECTING,
  USERS_DATA,
  ORGANIZATION_CONNECTING,
  ORGANIZATION_DATA,
  ANNOUNCEMENTS_CONNECTING,
  ANNOUNCEMENTS_DATA,
  EXTENSIONS_CONNECTING,
  EXTENSIONS_DATA,
  CIDS_CONNECTING,
  CIDS_DATA,
  TIMECONDITIONS_CONNECTING,
  TIMECONDITIONS_DATA,
  NUMBERS_CONNECTING,
  NUMBERS_DATA,
  PROFILE_CONNECTING,
  PROFILE_DATA,
  FIREBASE_CONNECTED
} from 'store/types';

import {
  rawuser_observable,
  user_observable,
  handled_observable,
  unhandled_observable,
  ringgroups_observable,
  voicemails_observable,
  voicemail_greetings_observable,
  users_observable,
  announcements_observable,
  timeconditions_observable,
  ringgroupExtensions_observable,
  extensions_observable,
  callerids_observable,
  numbers_observable,
  extensions_with_ringgroups_and_user_observable,
  ringgroupTable_observable,
  users_with_extensions_observable,
  phonenumbers_observable,
  currentRinggroup_observable,
  cids_observable,
  ringgroups_combinedData_observable,
  profile_observable,
  available_datasets_observable,
  freeswitch_destinations_observable,
  freeswitch_destinations_objects_observable,
  search_options_observable,
  callforward_list_observable,
  voicemails_with_greetings_observable,
  missedcalls_available_datasets_observable,
} from 'store/firebase/Observables';

import { setFormValues } from 'store/form/reducer';

// import { store } from 'index.js';

import { makeCollection, sortCollection, sortByTime, parseTypes, compare } from 'devtools/Helpers';

import { keyBy, isEmpty } from "lodash";

const relay = 'https://apu.vaihde.io:8088';
const FIREBASE_DOMAIN = "https://us-central1-gbc-dialer-fb8e6.cloudfunctions.net";
const FIREBASE_WEB_API = `${FIREBASE_DOMAIN}/api`;

// USED FOR CURRENTUSER!
const organization_observable = new Subject();
const fusionpbx_userAccount_observable = new Subject();
const userAccount_observable = new Subject();

const firebaseConfig = {
  apiKey: "AIzaSyDj228SEm_yjURxH1Tq16mJ9ocNpVpiDjM",
  authDomain: "gbc-dialer-fb8e6.firebaseapp.com",
  databaseURL: "https://gbc-dialer-fb8e6.firebaseio.com",
  projectId: "gbc-dialer-fb8e6",
  storageBucket: "gbc-dialer-fb8e6.appspot.com",
  messagingSenderId: "270903066459"
  // apiKey: "AIzaSyAO9ITWd4HWG-8aIRMb6LgaEqX2BLhJWqs",
  // authDomain: "jannetest-11eb1.firebaseapp.com",
  // databaseURL: "https://jannetest-11eb1.firebaseio.com",
  // projectId: "jannetest-11eb1",
  // storageBucket: "jannetest-11eb1.appspot.com",
  // messagingSenderId: "1082576144978",
  // appId: "1:1082576144978:web:e58facaeedffbc6fa2ed8b"
};

let headers = {
  'Access-Control-Allow-Origin': window.location.origin
}

function asCollection(snapshot) {
  console.log(parseTypes(snapshot.toJSON(), true))
  //{ id: snapshot.key, ...data }
  return makeCollection(parseTypes(snapshot.val(), true));
}


// const makeHTTPRequest = (url, options) => fetch(url, Object.assign(options, { headers: headers }));
const makeHTTPRequest = (url, options) => new HTTPQuery(url, Object.assign(options, { headers: headers }));

export default class Firebase {
  constructor() {
    //const app = initializeApp(firebaseConfig); // v9
    app.initializeApp(firebaseConfig);

    /* Firebase APIs */

    this.subscriptions = [];
    this.store = {};

    this.auth = app.auth();
    this.db = app.database();
    this.firestore = app.firestore();
    this.storage = app.storage();


    // this.auth = getAuth(app); // v9
    // this.db = getDatabase(app); // v9
    // this.firestore = getFirestore(app); // v9
    // this.storage = getStorage(app); // v9

    this.serverValue = this.db.ServerValue;
    this.emailAuthProvider = this.auth.EmailAuthProvider;

    this.authUser = null;

    this.authUserObservable = new ReplaySubject(null); // authstate data'!

    this.customFirestoreData = {};

    const rawuser_subscription = rawuser_observable.subscribe(
      (user) => {
        if(!isEmpty(this.store)) {
          this.store.dispatch({ type: 'AUTH_USER_SET', user });
          this.store.dispatch({ type: 'CURRENT_USER_DATA', payload: { isAdministator: isAdmin(user.claims.roles), authUser: user.userdata }});
        }
      },
      err => console.log(err)
    )

    this.auth.onIdTokenChanged(async user => {
      if (user) {
        // User is signed in or token was refreshed.
        const token = await user.getIdToken();
        headers.authorization = `Bearer ${token}`;
        console.log('new token!');
      }
    });

    // this.auth.onAuthStateChanged(authUser => {
    //   if(!authUser && !new RegExp("^/auth/\\w+").test(window.location.pathname)) window.location.replace(window.location.origin + '/login');
    // });

    this.authStatePromise = new Promise((resolve, reject) => {
      this.auth.onAuthStateChanged(authUser => {
        if(authUser) {
          this.onAuthUserListener(
            user => {
              rawuser_observable.next(user);
              resolve(user);
            },
            err => {
              resolve(null);
            },
          )
        } else {
          resolve(null);
        }
      });

      // const next = () => {
      //   resolve(this.authUser);
      // }
      //
      // const fallback = () => {
      //   reject(null);
      // }
      //
      // return this.auth.onAuthStateChanged(authUser => this.AuthStateCallBack(authUser, next, fallback));
    });

    /* Social Sign In Method Provider */

    //https://firebase.google.com/docs/reference/js/auth.auth.md#auth_interface

    // this.googleProvider = new app.auth.GoogleAuthProvider();
    // this.facebookProvider = new app.auth.FacebookAuthProvider();
    // this.twitterProvider = new app.auth.TwitterAuthProvider();

    // dispatch state on custom events!
    window.addEventListener('update-line-state', async(e) => {
      const status = e?.detail || 'PAIKALLA';
      // return firebase.webrtc_account().update({ status: status }); // promise

      await this.transaction(this.user().child("line"), function(data) {
        return status;
      })
    	 .then(() => console.log('COMPLETE:', status)) // promise
       .catch((err) => console.error('ERROR:', err)) // promise
    });

    // tästä tulee herja, jossain component muuttuu!

  }

  async onInit(store) {
    // setup store and firebase listeners
    this.store = store;
    await this.authStatePromise;
    return this.registerEventHandlers();
  }

  registerEventHandlers() {
    try {
      this.subscribeRXjs();

      // this.store.dispatch({ type: EXTENSIONS_CONNECTING });
      // this.subscriptions.push(
      //   this.doFirebaseQuery(
      //     this.extensionsOrderbyExtension(),
      //     snapshot => {
      //       const collection = makeCollection(parseTypes(snapshot.val(), true));
      //       this.store.dispatch({ type: EXTENSIONS_DATA, payload: { extensions: collection }});
      //       //console.log('extensions', collection)
      //       extensions_observable.next(collection);
      //     },
      //     err => {
      //       throw err;
      //     }
      //   )
      // )

      // users
      // this.store.dispatch({ type: USERS_CONNECTING });
      // this.subscriptions.push(
      //   this.doFirebaseQuery(
      //     this.users(),
      //     snapshot => {
      //       const collection = makeCollection(parseTypes(snapshot.val(), true));
      //       console.log(collection.map(item => item.email))
      //       users_observable.next(collection);
      //       this.store.dispatch({ type: USERS_DATA, payload: { users: collection }});
      //     },
      //     err => {
      //       throw err;
      //     }
      //   )
      // )

      // missedcalls
      // this.store.dispatch({ type: MISSEDCALLS_CONNECTING });
      // this.subscriptions.push(
      //   this.doFirebaseQuery(
      //     this.missedcalls().orderByChild("handled").equalTo(0),
      //     snapshot => {
      //       const collection = sortByTime(makeCollection(parseTypes(snapshot.val(), true)));
      //       const collection_30days = collection.filter(call => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY").isAfter( Moment.command(moment => moment().subtract(30, 'days')) )));
      //       this.store.dispatch({ type: MISSEDCALLS_DATA, payload: { unhandled: collection, unhandled_30days: collection_30days, unhandled_10recent: collection_30days.slice(0, 10) }});
      //       unhandled_observable.next(collection);
      //     },
      //     err => {
      //       throw err;
      //     }
      //   )
      // )
      // this.subscriptions.push(
      //   this.doFirebaseQuery(
      //     this.missedcalls().orderByChild("handled").equalTo(1),
      //     snapshot => {
      //       const collection = sortByTime(makeCollection(parseTypes(snapshot.val(), true)));
      //       const handled_100rows = collection.sort((a, b) => Moment.command(moment => compare(moment(b.handledtime, 'DD-MM-YYYY HH:mm:ss'), moment(a.handledtime, 'DD-MM-YYYY HH:mm:ss')))).slice(0, 100);
      //       this.store.dispatch({ type: MISSEDCALLS_DATA, payload: { handled: collection, handled_100rows: handled_100rows }});
      //       handled_observable.next(collection);
      //     },
      //     err => {
      //       throw err;
      //     }
      //   )
      // )

      // voicemails
      // this.store.dispatch({ type: VOICEMAILS_CONNECTING });
      // this.subscriptions.push(
      //   this.doFirebaseQuery(
      //     this.voicemails(),
      //     snapshot => {
      //       const collection = makeCollection(parseTypes(snapshot.val(), true));
      //       voicemails_observable.next(collection);
      //       this.store.dispatch({ type: VOICEMAILS_DATA, payload: { voicemails: collection } });
      //     },
      //     err => {
      //       throw err;
      //     }
      //   )
      // )

      // voicemail greetings
      // this.subscriptions.push(
      //   this.doFirebaseQuery(
      //     this.voicemail_greetings(),
      //     snapshot => {
      //       const collection = makeCollection(parseTypes(snapshot.val(), true));
      //       voicemail_greetings_observable.next(collection);
      //     },
      //     err => {
      //       throw err;
      //     }
      //   )
      // )

      // ringgroups
      // this.store.dispatch({ type: RINGGROUPS_CONNECTING });

      // this.subscriptions.push(
      //   this.doFirebaseQuery(
      //     this.ringgroups(),
      //     snapshot => {
      //       const data = parseTypes(snapshot.val(), true);
      //       this.store.dispatch(setFormValues('RinggroupLinkTable', data)); // dispatch initial values for form RinggroupLinkTable
      //
      //       const collection = makeCollection(data);
      //
      //       this.store.dispatch({ type: RINGGROUPS_DATA, payload: { ringgroups: collection }});
      //       ringgroups_observable.next(collection);
      //     },
      //     err => {
      //       throw err;
      //     }
      //   )
      // )

      // ringgroup extensions
      // this.subscriptions.push(
      //   this.doFirebaseQuery(
      //     this.ringgroup_extensions(),
      //     snapshot => {
      //       const collection = makeCollection(parseTypes(snapshot.val(), true));
      //       ringgroupExtensions_observable.next(collection);
      //     },
      //     err => {
      //       throw err;
      //     }
      //   )
      // )

      // organization
      // this.store.dispatch({ type: ORGANIZATION_CONNECTING });
      // this.subscriptions.push(
      //   this.doFirebaseQuery(
      //     this.organization(),
      //     snapshot => {
      //       const data = parseTypes(snapshot.val(), true);
      //       this.store.dispatch({ type: "ORGANIZATION_DATA", payload: { organization: { id: snapshot.key, ...data }}});
      //       this.store.dispatch({ type: "CURRENT_USER_DATA", payload: { organization: { domain: this.authUser.claims.domain, domain_uuid: data.domain_uuid }}});
      //     },
      //     err => {
      //       throw err;
      //     }
      //   )
      // )

      // announcements
      // this.store.dispatch({ type: ANNOUNCEMENTS_CONNECTING });
      // this.subscriptions.push(
      //   this.doFirebaseQuery(
      //     this.announcements(),
      //     snapshot => {
      //       this.store.dispatch(setFormValues('AnnouncementsTable', snapshot.val())); // dispatch initial values for form ExtensionsTable
      //
      //       const collection = makeCollection(parseTypes(snapshot.val(), true));
      //       this.store.dispatch({ type: ANNOUNCEMENTS_DATA, payload: { announcements: collection }});
      //       announcements_observable.next(collection); // ehkä bugaa koska array eikä object.
      //     },
      //     err => {
      //       throw err;
      //     }
      //   )
      // )

      // numbers
      // this.store.dispatch({ type: NUMBERS_CONNECTING });
      // this.subscriptions.push(
      //   this.doFirebaseQuery(
      //     this.dids(),
      //     snapshot => {
      //       const collection = makeCollection(parseTypes(snapshot.val(), true));
      //       this.store.dispatch({ type: NUMBERS_DATA, payload: { numbers: collection }});
      //       //console.log('numbers', collection)
      //       numbers_observable.next(collection);
      //     },
      //     err => {
      //       throw err;
      //     }
      //   )
      // )

      // cids
      // this.store.dispatch({ type: CIDS_CONNECTING });
      // this.subscriptions.push(
      //   this.doFirebaseQuery(
      //     this.cids(),
      //     snapshot => {
      //       const collection = makeCollection(parseTypes(snapshot.val(), true));
      //       this.store.dispatch({ type: CIDS_DATA, payload: { numbers: collection }});
      //       callerids_observable.next(collection)
      //     },
      //     err => {
      //       throw err;
      //     }
      //   )
      // )

      // timeconditions
      // this.store.dispatch({ type: TIMECONDITIONS_CONNECTING });
      // this.subscriptions.push(
      //   this.doFirebaseQuery(
      //     this.timeconditions(),
      //     snapshot => {
      //       const collection = makeCollection(parseTypes(snapshot.val(), true));
      //       //console.log('data', collection)
      //       this.store.dispatch({ type: TIMECONDITIONS_DATA, payload: { timeconditions: collection }});
      //       timeconditions_observable.next(collection);
      //     },
      //     err => {
      //       throw err;
      //     }
      //   )
      // )

    } catch (err) {
      console.log('registering', err);
    } finally {
      this.store.dispatch({ type: FIREBASE_CONNECTED });
    }
  }

  subscribeRXjs() {
    //if(subscription) subscription.unsubscribe();
    try {
      const user_subscription = user_observable.subscribe(
        (user) => {},
        err => console.log(err)
      )

      const extensions_with_users_subscription = users_with_extensions_observable.subscribe(
        ([users_with_extensions, extensions_with_user]) => {
          this.store.dispatch({ type: USERS_DATA, payload: { users_with_extensions: users_with_extensions }});
          this.store.dispatch({ type: EXTENSIONS_DATA, payload: { extensions_with_user: extensions_with_user }});
          this.store.dispatch(setFormValues('ExtensionsTable', keyBy(extensions_with_user, 'id'))); // dispatch initial values for form ExtensionsTable
        },
        err => console.log(err)
      )

      let ringgroupTable_subscription = ringgroupTable_observable.subscribe(
        (data) => {
          this.store.dispatch(setFormValues('RinggroupTable', keyBy(data, 'id')));
          this.store.dispatch({ type: EXTENSIONS_DATA, payload: { ringgroup_extensions: data }});
        },
        err => console.log(err)
      )

      const extensions_combined_subscription = extensions_with_ringgroups_and_user_observable.subscribe(
        (extensions) => {
          this.store.dispatch(setFormValues('RinggroupTransferList', keyBy(extensions, 'id'))); // dispatch initial values for form RinggroupTransferList
          this.store.dispatch({ type: EXTENSIONS_DATA, payload: { extensions_combined: extensions }});
        },
        err => console.log(err)
      )

      const currentRinggroup_subscription = currentRinggroup_observable.subscribe(
        (ringgroup) => {
          this.store.dispatch(setFormValues('RinggroupSettingsForm', ringgroup));
          this.store.dispatch({ type: RINGGROUPS_DATA, payload: { currentRinggroupLoading: false, currentRinggroup: ringgroup }});
        },
        err => console.log(err)
      )


      const cids_subscription = cids_observable.subscribe(
        (cids) => {
          this.store.dispatch({ type: NUMBERS_DATA, payload: { cids: cids }});
        },
        err => console.log(err)
      )

      const ringgroups_combinedData_subscription = ringgroups_combinedData_observable.subscribe(
        ([ringgroups, rgExtensions, combinedDataRinggroups]) => {
          this.store.dispatch({ type: RINGGROUPS_DATA, payload: {
            ringgroups: ringgroups,
            ringgroupExtensions: rgExtensions,
            combinedDataLoading: false,
            combinedDataRinggroups: combinedDataRinggroups,
          }});
        },
        err => console.log(err)
      )

      this.store.dispatch({ type: PROFILE_CONNECTING });
      const profile_subscription = profile_observable.subscribe(
        ([user, merged]) => {
          this.store.dispatch({ type: PROFILE_DATA, payload: { profile: merged }});
          const { id, ...rest } = user;
          this.store.dispatch(setFormValues('VaihdeAppOptionsCard', { [id]: rest })); // dispatch initial values for form VaihdeAppOptionsCard

          this.store.dispatch(setFormValues('UserRinggroupsTable', (function() {
            const ringgroups = sortCollection(merged.ringgroups, 'OrderByRinggroup');
            return keyBy(ringgroups, 'ring_group_destination_uuid');
          })())); // dispatch initial values for form UserRinggroupsTable
        },
        err => console.log(err)
      );

      const available_datasets_subscription = available_datasets_observable.subscribe(
        (datasets) => {
          this.store.dispatch({ type: ADDITIONALS_DATA, payload: { available_datasets: datasets }});
        },
        err => console.log(err)
      );

      const freeswitch_destinations_subscription = freeswitch_destinations_observable.subscribe(
        (data) => {
          this.store.dispatch({ type: ADDITIONALS_DATA, payload: { freeswitch_destinations: data, freeswitch_destinations_loading: false }});
        },
        err => console.log(err)
      );

      const freeswitch_destinations_object_subscription = freeswitch_destinations_objects_observable.subscribe(
        (data) => {
          this.store.dispatch({ type: ADDITIONALS_DATA, payload: { freeswitch_destinations_object: data, freeswitch_destinations_object_loading: false }});
        },
        err => console.log(err)
      );


      const search_options_subscription = search_options_observable.subscribe(
        (datasets) => {
          this.store.dispatch({ type: ADDITIONALS_DATA, payload: { search_options: datasets }});
        },
        err => console.log(err)
      )

      const phonenumbers_subscription = phonenumbers_observable.subscribe(
        (data) => {
          this.store.dispatch({ type: NUMBERS_DATA, payload: { phonenumbers: data }});
        },
        err => console.log(err)
      )

      const callforward_list_subscription = callforward_list_observable.subscribe(
        (data) => {
          this.store.dispatch({ type: NUMBERS_DATA, payload: { cfList: data }});
        },
        err => console.log(err)
      )

      const voicemails_with_greetings_subscription = voicemails_with_greetings_observable.subscribe(
        (data) => {
          this.store.dispatch(setFormValues('VoicemailsTable', keyBy(data, 'id'))); // dispatch initial values for form VoicemailsTable
          this.store.dispatch({ type: VOICEMAILS_DATA, payload: { voicemails_with_greetings: data }});
        },
        err => console.log(err)
      )

      const missedcalls_available_datasets_subscription = missedcalls_available_datasets_observable.subscribe(
        (data) => {
          this.store.dispatch({ type: MISSEDCALLS_DATA, payload: { available_datasets: data }});
        },
        err => console.log(err)
      )
    } catch(err) {
      console.log('subscribeRXjs', err);
    }
  }

  AuthStateCallBack = async (authUser, next, fallback) => {
    if(!authUser) fallback();
    try {
      const idTokenResult = await authUser.getIdTokenResult();
      const { token, claims } = idTokenResult;

      this.customFirestoreData = { updateby: authUser.uid };

      //console.log(token);

      this.authUser = {
        ...authUser,
        claims
      };

      headers.authorization = `Bearer ${token}`;

      this.authUser = {
        ...authUser,
        claims
      }; // attach custom claims


      // this.authUserObservable.next(this.authUser); // NOT IN USE

      this.subscriptions.push(
        this.doFirebaseQuery(
          this.fusionpbx_userAccounts(authUser.uid, claims.group),
          snapshot => {
            const data = {
              id: snapshot.key,
              ...snapshot.val()
            };

            fusionpbx_userAccount_observable.next(data);
            //next(this.authUser);
          },
          err => {
            throw err;
            // console.log(err);
            // switch (err.code) {
            //   case 'PERMISSION_DENIED':
            //     setTimeout(() => window.location.replace(window.location.origin + '/main'), 1000);
            //     break;
            //   default:
            // }
            //throw err;
          }
        )
      )

      this.subscriptions.push(
        this.doFirebaseQuery(
          this.domainuser(claims.group, authUser.uid),
          snapshot => {
            const data = {
              id: snapshot.key,
              ...snapshot.val()
            };

            userAccount_observable.next(data);
          },
          err => {
            throw err;
            // console.log(err);
            // switch (err.code) {
            //   case 'PERMISSION_DENIED':
            //     setTimeout(() => window.location.replace(window.location.origin + '/main'), 1000);
            //     break;
            //   default:
            // }
            //throw err;
          }
        )
      )


      let subscription = combineLatest(fusionpbx_userAccount_observable, userAccount_observable).subscribe(
        ([account, user]) => {
          this.authUser = {
            ...this.authUser,
            userdata: { ...user, fusionpbx_userAccount: account  }
          };

          // this.authUserObservable.next(this.authUser); // NOT IN USE
          next(this.authUser);
        },
        err => {
          throw err
        }
      );

    } catch (err) {
      console.log(err);
      fallback();
    }
  }

  doFirebaseQuery = (ref, success, err, eventType = "value") => {
    return ref.on(eventType, success, err);
  }

  doFirebaseObservableQuery2 = (ref, method = "on", eventType = "value", callbackFunction) => {
    function onError(err) {
      console.warn('doFirebaseObservableQuery', err);
    }

    // actual listener
    ref[method](eventType, callbackFunction, onError);

    // return function to clear listener!
    return () => ref.off(eventType, callbackFunction); // promise
  }

  doFirebaseObservableQuery = (ref, method = "on", eventType = "value", observable, useCollection = true) => {
    function onValueChange(snapshot) {
      let dataset;
      if(typeof useCollection === 'function') {
        dataset = snapshot.toJSON();
      } else if (useCollection === true) {
        dataset = makeCollection(parseTypes(snapshot.val(), true));
      } else {
        dataset = parseTypes({ id: snapshot.key, ...snapshot.val()}, true)
      }
      observable.next(dataset);
    }

    function onError(err) {
      console.warn('doFirebaseObservableQuery', err);
    }

    // actual listener
    ref[method](eventType, onValueChange, onError);

    // return function to clear listener!
    return () => ref.off(eventType, onValueChange); // promise
  }


  // *** Auth API ***

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email.toString(), password.toString());

  doSignInWithCustomToken = async token => {
    try {
      await this.authStatePromise;

      if(this.auth.currentUser.uid) {
        return this.doSignOut();
      } else {
        throw 'using token!';
      }
    } catch (err) {
      return this.auth.signInWithCustomToken(token);
    }
  }


  doIsSignInWithEmailLink = (url) =>
    this.auth.isSignInWithEmailLink(url);

  doSignInWithEmailLink = (email, url) =>
    this.auth.signInWithEmailLink(email, url);

  // doSignInWithGoogle = () =>
  //   this.auth.signInWithPopup(this.googleProvider);

  // doSignInWithFacebook = () =>
  //   this.auth.signInWithPopup(this.facebookProvider);

  // doSignInWithTwitter = () =>
  //   this.auth.signInWithPopup(this.twitterProvider);

  doUpdateEmail = email =>
    this.auth.currentUser.updateEmail(`${email}`);

  // doUpdateCurrentUser = object =>
  //   this.auth.updateCurrentUser(object); // saattaa bugaaa!!!

  doUpdateProfile = profile =>
    this.auth.currentUser.updateProfile(profile);

  doUpdatePhoneNumber = phoneNumber =>
    this.auth.currentUser.updatePhoneNumber(phoneNumber);

  doLinkWithPhoneNumber = phoneNumber =>
    this.auth.currentUser.linkWithPhoneNumber(phoneNumber);

  doReload = phoneNumber =>
    this.auth.currentUser.reload();

  doSignOut = () => {
    this.subscriptions.map(listener => {
      try {
        listener.off()
      } catch (err) {
        console.log(err);
      }
    });
    this.auth.signOut();
  }

  doPasswordReset = email => this.auth.sendPasswordResetEmail(`${email}`).then(() => `Email has been send to, ${email}`);

  doUpdatePassword = password => this.auth.currentUser.updatePassword(`${password}`).then(() => 'success');

  // *** Merge Auth and DB User API *** //

  AuthStateCallBack = async (authUser, next, fallback) => {
    try {
      if(!authUser) throw authUser;
      const idTokenResult = await authUser.getIdTokenResult();
      const { token, claims } = idTokenResult;

      this.customFirestoreData = { updateby: authUser.uid };

      //console.log(token);

      this.authUser = {
        ...authUser,
        claims
      };

      headers.authorization = `Bearer ${token}`;

      this.organization().on('value',
        snapshot => {
          organization_observable.next({
            id: snapshot.key,
            ...snapshot.val()
          });
        }
      );

      this.fusionpbx_userAccounts(this.authUser.uid, this.authUser.claims.group).on('value',
        snapshot => {
          const data = {
            id: snapshot.key,
            ...snapshot.val()
          };

          fusionpbx_userAccount_observable.next(data);
          //next(this.authUser);
        },
        err => {
          console.log(err);
          switch (err.code) {
            case 'PERMISSION_DENIED':
              setTimeout(() => window.location.replace(window.location.origin + '/main'), 1000);
              break;
            default:
          }
          //throw err;
        }
      );

      this.domainuser(this.authUser.claims.group, this.authUser.uid).on('value',
        snapshot => {
          const data = {
            id: snapshot.key,
            ...snapshot.val()
          };

          userAccount_observable.next(data);

          // this.authUser = {
          //   ...this.authUser,
          //   userdata: data
          // };

          //next(this.authUser);
        },
        err => {
          console.log(err);
          switch (err.code) {
            case 'PERMISSION_DENIED':
              setTimeout(() => window.location.replace(window.location.origin + '/main'), 1000);
              break;
            default:
          }
          //throw err;
        }
      );


      let subscription = combineLatest(fusionpbx_userAccount_observable, userAccount_observable, organization_observable).subscribe(
        ([account, user, organization]) => {
          this.authUser = {
            ...this.authUser,
            organization,
            userdata: { ...user, fusionpbx_userAccount: account }
          };

          this.authUserObservable.next(this.authUser); // IN USE !!!!!

          next(this.authUser);
        },
        err => console.log(err)
      );
    } catch (err) {
      console.log(err);
      fallback();
    }
  }

  onAuthUserListener = (next, fallback) => this.auth.onAuthStateChanged(authUser => this.AuthStateCallBack(authUser, next, fallback));

  // *** User API ***

  //getDomainUrl = domain => domain.split(',').join('.').toString();

  // organization = () => {
  //   return this.firestore.collection(this.authUser?.claims?.domain).doc('organization');
  // }

  firestore_organization = () => {
    return this.firestore.collection(this.authUser?.claims?.domain).doc('organization');
  }

  organization = () => this.db.ref(`domains/${this.group()}/organization`);

  // webrtc_account = (uid = this.authUser?.uid) => this.db.ref(`domains/${this.group()}/webrtc/${uid}`);
  webrtc_account = (uid = this.authUser?.uid) => this.db.ref(`domains/${this.group()}/webrtc/${uid}`);

  webrtc_accounts = () => this.db.ref(`domains/${this.group()}/webrtc`);

  user = (uid = this.authUser?.uid) => this.db.ref(`users/${uid}`);

  domainuser = (group, uid) => this.db.ref(`domains/${group}/users/${uid}`);

  users = () => this.db.ref(`domains/${this.group()}/users`);

  group = () => this.authUser.claims.group;
  // group = () => {
  //   console.log('group', this.authUser?.claims?.group)
  //   return "gbctest";
  // }

  //group = () => this.authUser.userdata.domain.split(',').shift();

  // profile = (uid = this.authUser?.uid) => this.db.ref(`domains/${this.group()}/users/${uid}`);
  profile = (uid = this.authUser?.uid) => this.db.ref(`domains/${this.group()}/users/${uid}`);

  firestore_users = () => this.firestore_organization().collection('users');

  numbers = () => this.firestore_organization().collection('numbers');

  //userRinggroup = (userid, rgid) => this.db.ref(`freeswitch/users/${this.authUser.domain}/${userid}/ringgroups/${rgid}`);

  missedcall = (id) => this.db.ref(`puhelut/${this.authUser?.claims?.group}/${id}`);

  missedcalls = () => this.db.ref(`puhelut/${this.authUser?.claims?.group}`);

  //ringgroup = (id) => this.db.ref(`freeswitch/domain/${this.authUser.domain}/ringgroup/${id}/extension`);

  //ringgroupToggle = (id, rgid) => this.db.ref(`ringgroup/${id}/${rgid}`);

  //ringroupTimeout = (rgid) => this.db.ref(`freeswitch/domain/${this.authUser.domain}/ringgroup/${rgid}/timeout`);

  //ringgroups = () => this.db.ref(`freeswitch/domain/${this.authUser.domain}/ringgroup`);

  //ringgroupDetails = (id) => this.db.ref(`freeswitch/domain/${this.authUser.domain}/ringgroup/${id}`);

  //firestore_extensions = () => this.firestore_organization().collection('extensions');

  firestore_users = () => this.firestore_organization().collection('users');

  contacts = () => this.db.ref(`domains/${this.group()}/contacts`);

  dids = () => this.db.ref(`domains/${this.group()}/numbers`);

  cids = () => this.db.ref(`domains/${this.group()}/callerids`);

  firestore_dids = () => this.firestore_organization().collection('numbers');

  //firestore_timeconditions = () => this.firestore_organization().collection('timeconditions');

  timeconditions = () => this.db.ref(`domains/${this.group()}/timeconditions`);

  //firestore_timecondition = uid => this.firestore_organization().collection('timeconditions').doc(uid);

  timecondition = uid => this.db.ref(`domains/${this.group()}/timeconditions/${uid}`);

  calllog = () => this.firestore_organization().collection('cdr');

  announcements = () => this.db.ref(`domains/${this.group()}/announcements`);

  voicemails = () => this.db.ref(`domains/${this.group()}/voicemails`);

  voicemail_greetings = () => this.db.ref(`domains/${this.group()}/voicemail_greetings`);

  firestore_announcements = () => this.firestore_organization().collection('announcements')

  storageImages = () =>  this.storage.ref().child(`${this.group()}/images`);

  ringgroups = () => this.db.ref(`domains/${this.group()}/ringgroups`);

  // extensions = () => this.db.ref(`domains/${this.group()}/extensions`).orderByChild('extension').startAt(0);
  //
  // extensions2 = () => this.db.ref(`domains/${this.group()}/extensions`);


  extensionsOrderbyExtension = () => this.db.ref(`domains/${this.group()}/extensions`).orderByChild('extension').startAt(0);

  extensions = () => this.db.ref(`domains/${this.group()}/extensions`);

  views = id => this.db.ref(`domains/${this.group()}/views/${id}`);

  fusionpbx_userAccounts = (id, group) => this.db.ref(`domains/${ group ? group : this.group()}/fusionpbx_userAccounts/${id}`);

  ringgroup_extensions = () => this.db.ref(`domains/${this.group()}/ringgroupExtensions`);

  //firestore_ringgroups = () => this.firestore_organization().collection('ringgroups');

  // httpQueryAddUser = body => makeHTTPRequest('https://us-central1-gbc-dialer-fb8e6.cloudfunctions.net/api/createUser', 'post', JSON.stringify(body), { Accept: 'application/json', 'Content-Type': 'application/json' });
  // httpQueryDeleteUsers = body => makeHTTPRequest('https://us-central1-gbc-dialer-fb8e6.cloudfunctions.net/api/deleteUsers', 'post', body, { Accept: 'application/json', 'Content-Type': 'application/json' });

  httpsApiSendSigninLink = body => makeHTTPRequest(`${FIREBASE_WEB_API}/sendSigninLink`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  });

  httpQueryAddUser = body => makeHTTPRequest(`${FIREBASE_WEB_API}/createUserTrigger`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  });

  httpQueryDeleteUsers = body => makeHTTPRequest(`${FIREBASE_WEB_API}/deleteUsersTrigger`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      "Content-Type": "application/json"
    },
    body: body,
  });

  httpQueryTTS = body => nodefetch('https://europe-west3-gbc-dialer-fb8e6.cloudfunctions.net/Portal_TextToSpeech', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body),
    cache: "no-cache"
  });

  //httpQueryTTSMicrosoft = body => fetch('https://us-central1-gbc-dialer-fb8e6.cloudfunctions.net/api/text_to_speech', {
  httpQueryTTSMicrosoft = body => fetch(`${FIREBASE_WEB_API}/text_to_speech`, {
    method: 'POST', // or 'PUT'
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    body: JSON.stringify(body),
  });


  // httpQueryTTS = body => {
  //   const obj = {
  //     method: 'post',
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({
  //       ...body,
  //       token: headers.authorization.substring(7),
  //     }),
  //     cache: "no-cache"
  //   }
  //
  //   console.log(obj);
  //   return nodefetch('https://europe-west3-gbc-dialer-fb8e6.cloudfunctions.net/Portal_TextToSpeech', obj)
  // };

  streamRecording = recording => {
    let params = new URLSearchParams();
    let parametersToAdd = {uid: recording};
    for(let key in parametersToAdd) {
      params.append(key, parametersToAdd[key]);
    }
    //return fetch(`https://fs6.vaihde.io:6444/recording?${params}`, {
    return fetch(`${relay}/recording?${params}`, {
      method: 'GET',
      headers: headers
    });
  };

  accountExists = email => {
    return nodefetch(`${FIREBASE_WEB_API}/accountExists/${email}`, {
      method: 'GET',
      headers: headers
    });
  };

  httpQueryUploadAnnouncements = files => {
    const formData = new FormData();
    files.map((file, index) => formData.set(`files[${index}]`, file.file, file.filename))
    return nodefetch('https://apu.vaihde.io:8088/fileuploads', {
      method: 'POST',
      body: formData,
      headers: headers
    });
  };


  httpQueryUploadAnnouncement = file => {
    const formData = new FormData();
    formData.append('file', file)
    return nodefetch('https://apu.vaihde.io:8088/fileupload', {
      method: 'POST',
      body: formData,
      headers: headers
    });
  };

  updateUserOld = (uid, data) => this.db.ref(`users/${uid}`).update(data);

  updateCurrentUser = data => this.db.ref(`users/${this.authUser.uid}`).transaction(
    (snapshot) => {
      return Object.assign(snapshot || {}, data);
    }, (error, committed, snapshot) => {
      if (error) {
        return error;
      } else if (!committed) {
        return committed;
      } else {
        return true;
      }
    }
  );

  updateUser = (uid, data) => this.db.ref(`users/${uid}`).transaction(
    (snapshot) => {
      return Object.assign(snapshot || {}, data);
    }, (error, committed, snapshot) => {
      if (error) {
        return error;
      } else if (!committed) {
        return committed;
      } else {
        return true;
      }
    }
  );

  transaction = (ref, callback) => ref.transaction(callback, (error, committed, snapshot) => {
    if (error) {
      return { state: 'error', error: error };
    } else if (!committed) {
      return { state: 'committed', committed: committed };
    } else {
      return { state: 'success', snapshot: snapshot };
    }
  });

  firestoreTransaction = (ref, callback) => this.firestore.runTransaction(t => t.get(ref).then(doc => callback(t, doc)));
}
