import React from 'react';
import { connect } from 'react-redux';

import AddIcon from  '@mui/icons-material/Add';

import Button from "components/CustomButtons/Button.js";

import { AnnouncementCreatorModal } from 'components/Modals'

import { modal_Actions } from 'store/modals/actions';
import { modal_events_observable } from 'store/Observables';

const NewAnnouncementButton = ({ float, color, title, size, dispatch, ...props }) => {
  const clickEvent = (e) => e;

  return (
    <div>
      <Button style={{ float: float}}  size={size} color={color} round onClick={(event) => {
        modal_events_observable.next(event);
        event.stopPropagation(); // stop menu close!
      }}>
        { title }
      </Button>
      <AnnouncementCreatorModal />
    </div>
  );
};


export default connect(
  null,
  null
)(NewAnnouncementButton);

NewAnnouncementButton.defaultProps = {
  title: <AddIcon />,
  color: 'github',
  size: 'sm',
  float: 'left',
};
