import React, { Fragment } from "react";
import { v4 as uuidv4 } from 'uuid';
import { merge } from 'lodash';

import { Card, CardBody, CardHeader, CardFooter } from 'components/Card';
import { ProgressbarSimple } from 'components/Loaders';

import {
  Button,
  Slide
} from "@mui/material";



import {
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
  ERRORS_CHUNK
} from 'store/types';

//https://iamhosseindhv.com/notistack/api

const snackbarContent = {
  SUCCESS: {
    message: 'Database Updated!',
    preventDuplicate: true,
    options: {
      variant: 'success'
    }
  },
  LOADING: {
    message: 'Updating database!',
    preventDuplicate: true,
    options: {
      variant: 'info',
    }
  },
  ERROR: {
    message: 'Error!',
    options: {
      variant: 'error'
    }
  },
  CALL_TRANSFER_INIT: {
    message: 'Transfer Initiated!',
    preventDuplicate: true,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    TransitionComponent: Slide,
    options: {
      variant: 'info'
    }
  },
  CALL_TRANSFER_COMPLETE: {
    message: 'Transfer Successful!',
    preventDuplicate: true,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    autoHideDuration: 3000,
    TransitionComponent: Slide,
    options: {
      variant: 'success',
      // content: (key, message) => (
      //   <Card color='success' message={"message"}>
      //     <CardHeader>
      //       <b>{message}</b>
      //     </CardHeader>
      //     <CardFooter>
      //       <ProgressbarSimple />
      //     </CardFooter>
      //   </Card>
      // )
    }
  },
};


export const Snackbar = (options) => {
  if (!options) return;
  return async (dispatch, getState, firebase) => {
    try {
      return await dispatch({
        type: ENQUEUE_SNACKBAR,
        notification: {
          ...options,
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          }
        }       
      });
    } catch (err) {
      console.warn("Snackbar", err);
    }
  };
};


export const showSnackbar = (type = "LOADING", options = {}, closeKey = null, newKey = uuidv4()) => {
  return async (dispatch, getState, firebase) => {
    try {
      if(closeKey) await removeSnackbar(closeKey instanceof Promise ? await closeKey : closeKey);
      await dispatch({
        type: ENQUEUE_SNACKBAR,
        notification: {
          key: newKey,
          ...merge(snackbarContent[type], options),
        },
      });

      return Promise.resolve(newKey);
    } catch (err) {
      console.warn("showSnackbar", err);
    }
  };
};


const staticOptions = {
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' }
}

export const onError = (dispatch, err, closeKey = null) => {
  console.log(err);
  dispatch({ type: ERRORS_CHUNK, payload: err });

  if(closeKey) {
    removeSnackbar(closeKey);
  } else {
    closeSnackbar(dispatch);
  }


  setTimeout(() => enqueueSnackbar(dispatch, 'ERROR'), 500);
  return;
};

export const enqueueSnackbar = (dispatch, notification, closeKey) => {
  let delay = 100;

  if(closeKey) {
    closeSnackbar(dispatch, closeKey);
    delay = 1000;
  }

  if(typeof notification === 'string') {
    notification = snackbarContent[notification];
  };

  const key = uuidv4();

  setTimeout(() => {
    //const key = notification.options && notification.options.key;

    Object.assign(notification.options, staticOptions);


    switch (notification.options.variant) {
      case 'error':
          Object.assign(notification.options, {
            action: key => (
              <>
                <Button onClick={() => closeSnackbar(dispatch, key)}>Dismiss</Button>
                <Button onClick={() => closeSnackbar(dispatch, key)}>Read</Button>
              </>
            )
          })
      default:
        Object.assign(notification.options, {
          action: key => (
            <Button onClick={() => closeSnackbar(dispatch, key)}>Close</Button>
          )
        })
    }

    dispatch({
      type: ENQUEUE_SNACKBAR,
      notification: {
        ...notification,
        key: key,
      },
    });
  }, delay);
  return key;
};


// export const enqueueSnackbarV2 = (notification, closeKey) => {
//   return async (dispatch, getState, firebase) => {
//     dispatch({ type: HANDLED_MISSEDCALLS_PENDING });
//     try {
//
//     } catch (err) {
//       onError(dispatch, err)
//     }
//   };
// };


export const closeSnackbar = (dispatch, key) => {
  dispatch({
    type: CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
  });
  return;
};

export const removeSnackbar = key => ({ type: REMOVE_SNACKBAR, key });
