import React, { forwardRef } from 'react';

import ExtensionIcon from '@mui/icons-material/Extension';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CallIcon from '@mui/icons-material/Call';

import {
  Chip
} from '@mui/material';

const TelephoneChip = React.forwardRef(function TelephoneChip({ icon, label, onClick, color }, ref) {
  return (
    <Chip
      ref={ref}
      component='span'
      size='small'
      sx={{
        fontSize: 10
      }}
      color={color}
      icon={{
        'extension': <ExtensionIcon fontSize="inherit" />,
        'mobile': <PhoneAndroidIcon fontSize="inherit" />,
        'business': <CallIcon fontSize="inherit" />
      }[icon ?? "business"]}
      label={label}
      onClick={onClick}
    />
  )
});

TelephoneChip.defaultProps = {
  color: "default"
}

export default React.memo(TelephoneChip);
