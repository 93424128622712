import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';

import {
  Button,
  Divider,
  Menu,
  MenuItem,
} from '@mui/material';

import TooltipComponent from './TooltipComponent';

import VideoCallIcon from '@mui/icons-material/VideoCall';

import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const ActionButtonsMenuComponent = (props) => {
  const { open, anchorEl, handleClose, session } = props;
  return (
    <StyledMenu
      id="demo-customized-menu"
      MenuListProps={{
        'aria-labelledby': 'demo-customized-button',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <MenuItem
        onClick={(event) => {
          const modifiers = [
            (description) => {
              console.log('modifiers description', description)
              return Promise.resolve(description);
            }
          ];
          //session.reinvite(modifiers)
          //session.reinvite()

          handleClose(event);
        }}
        disableRipple
      >
        <VideoCallIcon />
        video call
      </MenuItem>
      {
        // <Divider sx={{ my: 0.5 }} />
        // <MenuItem onClick={handleClose} disableRipple>
        //   <ArchiveIcon />
        //   Archive
        // </MenuItem>
        // <MenuItem onClick={handleClose} disableRipple>
        //   <MoreHorizIcon />
        //   More
        // </MenuItem>
      }

    </StyledMenu>
  );
}

export default ActionButtonsMenuComponent;
