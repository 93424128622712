import React from 'react';
import { v4 as uuidv4 } from 'uuid';
//import { connect } from 'react-redux';

//import { useForm } from "react-hook-form";

// import { submit, formValueSelector } from 'redux-form';

import { Grid, Box, Snackbar } from '@mui/material';
import RegularButton from 'components/CustomButtons';

const HookFormRemoteSubmitAndUndoComponent = (props) => {
  const { onSubmit, openOnDirty, reset, message, undo, getValues, formState: { isDirty, isSubmitting }, control: { _defaultValues }} = props;
  const id = uuidv4();

  //console.log('_defaultValues', _defaultValues);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  };

  return (
    <div>
      <Snackbar
        id={id}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={
          openOnDirty
            ? isDirty
            : true
              ? JSON.stringify(Object.keys(getValues())) !== JSON.stringify(_defaultValues)
              : isDirty
                ? true
                : false
        }
        onClose={handleClose}
        message={
          <small>{message}</small>
        }
        action={
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <RegularButton
                size='sm'
                color="success"
                round
                disabled={!isDirty || isSubmitting}
                onClick={onSubmit}
              >
                {
                  isSubmitting && <Box pr={1}>
                    <i className={"fas fa-spinner fa-spin"} />
                  </Box>
                }

                Apply
              </RegularButton>
            </Grid>

            {
              undo &&
                <Grid item>
                  <RegularButton
                    type="button"
                    size='sm'
                    color="warning"
                    round
                    disabled={!isDirty || isSubmitting}
                    onClick={() => reset()}
                  >
                    Undo
                  </RegularButton>
                </Grid>
            }

          </Grid>
        }
      />
    </div>
  )
};

HookFormRemoteSubmitAndUndoComponent.defaultProps = {
  undo: true,
  message: 'Default',
  openOnDirty: false
};

export default HookFormRemoteSubmitAndUndoComponent;
