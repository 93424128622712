import React from 'react';
import { withStyles } from '@mui/styles';
import { red, grey, green } from '@mui/material/colors';

import { Tooltip, Badge, Box, Alert, AlertTitle } from '@mui/material';

import HelperTooltipBootstrap from './HelperTooltipBootstrap';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
  },
}))(Tooltip);

const HelperBadge = withStyles((theme) => ({
  badge: {
    right: -10,
    top: 5,
    whiteSpace: 'normal',
    width: 'min-content',
    backgroundColor: grey[800],
    color: grey[100]
  }
}))(Badge);


const HelperTooltip = ({ badge, theme, title: titleProp, placement, children, ...props }) => {
  const { confirmcalls } = props;

  const content = {
    text_confirmcalls: (
      <div>
        <AlertTitle>Mobiles Only</AlertTitle>
        this option is to prevent calls from being <strong style={{ color: green[600] }}>Answered</strong> by Telecommunication operators <strong>voicemail</strong>.
      </div>
    ),
    default: <AlertTitle>Help</AlertTitle>
  }[titleProp];

  return (
    <CustomTooltip
      title={(
        <Box boxShadow={3}>
          <Alert severity={theme}>
            {content}
          </Alert>
        </Box>
      )}
      placement={placement}
    >
      {
        badge
          ? <HelperBadge badgeContent={'?'}>
              { children }
            </HelperBadge>
          : children
      }
    </CustomTooltip>
  )
};

HelperTooltip.defaultProps = {
  badge: false,
  theme: 'info',
  title: 'default',
  placement: 'top'
};

export {
  HelperTooltip,
  HelperTooltipBootstrap,
}
