import { firebase } from 'index.js';

import { values, merge, keyBy, groupBy, pick, isEmpty, isEqual, omit, partition } from "lodash";

import { Subject, ReplaySubject, combineLatest } from "rxjs";
import { map, distinctUntilChanged } from 'rxjs/operators';

import { makeCollection, sortCollection, sortByTime, parseTypes, compare } from 'devtools/Helpers';

import moment from 'Helpers/Momentjs';

// import WebRTCClient from 'components/WebRTC';

import { getFirebasePath } from 'Helpers/Firebase';

import {
  users_observable,
  extensions_observable,
  sip_agent_observable,
  sipAccount_observable,
  favorites_observable
} from 'Observables';

// function mergeArrays([first, ...arrays], key) {
//   return first.map((item) => {
//     for(let array of arrays) {
//       item = Object.assign(item, array.find(element => {
//         return element?.[key] === item?.[key]
//       }))
//     }
//     return item;
//   })
// }

export function serviceCleanup(subscription, firebaseRefs  = [], subscriptions = []) {
  try {
    if(subscription && subscription?.unsubscribe & typeof subscription.unsubscribe === 'function') {
      console.log("serviceCleanup main unsubscribe");
      subscription.unsubscribe();
    }


    if(subscriptions.length === 0) {
      console.log("COMPLETE UNRENDER!");
      for(let firebaseRefOff of firebaseRefs) {
        if(typeof firebaseRefOff === 'function') firebaseRefOff(); // execute
      }
    }
  } catch (e) {
    console.warn('serviceCleanup', e)
  }
}

// function cleanup(refs, subscription) {
//   console.log('cleanup', refs, subscription)
//   try {
//     if(Array.isArray(refs) && refs.length > 0) {
//       for(let firebaseRefOff of refs) {
//         firebaseRefOff(); // execute
//       }
//     }
//   } catch (e) {
//     console.warn('cleanup firebaseRefs', e)
//   }
//
//   try {
//     if(subscription && subscription?.unsubscribe) subscription?.unsubscribe();
//   } catch (e) {
//     console.warn('cleanup rxjs', e)
//   }
// }


/*
  add suscrier
  remove subscriber

  arrayna

  jos vikana poista reffit!

*/

export const service_sipAccount = function () {
  const profile_observable = new ReplaySubject();
  const webrtc_observable = new ReplaySubject();

  const subscriptions = [];

  const refs = [
    firebase.doFirebaseObservableQuery(firebase.profile(), "on", "value", profile_observable, false), // true for collection
    firebase.doFirebaseObservableQuery(firebase.webrtc_account(), "on", "value", webrtc_observable, false), // object
  ];

  //console.warn("account path:", arr.reverse().join('/'));

  const observable$ = combineLatest(profile_observable, webrtc_observable)
    .pipe(
      map(([user, webrtc]) => {
        // return {
        //   ...user,
        //   webrtc: webrtc
        // }
        return {
          username: user.username,
          ...webrtc
        }
      }),
      distinctUntilChanged((prev, curr) => isEqual(omit(prev, ['status']), omit(curr, ['status']))),
    );

  function add_subscriber(callback) {
    return subscriptions.push(
      observable$.subscribe(
        callback,
        err => console.error('service_sipAccount', err)
      )
    ); // add to array and return subscription!
  }

  return {
    add_subscriber: add_subscriber,
    remove_subscriber: (subscription) => serviceCleanup(subscription, refs, subscriptions),
  }
}

/*
export const service_contacts = function () {
  const observable_general$ = new ReplaySubject();
  const observable_emails$ = new ReplaySubject();
  const observable_telephones$ = new ReplaySubject();
  const observable_search_tags$ = new ReplaySubject();

  let subscription;
  const refs = [
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("general"), "on", "value", (snapshot) => observable_general$.next(makeCollection(parseTypes(snapshot.val(), true)))),
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("emails"), "on", "value", (snapshot) => observable_emails$.next(snapshot.toJSON())),
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("telephones"), "on", "value", (snapshot) => observable_telephones$.next(snapshot.toJSON())),
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("search_tags"), "on", "value", (snapshot) => observable_search_tags$.next(snapshot.toJSON())),
  ];

  function service_subscribe(callback) {
    subscription = combineLatest(observable_general$, observable_emails$, observable_telephones$, observable_search_tags$)
      .pipe(
        map(([general, emails, telephones, search_tags]) => {
          return general.map(item => {
            const _emails = Object.values(emails?.[item.id] ?? {});
            const _telephones = Object.values(telephones?.[item.id] ?? {});
            const _search_tags = Object.values(search_tags?.[item.id] ?? {});
            return {
              ...item,
              emails: _emails,
              telephones: _telephones,
              search_tags: _search_tags,
            }
          })
        }),
        distinctUntilChanged(),
      ).subscribe(
        callback,
        err => console.log('service_contacts', err)
      );
    return subscription;
  }

  return {
    service_subscribe: service_subscribe,
    service_unsubscribe: () => cleanup(refs, subscription),
  }
}
*/
// missedcalls handled
export const service_missedcalls_handled = function () {
  const observable_handledcalls$ = new ReplaySubject();
  const observable_users$ = new ReplaySubject();

  const subscriptions = [];

  const refs = [
    firebase.doFirebaseObservableQuery2(firebase.missedcalls().orderByChild("handled").equalTo(1), "on", "value", (snapshot) => {
      const collection = sortByTime(makeCollection(parseTypes(snapshot.val(), true)));
      const handled_100rows = collection.sort((a, b) => compare(moment(b.handledtime, 'DD-MM-YYYY HH:mm:ss'), moment(a.handledtime, 'DD-MM-YYYY HH:mm:ss'))).slice(0, 100);
      observable_handledcalls$.next(handled_100rows); // emit
    }),
    firebase.doFirebaseObservableQuery2(firebase.users(), "on", "value", (snapshot) => observable_users$.next(makeCollection(parseTypes(snapshot.val(), true)))),
  ];

  const observable$ = combineLatest(observable_handledcalls$, observable_users$)
    .pipe(
      map(([calls, users]) => {
        return calls.map(({ handledperson, ...item}) => {
          return {
            ...item,
            handledperson: users.find((user => user.username === handledperson)) || handledperson
          }
        })
      }),
      distinctUntilChanged(),
    );

  function add_subscriber(callback) {
    return subscriptions.push(
      observable$.subscribe(
        callback,
        err => console.error('service_missedcalls_handled', err)
      )
    ); // add to array and return subscription!
  }

  return {
    add_subscriber: add_subscriber,
    remove_subscriber: (subscription) => serviceCleanup(subscription, refs, subscriptions),
  }
}

// missedcalls unhandled
export const service_missedcalls_unhandled = function () {
  const observable$ = new ReplaySubject();

  const subscriptions = [];

  const refs = [
    firebase.doFirebaseObservableQuery2(firebase.missedcalls().orderByChild("handled").equalTo(0), "on", "value", (snapshot) => {
      const collection = sortByTime(makeCollection(parseTypes(snapshot.val(), true)));
      const collection_30days = collection.filter(call => moment(call?.aika, "DD-MM-YYYY").isAfter(moment().subtract(30, 'days')));
      const collection_30days_limit100 = collection_30days.slice(0, 100);
      observable$.next(collection_30days_limit100); // emit
    })
  ];

  function add_subscriber(callback) {
    return subscriptions.push(
      observable$.subscribe(
        callback,
        err => console.error('service_missedcalls_unhandled', err)
      )
    ); // add to array and return subscription!
  }

  return {
    add_subscriber: add_subscriber,
    remove_subscriber: (subscription) => serviceCleanup(subscription, refs, subscriptions),
  }
}

function toArray(...itemsArray) {
  return itemsArray.filter(Boolean);
}

// export const service_contacts = function () {
//   const observable_general$ = new ReplaySubject();
//   const observable_emails$ = new ReplaySubject();
//   const observable_telephones$ = new ReplaySubject();
//   const observable_search_tags$ = new ReplaySubject();
//
//   const subscriptions = [];
//
//   const refs = [
//     firebase.doFirebaseObservableQuery2(firebase.contacts().child("general"), "on", "value", (snapshot) => observable_general$.next(makeCollection(parseTypes(snapshot.val(), true)))),
//     firebase.doFirebaseObservableQuery2(firebase.contacts().child("emails"), "on", "value", (snapshot) => observable_emails$.next(snapshot.toJSON())),
//     firebase.doFirebaseObservableQuery2(firebase.contacts().child("telephones"), "on", "value", (snapshot) => observable_telephones$.next(snapshot.toJSON())),
//     firebase.doFirebaseObservableQuery2(firebase.contacts().child("search_tags"), "on", "value", (snapshot) => observable_search_tags$.next(snapshot.toJSON())),
//   ];
//
//   const observable = combineLatest(observable_general$, observable_emails$, observable_telephones$, observable_search_tags$)
//     .pipe(
//       map(([general, emails, telephones, search_tags]) => {
//         return general.map(item => {
//           const _emails = Object.values(emails?.[item.id] ?? {});
//           const _telephones = Object.values(telephones?.[item.id] ?? {});
//           const _search_tags = Object.values(search_tags?.[item.id] ?? {});
//           return {
//             ...item,
//             emails: _emails,
//             telephones: _telephones,
//             search_tags: _search_tags,
//           }
//         })
//       }),
//       distinctUntilChanged(),
//     );
//
//   function add_subscriber(callback) {
//     return subscriptions.push(
//       observable.subscribe(
//         callback,
//         err => console.warn('service_contacts', err)
//       )
//     ); // add to array and return subscription!
//   }
//
//
//   return {
//     add_subscriber: add_subscriber,
//     remove_subscriber: (subscription) => serviceCleanup(subscription, refs, subscriptions),
//   }
// }



// export const service_colleagues = function () {
//   const observable_extensions$ = new ReplaySubject();
//   const observable_users$ = new ReplaySubject();
//   const observable_webrtc_accounts$ = new ReplaySubject();
//
//   let subscription;
//   const refs = [
//     firebase.doFirebaseObservableQuery2(firebase.extensions(), "on", "value", (snapshot) => observable_extensions$.next(makeCollection(parseTypes(snapshot.val(), true)))),
//     firebase.doFirebaseObservableQuery2(firebase.users(), "on", "value", (snapshot) => observable_users$.next(snapshot.toJSON())),
//     firebase.doFirebaseObservableQuery2(firebase.webrtc_accounts(), "on", "value", (snapshot) => observable_webrtc_accounts$.next(snapshot.toJSON())),
//   ];
//
//   function service_subscribe() {
//     subscription = combineLatest(
//       observable_extensions$,
//       observable_users$,
//       observable_webrtc_accounts$
//     )
//       .pipe(
//         map(([extensions, users, webrtcs]) => {
//           return extensions.map(item => {
//             const user = users?.[item.userid] ?? null;
//             return {
//               ...item,
//               user: user,
//               webrtc: (() => {
//                 let data = webrtcs?.[item.userid] ?? null; // object or null;
//                 if(data && !isEmpty(data) && (data.extension_uuid === item.extension_uuid)) { // check for extension_uuid match
//                   return pick(data, ['status', 'extension']);
//                 } else {
//                   return null;
//                 }
//               })()
//             };
//           })
//             .sort((a,b) => a.extension - b.extension) // 0 -> 9999
//         }),
//
//         distinctUntilChanged(),
//       );
//     return subscription;
//   }
//
//   return {
//     service_subscribe: service_subscribe,
//     service_unsubscribe: () => cleanup(refs, subscription),
//   }
// }

export const sipAccountHandler = class {
  constructor(mounted = true) {
    this.mounted = mounted;

    return (async () => {
      await firebase.authStatePromise;

      this.firebaseListeners = [
        // firebase.databaseListener(firebase.users(), users_observable, true), // true for collection
        // firebase.databaseListener(firebase.sip_agent(), sip_agent_observable), // object
        // firebase.databaseListener(firebase.favorites(), favorites_observable, true) // true for collection
      ];

      return sipAccount_observable;
    })();
  }

  firebaseOff() {
    if(this.mounted) {
      try {
        for(let item of this.firebaseListeners) {
          item.off();
        }
      } catch (e) {
        console.log('cleanup error', e)
      }
    }
  }
}


export * from './Actions';
export * from './service_colleagues';
export * from './service_contact';
export * from './service_contact_class';
//export * from './service_contact_classobject'; // WEBRTC\components\Popovers\ContactPopover.js
export * from './service_contacts';
export * from './service_organization';
export * from './service_organizations';
export * from './service_organization_contacts';
export * from './service_tasklist';
export * from './service_ringgroups';
export * from './service_extensions';
export * from './service_localstorage';
