import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import MenuIcon from  '@mui/icons-material/Menu';
import SearchIcon from  '@mui/icons-material/Search';
import DirectionsIcon from  '@mui/icons-material/Directions';

import { Paper, InputBase, InputAdornment } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const SearchBar = ({ onSearchChange, options: { searchText = '' }, ...props }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={5}>
      <InputBase
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        className={classes.input}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        onChange={onSearchChange}
        value={searchText}
        type='search'
      />
    </Paper>
  );
}

SearchBar.defaultProps = {
  options: {
    searchText: ''
  }
}

export default SearchBar;
