import React, { createContext, useCallback, useReducer, useContext, useEffect, useState } from 'react';

import { ErrorBoundary } from 'react-error-boundary'
import { DialogErrorFallback } from 'components/CustomErrors';

const dialogsContext = createContext();

const INITIALSTATE = {
  open: false,
  component: null,
  state: {},
}

function reducer(state = INITIALSTATE, action) {
  switch (action.type) {
    case 'OPEN':
      return {
        ...Object.assign(
          state,
          {
            open: true,
            component: action.component,
            state: action.state
          }
        )
      };
    case 'CLOSE':
      return {
        ...INITIALSTATE
      };
    default:
      return state
  }
}

export function useDialogsProvider() {
  let mounted = true;
  const [store, dispatch] = useReducer(reducer, {...INITIALSTATE});
  const { open, component, state } = store;
  const id = open ? 'simple-dialog' : undefined;

  useEffect(() => {
    return () => mounted = false;
  }, []);

  const dialogAction = useCallback(
    (open, newState, component) => (event) => {
      if(!mounted) return;
      if(open)
        dispatch({ type: "OPEN", state: newState, component });
      else
        dispatch({ type: 'CLOSE' });
    },
    [],
  );

  return {
    id,
    open,
    component,
    state,
    dialogAction
  }
}

export function useDialogs() {
  return useContext(dialogsContext);
}

export function DialogsProvider({ children }) {
  const props = useDialogsProvider();
  const { open, dialogAction, state, ...rest } = props;

  return (
    <dialogsContext.Provider value={{ open, dialogAction }}>
      { children }
      <ErrorBoundary
        FallbackComponent={DialogErrorFallback}
        resetKeys={[open,state]}
      >
        <div>
          { open && <props.component {...state} open={open}  />}
        </div>
      </ErrorBoundary>

    </dialogsContext.Provider>
  )
}

export const withDialogs = (Component) => (props) => {
  return (
    <DialogsProvider>
      <Component {...props} />
    </DialogsProvider>
  )
}
