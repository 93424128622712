import React, { useEffect, useCallback } from "react";

import {
  Box,
  Stack,
  Button,
  IconButton,
  Divider
} from '@mui/material';

import { useForm, Controller, FormProvider, useFormContext } from "react-hook-form";
import { disableFormSubmitByEnterKey, sleep } from 'Helpers/Forms';
import { LoadingButton } from 'components/HookForm/Buttons';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import FriendsOptionsList from './FriendsOptionsList';
import FriendsSortOptions from './FriendsSortOptions';

import ExtensionIcon from '@mui/icons-material/Extension';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import StarIcon from '@mui/icons-material/Star';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

import localstorage from 'localStorage';

const INITIALVALUES = () => localstorage.get('friends_options');

const FormActionButtons = ({ onSubmit, ...props }) => {
  const methods = useFormContext(); // retrieve all hook methods
  const { watch, formState, handleSubmit } = methods;
  watch(); // watch for form changes

  return (
    <Box display='flex' flexGrow={1}>
      <LoadingButton type="submit" onSubmit={handleSubmit(onSubmit)} {...methods} {...props}>
        Apply
      </LoadingButton>
      <LoadingButton undo {...methods} {...props}>
        Undo
      </LoadingButton>
    </Box>
  )
}

const FriendsOptionsComponent = ({ handleClose, ...props }) => {
  let mounted = true;

  useEffect(() => {
    return () => mounted = false;
  }, []);

  const methods = useForm({
    defaultValues: {...INITIALVALUES()}
  });

  const { handleSubmit, control, watch, reset, setValue, formState: { errors, isDirty, isSubmitting }} = methods;

  // SUBMIT
  const onSubmit = useCallback(
    async (data) => {
      try {
        console.log('onSubmit', data);
        localstorage.set("friends_options", data);
        reset(data);
      } catch (err) {
        console.warn("onSubmit",  err);
      } finally {
        await sleep(250); // little delay for loader!
      }
    },
    [],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormSubmitByEnterKey}>
        <Stack direction="row" justifyContent='space-around' px={2} py={1} spacing={5}>
          <FormActionButtons onSubmit={onSubmit} handleClose={handleClose} />
          <IconButton onClick={(event) => setTimeout(handleClose(event))} aria-label="delete" size="large">
            <CloseRoundedIcon fontSize="inherit" />
          </IconButton>
        </Stack>

        {
          // OLD!
          //<FriendsSortOptions />
        }

        <FriendsOptionsList />
      </form>
    </FormProvider>
  );
}

export default FriendsOptionsComponent;
