import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';

import { Paper, Menu, Button, MenuItem, Box, CircularProgress, Select, InputBase, Divider, ListItemText, ListItemIcon, ListSubheader, Icon, ButtonGroup, Tabs, Tab } from '@mui/material';

import { AutocompleteWithCheckBox } from 'components/CustomMenu';

import ViewModuleIcon from  '@mui/icons-material/ViewModule';
import MenuIcon from  '@mui/icons-material/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 6px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  tab: {
    minWidth: 'inherit !important'
  }
}));

const rangeOptions = [
  { value: [1, 'day'], label: 'Today' },
  { value: [7, 'days'], label: '7 Days' },
  { value: [30, 'days'], label: '30 Days' },
  { value: [3, 'months'], label: '3 Months' },
  { value: [6, 'months'], label: '6 Months' }
];

const gridOptions = [
  { value:6, label: 'Grid Layout', icon: 'view_module' },
  { value:10, label: 'List Layout', icon: 'menu' }
];


const CustomToolBar = ({ onChange, values, data, ...props}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState( values || {
    datasets: [],
    range: '[30,"days"]',
    view: 'Hourly View',
    gridLayout: 6
  });
  const ref = React.createRef();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event, newValue) => {
    setSelected({ ...Object.assign(selected, newValue)})
  };

  const handleClose = (event, objValue) => {
    handleChange(event, objValue)
    setAnchorEl(null);
    onChange(selected);
  };

  return (
    <Box display='flex' width={1}>
      <Box>
        <Paper component="form" className={classes.root}>
          <AutocompleteWithCheckBox
            data={data || []}
            value={selected.datasets}
            onChange={(event, value, reason) => {
              console.log(value);
              //handleChange(event, )
            }}
            inputProps={{
              variant: 'standard',
              label: "Datasets Override",
              InputLabelProps: {
                shrink: true,
              }
            }}

          />
          {
            // <InputBase
            //   className={classes.input}
            //   placeholder="Filter Datasets"
            //   inputComponent={AutocompleteWithCheckBox}
            //   inputRef={ref}
            //   inputProps={{
            //     ref: ref,
            //     data: data || [],
            //     value: selected.datasets,
            //     onChange: (event, value, reason) => {
            //       console.log(value);
            //       //handleChange(event, )
            //     }
            //   }}
            // />
          }
        </Paper>
      </Box>

      <Box paddingX={3}>
        <Paper component="form" className={classes.root}>
          <Button aria-controls="range" aria-haspopup="true" onClick={handleClick} style={{ textTransform: 'capitalize' }}>
            { [...rangeOptions].filter(item => selected.range === JSON.stringify(item.value)).pop()?.label || '?' }
          </Button>

          {
            //<Divider className={classes.divider} orientation="vertical" />
            // <Button aria-controls="view" aria-haspopup="true" onClick={handleClick} style={{ textTransform: 'capitalize' }}>
            //   { selected.view }
            // </Button>
            // <Divider className={classes.divider} orientation="vertical" />
            // <Button aria-controls="gridLayout" aria-haspopup="true" onClick={handleClick} style={{ textTransform: 'capitalize' }}>
            //   { selected.gridLayout }
            // </Button>
          }
          <Divider className={classes.divider} orientation="vertical" />

          <Tabs
            value={selected.gridLayout}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, value) => handleChange(event, { gridLayout: value })}
            aria-label="disabled tabs example"
          >
            {
              gridOptions.map((item, index) => (
                <Tab key={index} component={Button} classes={{ root: classes.tab }}  size='small' value={item.value} label={<Icon>{item.icon}</Icon>} />
              ))
            }
          </Tabs>
          {
            // <ButtonGroup size='small' variant="contained" color="primary" aria-label="contained primary button group">
            //   {
            //     [
            //       'Hourly View',
            //       'Daily View',
            //       'Handled By',
            //     ].map((item, index) => (
            //       <Button color='secondary' key={index}>{item}</Button>
            //     ))
            //   }
            // </ButtonGroup>
          }

          <Menu
            id="range"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl) && anchorEl.getAttribute("aria-controls") === 'range'}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <ListSubheader component="li">Duration</ListSubheader>
            {
              rangeOptions.map((item, index) => {
                return (
                  <MenuItem key={index} selected={selected.range === JSON.stringify(item.value)} onClick={(e) => handleClose(e, { range: JSON.stringify(item.value) })} value={item.value}>
                    {
                      // <ListItemIcon>
                      //   <Icon>{item.icon}</Icon>
                      // </ListItemIcon>
                    }
                    <ListItemText primary={item.label} />
                  </MenuItem>
                )
              })
            }
          </Menu>

          {
            // <Menu
            //   id="view"
            //   anchorEl={anchorEl}
            //   keepMounted
            //   open={Boolean(anchorEl) && anchorEl.getAttribute("aria-controls") === 'view'}
            //   onClose={handleClose}
            //   anchorOrigin={{
            //     vertical: 'bottom',
            //     horizontal: 'center',
            //   }}
            //   transformOrigin={{
            //     vertical: 'top',
            //     horizontal: 'center',
            //   }}
            // >
            //   <ListSubheader component="li">View</ListSubheader>
            //   {
            //     [
            //       'Hourly View',
            //       'Daily View',
            //       'Handled By',
            //     ].map((item, index) => (
            //       <MenuItem key={index} selected={selected.range === item} onClick={(e) => handleClose(e, { view: item })} value={item}>{item}</MenuItem>
            //     ))
            //   }
            // </Menu>
            //
            // <Menu
            //   id="gridLayout"
            //   anchorEl={anchorEl}
            //   keepMounted
            //   open={Boolean(anchorEl) && anchorEl.getAttribute("aria-controls") === 'gridLayout'}
            //   onClose={handleClose}
            //   anchorOrigin={{
            //     vertical: 'bottom',
            //     horizontal: 'center',
            //   }}
            //   transformOrigin={{
            //     vertical: 'top',
            //     horizontal: 'center',
            //   }}
            // >
            //   <ListSubheader component="li">Grid Layout</ListSubheader>
            //   {
            //     gridOptions.map((item, index) => (
            //       <MenuItem key={index} selected={selected.gridLayout === item.value} onClick={(e) => handleClose(e, { gridLayout: item.value })} value={item.value}>
            //         <ListItemIcon>
            //           <Icon>{item.icon}</Icon>
            //         </ListItemIcon>
            //         <ListItemText primary={item.label} />
            //       </MenuItem>
            //     ))
            //   }
            // </Menu>
          }
        </Paper>
      </Box>
      <Box>
        <Paper component="form" className={classes.root}>
          <Tabs
            value={selected.view}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, value) => handleChange(event, { view: value })}
            aria-label="disabled tabs example"
          >
            {
              [
                'Hourly View',
                'Daily View',
                'Handled By',
              ].map((item, index) => (
                <Tab key={index} component={Button} classes={{ root: classes.tab }} size='small' value={item} label={item} />
              ))
            }
          </Tabs>
        </Paper>
      </Box>
    </Box>
  )
};

export default CustomToolBar;
