import React, { useCallback, useEffect } from 'react';

import { usePhone } from 'Providers';

import MergeIcon from '@mui/icons-material/Merge';

import {
  Stack,
  Button,
  Divider,
} from '@mui/material';


const AttendedTransferButton = (props) => {
  let mounted = true;
  const { sessions } = props;
  const { attendedTransfer } = usePhone();

  useEffect(() => {
    return () => {
      mounted = false;
    }
  }, [])

  const handleClick = useCallback(
    (event) => {
      if(mounted) {
        attendedTransfer(sessions[1], sessions[0])(event);
      }
    },
    [],
  );

  return (
    <Button variant="contained" fullWidth onClick={handleClick}>
       <MergeIcon sx={{ marginRight: '4px', transform: 'rotate(90deg)' }} /> Attended Transfer
    </Button>
  )
}

export default AttendedTransferButton;
