import React, { useState, useContext } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { styled, useTheme } from "@mui/material/styles";

import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import SwipeableViews from 'react-swipeable-views';

import {
  Tab,
  Tabs,
  Box,
  AppBar,
  Toolbar,
  Typography
} from '@mui/material';

import TabsContext from 'Contexts/TabsContext';

import PhoneIcon from '@mui/icons-material/Phone';

import { WEBRTC_PHONE_DRAWER_WIDTH } from 'constants/Widths';

import styles from "assets/jss/components/mainNavbarStyle.js";
const useStyles = makeStyles(styles);

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 8 : 4,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {
        value === index && children
      }
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
    sx: {
      minHeight: '60px !important',
    }
  };
}

const minHeight = "72px";

const DrawerTabsComponent = ({ components, index, style, color, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { currentTab, setTab } = useContext(TabsContext);

  const handleChange = (event, newValue) => setTab(newValue);
  const handleChangeIndex = (index) => setTab(index);

  return (
    <div id="DrawerTabsComponent">
      <ElevationScroll {...props} enableColorOnDark>
        <AppBar
          position="sticky"
          color={color}
          classes={{
            colorPrimary: classes.somicHeader
          }}
          className="tabs-component"
          sx={{
            minHeight: minHeight
          }}
        >
          {
            <Tabs
              value={currentTab}
              onChange={handleChange}
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
              centered
              classes={{
                indicator: classes.somicTabsIndicator,
                flexContainer: classes.fullHeight,
              }}
              sx={{
                minHeight: minHeight,
                color: "white"
              }}
            >
              {
                components.map(({ label, icon }, index) => {
                  return <Tab
                    key={"Tab-"+index}
                    label={label}
                    icon={icon}
                    classes={{
                      selected: classes.somicTabsIndicatorSelected
                    }}
                    {...a11yProps(index)}
                  />
                })
              }
            </Tabs>
          }
        </AppBar>
      </ElevationScroll>

      <SwipeableViews
        axis={'x'}
        index={currentTab}
        onChangeIndex={handleChangeIndex}
      >
        {
          components.map(({ component }, index) => {
            return (
              <TabPanel key={"TabPanel-" + index} value={index} index={index}>
                { component }
              </TabPanel>
            )
          })
        }
      </SwipeableViews>

      {
        // <TabPanel key={"TabPanel-" + index} value={index} index={index}>
        //   <MenuTest count={60} />
        // </TabPanel>
      }

    </div>
  );
}

DrawerTabsComponent.defaultProps = {
  index: 0,
  components: [],
  color: 'primary'
}

export default DrawerTabsComponent;
