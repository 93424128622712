import React, { Component, forwardRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { WEBRTC_PHONE_DRAWER_WIDTH } from 'constants/Widths';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

class MainDrawer extends Component {
  constructor(props) {
    super(props)

    this.ref = React.createRef();
  }

  render() {
    const {
      children,
      renderScrollbar,
      variant,
      anchor
    } = this.props;

    return (
      <Drawer
        PaperProps={{
          ref: this.ref // important bind scollbar to PerfectScrollbar
        }}
        sx={{
          width: WEBRTC_PHONE_DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: WEBRTC_PHONE_DRAWER_WIDTH
          }
        }}
        elevation={20}
        variant={variant}
        anchor={anchor}
        open
      >
        { children }
      </Drawer>
    );
  }
};

export default MainDrawer;
