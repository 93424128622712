import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import { Fab } from '@mui/material';

// import { v4 as uuidv4 } from 'uuid';

import CallIcon from  '@mui/icons-material/Call';

import { missedCallAction } from 'store/missedcalls/actions';

const CustomFab = withStyles((theme) => ({
  root: {
    '&:hover': {
      color: `${grey[400]} !important`
    }
  }
}))(Fab);


const CallButton = ({ dispatch, loading, protocol, href, args, ...props }) => {
  return !loading && <CustomFab href={protocol + href} {...props} onClick={event => setTimeout(() => dispatch(missedCallAction(...args)), 1500)} aria-label="CallIcon">
    <CallIcon />
  </CustomFab>
}

CallButton.defaultProps = {
  size: 'small',
  color: 'primary'
};


const mapStateToProps = state => ({
  loading: state.additionals.loading,
  protocol: state.additionals.phone_protocol,
});

// const areEqual = (prevProps, nextProps) => {
//   console.log(prevProps, nextProps)
//   return prevProps.loading === nextProps.loading || prevProps.protocol === nextProps.protocol;
// };

export default connect(
  mapStateToProps,
  null,
)(CallButton);


// import React, { memo } from 'react';
// import { connect } from 'react-redux';
// import { withStyles } from '@mui/styles';
// import { grey } from '@mui/material/colors';
// import { Fab } from '@mui/material';
//
// import CallIcon from  '@mui/icons-material/Call';
//
// import { missedCallAction } from 'store/missedcalls/actions';
//
// const CustomFab = withStyles((theme) => ({
//   root: {
//     '&:hover': {
//       color: `${grey[400]} !important`
//     }
//   }
// }))(Fab);
//
// const areEqual = (prevProps, nextProps) => {
//   console.log(prevProps, nextProps)
//   return prevProps.loading === nextProps.loading || prevProps.protocol === nextProps.protocol;
// };
//
// const CallButton = memo(({ dispatch, loading, protocol, href, args, ...props }) => {
//   const handleMissedCall = event => setTimeout(() => dispatch(missedCallAction(...args)), 1000);
//
//   return !loading &&
//     <CustomFab href={protocol + href} {...props} onClick={handleMissedCall} aria-label="CallIcon">
//       <CallIcon />
//     </CustomFab>
// }, areEqual)
//
// CallButton.defaultProps = {
//   size: 'small',
//   color: 'primary'
// };
//
//
// const mapStateToProps = state => ({
//   loading: state.additionals.loading,
//   protocol: state.additionals.phone_protocol,
// });
//
// export default connect(
//   mapStateToProps,
//   null,
// )(CallButton);
