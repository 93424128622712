import {
  DIDS_PENDING,
  DIDS_SUCCESS,
} from 'store/types';

import { onError,  enqueueSnackbar } from 'store/Notifier/actions';

export const dids_Actions = () => {
  return async (dispatch, getState, firebase) => {
    dispatch({ type:  DIDS_PENDING });

    try {
      await firebase.authStatePromise;
      firebase.dids().on('value',
        snapshot => {
          dispatch({ type:  DIDS_SUCCESS, payload: asCollection(snapshot.val()) });
        },
        err => {
          throw err;
        }
      );
    } catch (err) {
      onError(dispatch, err);
    }
  }
};

export const dids_update_Actions = (id, data, cb) => {
  return async (dispatch, getState, firebase) => {
    const loadingKey = enqueueSnackbar(dispatch, 'LOADING')
    try {
      await firebase.transaction(firebase.dids().child(id), (snapshot) => {
        return {...Object.assign(snapshot || {}, data)}
      });

      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey)
    } catch (err) {
      onError(dispatch, err);
    } finally {
      cb();
    };
  }
};

const asCollection = (object, idProp) => Object.keys(object || {}).map(key => ({ ...object[key], [idProp || 'id']: key}));
