import React, { useRef, forwardRef, useState, useEffect, useContext, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Logger from '../Logger';

import { v4 as uuidv4 } from 'uuid';

import { styled } from '@mui/material/styles';

import { invite } from 'WEBRTC/phoneManager';
import { outbound_calllog_action, outbound_calllog_get_item, OutboundCalllogItems } from 'WEBRTC/outbound_calllogManager';

import moment from 'Helpers/Momentjs';

//import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import useAutocomplete from '@mui/material/useAutocomplete';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
// import CallingListContext from 'Contexts/CallingListContext';
// import useSessionStorage from '../GlobalStates/useSessionStorage';

import { RenderGroup } from 'components/CustomAutoComplete';

import {
  Box,
  Paper,
  Divider,
  IconButton,
  InputBase,
  TextField,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  Popper,
  Menu,
  MenuItem,
  Avatar
} from '@mui/material';

import {
  StyledPopper,
  //ListboxComponent
} from './ListBoxComponent';

import { useForm, Controller } from "react-hook-form";

import { Card } from 'components/Card';
import RegularButton from 'components/CustomButtons';
import { TransparentTextField } from 'components/CustomInput';

import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CallIcon from '@mui/icons-material/Call';
import CloseIcon from '@mui/icons-material/Close';

import TooltipComponent from './TooltipComponent';
import DialingComponent from './AutoComplete/DialingComponent';
// import Dialer from './Dialer';

import { usePhone } from 'Providers';

const logger = new Logger('Dialer');

const DEFAULTVALUES = {
  target: null,
}

const DialerBar = (props) => {
  let mounted = true;
  const { invite } = usePhone();

  useEffect(() => {
    return () => {
      mounted = false;
    }
  }, [])

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    reset,
    setValue,
    formState,
    ...formProps
  } = useForm({
    defaultValues: {
      ...DEFAULTVALUES,
    }
  });

  useEffect(() => {
    // reset form after its submited!
    if(mounted && formState.isSubmitSuccessful) {
      reset(DEFAULTVALUES);
    }
  }, [formState, reset])

  const onSubmit = ({ target }, event) => {
    if(mounted) {
      try {
        invite(target);
        // cannot reset here, looses state as its async
      } catch(e) {
        console.log("err", e)
      } finally {
        // always resets field!
        reset(); // should clear field!!!
      }
    }
  };

  const handleRedialClick = (event) => {
    const target = getValues("target");

    if(target) {
      handleSubmit(onSubmit)(event); // try calling by form submit
    } else {
      if(JSON.stringify(control._defaultValues) !== JSON.stringify(DEFAULTVALUES)) reset(); // form reset
    }
  }

  return (
    <form name='dialer' onSubmit={(event) => event.preventDefault()}>
      <Paper id="dialer-bar-component" sx={{ p: '2px 4px', elevation: 6, display: 'flex', alignItems: 'center', minWidth: 400 }} elevation={8}>
        <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
          <Grid item xs="auto" style={{ flexGrow: 1 }}>
            <Controller
              name="target"
              control={control}
              render={({ field }) => <DialingComponent onSubmit={handleSubmit(onSubmit)} watch={watch} setValue={setValue} formState={formState} {...field} />}
            />
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
}


export default DialerBar;
