import React from "react";

// import FormAutoComplete from './FormAutoComplete';
import FormCreativeAutoComplete from './FormCreativeAutoComplete';

const FormCreativeMultipleAutoComplete = (props) => {
  return (
    <FormCreativeAutoComplete
      multiple
      disableCloseOnSelect // dont close on item select
      defaultValue={[]}
      limitTags={3}
      ChipProps={{
        color: "primary",
      }}
      {...props}
    />
  )
}

export default FormCreativeMultipleAutoComplete;
