import * as React from 'react';

import {
  Fade,
  Chip,
  Button,
  Grid,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CallIcon from '@mui/icons-material/Call';
import ExtensionIcon from '@mui/icons-material/Extension';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import {
  useUusiDialog
} from 'Providers';

import { useFormContext, Controller } from "react-hook-form";

const TelephoneFormDialog = (props) => {
  const { data: { item, number, onDelete, ...data }, open, handleClose } = useUusiDialog();
  const { control } = useFormContext(); // retrieve all hook methods

  function handleDelete(e) {
    onDelete();
    handleClose(e);
  }

  return open && (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Telephone Number: <Chip size='small' label={number} />
        <Button variant="outlined" size='small' color="error" style={{ float: "right" }} onClick={handleDelete} >Delete</Button>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container alignItems='center' justifyContent='center' spacing={4}>
          <Grid item xs={3}>
            <Controller
              name={`${item}.type`}
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    id="type"
                    select
                    label="Device Type"
                    variant="standard"
                    fullWidth
                    {...field}
                    SelectProps={{
                      renderValue: (value) => value // removes icon
                    }}
                    //helperText="Please select your currency"
                  >
                    <MenuItem value="extension">
                      <ListItemIcon>
                        <ExtensionIcon fontSize="inherit" />
                      </ListItemIcon>
                      <ListItemText primary="Extension" />
                    </MenuItem>
                    <MenuItem value="mobile">
                      <ListItemIcon>
                        <PhoneAndroidIcon fontSize="inherit" />
                      </ListItemIcon>
                      <ListItemText primary="Mobile" />
                    </MenuItem>
                    <MenuItem value="business">
                      <ListItemIcon>
                        <CallIcon fontSize="inherit" />
                      </ListItemIcon>
                      <ListItemText primary="Business" />
                    </MenuItem>
                  </TextField>
                )
              }}
            />
          </Grid>

          <Grid item xs={8}>
            <Controller
              control={control}
              name={`${item}.number`}
              render={({ field }) => (
                <TextField
                  autoFocus
                  margin="dense"
                  label="Telephone Number"
                  type="text"
                  fullWidth
                  variant="standard"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TelephoneFormDialog
