import React from 'react';
import { connect } from 'react-redux';
import { grey } from '@mui/material/colors';

import {
  TextField,
  ListItemText,
  InputAdornment,
  Autocomplete
} from '@mui/material';

import PhoneForwardedIcon from  '@mui/icons-material/PhoneForwarded';

import { isLoading } from 'devtools/Helpers';


const CallForwardAutoComplete = ({ onChange, label, customTextField, loading, data, dispatch, ...props }) => {
  return (
    <Autocomplete
      freeSolo
      blurOnSelect
      onChange={onChange}
      groupBy={(option) => option.category}
      options={data}
      loading={loading}
      getOptionLabel={(option) => String(option?.cc_number || option)}
      renderInput={({ InputProps: inputProps, ...params}) => {
        return (
          <TextField
            {...params}
            label={label}
            {...customTextField}
            InputProps={{
              ...inputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneForwardedIcon style={{ color: grey[500] }} />
                </InputAdornment>
              ),
            }}
          />
        )
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <ListItemText primary={option.description} secondary={option.cc_number} />
        </React.Fragment>
      )}
      {...props}
    />
  );
}

const mapStateToProps = state => ({
  data: state.numbers.cfList,
  loading: isLoading([state.numbers.loading,state.firebase.loading]),
});

export default connect(
  mapStateToProps,
  null
)(CallForwardAutoComplete);
