import moment from 'Helpers/Momentjs';
import { ARRAY_YEARS, ARRAY_MONTHS } from 'constants/Moment';


export const TIMECONDTIONS_CONDITIONS = [
  { value: 'year', title: 'Year', disabled: false },
  { value: 'mon', title: 'Month', disabled: false },
  { value: 'mday', title: 'Day of Month', disabled: true },
  { value: 'wday', title: 'Day of Week', disabled: false },
  { value: 'week', title: 'Week of Year', disabled: true },
  { value: 'mweek', title: 'Week of Month', disabled: true },
  { value: 'hour', title: 'Hour of Day', disabled: true },
  { value: 'minute-of-day', title: 'Time of Day', disabled: false },
  { value: 'date-time', title: 'Date & Time', disabled: false },
];


//export const TIMECONDTIONS_WEEKDAYS = moment.weekdays(true).map((item, index) => ({ value: `${index + 1}`, title: `${item}` }));
export const TIMECONDTIONS_WEEKDAYS = (() => {
  // SUN 1 SAt 7
  const array = moment.weekdays(true).map((item, index) => ({ value: `${index + 1}`, title: `${item}` }));
  return [...array.slice(1), array[0]]; // start monday first
})();

export const TIMECONDTIONS_MONTHS = ARRAY_MONTHS('MMMM').map((item, index) => ({ value: `${index + 1}`, title: `${item}` }));

export const TIMECONDTIONS_YEARS = ARRAY_YEARS().map(item => ({ value: `${item}`, title: `${item}` }))
