import React from "react";
// customize node_modules\material-table\dist\material-table.js
import { v4 as uuidv4 } from 'uuid';

import { TextField, FormControl, InputLabel, MenuItem, FormHelperText, InputAdornment, ListItemText } from '@mui/material';

const MuiTextField = (
  { input: { onChange, value, onFocus, onBlur, ...input }, label, meta: { asyncValidating, touched, error }, helperText, disableNone, themeColor, InputProps: inputProps, updateOnChange, ...props },
) => {
  const id = `MuiTextField-${uuidv4()}`;
  const {
    options,
    children,
    ...rest
  } = props;

  if(inputProps?.endAdornment || asyncValidating) {
    inputProps.endAdornment = (
      <React.Fragment>
        {inputProps.endAdornment}
        <InputAdornment position="start" style={{ visibility: asyncValidating ? 'visible' : 'hidden' }}>
          <i className={"fas fa-spinner fa-spin"} />
        </InputAdornment>
      </React.Fragment>
    )
  }

  return (
    <TextField
      id={id}
      label={label}
      value={value}
      error={error && touched}
      helperText={ helperText ? helperText(touched, error) : touched && error ? error : null }
      {...input}
      onFocus={updateOnChange ? null : onFocus}
      onBlur={updateOnChange ? null : onBlur} // forces update for forms after focus!
      onChange={(event) => {
        const newValue = event.target.value;
        if(!updateOnChange) {
          onChange(event);
        } else if (value != newValue && updateOnChange)  {
          onChange(event);
        }
      }}
      InputProps={inputProps || null}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    >
      {
        (options || children) && !disableNone && <MenuItem dense value=""><em>None</em></MenuItem>
      }
      {
        options && Array.isArray(options)
          ? [...options].map(({ value, title, secondary, disabled = false }, index) => {
              return (
                <MenuItem
                  key={index}
                  dense
                  value={value}
                  disabled={disabled}
                >
                  <ListItemText
                    primary={title || value}
                    secondary={secondary}
                  />
                </MenuItem>
              )
            })
          : children
      }
    </TextField>
  )
}

//<MenuItem dense key={index} value={value} disabled={disabled}>{title || value}</MenuItem>

MuiTextField.defaultProps = {
  InputProps: {},
  updateOnChange: false,
  //value: "", saattaa rikkoa
  variant: 'outlined',
  size: 'small',
  disableNone: false,
}


export default MuiTextField;
