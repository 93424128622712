import React, { useState, useCallback, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes

// @mui/material components
//import { makeStyles, createStyles } from '@mui/styles';
import { TextField, FormControl } from '@mui/material';

// import styles from "assets/jss/components/customInputStyle.js";

import { debounce } from 'lodash';

// const useStyles = makeStyles(createStyles(styles));

const CustomTextField = ({ value: valueProp, ...props}) => {
  const [value, setValue] = useState(valueProp || '');
  // const classes = useStyles();
  const {
    formControlProps,
    id,
    customProps,
    error,
    success,
    white,
    onChange,
    inputRootCustomClasses,
    errorText,
    successText,
  } = props;

  useEffect(() => {
    if(value !== valueProp) setValue(valueProp);
  }, [valueProp]);

  let staticProps;

  if(customProps.error) staticProps.helperText = errorText;
  if(customProps.success) staticProps.helperText = successText;

  const handler = useCallback(debounce(value => onChange(value), 400), []);

  return (
    <FormControl {...formControlProps} >
      <TextField
        id={id}
        value={value || ''}
        {...customProps}
        {...staticProps}
        onChange={e => {
          setValue(e.currentTarget.value);
          e.persist();
          handler(e);
        }}
      />
    </FormControl>
  );
}

CustomTextField.defaultProps = {
  value: ""
}

CustomTextField.propTypes = {
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node
};

export default CustomTextField;
