import React, { useState, useEffect, useRef } from 'react';

import {
  Box,
  FormControlLabel,
  Switch,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputAdornment,
  MenuItem
} from '@mui/material';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import { VisibilitySwitch } from 'components/CustomSwitch';

import SortIcon from '@mui/icons-material/Sort';

import { useFormContext, Controller } from "react-hook-form";

const OPTIONS = [
  { label: 'None', value: null },
  { label: 'Name', value: 'name' },
  { label: 'Number', value: 'number' },
];

function transform(value) {
  return {
    input: () => {
      //console.log('input', value)
      return (value == undefined || value == null) ? '' : value;
    },
    output: () => {
      //console.log('output', value)
      return (value == '' || !value) ? null : value;
    }
  }
}

const FriendsOptionsDialog = ({ data, open, handleClose, ...props }) => {
  const { control } = useFormContext(); // retrieve all hook methods
  const descriptionElementRef = useRef(null);
  const { title, controllerName } = data;

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" sx={{ display: "flex" }}>
        <Box flexGrow={1}>{title}</Box>
        <Box>
          <HelperTooltipBootstrap title="Visibility" placement='left' arrow>
            <span>
              <Controller
                name={`${controllerName}.display`}
                control={control}
                render={({ field: { value, ...field}}) => {
                  return (
                    <FormControlLabel
                      value={value}
                      control={<Switch checked={value} {...field} />}
                      label="Visibility"
                      labelPlacement="start"
                    />
                  )
                }}
              />
            </span>
          </HelperTooltipBootstrap>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Controller
            render={({ field: {value, onChange, ...field}}) => (
              <TextField
                disabled
                id="outlined-select-currency"
                select
                label="List Sorting"
                onChange={(e) => onChange(transform(e.target.value).output())}
                value={transform(value).input()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SortIcon />
                    </InputAdornment>
                  ),
                }}
                SelectProps={{
                  displayEmpty: true
                }}
                fullWidth
                {...field}
              >
                {
                  OPTIONS.map((option, index) => {
                    return (
                      <MenuItem key={index} value={transform(option.value).input()}>{option.label}</MenuItem>
                    )
                  })
                }
              </TextField>

            )}
            name="sortBy"
            control={control}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

FriendsOptionsDialog.defaultProps = {
  data: {}
}

export default FriendsOptionsDialog;
