import React from 'react';
import { grey } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

import {
  Chip,
} from '@mui/material';

export const DarkChip = withStyles({
  root: {
    backgroundColor: grey[400]
  }
})(Chip);


const StyledChip = styled(Chip)({
  '.mini': {
    fontSize: '10px',
    fontWeight: 'bold',
    padding: '2px',
    height: 'auto',
    '> .MuiChip-label': {
      paddingLeft: '4px',
      paddingRight: '4px',
    }
  }
});


// const StyledChip = styled(Chip)(({ theme }) => ({
//   root: {
//     '& .mini': {
//       fonSize: '10px',
//       fontWeight: 'bold',
//       padding: '2px',
//       height: 'auto',
//     },
//     '> MuiChip-label': {
//       paddingLeft: '4px',
//       paddingRight: '4px',
//     }
//   },
//   mini: {
//     fonSize: '10px',
//     fontWeight: 'bold',
//     padding: '2px',
//     height: 'auto',
//   }
// }));

export default StyledChip;
