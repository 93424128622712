import * as React from 'react';

import {
  Box
} from '@mui/material';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { useFormContext, useFieldArray, useWatch, Controller } from "react-hook-form";

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

const TelephoneNumbersSplitButton = ({ addAction }) => {
  const { control, watch, getValues, setValue } = useFormContext(); // retrieve all hook methods
  const { preferred_telephone, preferred_telephone_uuid } = useWatch(["telephones", "preferred_telephone", "preferred_telephone_uuid"]);

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    keyName: 'key',
    name: "telephones", // unique name for your Field Array
  });

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (event, id, number) => {
    //setSelectedIndex(index);
    if(number) setValue('preferred_telephone', `${number}`, { shouldDirty: true })
    if(id) setValue('preferred_telephone_uuid', `${id}`, { shouldDirty: true })
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box sx={{ float: "right" }}>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
          <HelperTooltipBootstrap
            title="Preffered Number!"
            placement='left'
            arrow
          >
            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <small>{preferred_telephone || 'Pick Preffered Number'}</small>
              <ArrowDropDownIcon />
            </Button>
          </HelperTooltipBootstrap>
         <Button onClick={addAction}>Add</Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {
                    fields.map(({ number, id }, index) => (
                      <MenuItem
                        key={index}
                        selected={id === preferred_telephone_uuid}
                        onClick={(event) => handleMenuItemClick(event, id, number)}
                        sx={{ textAlign: 'right' }}
                      >
                        {number}
                      </MenuItem>
                    ))
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}

export default TelephoneNumbersSplitButton;
