import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  grayColor
} from "assets/jss/default.js";
const cardIconStyle = {
  cardIcon: {
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader": {
      borderRadius: "3px",
      backgroundColor: grayColor[0],
      padding: "6px",
      marginTop: "-20px",
      marginRight: "15px",
      float: "left"
    },
    "&.p0": {
      padding: "0px !important"
    }
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  "@media (min-width: 1224px)": {
    cardIcon: {
      padding: "15px !important",
    },
    "&.p0": {
      padding: "0px !important"
    }
  }
};


export default cardIconStyle;
