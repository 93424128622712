import {
  WEBRTC_SESSIONS_INCREMENT,
  WEBRTC_SESSIONS_DECREMENT
} from 'store/types';

const INITIALSTATE = {
  sessions: [],
};

export default function (state = INITIALSTATE, action) {
  switch (action.type) {
    case WEBRTC_SESSIONS_INCREMENT:
      return {
        ...Object.assign(state, {
          sessions: [...state.sessions, action.payload]
        })
      };
    case WEBRTC_SESSIONS_DECREMENT:
      return {
        ...Object.assign(state, {
          sessions: state.sessions.filter(session => session.uuid !== action.payload.uuid)
        })
      };
    default:
      return state;
  }
};
