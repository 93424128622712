import React from 'react';
import { connect } from 'react-redux';
import { submit, formValueSelector } from 'redux-form';

import { Grid, Box, Snackbar } from '@mui/material';
import RegularButton from 'components/CustomButtons';

const RemoteSubmitAndUndoComponent = (props) => {
  const { dispatch, undo, message, pristine, dirty, reset, submitting, form, formValues, initialValues, openOnDirty } = props;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  };

  return (
    <div>
      <Snackbar
        id={form}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={
          openOnDirty
            ? dirty
            : initialValues
              ? JSON.stringify(formValues(form, Object.keys(initialValues))) !== JSON.stringify(initialValues)
              : dirty
                ? true
                : false
        }
        onClose={handleClose}
        message={
          <small>{message}</small>
        }
        action={
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <RegularButton
                size='sm'
                color="success"
                round
                disabled={pristine || submitting}
                onClick={(e) => dispatch(submit(form))}
              >
                <Box pr={1} display={submitting ? 'block' : 'none' }>
                  <i className={"fas fa-spinner fa-spin"} />
                </Box>
                Apply
              </RegularButton>
            </Grid>

            {
              undo &&
                <Grid item>
                  <RegularButton
                    type="button"
                    size='sm'
                    color="warning"
                    round
                    disabled={pristine || submitting}
                    onClick={() => dispatch(reset(form))}
                  >
                    Undo
                  </RegularButton>
                </Grid>
            }

          </Grid>
        }
      />
    </div>
  )
};

RemoteSubmitAndUndoComponent.defaultProps = {
  undo: true,
  message: 'Default',
  openOnDirty: false
};

const mapStateToProps = state => ({
  formValues: (form, props = []) => {
    const selector = formValueSelector(form)
    if(props.length > 0) {
      return selector(state, ...props);
    } else {
      return {};
    }
  }
});

export default connect(
  mapStateToProps,
  null,
)(RemoteSubmitAndUndoComponent)
