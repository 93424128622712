import React from "react";
import { FormAdvancedSpeakComponent } from 'components/Forms/Components';
import { Field } from 'redux-form'
import { Box } from '@mui/material';

const AdvancedTTS = (props) => {
  return (
    <Box width={1}>
      <Field
        component={FormAdvancedSpeakComponent}
        name="ssml"
        themeColor='white'
        label="Text To Speech Advanced"
        multiline
        rows={8}
        style={{ padding: 0, width: '100%' }}
        fullWidth
        variant="outlined"
        placeholder="Add Message Here"
        inputProps={{
          spellCheck: false
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  )
}


export default AdvancedTTS
