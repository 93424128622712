import React, { Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { purple } from '@mui/material/colors';
// @mui/material components
import { makeStyles } from '@mui/styles';

import { withAnimation } from 'HOC';

import { Outlet } from "react-router-dom";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";

// import { ALLROUTES } from "routes.js";

import login from "assets/img/login.jpeg";

import styles from "assets/jss/layouts/authStyle.js";
const useStyles = makeStyles(styles);

const Auth = React.memo(({ children, ...props}) => {
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  return (
    <div className={classes.wrapper} ref={wrapper}>
      <AuthNavbar />
      <div
        className={classes.fullPage}
        style={{ backgroundImage: "url("+login+")" }}
      >
        {
          <Suspense fallback={<div />}>
            <Outlet />
          </Suspense>
        }
      </div>
    </div>
  );
});

export default withAnimation(React.memo(Auth));
