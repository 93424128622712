import React, { useState, useEffect, useCallback, useReducer } from "react";

import { useDebugger } from 'Providers';

import {
  Box,
  Avatar,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Switch,
  Button,
  IconButton,
  ListItemButton,
  Collapse
} from '@mui/material';

// import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BugReportIcon from '@mui/icons-material/BugReport';
import DialerSipIcon from '@mui/icons-material/DialerSip';

const SIPDebugOption = (props) => {
  const { jssip, setDebugger } = useDebugger();
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    setOpen(!open);
  };

  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon sx={{ justifyContent: 'center'}}>
          <BugReportIcon />
        </ListItemIcon>
        <ListItemText
          primary="Debugger"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              ></Typography>
              <small>
                {" — Log event to console"}
              </small>
            </React.Fragment>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            onClick={(e) => setDebugger({ type: "SET", payload: { jssip: !jssip }})}
          >
            <ListItemIcon sx={{ justifyContent: 'center'}}>
              <DialerSipIcon />
            </ListItemIcon>
            <ListItemText
              primary="SIP Protocol"
              secondary={
                <small>
                  {" — Show SIP debug in console"}
                </small>
              }
            />
            <Switch checked={jssip} color="primary" />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
}

export default SIPDebugOption;
