import React, { useEffect, useCallback, useState } from "react";

import { isEmpty } from 'lodash';

import {
  Box,
  Stack,
  Button,
  IconButton,
  Divider,
  Typography,
  Grid,
  TextField,
  Avatar,
  Paper,
  Collapse,
  Alert,
  InputAdornment
} from '@mui/material';

import { useForm, Controller, FormProvider, useFormContext } from "react-hook-form";
import { disableFormSubmitByEnterKey, sleep } from 'Helpers/Forms';
import { LoadingButton } from 'components/HookForm/Buttons';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import { ProfileAvatar } from 'components/CustomAvatars';

import ExtensionIcon from '@mui/icons-material/Extension';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import StarIcon from '@mui/icons-material/Star';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

import {
  RinggroupOptions,
  AccountOptionsList
} from './Components';

import { ErrorMessage } from "@hookform/error-message";

import { change_password } from 'Services/Actions';
import { service_account } from 'Services/service_account';

import localstorage from 'localStorage';

const INITIALVALUES = () => ({
  password1: '',
  password2: ''
});

const FormActionButtons = ({ onSubmit, ...props }) => {
  const methods = useFormContext(); // retrieve all hook methods
  const { watch, formState, handleSubmit } = methods;
  //watch(); // watch for form changes

  return (
    <Box display='flex' flexGrow={1}>
      <LoadingButton type="submit" onSubmit={handleSubmit(onSubmit)} {...methods} {...props}>
        Save
      </LoadingButton>
    </Box>
  )
}

const PasswordChangeDrawer = ({ handleClose, data, isLoading, ...props }) => {
  let mounted = true;
  const [show, setShow] = useState({
    password1: false,
    password2: false
  })


  useEffect(() => {
    return () => mounted = false;
  }, []);

  const methods = useForm({
    defaultValues: {...INITIALVALUES()}
  });

  const { handleSubmit, control, watch, reset, setValue, setError, formState: { errors, isDirty, isSubmitting }} = methods;

  // SUBMIT
  const onSubmit = useCallback(
    async (data) => {
      console.log('onSubmit', data);
      try {
        if(data.password1 !== data.password2) throw 'passwords do not match!';
        await change_password(data.password2);
      } catch (err) {
        setError("singleErrorInput", { type: "manual", message: err?.message ?? err });
      } finally {
        await sleep(800); // little delay for loader!
        if(isEmpty(errors)) handleClose();
      }
    },
    [],
  );


  const handleClickShowPassword = (name) => (event) => {
    setShow({
      ...show,
      [name]: !show[name],
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormSubmitByEnterKey}>
        <Paper elevation={12} sx={{ padding: "0px 0px 40px 0px", borderRadius: "0px 0px 20px 20px" }}>
          <Grid container justifyContent='flex-end' alignItems="center" spacing={0} sx={{ width: '100%', height: '100%'  }}>
            <Grid item>
              <Stack direction="row" justifyContent='space-around' px={2} spacing={5}>
                <IconButton onClick={(event) => setTimeout(handleClose(event))} aria-label="delete" size="large">
                  <CloseRoundedIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems="center" spacing={2} sx={{ width: '100%', height: '100%'  }}>
            <Grid item xs="auto">
              <Avatar sx={{ height: 60, width: 60, fontSize: 40, display: "flex" }}>
                <FingerprintIcon fontSize='inherit' />
              </Avatar>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2" sx={{ width: "100%", textAlign: 'center' }} >Change Password</Typography>
            </Grid>

            <Grid item xs={11}>
              <Controller
                control={control}
                name="password1"
                rules={{
                  required: true,
                  minLength: 5,
                }}
                render={({ field }) => (
                  <TextField
                    id="outlined-basic1"
                    type={show.password1 ? "text" : "password"}
                    label="New Password"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword('password1')}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {show.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={11}>
              <Controller
                control={control}
                name="password2"
                rules={{
                  required: true,
                  minLength: 5,
                }}
                render={({ field }) => (
                  <TextField
                    id="outlined-basic2"
                    type={show.password2 ? "text" : "password"}
                    label="Retype Password"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword('password2')}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {show.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    {...field}
                  />
                )}

              />
            </Grid>
          </Grid>
        </Paper>

        <br />

        <Grid container justifyContent='center' alignItems="center" spacing={5}>
          <Grid item xs={11} textAlign='center'>
            {isEmpty(errors) && "You have to have a recent login to perform this update!"}
            <ErrorMessage
              errors={errors}
              name="singleErrorInput"
              render={({ message }) => (
                <Collapse in timeout={200}>
                  <Alert severity="error" variant="filled">
                    <strong>{ message }</strong>
                  </Alert>
                </Collapse>
              )}
            />
          </Grid>

          <Grid container item xs={12} justifyContent='center'>
            <Grid item>
              <Paper elevation={3}>
                <Stack direction="row" justifyContent='space-around' px={2} py={1} spacing={5}>
                  <FormActionButtons onSubmit={onSubmit} handleClose={handleClose} />
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

      </form>
    </FormProvider>
  );
}


const logicalComponent = (Component) => (props) => {
  let mounted = true, subscription;
  const [data, setData] = useState(null);
  const { add_subscriber, remove_subscriber } = service_account();

  useEffect(() => {
    if(mounted) {
      subscription = add_subscriber(
        value => {
          if(mounted && value) {
            setData(value);
          }
        },
        err => console.log("PasswordChangeDrawer", err)
      )
    }

    return () => mounted = false;
  }, []);

  return <Component {...props} data={data ?? {}} isLoading={!Array.isArray(data)} />
}


export default logicalComponent(PasswordChangeDrawer);
