import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { orange, green } from '@mui/material/colors';

import { Switch, FormGroup, FormControlLabel } from '@mui/material';

const OrangeThemeSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: orange[500],
    },
    '&$checked + $track': {
      backgroundColor: orange[600],
    },
  },
  disabled: {
    '&$checked': {
      color: orange[300] + ' !important',
    }
  },
  checked: {},
  track: {},
})(Switch);

const GreenThemeSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: green[600],
    },
    '&$checked + $track': {
      backgroundColor: green[700],
    },
  },
  disabled: {
    '&$checked': {
      color: green[300] + ' !important',
    }
  },
  checked: {},
  track: {},
})(Switch);

const CustomSwitch = ({ onChange, checked: checkedProp, customFormControlProps, ...props }) => {
  const [checked, setChecked] = useState(Boolean(checkedProp));

  useEffect(() => {
    if(checkedProp !== checked) setChecked(checkedProp)
  }, [checkedProp])


  const toggleChecked = (e) => {
    setChecked((prev) => !prev);
    onChange(e, e.currentTarget.checked);
  };

  return (
    <FormGroup style={{ alignItems: 'center' }}>
      <FormControlLabel style={{ margin: 0 }}
        control={<props.theme {...props} checked={checked} onChange={toggleChecked} />}
        {...customFormControlProps}
      />
    </FormGroup>
  );
}


const OrangeSwitch = (props) => {
  return (
    <CustomSwitch theme={OrangeThemeSwitch} {...props} />
  )
}

OrangeSwitch.defaultProps = {
  checked: false,
};

const GreenSwitch = (props) => {
  return (
    <CustomSwitch theme={GreenThemeSwitch} {...props} />
  )
}

GreenSwitch.defaultProps = {
  checked: false,
};


export {
  GreenSwitch,
  GreenThemeSwitch,
  OrangeSwitch,
  OrangeThemeSwitch,
}
