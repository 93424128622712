import React from 'react';
import { createTheme } from "@mui/material/styles";
// import createBreakpoints from "@mui/material/styles/createBreakpoints";

const BREAKPOINTS = {
  xs: 0,
  sm: 960,
  md: 1280,
  lg: 1420,
  xl: 1680,
  mobile: 0,
  tablet: 640,
  laptop: 1024,
  desktop: 1200,
};

// const breakpointsFull = createBreakpoints({
//     values: {...BREAKPOINTS},
//     keys: Object.keys(BREAKPOINTS),
//   });

// export const theme = createTheme({
//    // breakpoints: breakpointsFull,
// });

export const theme = createTheme({
  breakpoints: {
    values: {
      ...BREAKPOINTS
    },
  },
});
