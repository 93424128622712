import { firebase, store } from 'index.js';

import { isEmpty, uniqBy, keyBy, merge } from 'lodash';

import { v4 as uuidv4 } from 'uuid';

import { onError, enqueueSnackbar } from 'store/Notifier/actions';

import { ReplaySubject, combineLatest } from "rxjs";
import { map, distinctUntilChanged } from 'rxjs/operators';

import { makeCollection, sortCollection, sortByTime, parseTypes, compare } from 'devtools/Helpers';

import { serviceCleanup } from './index';

// tämä toimii hakuna!
// firebase.contacts().child("telephones")
// .orderByChild('+358931521916')
// .startAt("+358931521916")
// .once("value")
// .then((snapshot) => console.log('math orderByChild +358931521916', snapshot.val()))

function getData(snapshot) {
  return makeCollection(parseTypes(snapshot.toJSON(), true));
}


// Contact page
export const service_contact = function (id) {
  if(!id) return {
    add_subscriber: null,
    remove_subscriber: null
  };

  const observable_general$ = new ReplaySubject();
  const observable_emails$ = new ReplaySubject();
  const observable_telephones$ = new ReplaySubject();
  const observable_search_tags$ = new ReplaySubject();

  let subscriptions = [];

  const refs = [
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("general").child(id), "on", "value", (snapshot) => observable_general$.next(snapshot.toJSON())),
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("emails").child(id), "on", "value", (snapshot) => observable_emails$.next(snapshot.toJSON())),
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("telephones").orderByChild('contact').equalTo(id), "on", "value", (snapshot) => observable_telephones$.next(getData(snapshot))),
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("search_tags").child(id), "on", "value", (snapshot) => observable_search_tags$.next(getData(snapshot))),
  ];

  const observable = combineLatest(observable_general$, observable_emails$, observable_telephones$, observable_search_tags$)
    .pipe(
      map(([general, emails = [], telephones = [], search_tags = []]) => {
        return {
          id: id,
          ...general,
          emails: emails,
          telephones: telephones,
          search_tags: search_tags,
        }
      }),
      distinctUntilChanged(),
    );

  function add_subscriber(callback) {
    return subscriptions.push(
      observable.subscribe(
        callback,
        err => console.warn('service_contact', err)
      )
    ); // add to array and return subscription!
  }


  return {
    add_subscriber: add_subscriber,
    remove_subscriber: (subscription) => serviceCleanup(subscription, refs, subscriptions),
  }
}

export const firebase_set_contact = async (id, data = {}, bulkUpdates = {}) => {
  // maybe needs recording_filename to be changed!
  const loadingKey = enqueueSnackbar(store.dispatch, 'LOADING');

  try {
    if(isEmpty(data)) throw 'Data property empty!';
    await firebase.authStatePromise;
    const postKey = id ?? await firebase.contacts().push().key; // contruct new key without any data!

    const {
      telephones,
      emails,
      search_tags,
      ...rest
    } = data;

    //telephones
    for(let {id, ...telephone } of telephones) {
      if(telephone.contact === 'new') telephone.contact = postKey;
      bulkUpdates['/telephones/' + id] = telephone;
    };

    bulkUpdates['/emails/' + postKey] = toObject(emails);
    bulkUpdates['/search_tags/' + postKey] = toObject(search_tags);
    bulkUpdates['/general/' + postKey] = rest;

    await firebase.contacts().update(bulkUpdates);
    enqueueSnackbar(store.dispatch, 'SUCCESS', loadingKey);
  } catch (err) {
    onError(store.dispatch, err)
  }
};


export const firebase_unset_telephone_contact = async (id) => {
  try {
    if(!id) throw `id property '${id}'`;
    await firebase.authStatePromise;
    await firebase.contacts().child("telephones").child(id).remove();
    console.log(id)
  } catch (err) {
    onError(store.dispatch, err)
  }
};

const toObject = (array = []) => array.length > 0 ? Object.assign(array) : null;
