import { merge, clone } from 'lodash';

// import deepmerge from 'deepmerge';
import Logger from './Logger';
import SipAccount from './SipAccounts';

import storage from './storage';

const logger = new Logger('settingsManager');

let MEDIA_CONSTRAITS = { audio: true, video: false };
const WSS_PORT = 7443;
const ICESERVERS = {
	iceServers: [
		{
			urls: [
				"stun:stun.gbc.fi:3478",
				"stun:stun.l.google.com:19302",
				"stun:stun1.l.google.com:19302"
			]
		}
	]
};

const MEDIA_OPTIONS = {
	mediaConstraints : MEDIA_CONSTRAITS,
	// rtcOfferConstraints : {
	// 	offerToReceiveAudio : 1,
	// 	offerToReceiveVideo : 1,
	// 	// offerToReceiveVideo : 1
	// },
	pcConfig: ICESERVERS
};

const DEFAULT_SETTINGS = {
	configuration: {
		...new SipAccount({ user_context: 'gbctest.vaihde.io', extension: '2434', password: 'b344rtr44cd' }, WSS_PORT).configuration,
		pcConfig: {
			rtcpMuxPolicy: 'negotiate',
			...ICESERVERS
		}
	},
	mediaOptions: MEDIA_OPTIONS,
}

let settings;

settings = storage.get(); // First, read settings from local storage

if (settings) logger.debug('settings found in local storage');

// Try to read settings from a global SETTINGS object
if (window.SETTINGS) {
	logger.debug('window.SETTINGS found');

	settings = merge(window.SETTINGS, settings || {});


	// settings = deepmerge(
	// 	window.SETTINGS,
	// 	settings || {},
	// 	{ arrayMerge: (destinationArray, sourceArray) => sourceArray });
}

// If not settings are found, clone default ones
if (!settings) {
	logger.debug('no settings found, using default ones');
	settings = clone(DEFAULT_SETTINGS);
}

export default {
	get: () => {
		return settings;
	},
	set: (newSettings) => {
		storage.set(newSettings);
		settings = newSettings;
	},
	clear: () => {
		storage.clear();
		settings = clone(DEFAULT_SETTINGS);
	},
	isReady: () => {
		return Boolean(settings?.configuration?.uri);
	},
	getDefaultDomain: () => {
		return DEFAULT_SETTINGS['registrar_server'];
	}
}
