import React, { createContext, useCallback, useReducer, useContext, useEffect, useState } from 'react';

import {
  Dialog,
} from '@mui/material';

import ErrorBoundary from 'components/ErrorBoundary';

const dialogContext = createContext();

const INITIALSTATE = {
  open: false,
  data: {},
  callback: null,
}

function reducer(state = INITIALSTATE, action) {
  switch (action.type) {
    case 'OPEN':
      return {
        ...Object.assign(
          state,
          {
            open: true,
            data: action.payload,
            callback: action.callback
          }
        )
      };
    case 'CLOSE':
      return {
        ...INITIALSTATE
      };
    default:
      return state
  }
}

export function useDialogProvider() {
  const [state, dispatch] = useReducer(reducer, {...INITIALSTATE});
  const { open, data } = state;
  const id = open ? 'simple-dialog' : undefined;

  const handleDialogClick = useCallback(
    (newState, callback) => {
      dispatch({ type: "OPEN", payload: newState, callback: callback })
    },
    [],
  );

  const handleClose = useCallback(
    (event) => {
      if(typeof state.callback === 'function' && !(event.nativeEvent instanceof Event)) state.callback(event);
      dispatch({ type: "CLOSE" });
    },
    [],
  );

  return {
    id,
    open,
    data,
    handleDialogClick,
    handleClose,
  }
}

export function useDialog() {
  return useContext(dialogContext);
}

export function DialogProvider({ children, DialogComponent, dialogProps, componentProps: { data, ...componentProps }}) {
  const { handleDialogClick, ...props} = useDialogProvider();
  const { id, open, handleClose } = props;

  return (
    <dialogContext.Provider value={[ handleDialogClick ]}>
      {children}
      <Dialog
        id={id}
        open={open}
        onClose={handleClose}
        {...dialogProps}
      >
        <ErrorBoundary>
          <DialogComponent {...props} dialogProps={dialogProps} {...componentProps} />
        </ErrorBoundary>
      </Dialog>
    </dialogContext.Provider>
  )
}

export const withDialog = (Component, { component, ...rest }) => (props) => {
  return (
    <DialogProvider DialogComponent={component} dialogProps={rest} componentProps={props}>
      <Component {...props} />
    </DialogProvider>
  )
}
