import React, {Suspense, useEffect, useState} from 'react';
import { connect } from 'react-redux';
//import { makeStyles, createStyles } from '@mui/styles';
//import styles from "assets/jss/components/missedCallsStyle";

import { users_Actions } from 'store/profile/actions';
import { handled_missed_calls_Actions, unhandled_missed_calls_Actions } from 'store/missedcalls/actions';

import ChartFilters from './Filters/ChartFilters';

import { AutocompleteWithCheckBoxBar, RangeSelectionBar, GridSelectionBar, ViewSelectionBar } from 'components/CustomToolBars';

import ChartGrid from 'components/Charts/MissedCalls';
import { SimpleLoader } from 'components/Loaders';

import { Box } from '@mui/material';

import ThreeMonthCharts from './GridCharts/ThreeMonthCharts';
import SixMonthCharts from './GridCharts/SixMonthCharts';
import Last30DaysCharts from './GridCharts/Last30DaysCharts';
import Last7DaysCharts from './GridCharts/Last7DaysCharts';
import YesterdayCharts from './GridCharts/YesterdayCharts';
import TodayCharts from './GridCharts/TodayCharts';

import { isLoading } from 'devtools/Helpers';

const MissedCallsCharts = ({ dispatch, isMobile, filters, ...props}) => {
  const { loading, handledCalls, unhandledCalls } = props

  if(loading) return <SimpleLoader p={2} />

  return (
    <div>
      <ChartFilters />
      <Box px={isMobile ? 0 : 5}>
        <ChartGrid filters={filters} data={{ handledCalls, unhandledCalls }} {...props} />
      </Box>
    </div>
  );

}

const mapStateToProps = state => ({
  filters: state.filters.missedcalls,
  handledCalls: state.missedcalls.handled,
  unhandledCalls: state.missedcalls.unhandled,
});

export default connect(
  mapStateToProps,
  null,
)(MissedCallsCharts);
