import React, { createContext, useReducer } from 'react';
const TabsContext = createContext();

const INITIALSTATE = 0;

function reducer(state = INITIALSTATE, newState) {
  return newState !== state ? newState : state;
}

// rest sessions setSessions
const withTabsContext = (Component) => (props) => {
  const [currentTab, setTab] = useReducer(reducer, INITIALSTATE);
  return (
    <TabsContext.Provider value={{ currentTab, setTab }}>
      <Component {...props} />
    </TabsContext.Provider>
  )
};

export {
  TabsContext as default,
  withTabsContext
}
