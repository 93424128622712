import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

// calllog item
// direction: "incoming"
// disposition: "Answered"
// duration: 7078
// end_time: "2022-09-23T13:06:18.275Z"
// id: "23"
// local_identity: {_uri: {…}, _parameters: {…}}
// remote_identity: {_uri: {…}, _parameters: {…}, _display_name: 'GBC HAILIKARI JANNE GBC NETWORKS OY'}
// start_time: "2022-09-23T13:06:11.197Z"
// time: "2022-09-23T13:06:18.281Z"
// uid: "51277565-10ec-40be-bd46-3fe5da2930e3"


export class ContactPopoverClass {
  constructor({ contact, caller_id_name = null, caller_id_number = null, ...props}) {
    return {
      hasContact: contact && !isEmpty(contact) ? true : false,
      caller_id_name: contact?.fullName || caller_id_name,
      caller_id_number,
      contact: contact || {
        id: "new",
        firstName: caller_id_name,
        telephones: [ new Contact_Telephone({ number: caller_id_number }) ],
      },
    }
  }

  #getAvatarProps(contact) {
    if(contact && !isEmpty(contact)) {
      return {
        name: null,
        src: null,
        color: null
      }
    } else {
      return null;
    }
  }
}


export class Contact_Telephone {
  constructor({ contact = "new", id = uuidv4(), number = "", type = "mobile"}) {
    return {
      contact: contact,
      id: id,
      number: number,
      type: type,
    }
  }
}
