import React from 'react';
import { Bar } from 'react-chartjs-2';

import Moment from "devtools/momentjs";
// const dataFilter = (data, filterBy, range) => {
//   if(!data && data.length < 1) return [];
//   return data.filter(item => (item[filterBy] && Moment.command(moment => moment(item[filterBy], "DD-MM-YYYY").isAfter(moment().subtract(...range)))));
// };

const toSeries = ({data, filterBy, indexFormat, range}) => {
  // [] aika dd [7, days]
  let labels = Moment.chartLabels(range, indexFormat);
  let series = new Array(labels.length).fill(0);

  const target = Moment.command(moment => moment().subtract(...range));

  data.filter(item => {
    if(item[filterBy] && Moment.command(moment => moment(item[filterBy], "DD-MM-YYYY").isAfter(target))) {
      // exist in dataset
      const inx = labels.indexOf(Moment.command(moment => moment(item[filterBy], "DD-MM-YYYY").format(indexFormat)));
      series[inx] += 1;
    }
  });

  return [{ labels: labels, series: [series]}, data];
}

const toSeriesNew = (data, labels, {filterBy, indexFormat, range}) => {
  console.log({data, filterBy, indexFormat, range})

  return [
    {
      labels: ['Janne Hailikari', 'February', 'March', 'April', 'May'],
      datasets: [
        {
          borderWidth: 2,
          data: [65, 59, 80, 81, 56]
        }
      ]
    },
    {
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            fontColor: '#fff'
          },
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            fontColor: '#fff'
          },
        }]
      }
    }
  ];
}

export const parseChartData = object => Promise.resolve(toSeries(object));
export const parseChartDataNew = object => Promise.resolve(toSeriesNew(object));

function doMath(val, multiplier) {
  return Math.ceil(val / multiplier) * multiplier;
}

export function getMax(value) {
  if (value <= 100) {
    return value === doMath(value, 10) ? value + 5 : doMath(value, 10)
  } else if (value < 1000) {
    return value === doMath(value, 50) ? value + 10 : doMath(value, 50)
  } else {
    return value === doMath(value, 100) ? value + 10 : doMath(value, 100)
  }
}

// function datasets({ handledCalls: handledCalls, unhandledCalls: unhandledCalls }, range) {
//
// }

// function getLabels(view, [first, second]) {
//   switch (view) {
//     case 'Hourly View':
//       return Moment.hoursAsLabels('HH');
//       break;
//     case 'Daily View':
//       return Moment.chartLabels([first, 'days'], 'MMM[,] DD dd');
//       break;
//     case 'Monthly View':
//       return Moment.chartLabels([first, 'months'], 'MMM');
//       break;
//     case 'Handled By':
//
//       break;
//     case 'All Charts':
//
//       break;
//   }
//
//   //labels = ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'];
// }

// const MISSEDCALLS_DATASET_CONFIGS = {
//   'Hourly View' : {
//      'Today': [
//        {
//          title: 'Todays Missed Calls',
//          dataFilter: (data) => data.filter(call => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isAfter( Moment.command(moment => moment().startOf('day') )))),
//          card: {
//            color: 'info'
//          }
//        },
//        {
//          title: 'Todays Unhanled Calls',
//          dataFilter: (data) => data.filter(call => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isAfter( Moment.command(moment => moment().startOf('day') )))),
//          card: {
//            color: 'success'
//          }
//        },
//        {
//          title: 'Todays Handled Calls',
//          dataFilter: (data) => data.filter(call => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isAfter( Moment.command(moment => moment().startOf('day') )))),
//          card: {
//            color: 'danger'
//          }
//        }
//      ],
//      'Yesterday': [
//        {
//          title: 'Yesterdays Missed Calls',
//          dataFilter: (data) => data.filter(call => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isAfter( Moment.command(moment => moment().startOf('day') )))),
//          card: {
//            color: 'info'
//          }
//        },
//
//        const unhandledDataset = unhandledCalls.filter(call => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isBetween( startOfDay, endOfDay )))
//        const handledDataset = handledCalls.filter(call => {
//          return Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isBetween( startOfDay, endOfDay )) && Moment.command(moment => moment(call?.handledtime, "DD-MM-YYYY HH:mm:ss").isBetween( startOfDay, endOfDay )) && true;
//        });
//
//        {
//          title: 'Yesterdays Unhanled Calls',
//          dataFilter: (data) => data.filter(call => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isAfter( Moment.command(moment => moment().startOf('day') )))),
//          card: {
//            color: 'success'
//          }
//        },
//        {
//          title: 'Yesterdays Handled Calls',
//          dataFilter: (data) => data.filter(call => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isAfter( Moment.command(moment => moment().startOf('day') )))),
//          card: {
//            color: 'danger'
//          }
//        }
//      ],
//      '7 Days': [
//        {
//          title: 'Todays Missed Calls',
//          dataFilter: (data) => data.filter(call => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isAfter( Moment.command(moment => moment().startOf('day') )))),
//          card: {
//            color: 'info'
//          }
//        },
//        {
//          title: 'Todays Unhanled Calls',
//          dataFilter: (data) => data.filter(call => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isAfter( Moment.command(moment => moment().startOf('day') )))),
//          card: {
//            color: 'success'
//          }
//        },
//        {
//          title: 'Todays Handled Calls',
//          dataFilter: (data) => data.filter(call => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isAfter( Moment.command(moment => moment().startOf('day') )))),
//          card: {
//            color: 'danger'
//          }
//        }
//      ],
//   }
//
//   1:
//   2:
//   3:
//   4:
//   5:
//   6:
//   7:
//   8:
// }



// function* generateMissedCallsDataset({ handledCalls, unhandledCalls }, searchOptions) {
//   {
//     dataFilter: (data) => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isAfter( Moment.command(moment => moment().startOf('day') )))),
//
//   }
//   const labels = getLabels(opts.view, range);
//   yield
//
//
// }


function filterCalls({ handledCalls, unhandledCalls }, datasets, opts) {
  const [duration, monthsdays] = JSON.parse(opts.range);

  if(opts.range === "[2,\"days\"]") {
    const startOfDay = Moment.command(moment => moment().subtract(1, 'days').startOf('day'));
    const endOfDay = Moment.command(moment => moment().subtract(1, 'days').endOf('day'));

    return {
      unhandledCalls:unhandledCalls.filter(call => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isBetween(startOfDay, endOfDay))),
      handledCalls: handledCalls.filter(call => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isBetween( startOfDay, endOfDay )) && Moment.command(moment => moment(call?.handledtime, "DD-MM-YYYY HH:mm:ss").isBetween( startOfDay, endOfDay )) && true),
    }
  } else {
    return {
      unhandledCalls:unhandledCalls.filter(call => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isAfter( Moment.command(moment => moment().subtract(duration, monthsdays).startOf('day') )))),
      handledCalls: handledCalls.filter(call => Moment.command(moment => moment(call?.handledtime, "DD-MM-YYYY HH:mm:ss").isAfter( Moment.command(moment => moment().subtract(duration, monthsdays).startOf('day') )))),
    }
  }


}




// function getDatasets(data, opts) {
//   const [first, second] = JSON.parse(opts.range);
//   const calls = filterCalls(data, opts);
//   // const iterator = generateMissedCallsDataset(data);
//   // let datasets = [];
//   //
//   // for (let value of [1,2,3]) { // search options
//   //   datasets.push(iterator.next(value).value);
//   // }
//   // return datasets; // [datasets, labels];
// }

export function* generateCharts(dataObj, opts) {
  console.log(opts)

  const datasets = opts.selectedDatasets.map(item => item.value); // [ "All Calls", "0934783699" ]
  let labels = [];
  const { unhandledCalls, handledCalls } = filterCalls(dataObj, datasets, opts);
  const charts = ['All Calls', 'Handled Calls', 'Unhandled Calls' ];

  switch (opts.view) {
    case 'Hourly View':
      labels = Moment.hoursAsLabels('HH');
      break;
    case 'Daily View':
      labels = Moment.chartLabels(JSON.parse(opts.range), 'MMM[,] DD dd');
      break;
    case 'Monthly View':
      labels = Moment.chartLabels(JSON.parse(opts.range), 'MMM');
      break;
    case 'Handled By':
      labels = []; // persons...
      break;
    case 'All Charts':
      labels = [];
      break;
  }


  for(const name of charts) {
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: name,
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    }

    yield new ChartData(chartData, { color: 'success' });
  }

  // const range = JSON.parse(opts.range);

  // // const labels = getLabels(opts.view, range);
  //const [datasets, labels] = getDatasets(dataArray, opts);
  //
  //
  // // <VerticalBarChart
  // //   footerText='All Calls'
  // //   customLabels={labelsHourly}
  // //   title='All Calls for Today Hourly'
  // //   color='info'
  // //   selectedDatasets={filters.selectedDatasets}
  // //   subLabels={{
  // //     x: 'Hour',
  // //     y: 'Count'
  // //   }}
  // //   data={[...unhandledCalls, ...handledCalls ].filter(call => Moment.command(moment => moment(call?.aika, "DD-MM-YYYY HH:mm:ss").isAfter( Moment.command(moment => moment().startOf('day') ))))}
  // //   loading={unhandledLoading === false && handledLoading === false ? false : true}
  // //   filterCallback={(item, dataset, increment) => {
  // //     if(item?.aika) {
  // //       const index = Moment.command(moment => moment(item.aika, "DD-MM-YYYY HH:mm:ss").format('HH'));
  // //       increment(index)
  // //     }
  // //     return;
  // //   }}
  // // />


}

class ChartData {
  constructor(data, cardOptions) {
    this.cardOptions = cardOptions;
    this.data = data;

    this.options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Chart.js Bar Chart'
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    this.component = <Bar data={this.data} options={this.options} />;
    return this;
  }
};
