import React, { memo } from 'react';
import { connect } from 'react-redux';
import { grey } from '@mui/material/colors';
import { uniqBy } from 'lodash';

import { InputAdornment } from '@mui/material';

import CallIcon from  '@mui/icons-material/Call';

import MuiTextField from "./MuiTextField";

function areEqual(prevProps, nextProps) {
  // return prevProps.loading !== nextProps.loading || prevProps.options !== nextProps.options
  return prevProps.options !== nextProps.options
}

const FormCallerIDSelect = ({ dispatch, property, loading, options: optionsProp, ...props}) => {
  let options = uniqBy(optionsProp, property).map(item => ({ title: item.description, secondary: item.cc_number, value: item.cc_number })) // remove duplicates and set format

  return (
    <MuiTextField
      select
      style={{ minWidth: 100 }}
      options={options}
      SelectProps={{
        renderValue: (value) => {
          return value // +358343434
        }, // without gives object
        MenuProps: {
          MenuListProps: {
            dense: true
          }
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CallIcon style={{ color: grey[500] }} />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}

FormCallerIDSelect.defaultProps = {
  property: 'cc_number',
  disableNone: true,
};

const mapStateToProps = state => ({
  loading: state.numbers.loading,
  options: state.numbers.cids
});

export default memo(
  connect(
    mapStateToProps,
    null
  )(FormCallerIDSelect),
  areEqual
)
