import React, { createContext, useCallback, useReducer, useContext, useEffect, useState } from 'react';

import {
  RequestedBrowserTransport,
  SignalType,
  CallControlFactory,
} from '@gnaudio/jabra-js';

import {
  useWebHID,
  usePhone,
  useSessions,
} from 'Providers';

// const INITIALSTATE = {
//   ignoreSignals: false,
//   hasCallLock: false,
//   callActive: false,
//   muted: false,
//   ringing: false,
//   lineBusy: false,
//   online: false,
//   hold: false,
// };
//
// function reducer(state, action) {
//   switch (action.type) {
//     case 'SET':
//       return {
//         ...state,
//         ...action.payload
//       };
//     default:
//       return state
//   }
// }

const jabraSignalsContext = createContext();

export function useJabraSignalsProvider() {
  let mounted = true, subscription;
  // const [state, dispatch] = useReducer(reducer, INITIALSTATE);

  const { sessions, activeSession, transferMode, setActiveSession, dispatch: dispatcher } = useSessions();
  const { hangup, answer, mute, unMute, hold, unHold  } = usePhone();

  const {
    callControl,
    webhidActions
  } = useWebHID(); // <--

  useEffect(() => {
    return () => {
      mounted = false;
    }
  }, []);

  useEffect(() => {
    if(!mounted) return;
    else if (callControl && !subscription) {
      subscription = callControl?.deviceSignals?.subscribe(handleIncomingSignals)
    }
    return () => {
      if(subscription) subscription?.unsubscribe();
    };
  }, [
    callControl,
    activeSession,
    sessions,
    transferMode
  ]);


  const handleIncomingSignals = (signal, device, value) => {
    // https://developer.jabra.com/site/global/sdk/javascript/api-reference/jabra-js_signaltype.gsp
    console.log(`signal Acknowledge incoming signal: ${signal.type} ${SignalType[signal.type]}`, signal.value, device);

    try {
      switch (SignalType[signal.type]) {
        case "HOOK_SWITCH": {
          console.log(`Acknowledge incoming signal: ${SignalType[signal.type]} (${signal.value}) (ANSWER/HANGUP)`);
          
          /**
            if emits signal value: false ingnore others
          **/

          /**
           * Update call state.
           * The HOOK_SWITCH value is "absolute" meaning it is either true or false reflecting the current device state.
          */

          if (signal.value && activeSession?.isInProgress() && activeSession.direction === 'incoming') {
            // ANSWER
            console.error('ANSWER');
            if (activeSession) answer(activeSession)(); // try answer
          } else if (!signal.value && !activeSession?.isEnded()) {
            // HANGUP
            console.error('HANGUP ACTIVECALL');
            if (activeSession) hangup(activeSession)(); // try hangup
          }

          //console.log(`Acknowledge incoming signal: ${SignalType[signal.type]}`, 'HOOK_SWITCH', signal.value);
          //dispatch({ type: "SET", payload: { callActive: signal.value, ringing: false }});
        }
        break;
        case "REJECT_CALL": {
          /**
  	       *  Stop the ringer
  	      */
          hangup(activeSession)(); // try hangup

          //console.log(`Acknowledge incoming signal: ${SignalType[signal.type]}`, "REJECT_CALL");
          //dispatch({ type: "SET", payload: { ringing: false }});
        }
        break;
        case "PHONE_MUTE": {
          /**
            Must use local state, signal value always true
          **/
          /**
  	       * The mute signal's value-type is relative meaning it should be treated as a toggle,
  	       * hence being opposite of current mute state
  	       */
  	      /**
  	       * Acknowledge the incoming signal by calling the equivalent command with
  	       * our updated state value
  	      */

          console.log(`PHONE_MUTE Acknowledge incoming signal: ${SignalType[signal.type]}`, signal.value, activeSession?.data?.muted);
          // if(activeSession?.data?.muted) {
          //   unMute(activeSession)();
          // } else {
          //   mute(activeSession)();
          // }

  	      //console.log(`Acknowledge incoming signal: ${SignalType[signal.type]}`, signal.value);
          //dispatch({ type: "SET", payload: { muted: signal.value }});
        }
        break;
        case "LINE_BUSY": {
          /**
    	     * SignalType.LINE_BUSY is a read-only value that signals if the device is in an active call
    	     */
          //console.log(`Acknowledge incoming signal: ${SignalType[signal.type]}`, 'PUHELU KÄYNNISSÄ', signal.value);
          //dispatch({ type: "SET", payload: { lineBusy: signal.value }});
        }
        break;
        case "ONLINE": {
          /**
    	     * SignalType.ONLINE is a read-only value that signals if a wireless adapter (Bluetooth or DECT) is transmitting
    	     * audio to a connected device
    	     */
          //console.log(`Acknowledge incoming signal: ${SignalType[signal.type]}`, 'HEADSET IN USE', signal.value);
          //dispatch({ type: "SET", payload: { online: signal.value }});
        }
        break;
        case "FLASH": {
          /*
            EMITS signal.value true always!
          */

          const { local, remote } = activeSession?.isOnHold();
          const isOnHold = local || remote;

          // Set hold if not set!
          if(isOnHold) {
            hold(activeSession)();
          } else {
            unHold(activeSession)();
          }

          // tämä on custom ja tässä tarvii köyttää stored value
  	      console.log(`Acknowledge incoming signal: ${SignalType[signal.type]}`, 'HOLD', isOnHold);
        }
        break;
        case "SEND": {
          console.log(`Acknowledge incoming signal: ${SignalType[signal.type]}`, "START NEW CALL");
        }
        break;
        default: {
          console.warn(`Ignore incoming signal: ${SignalType[signal.type]}`, "warn", '');
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return {};
}

export function useJabraSignals() {
  return useContext(jabraSignalsContext);
}

export function JabraSignalsProvider({ children }) {
  const state = useJabraSignalsProvider();

  return (
    <jabraSignalsContext.Provider value={state}>
      { children }
    </jabraSignalsContext.Provider>
  )
}

export const withJabraSignals = (Component) => (props) => {
  return (
    <JabraSignalsProvider>
      <Component {...props} />
    </JabraSignalsProvider>
  )
}
