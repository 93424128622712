import React from 'react';
import { Link, useNavigate } from "react-router-dom";

import { makeStyles, withStyles, createStyles } from '@mui/styles';
import { green, red } from '@mui/material/colors';
import cx from "classnames";

import { AvatarCustom } from 'components/CustomAvatar';
import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import {
  Badge,
  Tooltip,
  Avatar,
  AvatarGroup
} from '@mui/material';

const UsersAvatarGroupUser = ({ avatarSize, navlinks, max, spacing, users, ...props}) => {
  let navigate = useNavigate();
  
  const handleClick = (event, location) => {
    event.stopPropagation();
    if(location) navigate(location)
  }

  return (
    <AvatarGroup max={max} spacing={spacing}>
      {
        users.map((user, index) => {
          return (
            <HelperTooltipBootstrap key={index} style={{ borderRadius: '50%' }} title={user?.username || 'Username Missing'}>
              <Avatar
                onClick={(event) => handleClick(event, `./profiles/${user?.id}`)}
                { ...user?.image && { src: user?.image } }
                style={{
                  backgroundColor: user?.avatarColor || '#bdbdbd',
                  color: '#fff'
                }}
              >
                {user?.altName || user?.username?.substring(0, 1) || '?' }
              </Avatar>
            </HelperTooltipBootstrap>
          )
        })
      }
    </AvatarGroup>
  )
}

UsersAvatarGroupUser.defaultProps = {
  max: 5,
  navlinks: true,
  spacing: 12
};

export default UsersAvatarGroupUser;
