import React, { Suspense, useState } from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { grey } from '@mui/material/colors';

// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';

// react plugin for creating charts

import { Card, CardHeader, CardBody, CardFooter } from "components/Card";

import CustomChartJS from '../ChartJS';
// import { Bar, Line } from 'react-chartjs-2';

// import AccessTime from "@mui/icons-material/AccessTime";
// import Refresh from "@mui/icons-material/Refresh";
import Edit from "@mui/icons-material/Edit";
import FullscreenIcon from  '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from  '@mui/icons-material/FullscreenExit';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import { Fade, Grid, Box, IconButton, Fab } from '@mui/material';

import ErrorBoundary from 'components/ErrorBoundary';

import { CustomAvatarWithBadge } from 'components/CustomAvatar';

import ChartAdditionalInformation from '../ChartAdditionalInformation';

import styles from "assets/jss/views/dashboardStyle.js";

import { parseChartData } from 'devtools/chartHelper';

import { reduce, groupBy, pick } from 'lodash';

import { COLORS } from "constants/index.js";

const useStyles = makeStyles(createStyles(styles));

const enumColors = {
  primary: '#AB47BC',
  warning: '#CC851E',
  rose: '#E91E63',
  info: '#187E8B',
  success: '#407743',
  danger: '#B50E0B',
}

//const NewChart = ({ children, color, type, datasetFilter, footerText, title, customLabels, filterCallback, selectedDatasets, subLabels, loading, data: dataProp, ...props}) => {

//#e6e6e661

const LinearChart = (props) => {
  const classes = useStyles();
  const handle = useFullScreenHandle();
  const { id, data, color, datasets, labels, type, options, filters } = props;

  const [expanded, setExpanded] = useState(false);

  const handleChange = function (event) {
    setExpanded(!expanded);
  };

  return (
    <Fade in timeout={{ appear: 1300, enter: 1000, exit: 1000}}>
      <Card chart className={classes.cardHover}>
        <FullScreen handle={handle}>
          <CardHeader color={color} style={{ marginTop: handle.active ? 20 : -20 }}>
            {
              <Box width={1} display='flex' flexDirection='row' justifyContent="flex-start">
                <Box style={{ marginLeft: 'auto' }}>
                  {
                    handle.active
                      ? <HelperTooltipBootstrap placement='left' title="Exit Full Screen" aria-label="Exit Full Screen" arrow>
                          <Fab color="primary" size='small' onClick={handle.exit}>
                            <FullscreenExitIcon />
                          </Fab>
                        </HelperTooltipBootstrap>
                      : <HelperTooltipBootstrap placement='left' title="Enter Full Screen" aria-label="Enter Full Screen" arrow>
                          <Fab color="primary" size='small' onClick={handle.enter}>
                            <FullscreenIcon />
                          </Fab>
                        </HelperTooltipBootstrap>
                  }
                </Box>
              </Box>
            }

            <ErrorBoundary>
              <CustomChartJS
                id={id}
                type={type}
                data={{
                  labels: labels,
                  datasets: datasets
                }}
                options={options}
              />
            </ErrorBoundary>

          </CardHeader>
        </FullScreen>
        <ChartAdditionalInformation {...props} />
      </Card>
    </Fade>
  );
};

LinearChart.defaultProps = {
  color: 'success',
  type: 'bar',
  options: {},
}

export default LinearChart;
