import React, { forwardRef, useState, useEffect, useContext, useCallback } from 'react';
import { makeStyles } from '@mui/styles';

import { blueGrey } from '@mui/material/colors';

import { groupBy, sortBy } from 'lodash';

import {
  usePhone,
  useDrawers,
  useDropDown,
  withDropDown,
} from 'Providers';

import { useNavigate, useLocation } from "react-router-dom";

import { withColleaguesContext } from "Contexts/ColleaguesContext";

import cx from "classnames";

import {
  useFunctions,
  useSimpleState,
} from 'HOOKS';

import {
  Box,
  Grid,
  Divider,
  Stack,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  ListSubheader,
  ListItemAvatar,
  Avatar,
  Chip,
  Fab,
  Collapse,
  Menu,
  MenuList,
  MenuItem,
  Badge,
  ButtonGroup,
  CircularProgress,
  Button,
} from '@mui/material';

import { Card } from 'components/Card';
import RegularButton from "components/CustomButtons/Button.js";
import AvatarWithLineState from 'components/CustomAvatars';
import { LinkedBadge } from 'components/CustomBadge';

import BasicList from '../BasicList';

import PersonAdd from '@mui/icons-material/PersonAdd';
import Logout from '@mui/icons-material/Logout';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallIcon from '@mui/icons-material/Call';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import EmailIcon from '@mui/icons-material/Email';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
// import CallMissedIcon from '@mui/icons-material/CallMissed';


import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import { CustomAvatar, CustomIcon } from "components/CustomAvatars";

import RinggroupsListComponent from './RinggroupsListComponent';
import ExtensionsListComponent from './ExtensionsListComponent';

import ExpandIcons from './ExpandIcons';
import ErrorBoundary from '../ErrorBoundary';
import DrawerSection from '../DrawerSection';

import { DrawerListSectionDivider } from 'components/CustomDivider';

import useSingleAndDoubleClick from 'Contexts/useSingleAndDoubleClick';
import ColleaguesContext from 'Contexts/ColleaguesContext';
import MenuContext from 'Contexts/MenuContext';

import { ContactPopoverClass } from 'Classes';

import { ContactPopover } from '../Popovers';
import { FriendsOptionsComponent } from '../Lists';

import { service_colleagues } from 'Services';

import moment from 'Helpers/Momentjs';

const useStylesButtonGroup = makeStyles(theme => ({
  ButtonGroupDark: {
    "& button": {
      fontSize: '18px',
      color: '#e7e7e7',
      borderColor: '#000000 !important',
      backgroundColor: "#3a3a3a",
      '&:hover': {
        color: '#e7e7e7',
        backgroundColor: "#4c4c4c",
      }
    }
  }
}))

const useStyles = makeStyles(theme => ({
  colorWhite: {
    color: 'white',
  },
  success: {
    background: 'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))',
    boxShadow: 'rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.62',
  },
  danger: {
    boxShadow: '0 2px 2px 0 rgb(244 67 54 / 14%), 0 3px 1px -2px rgb(244 67 54 / 20%), 0 1px 5px 0 rgb(244 67 54 / 12%)',
    backgroundColor: '#f44336',
  },
  warning: {
    boxShadow: '0 2px 2px 0 rgb(255 152 0 / 14%), 0 3px 1px -2px rgb(255 152 0 / 20%), 0 1px 5px 0 rgb(255 152 0 / 12%)',
    backgroundColor: '#ff9800',
  },
  listItemAvailable: {
    backgroundColor: "#2bae2b5c"
  },
  listItemBusy: {
    backgroundColor: '#fd7d7d6e'
  },
  // ListItem
  listItem: {
  },
  listItemUnavailable: {
    background: 'linear-gradient(195deg, #878787, #646464)',
  },
  listItemBusy: {
    color: '#e7e7e7',
    background: 'linear-gradient(195deg, #ff6b60, #f44336)',
  },
  listItemVacation: {
    color: '#e7e7e7',
    background: 'linear-gradient(195deg, #da33f5, #9515b5)',
  },
  listItemRinging: {
    color: '#ffffff',
    background: 'linear-gradient(195deg, #f5d000, #ffb100)',
  },
  // Chip
  chip: {
    color: 'black',
    boxShadow: '0px 0px 2px 0px rgb(0 0 0 / 75%)',
  },
  chipAvailable: {
    color: '#e7e7e7',
    background: 'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))',
  },
  chipUnavailable: {
    color: '#e7e7e7',
    background: 'linear-gradient(195deg, #4c4c4c, #3a3a3a)',
  },
  chipVacation: {
    color: '#e7e7e7',
    background: 'linear-gradient(195deg, #da33f5, #9515b5)',
  },
  chipBusy: {
    color: '#e7e7e7',
    background: 'linear-gradient(195deg, #ff6b60, #f44336)',
  },
  chipRinging: {
    color: '#ffffff',
    background: 'linear-gradient(195deg, #f5d000, #ffb100)',
  },

}));

const ListItems = ({ data = [], text, open, handleClick, ...props}) => {
  let mounted = true;
  const classes = useStyles();
  const navigate = useNavigate();
  const { invite, transfer, hasActiveSession } = usePhone();
  const handleClicks = useSingleAndDoubleClick(onSingleClick, onDoubleClick);

  useEffect(() => {
    return () => mounted = false
  }, [])

  function onSingleClick(event, item, elementId) {
    if(!item || !mounted) return;
    event.stopPropagation();

    if(typeof handleClick === 'function') handleClick(item)(document.getElementById(elementId)); // opens dropdownmenu
  }

  function onDoubleClick(event, item, elementId, mediaConstaits = undefined) {
    if(!item || !mounted) return;
    event.stopPropagation();

    const { contact, caller_id_name, caller_id_number } = item;

    if(hasActiveSession()) {
      transfer(caller_id_number)(event);
    } else {
      invite(caller_id_number, contact, mediaConstaits) // make new call
    }
  }

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      {
        data.map((item, index) => {
          const { caller_id_name, caller_id_number, contact } = item;
          const { avatar, fullName, search_tags = [], status = "UNKNOWN" } = contact;

          const elementId = `ColleaguesComponent-${text}-ListItems-${index}`;

          const listItemClasses =
            classes.listItem +
            " " +
            cx({
              [classes.listItemBusy]: ["KOKOUS","VARATTU"].includes(status),
              [classes.listItemVacation]: ["LOMA"].includes(status),
              [classes.listItemRinging]: ["SOITTAA","RINGING"].includes(status),
              [classes.listItemUnavailable]: ["POISSA"].includes(status),
            });

          const chipClasses =
            classes.chip +
            " " +
            cx({
              [classes.chipAvailable]: ["PAIKALLA","IDLE"].includes(status),
              [classes.chipVacation]: ["LOMA"].includes(status),
              [classes.chipBusy]: ["KOKOUS","VARATTU"].includes(status),
              [classes.chipRinging]: ["SOITTAA","RINGING"].includes(status),
              [classes.chipUnavailable]: ["POISSA"].includes(status),
            });


          return (
            <ListItem
              id={elementId}
              key={index}
              dense
              divider
              disableGutters
              disablePadding
              className={listItemClasses}
              secondaryAction={
                <Chip
                  size='small'
                  className={chipClasses}
                  label={status}
                  sx={{
                    fontSize: "10px",
                    width: "60px",
                    marginRight: "20px"
                  }}
                />
              }
            >
                <ListItemButton style={{ width: '100%' }} onClick={event => handleClicks(event, item, elementId)}>
                  <ListItemAvatar style={{ minWidth: '44px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <IconButton aria-label="add" size="small" onClick={(e) => navigate(`/contacts/${contact.id}`, { state: contact })}>
                      <CustomAvatar name={caller_id_name} src={avatar?.image} bgcolor={avatar?.avatarColor} />
                    </IconButton>
                  </ListItemAvatar>
                  <ListItemText
                    primary={caller_id_name}
                    secondary={
                      caller_id_number
                        ? <b>{caller_id_number}</b>
                        : <Button
                            startIcon={<AddCircleIcon />}
                            size='small' sx={{ textTransform: 'none' }}
                            onClick={(event) => {
                              event.stopPropagation(); // blocks ListItemButton onClick!
                              navigate(`/contacts/${contact.id}?action=addNumber`, { state: contact })
                            }}
                          >
                          New Number
                        </Button>
                    }
                  />
                </ListItemButton>
            </ListItem>
          )
        })
      }
    </Collapse>
  )
}

const ColleaguesComponent = (props) => {
  const { data, isLoading } = props;
  const { handleClick } = useDropDown();
  const [open, setOpen] = useSimpleState({
    favorites: true,
    colleagues: true,
    ringgroups: true,
  });

  const { colleagues = [], favorites = [] } = data ?? {};

  if(isLoading) return (
    <Box p={2} display="flex" justifyContent='center' spacing={2} alignItems='center'>
      <CircularProgress size={20}/>
      <Box pl={1}>Loading...</Box>
    </Box>
  )
  return (
    <ErrorBoundary fallback={<div>Error Colleagues</div>}>
      <List
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          position: 'relative',
          // overflow: 'auto', // miksi oli tää
          // minHeight: '93vh', // miksi oli tää
          //maxHeight: '88vh',
          '& ul': { padding: 0 },
        }}
        dense
        disablePadding
      >
        <DrawerListSectionDivider
          onClick={setOpen({ type: 'set', payload: "colleagues" })}
        >
          Colleagues ({colleagues.length}) { colleagues.length > 0 && <ExpandIcons state={open.colleagues} /> }
        </DrawerListSectionDivider>
        <ListItems text="Colleagues" open={open.colleagues} data={colleagues} handleClick={handleClick} />
      </List>
    </ErrorBoundary>
  );
}

function areEqual(prev, next) {
  return JSON.stringify(prev.friends) !== JSON.stringify(next.friends);
}




const logicalComponent = (Component) => (props) => {
  let mounted = true, subscription, service;
  const [data, setData] = useState(null);


  useEffect(() => {
    if(!mounted) return;
    mountService();
    return () => {
      if(subscription && typeof service.remove_subscriber === 'function') service.remove_subscriber(subscription);
      mounted = false;
    }
  }, [])

  const mountService = useCallback(() => {
    service = service_colleagues();
    if(typeof service.add_subscriber !== 'function') return;
    subscription = service.add_subscriber(
  		value => {
  			if(mounted && value) setData(value)
  		}
		)
  }, [data]);

  return <Component {...props} data={data ?? []} isLoading={!data} />
}


export default withDropDown(React.memo(logicalComponent(ColleaguesComponent)), ContactPopover);
