import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';
// @mui/icons-material

// core components
import styles from "assets/jss/components/cardStyle.js";

const useStyles = makeStyles(createStyles(styles));

export default function TableRowCard(props) {
  const classes = useStyles();
  const {
    className,
    listStyle,
    children,
    plain,
    profile,
    blog,
    raised,
    selected,
    background,
    pricing,
    color,
    product,
    testimonial,
    chart,
    fullHeight,
    login,
    ...rest
  } = props;

  const cardClasses = classNames({
    [classes.cardTableRow]: true,
    [classes.cardListStyle]: listStyle,
    [classes.cardSelected]: selected,
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile || testimonial,
    [classes.cardBlog]: blog,
    [classes.cardRaised]: raised,
    [classes.cardBackground]: background,
    [classes.cardPricingColor]:
      (pricing && color !== undefined) || (pricing && background !== undefined),
    [classes[color]]: color,
    [classes.cardPricing]: pricing,
    [classes.cardProduct]: product,
    [classes.cardChart]: chart,
    [classes.cardLogin]: login,
    [classes.cardFullHeight]: fullHeight,
    [className]: className !== undefined
  });
  return (
    <tr className={cardClasses} style={{ display: 'table-row !important' }} {...rest}>
      {children}
    </tr>
  );
}

TableRowCard.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  blog: PropTypes.bool,
  raised: PropTypes.bool,
  background: PropTypes.bool,
  pricing: PropTypes.bool,
  testimonial: PropTypes.bool,
  product: PropTypes.bool,
  chart: PropTypes.bool,
  login: PropTypes.bool,
  children: PropTypes.node
};
