import React from "react";
import MuiTextField from "./MuiTextField";

const OPTIONS = Array.from({length: 24}, (_, i) => i + 1).map((item) => ({ value: `${item}`, title: `${item}` })) // [1 - 31]

const FormSelectHour = (props) => {
  return (
    <MuiTextField
      select
      label="Hour"
      style={{ minWidth: 100 }}
      options={OPTIONS}
      {...props}
    />
  )
}

export default FormSelectHour;
