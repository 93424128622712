import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@mui/styles';

import Paginations from 'components/Pagination/Paginations';

// import IconButton from '@mui/material/IconButton';
// import FirstPageIcon from  '@mui/icons-material/FirstPage';
// import KeyboardArrowLeft from  '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRight from  '@mui/icons-material/KeyboardArrowRight';
// import LastPageIcon from  '@mui/icons-material/LastPage';

const CustomTablePagination = (props) => {
  const { count, page, rowsPerPage, onPageChange, nextIconButtonProps, backIconButtonProps } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const handleClick = (event, prop) => {
    onPageChange(event, prop.text);
  };

  const numberOfPages = Math.ceil(count / rowsPerPage);

  const pages = Array.from(Array(numberOfPages).keys());

  return (
    <Paginations
      style={{ display: 'flex', marginLeft: '40px', marginRight: '40px' }}
      pages={[
        { text: "First", onClick: handleFirstPageButtonClick },
        ...pages.map(item => {
          return { text: `${item + 1}`, active: item === page, onClick: event => onPageChange(event, item)}
        }),
        { text: 'Last', onClick: handleLastPageButtonClick }
      ]}
      color="info"
    />
  );
}

CustomTablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default CustomTablePagination;
