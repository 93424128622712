import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import { TableRow, TableHead, TableCell, Checkbox, TableSortLabel } from '@mui/material';

import SearchIcon from  '@mui/icons-material/Search';

import { Card, CardBody } from 'components/Card';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          //backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  margin: {
    margin: theme.spacing(1),
  }
}));

const EnhancedTableHead = (props) => {
  const classes = useStyles();
  const { checkboxCollumn, onSelectAllClick, numSelected, rowCount, headCells, onRequestSort, options } = props;
  const { order, orderBy } = options;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const tableRows = [...headCells];
  // add checkbox TableCell for index...
  tableRows.splice(checkboxCollumn.index, 0, checkboxCollumn);

  return (
    <TableHead>
      <TableRow>
        {
          tableRows.map((headCell, index) => {
            if(index === checkboxCollumn.index) {
              return (
                <TableCell
                  key='checkboxCollumn'
                  align='center'
                  padding="checkbox"
                  style={{
                    display: checkboxCollumn.display ? 'table-cell' : 'none'
                  }}
                >
                  <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                    size={checkboxCollumn.size}
                  />
                </TableCell>
              )
            } else {
              return (
                <TableCell
                  key={headCell.id}
                  align={headCell.alignment}
                  padding={headCell.padding || 'none'}
                  style={headCell?.style || {}}
                  sortDirection={orderBy === headCell.id ? order : false}
                  style={{ display: headCell.display ? 'table-cell' : 'none' }}
                >
                  {
                    headCell.sort
                      ? <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {
                            // orderBy === headCell.id ? (
                            //   <span className={classes.visuallyHidden}>
                            //     {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            //   </span>
                            // ) : null
                          }
                        </TableSortLabel>
                      : headCell.label
                  }
                </TableCell>
              )
            }
          })
        }
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead;
