// redux format function methods
// function(value: T, name: T) => any
export const formatfilterByProp = function (items = [], method = 'filter', formatFunction, key, val) {
  // redux format function
  return function (value, name) {
    const item = items[method](obj => asFormat(obj[key], formatFunction) === asFormat(val || value, formatFunction)) // return single item or array
    if(!value || !item) return method === 'filter' ? [] : null;
    //console.log(item)
    return item
  }
}

// redux parse function methods
// function(value: T, name: T) => any
export const parseAsProperty = function (prop, formatFunction, defaultProp = null) {
  // redux parse function
  return function (value, name) {
    if(!value) {
      return defaultProp;
    } else if (Array.isArray(value)) {
      return value.map(item => item[prop || name]);
    } else if (typeof value === 'object') {
      return asFormat(value[prop], formatFunction);
    } else {
      return asFormat(value, formatFunction);
    }
  }
}


function asFormat(prop, format) {
  return !format ? prop : (format)(prop);
}

// AutoComplete getOptionSelected function methods!
// function(option: T, value: T) => boolean
export const getOptionSelected_ObjectMatch = function (option, value) {
  return JSON.stringify(option) === JSON.stringify(value)
}

export const getOptionSelected_PropertyMatch = (property) => (option, value) => {
  return option[property] === value;
}

export const getOptionSelected_ValueMatch = function (option, value) {
  return option === value;
}
