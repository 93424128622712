import {
  HANDLED_MISSEDCALLS_PENDING,
  HANDLED_MISSEDCALLS_SUCCESS,
  UNHANDLED_MISSEDCALLS_PENDING,
  UNHANDLED_MISSEDCALLS_SUCCESS,
} from 'store/types';

import * as moment from 'moment';
import { onError, enqueueSnackbar, showSnackbar, closeSnackbar } from 'store/Notifier/actions';
import { sortBy } from 'lodash';

export const handled_missed_calls_Actions = limit => {
  return async (dispatch, getState, firebase) => {
    dispatch({ type: HANDLED_MISSEDCALLS_PENDING });
    try {
      await firebase.authStatePromise;

      let ref = firebase.missedcalls().orderByChild("handled").equalTo(1);
      if(limit) ref = ref.limitToLast(Number(limit));

      await ref.on('value',
        snapshot => dispatch({ type: HANDLED_MISSEDCALLS_SUCCESS, payload: sortByTime(asCollection(snapshot.val() || []))}),
        err => {
          throw err;
        }
      );
    } catch (err) {
      onError(dispatch, err)
    }
  };
};

export const unhandled_missed_calls_Actions = limit => {
  return async (dispatch, getState, firebase) => {
    dispatch({ type: UNHANDLED_MISSEDCALLS_PENDING });
    try {
      await firebase.authStatePromise;

      let ref = firebase.missedcalls().orderByChild("handled").equalTo(0);
      if(limit) ref = ref.limitToLast(Number(limit));

      await ref.on('value',
        snapshot => dispatch({ type: UNHANDLED_MISSEDCALLS_SUCCESS, payload: sortByTime(asCollection(snapshot.val() || []))}),
        err => {
          throw err;
        }
      );
    } catch (err) {
      onError(dispatch, err)
    }
  };
};

export const missedCallAction = (datasets = [], props = {}) => {
  return async (dispatch, getState, firebase) => {
    let loadingKey;
    let newData = {};

    try {
      if(Array.isArray(datasets) && datasets.length <= 0) throw new Error('datasets property missing!');
      loadingKey = enqueueSnackbar(dispatch, 'LOADING')

      await firebase.authStatePromise;

      const username = await firebase.authUser.userdata.username;
      if(!username) throw new Error('cannot set username property missing!');

      for(let {id, ...dataset} of datasets) {
        newData[id] = Object.assign(dataset, {
          ...props,
          handledperson: username,
          handledtime: moment().format("DD-MM-YYYY HH:mm:ss")
        })
      }

      await firebase.missedcalls().update(newData);

      if(datasets.length > 1)
        enqueueSnackbar(dispatch, 'SUCCESS', loadingKey)
      else
        closeSnackbar(dispatch, loadingKey)
    } catch (err) {
      onError(dispatch, err, loadingKey);
    }
  };
};



const sortByTime = collection => {
  return sortBy(collection, function(dateObj) {
    return new Date(dateObj.timestamp);
  }).reverse();
}

const asCollection = object => {
  if(!object) return object;
  return Object.keys(object).map(
    key => ({
      ...object[key],
      uid: key,
    }),
  )
};
