import React from 'react';
import { Avatar } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { green, pink } from '@mui/material/colors';
import classNames from "classnames";

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  medium: {
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
  },
}));


const AvatarComponent = ({ children, ...props }) => {
  const classes = useStyles();
  const { color, size } = props;

  const avatarClasses = classNames({
    [classes[size]]: true,
    [classes[color]]: true,
  });

  return (
    <div className={classes.root}>
      <Avatar className={avatarClasses} {...props}>
        {children}
      </Avatar>
    </div>
  )
}

const AvatarCustom = ({ tooltip, tooltipProps, ...props}) => {
  return tooltip
    ? <HelperTooltipBootstrap {...tooltipProps}>
        <div>
          <AvatarComponent {...props} />
        </div>
      </HelperTooltipBootstrap>
    : <AvatarComponent {...props} />
}

AvatarCustom.defaultProps = {
  size: 'normal',
  tooltip: false,
  tooltipProps: {}
};

export default AvatarCustom;
