import React, { useState, useCallback, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';

import {
  Autocomplete,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField
} from '@mui/material';

import styles from "assets/jss/components/customInputStyle.js";

import { debounce, merge } from 'lodash';

const useStyles = makeStyles(createStyles(styles));

const CustomAutoComplete = ({ value: valueProp, ...props}) => {
  const [value, setValue] = useState(valueProp || '');
  const classes = useStyles();
  const {
    formControlProps,
    autoCompleteProps,
    id,
    customTextFieldProps,
    error,
    success,
    white,
    onClose,
    inputRootCustomClasses,
    errorText,
    successText,
    helperText,
    pattern
  } = props;

  useEffect(() => {
    if(value != valueProp) setValue(valueProp);
  }, [valueProp]);

  let staticTextFieldProps;

  if(staticTextFieldProps?.error) staticTextFieldProps.helperText = errorText;
  if(staticTextFieldProps?.success) staticTextFieldProps.helperText = successText;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error
  });

  //const handler = useCallback(debounce(value => onChange(value), 600), []);

  return (
    <FormControl {...formControlProps} >
      <Autocomplete
        id="combo-box-demo"
        {...autoCompleteProps}
        onInputChange={(event, newValue, reason) => setValue(newValue)}
        onClose={(event, reason) => onClose(value, reason)}
        renderInput={
          (params) => {
            const objects = merge(params, customTextFieldProps);
            return (
              <TextField
                label='solo'
                value={value}
                {...objects}
              />
            )
          }

        }
      />
    </FormControl>
  );
}

CustomAutoComplete.propTypes = {
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node
};

export default CustomAutoComplete;
