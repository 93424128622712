export function asCountryCode(str) {
  if(!str) return '';
  const [first, second, ...elements] = Array.from(str);
  const rest = elements.join('').replace(/\s/g, '')	// remove spaces!

  if(isNaN(Number(first))) {
  	return `${str}`;
  } else if(first.concat(second) == '00') {
  	return `+${rest}`;
  } else if (first === '0') {
  	return `+358${second}${rest}`;
  } else {
  	return `${str}`;
  }
};
