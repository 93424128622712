import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { orange, green } from '@mui/material/colors';

import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';

const OrangeThemeCheckBox = withStyles({
  root: {
    color: orange[400],
    '&$checked': {
      color: orange[600],
    },
  },
  checked: {},
})(Checkbox);

const GreenThemeCheckBox = withStyles({
  root: {
    color: green[600],
    '&$checked': {
      color: green[700],
    },
  },
  checked: {},
})(Checkbox);


const CustomCheckBox = ({ onChange, checked: checkedProp, customFormControlProps, ...props }) => {
  const [checked, setChecked] = useState(Boolean(checkedProp));

  const toggleChecked = (e) => {
    setChecked((prev) => !prev);
    onChange(e, e.currentTarget.checked);
  };

  return (
    <FormControlLabel
      style={{ margin: 0 }}
      control={<props.theme checked={checked} onChange={toggleChecked} {...props} />}
      {...customFormControlProps}
    />
  );
}


const OrangeCheckBox = (props) => {
  return (
    <CustomCheckBox theme={OrangeThemeCheckBox} {...props} />
  )
}

OrangeCheckBox.defaultProps = {
  checked: false,
};

const GreenCheckBox = (props) => {
  return (
    <CustomCheckBox theme={GreenThemeCheckBox} {...props} />
  )
}

GreenCheckBox.defaultProps = {
  checked: false,
};

export {
  GreenCheckBox,
  OrangeCheckBox,
  GreenThemeCheckBox,
  OrangeThemeCheckBox,
}
