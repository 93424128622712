import React, { memo } from 'react';
import { connect } from 'react-redux';
import { grey } from '@mui/material/colors';
import { uniqBy } from 'lodash';
import { Avatar, ListItemAvatar, TextField, ListItemText, InputAdornment, Autocomplete } from '@mui/material';

import CallIcon from  '@mui/icons-material/Call';

function areEqual(prevProps, nextProps) {
  return prevProps.loading !== nextProps.loading || prevProps.options !== nextProps.options
}


const FormCalleridAutoComplete = (
  { input: { onChange, onClose, name, value: valueProp, ...input}, label, meta: { touched, error }, customTextField, options, dispatch, property, ...custom },
) => {
  options = uniqBy(options, property); // remove duplicates

  // value needs to be (data) array element!
  const value = options.find(obj => {
    return obj[property] === valueProp; // +358934783610
  }) || null;

  const handleChange = (event, value, reason) => {
    if(property && value) {
      onChange(value[property])
    } else {
      onChange(value) // {cc_number: '+358917100894', description: 'Kutsu', destination_uuid: '92e8dbbc-d435-4a3c-8603-993830b3321a', number: '0917100894'}
    }
  }

  return (
    <Autocomplete
      onChange={handleChange}
      value={value}
      name={name}
      groupBy={(option) => option.category}
      options={[...options]}
      getOptionLabel={(option) => String(option?.[property] || option)}
      getOptionSelected={(option, value) => {
        //console.log(option, value)
        return option[property] === value
      }}
      renderInput={({ InputProps, ...params}) => {
        return (
          <TextField
            label={label}
            placeholder="-"
            {...params}
            {...customTextField}
            InputProps={{
              ...InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <CallIcon style={{ color: grey[500] }}/>
                </InputAdornment>
              ),
            }}
          />
        )
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <ListItemAvatar>
            <Avatar>
              <CallIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={option.description}
            secondary={option?.[property]}
          />
        </React.Fragment>
      )}
      {...custom}
    />
  );
}

FormCalleridAutoComplete.defaultProps = {
  property: 'cc_number',
};

const mapStateToProps = state => ({
  loading: state.numbers.loading,
  options: state.numbers.cids
});

export default memo(
  connect(
    mapStateToProps,
    null
  )(FormCalleridAutoComplete),
  areEqual
)

// import React, { memo } from 'react';
// import { connect } from 'react-redux';
// import { grey } from '@mui/material/colors';
// import { uniqBy } from 'lodash';
// import { Avatar, ListItemAvatar, TextField, ListItemText, InputAdornment } from '@mui/material';
//
// import CallIcon from  '@mui/icons-material/Call';
//
// function areEqual(prevProps, nextProps) {
//   return prevProps.loading !== nextProps.loading || prevProps.options !== nextProps.options
// }
//
//
// const FormCalleridAutoComplete = (
//   { input: { onChange, onClose, name, value, ...input}, label, meta: { touched, error }, customTextField, options, dispatch, property, ...custom },
// ) => {
//   options = uniqBy(options, property); // remove duplicates
//
//   return (
//     <Autocomplete
//       freeSolo={false}
//       onChange={(event, value, reason) => {
//         // onChange(event, value)
//         onChange(value)
//       }}
//       value={value}
//       //{...input}
//       name={name}
//       groupBy={(option) => option.category}
//       options={[...options]}
//       getOptionLabel={(option) => String(option?.[property] || option)}
//       getOptionSelected={(option, value) => {
//         //console.log(option, value)
//         return option[property] === value
//       }}
//       renderInput={({ InputProps, ...params}) => {
//         return (
//           <TextField
//             label={label}
//             placeholder="-"
//             {...params}
//             {...customTextField}
//             InputProps={{
//               ...InputProps,
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <CallIcon style={{ color: grey[500] }}/>
//                 </InputAdornment>
//               ),
//             }}
//           />
//         )
//       }}
//       renderOption={(option, { selected }) => (
//         <React.Fragment>
//           <ListItemAvatar>
//             <Avatar>
//               <CallIcon />
//             </Avatar>
//           </ListItemAvatar>
//           <ListItemText
//             primary={option.description}
//             secondary={option?.[property]}
//           />
//         </React.Fragment>
//       )}
//       {...custom}
//     />
//   );
// }
//
// FormCalleridAutoComplete.defaultProps = {
//   property: 'cc_number',
// };
//
// const mapStateToProps = state => ({
//   loading: state.numbers.loading,
//   options: state.numbers.cids
// });
//
// export default memo(
//   connect(
//     mapStateToProps,
//     null
//   )(FormCalleridAutoComplete),
//   areEqual
// )
