import React, { createContext, useCallback, useReducer, useContext, useEffect, useState } from 'react';

import { calllog_action } from 'WEBRTC/calllogManager';

import Logger from 'WEBRTC/Logger';
const logger = new Logger('settingsManager');

const sessionsContext = createContext();

var LOCALVIDEO, REMOTEVIDEO;
const INITIALSTATE = [];

function sessionsAreSame(session1, session2) {
	return session1?.id === session2?.id;
}

function getSessionIndex(session, sessions) {
	const index = sessions.findIndex((item) => sessionsAreSame(item, session));
	if(index === -1) console.error('index -1')
	return index;
};

function sessionRemoveOrReplace(session, sessions, newSession = undefined) {
	//console.log('sessionRemoveOrReplace')
	if(newSession) {
		sessions.splice(getSessionIndex(session, sessions), 1, newSession);
	} else {
		sessions.splice(getSessionIndex(session, sessions), 1);
	}
	return sessions;
}

function handleRemoteStream(stream, session) {
	logger.debug('handleRemoteStream() [stream:%o]', stream);

	if(!REMOTEVIDEO) REMOTEVIDEO = createElement(`remoteVideo-${session.id}`, session);
	REMOTEVIDEO.srcObject = stream; // attach remote stream
	checkRemoteVideo(stream, session);
}

function checkRemoteVideo(stream, session) {
	session.data.remoteHasVideo = true; // !!!!!!!!!!!
	// const videoTrack = stream.getVideoTracks()[0];
	// setState({ remoteHasVideo: Boolean(videoTrack) });
}

function createElement(id, session) {
	let element = document.createElement(session.data.mediaConstraints.video ? "video" : "audio");
	element.setAttribute("id", id);
	element.classList.add(id, 'hidden');
	element.setAttribute("autoPlay", true);

	switch (id) {
		case 'localVideo': {
			element.setAttribute("muted", true);
		}
		break;
		case 'remoteVideo': {

		}
		break;
	}

	document.body.appendChild(element);
	return element;
}

function destroyElement(id = null) {
	if(!id) return;
	const element = document.getElementById(id);
	if(element && element.parentNode) {
		element.parentNode.removeChild(element);
	}
}

function removeAudioStreams(id) {
	destroyElement(`localVideo-${id}`);
	destroyElement(`removeVideo-${id}`);
}


function connectAudioStreams(newSession) {
	if(newSession?.connection) {
		const peerconnection = newSession?.connection;
		const localStream = peerconnection.getLocalStreams()[0];
		const remoteStream = peerconnection.getRemoteStreams()[0];

		//console.warn('connectAudioStreams localStream', localStream, 'remoteStream', remoteStream)

		// Handle local stream
		if (localStream) {
			// Clone local stream
			//var _localClonedStream = localStream.clone();

			// Display local stream
			if(!LOCALVIDEO && newSession.data.mediaConstraints.video) {
				LOCALVIDEO = createElement(`localVideo-${newSession.id}`, newSession);
				// LOCALVIDEO.srcObject = _localClonedStream;
				LOCALVIDEO.srcObject = localStream;
				console.log(LOCALVIDEO.srcObject)
			}
		}

		// If incoming all we already have the remote stream
		if (remoteStream) {
			logger.debug('already have a remote stream');
			handleRemoteStream(remoteStream, newSession);
		}

		// listen to streams!!!
		peerconnection.addEventListener('addstream', (event) => {
			logger.debug('peerconnection "addstream" event');
			console.log('peerconnection "addstream" event');
			handleRemoteStream(event.stream, newSession);
		});
	}
}

function reducer(sessions = INITIALSTATE, { type, payload: newSession }) {
  let array = [];
  switch (type) {
    case 'ADD': {
		connectAudioStreams(newSession);
		array = [...sessions, newSession];
	}
	break;
	case 'UPDATE': {
		connectAudioStreams(newSession);
		sessions = sessionRemoveOrReplace(newSession, sessions, newSession);
		array = [...sessions];
	}
	break;
    case 'REMOVE': {
		removeAudioStreams(newSession.id);
		sessions = sessionRemoveOrReplace(newSession, sessions);
		calllog_action({ type: "CALLLOG_ADD", payload: newSession });
		array = [...sessions];
	}
	break;
	case 'REFRESH': {
		array = [...sessions];
	}
	break;
  }
	return array;
}

export function useSessionsProvider() {
  const [sessions, dispatch] = useReducer(reducer, [...INITIALSTATE]);
  const [transferMode, setTransferMode] = useState(null);
  const [activeSession, setActiveSession] = useState(null);

  return {
    sessions,
    dispatch,
	activeSession,
	setActiveSession,
	transferMode,
	setTransferMode
  }
}

export function useSessions() {
  return useContext(sessionsContext);
}

export function SessionsProvider({ children }) {
  const state = useSessionsProvider();

  return (
    <sessionsContext.Provider value={state}>
      { children }
    </sessionsContext.Provider>
  )
}

export const withSessions = (Component) => (props) => {
  return (
    <SessionsProvider>
      <Component {...props} />
    </SessionsProvider>
  )
}
