import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import ContactTelephonesList from './ContactTelephonesList';

import { Box, Chip, Collapse, Divider, FormControlLabel, ListSubheader } from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';

import KeyboardArrowUpIcon from  '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowLeftIcon from  '@mui/icons-material/KeyboardArrowLeft';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    //backgroundColor: theme.palette.background.paper,
  },
}));

const CustomList = ({ data, selectAll, selectedItems, header, listItemProps, content, ...props }) => {
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [checked, setChecked] = useState([]);
  const [open, setOpen] = useState([0]);

  const { staticContent, arrayContent } = content;

  useEffect(() => {
    if(data && Array.isArray(data) && items !== data) setItems(data)
  }, [data]);

  const handleClick = (value) => () => {
    const currentIndex = open.indexOf(value);
    const newValue = [...open];

    if (currentIndex === -1) {
      newValue.push(value);
    } else {
      newValue.splice(currentIndex, 1);
    }
    setOpen(newValue);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    selectedItems(newChecked); // callback
  };

  const handleToggleAll = (e) => {
    let array = [];
    if (e.target.checked) {
      array = [...items];
    }
    setChecked(array);
    selectedItems(array); // callback
  };

  const isChecked = (item) => {
    if(Array.isArray(item) && item.length > 0) {
      return item.length === items.length;
    } else {
      return checked.indexOf(item) !== -1;
    }
  }

  return (
    <List
      className={classes.root}
      aria-labelledby="nested-list-subheader"
      subheader={
        header && <ListSubheader component="div" id="nested-list-subheader" style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <Box flexGrow={1}>
            <Box height={30} width={1}>
              {header}
              {
                //<Chip label={items.length} size='small' />
              }

            </Box>
            {
              selectAll &&
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ padding: '0px 9px' }}
                      checked={isChecked(checked)}
                      color="primary"
                      onClick={handleToggleAll}
                      size="small"
                    />
                  }
                  label={<small>{ isChecked(checked) ? 'Unselect' : 'Select' } All</small>}
                  value="end"
                  labelPlacement="end"
                />
            }
          </Box>

        </ListSubheader>
      }
    >
      <Divider />
      {
        staticContent && staticContent(items, isChecked(checked), handleToggleAll)
      }

      {
        arrayContent && [...items].map((item, index) => {
          const labelId = `checkbox-list-label-${index}`;
          const isOpen = open.includes(index);
          return (
            <React.Fragment key={index}>
              { arrayContent(item, index, isOpen, isChecked(item), labelId, handleToggle, handleClick) }
            </React.Fragment>
          )
        })
      }
    </List>
  );
}

CustomList.defaultProps = {
  data: [],
  listItemProps: {
    button: true,
    divider: true
  }
}

export * from './Styled';

export {
  ContactTelephonesList
}

export default CustomList;
