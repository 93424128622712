import React, { useState, useEffect, useCallback, useReducer } from 'react';

import { grey } from '@mui/material/colors';

import { groupBy, sortBy, isEqual, omit } from 'lodash';

import {
  usePhone,
} from 'Providers';

//import cx from "classnames";

import {
  Box,
  Grid,
  Divider,
  Stack,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  ListSubheader,
  ListItemAvatar,
  Avatar,
  Chip,
  Collapse,
  Button,
} from '@mui/material';

import PersonAdd from '@mui/icons-material/PersonAdd';
import Logout from '@mui/icons-material/Logout';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallIcon from '@mui/icons-material/Call';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import EmailIcon from '@mui/icons-material/Email';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import ExtensionIcon from '@mui/icons-material/Extension';


import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import { CustomAvatar, CustomIcon } from "components/CustomAvatars";

import ExpandIcons from './ExpandIcons';

import { FriendsListSectionDividerStyled } from 'components/CustomDivider';

import useSingleAndDoubleClick from 'Contexts/useSingleAndDoubleClick';

import { Service_extensions } from 'Services';

function filterExtensionItems(items) {
  return items.filter((item) => !item?.userid)
};

const ListComponent = ({ data = [], text, open, ...props}) => {
  let mounted = true;
  const { invite, transfer, hasActiveSession } = usePhone();
  const handleClicks = useSingleAndDoubleClick(onSingleClick, onDoubleClick);

  useEffect(() => {
    return () => mounted = false
  }, []);

  function onSingleClick(event, item, elementId) {
    // do nothing!
  }

  function onDoubleClick(event, caller_id_name, caller_id_number, mediaConstaits = undefined) {
    if(!mounted || !caller_id_number) return;

    if(hasActiveSession()) {
      transfer(caller_id_number)(event);
    } else {
      invite(caller_id_number, null, mediaConstaits) // make new call
    }
  }

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      {
        data.map((item, index) => {
          const {
            extension,
            description,
          } = item;

          const [caller_id_name, caller_id_number] = [`${description}`, `${extension}`]

          const elementId = `ColleaguesComponent-${text}-ListItems-${index}`;

          return (
            <ListItem
              id={elementId}
              key={index}
              dense
              divider
              disableGutters
              disablePadding
            >
              <ListItemButton style={{ width: '100%' }} onClick={event => handleClicks(event, caller_id_name, caller_id_number)}>
                <ListItemAvatar style={{ minWidth: '44px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <CustomAvatar bgcolor={grey[600]}>
                    <ExtensionIcon fontSize='inherit' />
                  </CustomAvatar>
                </ListItemAvatar>
                <ListItemText
                  primary={caller_id_name}
                  secondary={<b>{caller_id_number}</b>}
                />
              </ListItemButton>
          </ListItem>
          )
        })
      }
    </Collapse>
  )
}


const ExtensionsListComponent = (props) => {
  const { data: extensions, isLoading } = props;
  const [open, setOpen] = useState(true);
  const toggleOpen = () => setOpen(prev => !prev);

  return (
    <List
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        position: 'relative',
        // overflow: 'auto', // miksi oli tää
        // minHeight: '93vh', // miksi oli tää
        '& ul': { padding: 0 },
      }}
      dense
      disablePadding
    >
      <FriendsListSectionDividerStyled onClick={toggleOpen}>
        Extensions ({extensions.length}) { extensions.length > 0 && <ExpandIcons state={open} /> }
      </FriendsListSectionDividerStyled>
      <ListComponent text="Extensions" open={open} data={extensions} />
    </List>
  );
}

function areEqual(prev, next) {
  return !isEqual(omit(prev, ['data', 'isLoading']), omit(next, ['data', 'isLoading']))
}

const INITIALSTATE = {
  loading: true,
  data: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET':
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    default:
      return state
  }
}

const logicalComponent = (Component) => (props) => {
  let mounted = true, service, subscription;
  const [state, dispatch] = useReducer(reducer, INITIALSTATE);

  useEffect(() => {
    service = new Service_extensions(filterExtensionItems);
    doSubscribe();

    return () => {
      service?.remove_subscriber(subscription);
      mounted = false;
    }
  }, [])

  function doSubscribe() {
    if(!mounted || !service.add_subscriber) return;
    subscription = service.add_subscriber(
      value => {
        //console.warn(value)
        if(mounted && value) dispatch({ type: "SET", payload: value });
      }
    )
  }

  return <Component {...props} data={state.data ?? []} isLoading={state.loading} />
}


export default logicalComponent(React.memo(ExtensionsListComponent, areEqual));
