import React, { forwardRef } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Checkbox, TextField, Autocomplete } from '@mui/material';


import { Card, CardBody } from 'components/Card';

import CheckBoxOutlineBlankIcon from  '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from  '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 6px',
    display: 'flex',
    alignItems: 'center',
  }
}));

const AutocompleteWithCheckBox = forwardRef(({ data, inputRef, inputProps, value: valueProp, ...props},  ref) => {
  const classes = useStyles();

  return (
    <Card style={{ width: 'max-content', marginBottom: 0 }}>
      <CardBody style={{ padding: '8px 4px' }}>
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={data || []}
          getOptionLabel={(option) => option?.value || 'Label Missing'}
          getOptionSelected={(option, value) => {
            const boolean = option.value === value.value;
            return boolean
          }}
          disableCloseOnSelect
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.value}
            </>
          )}
          value={valueProp}
          renderInput={(params) => (
            <TextField {...inputRef} {...params} {...inputProps} />
          )}
          {...props} // value, onChange
        />
      </CardBody>
    </Card>
  );
});

export default AutocompleteWithCheckBox;

// value={value}
// onChange={(event, value, reason) => onChange(event, value, reason)}
