import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';
import Grid from "@mui/material/Grid";

const styles = {
  grid: {
    padding: "5px 15px 0px 15px"
  },
  list: {
    padding: "5px 15px 10px 15px !important"
  }
};

const useStyles = makeStyles(createStyles(styles));

export default function GridItem(props) {
  const classes = useStyles();
  const {
    children,
    className,
    list,
    ...rest
  } = props;

  const gridClass = classNames({
    [classes.grid]: list ? false : true,
    [classes.list]: list ? true : false,
    [className]: className !== undefined
  });


  return (
    <Grid item {...rest} className={gridClass}>
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
