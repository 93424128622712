import React, { Suspense, useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles, withStyles, createStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

import { Field, FieldArray, reduxForm, formValueSelector, change } from 'redux-form';
import { FormTextField, FormSpeakComponent } from 'components/Forms/Components';
import { required, minWavLength3 } from 'components/Forms/Validators';

import AudioSample, { FileSaver, filenameSplitter } from 'Helpers/Announcements';

import { DarkChip } from 'components/CustomChips';

import CloudUploadIcon from  '@mui/icons-material/CloudUpload';
import MusicNoteIcon from  '@mui/icons-material/MusicNote';
import CancelIcon from  '@mui/icons-material/Cancel';
import AddIcon from  '@mui/icons-material/Add';
import SettingsIcon from  '@mui/icons-material/Settings';
import ExpandMoreIcon from  '@mui/icons-material/ExpandMore';
import GetAppIcon from  '@mui/icons-material/GetApp';

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { CustomTextField } from 'components/CustomInput';
import { UploadCard, SimpleDropZone } from 'components/Announcements';

import { Card, CardHeader, CardBody, CardFooter } from 'components/Card';

import { Grid, Checkbox, Tab, Tabs, Box, Chip, FormControlLabel, Switch, CircularProgress, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle, ListItemText, MenuItem, List, InputAdornment, IconButton, Button, Divider, ListSubheader, ListItemIcon, ListItemAvatar, Avatar, ListItemSecondaryAction } from '@mui/material';
import { CardActions, CardActionArea, CardMedia, CardContent, Typography } from '@mui/material';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import RegularButton from "components/CustomButtons/Button";
import { SimpleLoader } from 'components/Loaders';
import { AudioControls } from 'components/Custom';

import SimpleTTS from './SimpleTTS';
import AdvancedTTS from './AdvancedTTS';

import { parse2ObjectAsync, parse2XMLSyncronous } from 'devtools/xml2js';

import { setFormValues } from 'store/form/actions';

const FORM = 'createAnnouncementForm'

const TTSform = ({ dispatch, sample, advancedMode, getFormValues, ...props }) => {
  const { handleSubmit, submit, pristine, dirty, inValid, reset, previous, submitting, change, form, initialValues = null } = props;
  let mounted = true;

  useEffect(() => {
    (async() => {
      if(mounted) await dispatch(setFormValues("createAnnouncementForm", { ...sample, text: Boolean(sample?.text) ? await parse2ObjectAsync(sample?.ssml) : sample?.text }))
    })();
    return () => {
      mounted = false;
    }
  }, [sample])



  useEffect(() => {

    (async() => {
      if(dirty) {
        const { content, ssml, text } = getFormValues('createAnnouncementForm', ['content', 'text', 'ssml'])

        if(dirty && content === 'text-to-speech') {
          if(advancedMode) {
            if(mounted) change('ssml', parse2XMLSyncronous(text))
          } else {
            if(mounted) change('text', await parse2ObjectAsync(ssml))
          }
        }
      }

    })();
  }, [advancedMode])

  return initialValues &&
    <form onSubmit={handleSubmit}>
      <Card noMargin>
        <CardHeader>
          <Grid container>
            <Grid item xs={6} md={5}>
              <Field
                component={FormTextField}
                label="Filename"
                name="filename"
                validate={[required, minWavLength3]}
                format={(value, prop) => {
                  if(!value) return "";
                  return filenameSplitter(value).name;
                }}
                parse={(value, prop) => {
                  return  value && `${value}.wav` || ""
                }}
                variant="filled"
                size="small"
                fullWidth
                inputProps={{
                  type: 'search',
                  spellCheck: false
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"><DarkChip label='.wav' /></InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={6} md={7} style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
              {
                submitting
                  ? <SimpleLoader />
                  : sample?.file && sample?.file.size &&
                      <Box display='flex'>
                        <AudioControls audio={sample?.file} filesize={sample?.file?.size} noContentText={false} />
                        <HelperTooltipBootstrap title={`Download ${sample.filename}`} placement='bottom'>
                          <IconButton onClick={(e) => new FileSaver(sample.file, sample.filename)}>
                            <GetAppIcon />
                          </IconButton>
                        </HelperTooltipBootstrap>
                      </Box>
              }
            </Grid>
          </Grid>
        </CardHeader>
        <Divider />
        <CardBody>
          <Grid item xs={12}>
            {
              advancedMode
                ? <AdvancedTTS {...props} />
                : <SimpleTTS {...props} />
            }
          </Grid>
        </CardBody>
        <Divider />
        <CardFooter>
          <Grid item container justifyContent="flex-end" spacing={1}>
            <Grid item >
              <RegularButton
                id='apply'
                type="submit"
                size='sm'
                color="github"
                round
                disabled={inValid || submitting}
              >
                <Box pr={1} display={submitting ? 'block' : 'none' }>
                  <i className={"fas fa-spinner fa-spin"} />
                </Box>

                {
                  {
                    'upload': 'Save',
                    'text-to-speech': sample?.file ? "Apply Changes" : "Create Audio Sample"
                  }[sample?.content]
                }

              </RegularButton>

            </Grid>
            <Grid item>
              {
                dirty
                ? <RegularButton
                    id='reset'
                    type="button"
                    size='sm'
                    color='warning'
                    round
                    onClick={reset}
                  >
                    Undo Changes
                  </RegularButton>
                : <RegularButton
                    id='reset'
                    type="button"
                    size='sm'
                    color='info'
                    round
                    disabled={submitting}
                    onClick={previous}
                  >
                    Previous Sample
                  </RegularButton>

              }
            </Grid>
          </Grid>
        </CardFooter>
      </Card>
    </form>
}

const mapStateToProps = state => {
  return {
    initialValues: state.formState[FORM],
    getFormValues: (form, values) => {
      const selector = formValueSelector(form)
      return selector(state, ...values)
    }
  }
};

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: FORM,
    enableReinitialize: true,
    destroyOnUnmount: true,
  })(TTSform)
);
