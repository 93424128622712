import React, { useContext, createContext, useState, useEffect, useReducer } from "react";
import { firebase, store } from "index.js";
import { showSnackbar } from 'store/Notifier/actions';
import { newLineState } from 'store/actions';

import { isEmpty } from 'lodash';

import VolumeOffIcon from '@mui/icons-material/VolumeOff';

import IceCandidates from './IceCandidates';

import JsSIP from 'jssip';

import { v4 as uuidv4 } from 'uuid';

import _package from '../../../package.json';

import { asCountryCode } from 'Helpers/Calls';
import { getFirebasePath } from 'Helpers/Firebase';

import audioPlayer from 'WEBRTC/audioPlayer';
import { createDesktopNotifycation, closeDesktopNotifycation, notifyer, WINDOW_HAS_HAD_FOCUS } from 'WEBRTC/desktopNotifycations';

import localStorage from 'localStorage';

import {
	useWebHID,
	useJabraSignal,
	useSessions,
	useAudioProtection
} from 'Providers';

import { concatString } from 'Helpers/Text';

// import { jabraCallControl, initializeHID } from 'HID/devices';

import Logger from 'WEBRTC/Logger';
const logger = new Logger('settingsManager');

let MEDIA_CONSTRAINTS = { audio: true, video: false };
let DOMAIN = null;
const WSS_PORT = 7443;

const DTMF_OPTIONS = {
	duration: 100,
	interToneGap: 500,
	transportType: "RFC2833"
}


const RTCPeerConnection = {
	iceServers: [
		{ 
			urls: [ 
				"stun:stun.vaihde.io:3478",
				"stun:stun.counterpath.net:3478",
				"stun:stun.l.google.com:19302",
			]
		},
		{ 
			urls: 'turn:stun.vaihde.io:3478', 
			username: 'gbc',
			credential: 'gbc' 
		}
	],
	iceTransportPolicy: 'relay', // all public relay
	iceCandidatePoolSize: 10,
}

//const peerConnection = new RTCPeerConnection(configuration);

// const ICESERVERS = [
// 	// {
// 	// 	urls: [
// 	// 		// "stun:stun.gbc.fi:3478", // saattaa palomuuri blokata ....
// 	// 		// "stun:stun.counterpath.net:3478",
// 	// 		// "stun:stun.l.google.com:19302",
// 	// 		// "stun:stun1.l.google.com:19302",

// 	// 		"stun:stun.vaihde.io:3478",
// 	// 		"stun:stun.counterpath.net:3478",
// 	// 		"stun:stun.l.google.com:19302",
// 	// 		"stun:stun1.l.google.com:19302",
// 	// 	],
// 	// },

// 	{
// 		urls: [
// 			"turn:stun.vaihde.io:3478",  // A TURN server
// 		],
// 		username: "gbc",
// 		credential: "gbc",
// 		credentialType: "password"
// 	}
// ]

const MEDIA_OPTIONS = {
	mediaConstraints : MEDIA_CONSTRAINTS,
	pcConfig: RTCPeerConnection
};

const SESSION_DEFAULTS = {
	localHasVideo  : false,
	remoteHasVideo : false,
	muted		 			 : false,
	localHold			 : false,
	remoteHold     : false,
	canHold        : false,
	ringing        : false,
	hasAnswer			 : false,
	hasStream			 : false,
	contact				 : null,
  state          : 'connecting',
	mediaConstraints: MEDIA_CONSTRAINTS,
}

export const phoneContext = createContext();

function sanitize(str) {
	return `${str}`.replace(/[^a-zA-Z0-9\\+]/g, ''); // allow only these
}

export function usePhone() {
  return useContext(phoneContext);
}

async function searchForContact(uri) {
  try {
    if(!uri) throw `Missing uri: '${uri}'`;
    uri = asCountryCode(uri);

    const contactsRef = firebase.contacts();

		// search remote
    const promise1 = contactsRef.child('telephones').orderByChild("number").equalTo(uri).once("value") // uri string???
			.then((snapshots) => {
				if(!snapshots.exists())
					throw 'snapshot does not exist!';
				else {
					let promise;
					snapshots.forEach((snapshot) => {
						const { contact } = snapshot.val() || {};
						if(!contact) throw '1No Contact Found!';
						console.warn("general", contact);
						promise = contactsRef.child('general').child(contact).once("value");
					});
					if(promise)
						return promise;
					else
						throw '2No Contact Found!';
				}
			})
      .then((snapshot) => {
				console.warn(snapshot.val());
				const data = snapshot.val() || {}
				const { firstName, lastName, fullName } = data;
				return {
					contact_uuid: snapshot.key,
					contact: { id: snapshot.key, ...data },
					display_name: fullName ? fullName : concatString([firstName, lastName])
				}
			})

		// search local
    //const promise2 = new Promise((resolve, reject) => setTimeout(reject, 5000, 'quick'));

    return await Promise.any([
			promise1
		])
      .catch((e) => console.warn(e)) // return value;
  } catch (err) {
    console.warn('searchForContact', err);
  }
}

class ReferContext {
	constructor(session, webhidActions, setTransferMode, dispatch, isAttended) {
		this.loadingKey = null;
		this.direction = session.direction; // might not exist later

		return {
			'requestSucceeded': async () => {
				console.log('requestSucceeded', webhidActions)
				store.dispatch(showSnackbar("CALL_TRANSFER_COMPLETE"), this.loadingKey)
				setTransferMode(null);

				if (webhidActions && !isAttended) {
					// this is needed for blind transfers
					if (this.direction === 'incoming') webhidActions?.stopRinger();
					webhidActions?.stopCall();
				}

				setTimeout(() => {
					// reset jabra state!
					dispatch({ type: 'REMOVE', payload: session });
				})
			},
			'requestFailed': () => {
				console.error('requestFailed')
				setTransferMode(null);
			},
			'trying': () => {
				console.log('trying')
				this.loadingKey = store.dispatch(showSnackbar("CALL_TRANSFER_INIT"))
			},
			'progress': () => {
				console.log('progress')
			},
			'accepted': () => {
				console.log("accepted")
			},
			'failed': () => {
				console.log('failed')
				setTransferMode(null);
			}
		}
	}
}

class SIPSession {
	constructor(session, dispatch, newState, updateLineState, callControl, webhidActions, store, lineIndex = 0) {
		// let direction = session.direction.toUpperCase();
		
		/*
		  this runs while takeCallLock is processing
		*/

		newState('activeSession', session);
		console.log(session)
		
		session.data = {
			...session.data,
			uid: uuidv4(),
			...SESSION_DEFAULTS
		}

		if(session.data?.contact_uuid) {
			console.log('WE COULD JUST GET CONTACT WITH UUID', session.data?.contact_uuid)
			console.log('WE COULD JUST GET CONTACT WITH UUID', session.data?.contact_uuid)
		}

		if(!session.data?.contact) {
			searchForContact(session.remote_identity.uri.user) // promise
	      .then((data) => {
					const { contact_uuid = null, contact = null, display_name = null } = data || {};
					console.log(data, data, data)
	        if(display_name) session.remote_identity.display_name = display_name;
					if(contact_uuid) session.data.contact_uuid = contact_uuid;
					if(contact) session.data.contact = contact;
					console.log(`Contact: '${display_name}' with contact_uuid: '${contact_uuid}'`)
	      })
				.then(() => dispatch({ type: 'REFRESH' })) // onko tää tarpeellinen!?
	      .catch((e) => console.warn("searchForContact", e))

		}


		// https://developer.mozilla.org/en-US/docs/Web/API/Notification
		// https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/API/notifications

		if(session.direction === 'incoming') {
			updateLineState('RINGING');

			// RINGTONE
			if(callControl) {
				webhidActions.startRinger();
			} else {
				audioPlayer.play('ringing', { loop: true });
			}

			setTimeout(() => {
	      		createDesktopNotifycation(
	       			concatString([session.remote_identity.display_name !== 'UNKNOWN' ? session.remote_identity.display_name : null, session.remote_identity.uri.user]),
					{},
					(event) => {
					console.log('desktop notification onClick');
								session.answer({
									pcConfig: Object.assign(
										{
											rtcpMuxPolicy: 'negotiate',
											...RTCPeerConnection
										},
									)
								});
					}
				);
			}, 1000)
		}

		session
			// peerconnection
			// newInfo
			.on('connecting', (data) => {
				console.log('session "connecting" event [data:%o]', data);
				session.data.state = "connecting";
				// attach media here!

				if (session.direction === "outgoing" && lineIndex === 0) webhidActions.startCall();
 
				dispatch({ type: 'UPDATE', payload: session })
			})
			.on('progress', (data) => {
				console.log('session "progress" event [data:%o]', data);
				session.data.state = "Ringing";
				session.data.ringing = true; // outgoing & incoming
        		dispatch({ type: 'REFRESH' })
			})
			.on('confirmed', (data) => {
				if(callControl) {
				    if(session.direction === 'incoming') {
					   webhidActions.stopRinger(); // stop ringing
				    } 
					//webhidActions.startCall(); // call active
				}

				console.log('session "confirmed" event [data:%o]', data);
				session.data.state = "Answered";
			})
			.on("icecandidate", function ({candidate, ready}) {
				IceCandidates.validateCandidate(candidate, ready);
			})
			.on('muted', (data) => {
				console.log('muted', data)
				session.data.muted = true;
				session.data.state = "muted";
				dispatch({ type: 'REFRESH' })
	    	})
			.on('unmuted', (data) => {
					console.log('unmuted', data)
					session.data.muted = false;
					session.data.state = "Answered";
					dispatch({ type: 'REFRESH' })
			})
			.on('hold', (data) => {
				console.log('hold', data)
				switch (data.originator) {
					case 'local':
					session.data.localHold = true;
					newState('activeSession', null);
					break;
					case 'remote':
					session.data.remoteHold = true;
					break;
				}
				session.data.state = "hold";
			})
			.on('unhold', (data) => {
				console.log('unhold', data)

				switch (data.originator) {
					case 'local':
					session.data.localHold = false;
					newState('activeSession', session);
					break;
					case 'remote':
					session.data.remoteHold = false;
					break;
				}
				session.data.state = "Answered";
			})
			.on('newDTMF', (data) => {
				console.log('session "newDTMF" event [data:%o]', data);
				audioPlayer.play('dtmf');
			})
			.on('reinvite', (data) => {
				console.log('session "reinvite" event [data:%o]', data);
			})
			.on('update', (data) => {
				console.log('session "update" event [data:%o]', data);
			})
			.on('replaces', (data) => {
				console.log('session "replaces" event [data:%o]', data);
			})
			.on('refer', (data) => {
				console.log('session "refer" event [data:%o]', data);
			})
			.on('accepted', (data) => {
				console.log('session "accepted" event [data:%o]', data);

				session.data.state = "accepted";
				if(session.direction === 'incoming') {
					//this.props.setTab(1) // force contacts tab

					if(callControl) {
						webhidActions.stopRinger(); // stop ringing
						if (lineIndex === 0) webhidActions.startCall(); // call active 
					} else {
						audioPlayer.stop('ringing');
					}

					closeDesktopNotifycation();
				}

				session.data.ringing = false; // outgoing & incoming
				session.data.canHold = true; // outgoing & incoming
				session.data.hasAnswer = true; // outgoing & incoming

				//if(activeSession && !activeSession.isOnHold().local) handleHold(activeSession); // mikäs tää on

				dispatch({ type: 'REFRESH' }); // REFRESH reactjs sessions
				newState('activeSession', session); // set as activeSession

				if(typeof session.data.callbacks?.onAccepted === "function") session.data.callbacks?.onAccepted(data);
				updateLineState('VARATTU');
			})
			.on('failed', (data) => {
					// events: ended ??? not when canceled!?
					console.log('session "failed" event [data:%o]', data);
					session.data.state = "failed";
					session.data.ringing = false;
					if(session.direction === 'incoming') {
						if(callControl) {
							webhidActions.stopRinger();
						} else {
							audioPlayer.stop('ringing');
						}

						closeDesktopNotifycation();
					}

					if (callControl && (data.cause === JsSIP.C.causes.REJECTED || data.cause === JsSIP.C.causes.CANCELED)) {
						webhidActions.stopCall(); // hangup channel
					}

					try {
						if(typeof session.data.callbacks?.onFailed === "function") session.data.callbacks?.onFailed(data);
						updateLineState('PAIKALLA');

						
						// if (data.originator === 'remote') {
						// 	store.dispatch(showSnackbar('', { variant: "error", message: `${data.cause}` }))
						// }

						// if(data.cause === JsSIP.C.causes.REJECTED) {
						//   // ended also fired! callog from there!
						// } else if (data.cause === JsSIP.C.causes.CANCELED) {
						//   // calllog_action({ type: "CALLLOG_ADD", payload: session }); // inbound call ended add to calllog! CANCEL
						// }
					} catch (err) {
						console.log('err', err)
					} finally {
						dispatch({ type: 'REMOVE', payload: session })
						newState("activeSession", null);
					}
			})
			.on('ended', (data) => {
				console.log('session "ended" event [data:%o]', data, data.cause);
				session.data.state = "ended";
				session.data.ringing = false; // outgoing & incoming

				try {
					updateLineState('PAIKALLA');		
					console.log(session._referSubscribers)

					if (isEmpty(session._referSubscribers) && callControl) {
						console.error('ended ended ended ended, reset jabra state')
						if (session.direction === 'incoming') webhidActions.stopRinger(); // stop ringing
						webhidActions.stopCall(); // hangup channel
						//webhidActions.releaseCallLock(); // releaseCallLock // if more cahbnnels dont
					}					

					setTimeout(() => {
						dispatch({ type: 'REMOVE', payload: session })
						newState('activeSession', null);
					})

				} catch (err) {
					console.log('err', err)
				}
			});

		this.session = session;	
	}
}


// BEGIN
function useProvidePhone() {
	let mounted = true;
	// JSSIP PHONE!
	//console.time('phone')

	const state = useWebHID();
	const { isAudioEnabled, enableAudioIfBlocked } = useAudioProtection();

	const {
		callControl,
		webhidState,

		//jabraConfig

		//ACTIONS
		webhidActions,
	} = state; // IEasyCallControlBase

	const [status, setStatus] = useState(null);
  const [phone, setPhone] = useState(null);

	const {
		sessions,
		activeSession,
		transferMode,
		setTransferMode,
		setActiveSession,
		dispatch
	} = useSessions();
	const [doNotDisturb, setDoNotDisturb] = useState(() => localStorage.get('dnd'));

	document.title = status; // debugging

	useEffect(() => {
		// initial load
		return () => {
			mounted = false;
		}
	}, []);

	useEffect(() => {
		// mount listeners
		if(!phone) return;

		//console.error('RENDER')

		if(mounted) {
			//mount listeners with new state
			phone.on('connecting', connecting);
			phone.on('connected', connected);
			phone.on('disconnected', disconnected);
			phone.on('registered', registered);
			phone.on('unregistered', unregistered);
			phone.on('registrationFailed', registrationFailed);
			phone.on('newRTCSession', newRTCSession);
		}

		return () => {
			//unmount listeners for new state
			phone.off('connecting', connecting);
			phone.off('connected', connected);
			phone.off('disconnected', disconnected);
			phone.off('registered', registered);
			phone.off('unregistered', unregistered);
			phone.off('registrationFailed', registrationFailed);
			phone.off('newRTCSession', newRTCSession);
		}
	}, [
		// rerender listeners if any of these changes
		phone,
		doNotDisturb,
		sessions,
		callControl,
		state,
		isAudioEnabled
	]);

	// useEffect(() => {
	// 	// autorun
	// 	if (sessions.length === 0 && webhidActions) {
	// 	  webhidActions.stopCall();
	// 	  webhidActions.releaseCallLock();
	// 	}
	// }, [sessions]);
	

	function getSess() {
		return sessions;
	}

	function updateLineState(state = 'PAIKALLA') {
		if(mounted) window.dispatchEvent(new CustomEvent('update-line-state', { detail: state }));
	}

	function newState(action, newState) {
		if(!mounted) return;
		switch (action) {
			case 'status':
				setStatus(newState)
				break;
			case 'phone':
				setPhone(newState)
				break;
			case 'activeSession':
				setActiveSession(newState)
				break;
		}
	}


	// const getSessionIndex = (session, sessions) => {
	//   const index = sessions.findIndex((item) => item.id === session.id);
	//   if(index === -1) console.error('index -1')
	//   return index;
	//  };

	// const sessionsAreSame = (session1, session2) => session1.id === session2.id;
	//
	// const removeSessionIndex = (session) => sessions.splice(getSessionIndex(session, sessions), 1);

	function connecting () {
		logger.debug('UA "connecting" event');
		newState("status", 'Connecting');
	};

	function connected () {
		logger.debug('UA "connected" event');
		newState("status", 'Connected');
	};

	function disconnected () {
		logger.debug('UA "disconnected" event');
		newState("status", 'Disconnected');
	};

	function registered () {
		logger.debug('UA "registered" event');
		newState("status", 'Registered');
		setTimeout(closeDesktopNotifycation()); // mikäs tää on?
		//console.log("WINDOW_HAS_HAD_FOCUS", WINDOW_HAS_HAD_FOCUS)
		enableAudioIfBlocked();
	};

	function unregistered () {
		logger.debug('UA "unregistered" event');

		if (phone.isConnected())
			newState("status", 'Connected');
		else
			newState("status", 'Disconnected');
			if(!phone.isRegistered()) {
				notifyer(
					`Connect Unregistered`,
					{ body: 'Connect can not receive calls, please click to reregister!' },
					() => {
						// do register
						phone.stop()
						setTimeout(() => {
							phone.start();
							if(!phone.isRegistered()) phone.register();
						}, 500);
					}
				)
			}
	};

	function registrationFailed (data) {
		logger.debug('UA "registrationFailed" event');

		if (phone.isConnected())
			newState("status", 'Connected');
		else
			newState("status", 'Disconnected');

			// onNotify({
			//   level   : 'error',
			//   title   : 'Registration failed',
			//   message : data.cause
			// });
	};

	const newRTCSession = function (data) {
		logger.debug('UA "newRTCSession" event');
		console.log('UA "newRTCSession" event');

		console.log(data.request)

		if (data.originator === 'local') return;

		/*
			sessions.length lisätään vasta tän jälkeen!!!
			Object.keys(this._sessions).length näyttää heti oikein.
		*/

		//if(Object.keys(this._sessions).length > 1) {

		// BUG: session is add after!!!
		if(!isAudioEnabled) {
			// client audio not enabled and client has incoming invite! shit
			data.session.terminate({
				'status_code'   : 486,
				'reason_phrase' : 'Busy Here'
			});
		} else if (sessions.length > 0) { //
			 // if active session exist, busy on busy!.
			 logger.debug('Incoming call replied with 486 "Busy Here"');
			 data.session.terminate({
				 'status_code'   : 486,
				 'reason_phrase' : 'Busy Here'
			 });
		} else if (doNotDisturb) {
			// if active respond as Do Not Disturb!
			logger.debug('Do Not Disturb feature is active! call replied with 480 "Do Not Disturb"');
			data.session.terminate({
				'status_code'   : 480,
				'reason_phrase' : 'Do Not Disturb'
			});
		} else {
			setTimeout(async() => {
				await webhidActions.takeCallLock(); // try open calllock
				// add session to sessions
				dispatch({ type: 'ADD', payload: new SIPSession(data.session, dispatch, newState, updateLineState, callControl, webhidActions, store, sessions.length).session });
			})
		}
	};

	const dtmf = (session, dmtfKey = "1", options = DTMF_OPTIONS) => {
		try {
			if(!dmtfKey)
				throw 'dmtfKey missing!';
			else if(!session)
				throw 'session missing!';

			//session.sendDTMF(`${dmtfKey}`, options);
			//session.sendDTMF(`${dmtfKey}`);
			session.sendDTMF(`${dmtfKey}`, options);
			audioPlayer.play('dtmf');

		} catch (err) {
			console.warn('handleDTMF()', err);
		}
	}

	const answer = (session = activeSession) => event => {
		logger.debug('AnswerIncoming()', session);
		console.log('AnswerIncoming()', session);

		try {
			if(!mounted || !session) return;

			try {
				// close incoming call notifycation!
			  closeDesktopNotifycation();
			} catch (err) {
				console.warn(err);
			}

			const options = {
				pcConfig: Object.assign(
					{
						rtcpMuxPolicy: 'negotiate',
						...RTCPeerConnection
					},
					//this.props.settings.pcConfig || {}
				)
			}

			//console.log(JSON.stringify(options));

			session.answer(options);
			// call accepted
			// if(callControl) {
			// 	webhidActions.startCall();
			// 	webhidActions.stopRinger();
			// }
		} catch (err) {
			console.warn('handleAnswerIncoming()', err);
		}
	}

	const hangup = (session = activeSession, options = { status_code: 487 }) => event => {
		//alert(2);
		/*
			487 Request Terminated -- Request has terminated by bye or cancel
			486 Busy Here -- Callee is busy
		*/

		logger.debug('hangup()', session, options);
		try {
			if(!mounted || !session) return;

			try {
				// close incoming call notifycation!
			  closeDesktopNotifycation();
			} catch (err) {
				console.warn(err);
			}

			// if(session.data.hasAnswer) {
			// 	merge(options, { status_code: 487 };
			// }

			//session.direction === 'outgoing' && session.data.hasAnswer ? null : { status_code: 487 }
			session.terminate(options);
			// hangup accepted

			// if(callControl) {
			// 	webhidActions.stopCall();
			// 	webhidActions.stopRinger();
			// }

		} catch (err) {
			console.warn('hangup()', err);
		}
	}

	const mute = (session = activeSession, options = MEDIA_CONSTRAINTS) => (event) => {
	  logger.debug('Mute()');
	  try {
			if(!mounted || !session) return;
	  	session.mute(options);
			webhidActions.mute();
	  } catch (err) {
	  	console.warn('Mute()', err);
	  }
	}

	const unMute = (session = activeSession, options = MEDIA_CONSTRAINTS) => (event) => {
	  logger.debug('Unmute()');
	  try {
			if(!mounted || !session) return;
	  	session.unmute(options);
			webhidActions.unmute();
	  } catch (err) {
	  	console.warn('Unmute()', err);
	  }
	}

	const hold = (session = activeSession, options = { useUpdate: false }) => (event) => {
	  logger.debug('handleHold()');
	  try {
			if(!mounted || !session) return;
	    session.hold(options);

			if(callControl) {
				webhidActions.holdCall();
			}
	  } catch (err) {
	  	console.warn('handleHold()', err);
	  }
	}

	const unHold = (session = activeSession, options = { useUpdate: false }) => (event) => {
	  logger.debug('handleResume()');
	  try {
			if(!mounted || !session) return;
	  	session.unhold(options);

			if(callControl) {
				webhidActions.resumeCall();
			}
	  } catch (err) {
	  	console.warn('handleResume()', err);
	  }
	}

	const transfer = (target = null, options = {}) => event => {
		console.log('handleTransfer activeSession', activeSession, target, options)
		logger.debug('transferMode() [session:"%s"]', activeSession, target);

		const isBlindTransfer = typeof target === 'object' && target instanceof JsSIP.RTCSession;

		try {
			if(!mounted || !activeSession) return;
			if(!target)
				throw `missing property target`;
			else if(!activeSession)
				throw `missing propertty activeSession`;

			

			activeSession.refer(`${target}@${phone._configuration.realm}`, {
				eventHandlers: new ReferContext(activeSession, webhidActions, setTransferMode, dispatch, isBlindTransfer),
				replaces: isBlindTransfer ? target : null, // attended or blind
				...options,
			})

			// send refering flag
			setTransferMode('Blind')
		} catch (err) {
			console.warn('transferMode()', err);
		}
	};

	// might not be inuse!
	const blindTransfer = (target = null, options = {}) => event => {
		console.log('handleTransfer activeSession', activeSession, target, options)
		logger.debug('blindTransfer() [session:"%s"]', activeSession, target);

		try {
			if(!mounted || !activeSession) return;
			if(!target)
				throw `missing property target`;
			else if(!activeSession)
				throw `missing propertty activeSession`;

			activeSession.refer(`${target}@${phone._configuration.realm}`, {
				eventHandlers: new ReferContext(activeSession, webhidActions, setTransferMode, dispatch, false),
				...options,
			})

			// send refering flag
			setTransferMode("Blind")
		} catch (err) {
			console.warn('blindTransfer()', err);
		}
	};

	// might not be inuse!
	const attendedTransfer = (session1, session2, options = {}) => event => {
		logger.debug('attendedTransfer() [session:"%s"]', session1);

		try {
			if(!session1)
				throw `missing property session1 ${session1}`;
			else if(!session2)
				throw `missing property session2 ${session2}`;

			session1.refer(session2.remote_identity.uri, {
				eventHandlers: new ReferContext(session1, webhidActions, setTransferMode, dispatch, true),
				replaces: session2, // attended
				...options,
			})

			// send refering flag
			setTransferMode('Attended');
		} catch (err) {
			console.warn('attendedTransfer()', err);
		}
	};

	function sessionIsOnHold(session) {
		const { remote, local } = session.isOnHold();
		return (remote || local); // boolean
	}

	function getSIPAddress(target, display_name) {
		return new JsSIP.NameAddrHeader(new JsSIP.URI("sip", target, DOMAIN), display_name);
	}


	const invite = (target, contact, mediaConstraints = MEDIA_CONSTRAINTS, callbacks = {}) =>  {
		//invite string undefined object

		logger.debug('handleOutgoingCall() [target:"%s"]', target);
		try {
			if(!target)
				throw `invite target: ${typeof target}`;
			else if (!phone || !phone.isRegistered())
				throw `invite failed phone state: ${phone?.isRegistered()}`;
			else if (sessions.length > 0 && !sessionIsOnHold(sessions[sessions.length - 1]))
				throw `invite blocked, session ringing already!`;


			target = sanitize(target);
			console.log("invite:", target, mediaConstraints)

			const options = {
				anonymous: false, // works can be used!
				pcConfig: Object.assign(
					{
						rtcpMuxPolicy: 'negotiate',
						...RTCPeerConnection
					},
				),
				//sessionTimersExpires: 900,
				mediaConstraints: mediaConstraints,
				rtcOfferConstraints: {
					offerToReceiveAudio : mediaConstraints?.audio ? 1 : 0, // important to match audio = true
					offerToReceiveVideo : mediaConstraints?.video ? 1 : 0, // important to match video = false
				}
			}

			// set active call to hold!
			if(sessions[0] && ! sessions[0].isOnHold().local) hold(sessions[0])();

			let s = phone.call(`${target}@${phone._configuration.realm}`, options);

			if(contact) {
				if(contact?.fullName) s.remote_identity.display_name = contact.fullName  // set remote target display_name
				s.data.contact = contact;
			}
			s.data.callbacks = callbacks;

			(async() => {
				if (sessions.length === 0) await webhidActions.takeCallLock(); // try open calllock
				dispatch({ type: 'ADD', payload: new SIPSession(s, dispatch, newState, updateLineState, callControl, webhidActions, store, sessions.length).session })
			})();

			updateLineState('SOITTAA');
			// add session on newRTCSession!!! old
		} catch (err) {
			console.warn('invite', err);
		}
	};

	const toggleDND = function (e) {
		localStorage.set('dnd', !doNotDisturb);
		setDoNotDisturb(!doNotDisturb)
		return;
	}

	const doRegister = (account) => {
		// REGISTER
		//console.log("REGISTER", account.webrtc);
		console.log("REGISTER");
		try	{
			const { username, extension, domain, password, ws, ws_port = WSS_PORT } = account;
			DOMAIN = domain;
			let _phone = phone;

			let	socket = new JsSIP.WebSocketInterface(`${ws}:${ws_port}`);
			//socket.via_transport = "WSS";

			if(_phone === null) {
				// do first registration

				_phone = new JsSIP.UA({
					uri                 : `sip:${extension}@${domain}`,
					display_name        : `${username}`,
					password            : `${password}`,
					sockets             : [ socket ],
					session_timers      : false,
					contact_uri         : `sip:${extension}@${domain};transport=wss`,
					authorization_user  : null,
					instance_id         : null, //`uuid:${uuidv4()}`,
					register_expires		  : 900,
					no_answer_timeout	    : 60,
					registrar_server      : `sip:${domain}`,
					use_preloaded_route : true,
					user_agent: `Connect v${_package.version} (JsSIP v${JsSIP.version})`
				});




				// let registrator = phone.registrator();
				// // registrator.setExtraContactParams([
				// //   //'transport: wss',
				// //   'X-Foo: bar'
				// // ]);

				logger.debug('UA "started" event');
				_phone.start();

			} else {
				// Currently just authorization_user, password, realm, ha1, authorization_jwt and display_name can be modified.
				_phone.set('display_name', `${extension}`);
				_phone.set('password', `${password}`);

				logger.debug('UA "REGISTER" event');
				_phone.register();
			}

			setPhone(_phone);

		} catch (error) {
			console.log(error)
			// this.onNotify({
			//   level   : 'error',
			//   title   : 'Wrong JsSIP.UA settings',
			//   message : error.message
			// });
		}
	};

	function getSess() {
		return sessions;
	}

	//console.timeEnd('phone')

  return {
		status,
		doNotDisturb,
    	phone,
		sessions,
		activeSession,
		// phone method
		doRegister,
		doUnregister: (options = { all: true}) => {
			try {
				phone.unregister(options);
			} catch (e) {
				console.warn("doUnregister", e)
			}
		},
		invite,
		// Helper functions
		hasActiveSession: () => {
			return Boolean(activeSession);
		},
		//sessionCleanup,
		// session method
		sip_uri_constructor: function (value, display_name) {
		  return new JsSIP.NameAddrHeader(new JsSIP.URI("sip", value, DOMAIN), display_name)
		},
		dtmf,
		transfer,
		blindTransfer,
		attendedTransfer,
		answer,
		hold,
		unHold,
		mute,
		unMute,
		hangup,
		toggleDND,
  }
}


export const withPhoneProvider = (Component)  => (props) => {
	const phone = useProvidePhone();

	return (
		<phoneContext.Provider value={{...phone }}>
			<phoneContext.Consumer>
				{
					// phone => (<Component {...props} {...phone} />)
					({doRegister}) => (<Component {...props} doRegister={doRegister} />)
				}
			</phoneContext.Consumer>
		</phoneContext.Provider>
	)
}

// COMPONENT PROVIDERS

export const PhoneStatusComponent = React.memo(({ children }) => {
	const { status } = usePhone();
  return children(status)
});

export const DialerComponent =  React.memo(({ children }) => {
	const { invite } = usePhone();
  return children(invite)
});

export const SessionsComponent = React.memo(({ children }) => {
	const { sessions } = useSessions();
  return children(sessions)
});

// function sessionsAreEqual(prevProps, nextProps) {
//   return prevProps.sessions === nextProps.sessions;
// 	//return JSON.stringify(prevProps.sessions) === JSON.stringify(nextProps.sessions);
// }

// export const SessionsComponent = React.memo(({ children }) => {
// 	const { sessions } = useSessions();
//   return children(sessions)
// }, sessionsAreEqual);
