import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import JsSIP from 'jssip';
import Logger from '../Logger';
// import TransitionAppear from './TransitionAppear';

import { grey } from '@mui/material/colors';

import { v4 as uuidv4 } from 'uuid';

import CallEndIcon from '@mui/icons-material/CallEnd';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';


import TransferForm from './AutoComplete/TransferForm';
import TimerComponent from './TimerComponent';

import { CustomAvatar } from "components/CustomAvatars";

import {
	ActiveSessionButtons,
	RingingButtons
} from './ActionButtons';

import { Card, CardBody, CardFooter, CardHeader } from 'components/Card';

import {
	Fade,
	Grid,
	Avatar,
	Divider,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	ListItemSecondaryAction,
	Backdrop,
	CircularProgress,
	Box,
	Typography,
} from '@mui/material';

import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';


const logger = new Logger('Session');

export default class SessionActive extends React.Component {
	//static contextType = PhoneContext;

	constructor(props) {
		super(props);

		this.state = {
			localHasVideo  : false,
			remoteHasVideo : false,
			localHold      : false,
			remoteHold     : false,
			canHold        : false,
			ringing        : false,
			hasStream			 : false,
			localMuted     : false,
			expanded			 : false,
		};

		this.localVideo = React.createRef();
		this.remoteVideo = React.createRef();

		// Mounted flag
		this._mounted = false;
		// Local cloned stream
		this._localClonedStream = null;

		//this.componentFocus = utilizeFocus();
	}

	render() {
		const {
			session,
			sessionCount,
			transferMode,
		} = this.props;

		const {
			direction,
			data,
			start_time,
		} = session;

		const {
			contact,
			hasAnswer,
			ringing,
			remoteHold,
			localHold,
			localMuted,
			remoteHasVideo,
			state
		} = data;

		const backdropOpen = transferMode !== null && (transferMode === "Blind" && !(remoteHold || localHold));

		const {
			expanded
		} = this.state;

		let noRemoteVideo, sessionStatus;

		const cardColor = hasAnswer && !ringing && !(remoteHold || localHold) ? 'success' : 'warning';
		const display_name = session.remote_identity?._display_name;
		const uri = session.remote_identity?._uri;

		const remote_identity = {
			primary: <Typography variant="body2" noWrap>{display_name || uri.user}</Typography>,
			secondary: <Typography variant="body2" fontSize={10} noWrap>{!display_name ? null : uri.user}</Typography>
		}

		if (session && !hasAnswer)
			sessionStatus = 'Connecting ...';
		else if (ringing)
			sessionStatus = "Ringing ...";
		else if (localHold)
			sessionStatus = "Call On Hold (local)";
		else if (remoteHold)
			sessionStatus = "Call On Hold (remote)";
		else if (!remoteHasVideo)
			sessionStatus = "Call Answered (Audio)";


		return (
			<div>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={backdropOpen}
				>
					<Fade in style={{
						transitionDelay: `250ms`,
					}}>
						<Grid container alignItems='center' justifyContent='center' spacing={2}>
							<Grid item>
								<CircularProgress color="secondary" sx={{ display: "flex" }} />
							</Grid>
							<Grid item>
								<Typography variant="h6" gutterBottom>
									{transferMode} Transfer in progress
								</Typography>
							</Grid>
						</Grid>
					</Fade>
				</Backdrop>
				<Card color={cardColor} elevation={16} listMargin>
				  <CardBody style={{ padding: "8px" }}>
					<List dense disablePadding sx={{ width: '100%' }}>
						<ListItem
							dense
							disableGutters
							disablePadding
							secondaryAction={start_time && <TimerComponent start_time={start_time} />}
						>
							<ListItemAvatar>
								<CustomAvatar
									name={session.data?.contact?.fullName}
									src={session.data?.contact?.image}
									bgcolor={session.data?.contact?.avatarColor} sx={{ height: "40px", width: "40px" }}
									onClick={contact ? (event) => this.props.history.push({ pathname: `/contacts/${contact.id}`, state: contact }) : undefined}
								/>
							</ListItemAvatar>
							<ListItemText
								primary={<small>{display_name || uri.user}</small>}
								secondary={!display_name ? null : uri.user}
							/>
						</ListItem>
						<ListItem>
							<small>{state}</small>

							{
 							 !start_time && !hasAnswer
 								 ? <RingingButtons
 										 {...data}
 										 session={session}
 										 direction={direction}
 									 />
 								 : <ActiveSessionButtons
 										 {...data}
 										 session={session}
 									 />
 						 }
						</ListItem>
					</List>

					 {
						 // <Grid container wrap="nowrap"  spacing={2}>
							//  <Grid item>
							//  	 <CustomAvatar name={session.data?.contact?.fullName} src={session.data?.contact?.image} bgcolor={session.data?.contact?.avatarColor} sx={{ height: "40px", width: "40px" }} />
							//  </Grid>
							//  <Grid item xs>
							// 	 <Typography noWrap variant="subtitle2" gutterBottom component="div" style={{ margin: 0 }}>{display_name || uri.user}</Typography>
							// 	 <Typography noWrap variant="caption" display="block" gutterBottom style={{ margin: 0 }}>{!display_name ? null : uri.user}</Typography>
							//  </Grid>
							//  <Grid item container xs flexDirection='column' alignContent="flex-end" spacing={1}>
							// 	 <Grid item xs style={{ alignSelf: 'flex-end' }}>
							// 	 		{ start_time && <TimerComponent start_time={start_time} /> }
							// 	 </Grid>
							// 	 <Grid item xs>
							// 		 {
							// 			 !start_time && !hasAnswer
							// 				 ? <RingingButtons
							// 						 {...data}
							// 						 session={session}
							// 						 direction={direction}
							// 					 />
							// 				 : <ActiveSessionButtons
							// 						 {...data}
							// 						 session={session}
							// 					 />
							// 		 }
							// 	 </Grid>
							//  </Grid>
						 // </Grid>
					 }

				  </CardBody>
				  <Divider />
				  <CardFooter sx={{ padding: '6px', margin: 0 }}>
				 		<TransferForm session={session} />
				  </CardFooter>


					 {
						//  <div data-component='Session' style={{ display: 'none' }}>
		 				// 	<video
		 				// 		ref={this.localVideo}
		 				// 		className={classnames('local-video', { hidden: !this.state.localHasVideo })}
		 				// 		autoPlay
		 				// 		muted
		 				// 	/>
						//
		 				// 	<video
		 				// 		ref={this.remoteVideo}
		 				// 		className={classnames('remote-video', { hidden: noRemoteVideo })}
		 				// 		autoPlay
		 				// 	/>
						//
		 				// 	{
		 				// 		noRemoteVideo && (
		 				// 			<div className='no-remote-video-info'>
		 				// 				{noRemoteVideo}
		 				// 			</div>
		 				// 		)
		 				// 	}
		 				// </div>
					 }
			  </Card>
			</div>
		);

		/*
		return (
			<Grid item xs={12} style={{ paddingLeft: 0 }}>
				<Fade in>
					<div data-component='Session'>
						<video
							ref={this.localVideo}
							className={classnames('local-video', { hidden: !localHasVideo })}
							autoPlay
							muted
						/>

						<video
							ref={this.remoteVideo}
							className={classnames('remote-video', { hidden: noRemoteVideo })}
							autoPlay
						/>

						{
							noRemoteVideo && (
								<div className='no-remote-video-info'>
									{noRemoteVideo}
								</div>
							)
						}

						<Card noMargin color={cardColor}>
							<CardHeader color={cardColor} style={{
								backgroundColor: grey[600] + ' !important',
								padding: '0px 10px',
								fontSize: '10px',
								fontWeight: '800',
								lineHeight: '13px',
								top: '8px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between'
							 }}
							>
								<span className='message'>
									<Typography variant="button" display="inline-block" fontSize={12} gutterBottom>
						        {direction + " Call"}
						      </Typography>
									{ "  " }
									{sessionStatus}
								</span>
								{
									start_time &&
										<TimerComponent start_time={start_time} />
								}

							</CardHeader>
							<CardBody style={{ borderRadius: '6px' }} noPadding>
								<Grid container spacing={1} display='flex' justifyContent='flex-start' alignItems='center' style={{ width: '100%', margin: 0, padding: '6px '}} >
									<Grid container item xs={12} spacing={1}>
										<Grid item>
											<Avatar
												alt="Remy Sharp"
												src="/static/images/avatar/1.jpg"
												sx={{ width: 30, height: 30 }}
											/>
										</Grid>
										<Grid item>
											<Box>
												<Typography variant="body2" noWrap>{display_name || uri.user}</Typography>
												<Typography variant="body2" fontSize={10} noWrap>{!display_name ? null : uri.user}</Typography>
											</Box>
										</Grid>
									</Grid>
									{
										!start_time && !hasAnswer
											? <RingingButtons
													{...this.state}
													session={session}
													direction={direction}
													handleAnswerIncoming={handleAnswerIncoming}
													handleHangUpOrCancel={handleHangUpOrCancel}
												/>
											: <ActiveSessionButtons
													{...this.state}
													session={session}
													sessions={sessions}
													handleTransfer={handleTransfer}
													handleHangUpOrCancel={handleHangUpOrCancel}
													handleHold={handleHold}
													handleResume={handleResume}
												/>
									}
								</Grid>
							</CardBody>
						</Card>
					</div>
				</Fade>
			</Grid>
		);
		*/
	}

	componentDidMount() {
		console.log('componentDidMount()', JsSIP.Utils)
		logger.debug('componentDidMount()');

		this._mounted = true;

		const {
			session,
			sessionEnded
		} = this.props;


		// if(session?.connection) {
		// 	const peerconnection = session?.connection;
		// 	const localStream = peerconnection.getLocalStreams()[0];
		// 	const remoteStream = peerconnection.getRemoteStreams()[0];
		//
		// 	//console.log('localStream', localStream, 'remoteStream', remoteStream)
		//
		// 	// Handle local stream
		// 	if (localStream) {
		// 		// Clone local stream
		// 		this._localClonedStream = localStream.clone();
		//
		// 		// Display local stream
		// 		this.localVideo.current.srcObject = this._localClonedStream;
		//
		// 		setTimeout(() => {
		// 			if (!this._mounted) return;
		//
		// 			if (localStream.getVideoTracks()[0]) this.setState({ localHasVideo: true });
		// 		}, 1000);
		// 	}
		//
		// 	// If incoming all we already have the remote stream
		// 	if (remoteStream) {
		// 		logger.debug('already have a remote stream');
		// 		this._handleRemoteStream(remoteStream);
		// 	}
		//
		// 	// listen to streams!!!
		// 	peerconnection.addEventListener('addstream', (event) => {
		// 		logger.debug('peerconnection "addstream" event');
		//
		// 		if (!this._mounted) {
		// 			logger.error('_handleRemoteStream() | component not mounted');
		// 			return;
		// 		}
		//
		// 		this._handleRemoteStream(event.stream);
		// 	});
		// }


		if (session.isEstablished()) {
			setTimeout(() => {
				if (!this._mounted) return;
				this.setState({ canHold: true });
			});
		}

		session
			.on('progress', (data) => {
				if (!this._mounted) return;

				//logger.debug('session "progress" event [data:%o]', data);

				if (session.direction === 'outgoing') this.setState({ ringing: true });
			})
			.on('accepted', (data) => {
				if (!this._mounted) return;

				//logger.debug('session "accepted" event [data:%o]', data);

				if (session.direction === 'outgoing') {
					this.props.onNotify({
						level : 'success',
						title : 'Call answered'
					});
				}

				this.setState({ canHold: true, ringing: false })
			})
			.on('failed', (data) => {
				if (!this._mounted) return;

				//logger.debug('session "failed" event [data:%o]', data);

				if(data.cause === "Canceled" && data.originator === 'remote') {
					// incoming call was canceled!
					//sessionEnded(session);
				} else {
					this.props.onNotify({
						level   : 'error',
						title   : 'Call failed',
						message : `Cause: ${data.cause}`
					});
				}

				if (session.direction === 'outgoing') this.setState({ ringing: false });
			})
			.on('ended', (data) => {
				//console.log('session "ended" event [data:%o]', data, data.cause);
				if (!this._mounted) return;

				//logger.debug('session "ended" event [data:%o]', data);

				this.props.onNotify({
					level   : 'info',
					title   : 'Call ended',
					message : `Cause: ${data.cause}`
				});

				if (session.direction === 'outgoing') this.setState({ ringing: false });
			})
			.on('hold', (data) => {
				if (!this._mounted) return;

				const originator = data.originator;
				//logger.debug('session "hold" event [originator:%s]', originator);

				switch (originator) {
					case 'local':
						session.data.localHold = true;
						this.setState({ localHold: true });
						break;
					case 'remote':
						session.data.remoteHold = true;
						this.setState({ remoteHold: true });
						break;
				}
			})
			.on('unhold', (data) => {
				if (!this._mounted) return;

				const originator = data.originator;

				//logger.debug('session "unhold" event [originator:%s]', originator);

				switch (originator) {
					case 'local':
						session.data.remoteHold = false;
						this.setState({ localHold: false });
						break;
					case 'remote':
						session.data.remoteHold = false;
						this.setState({ remoteHold: false });
						break;
				}
			})
			.on('muted', (data) => {
				if (!this._mounted) return;
				this.setState({ localMuted: true });
			})
			.on('unmuted', (data) => {
				if (!this._mounted) return;
				this.setState({ localMuted: false });
			})
	}

	componentWillUnmount() {
		//console.log('componentWillUnmount()', JsSIP.Utils)
		//logger.debug('componentWillUnmount()');

		this._mounted = false;
		JsSIP.Utils.closeMediaStream(this._localClonedStream);
	}

	_handleRemoteStream(stream) {
		logger.debug('_handleRemoteStream() [stream:%o]', stream);

		this.remoteVideo.current.srcObject =  stream; // attach remote stream
		this._checkRemoteVideo(stream);
	}

	_checkRemoteVideo(stream) {
		if (!this._mounted) {
			logger.error('_checkRemoteVideo() | component not mounted');
			return;
		}
		// else {
		// 	logger.debug('_checkRemoteVideo() | has videoTrack');
		// }

		const videoTrack = stream.getVideoTracks()[0];
		this.setState({ remoteHasVideo: Boolean(videoTrack) });
	}

	// handleExpandClick(event) {
	// 	if(!this.state.expanded === true) this.componentFocus.setFocus();
	// 	this.setState({ expanded: !this.state.expanded });
	// }
}

// Session.propTypes =
// {
// 	session            : PropTypes.object.isRequired,
// 	onNotify           : PropTypes.func.isRequired,
// 	onHideNotification : PropTypes.func.isRequired
// };
