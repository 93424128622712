import React, { memo } from 'react';
import { connect } from 'react-redux';
import { grey } from '@mui/material/colors';

import {
  TextField,
  ListItemText,
  InputAdornment,
  Autocomplete
 } from '@mui/material';

import { isLoading } from 'devtools/Helpers';

import CallIcon from  '@mui/icons-material/Call';

function areEqual(prevProps, nextProps) {
  return prevProps.loading !== nextProps.loading || prevProps.options !== nextProps.options;
}

const CalleridAutoComplete = ({ options, dispatch, label, textFieldProps, ...props}) => {
  console.log(options)
  return (
    <Autocomplete
      groupBy={(option) => option.category}
      options={[...options]}
      getOptionLabel={(option) => option?.cc_number || String(option)}
      //renderInput={(params) => <TextField {...params} label={label} {...textFieldProps} />}
      renderInput={({ InputProps, ...params}) => {
        return (
          <TextField
            {...params}
            label={label}
            {...textFieldProps}
            InputProps={{
              ...InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <CallIcon style={{ color: grey[500] }}/>
                </InputAdornment>
              ),
            }}
          />
        )
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <ListItemText primary={option.description} secondary={option.cc_number} />
        </React.Fragment>
      )}
      {...props}
    />
  );
}

const mapStateToProps = state => ({
  loading: isLoading([state.numbers.loading, state.firebase.loading]),
  options: state.numbers.cids,
});

export default memo(
  connect(
    mapStateToProps,
    null
  )(CalleridAutoComplete),
  areEqual
);
