export const LOCAL_PHONENUMBER_REGEX = new RegExp('^0\\d{0,11}$');
export const PHONENUMBER_WITHOUT_CC_REGEX = new RegExp('^(?!0)\\d{6,11}$');
export const EMAIL_REGEX = new RegExp("^\\S+@\\S+\\.\\S{2,}$");
export const PASSWORD_REGEX = new RegExp("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])[\\w~@#$%^&*+=`|{}:;!.?\\\"()\\[\\]-]{6,25}$");
export const ISINTERGER_REGEX = new RegExp("^-?[0-9]+$");

export const CALLNUMBER_REGEX = new RegExp('^(\\+?)\\d+');



//export const PASSWORD_REGEX = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{6,}$");
//export const EMAIL_REGEX = new RegExp('^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');
// export const PASSWORD_REGEX = new RegExp('(?=^.{6,30}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$');
