import React, { useEffect } from 'react';

import { withScrollBar } from 'Providers';

import Drawer from '@mui/material/Drawer';

const drawerWidth = 300;

const DrawerPermanentRight = ({ children, scrollRef, ...props }) => {
  return (
    <Drawer
      PaperProps={{
        ref: scrollRef,
        elevation: 8,
        sx: {
          border: 'none',
        }
      }}
      sx={{
        border: 'none',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
        height: "100%",
      }}
			{...props}
    >
      { children }
    </Drawer>
  )
};

DrawerPermanentRight.defaultProps = {
  variant: "permanent",
  anchor: "right",
}


export default withScrollBar(DrawerPermanentRight);
