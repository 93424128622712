import React, { createContext, useEffect, useReducer } from 'react';

const MenuContext = createContext();

const INITIALSTATE = null;

function reducer(state = INITIALSTATE, action) {
  switch (action.type) {
    case 'OPEN': {
      return action.payload;
    }
    case 'CLOSE': {
      return null;
    }
    default:
      return state;
  }
}

// rest sessions setSessions
const withMenuContext = (Component) => (props) => {
  const [anchorEl, menuAction] = useReducer(reducer, INITIALSTATE);
  let mounted = true;

  return (
    <MenuContext.Provider value={{ anchorEl, menuAction }}>
      <Component {...props} />
    </MenuContext.Provider>
  )
};

export {
  MenuContext as default,
  withMenuContext
}
