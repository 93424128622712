import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import { MenuList, Popper, Grow, Paper, ClickAwayListener } from '@mui/material';

import NoMenuItem from './NoMenuItem';
import AnnouncementsMenuItems from './AnnouncementsMenuItems';
import RinggroupsMenuItems from './RinggroupsMenuItems';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  secondaryAction: {
    paddingRight: '80px'
  },
  primary: {
    fontSize: '12px'
  }
}));

const MenuPopperList = forwardRef((props, ref) => {
  const { onOpen, onChange, useNoneItem, announcements, ringgroups, customPopperProps, listItemProps, announcementProps, ringgroupProps, value: valueProp } = props;
  const classes = useStyles();

  const popperProps = Object.assign({
    placement: 'bottom-start',
    style: {
      zIndex: 100
    },
    modifiers: {
      preventOverflow: {
        enabled: true,
        boundariesElement: 'window',
      }
    }
  }, customPopperProps || {});

  const [selected, setSelected] = useState(valueProp || '');
  const [anchorEl, setAnchorEl] = useState(null);


  useEffect(() => {
    console.log(onOpen);
    setAnchorEl(onOpen?.currentTarget);
  }, [onOpen]);

  const handleMenuItemClick = (event, selectedValue) => {
    setSelected(selectedValue)
    onChange(selectedValue);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
      placement='bottom-end'
    >
      {({ style, TransitionProps }) => (
        <ClickAwayListener onClickAway={handleClose}>
          <Grow {...TransitionProps}>
            <Paper elevation={5}>
              <MenuList>
                { useNoneItem ? <NoMenuItem handleMenuItemClick={handleMenuItemClick} selected={selected} /> : null}
                { announcements ? <AnnouncementsMenuItems handleMenuItemClick={handleMenuItemClick} selected={selected} {...announcementProps} /> : null }
                { ringgroups ? <RinggroupsMenuItems handleMenuItemClick={handleMenuItemClick} selected={selected} {...ringgroupProps} /> : null }
              </MenuList>
            </Paper>
          </Grow>
        </ClickAwayListener>
      )}
    </Popper>
  );
});


export default MenuPopperList;
