import React from 'react';
import { grey } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import { TextField, InputAdornment } from '@mui/material';

const WhiteTextField = withStyles({
  root: {
    color: grey[300],
    '& div': {
      color: grey[300],
      '& svg': {
        color: grey[300],
      }
    },
    '& label': {
      color: grey[300],
    },
    '& label.Mui-focused': {
      color: grey[300],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: grey[300],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: grey[300],
      },
      '&:hover fieldset': {
        borderColor: grey[300],
      },
      '&.Mui-focused fieldset': {
        borderColor: grey[300],
      },
    },
  },
})(TextField);


const FormTextField = (
  { input: { onChange, value, onFocus, onBlur, ...input }, label, meta: { asyncValidating, touched, error }, helperText, themeColor, InputProps: inputProps, updateOnChange, ...custom },
) => {
  const ThemeComponent = {
    white: WhiteTextField,
    default: TextField,
  }[themeColor || 'default']

  if(inputProps?.endAdornment || asyncValidating) {
    inputProps.endAdornment = (
      <React.Fragment>
        {inputProps.endAdornment}
        <InputAdornment position="start" style={{ visibility: asyncValidating ? 'visible' : 'hidden' }}>
          <i className={"fas fa-spinner fa-spin"} />
        </InputAdornment>
      </React.Fragment>
    )
  }

  return (
    <ThemeComponent
      error={error && touched}
      helperText={ helperText ? helperText(touched, error) : touched && error ? error : null }
      label={label}
      {...custom}
      {...input}
      value={value}
      onFocus={updateOnChange ? null : onFocus}
      onBlur={updateOnChange ? null : onBlur} // forces update for forms after focus!
      onChange={(event) => {
        const newValue = event.target.value;
        if(!updateOnChange) {
          onChange(event);
        } else if (value != newValue && updateOnChange)  {
          onChange(event);
        }
      }}
      InputProps={inputProps || null}
    />
  )
}

FormTextField.defaultProps = {
  InputProps: {},
  updateOnChange: false,
}

export default FormTextField;
