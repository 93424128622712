import React, { useState, useEffect } from 'react';

import {
  Avatar
} from '@mui/material';

import { service_account } from 'Services/service_account';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function getCharacters(name) {
  try {
    return `${name?.split(' ')[0][0]}${name?.split(' ')?.[1]?.[0] || ""}`.toUpperCase()
  } catch (e) {
    return "?";
  }
}

function stringAvatar({ data = {}, name, src, bgcolor, height, width, fontSize, type }) {
  const { username = "", image = null } = data?.userdata || {};

  return {
    sx: {
      boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
      ...{
        'chip': {
          marginLeft: '5px',
          marginRight: '-6px',
          width: '24px',
          height: '24px',
          fontSize: '0.75rem',
        },
        'default': {
          bgcolor: bgcolor ?? stringToColor(username),
          fontSize,
          width,
          height,
        }
      }[type]
    },
    src: image,
    children: getCharacters(username),
  };
}

const ProfileAvatar = (props) => <Avatar {...stringAvatar(props)} />;

ProfileAvatar.defaultProps = {
  fontSize: "12px !important",
  width: 20,
  height: 20,
  type: 'default',
}

const logicalComponent = (Component) => (props) => {
  let mounted = true, subscription;
  const [data, setData] = useState(null);
  const { add_subscriber, remove_subscriber } = service_account();

  useEffect(() => {
    if(mounted) {
      subscription = add_subscriber(
        value => {
          if(mounted && value) {
            setData(value);
          }
        },
        err => console.log("ProfileAvatar", err)
      )
    }

    return () => mounted = false;
  }, []);

  return <Component {...props} data={data ?? {}} isLoading={!Array.isArray(data)} />
}

export default logicalComponent(ProfileAvatar);
