import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { MenuItem, Box, CircularProgress, Select } from '@mui/material';

import { dids_Actions } from 'store/dids/actions';

const CalleridMenu = ({ classes, numbers, getNumbers, loading, children, ...props}) => {
  useEffect(() => {
    getNumbers();
  }, []);

  const list = (numbers) => numbers.map((option, index) => (
    <MenuItem
      key={index}
      value={option.destination_number.replace(/^0/, '+358')}
      classes={{
        root: classes.selectMenuItem,
        selected: classes.selectMenuItemSelected
      }}
    >
      <div style={{ width: '100%', padding: '0px 20px' }}>
        <span>{option.description}</span>
        <span style={{ float: 'right' }}>{option.destination_number.replace(/^0/, '+358')}</span>
      </div>
    </MenuItem>
  ));

  return loading === false
        ? <Select
            classes={{
              select: classes.select
            }}
            {...props}
          >
            {children}
            {list(numbers)}
          </Select>
       : <Select value={''}>
          <MenuItem
            disabled
            classes={{
              root: classes.selectMenuItem
            }}
          >

            <Box display="flex" justifyContent="center" px={'auto'}>
              Loading...
              <Box display="flex" justifyContent="center" pl={3}>
                <CircularProgress color="secondary" size={20} />
              </Box>
            </Box>
          </MenuItem>
        </Select>
};

const mapStateToProps = state => ({
  numbers: state.dids_State.numbers,
  loading: state.dids_State.loading
});

const mapDispatchToProps = dispatch => ({
  getNumbers: () => dispatch(dids_Actions())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalleridMenu);
