import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Field, change, reduxForm, SubmissionError } from 'redux-form';

const FORM = `form-${uuidv4()}`

const FormComponent = reduxForm({
  form: FORM,
  enableReinitialize: true,
})(({ chirdren, formProps, ...props}) => {
  formProps(props); // pass props back!
  console.log(formProps)

  const { handleSubmit, pristine, dirty, reset, submitting, change, form } = props;

  return (
    <form onSubmit={handleSubmit}  autoComplete='off'>
      { chirdren }
    </form>
  )
})

const withForm = (Component, formProps) => ({ ...props }) => {
  return (
    <FormComponent {...formProps} onSubmit={() => { }}>
      <Component {...props } />
    </FormComponent>
  )
};


export default withForm;
