// import _ from 'lodash';


function getAudioContext() {
  AudioContext = window.AudioContext || window.webkitAudioContext;
  const audioContent = new AudioContext();
  return audioContent;
};

const getType = value => {
  if(!value) return;
  if(RegExp('\\.wav').test(value)) return 'Announcement';
  if(RegExp('^\\d{2,5} XML \\w{1,}\\.vaihde\\.io$').test(value)) return 'Ringgroup';
  return 'Unknown';
}

const getDestination = value => {
  if(!value) return;
  let obj = {};
  switch (getType(value)) {
    case 'Announcement':
      obj = { application: "lua", data: value };
      break;
    case 'Ringgroup':
      obj = { application: "transfer", data: value };
      break;
    default:
  }

  return obj;
}

const fetchDestination = object => {
  if(!object) return;
  switch (object?.application) {
    case 'lua':
      return object.data.split(' ')[1];
      break;
    case 'transfer':
      return object.data;
      break;
    default:
  }
}

const compareObjects = function(a, b) {
  for(let i in a) {
      if(typeof b[i] == 'undefined') {
          return Promise.resolve(false);
      }
      if(typeof b[i] == 'object') {
          if(!b[i].equals(a[i])) {
              return Promise.resolve(false);
          }
      }
      if(b[i] != a[i]) {
          return Promise.resolve(false);
      }
  }
  for(let i in b) {
      if(typeof a[i] == 'undefined') {
          return Promise.resolve(false);
      }
      if(typeof a[i] == 'object') {
          if(!compareObjects(a[i], b[i])) {
            return Promise.resolve(false);
          }
      }
      if(a[i] != b[i]) {
          return Promise.resolve(false);
      }
  }
  return Promise.resolve(true);
}

const deepEqual = function (object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }
  return true;

}

function blobToBase64 (blob) {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

function b64toBlob (b64Data, contentType = '', sliceSize = 512) {
  if(!b64Data) return null;
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

const isObject = object => object != null && typeof object === 'object';


function fileMetadata (str) {
  let arr = str.split('.');
  const extension = arr.pop();
  return {
    name: arr.join('.'),
    extension: '.'+extension
  };
}

function firebaseDataIsLoaded(array, key = false) {
  return array.length === array.filter(e1 => {
    return e1 === key;
  }).length;
}

function notNull(value, boolean) {
  if(boolean) {
    return String(value) === 'null' ? false : true;
  } else {
    return String(value) === 'null' ? null : value;
  }
}

export {
  getAudioContext,
  getType,
  getDestination,
  fetchDestination,
  compareObjects,
  deepEqual,
  b64toBlob,
  blobToBase64,
  fileMetadata,
  firebaseDataIsLoaded,
  notNull,
};
