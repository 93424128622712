import React, { createContext, useCallback, useReducer, useContext, useEffect, useState } from 'react';

import { Dialogs } from 'components/CustomDialogs';

import ErrorBoundary from 'components/ErrorBoundary';

const dialogsContext = createContext();

const INITIALSTATE = [
  {
    open: false,
    component: null,
    dialogProps: {},
    childrenProps: {},
  }
];

function reducer(state = INITIALSTATE, action) {
  switch (action.type) {
    case 'OPEN': {
      const index = state.findIndex((item) => item.open === false);
      state[index] = {
        ...Object.assign(
          state[index],
          {
            open: true,
            component: action.childrenProps?.component,
            dialogProps: action.dialogProps,
            childrenProps: action.childrenProps
          }
        )
      };
      return [...state];
    }
    case 'CLOSE': {
      const index = state.findLastIndex((item) => item.open === true);
      state[index] = {
        ...Object.assign(
          state[index],
          {
            open: false,
            component: null,
            dialogProps: {},
            childrenProps: {},
          }
        )
      };
      return [...state];
    }
    default:
      return state
  }
}

export function useMuiDialogsProvider() {
  let mounted = true;
  const [dialogs, dispatch] = useReducer(reducer, [...INITIALSTATE]);

  useEffect(() => {
    return () => mounted = false;
  }, []);

  const dialogAction = useCallback(
    (type = "CLOSE", dialogProps, childrenProps) => (event) => {
      if(!mounted) return;
      if(type === 'OPEN')
        dispatch({ type: type, dialogProps, childrenProps });
      else
        dispatch({ type: type });
    },
    [],
  );

  return {
    dialogs,
    dialogAction
  }
}

export function useMuiDialogs() {
  return useContext(dialogsContext);
}

export function MuiDialogsProvider({ children }) {
  const { dialogs, dialogAction } = useMuiDialogsProvider();
  // const { open, drawerAction, state, ...rest } = props;

  return (
    <dialogsContext.Provider value={{ dialogs, dialogAction }}>
      { children }
      <Dialogs dialogs={dialogs} dialogAction={dialogAction} />
    </dialogsContext.Provider>
  )
}

export const withMuiDialogs = (Component) => (props) => {
  return (
    <MuiDialogsProvider>
      <Component {...props} />
    </MuiDialogsProvider>
  )
}
