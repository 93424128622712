import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';

import { Form, Field,  reduxForm, SubmissionError, formValueSelector } from 'redux-form';

import RegularButton from 'components/CustomButtons';
import CustomTable from 'components/CustomTable';
import { OrangeSwitch, OrangeThemeSwitch } from 'components/Custom/CustomSwitch';
import { Card, CardHeader, CardBody, CardFooter } from 'components/Card';
import { CallForwardAutoComplete } from 'components/CallForward';

import { Chip, Button, Box, TableCell, TableRow, Checkbox, Tooltip, Grid, Collapse, Alert } from '@mui/material';

import { FormCallForwardAutoComplete, FormProfileExtensionsAutoComplete, FormSwitch } from 'components/Forms/Components';

import { setNulls } from 'devtools/Helpers';
import { updateDomainUserAction } from 'store/profile/actions';
import { setFormValues } from 'store/form/reducer';

const FORM = 'UserCallForwardingTable';

const useStyles = makeStyles({
  root: {
  },
  callForwardEnabled: {
    backgroundColor: '#ff8d0057',
    '&:hover': {
      backgroundColor: '#ff8d0057 !important'
    }
  },
});

const EMPTY_FORM_STATE = {
  extensions: [],
  forward_all_destination: null,
  forward_all_enabled: false,
  forward_busy_destination: null,
  forward_busy_enabled: false,
  forward_no_answer_destination: null,
  forward_no_answer_enabled: false,
  forward_user_not_registered_destination: null,
  forward_user_not_registered_enabled: false,
}

const UserCallForwardingTable = ({ dispatch, profile, initialValues, extensionsValue, extensions, ...props }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { handleSubmit, pristine, dirty, reset, submitting, change, form } = props;

  useEffect(() => {
    const callforward = Object.assign(EMPTY_FORM_STATE, setNulls(profile.callforward || {}, true));

    if(callforward.extensions.length > 0) {
      callforward.extensions = extensions.filter(extension => callforward.extensions.find(item => item === extension.id))
    }

    dispatch(setFormValues(FORM, callforward));
    setData([
      { name: 'Forward All', id:'forward_all', destination: callforward.forward_all_destination, enabled: callforward.forward_all_enabled || false },
      { name: 'Not Registered', id:'forward_user_not_registered', destination: callforward.forward_user_not_registered_destination, enabled: callforward.forward_user_not_registered_enabled || false },
      { name: 'Busy', id:'forward_busy', destination: callforward.forward_busy_destination, enabled: callforward.forward_busy_enabled || false },
      { name: 'No Answer', id:'forward_no_answer', destination: callforward.forward_no_answer_destination, enabled: callforward.forward_no_answer_enabled || false },
    ])
  }, [profile])

  async function submit(formvalues) {
    let values = {...formvalues};

    try {
      values.extensions = values.extensions.map(item => item.id);
      await dispatch(updateDomainUserAction(profile.id, { callforward: setNulls(values, false) }));
      return true;
    } catch (err) {
      throw new SubmissionError({
        submit: 'Submiting Error',
        _error: err,
      });
    } finally {
      //setTimeout(() => reset(), 500);
    }
  }

  const headCells = [
    { id: 'name', alignment: 'left', display: true, padding: 'normal', sort: false, label: 'Routing Condition' },
    { id: 'target', alignment: 'right', display: true, padding: 'normal', sort: false, label: 'Routing Destination' },
    { id: 'active', alignment: 'center', display: true, padding: 'normal', sort: false, label: 'Activated' },
  ];


  return <Form name={form} onSubmit={handleSubmit(submit)} autoComplete='off'>
      <Card>
        <CardHeader>
          <Field
            component={FormProfileExtensionsAutoComplete}
            data={profile.extensions}
            label="Selected Extensions"
            name='extensions'
            fullWidth
            customTextField={{
              placeholder: "",
              variant: "standard",
              size: "small",
              InputLabelProps:{
                shrink: true,
              }
            }}
          />

          {
            extensionsValue
              && <Collapse in={initialValues?.extensions?.length > extensionsValue?.length} timeout="auto" unmountOnExit>
                  <Box pt={3}>
                    <Alert
                      severity="warning"
                      action={
                        <Button color="inherit" size="small"
                          onClick={(e) => document.getElementById('UserExtensionsTable').scrollIntoView()}
                        >
                          SHOW
                        </Button>
                      }
                    >
                      <p>Removing an extension from selected <b>will not disable</b> any active callforwards!</p>
                      <small>After apply to <b>disable</b> callforward for removed extension do it manually in the extensions table below</small>
                    </Alert>
                  </Box>
                </Collapse>
          }
        </CardHeader>
        <CardBody noPadding>
          {
            data.length > 0
              && <CustomTable
                data={data}
                headCells={headCells}
                options={{
                  dense: false,
                  order: 'asc',
                  orderBy: '',
                  checkboxCollumn: {
                    index: 0,
                    display: true
                  },
                }}
                components={{
                  defaultRow: <Chip label="Missing Data!" />,
                  selectedToolbar: function(selected, clearSelected) {
                    return (
                      <Grid container justify="center" alignItems="center" direction="row" spacing={1}>
                        <Grid item>
                          {
                            <Tooltip title="Select Destination" placement='top' arrow>
                              <div>
                                <CallForwardAutoComplete
                                  label="Destination"
                                  onChange={(event, value, reason) => {
                                    for(let item of selected()) {
                                      change(`${item.id}_destination`, value);
                                    }
                                  }}
                                  fullWidth
                                  customTextField={{
                                    placeholder: "-",
                                    variant: "outlined",
                                    size: "small",
                                    style: { minWidth: 240 },
                                    InputLabelProps:{
                                      shrink: true,
                                    }
                                  }}
                                />
                              </div>
                            </Tooltip>
                          }
                        </Grid>

                        <Grid item>
                          <Tooltip title="Enable CallForwarding" placement='top' arrow>
                            <div>
                              <OrangeSwitch
                                checked={false}
                                onChange={(e, value) => {
                                  for(let item of selected()) {
                                    change(`${item.id}_enabled`, value)
                                  }
                                }}
                                name="toggleAllSwitch"
                              />
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    );
                  },
                  rowCells: function(row, index, rowClick, isRowSelected, labelId, disableRowSelectOnClick, collapse, toggleCollapse) {
                    return (
                      <TableRow
                        hover
                        onClick={(event) => rowClick(event, row)}
                        role="checkbox"
                        aria-checked={isRowSelected}
                        tabIndex={-1}
                        key={labelId}
                        classes={{ root: row.enabled ? classes.callForwardEnabled : classes.root }}
                        selected={isRowSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isRowSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>

                        <TableCell padding='normal' align="left">
                          <Chip label={row.name} />
                        </TableCell>

                        <TableCell padding='normal' align="right" onClick={disableRowSelectOnClick}>
                          <Field
                            component={FormCallForwardAutoComplete}
                            label="Destination"
                            name={`${data[index].id}_destination`}
                            parse={(value, name) => value !== "" ? value : null}
                            fullWidth
                            customTextField={{
                              placeholder: "-",
                              variant: "outlined",
                              size: "small",
                              InputLabelProps:{
                                shrink: true,
                              }
                            }}
                          />
                        </TableCell>



                        <TableCell padding="checkbox" align="center" onClick={disableRowSelectOnClick}>
                          <Field
                            component={FormSwitch}
                            name={`${data[index].id}_enabled`}
                            useTheme={OrangeThemeSwitch}
                            formControlLabelProps={{
                              style: { margin: 0 }
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  }
                }}
              />
          }
        </CardBody>
        <Collapse in={dirty && !pristine} timeout="auto" unmountOnExit>
          <CardFooter>
            <Grid container justify="flex-end" spacing={1}>
              <Grid item>
                <RegularButton
                  type="submit"
                  size='sm'
                  color="success"
                  round
                  disabled={pristine || submitting}
                >
                  <Box pr={1} display={submitting ? 'block' : 'none' }>
                    <i className={"fas fa-spinner fa-spin"} />
                  </Box>
                  Apply
                </RegularButton>

              </Grid>
              <Grid item>
                <RegularButton
                  type="button"
                  size='sm'
                  color="warning"
                  round
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Undo
                </RegularButton>
              </Grid>
            </Grid>
          </CardFooter>
        </Collapse>
      </Card>
    </Form>
}

UserCallForwardingTable.defaultProps = {
  profile: {}
};


const selector = formValueSelector(FORM)

const mapStateToProps = state => ({
  extensions: state.extensions.extensions,  // pull initial values from form reducer
  initialValues: state.formState[FORM] || EMPTY_FORM_STATE,  // pull initial values from form reducer
  extensionsValue: selector(state, 'extensions'),
});

// const mapDispatchToProps = dispatch => ({
//   updateProfile: (id, data) => dispatch(updateDomainUserAction(id, data)),
//   setFormValues: (data) => dispatch(setFormValues(FORM, data)),
//   change,
// });

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: FORM,
    enableReinitialize: true,
    destroyOnUnmount: true,
    // onChange: (values) => console.log(values)
    // validate,
    // asyncValidate,
  })(UserCallForwardingTable)
)
