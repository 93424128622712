import React, { useState, useEffect, useCallback } from 'react';
//import { FullScreen, useFullScreenHandle } from "react-full-screen";

// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';

// react plugin for creating charts

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { Bar } from 'react-chartjs-2';

// import AccessTime from "@mui/icons-material/AccessTime";
// import Refresh from "@mui/icons-material/Refresh";
import Edit from "@mui/icons-material/Edit";
import FullscreenIcon from  '@mui/icons-material/Fullscreen';

import { grey } from '@mui/material/colors';

import Moment from "devtools/momentjs";

import { Fade, Box, Chip, IconButton } from '@mui/material';

import { CustomAvatarWithBadge } from 'components/CustomAvatar';

import styles from "assets/jss/views/dashboardStyle.js";

import { parseChartData } from 'devtools/chartHelper';

import { reduce, groupBy } from 'lodash';

import { COLORS } from "constants/index.js";

const useStyles = makeStyles(createStyles(styles));

const enumColors = {
  primary: '#AB47BC',
  warning: '#CC851E',
  rose: '#E91E63',
  info: '#187E8B',
  success: '#407743',
  danger: '#B50E0B',
}

const VerticalBarChart = ({ children, color, footerText, title, filterCallback, selectedDatasets, subLabels, loading, data: dataProp, filterProps, ...props}) => {
  //const handle = useFullScreenHandle();
  const classes = useStyles();


  const [rows, setRows] = useState([]);
  const [data, setData] = useState({
    labels: [],
    datasets: []
  });

  const getMax = (arr) => {
    const max = Math.max(...arr);
    let timesby;
    if(max <= 15) {
      timesby = 15;
    } else if (max <= 25) {
      timesby = 25;
    } else if (max <= 50) {
      timesby = 50;
    } else {
      timesby = 100;
    }
    return Math.ceil(max/100) * timesby;
  }


  const [options, setOptions] = useState({
    indexAxis: 'y',
    title: {
      display: true,
      color: grey[300],
      text: title
    },
    plugins: {
      legend: {
        labels: {
          color: grey[300],
        }
      }
    },
    scales: {
      x: {
        stacked: false,
        beginAtZero: true,
        suggestedMax: 10,
        display: true,
        color: grey[300],
        fontStyle: 'bold',
        ticks: {
          stepSize: 1,
          display: true,
          color: grey[300],
        },
        grid: {
          display:true,
          color: grey[300],
        }
      },
      y: {
        stacked: false,
        beginAtZero: true,
        suggestedMax: 10,
        display: true,
        color: grey[300],
        fontStyle: 'bold',
        ticks: {
          stepSize: 1,
          color: grey[300],
        },
        grid: {
          display:true,
          color: grey[300],
        }
      }
    },
    legend: {
      labels: {
        color: grey[300]
      }
    },
    responsive: true,
  });

  useEffect(() => {
    if(loading === false) {
      (async() => {
        const { labels, dataSet } = await new Promise((resolve, reject) => {
          let dataset = new Map();

          dataProp.map(item =>
            filterCallback(item, dataset, (index) => {
              dataset.set(index, (dataset.get(index) || 0) + 1)
            })
          )

          const datasetObject = Object.fromEntries(dataset);

          resolve({
            labels: Object.keys(datasetObject),
            dataSet: Object.values(datasetObject)
          })
        });

        setData({
          labels: labels,
          datasets: [
            {
              display: true,
              label: title,
              borderWidth: 2,
              data: dataSet,
              borderColor: enumColors['success'],
              backgroundColor: enumColors['success']
            }
          ]
        })
      })();
    }
  }, [loading, selectedDatasets, dataProp]);

  return (
    <Fade in timeout={{ appear: 1200, enter: 1000, exit: 1000}}>
      <Card chart className={classes.cardHover}>
        <CardHeader color={color}>
          <Bar
            data={data}
            options={options}
          />
        </CardHeader>
        <CardBody style={{ textAlign: 'right' }}>
        </CardBody>
      </Card>
    </Fade>
  );
};
export default VerticalBarChart;



// import React, { useState, useEffect } from 'react';
//
// // @mui/material components
// import { makeStyles, createStyles } from '@mui/styles';
//
// // react plugin for creating charts
// import ChartistGraph from "react-chartist";
// import Chartist from "chartist";
//
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardBody from "components/Card/CardBody.js";
//
// import { HorizontalBar } from 'react-chartjs-2';
//
//
// // import AccessTime from "@mui/icons-material/AccessTime";
// // import Refresh from "@mui/icons-material/Refresh";
// // import Edit from "@mui/icons-material/Edit";
//
// import Moment from "devtools/momentjs";
//
// import { Fade, Box, Chip } from '@mui/material';
//
// import { CustomAvatarWithBadge } from 'components/CustomAvatar';
//
// import styles from "assets/jss/views/dashboardStyle.js";
//
// import { parseChartDataNew } from 'devtools/chartHelper';
//
// import { reduce } from 'lodash';
//
// const useStyles = makeStyles(createStyles(styles));
//
// var delays = 80, durations = 500;
//
// const colors = {
//   success: {},
//   danger: {},
//   info: {
//     borderColor: 'rgba(26, 138, 152, 0.6)',
//     backgroundColor: 'rgba(26, 138, 152, 0.6)',
//   }
// };
//
// const HorizontalBarChart = ({ children, color, title, footerText, loading, data: dataProp, users, filterProps, datasetColors, ...props}) => {
//   const classes = useStyles();
//   const [data, setData] = useState({
//     labels: [],
//     datasets: []
//   });
//
//   const getMax = (arr) => {
//     const max = Math.max(...arr);
//     let timesby;
//     if(max <= 15) {
//       timesby = 15;
//     } else if (max <= 25) {
//       timesby = 25;
//     } else if (max <= 50) {
//       timesby = 50;
//     } else {
//       timesby = 100;
//     }
//     return Math.ceil(max/100) * timesby;
//   }
//
//   const [options, setOptions] = useState({
//     legend: {
//       labels: {
//         fontColor: 'rgba(255, 255, 255, 0.75)'
//       }
//     },
//     scales: {
//       xAxes: [{
//         stacked: true,
//         beginAtZero: true,
//         ticks: {
//           stepSize: 1,
//           fontColor: 'rgba(255, 255, 255, 0.75)',
//           suggestedMax: 10,
//         },
//         gridLines: {
//           display:true,
//           color: 'rgba(255, 255, 255, 0.75)'
//         }
//       }],
//       yAxes: [{
//         stacked: true,
//         beginAtZero: true,
//         ticks: {
//           stepSize: 1,
//           fontColor: 'rgba(255, 255, 255, 0.75)',
//           suggestedMax: 10,
//         },
//         gridLines: {
//           display:true,
//           color: 'rgba(255, 255, 255, 0.75)'
//         }
//       }]
//     }
//   });
//
//
//   useEffect(() => {
//     if(loading === false) {
//       (async() => {
//         const { labels, dataSet } = await new Promise((resolve, reject) => {
//           let dataset = {};
//
//           dataProp.map(item => {
//             if(item.handled === 1 && item.handledperson) {
//               if(dataset[item.handledperson] == undefined) {
//                 dataset[item.handledperson] = 1;
//               } else {
//                 dataset[item.handledperson]++;
//               }
//             }
//           });
//
//           resolve({
//             labels: Object.keys(dataset),
//             dataSet: Object.values(dataset)
//           })
//         });
//
//         setData({
//           legend: {
//             labels: {
//               fontColor: 'rgba(255, 255, 255, 0.75)'
//             }
//           },
//           labels: labels,
//           datasets: [
//             {
//               display: true,
//               label: title,
//               borderWidth: 2,
//               data: dataSet,
//               ...colors[datasetColors]
//             }
//           ]
//         });
//
//         setOptions({
//           legend: {
//             labels: {
//               fontColor: 'rgba(255, 255, 255, 0.75)'
//             }
//           },
//           scales: {
//             xAxes: [{
//               stacked: true,
//               beginAtZero: true,
//               ticks: {
//                 stepSize: 1,
//                 fontColor: 'rgba(255, 255, 255, 0.75)',
//                 suggestedMax: getMax(dataSet),
//               },
//               gridLines: {
//                 display:true,
//                 color: 'rgba(255, 255, 255, 0.75)'
//               }
//             }],
//             yAxes: [{
//               stacked: true,
//               beginAtZero: true,
//               ticks: {
//                 stepSize: 1,
//                 fontColor: 'rgba(255, 255, 255, 0.75)',
//                 suggestedMax: getMax(dataSet),
//               },
//               gridLines: {
//                 display:true,
//                 color: 'rgba(255, 255, 255, 0.75)'
//               }
//             }]
//           }
//         });
//
//       })();
//     }
//   }, [loading]);
//
//   return (
//     <Fade in timeout={{ appear: 1200, enter: 1000, exit: 1000}}>
//       <Card chart className={classes.cardHover}>
//         <CardHeader color={color}>
//           <HorizontalBar
//             data={data}
//             options={options}
//           />
//         </CardHeader>
//         <CardBody style={{ textAlign: 'right' }}>
//           <Box width={1} display='flex' flexDirection='row' justifyContent="flex-start">
//             <Box alignSelf='flex-end'>
//               <p className={classes.cardCategory}>{footerText} <Chip component='span' label={dataProp.length}/></p>
//             </Box>
//           </Box>
//
//         </CardBody>
//       </Card>
//     </Fade>
//   );
// };
// export default HorizontalBarChart;
