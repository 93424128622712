import React from 'react';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

const TooltipComponent = ({ children, ...props}) => {
  return (
    <HelperTooltipBootstrap {...props}>
      {children}
    </HelperTooltipBootstrap>
  )
}

TooltipComponent.defaultProps = {
  title: '?',
  placement: 'bottom',
  arrow: true
}

export default TooltipComponent;
