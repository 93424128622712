export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function capitalizeTheFirstLetterOfEachWord(words) {
  try {
    if(!words) throw '';
    let word = words.toLowerCase().split(' ');
    for (var i = 0; i < word.length; i++) {
      word[i] = word[i].charAt(0).toUpperCase() +
      word[i].substring(1);
    }
    return word.join(' ');
  } catch (e) {
    console.log(e)
    return e;
  }
}

export function username2altName(str = '') {
  return str.split(' ').map(a => a.charAt(0).toUpperCase()).join('.');
}

export function concatString(values = [], delimiter = " ") {
	return values.filter(item => Boolean(item)).join(delimiter);
}
