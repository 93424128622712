import React from 'react';

import ExtensionIcon from  '@mui/icons-material/Extension';
import MusicNoteIcon from  '@mui/icons-material/MusicNote';
import AccountTreeIcon from  '@mui/icons-material/AccountTree';

import { Subject, combineLatest } from "rxjs";
// import { map, distinctUntilChanged, filter } from 'rxjs/operators';
//
// import { groupBy, partition, keyBy, merge, values, uniqBy, uniqWith, isEqual } from 'lodash'

export const modal_events_observable = new Subject();
