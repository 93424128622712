import React from 'react';
import { withStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

import { Badge } from '@mui/material';

const HelperBadge = withStyles((theme) => ({
  badge: {
    right: -10,
    top: 5,
    whiteSpace: 'normal',
    width: 'min-content',
    backgroundColor: grey[800],
    color: grey[100]
  }
}))(Badge);

export default HelperBadge;
