import React from 'react'
import { connect } from 'react-redux';
import { grey } from '@mui/material/colors';
import { Field, FieldArray, formValueSelector, change, reduxForm } from 'redux-form'
import { FormTextField, FormCalleridAutoComplete, FormPhoneNumberTextField, FormExtensionTextField } from 'components/Forms/Components';
import { required, asyncExtensionCheck } from 'components/Forms/Validators';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import CloseIcon from  '@mui/icons-material/Close';

import { Chip, IconButton, InputAdornment, TableRow, TableCell } from '@mui/material';

const FormCreateExtensionFields = ({ fields, meta: { error, submitFailed }, getFormValues, ...props }) => {
  const { isRowSelected, labelId, pristine, dirty, reset, submitting, change, initialValues, form } = props;

  if(!fields || fields.length === 0) return null;
  return fields.map((row, index) => {
    return (
      <TableRow
        key={row}
        hover
        role="checkbox"
        aria-checked={isRowSelected}
        tabIndex={-1}
        selected={isRowSelected}
      >

        <TableCell padding="normal" align="center">
          <HelperTooltipBootstrap title='Remove' placement='right'>
            <IconButton onClick={() => fields.remove(index)}>
              <CloseIcon />
            </IconButton>
          </HelperTooltipBootstrap>
        </TableCell>
      </TableRow>
    )
  })
}

const mapStateToProps = state => {
  return {
    getFormValues: (form, values) => {
      const selector = formValueSelector(form)
      return selector(state, ...values) || {};
    }
  }
};

export default connect(
  mapStateToProps,
  null
)(FormCreateExtensionFields);
