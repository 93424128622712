import React, { memo, useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

import classNames from "classnames";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import FiberManualRecord from "@mui/icons-material/FiberManualRecord";

import styles from "assets/jss/views/regularFormsStyle";
const useStyles = makeStyles(createStyles(styles));

const FormExtensionCreativeRadioGroup = (
  { input: { onChange, onClose, name, value, ...input}, label, meta: { touched, dirty, valid, error }, customTextField, options, disabled, helperText, ...custom },
) => {
  const classes = useStyles();

  const showError = dirty && error;
  const success = valid && dirty && !showError ? true : false;

  const rootClasses = classNames({
    [classes.radioRoot]: true,
    [classes.labelSuccess]: success && !showError,
  });

  return (
    <FormControl component="fieldset" >
      { label && <FormLabel component="legend">{label}</FormLabel> }
      <RadioGroup
        color='primary'
        aria-label={label}
        name={name}
        value={value}
        onChange={onChange}
        {...custom}
      >
        {
          [...options].map((option, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Radio
                    aria-label={option.label}
                    icon={<FiberManualRecord className={classes.radioUnchecked} />}
                    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                    classes={{
                      checked: classes.radio,
                      disabled: classes.disabledCheckboxAndRadio,
                      root: rootClasses
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                value={String(option.value)}
                disabled={disabled}
                label={option.label}
              />

            )
          })
        }
      </RadioGroup>
      <FormHelperText>
        {helperText}
        {error}
      </FormHelperText>
    </FormControl>
  )
}

FormExtensionCreativeRadioGroup.defaultProps = {
  options: []
};


export default FormExtensionCreativeRadioGroup;
