import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';

import styles from "assets/jss/components/badgeStyle.js";

const useStyles = makeStyles(createStyles(styles));

export default function Badge(props) {
  const { color, children } = props;
  const classes = useStyles();
  return (
    <span className={classes.badge + " " + classes[color]}>{children}</span>
  );
}

Badge.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  children: PropTypes.node
};
