import React, { useState, useCallback, useEffect } from "react";
import { makeStyles, createStyles } from '@mui/styles';
import classNames from "classnames";
import PropTypes from "prop-types";

import { TextareaAutosize } from '@mui/material';
import { debounce } from 'lodash';

import styles from "assets/jss/components/customInputStyle.js";
const useStyles = makeStyles(createStyles(styles));

const CustomTextAreaAutoSize = ({ value: valueProp, ...props}) => {
  const [value, setValue] = useState(valueProp || '');
  const classes = useStyles();
  const {
    formControlProps,
    id,
    customProps,
    error,
    success,
    white,
    onChange,
    inputRootCustomClasses,
    errorText,
    successText,
  } = props;

  const rootClasses = classNames({
    [classes.textarea]: white
  });

  useEffect(() => {
    if(value !== valueProp) setValue(valueProp);
  }, [valueProp]);

  let staticProps;

  if(customProps.error) staticProps.helperText = errorText;
  if(customProps.success) staticProps.helperText = successText;

  const handler = useCallback(debounce(value => onChange(value), 400), []);

  return (
    <TextareaAutosize
      id={id}
      className={rootClasses}
      value={value || ''}
      {...customProps}
      {...staticProps}
      onChange={e => {
        setValue(e.currentTarget.value);
        e.persist();
        handler(e);
      }}
    />
  );
}

CustomTextAreaAutoSize.propTypes = {
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node
};

export default CustomTextAreaAutoSize;
