import React, { useState, useEffect, useCallback } from 'react';
//import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { grey } from '@mui/material/colors';

// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';

// react plugin for creating charts

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { Bar } from 'react-chartjs-2';

// import AccessTime from "@mui/icons-material/AccessTime";
// import Refresh from "@mui/icons-material/Refresh";
import Edit from "@mui/icons-material/Edit";
import FullscreenIcon from  '@mui/icons-material/Fullscreen';

import Moment from "devtools/momentjs";

import { Fade, Box, Chip, IconButton } from '@mui/material';

import { CustomAvatarWithBadge } from 'components/CustomAvatar';

import styles from "assets/jss/views/dashboardStyle.js";

import { parseChartData, getMax } from 'devtools/chartHelper';

import { reduce, groupBy } from 'lodash';

import { COLORS } from "constants/index.js";

const useStyles = makeStyles(createStyles(styles));

const enumColors = {
  primary: '#AB47BC',
  warning: '#CC851E',
  rose: '#E91E63',
  info: '#187E8B',
  success: '#407743',
  danger: '#B50E0B',
}

const CustomChart = ({ children, color, footerText, title, customLabels, filterCallback, selectedDatasets, subLabels, loading, data: dataProp, filterProps, ...props}) => {
  //const handle = useFullScreenHandle();
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [data, setData] = useState({
    labels: [],
    datasets: []
  });

  const [options, setOptions] = useState({
    indexAxis: 'y',
    color: grey[300],
    plugins: {
      title: {
        display: true,
        color: grey[300],
        fontSize: 16,
        text: title,
      }
    }
  });

  useEffect(() => {
    console.log(loading)
    if(loading === false) {
      (async() => {
        const { labels, dataSets } = await new Promise((resolve, reject) => {
          function doLabels(arr) {
            let myMap = new Map();
            arr.map(item => myMap.set(item, 0))
            return myMap;
          }

          let rawdata = {
            'All Calls': dataProp,
            ...groupBy(dataProp, function(b) { return b.soitettu})
          }

          for (const [label, dataChunk] of Object.entries(rawdata)) {
            let dataset = new Map(customLabels ? doLabels(customLabels) : null);

            dataChunk.map(item =>
              filterCallback(item, dataset, (index) => {
                dataset.set(index, (dataset.get(index) || 0) + 1)
              })
            )

            rawdata[label] = Array.from(dataset.values());
          }

          resolve({
            labels: customLabels,
            dataSets: rawdata
          })
        });

        setData({
          labels: labels,
          datasets: [
            ...Object.values(dataSets).map((dataset, index) => {
              const isFirst = index === 0;
              const datasetColor = isFirst ? enumColors[color] : COLORS[index];
              const label = Object.keys(dataSets)[index];

              // filter for selectedDatasets
              let formatedArray = selectedDatasets.map(item => item.value);
              const hideByOverride = !formatedArray.includes(label);

              return {
                hidden: hideByOverride,
                label: label,
                borderWidth: 2,
                data: dataset,
                backgroundColor: datasetColor,
                borderColor: datasetColor
              }
            })
          ]
        })

        setOptions({
          title: {
            display: true,
            color: grey[300],
            text: title
          },
          plugins: {
            legend: {
              labels: {
                color: grey[300],
              }
            }
          },
          scales: {
            x: {
              stacked: false,
              beginAtZero: true,
              suggestedMax: 10,
      				display: true,
              color: grey[300],
              fontStyle: 'bold',
              ticks: {
                stepSize: 1,
                display: true,
                color: grey[300],
              },
              grid: {
                display:true,
                color: grey[300],
              }
            },
            y: {
              stacked: false,
              beginAtZero: true,
              suggestedMax: 10,
      				display: true,
              color: grey[300],
              fontStyle: 'bold',
              ticks: {
                stepSize: 1,
                color: grey[300],
              },
              grid: {
                display:true,
                color: grey[300],
              }
            }
          },
          legend: {
            labels: {
              color: grey[300]
            }
          },
          responsive: true,
        });
      })();
    }
  }, [loading, selectedDatasets, dataProp]);

  return (
    <Fade in timeout={{ appear: 1300, enter: 1000, exit: 1000}}>
      <Card chart className={classes.cardHover}>
        <CardHeader color={color}>
          <Bar
            data={data}
            options={options}
          />
        </CardHeader>
        <CardBody style={{ textAlign: 'right' }}>
          <Box width={1} display='flex' flexDirection='row' justifyContent="flex-start">
            {
              data.datasets.map((item, index) => <Box key={index} paddingX={4} component='span' className={classes.cardCategory}>{item.label} <Chip component='span' label={item.data.reduce((a, c) => a + c)}/></Box>)
            }
            {
              // <Box style={{ marginLeft: 'auto' }}>
              //   <IconButton onClick={handle.enter}>
              //     <FullscreenIcon />
              //   </IconButton>
              // </Box>
            }
          </Box>
        </CardBody>
      </Card>
    </Fade>
  );
};
export default CustomChart;
