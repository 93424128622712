import React, { Fragment } from 'react';
import { ListSubheader, Divider, Chip, Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const RenderGroup = (props) => {
  const randomKey = uuidv4();

  const { children, group, additionals } = props;
  return (
    <Fragment key={randomKey}>
      <ListSubheader style={{ backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Grid container spacing={2} justifyContent='flex-start' alignItems='center'>
          <Grid item xs>
            {group}
          </Grid>

          <Grid item xs container justifyContent="flex-end" alignItems='center' spacing={2}>
            <Grid item>
              { additionals && additionals[group] ?  additionals[group] : null }
            </Grid>

            <Grid item>
              <Chip label={children.length} size='small' />
            </Grid>
          </Grid>
        </Grid>

      </ListSubheader>
      {children}
      <Divider />
    </Fragment>
  )
};

export default RenderGroup;
