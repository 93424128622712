import React, { useEffect, useState, forwardRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Chip } from '@mui/material';

import { sleep } from 'Helpers';

class AudioFile {
  constructor(data, filename = 'AudioFile.wav') {
    return new File([data], filename, { type: "audio/wav" });
  }
}

const AudioControls = forwardRef(({ audio, noContentText, size, filesize: filesizeProp, ...props}, ref) => {
  const { autoPlay, controls } = props;
  if(!audio?.type) console.error('missing audio.type!');
  const [srcUrl, setSrcUrl] = useState(window.URL.createObjectURL(new AudioFile(audio)));

  useEffect(() => {
    (async() => {
      console.log('new file detected!')
      if(filesizeProp) {
        setSrcUrl(null) // setting null unrenders component for changes !important
        await sleep(100);
        setSrcUrl(window.URL.createObjectURL(new AudioFile(audio)));
      }
    })();
  }, [filesizeProp])

  return srcUrl
    ? <audio ref={ref} controls={Boolean(controls)} autoPlay={Boolean(autoPlay)}>
       {
         srcUrl && <source src={srcUrl} type={audio?.type} />
       }
       <Chip label="Your browser does not support the audio element." />
      </audio>
    : noContentText && <Chip label={noContentText} size={size} />
});

// const file = new File([blobData], "voice.mp3")
// this.audioSource = URL.createObjectURL(file)

AudioControls.defaultProps = {
  noContentText: "No Audio Content Available!",
  size: 'medium',
  controls: true,
  filesize: null,
}

export default AudioControls;
