import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Avatar, TableCell, TableRow } from '@mui/material';

import RegularButton from "components/CustomButtons/Button";
import { TableRowCard } from "components/Card";
const grid = 8;

const getItemStyle = (draggableStyle, isDragging) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // styles we need to apply on draggables
  ...draggableStyle
});

//
// class DragNDrop extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       items: getItems(10)
//     }
//     this.onDragEnd = this.onDragEnd.bind(this);
//   }
//
//   onDragEnd (result) {
//     // dropped outside the list
//     if(!result.destination) {
//        return;
//     }
//
//     const items = reorder(
//       this.state.items,
//       result.source.index,
//       result.destination.index
//     );
//
//     this.setState({
//       items
//     });
//   }
//
//   // Normally you would want to split things out into separate components.
//   // But in this example everything is just done in one place for simplicity
//   render() {
//      return (
//        <DragDropContext onDragEnd={this.onDragEnd}>
//         <Droppable droppableId="droppable">
//           {(provided, snapshot) => (
//             <div
//               ref={provided.innerRef}
//               style={getListStyle(snapshot.isDraggingOver)}
//               {...provided.droppableProps}
//             >
//               {this.state.items.map((item, index) => (
//                 <Draggable
//                   key={item.id}
//                   draggableId={item.id}
//                   index={index}
//                 >
//                   {(provided, snapshot) => (
//                     <Box display='flex' alignItems="center">
//                       <Box width={'40px'} textAlign='center'>
//                         <Typography>{index + 1}</Typography>
//                       </Box>
//                       <Box
//                         flexGrow={1}
//                         ref={provided.innerRef}
//                         {...provided.dragHandleProps}
//                         {...provided.draggableProps}
//                         style={getItemStyle(
//                           provided.draggableProps.style,
//                           snapshot.isDragging
//                         )}
//                       >
//                         {item.content}
//                       </Box>
//                     </Box>
//                    )}
//                 </Draggable>
//                ))}
//               {provided.placeholder}
//             </div>
//            )}
//         </Droppable>
//       </DragDropContext>
//      );
//   }
// }
//
// export default DragNDrop;

class DragNDrop extends Component {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd (result) {
    console.log(result)
    // dropped outside the list
    // if(!result.destination) {
    //    return;
    // }
    //
    // const items = reorder(
    //   this.state.items,
    //   result.source.index,
    //   result.destination.index
    // );
    //
    // this.setState({
    //   items
    // });
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { draggables } = this.props;
     return (
       <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {draggables.map((draggableContent, index) => (
                <Draggable
                  key={'draggable-'+index}
                  draggableId={'draggable-'+index}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <TableRow
                      component={TableRowCard}
                      hover
                      color={null}
                      flexGrow={1}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        provided.draggableProps.style,
                        snapshot.isDragging
                      )}
                    >
                      <TableCell>
                        <RegularButton
                          justIcon
                          round
                          color="github"
                        >
                          { index + 1}
                        </RegularButton>
                      </TableCell>
                      {draggableContent}
                      {
                        provided.placeholder
                      }
                    </TableRow>
                  )}
                </Draggable>
               ))}
            </div>
           )}
        </Droppable>
      </DragDropContext>
     );
  }
}

// <Box display='flex' width={1} alignItems="center">
//   <Box paddingX={2} textAlign='center'>
//     <Avatar>
//       {index + 1}
//     </Avatar>
//   </Box>
//   <Box
//     flexGrow={1}
//     ref={provided.innerRef}
//     {...provided.dragHandleProps}
//     {...provided.draggableProps}
//     style={getItemStyle(
//       provided.draggableProps.style,
//       snapshot.isDragging
//     )}
//   >
//     {draggableContent}
//     {provided.placeholder}
//   </Box>
// </Box>

export default DragNDrop;
