import React from "react";

import {
  Switch,
} from '@mui/material';

import { DarkerButton } from 'components/CustomButtons';

const DNDButton = ({ value, handleChange, ...props }) => {
  return (
    <DarkerButton
      color={value ? 'error' : 'primary'}
      id="demo-customized-button-dnd"
      variant="contained"
      endIcon={(
        <Switch checked={value} size='small' color="warning" />
      )}
      onClick={handleChange}
    >
      Do Not Disturb
    </DarkerButton>
  )
}

export default DNDButton;
