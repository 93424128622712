// import { deepOrange } from '@mui/material/colors';



const somicBlue = "#2D4492";
const somicBlueDark = "#041e42";
const somicLightGrey = "#adaeb0";
const somicYellow = "#f3e52c";
const somicGreyColor = "#262626";

const greyShadow = "0 0.5em 1em rgb(0 0 0 / 40%)";

const somicGrey = {
  boxShadow: greyShadow,
  backgroundColor: somicBlue + " !important",
};

const somicHeader = {
  color: somicGrey + " !important",
  backgroundColor: somicBlue + " !important",
};

export {
  somicBlue,
  somicBlueDark,
  somicHeader,
  somicYellow,
  somicGrey,
};
