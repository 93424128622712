import React, { useState, useEffect, forwardRef } from "react";
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';
import { Modal, Fade, Backdrop, Dialog } from '@mui/material';

import { DialogTitle } from './Components';

import { Card, CardHeader, CardIcon, CardBody, CardFooter } from 'components/Card';

import AccountTreeIcon from  '@mui/icons-material/AccountTree';

import { SimpleLoader } from 'components/Loaders';

import { modal_events_observable } from 'store/Observables';

// const useStyles = makeStyles((theme) => ({
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   paper: {
//     backgroundColor: theme.palette.background.paper,
//     border: '2px solid #000',
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(2, 4, 3),
//   },
// }));

const withModal = Component => {
  class WithModal extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        open: false,
        submitting: false,
        scroll: 'paper'
      };

    }

    componentDidMount() {
      this.subscription = modal_events_observable.subscribe(
        event => {
          if(event) this.setState({ open: true });
        },
        err => {

        }
      )
    }

    componentWillUnmount() {
      if(this.subscription) this.subscription.unsubscribe();
    }

    handleOpen = () => {
      this.setState({ open: true });
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    handleComplete = async (promise) => {
      this.setState({ submitting: true });
      try {
        await promise;
        this.setState({ open: false, submitting: false });
      } catch (err) {
        this.setState({ open: true, submitting: false });
      }
    };

    render() {
      const { open, submitting, scroll } = this.state;
      const { fullWidth, maxWidth } = this.props;
      return (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          container={document.body}
          open={open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Dialog
            maxWidth={submitting ? 'sm' : maxWidth}
            fullWidth={submitting ? false : fullWidth}
            onClose={this.handleClose}
            open={open}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
              //style: { overflow: 'visible' }
            }}
            // PaperProps={{
            //   noMargin: true,
            //   style: {
            //     overflow: 'visible'
            //   }
            // }}
          >
            <Component
              open={!submitting}
              handleOpen={this.handleOpen}
              handleClose={this.handleClose}
              handleComplete={this.handleComplete}
              {...this.props}
            />

            {
              submitting &&
                <DialogTitle id="alert-dialog-title">
                  <SimpleLoader p={3} label="Submitting" />
                </DialogTitle>
            }
          </Dialog>
        </Modal>
      )
    }
  }

  // Set default props
  WithModal.defaultProps = {
    fullWidth: true,
    maxWidth: "lg"
  };

  return connect(
    null,
    null,
  )(WithModal);
};

export default withModal;
