import React, { createContext, useEffect, useReducer } from 'react';
import Logger from 'WEBRTC/Logger';

import { v4 as uuidv4 } from 'uuid';

const logger = new Logger('Session');
const SessionsContext = createContext();

function reducer(state, action) {
  // switch (action.type) {
  //   case 'SESSION_ADD':
  //     return [...state, action.payload];
  //   // case 'SESSIONS_UPDATE':
  //   //   return state.map(item => {
  //   //     if (item.uuid === action.payload.uuid) {
  //   //       return Object.assign(item, action.payload);
  //   //     }
  //   //     return item;
  //   //   })
  //   case 'SESSION_DELETE':
  //     return [...state].filter(session => session?.data?.uuid !== action.payload?.data?.uuid);
  //   default:
  //     return state;
  // }

  switch (action.type) {
    case 'SESSION_ADD':
      return { ...Object.assign(state, { [uuidv4()]: action.payload })}
    // case 'SESSIONS_UPDATE':
    //   return state.map(item => {
    //     if (item.uuid === action.payload.uuid) {
    //       return Object.assign(item, action.payload);
    //     }
    //     return item;
    //   })
    case 'SESSION_DELETE':
      //return [...state].filter(session => session?.data?.uuid !== action.payload?.data?.uuid);
      return state;
    default:
      return state;
  }
}

// rest sessions setSessions
const withSessionsContext = (Component) => (props) => {
  const {
    setTab
  } = props;

  const [sessions, dispatcher] = useReducer(reducer, {});


  //console.log('RENDER')
  //let mounted = true, subscription;

  // useEffect(() => {
  //   if(mounted && sessions_observable) {
  //     subscription = sessions_observable.subscribe(
  //       value => setSessions(value),
  //       err => console.log(err)
  //     );
  //   }
  //   return () => {
  //     if(mounted && sessions_observable) {
  //       subscription.unsubscribe();
  //       mounted = false;
  //     }
  //   }
  // }, [])


  return (
    <SessionsContext.Provider value={{ sessions, dispatcher }}>
      <Component {...props} />
    </SessionsContext.Provider>
  )
};

export {
  SessionsContext as default,
  withSessionsContext
}
