import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { grey } from '@mui/material/colors';
// import { makeStyles, createStyles } from '@mui/styles';
import { Avatar, TextField, ListItemText, ListItemAvatar, InputAdornment, Autocomplete } from '@mui/material';

import HearingIcon from  '@mui/icons-material/Hearing';
import MusicNoteIcon from  '@mui/icons-material/MusicNote';

import { isLoading } from 'devtools/Helpers';

const FormRingBackAutocompelete = (
  { input: { onChange, onClose, name, value, ...input}, label, meta: { touched, error }, customTextField, data, loading, dispatch, ...custom },
) => {
  return (
    <Autocomplete
      blurOnSelect
      onChange={(event, value, reason) => {
        onChange(value)
      }}
      disableClearable
      fullWidth
      name={name}
      value={value}
      options={data}
      loading={loading}
      groupBy={(option) => option.category}
      getOptionSelected={(option, value) => {
        return String(option.value) === String(value)
      }}
      getOptionLabel={(option) => String(option?.recording_description || option)}
      renderInput={({ InputProps, ...params}) => {
        return (
          <TextField
            {...params}
            label={label}
            {...customTextField}
            InputProps={{
              ...InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <HearingIcon style={{ color: grey[500] }} />
                </InputAdornment>
              ),
            }}
          />
        )
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          {
            option.category === 'Announcements' &&
              <ListItemAvatar>
                <Avatar size='small'>
                  <MusicNoteIcon />
                </Avatar>
              </ListItemAvatar>
          }
          <ListItemText primary={option.recording_description} />
        </React.Fragment>
      )}
      {...custom}
    />
  );
}

const mapStateToProps = state => ({
  loading: state.announcements.loading,
  data: (function(){
    const { group } = state.currentUser.authUser;
    return [
      { recording_description: 'Default Ringing', value: '${fi-ring}', category: 'Other' },
      { recording_description: 'Background Music', value: 'local_stream://default', category: 'Other' },
      ...state.announcements.announcements.map(({recording_filename, ...item}) => ({ ...item, value: `/var/lib/freeswitch/recordings/${group}.vaihde.io/${recording_filename}`, category: 'Announcements' }))
    ];
  })(),
});

export default connect(
  mapStateToProps,
  null
)(FormRingBackAutocompelete);
