import {
  ANNOUNCEMENTS_PENDING,
  ANNOUNCEMENTS_SUCCESS,
  UPLOAD_ANNOUNCEMENT_SUCCESS,
  ANNOUNCEMENT_SAMPLES_PUSH,
  ANNOUNCEMENT_SAMPLES_CHUNK
} from 'store/types';

import Moment from 'devtools/momentjs';
import { b64toBlob, blobToBase64, getAudioContext } from 'devtools/helper';
import { onError, enqueueSnackbar } from 'store/Notifier/actions';
import { v4 as uuidv4 } from 'uuid';

import { sweetalerts } from 'sweetalerts';

export const updateAnnouncementActions = (object, callback) => {
  return async (dispatch, getState, firebase) => {
    const { id, recording_name } = object;
    const filename = `${recording_name}.wav`;
    const data = { recording_name, recording_filename: filename, recording_description: filename }
    let state;

    const loadingKey = enqueueSnackbar(dispatch, 'LOADING')

    try {
      await firebase.authStatePromise;

      await firebase.transaction(firebase.announcements().child(id), (snapshot) => {
        return {...Object.assign(snapshot || {}, data)};
      });

      // await firebase.firestoreTransaction(firebase.announcements().doc(uid), (t, doc) => {
      //   t.update(doc.ref, Object.assign(doc.data() || {}, { ...data, ...firebase.customFirestoreData }));
      //   return;
      // });
      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey);
      state = true;
    } catch (err) {
      onError(dispatch, err)
      state = false;
    } finally {
      callback(state);
    }
  }
};

export const updateAnnouncementsActions = (data) => {
  return async (dispatch, getState, firebase) => {
    // maybe needs recording_filename to be changed!

    const loadingKey = enqueueSnackbar(dispatch, 'LOADING')

    try {
      await firebase.authStatePromise;
      await firebase.announcements().update(data);
      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey);
    } catch (err) {
      onError(dispatch, err)
    }
  }
};

export const removeAnnouncementsActions = (data) => {
  return async (dispatch, getState, firebase) => {
    // maybe needs recording_filename to be changed!

    try {
      await firebase.authStatePromise;
      await sweetalerts.confirm();
      const loadingKey = enqueueSnackbar(dispatch, 'LOADING');
    
      await firebase.announcements().update(data);
      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey);
    } catch (err) {
      if (err) onError(dispatch, err)
    }
  }
};


export const removeAnnouncementActions = (id, callback) => {
  return async (dispatch, getState, firebase) => {
    let state;

    const loadingKey = enqueueSnackbar(dispatch, 'LOADING')

    try {
      await firebase.authStatePromise;

      await firebase.transaction(firebase.announcements().child(id), (snapshot) => {
        return null;
      });

      // await firebase.firestoreTransaction(firebase.announcements().doc(uid), (t, doc) => {
      //   t.delete(doc.ref);
      //   return;
      // });

      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey);

      state = true;
    } catch (err) {
      onError(dispatch, err);
      state = false;
    } finally {
      callback(state);
    }
  }
};


export const upload_announcementActions = file => {
  return async (dispatch, getState, firebase) => {
    let state;

    const loadingKey = enqueueSnackbar(dispatch, 'LOADING')

    try {
      await firebase.authStatePromise;
      const response = await firebase.httpQueryUploadAnnouncement(file);

      switch (response.status) {
        case 200:
          dispatch({ type:  UPLOAD_ANNOUNCEMENT_SUCCESS, payload: response });

          enqueueSnackbar(dispatch, 'SUCCESS', loadingKey);
          break;
        default:
          throw response;
      }
    } catch (err) {
      onError(dispatch, err);
    }
  }
};

export const create_sample = (sample) => {
  const { filename, ssml } = sample;
  return async (dispatch, getState, firebase) => {
    try {
      await firebase.authStatePromise;
      const response = await firebase.httpQueryTTSMicrosoft({ ssml: ssml });

      // const response = await firebase.httpQueryTTSMicrosoft({
      //   ssml: `<speak version="1.0" xmlns="https://www.w3.org/2001/10/synthesis" xml:lang="fi-FI">` +
      //     `<voice name="fi-FI-HeidiRUS">` +
      //       `<prosody rate="0%" pitch="0%">` +
      //         `Hei, soititte GBC Networksille. Palvelemme teitä hetken kuluttua.` +
      //       `</prosody>` +
      //     `</voice>` +
      //   `</speak>`
      // });


      // const response = await firebase.httpQueryTTSMicrosoft({
      //   ssml: `<speak version="1.0" xmlns="https://www.w3.org/2001/10/synthesis" xml:lang="fi-FI">` +
      //     `<voice name="fi-FI-HarriNeural">` +
      //       `<prosody rate="0%" pitch="0%">` +
      //         `Hei, soititte GBC Networksille. Palvelemme teitä hetken kuluttua.` +
      //       `</prosody>` +
      //     `</voice>` +
      //   `</speak>`
      // });

      // const response = await firebase.httpQueryTTSMicrosoft({
      //   ssml: `<speak version="1.0" xmlns="https://www.w3.org/2001/10/synthesis" xml:lang="en-US">` +
      //     `<voice name="fi-FI-SelmaNeural">` +
      //       `Good morning!` +
      //     `</voice>` +
      //     `<voice name="fi-FI-HarriNeural">` +
      //       `Good morning to you too Aria!` +
      //     `</voice>` +
      //   `</speak>`
      // });

//       const response = await firebase.httpQueryTTSMicrosoft({
//         ssml: `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="en-US">
//     <voice name="en-IE-EmilyNeural">
//         <p>
//             <audio src="https://contoso.com/opinionprompt.wav"/>
//             Thanks for offering your opinion. Please begin speaking after the beep.
//             <audio src="https://contoso.com/beep.wav">
//                 Could not play the beep, please voice your opinion now.
//             </audio>
//         </p>
//     </voice>
// </speak>`
//       });


      // const response = await firebase.httpQueryTTS({
      //   token: 'L3xQ7UerzU5saczR',
      //   method: 'tts',
      //   ssml: true,
      //   text: ssml
      // });

      // const response = await firebase.httpQueryTTS({
      //   //token: 'L3xQ7UerzU5saczR',
      //   method: 'tts',
      //   ssml: true,
      //   text: ssml
      // });


      if(response.status !== 200) throw await response.json();
      console.log(response);

      const json = await response.json();
      if(!json) throw response;

      const blob = b64toBlob(json["audio_content"]);
      const file = new File([blob], filename, { type: "audio/wav" });

      return file;

      //dispatch({ type:  ANNOUNCEMENT_SAMPLES_PUSH, payload: Object.assign({ sampleid: uuidv4(), static: false, text: text, filename: file.name, file: file, audio: json["audio_content"] }, { selected: false, createdAt: Moment.timestamp() }) });

      //return sample;
    } catch (err) {
      onError(dispatch, err)
    } finally {
      //callback();
    }
  };
};


// create new sample!
export const add_sample = file => {
  return async (dispatch, getState, firebase) => {
    dispatch({ type:  ANNOUNCEMENT_SAMPLES_PUSH, payload: file });
    //dispatch({ type:  ANNOUNCEMENT_SAMPLES_PUSH, payload: Object.assign({ sampleid: uuidv4(), text: null, filename: file.name, file: file, audio: null }, { selected: true, createdAt: Moment.timestamp() }) });
    return true;
  };
};

export const edit_sample = data => {
  return async (dispatch, getState, firebase) => {
    try {
      const samples = getState().announcement_samples.samples.map(sample => {
        if(sample.sampleid === data.sampleid) {
          return Object.assign(sample, data);
        } else {
          return sample;
        }
      });

      dispatch({ type:  ANNOUNCEMENT_SAMPLES_CHUNK, payload: samples });
      return true;
    } catch (err) {
      onError(dispatch, err)
    }
  };
};


export const remove_sample = id => {
  return async (dispatch, getState, firebase) => {
    try {
      let samples = getState().announcement_samples.samples.filter(item => item.sampleid !== id);
      dispatch({ type:  ANNOUNCEMENT_SAMPLES_CHUNK, payload: samples });
      return true;
    } catch (err) {
      onError(dispatch, err)
    }
  };
};

// export const toggleSampleSelected_Actions = index => {
//   return async (dispatch, getState, firebase) => {
//     try {
//       let samples = getState().announcement_samples_State.samples;
//       samples[index].selected = !samples[index].selected;
//       dispatch({ type:  ANNOUNCEMENT_SAMPLES_CHUNK, payload: samples });
//     } catch (err) {
//       onError(dispatch, err)
//     }
//   };
// }

// used be TTSAudioList.js toogle sample selected
export const editSample_Actions = (index, data) => {
  return async (dispatch, getState, firebase) => {
    try {
      let samples = getState().announcement_samples_State.samples;
      samples[index] = Object.assign(samples[index], data);
      dispatch({ type:  ANNOUNCEMENT_SAMPLES_CHUNK, payload: samples });
    } catch (err) {
      onError(dispatch, err)
    }
  };
}


export const upload_samples = (files) => async (dispatch, getState, firebase) => {
  let state;
  if(files.length === 0) throw 'error files.length';

  const loadingKey = enqueueSnackbar(dispatch, {
    message: `Uploading (${files.length}) Files!`,
    options: {
      variant: 'info'
    }
  })

  try {
    await firebase.authStatePromise;
    const response = await firebase.httpQueryUploadAnnouncements(files);

    switch (response.status) {
      case 200: {
        enqueueSnackbar(dispatch, {
          message: `(${files.length}) Files uploaded`,
          options: {
            variant: 'success'
          }
        }, loadingKey);
        break;
      }
      default:
        throw response;
    }
  } catch (err) {
    onError(dispatch, err)
  } finally {
  }
};


const asCollection = object => Object.keys(object || {}).map(key => ({ ...object[key], id: key}));
