import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';

import { withMediaQuery } from 'HOC';

import { Link } from "react-router-dom";

import { Card, CardHeader, CardIcon, CardFooter } from 'components/Card';
import PhoneMissedIcon from  '@mui/icons-material/PhoneMissed';
import { Box } from '@mui/material';

import RegularButton from 'components/CustomButtons';

import styles from "assets/jss/views/dashboardStyle.js";
const useStyles = makeStyles(createStyles(styles));

const MissedCallsCardWithCount = ({ dispatch, ...props}) => {
  const classes = useStyles();
  const { mediaQuery: { isMobile }, calls, loading } = props;

  return (
    <Card>
      <CardHeader color="danger" stats={isMobile} icon>
        <CardIcon color="danger">
          <PhoneMissedIcon />
        </CardIcon>
        <Box textAlign='right'>
          <p className={classes.cardCategory}>Missed Calls</p>
          <h3 className={classes.cardTitle}>
            {
              loading === false
              ? calls.length < 100
                ? calls.length
                : '99+'
              : '?'
            }
          </h3>
        </Box>
      </CardHeader>
      <CardFooter stats>
        <div className={classes.stats}>
          10 Most Recent Calls Below
        </div>
        <RegularButton
          size='sm'
          round
          component={Link}
          to='./missedcalls'
        >
          More
        </RegularButton>
      </CardFooter>
    </Card>
  );
}

const mapStateToProps = state => ({
  loading: state.missedcalls.loading,
  calls: state.missedcalls.unhandled_30days
});

export default connect(
  mapStateToProps,
  null
)(
  withMediaQuery(MissedCallsCardWithCount)
);
