/*!
=========================================================
* Portal.vaihde.io
=========================================================

* App Auther Janne Hailikari

=========================================================
*/
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
// import { createTheme } from '@mui/material/styles';


import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import Store from './store';
import "assets/scss/portal.vaihde.io.scss?v=1.8.0";

import "./index.css"; // load fonts

import App from './App';
import _package from '../package.json';

import Firebase, { FirebaseContext } from './Firebase';

import { Grid, Fade, CircularProgress } from '@mui/material';

const Notifier = lazy(() => import('./components/Notifier'));

// const hist = createBrowserHistory(); //tämä
const firebase = new Firebase();
let store = new Store(firebase);

function MainLoader() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <CircularProgress size={70} thickness={2} color="secondary" />
      </Grid>
    </Grid>
  )
}

ReactDOM.render(
  <FirebaseContext.Provider value={firebase}>
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <Suspense fallback={<MainLoader />}>
          <Notifier />
          <App firebase={firebase} store={store} />
        </Suspense>


        <div id='version' style={{ display: "none" }}>
          v{_package?.version}
        </div>

        <div id='modal' />
        <div id='snackbar' />
      </SnackbarProvider>
    </Provider>
  </FirebaseContext.Provider>,
  document.getElementById('root'),
);

export { store, firebase };
