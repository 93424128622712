import React from 'react';
import { grey } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import { Input, Chip, FormControl, FormHelperText } from '@mui/material';

const WhiteInput = withStyles({
  root: {
    color: grey[200],
  }
})(Input);

const FormExtensionTextField = (
  { input: input, meta: { asyncValidating, touched, error }, size, label, ...custom },
) => {
  return (
    <FormControl error={Boolean(touched && error)}>
      <Chip
        size={size}
        color="primary"
        style={{ color: 'white', width: '100px' }}
        label={
          <WhiteInput
            {...input}
            placeholder={label}
            id="component-extension"
            inputProps={{
              'aria-label': 'component-extension',
              'aria-describedby': "component-extension",
               style: { color: 'white' }
            }}
            {...custom}
          />
        }
      />
      <FormHelperText id="component-error-text">{touched && error ? error : null }</FormHelperText>
    </FormControl>
  )
}

export default FormExtensionTextField;



// import React from 'react';
// import { grey } from '@mui/material/colors';
// import { withStyles } from '@mui/styles';
// import { TextField, InputAdornment } from '@mui/material';
//
// import { isNumberEvent } from '../../Validators';
//
// const WhiteTextField = withStyles({
//   root: {
//     color: grey[300],
//     '& div': {
//       color: grey[300],
//       '& svg': {
//         color: grey[300],
//       }
//     },
//     '& label': {
//       color: grey[300],
//     },
//     '& label.Mui-focused': {
//       color: grey[300],
//     },
//     '& .MuiInput-underline:after': {
//       borderBottomColor: grey[300],
//     },
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: grey[300],
//       },
//       '&:hover fieldset': {
//         borderColor: grey[300],
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: grey[300],
//       },
//     },
//   },
// })(TextField);
//
// const REGEX = new RegExp('^2\\d{0,3}$');
//
// const FormExtensionTextField = (
//   { input: { onChange, ...input}, label, meta: { asyncValidating, touched, error }, helperText, themeColor, InputProps: inputProps, ...custom },
// ) => {
//   const ThemeComponent = {
//     white: WhiteTextField,
//     default: TextField,
//   }[themeColor || 'default']
//
//   const handleChange = (event) => {
//     if(REGEX.test(event.target.value)) onChange(event);
//   }
//
//   return (
//     <ThemeComponent
//       error={error && touched}
//       helperText={
//         (helperText || error && touched) &&
//           <small>{ helperText(touched, error) }</small>
//         }
//       label={label}
//       {...input}
//       {...custom}
//       onChange={handleChange}
//       InputProps={{
//         ...inputProps,
//         endAdornment: (
//           <InputAdornment position="end" style={{ visibility: asyncValidating ? 'visible' : 'hidden' }}>
//             <i className={"fas fa-spinner fa-spin"} />
//           </InputAdornment>
//         )
//       }}
//     />
//   )
// }
//
// export default FormExtensionTextField;
