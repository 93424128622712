import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';
import Button from "@mui/material/Button";

import styles from "assets/jss/components/paginationStyle.js";

const useStyles = makeStyles(createStyles(styles));

const Pagination = ({ pages: pagesProp, active: activeProp, ...props}) => {
  const classes = useStyles();
  const { onChange, color } = props;

  const [active, setActive] = React.useState(activeProp);
  const [pages, setPages] = React.useState(pagesProp);

  const handleChange = (e, prop) => {
    setActive(prop);
    onChange(e, prop);
  }

  return (
    <ul className={classes.pagination} {...props}>
      {pages.map((prop, key) => {
        const paginationLink = cx({
          [classes.paginationLink]: true,
          [classes[color]]: JSON.stringify(prop) === JSON.stringify(active) ? true : false,
          [classes.disabled]: prop.disabled
        });
        return (
          <li className={classes.paginationItem} key={key}>
            {prop.onClick !== undefined ? (
              <Button onClick={prop.onClick} className={paginationLink}>
                {prop.text}
              </Button>
            ) : (
              <Button
                onClick={(e) => handleChange(e, prop)}
                className={paginationLink}
              >
                {prop.text}
              </Button>
            )}
          </li>
        );
      })}
    </ul>
  );
}

Pagination.defaultProps = {
  color: "info"
};

Pagination.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      disabled: PropTypes.bool,
      text: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.oneOf(["PREV", "NEXT", "..."])
      ]).isRequired,
      onClick: PropTypes.func
    })
  ).isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  onChange: PropTypes.func
};

export default Pagination;
