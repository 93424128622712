import React, { createRef, useState } from 'react';
import { makeStyles, withStyles, createStyles } from '@mui/styles';
import Button from "components/CustomButtons/Button.js";
import { InputBase, ListItemText, MenuItem, Select } from '@mui/material';

import { v4 as uuidv4 } from 'uuid';

const options = Array.from(Array(301).keys()); // 0 - 300

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'inherit',
  },
  input: {
    padding: '0 !important',
    width: '50px',
    [theme.breakpoints.down("md")]: {
      width: '40px',
    },
  }
}));

function range(size, step) {
  // 305, 5 => 0-300
  return [...Array(size / step).keys()].map(i => i * step);
}

const CustomInput = withStyles((theme) => ({
  root: {
  },
  input: {
    border: 0,
    outlined: 'none',
  },
}))(InputBase);

const FormRinggroupNumbers = (
  { input: { value, onChange, onClose, onFocus, onBlur, ...input}, meta: { touched, error }, labelId, ...custom }
) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles();
  const inputRef = createRef();

  const handleChange = event => {
    if(value != event.target.value) onChange(event);
  }

  return (
    <Button color="github" round {...custom} aria-controls="simple-menu" size='sm' aria-haspopup="true" onClick={(event) => setOpen(!open)}>
      <Select
        inputRef={inputRef}
        open={open}
        onChange={handleChange}
        style={{ color: 'white', maxWidth: '80px' }}
        value={value}
        input={<CustomInput />}
        renderValue={(_value) => {
          if(_value.length === 0) return '';
          return `${_value}`;
        }}
        labelId={labelId}
        {...input}
        {...custom}
      >
        {
          range(305, 5).map(value => {
            return (
              <MenuItem key={value} value={value}>
                <ListItemText
                  primary={value + ' Seconds'}
                />
              </MenuItem>

            )
          })
        }
      </Select>
    </Button>
  );
}

FormRinggroupNumbers.defaultProps = {
  id: `select-id-${uuidv4()}`,
  labelId: `select-labelId-${uuidv4()}`,
}

export default FormRinggroupNumbers;
