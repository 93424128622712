import React, { useState, useEffect, useCallback, useReducer } from "react";

import { useWebHID } from 'Providers';

import {
  Card,
  Box,
  Avatar,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Switch,
  Stack,
  Button,
  IconButton,
  ListItemButton,
  Collapse
} from '@mui/material';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import { VisibilitySwitch } from 'components/CustomSwitch';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ExtensionIcon from '@mui/icons-material/Extension';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import StarIcon from '@mui/icons-material/Star';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import HeadsetIcon from '@mui/icons-material/Headset';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HearingIcon from '@mui/icons-material/Hearing';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


import jabra_logo from 'assets/img/jabra_logo.png';

const PairedItemComponent = ({ handleClose, disabled, webhidActions, dispatchJabraConfig, callControl, handlePairingDevice, handleDisconnect }) => {
  const [open, setOpen] = useState(() => !disabled);

  const handleClick = (e) => {
    setOpen(!open);
  };

  return (
    <div>
      <ListItemButton onClick={handleClick} disabled={disabled}>
        <ListItemIcon sx={{ justifyContent: 'center'}}>
          <HelperTooltipBootstrap title={callControl?.device?.name} placement='left' arrow>
            <Avatar alt="J" src={jabra_logo} />
          </HelperTooltipBootstrap>
        </ListItemIcon>
        <ListItemText
          primary="Headset Integration"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              ></Typography>
              <small>
                {" — enables action buttons"}
              </small>
            </React.Fragment>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            // <ListItemButton onClick={(e) => dispatchJabraConfig({ type: 'SET', payload: { jabraRingTone: !jabraRingTone }})}>
            //   <ListItemIcon sx={{ justifyContent: 'center'}}>
            //     <HearingIcon />
            //   </ListItemIcon>
            //   <ListItemText primary="Jabra Default Ringtone" />
            //   <Switch checked={jabraRingTone} />
            // </ListItemButton>
          }
          <ListItemButton
            onClick={() => {
              if(callControl) {
                setTimeout(async() => {
                  await webhidActions.takeCallLock();
                  webhidActions.stopRinger();
                  webhidActions.stopCall();
                  webhidActions.releaseCallLock();
                })
              }
            }}
          >
            <ListItemIcon sx={{ justifyContent: 'center'}}>
              <ClearIcon />
            </ListItemIcon>
            <ListItemText primary="Reset Jabra State" />
          </ListItemButton>
          {
            <ListItemButton onClick={async(e) => {
                await handleDisconnect(e);
                handleClose(e);
              }}
            >
              <ListItemIcon sx={{ justifyContent: 'center'}}>
                <AddLinkIcon />
              </ListItemIcon>
              <ListItemText primary="Disconnect Device" />
            </ListItemButton>
          }
          <ListItemButton onClick={handlePairingDevice}>
            <ListItemIcon sx={{ justifyContent: 'center'}}>
              <AddLinkIcon />
            </ListItemIcon>
            <ListItemText primary="Pairing Device" />
          </ListItemButton>
        </List>
      </Collapse>
    </div>
  );
}

const NotPairedItemComponent = ({ disabled, handlePairingDevice }) => {
  return (
    <ListItemButton onClick={handlePairingDevice} disabled={disabled}>
      <ListItemIcon sx={{ justifyContent: 'center'}}>
        <HeadsetIcon />
      </ListItemIcon>
      <ListItemText
        primary="Headset Integration"
        secondary={
          <React.Fragment>
            <Typography
              sx={{ display: 'inline' }}
              component="span"
              variant="body2"
              color="text.primary"
            ></Typography>
            <small>
              {" — Not connected!"}
            </small>
          </React.Fragment>
        }
      />
      <ArrowForwardIosIcon />
    </ListItemButton>
  )
}


const HeadsetOptions = ({ disabled, ...props}) => {
  const { callControl, ...webhidProps } = useWebHID();
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {
        Boolean(callControl)
          ? <PairedItemComponent callControl={callControl} {...webhidProps} {...props} disabled={disabled} />
          : <NotPairedItemComponent {...webhidProps} disabled={disabled} />
      }
    </List>
  );
}

export default HeadsetOptions;
