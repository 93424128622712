import React from "react";
import MuiTextField from "./MuiTextField";

const OPTIONS = Array.from({length: 31}, (_, i) => i + 1).map((item) => ({ value: `${item}`, title: `${item}` })) // [1 - 31]

const FormSelectDayOfMonth = (props) => {
  return (
    <MuiTextField
      select
      label="Day Of Month"
      style={{ minWidth: 100 }}
      options={OPTIONS}
      {...props}
    />
  )
}

export default FormSelectDayOfMonth;
