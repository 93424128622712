import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';
import Snack from "@mui/material/SnackbarContent";
import IconButton from "@mui/material/IconButton";

import { Box } from '@mui/material';

// @mui/icons-material
import Close from "@mui/icons-material/Close";

import styles from "assets/jss/components/snackbarContentStyle.js";

const useStyles = makeStyles(createStyles(styles));

export default function SnackbarContent(props) {
  const classes = useStyles();
  const { message, color, close, icon, iconStyle, boxProps } = props;
  var action = [];
  const messageClasses = cx({
    [classes.iconMessage]: icon !== undefined
  });
  if (close !== undefined) {
    action = [
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
      >
        <Close className={classes.close} />
      </IconButton>
    ];
  }
  const iconClasses = cx({
    [classes.icon]: classes.icon,
    [classes.infoIcon]: color === "info",
    [classes.successIcon]: color === "success",
    [classes.warningIcon]: color === "warning",
    [classes.dangerIcon]: color === "danger",
    [classes.primaryIcon]: color === "primary",
    [classes.roseIcon]: color === "rose"
  });
  return (
    <Snack
      message={
        <Box component='div' {...boxProps}>
          {icon !== undefined ? <props.icon className={iconClasses} style={iconStyle} /> : null}
          <span className={messageClasses}>{message}</span>
        </Box>
      }
      classes={{
        root: classes.root + " " + classes[color],
        message: classes.message
      }}
      action={action}
    />
  );
}

SnackbarContent.defaultProps = {
  color: "info"
};

SnackbarContent.propTypes = {
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    "info",
    "success",
    "warning",
    "danger",
    "primary",
    "rose"
  ]),
  close: PropTypes.bool,
  icon: PropTypes.object
};
