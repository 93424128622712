import * as React from 'react';
import { makeStyles } from '@mui/styles';
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { withAnimation } from 'HOC';

import {
  AppBar,
  Fade,
  Stack,
  Zoom
} from '@mui/material';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';

import MenuIcon from "@mui/icons-material/Menu";
import LiveHelpIcon from  '@mui/icons-material/LiveHelp';
import Fingerprint from "@mui/icons-material/Fingerprint";

import { authRoutes } from 'routes';

import styles from "assets/jss/components/authNavbarStyle.js";
const useStyles = makeStyles(styles);


// <Button
//   color="inherit"
//   component={NavLink}
//   className={cx(classes.navLink, {
//     [classes.navLinkActive]: true
//   })}
// >
//   <Fingerprint className={classes.listItemIcon} />
//   Login
// </Button>
// <Button
//   color="inherit"
//   component={NavLink}
//   to={}
//   className={cx(classes.navLink, {
//     [classes.navLinkActive]: false
//   })}
// >
//   <LiveHelpIcon className={classes.listItemIcon} />
//   Forgot Password
// </Button>

function getButtons(routes, classes) {
  return [...routes].map((item, index) => {
    const { name, route: {component, path, ...rest}} = item;
    return (
      <Button
        key={index}
        to={path}
        size="large"
        activeClassName={classes.active}
        isActive={(match, location) => {
          if (!match) {
            return false;
          } else if (match.url === location.pathname) {
            return true;
          } else {
            return false;
          }
        }}
        component={NavLink}
        {...rest}
        startIcon={item?.icon && <item.icon/>}
        style={{
          color: 'white',
        }}
      >
        {name}
      </Button>
    );
  });
}

const AuthNavbar = (props) => {
  const classes = useStyles();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0} className={classes.appBar} sx={{
        color: '#555555',
        width: '100%',
        border: '0',
        display: 'block',
        padding: '10px 0',
        zIndex: '1029',
        position: 'absolute',
        boxShadow: 'none',
        minHeight: '50px',
        transition: 'all 150ms ease 0s',
        paddingTop: '10px',
        borderBottom: '0',
        borderRadius: '3px',
        marginBottom: '0',
        backgroundColor: 'transparent',
      }}>
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Stack direction="row" spacing={1} sx={{ mr: 2 }}>
              {
                getButtons(authRoutes.filter(route => route.display.AuthNavbar), classes)
              }
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default AuthNavbar;

// {children}
