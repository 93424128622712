import React from "react";
import { styled } from '@mui/material/styles';
// import { purple } from '@mui/material/colors';
import { Divider } from '@mui/material';

export const DrawerListSectionDivider = styled(Divider)({
  fontWeight: 600,
  margin: '10px 0px',
  textAlign: "center",
  '& span': {
    display: 'flex',
  },
})

export const FriendsListSectionDividerStyled = DrawerListSectionDivider;
