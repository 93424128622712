import React, { useEffect, useState, forwardRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  Autocomplete,
  CircularProgress,
  Popper,
  Paper,
  ListItem,
  ListItemText,
  Stack,
  Chip,
  Divider,
} from '@mui/material/';

import { matchSorter } from 'match-sorter';

import { CALLNUMBER_REGEX } from 'constants/Regex';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

//import { createFilterOptions } from '@mui/material/Autocomplete';

import TelephoneChip from '../TelephoneChip';

import { TransparentTextField } from 'components/CustomInput';

import { concatString } from 'Helpers/Text';

import { service_contacts } from 'Services';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function groupBy(array = [], prop = 'title') {
  return array.map((option) => {
    const fullName = concatString([option.firstName, option.lastName]);
    const firstLetter = fullName[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      fullName,
      ...option,
    };
  });
}

const KEYS = ['firstLetter', 'fullName', 'firstName', 'job_title', 'language', 'lastName', 'notes', 'organization', 'street_address', 'website', 'id', 'emails', 'search_tags', 'preferred_telephone', 'preferred_telephone_uuid', 'telephones.0.number', 'telephones.1.number', 'telephones.2.number', 'telephones.3.number', 'telephones.4.number'];

const DialingComponent = forwardRef((props, ref) => {
  let mounted = true, searchResults = [], subscription;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const id = `DialingComponent-${uuidv4()}`;

  const loading = open && options.length === 0;

  const { onChange, value, watch, name, onSubmit, setValue, formState, placeholder, ...rest } = props;
  //watch(name); // watch for updates!

  const { add_subscriber, remove_subscriber } = service_contacts();

  const filterOptions = (options, { inputValue }) => {
    if(inputValue.charAt(0) === "0") inputValue = `+358${inputValue.substring(1)}` // check with +358 if local is used!
    searchResults = matchSorter(options, inputValue, {keys: KEYS});
    return searchResults;
  }

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    if (mounted) {
      (async () => {
        await sleep(250);
        subscription = add_subscriber(
          values => {
            if (mounted) setOptions(groupBy(values));
          }
        )
      })();
    }
  }, [loading]);

  useEffect(() => {
    return () => {
      remove_subscriber(subscription);
      mounted = false;
    }
  }, [])

  // const preventCondition = (event) => {
  //   const value = document.getElementById(id).value;
  //   // if (event.key === 'Enter') {
  //   //   if (searchResults.length > 1 && ref.current) {
  //   //
  //   //   // Prevent's default 'Enter' behavior.
  //   //   event.defaultMuiPrevented = true;
  //   //   // your handler code
  //   // }
  // }

  const handleChange = (event, rawValue, reason, target = null) => {
    if(!mounted) return;

    //console.log(searchResults)

    switch (reason) {
      case "createOption": {
        const isNumber = CALLNUMBER_REGEX.test(rawValue);

        if(searchResults.length === 1 && !isNumber) {
          // is 'Name' so get number as target!
          const { telephones, preferred_telephone } = searchResults?.[0] || {};
          target = preferred_telephone || telephones[0].number;
        } else {
          target = rawValue; // dialed target
        }
      }
      break;
      case "selectOption": {
        if(rawValue) {
          // use preffered number as target
          const { telephones, preferred_telephone } = rawValue || {};
          target = preferred_telephone || telephones[0].number;
          //target = rawValue.telephones[0].number;
        }
      }
      break;
      default:
        //"removeOption", "blur" or "clear".
        return;
    }

    if(!target || !CALLNUMBER_REGEX.test(target)) return;

    onChange(target);
    if(target && !formState.isSubmitting) onSubmit(); // try call
    if(open) setOpen(false) //close menu

    // if(reason === 'createOption' && searchResults.length === 1) {
    //   // use listItem
    //   if(searchResults[0].telephones[0].number) {
    //     rawValue = searchResults[0]; // set item as searchResults!
    //   }
    // } else if (searchResults.length > 1 && typeof rawValue === 'string') {
    //   console.log(2222)
    //   return;
    // }
    //
    // const value = typeof rawValue === 'object'
    //   ? rawValue.telephones[0].number
    //   : rawValue;
    // console.log(3333)
    // onChange(value);
    // if(value && !formState.isSubmitting) onSubmit(); // try call
  }
  const handleListVisibility = (event, value) => {
    if(mounted) {
      if(value && value.length >= 3 && !formState.isSubmitting) {
        if(!open) setOpen(true);
      } else {
        if(open) setOpen(false);
      }
    }
  }

  return (
    <Autocomplete
      ref={ref}
      id={id}
      freeSolo
      value={value}
      name={name}
      open={open}
      openOnFocus
      // autoSelect
      // onOpen={() => {
      //   setOpen(true);
      // }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={filterOptions}
      onChange={handleChange}
      onInputChange={handleListVisibility}
      PaperComponent={(props) => <Paper sx={{ mb: '10px' }} {...props} />}
      isOptionEqualToValue={(option, value) => option.fullName === value.fullName}
      getOptionLabel={(option = "") => option?.fullName || option}
      groupBy={(option) => option.firstLetter}
      options={options.length > 0 ? options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : options}
      loading={loading}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.fullName, inputValue);
        const parts = parse(option.fullName, matches);
        return (
          <ListItem {...props}>
            <ListItemText
              component='div'
              primary={
                parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))
              }
              secondary={(
                <Stack
                  component='span'
                  direction="row"
                  divider={<Divider component='span' orientation="vertical" flexItem />}
                  spacing={1}
                >
                  {
                    option.telephones.map((telephone, index) => {
                      const chipColor = (option?.preferred_telephone_uuid === telephone?.id) || (index === 0 && !option?.preferred_telephone_uuid) ? "primary" : "default"
                      return (
                        <TelephoneChip
                          key={index}
                          color={chipColor}
                          label={telephone.number}
                          icon={telephone.type}
                          onClick={(event) => {
                            event.stopPropagation();
                            setValue(name, `${telephone.number}`)
                            onSubmit();
                            setOpen(false);
                          }}
                        />
                      )
                    })
                  }
                </Stack>
              )}
            />
          </ListItem>
        )
      }}
      renderInput={({ ...params}) => {
        return (
          <TransparentTextField
            {...params}
            name={name}
            placeholder={placeholder}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              onFocus: handleListVisibility,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )
      }}
    />
  );
});

DialingComponent.defaultProps= {
  placeholder: "Enter name or number"
}

export default DialingComponent;
