import AudioSample from 'Helpers/Announcements';

export const DEFAULT_SAMPLE = new AudioSample({
  audio: null,
  file: null,
  filename: 'AudioFile1.wav',
  selected: false,
  static: true,
  text: `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-GB">
    <voice name="fi-FI-HarriNeural">
      <prosody rate="0%" pitch="0%">Hei, Olemme juuri nyt varattuja. Odottakaa pieni hetki, palvelemme teitä mahdollisimman pian.</prosody>
    </voice>
  </speak>`
});

export const AVAILABLE_SAMPLES = [
  // {
  //   audio: null,
  //   createdAt: null,
  //   file: null,
  //   filename: 'AudioFile1.wav',
  //   sampleid: "aeac0f31-4902-4795-8bad-ed6601d3a276",
  //   selected: false,
  //   static: true,
  //   text: `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-GB">
  //     <voice name="fi-FI-HeidiRUS">
  //       <prosody rate="0%" pitch="0%">Hei, Olemme juuri nyt varattuja. Odottakaa pieni hetki, palvelemme teitä mahdollisimman pian.</prosody>
  //     </voice>
  //   </speak>`
  // }
]
