import React, { useState, useEffect, forwardRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { merge } from 'lodash';

import LoadingButton from '@mui/lab/LoadingButton';

import UndoIcon from '@mui/icons-material/Undo';
import SaveIcon from '@mui/icons-material/Save';

import { sleep } from 'Helpers';

import { useFormContext } from "react-hook-form";

const FormActionButton = forwardRef(({ children, undo, delay, onSubmit, ...props}, ref) => {
  let mounted = true;
  const { control, handleSubmit, reset, getValues, formState: { isDirty, isSubmitting } } = useFormContext(); // retrieve all hook methods

  useEffect(() => {
    return () => {
      mounted = false;
    }
  }, []);

  const activeStyle = undo && isDirty;
  const disabled = undo
    ? !(isDirty && !isSubmitting)
    : (!isDirty || isSubmitting)


  async function handleClick(e) {
    if(!mounted) return;
    try {
      if(undo === true) {
        if(delay && delay > 0) await sleep(delay); // little delay for loader!
        await reset(); // reset to initialValues!
      } else {
        await onSubmit(e) // submit
      }
    } catch (e) {
      console.warn(e);
    }
  }



  return (
    <LoadingButton
      ref={ref}
      onClick={handleClick}
      loading={isSubmitting}
      disabled={disabled}
      type={undo ? "submit": "button"}
      {...merge(props, {
        variant: activeStyle ? 'outlined' : props?.variant ?? 'text',
        color: activeStyle ? 'warning' : props?.color ?? 'primary'
      })}
      startIcon={undo ? <UndoIcon /> : <SaveIcon />}
      {...props}
   >
     {children}
   </LoadingButton>
  );
});

FormActionButton.defaultProps = {
  delay: 0,
  loadingPosition: "start",
  undo: false,
};

export default FormActionButton;
