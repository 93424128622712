import { firebase, store } from 'index.js';

// import { isEmpty, uniqBy, keyBy, merge } from 'lodash';

// import { v4 as uuidv4 } from 'uuid';

// import { onError, enqueueSnackbar } from 'store/Notifier/actions';

import { ReplaySubject, combineLatest } from "rxjs";
import { map, distinctUntilChanged } from 'rxjs/operators';

// import { makeCollection, sortCollection, sortByTime, parseTypes, compare } from 'devtools/Helpers';

// ProfileAvatar component
export const service_account = function () {
  let subscriptions = [];

  const observable = firebase.authUserObservable
    .pipe(
      distinctUntilChanged(),
    );

  function add_subscriber(callback) {
    let subscription = observable.subscribe(
      callback,
      err => console.warn('service_account', err)
    );
    subscriptions.push(subscription); // add to array and return subscription! ERROR push return array.length
    return subscription;
  }


  return {
    add_subscriber: add_subscriber,
    remove_subscriber: function (subscription) {
      if(subscription) subscription.unsubscribe();
    },
  }
}


// export const firebase_unset_telephone_contact = async (id) => {
//   try {
//     if(!id) throw `id property '${id}'`;
//     await firebase.authStatePromise;
//     await firebase.contacts().child("telephones").child(id).remove();
//     console.log(id)
//   } catch (err) {
//     onError(store.dispatch, err)
//   }
// };
//
// const toObject = (array = []) => array.length > 0 ? Object.assign(array) : null;
