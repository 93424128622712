import React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const TransparentTextField = styled(TextField)({
  padding: "2px",
  '& .MuiInput-underline:after': {
    border: 0,
    display: 'none',
    content: 'none',
  },
  '& .MuiInput-underline:before': {
    border: 0,
    display: 'none',
    content: 'none',
  }
});

export {
  TransparentTextField
};
