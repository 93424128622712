import React, { useState, useEffect, forwardRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import MuiLoadingButton from '@mui/lab/LoadingButton';

import UndoIcon from '@mui/icons-material/Undo';
import SaveIcon from '@mui/icons-material/Save';

import { sleep } from 'Helpers';

import { useFormContext } from "react-hook-form";

const LoadingButton = forwardRef(({ children, ...props}, ref) => {
  const { control } = useFormContext(); // retrieve all hook methods
  const {
    onSubmit,
    handleSubmit,
    reset,
    undo,
    getValues,
    formState: { isDirty, isSubmitting },
    control: { _defaultValues },
    componentProps,
  } = props;

  let mounted = true;

  useEffect(() => {
    return () => {
      mounted = false;
    }
  }, []);


  const disabled = undo
    ? !(isDirty && !isSubmitting)
    : (!isDirty || isSubmitting)

  const [loading, setLoading] = useState(false);

  async function handleClick(e) {
    setLoading(true);
    try {
      if(Boolean(undo)) {
        await reset(); // reset to initialValues!
      } else {
        console.log('SUBMIT!')
        await onSubmit(e) // submit
      }
    } catch (e) {
      console.warn(e);
    } finally {
      await sleep(250); // little delay for loader!
      if(mounted) setLoading(false);
    }
  }

  const activeStyle = undo && isDirty;

  return (
    <MuiLoadingButton
      {...componentProps}
      ref={ref}
      onClick={handleClick}
      loading={loading}
      disabled={disabled}
      type={undo ? "submit": "reset"}
      variant={activeStyle ? 'outlined' : 'text'}
      color={activeStyle ? 'warning' : 'primary'}
      startIcon={undo ? <UndoIcon /> : <SaveIcon />}

   >
     {children}
   </MuiLoadingButton>
  );
});

LoadingButton.defaultProps = {
  undo: false,
  componentProps: {
    color: 'primary',
    loadingPosition: "start",
  }
};

export default LoadingButton;
