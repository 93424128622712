import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
// nodejs library that concatenates classes
import { makeStyles, createStyles } from '@mui/styles';
import classNames from "classnames";
import styles from "assets/jss/components/customDropdownStyle.js";

import {
  TextField,
  ListItemText,
  InputAdornment,
  Chip,
  CheckBox,
  Autocomplete,
 } from '@mui/material';

import CheckBoxOutlineBlankIcon from  '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from  '@mui/icons-material/CheckBox';

import Button from "components/CustomButtons/Button.js";

import { escapeStringRegExp } from 'devtools/Helpers';

const useStyles = makeStyles(createStyles(styles));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CallForwardRoutingAutoComplete = forwardRef(({ phonenumbers, ...props}, forwardRef) => {
  const classes = useStyles();
  const {
    value,
    prefix,
    startAdornmentProps,
    paperProps
  } = props;

  return (
    <div>
      <div>
        <Button
          ref={forwardRef}
          {...paperProps}
        >
          <Autocomplete
            freeSolo
            fullWidth
            id="free-solo-2-demo"
            disableClearable
            value={value}
            groupBy={(option) => option.category}
            options={phonenumbers}
            getOptionLabel={(option) => option?.short_number || option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <ListItemText primary={option.description} secondary={option.cc_number} />
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                  style: { minWidth: '200px' },
                  startAdornment:
                    startAdornmentProps.component &&
                      <InputAdornment {...startAdornmentProps.InputAdornmentProps}>
                        {startAdornmentProps.component}
                      </InputAdornment>
                }}
              />
            )}
          />
        </Button>
      </div>
    </div>
  );
});

// <Checkbox
//   icon={icon}
//   checkedIcon={checkedIcon}
//   style={{ marginRight: 8 }}
//   checked={selected}
// />

CallForwardRoutingAutoComplete.defaultProps = {
  prefix: "+358",
  value: ""
};

const mapStateToProps = state => ({
  phonenumbers: state.numbers.phonenumbers,
});

export default connect(
  mapStateToProps,
  null
)(CallForwardRoutingAutoComplete);
