import React from 'react';
import { styled } from '@mui/material/styles';
import {
  LinearProgress
} from '@mui/material';

export const SmoothLinearProgress = styled(LinearProgress)((props) => {
  return {
    '.MuiLinearProgress-bar': {
      transitionDuration: `${props.smooth ? props.duration : 200}ms`,
    }
  }
});
