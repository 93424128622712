import React, { useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import { Tabs, Tab, Button } from '@mui/material';

import { AutocompleteWithCheckBox } from 'components/CustomMenu';

import { Card, CardBody } from 'components/Card';

import { VIEW_OPTIONS } from 'constants/index.js';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 6px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  tab: {
    minWidth: 'inherit !important'
  }
}));


const RangeWithGridOptions = ({ onChange, values, ...props}) => {
  const classes = useStyles();

  return (
    <Card style={{ width: 'max-content', marginBottom: 0 }}>
      <CardBody style={{ padding: '8px 4px' }}>
        <Tabs
          value={values.view}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, value) => onChange(event, { view: value })}
          aria-label="disabled tabs example"
        >
          {
            VIEW_OPTIONS.map((item, index) => (
              <Tab key={index} component={Button} classes={{ root: classes.tab }} size='small' value={item} label={item} />
            ))
          }
        </Tabs>
      </CardBody>
    </Card>
  );
}

export default RangeWithGridOptions;
