import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { grey } from '@mui/material/colors';
// import { makeStyles, createStyles } from '@mui/styles';
import { Avatar, TextField, ListItemText, ListItemAvatar, InputAdornment, ListSubheader, Divider, Autocomplete } from '@mui/material';

import AccountCircle from  '@mui/icons-material/AccountCircle';
import ArrowForwardIcon from  '@mui/icons-material/ArrowForward';
import ExtensionIcon from  '@mui/icons-material/Extension';
import MusicNoteIcon from  '@mui/icons-material/MusicNote';
import AccountTreeIcon from  '@mui/icons-material/AccountTree';
import NavigateNextIcon from  '@mui/icons-material/NavigateNext';

import { isLoading } from 'devtools/Helpers';

const FormDestinationAutoCompelete = (
  { input: { onChange, onClose, name, value: valueProp, ...input}, label, value: dummy, meta: { touched, error }, customTextField, data, loading, dispatch, ...custom },
) => {
  // value needs to be (data) array element!
  const value = data.find(obj => {
    return obj.value === valueProp;
  });

  return !loading &&
    <Autocomplete
      freeSolo
      blurOnSelect
      onChange={(event, value, reason) => {
        onChange(value)
      }}
      fullWidth
      name={name}
      value={value || valueProp}
      options={data}
      loading={loading}
      groupBy={(option) => option.category}
      getOptionSelected={(option, value) => {
        return JSON.stringify(option) === JSON.stringify(value)
      }}
      getOptionLabel={(option) => {
        return option?.primary || String(option) || 'missing value'
      }}
      renderInput={({ InputProps, ...params}) => {
        return (
          <TextField
            {...params}
            label={label}
            {...customTextField}
            InputProps={{
              ...InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {
                    {
                      'Extensions': <ExtensionIcon style={{ color: grey[500] }} />,
                      'Annoucements': <MusicNoteIcon style={{ color: grey[500] }} />,
                      'Ringgroups': <AccountTreeIcon style={{ color: grey[500] }} />,
                      'Other': <NavigateNextIcon style={{ color: grey[500] }} />,
                      'default': <ArrowForwardIcon style={{ color: grey[500] }} />,
                    }[value?.category || 'default']
                  }
                </InputAdornment>
              ),
            }}
          />
        )
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          {
            option?.icon &&
              <ListItemAvatar>
                <Avatar size='small'>
                  {option?.icon || '?'}
                </Avatar>
              </ListItemAvatar>
          }
          <ListItemText primary={option.primary} secondary={option?.secondary}/>
        </React.Fragment>
      )}
      renderGroup={({ key, children, group}) => {
        return (
          <React.Fragment key={key}>
            <ListSubheader color='secondary' style={{ backgroundColor: 'white' }}>{group}</ListSubheader>
            {children}
            <Divider />
          </React.Fragment>
        )
      }}
      {...custom}
    />
}

FormDestinationAutoCompelete.defaultProps = {
  value: null,
  data: [],
};

const mapStateToProps = state => ({
  loading: state.additionals.freeswitch_destinations_loading,
  data: state.additionals.freeswitch_destinations
});

export default connect(
  mapStateToProps,
  null
)(FormDestinationAutoCompelete);
