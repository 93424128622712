import * as React from 'react';

import {
  Avatar
} from '@mui/material';


function stringToColor(string = "") {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function onlyLetters(str = "?") {
  // console.log(str, str, str, 'tää blockkaa soittoa')
  if(!str || str === "?" || str === '') return "?";
  return `${str}`.match(/[a-zA-Z]+/g)?.join(" ");
}

function getCharacters(name = "?") {
  try {
    return `${name?.split(' ')[0][0]}${name?.split(' ')?.[1]?.[0] || ""}`.toUpperCase();
  } catch (e) {
    return "?";
  }
}

function stringAvatar({ onClick, name, src, height, width, fontSize, bgcolor, sx, style, useDefault, variant, children }) {
  name = onlyLetters(name);
  return {
    onClick,
    src,
    sx: {
      bgcolor: bgcolor || (useDefault ? "#000000" : stringToColor(name)),
      textShadow: "0px 0px 2px #000000",
      boxShadow:
        src
          ? '0px 0px 5px 1px rgb(0 0 0 / 50%)'
          : '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
      fontSize,
      width: width,
      height: height,
      ...sx
    },
    style: style,
    children: children || (useDefault ? "?" : getCharacters(name)),
    variant: variant,
  };
}

const CustomAvatar = (props) => <Avatar {...stringAvatar(props)}  />;

CustomAvatar.defaultProps = {
  name: "?",
  fontSize: 14,
  width: 30,
  height: 30,
  useDefault: false,
  onClick: undefined,
  variant: 'circular',
}

export default CustomAvatar;
