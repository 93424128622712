import React, { isValidElement } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';

// core components
import Badge from "components/Badge/Badge.js";

// import { Fade } from '@mui/material';

import styles from "assets/jss/components/timelineStyle.js";

const useStyles = makeStyles(createStyles(styles));

export default function Timeline(props) {
  const classes = useStyles();
  const { stories, simple } = props;
  const timelineClass =
    classes.timeline +
    " " +
    cx({
      [classes.timelineSimple]: simple
    });
  return (
    <ul className={timelineClass}>
      {stories.map((prop, key) => {

        const panelAdditionalClasses =
          classes.timelinePanelAdditionals;

        const panelClasses =
          classes.timelinePanel +
          " " +
          cx({
            [classes.timelinePanelInverted]: prop.inverted || simple,
            [classes.timelineSimplePanel]: simple
          });
        const timelineBadgeClasses =
          classes.timelineBadge +
          " " +
          classes[prop.badgeColor] +
          " " +
          cx({
            [classes.timelineSimpleBadge]: simple
          });

        const itemClasses =
          classes.item +
          " " +
          classes[prop.classNames];

        return (
          <li
            className={itemClasses}
            key={key}
          >
            <div className={timelineBadgeClasses}>
              {
                isValidElement(prop.badgeIcon)
                  ? prop.badgeIcon
                  : <prop.badgeIcon className={classes.badgeIcon} {...prop?.badgeProp} />
              }
            </div>

            { prop.before && <div className={panelAdditionalClasses}>{prop.before}</div> }
            <div className={panelClasses} style={prop?.style}>
              {
                prop.title ? (
                  <div className={classes.timelineHeading}>
                    <Badge color={prop.titleColor}>{prop.title}</Badge>
                  </div>
                ) : null
              }

              <div className={classes.timelineBody}>{prop.body}</div>
              {prop.footerTitle ? (
                <h6 className={classes.footerTitle}>{prop.footerTitle}</h6>
              ) : null}
              {prop.footer ? <hr className={classes.footerLine} /> : null}
              {prop.footer ? (
                <div className={classes.timelineFooter}>{prop.footer}</div>
              ) : null}
            </div>
            { prop.after && <div className={panelAdditionalClasses}>{prop.after}</div> }
          </li>
        );
      })}
    </ul>
  );
}

Timeline.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  simple: PropTypes.bool
};
