import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  whiteColor
} from "assets/jss/default.js";

const badgeStyle = theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    textTransform: "uppercase",
    fontWeight: "700",
    lineHeight: "1",
    textAlign: "center",
    verticalAlign: "baseline",
    //boxShadow: `0 0 5px 1px currentColor`,
    borderRadius: '50%',
  },
  'avatar-small': {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  'avatar-large': {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  'avatar-profile': {
    height: '120px',
    width: '120px'
  },
  badge: {
    borderRadius: '50%',
    boxShadow: 'none !important',
    borderColor: successColor,
    "& > span:last-child": {
      backgroundColor: 'inherit',
      boxShadow: '0 0 6px 1px grey',
      padding: '8px',
      borderRadius: '50%',
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 3s infinite ease-in-out',
        border: `1px solid`,
        borderColor: 'inherit',
        content: '""'
      }
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.1)',
      opacity: 0,
    },
    '79%': {
      opacity: 0,
      transform: 'scale(.1)'
    },
    '80%': {
      opacity: 1,
      transform: 'scale(.1)'
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
  primary: {
    borderColor: primaryColor[0],
    backgroundColor: primaryColor[0],
    boxShadow: `0 0 5px 1px ${primaryColor[1]}`,
  },
  warning: {
    borderColor: warningColor[0],
    backgroundColor: warningColor[0],
    boxShadow: `0 0 5px 1px ${warningColor[1]}`,
  },
  danger: {
    borderColor: dangerColor[0],
    backgroundColor: dangerColor[0],
    boxShadow: `0 0 5px 1px ${dangerColor[1]}`,
  },
  success: {
    borderColor: successColor[0],
    backgroundColor: successColor[0],
    boxShadow: `0 0 5px 1px ${successColor[1]}`,
  },
  info: {
    borderColor: infoColor[0],
    backgroundColor: infoColor[0],
    boxShadow: `0 0 5px 1px ${infoColor[1]}`,
  },
  rose: {
    borderColor: roseColor[0],
    backgroundColor: roseColor[0],
    boxShadow: `0 0 5px 1px ${roseColor[1]}`,
  },
  gray: {
    borderColor: grayColor[0],
    backgroundColor: grayColor[0],
    boxShadow: `0 0 5px 1px ${grayColor[1]}`,
  }
});

export default badgeStyle;
