import { PASSWORD_REGEX } from 'constants/Regex';

export function useCountryCode(str) {
  return str.replace(/^0/, '+358');
}

// function that returns true if value is email, false otherwise
export function isLocalNumber(value) {
  var telRex = /^(040|0400|041|042|043|044|045|046|050|0500)\d{5,8}$/;
  if (telRex.test(value)) {
    return true;
  }
  return false;
}

// function that verifies if a string has a given length or not
export function isExtension(value) {
  if (new RegExp('/^(?!^0|^1\d{2}$)\d{2,5}$/').test(value)) {
    return true;
  }
  return false;
}

export function checkExtension(value) {
  if (Number(value) >= 2000 && Number(value) <= 2999) {
    return true;
  }
  return false;
}

export function validateEmail(value) {
  var regex = /^\S+@\S+\.\S+$/gm;
  if (regex.test(value)) {
    return true;
  }
  return false;
}

export function validateOldAccount(value) {
  var regex = /^\d+@gbc.fi$/gm;
  if (regex.test(value)) {
    return true;
  }
  return false;
}

export function isNumberEvent(evt) {
  evt = (evt) ? evt : window.event;
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

const verifyPassword = function (value) {
  if (PASSWORD_REGEX.test(value)) {
    return true;
  }
  return false;
}

export const required = value => (value || typeof value === 'number' ? undefined : 'Required')
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
export const maxLength15 = maxLength(15)
export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined
export const minLength2 = minLength(2)
export const minLength3 = minLength(3)
export const minLength7 = minLength(7)
export const minWavLength = min => value =>
  value && value.length < min + 4 ? `Must be ${min} characters or more` : undefined
export const minWavLength3 = minWavLength(3)

export const number = value =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
export const minValue13 = minValue(13)

export const password = value =>
    value && !PASSWORD_REGEX.test(value)
    ? 'Minimum 6 characters, at least one uppercase letter, one lowercase letter and one number'
    : undefined
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined
export const isEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined
export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined
export const phoneNumberLocal = value =>
  value && !/^0[1-9][0-9]{4,9}$/.test(value)
    ? 'Local Format example 050123456'
    : undefined

export const phoneNumberNotStartingWithZero = value =>
  value && !/^[1-9][0-9]{5,11}$/.test(value)
    ? 'invalid phone number!'
    : undefined


export const emaillinksigninValidation = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required!'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address!'
  }
  return errors
}

export {
  verifyPassword
};
