import React from "react";
import { Box } from '@mui/material';
import { FieldArray } from 'redux-form';
import { FormSpeakComponent } from 'components/Forms/Components';

const SimpleTTS = (props) => {
  const { initialValues = null } = props;
  return initialValues && <FieldArray name="text.speak.voice" component={FormSpeakComponent} {...props} />
}


export default SimpleTTS;
