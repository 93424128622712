import React, { useState, useEffect, useCallback, useReducer } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  Card,
  Box,
  Avatar,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Switch,
  Stack,
  Button,
  IconButton,
  ListItemButton,
  Collapse
} from '@mui/material';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import { VisibilitySwitch } from 'components/CustomSwitch';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ExtensionIcon from '@mui/icons-material/Extension';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import StarIcon from '@mui/icons-material/Star';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import HeadsetIcon from '@mui/icons-material/Headset';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HearingIcon from '@mui/icons-material/Hearing';

import HeadsetOptions from './HeadsetOptions';
import DNDOption from './DNDOption';
import PasswordChangeOption from './PasswordChangeOption';
import SIPDebugOption from './SIPDebugOption';

const AccountOptionsList = (props) => {
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <Divider variant="inset" component="li" />
      <DNDOption />
      <Divider variant="inset" component="li" />
      <PasswordChangeOption />
      <Divider variant="inset" component="li" />
      <HeadsetOptions {...props} disabled={true} /> 
      <Divider variant="inset" component="li" />
      <SIPDebugOption />
      <Divider variant="inset" component="li" />
    </List>
  );
}

export default AccountOptionsList;
