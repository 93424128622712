import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import { Button, Alert, AlertTitle } from '@mui/material';
// import RegularButton from 'components/CustomButtons';

const RemoteSubmitAndUndoAlertComponent = (props) => {
  const { dispatch, severity, undo, pristine, dirty, reset, submitting, form } = props;

  return dirty && <Alert
      severity={severity}
      action={
        <div>
          <Button color="inherit" size="small" disabled={pristine || submitting}
            onClick={(e) => dispatch(submit(form))}
          >
            APPLY CHANGES
          </Button>

          {
            undo &&
            <Button color="inherit" size="small" disabled={pristine || submitting}
              onClick={(e) => dispatch(reset(form))}
            >
              UNDO CHANGES
            </Button>
          }
        </div>
      }
    >
      <AlertTitle>Reminder!</AlertTitle>
      Remember to apply changes to take <strong>affect</strong>!
    </Alert>
};

RemoteSubmitAndUndoAlertComponent.defaultProps = {
  undo: true,
  severity: 'info'
};

export default connect()(RemoteSubmitAndUndoAlertComponent)
