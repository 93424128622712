import React, { useContext, createContext, useState } from "react";
import { firebase, store } from "index.js";

// let isAuthenticated = Promise.resolve(false);
//


const authContext = createContext();

export function useAuth() {
  return useContext(authContext);
}

export function ProvideAuth({ children }) {
  const [authUser, setAuthUser] = useState(true);
  // if loaded then
  // firebase.auth.onAuthStateChanged(authUser => {
  //   if(authUser) {
  //     setAuthUser(authUser);
  //   }
  // })

  const auth = useProvideAuth(authUser);

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}


function useProvideAuth(state) {
  const [user, setUser] = useState(state);

  console.log(user)

  return {
    user,
    doSignInWithEmailAndPassword: async (email, password, cb) => {
      return firebase.doSignInWithEmailAndPassword(`${email}`, `${password}`)
        .then((user) => {
          if(user) setUser(user);
          cb(user);
        })
    },
    doSignInWithCustomToken: (token, cb) => {
      return firebase.doSignInWithCustomToken(`${token}`)
        .then((user) => {
          if(user) setUser(user);
          cb(user);
        })
    },
    signout: (cb) => {
      setUser(null);
      return firebase.doSignOut()
        .then((user) => {
          setTimeout(cb, 100); // fake async
        })
    },
    // promises
    doPasswordReset: (email) => {
      return firebase.doPasswordReset(email);
    },
    doPromiseSignInWithEmailAndPassword: (email, password) => {
      return firebase.doSignInWithEmailAndPassword(`${email}`, `${password}`)
        .then((user) => {
          if(user) setUser(user);
          return user;
        })
    },
    doPomiseSignInWithCustomToken: (token) => {
      return firebase.doSignInWithCustomToken(`${token}`)
        .then((user) => {
          if(user) setUser(user);
          return user;
        })
    }
  }
}
