import React, { useState } from 'react';
import { ToggleButtonGroup } from '@mui/material';
import { GreyToggleButton } from 'components/CustomButtons';

const FormButtonGroup = (
  { input: { value, name, onChange}, label, meta: { touched, error }, options, allowEmpty, ...custom },
) => {
  const handleChange = (event, newValue) => {
    if(allowEmpty) {
      onChange(newValue);
    } else if (newValue !== null) {
      onChange(newValue);
    } else {

    }
  };

  return (
    <ToggleButtonGroup
      style={{ width: 'max-content' }}
      value={value}
      name={name}
      onChange={handleChange}
      size='small'
      exclusive
      aria-label="text alignment"
    >
      {
        options.map((option, index) => (
          <GreyToggleButton key={index} value={option.value} aria-label={option.label}>
            {option.label || "Label Missing!"}
          </GreyToggleButton>
        ))
      }
    </ToggleButtonGroup>
  );
}

FormButtonGroup.defaultProps = {
  allowEmpty: false
};

export default FormButtonGroup;
