import React from 'react';
import { Switch, FormGroup, FormControl, FormLabel, FormControlLabel, FormHelperText } from '@mui/material';

const FormSwitchx = (
  { input: { value, ...rest}, label, meta: { touched, error }, useTheme, formControlLabelProps, ...custom },
) => {
  const Component = useTheme || Switch;

  return (
    <FormControlLabel
      label={label}
      {...formControlLabelProps}
      control={<Component {...custom} checked={Boolean(value)} {...rest} />}
    />

  );
}

FormSwitchx.defaultProps = {
  formControlLabelProps: {
    value: 'start',
    labelPlacement: 'start'
  }
}

export default FormSwitchx;
