import { firebase, store } from 'index';
import { isEmpty } from 'lodash';

import { onError, enqueueSnackbar } from 'store/Notifier/actions';

// CAHNGE PASSWORD
export const change_password = async (newPassword) => {
  if (!newPassword) throw 'newPassword missing';
  await firebase.authStatePromise;
  return await firebase.doUpdatePassword(newPassword);
}
