import React, { useEffect } from 'react';
import { grey } from '@mui/material/colors';
import { connect } from 'react-redux';
// import { makeStyles, createStyles } from '@mui/styles';
import { Avatar, TextField, ListItemText, ListItemAvatar, InputAdornment, Autocomplete } from '@mui/material';

import MusicNoteIcon from  '@mui/icons-material/MusicNote';

import { isLoading } from 'devtools/Helpers';

const FormAnnoucementsAutocompelete = (
  { input: { onChange, onClose, name, value, ...input}, label, meta: { touched, error }, customTextField, data, loading, dispatch, ...custom },
) => {
  return !loading &&
    <Autocomplete
      blurOnSelect
      onChange={(event, value, reason) => {
        onChange(value)
      }}
      fullWidth
      name={name}
      value={value}
      options={data}
      loading={loading}
      groupBy={(option) => option.category}
      getOptionSelected={(option, value) => {
        return JSON.stringify(option.recording_filename) === JSON.stringify(value)
      }}
      getOptionLabel={(option) => {
        return String(option?.recording_description || option)
      }}
      renderInput={({ InputProps, ...params}) => {
        return (
          <TextField
            {...params}
            label={label}
            {...customTextField}
            InputProps={{
              ...InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <MusicNoteIcon style={{ color: grey[500] }} />
                </InputAdornment>
              ),
            }}
          />
        )
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <ListItemAvatar>
            <Avatar size='small'>
              <MusicNoteIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={option.recording_description} />
        </React.Fragment>
      )}
      {...custom}
    />
}

const mapStateToProps = state => ({
  loading: state.announcements.loading,
  data: (function(){
    return [
      { recording_description: 'None', value: 'null', category: 'Other' },
      ...state.announcements.announcements.map(item => ({ ...item, category: 'Announcements' }))
    ];
  })(),
});

export default connect(
  mapStateToProps,
  null
)(FormAnnoucementsAutocompelete);
