import React, { useState } from 'react';
import { connect } from 'react-redux';

import MoreVertIcon from  '@mui/icons-material/MoreVert';
import CloseIcon from  '@mui/icons-material/Close';
import ArrowForwardIosIcon from  '@mui/icons-material/ArrowForwardIos';

import { IconButton, Menu, MenuItem, ListSubheader, Divider, ListItemIcon } from '@mui/material';

import { passwordReset, sendSigninLink } from 'store/actions';
import { deleteProfilesAction } from 'store/profile/actions';

const actions = {
  'Send Signin Link': sendSigninLink,
  'Send Password Reset': passwordReset
}

const ProfileActionsMenu = ({ dispatch, profile, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const doAction = async (operation) => {
    try {
      dispatch(actions[operation](profile.email));
    } catch (e) {
      console.log(e);
    } finally {
      handleClose(); // close menu!
    }
  }


  return profile &&
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="profile-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ListSubheader component="div" id="nested-list-subheader">Send Email Action</ListSubheader>
        {
          // <MenuItem
          //   dense
          //   onClick={event => doAction('Send Signin Link')}
          // >
          //   <ListItemIcon style={{ minWidth: 40 }}>
          //     <ArrowForwardIosIcon />
          //   </ListItemIcon>
          //   Signin Link
          // </MenuItem>
        }

        <MenuItem
          dense
          onClick={event => doAction('Send Password Reset')}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <ArrowForwardIosIcon />
          </ListItemIcon>
          Password Reset
        </MenuItem>
        <Divider />
        <MenuItem
          dense
          onClick={event => dispatch(deleteProfilesAction([profile]))}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <CloseIcon />
          </ListItemIcon>
          Delete Account
        </MenuItem>
      </Menu>
    </div>
}

export default connect()(ProfileActionsMenu)
