import { firebase, store } from 'index.js';

import { isEmpty } from 'lodash';

import { onError, enqueueSnackbar } from 'store/Notifier/actions';

import { uniqBy } from "lodash";

import { ReplaySubject, combineLatest } from "rxjs";
import { map, distinctUntilChanged } from 'rxjs/operators';

import { makeCollection, sortCollection, sortByTime, parseTypes, compare } from 'devtools/Helpers';

import moment, { humanize } from 'Helpers/Momentjs';

import { serviceCleanup } from './index';

// const organizationsRef = function () {
//   return firebase.contacts().child('organizations');
// }

export const service_tasklist = function () {
  const observable = new ReplaySubject();

  const subscriptions = [];

  console.log(moment().subtract(1, 'm'))

  const refs = [
    firebase.doFirebaseObservableQuery(firebase.missedcalls().orderByChild("timestamp").startAt(Number(moment().subtract(1, 'month').format('x'))), "on", "value", observable, true), // true for collection
    //firebase.doFirebaseObservableQuery2(firebase.missedcalls().orderByChild("aika").endAt(moment().subtract(1, 'm').format('YYYY-MM-DD')), "on", "value", observable, true) // true for collection
  ];

  // .once("value").then((snapshots) => {
  //   snapshots.forEach((snapshot, i) => {
  //     console.log(snapshot.val())
  //   });
  // })

  //console.warn("account path:", arr.reverse().join('/'));

  const observable$ = observable
    .pipe(
      // map((contacts) => {
      //   return uniqBy(contacts, 'name');
      // }),
      distinctUntilChanged(),
    );

  function add_subscriber(callback) {
    return subscriptions.push(
      observable$.subscribe(
        callback,
        err => console.error('service_tasklist', err)
      )
    ); // add to array and return subscription!
  }

  return {
    add_subscriber: add_subscriber,
    remove_subscriber: (subscription) => serviceCleanup(subscription, refs, subscriptions),
  }
}

// export const update_organization = async (id, newData = {}) => {
//   if(isEmpty(newData))
//     throw 'data missing';
//   else if (!id)
//     throw 'id missing';
//
//   try {
//     await firebase.authStatePromise;
//     return await firebase.transaction(organizationsRef().child(id), function(data = {}) {
//       return Object.assign(data, newData);
//     })
//   } catch (err) {
//     console.log(err);
//     onError(store.dispatch, err?.response ? await err.response.text() : err);
//   }
//   return;
// }
//
// export const remove_organization = async (id) => {
//   if(!id) throw 'id missing';
//
//   try {
//     await firebase.authStatePromise;
//     return await organizationsRef().child(id).remove();
//   } catch (err) {
//     console.log(err);
//     onError(store.dispatch, err?.response ? await err.response.text() : err);
//   }
//   return;
// }
//
// export const organization_check_value = async (prop, value) => {
//   if(!prop)
//     throw 'prop missing';
//   else if (!value)
//     return false;
//
//   try {
//     await firebase.authStatePromise;
//     return organizationsRef().orderByChild(prop).equalTo(value).once('value')
//       .then((snapshots) => {
//         return snapshots.exists();
//       })
//   } catch (err) {
//     console.log("organization_check_value", err)
//   }
//   return false;
// }
