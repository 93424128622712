import React, { forwardRef } from 'react';
import { Checkbox, TextField, Autocomplete } from '@mui/material';

import CheckBoxOutlineBlankIcon from  '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from  '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutocompleteWithCheckBox = forwardRef(({ data, inputRef, inputProps, ...props},  ref) => {
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={data || []}
      disableCloseOnSelect
      getOptionLabel={(option) => option?.value || ''}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.value}
        </>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...inputRef} {...params} {...inputProps} />
      )}
      {...props} // value, onChange
    />
  );
});

export default AutocompleteWithCheckBox;

// value={value}
// onChange={(event, value, reason) => onChange(event, value, reason)}
