import React from "react";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { HelperTooltipBootstrap } from "components/CustomTooltips";

import {
  Stack,
} from '@mui/material';

import {
  useNavigate,
} from "react-router-dom";

const CustomToolbar = ({ mediaQuery }) => {
  const { isXlScreen } = mediaQuery;
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <HelperTooltipBootstrap title="New Contact" placement='top'>
        <IconButton
          size={isXlScreen ? 'medium' : 'small'}
          onClick={(e) => navigate('/contacts/new')}
        >
          <AddCircleIcon fontSize="inherit" />
        </IconButton>
      </HelperTooltipBootstrap>
    </React.Fragment>
  );
}

export default CustomToolbar;
