import React, { useEffect, useState, useRef } from 'react';

import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

// import LineChart from './LineChart';
// import BarChart from './BarChart';

export const CustomChartJS = (props) => {
  const chartRef = useRef(null);
  // const [chartData, setChartData] = useState({
  //   datasets: [],
  // });

  const { id, data, options } = props;

  useEffect(() => {
    const chart = chartRef.current;

    if (chart) {
      // setChartData({
      //   datasets: [{
      //       backgroundColor: createBackgroundGradient(chart.ctx),
      //       // ...
      //   }]
      // });
    }
  }, []);

  return (
    <Chart
      ref={chartRef}
      // onClick={(event) => {
      //   const dataset = getDatasetAtEvent(chartRef.current, event);
      //   const element = getElementAtEvent(chartRef.current, event);
      //   const elements = getElementsAtEvent(chartRef.current, event);
      // }}
      {...props}
    />
  )
}

export default CustomChartJS;
