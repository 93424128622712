import moment from 'Helpers/Momentjs';

export const ARRAY_YEARS = (format = 'YYYY', length = 10) => {
 const years = []
 const dateStart = moment()
 const dateEnd = moment().add(length, 'y')
 while (dateEnd.diff(dateStart, 'years') >= 0) {
   years.push(dateStart.format(format))
   dateStart.add(1, 'year')
 }
 return years
}

export const ARRAY_MONTHS = (format = 'M', length = 12) => {
 const months = []
 const dateStart = moment()
 const dateEnd = moment().add(length, 'month')
 while (dateEnd.diff(dateStart, 'months') >= 0) {
  months.push(dateStart.format(format))
  dateStart.add(1, 'month')
 }
 return months
}

export const ARRAY_DAYS = (format = 'D', length = 30) => {
 const days = []
 const dateStart = moment()
 const dateEnd = moment().add(length, 'days')
 while (dateEnd.diff(dateStart, 'days') >= 0) {
  days.push(dateStart.format(format))
  dateStart.add(1, 'days')
 }
 return days
}
