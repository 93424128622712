import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { grey } from '@mui/material/colors';
//import { withFirebase } from '../../Firebase';

import { ThemeProvider } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";

import { merge } from "lodash";

// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';

import { Card, CardHeader, CardBody } from "components/Card";

import {
  Box,
  Chip,
  Avatar,
  IconButton,
  TableRow,
  CircularProgress,
  Stack,
  Fab,
  Grid,
  Checkbox,
} from '@mui/material';

import CallIcon from  '@mui/icons-material/Call';
import PhoneMissedIcon from  '@mui/icons-material/PhoneMissed';
import DnsIcon from  '@mui/icons-material/Dns';
import UndoIcon from  '@mui/icons-material/Undo';
import AccountCircleIcon from  '@mui/icons-material/AccountCircle';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import { CallButton } from "components/CustomFabs";
import { CustomAvatarWithBadge } from 'components/CustomAvatar';
import { CustomAvatar } from 'components/CustomAvatars';
import { SimpleLoader } from 'components/Loaders';

import { HandledMissedCallsCustomToolBarSelect } from 'components/DataTables';
import { LoadingStateComponent } from 'components/LoadingStates';

import { ContactPopoverClass } from 'Classes';

import ContactChip from './ContactChip';
import { ContactPopover } from 'WEBRTC/components/Popovers';

import ErrorBoundary from 'components/ErrorBoundary';

import { usePhone, useDropDown, withDropDown } from 'Providers';

import { missedCallAction } from 'store/missedcalls/actions';

import { isLoading } from 'devtools/Helpers';

import moment, { humanize } from 'Helpers/Momentjs';

import { getMUIDataTableTheme, getMUIDataTableOptions } from 'constants/MUIDataTable';

import { service_missedcalls_handled } from 'Services';

import useSingleAndDoubleClick from 'Contexts/useSingleAndDoubleClick';


import styles from "assets/jss/components/missedCallsStyle";
const useStyles = makeStyles(createStyles(styles));

function compare(a,b) {
  if (a < b) {
    return -1;
  } else if  (a > b) {
    return 1;
  } else {
    return 0;
  }
}

function getTelNumber(str) {
  return `${str}`.match(/\+?\d+$/)?.pop() || null;
}

const HandledCalls = withDropDown(({ dispatch, ...props }) => {
  let mounted = true;
  const { invite } = usePhone();
  const classes = useStyles();
  const { handleClick } = useDropDown();
  const { data, isLoading } = props;

  const options = merge(getMUIDataTableOptions('missedcalls', data.length), {
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <HandledMissedCallsCustomToolBarSelect data={data} selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} />
    )
  })

  useEffect(() => {
    return () => mounted = false;
  }, [])

  const handleClicks = useSingleAndDoubleClick(onSingleClick, onDoubleClick);

  function call(target, contact, data) {
    if(mounted) {
      invite(getTelNumber(target), contact, { audio: true, video: false });
      dispatch(missedCallAction([data], { handled: 1, ok: 'Valmis' }))
    }
  }

  function onSingleClick(event, target, contact, data) {
    if(!mounted || !target) return;
    return;
  }

  function onDoubleClick(event, target, contact, data) {
    if(!mounted || !target) return;
    call(target, contact, data);
  }


  const columns = [
    {
     name: "id",
     options: {
       //display: true,
       display: 'excluded',
       filter: false,
     }
    },
    {
      name: "caller_id_number",
      label: "From",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const dataset = data[tableMeta.currentTableData[tableMeta.rowIndex].index];
          const { caller_id_name, caller_id_number, soittaja, contact_uuid, id } = dataset;
          return (
            <ContactChip
              key={id + contact_uuid} // prevent rerenders on same key and unmounts!
              id={contact_uuid}
              title={(contact) => contact?.fullName || caller_id_name || caller_id_number || soittaja}
              onAvatarClick={(event, contact) => handleClick(new ContactPopoverClass({ caller_id_name, caller_id_number, contact }))(event)}
            >
              {
                (contact, isLoading) => {
                  const useName = contact?.fullName || (caller_id_name && caller_id_number !== caller_id_name);
                  return (
                    <span>
                      { useName && <div style={{ fontWeight: 500 }}>{contact?.fullName || caller_id_name}</div>}
                      <a style={{ fontWeight: 500 }} onClick={event => handleClicks(event, caller_id_number || soittaja, contact, dataset)}>{caller_id_number || soittaja}</a>
                    </span>
                  )
                }
              }
            </ContactChip>
          )
        },
        filter: true,
        sort: true,
      }
    },
    {
      name: "soitettu",
      label: "To",
      options: {
        filter: true,
        sort: true,
      }
    },
   {
    name: "aika",
    label: "Datetime",
    options: {
      setCellHeaderProps: function (columnMeta) {
        return {
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          }
        }
      },
      setCellProps: function (cellValue, rowIndex, columnIndex) {
        return {
          style: {
            textAlign: 'center'
          },
        }
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        return value && (
          <HelperTooltipBootstrap title={value ?? ''} placement='bottom' aria-label="aika" arrow>
            <span>
              <Chip
                size='small'
                label={humanize(value, "DD-MM-YYYY HH:mm:ss")}
                sx={{
                  minWidth: '100px',
                  fontSize: '10px'
                }}
              />
            </span>
          </HelperTooltipBootstrap>
        )
      },
      filter: false,
      sort: false,
    }
   },
   {
     name: "handledperson",
     options: {
       display: 'excluded',
     }
   },
   {
     name: "contact_uuid",
     options: {
       display: 'excluded',
     }
   },
   {
     name: "handled_contact_uuid",
     options: {
       display: 'excluded',
     }
   },

   {
    name: "handledtime",
    label: "Handled By",
    options: {
      setCellHeaderProps: function (columnMeta) {
        return {
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          }
        }
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        const dataset = data[tableMeta.currentTableData[tableMeta.rowIndex].index];
        const { handledtime, handledperson, handled_contact_uuid, id } = dataset;

        return handled_contact_uuid
          ? <ContactChip
              key={"handledtime"+id}
              id={handled_contact_uuid}
              title={(contact) => `${contact?.fullName || handledperson} - ${handledtime}`}
            >
              {humanize(handledtime, "DD-MM-YYYY HH:mm:ss")}
            </ContactChip>
          : <ContactChip
              key={"handledtime"+id}
              title={handledperson?.username}
              contact={{
                fullName: handledperson?.username,
                image: handledperson?.image,
                avatar: {
                  bgcolor: handledperson?.avatarColor
                }
              }}
            >
              {humanize(handledtime, "DD-MM-YYYY HH:mm:ss")}
            </ContactChip>
      },
      filter: true,
      sort: true,
    }
   },
   {
    name: "actions",
    label: "Actions",
    filter: false, // Display column in filter list.
    options: {
      setCellHeaderProps: function (columnMeta) {
        return {
          style: {
            textAlign: 'center'
          }
        }
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        const dataset = data[tableMeta.currentTableData[tableMeta.rowIndex].index];
        const { caller_id_number, soittaja, id } = dataset;

        return (
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <LoadingStateComponent>
              {
                ({ ref, loading, handleClickPromise, ...rest }) => (
                  <HelperTooltipBootstrap title={caller_id_number || soittaja} placement='left'>
                    <span>
                      <Fab
                        ref={ref}
                        size="small"
                        color='primary'
                        aria-label="Call"
                        disabled={loading}
                        sx={{ width: 36, height: 36, zIndex: "auto" }}
                        onClick={(e) => {
                          invite(getTelNumber(caller_id_number || soittaja), undefined, { audio: true, video: false}); // contact...
                        }}
                        // onClick={
                        //   handleClickPromise(async(e) => {
                        //     return await new Promise((resolve, reject) => {
                        //       invite(
                        //         getTelNumber(caller_id_number || soittaja),
                        //         { audio: true, video: false },
                        //         {
                        //           onAccepted: resolve(),
                        //           onFailed: reject(),
                        //         }
                        //       )
                        //     })
                        //   })
                        // }
                      >
                        {
                          loading
                            ? <CircularProgress color="inherit" size={16} />
                            : <CallIcon />
                        }
                      </Fab>
                    </span>
                  </HelperTooltipBootstrap>
                )
              }
            </LoadingStateComponent>

            <LoadingStateComponent>
              {
                ({ ref, loading, handleClickPromise, ...rest }) => (
                  <HelperTooltipBootstrap title="Undo Action" placement='right' arrow>
                    <IconButton
                      ref={ref}
                      onClick={
                        handleClickPromise(async(e) => {
                          return await dispatch(missedCallAction([dataset], { handled: 0, ok: 'Odottaa' }))
                        })
                      }
                    >
                      {
                        loading
                          ? <CircularProgress color="inherit" size={16} />
                          : <UndoIcon />
                      }

                    </IconButton>
                  </HelperTooltipBootstrap>
                )
              }
            </LoadingStateComponent>
          </Stack>
        )
      },
      filter: false,
      sort: false,
    }
   }
  ];

  return (
    <Card elevation={8}>
      <CardHeader color="success">
        <h4 className={classes.cardTitleWhite}>Handled Missed Calls</h4>
        <p className={classes.cardCategoryWhite}>
          Table shows list of most recent handled calls with limit to <b>100</b> rows.
        </p>
      </CardHeader>
      <CardBody noPadding style={{ overflow: 'hidden' }}>
        <ErrorBoundary>
          <ThemeProvider theme={getMUIDataTableTheme('missedcalls')}>
            {
              isLoading
                ? (
                    <Grid container justifyContent="center" alignItems='center' spacing={3}>
                      <Grid item>
                        <Box p={2}>
                          <CircularProgress color='secondary' />
                        </Box>
                      </Grid>
                      <Grid item>
                        <b>Table Loading...</b>
                      </Grid>
                    </Grid>
                  )
                : (
                    <MUIDataTable
                      data={data}
                      columns={columns}
                      options={options}
                      components={{
                        Checkbox: (props) => <Checkbox size='small' {...props} /> ,
                        Tooltip: (props) => {
                          return <HelperTooltipBootstrap {...props} />
                        },
                      }}
                    />
                  )
            }
          </ThemeProvider>
        </ErrorBoundary>
      </CardBody>
    </Card>
  );
}, ContactPopover);

const mapStateToProps = state => ({
  // calls: state.missedcalls.handled
  //   .filter(call => Moment.command(moment => moment(call?.handledtime, "DD-MM-YYYY").isAfter( Moment.command(moment => moment().subtract(30, 'days')) )))
  //   .sort((a, b) => Moment.command(moment => compare(moment(b.handledtime, 'DD-MM-YYYY HH:mm:ss'), moment(a.handledtime, 'DD-MM-YYYY HH:mm:ss')))),
  // calls: state.missedcalls.handled_100rows || [],
  // users: state.users.users,
  // loading: isLoading([state.missedcalls.loading,state.firebase.loading])
});

// const mapDispatchToProps = dispatch => ({
//   markUncomplete: (id, args) => dispatch(missedCallAction(id, args))
// });

const logicalComponent = (Component) => (props) => {
  let mounted = true, subscription;
  const [data, setData] = useState(null);
  const { add_subscriber, remove_subscriber } = service_missedcalls_handled();

  useEffect(() => {
    (async() => {
      if(mounted) {
        subscription = add_subscriber(
    			value => {
            if(mounted && value) setData(value)
    			},
    			err => console.warn('HandledCalls', err)
    		)
      }
    })();

    return () => {
      mounted = false;
      remove_subscriber(subscription);
    }
  }, [])

  return <Component {...props} data={data ?? []} isLoading={!Array.isArray(data)} />
}

export default connect(
  mapStateToProps,
  null,
)(logicalComponent(HandledCalls))
