import { firebase, store } from 'index.js';

import { isEmpty, groupBy } from 'lodash';

import { onError, enqueueSnackbar } from 'store/Notifier/actions';

import { uniqBy } from "lodash";

import { ReplaySubject, combineLatest } from "rxjs";
import { map, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { makeCollection, sortCollection, sortByTime, parseTypes, compare } from 'devtools/Helpers';

import { serviceCleanup } from './index';

export const service_organization_contacts = function (id) {
  const observable_general$ = new ReplaySubject();
  const observable_emails$ = new ReplaySubject();
  const observable_telephones$ = new ReplaySubject();
  const observable_search_tags$ = new ReplaySubject();

  const subscriptions = [];

  const refs = [
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("general").orderByChild('organization_uuid').equalTo(id), "on", "value", (snapshot) => observable_general$.next(makeCollection(parseTypes(snapshot.val(), true)))),
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("emails"), "on", "value", (snapshot) => observable_emails$.next(snapshot.toJSON())),
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("telephones"), "on", "value", (snapshot) => observable_telephones$.next(groupBy(makeCollection(parseTypes(snapshot.val(), true)), "contact"))),
    firebase.doFirebaseObservableQuery2(firebase.contacts().child("search_tags"), "on", "value", (snapshot) => observable_search_tags$.next(snapshot.toJSON())),
  ];

  const observable$ = combineLatest(observable_general$, observable_emails$, observable_telephones$, observable_search_tags$)
    .pipe(
      map(([general, emails, telephones, search_tags]) => {
        return general.map(item => {
          return {
            ...item,
            emails: Object.values(emails?.[item.id] ?? {}) ?? [],
            telephones: Object.values(telephones?.[item.id] ?? {}) ?? [],
            search_tags: Object.values(search_tags?.[item.id] ?? {}) ?? [],
          }
        })
      }),
      distinctUntilChanged(),
    );

  function add_subscriber(callback) {
    return subscriptions.push(
      observable$.subscribe(
        callback,
        err => console.error('service_organization', err)
      )
    ); // add to array and return subscription!
  }

  return {
    add_subscriber: add_subscriber,
    remove_subscriber: (subscription) => serviceCleanup(subscription, refs, subscriptions),
  }
}

// actions --> service_organizations
