import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
//import { announcementsActions } from 'store/announcements/actions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AnnouncementPicker = ({ handleChange, selected, announcements, getAnnouncements, ...props }) => {
  const classes = useStyles();
  const {
    label
  } = props;

  const [announcement, setAnnouncement] = React.useState(selected || 'null');

  useEffect(() => {
    getAnnouncements();
  }, []);

  useEffect(() => {
    if(selected) setAnnouncement(selected);
  }, [selected]);

  useEffect(() => {
    const target = announcements.filter((item, index) => item.recording_filename === selected);
    if(target.lenght > 0) setAnnouncement(target[0]);
  }, [announcements]);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-helper-label"><small>{label}</small></InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={announcement}
        onChange={(e) => {
          handleChange(e);
          setAnnouncement(e.target.value);
        }}
      >
        <MenuItem value={'null'}><em>None</em></MenuItem>

        { announcements.map((row, index) => (
          <MenuItem value={row.recording_filename} key={index}>{ row.recording_description }</MenuItem>
        ))}

        <MenuItem value={selected}><em>{selected}</em></MenuItem>
      </Select>
    </FormControl>
  );
};

const mapStateToProps = state => ({
  announcements: state.announcements_State.announcements
});

const mapDispatchToProps = dispatch => ({
  getAnnouncements: () => {
    //dispatch(announcementsActions())
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementPicker);
