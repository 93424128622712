import React, { useState } from 'react'
import { connect } from 'react-redux';

import { withMediaQuery } from 'HOC';

// import GridItem from "components/Grid/GridItem.js";
// import GridContainer from "components/Grid/GridContainer.js";

import { MISSEDCALLS_RANGE_OPTIONS, GRID_OPTIONS, VIEW_OPTIONS_NEW, STATE_OPTIONS_NEW, DIRECTION_OPTIONS_NEW } from 'constants/index.js';

import { Collapse, Grid, Box } from '@mui/material';

import { Card, CardBody } from 'components/Card';

import { Form, Field, change, reduxForm, SubmissionError, formValueSelector } from 'redux-form';

import { FabPicker } from 'components/CustomFabs';
import RegularButton from 'components/CustomButtons';
import { FormCardButtonGroup, FormCardMissedCallsDatasetAutoComplete, FormDateField } from 'components/Forms/Components';

// import { applyFilter } from 'store/reducers/filters';
import { applyFilter } from 'store/actions';
import { sleep } from 'Helpers';

const FORM = 'MissedCallsChartsFilters';

const ChartFilters = ({ dispatch, setLoading, startDate, ...props}) => {
  const { handleSubmit, pristine, dirty, reset, submitting, change, form, initialValues = null} = props;

  //console.log('initialValues', initialValues)
  //if(isTabletOrMobile) return null; // bugaa

  async function submit(formvalues) {
     try {
       return dispatch(applyFilter('missedcalls', { dirty: true, ...formvalues }));
     } catch (err) {
       throw new SubmissionError({
         submit: 'Submiting Error',
         _error: err,
       });
     } finally {
       //reset();
       // setTimeout(() => setLoading(false), 500);
     }
  }

  //console.log(initialValues.selectedDatasets)

  return initialValues &&
    <Form onSubmit={handleSubmit(submit)} autoComplete='off'>
      <Grid container justifyContent="center" spacing={2}>
         <Grid item>
           <Box p={1} width='min-content'>
             <Field
               type='select-multiple'
               component={FormCardMissedCallsDatasetAutoComplete}
               label="Datasets"
               name='selectedDatasets'
               freeSolo
               multiple
               disableCloseOnSelect
             />
           </Box>
         </Grid>

         <Grid item>
           <Box p={1} width='max-content'>
             <Card noMargin>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item>
                    <Field
                      component={FormDateField}
                      variant="outlined"
                      label="Time Range Start"
                      name='range.start'
                      parse={(moment, name) => moment && moment.startOf('day')}
                      disableFuture
                    />
                  </Grid>

                  <Grid item>
                    <Field
                      component={FormDateField}
                      variant="outlined"
                      label="Time Range End"
                      minDate={startDate || initialValues.range?.start}
                      name='range.end'
                      parse={(moment, name) => moment && moment.endOf('day')}
                      disableFuture
                    />
                  </Grid>

                  <Grid item>
                    <FabPicker
                      options={MISSEDCALLS_RANGE_OPTIONS}
                      onChange={(newValue) => {
                        change('range.start', newValue.start.startOf('day'))
                        change('range.end', newValue.end.endOf('day'))
                      }}
                    />
                  </Grid>
                </Grid>
              </CardBody>
             </Card>
            </Box>
         </Grid>

         <Grid item>
           <Box p={1} width='min-content'>
             <Field
               component={FormCardButtonGroup}
               options={GRID_OPTIONS}
               label="Layout"
               name='gridLayout'
               onChange={(newValue) => {
                 change('gridLayout', newValue)
                 console.log(newValue)
               }}
             />
           </Box>
         </Grid>

         <Grid item>
           <Box p={1} width='min-content'>
             <Field
               component={FormCardButtonGroup}
               options={VIEW_OPTIONS_NEW}
               label="Chart View"
               name='view'
             />
           </Box>
         </Grid>

         <br />
       </Grid>

       <Collapse in={dirty && !pristine}>
         <Grid container justifyContent="center" spacing={1}>
           <Grid item>
             <RegularButton
               type="submit"
               size='sm'
               color="success"
               round
               disabled={pristine || submitting}
             >
               <Box pr={1} display={submitting ? 'block' : 'none' }>
                 <i className={"fas fa-spinner fa-spin"} />
               </Box>
               Apply Changes
             </RegularButton>

           </Grid>
           <Grid item>
             <RegularButton
               type="button"
               size='sm'
               color="warning"
               round
               disabled={pristine || submitting}
               onClick={reset}
             >
               Undo Changes
             </RegularButton>
           </Grid>
         </Grid>
       </Collapse>
    </Form>
}



const selector = formValueSelector(FORM);
const mapStateToProps = state => {
  return {
    startDate: selector(state, 'range.start'),
    initialValues: state.formState[FORM],  // pull initial values from form reducer
  }
}


// const mapDispatchToProps = dispatch => ({
//
//   change,
// });

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: FORM, // a unique identifier for this form
    enableReinitialize: true,
    destroyOnUnmount: true,
    // onSubmit: () => {},
    // validate,
    // asyncValidate,
  })(ChartFilters)
)
