import { serviceCleanup } from './index';

export default class CleanupService {
  constructor() {
    this.refs = [];
    this.subscriptions = [];
  }

  firebaseRefs(refs) {
    this.refs = refs;
  }

  remove_subscriber(subscription) {
    return this.#cleanup(subscription);
  }

  #cleanup(subscription) {
    try {
      if(subscription && subscription?.unsubscribe & typeof subscription.unsubscribe === 'function') {
        console.log("serviceCleanup main unsubscribe");
        subscription.unsubscribe();
      }

      // if no subscribers then remove firebase refs
      if(this.subscriptions.length === 0) {
        console.log("COMPLETE UNRENDER!");
        for(let firebaseRefOff of this.refs) {
          if(typeof firebaseRefOff === 'function') firebaseRefOff(); // execute
        }
      }
    } catch (e) {
      console.warn('serviceCleanup', e)
    }
  }
}
