import React, { forwardRef, useState, useEffect, useReducer, useCallback } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { groupBy, sortBy, isEmpty } from 'lodash';

import { useNavigate } from "react-router-dom";

import { usePhone } from 'Providers';

import { v4 as uuidv4 } from 'uuid';

import { Contact_Telephone } from 'Classes';

import CallIcon from '@mui/icons-material/Call';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ContactsIcon from '@mui/icons-material/Contacts';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BusinessIcon from '@mui/icons-material/Business';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon  from '@mui/icons-material/PersonAdd';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import ExtensionIcon from '@mui/icons-material/Extension';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import JoinFullIcon from '@mui/icons-material/JoinFull';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { PhoneNumberDeviceIcon } from '../Icons';

import {
  CardMedia,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from '@mui/material';

import { service_contact_class } from 'Services';

import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Divider,
  Stack,
  Button,
  Box,
  Chip,
  Collapse,
  Fab,
  Popover,
  Typography
} from '@mui/material';

import FaceIcon from '@mui/icons-material/Face';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import { CustomAvatar } from "components/CustomAvatars";

const CallListItem = ({ index, divider, selected, onClick, type, number }) => {
  return (
    <ListItem
      selected={selected}
      dense
      disablePadding
    >
      <ListItemButton divider={divider} dense onClick={onClick(number)}>
        <ListItemIcon>
          <PhoneNumberDeviceIcon type={type} />
        </ListItemIcon>
        <ListItemText
          primary={number}
        />
      </ListItemButton>
    </ListItem>
  )
}

const CallsList = ({ data = [], preferred_telephone_uuid, onClick }) => {
  const lastIndex = data.length - 1;

  return data.map((item, index) => {
    return <CallListItem key={index} index={index} selected={preferred_telephone_uuid === item.id} divider={index !== lastIndex} onClick={onClick}  {...item} />
  })
}

const EmailsList = ({ data = [], onClick }) => {
  return data.map((item, index) => {
    return (
      <ListItemButton dense key={index} onClick={onClick(item)}>
        <ListItemIcon>
          <AlternateEmailIcon />
        </ListItemIcon>
        <ListItemText primary={item} />
      </ListItemButton>
    )
  })
}

const NewContactPopover = ({ caller_id_name, caller_id_number }) => {
  const navigate = useNavigate();

  const openPage = (...args) => event => {
    event.preventDefault();
    navigate(...args) // open page...
  }

  return (
    <Card elevation={0} sx={{ display: 'flex', padding: 0, flexDirection: "row" }}>
      <CustomAvatar variant="square" useDefault={true} sx={{ height: "100px !important", width: "100px !important" }} style={{ margin: 0 }} />
      <CardContent sx={{ p: 1, width: '100%', paddingBottom: "0px !important" }}>
        <List disablePadding sx={{ display: "flex", width: "100%" }}>
          <ListItem disablePadding>
            <ListItemText
              primary={caller_id_name ?? caller_id_number}
              secondary={caller_id_name ? caller_id_number : undefined}
            />
          </ListItem>
        </List>
        < br />
        <Stack direction="row" justifyContent='flex-end' spacing={1} sx={{ p: 0 }}>
          {
            // <Button size='small' variant="contained" color='secondary' startIcon={<JoinFullIcon />}>Merge</Button>
          }
          <Button size='small' variant="contained" startIcon={<AddCircleIcon />} onClick={openPage(`/contacts/new`, { state: { firstName: caller_id_name, telephones: [{ id: uuidv4(), type: 'mobile', number: caller_id_number }] }})}>Add</Button>
        </Stack>
      </CardContent>
    </Card>
  )
}


// colleages tab
// callog tab
// missed calls
const ContactPopover = ({ contact, state, ...props}) => {
  let mounted = true;

  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState(null);
  const open = Boolean(anchorEl);

  const { invite, transfer, hasActiveSession } = usePhone();
  const navigate = useNavigate();

  let {
    hasContact = state?.hasContact || false,
    caller_id_name,
    caller_id_number,
    //contact,
  } = state; // ContactPopoverClass

  const { id, fullName = undefined, image = undefined, organization, organization_uuid, emails = [], avatar, telephones = [] } = contact || {}; // calllog item

  useEffect(() => {
    return () => mounted = false
  }, [])

  const handleClick = (newType) => (event) => {
    if(!mounted) return;
    event.stopPropagation();
    setAnchorEl(element => !element ? event.currentTarget : null);
    setType(type => !type ? newType : null)
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setType(null);
  };

  const handleCallFab = (event) => {
    if(telephones.length === 1) {
      // call! transfer!?
      if(hasActiveSession()) {
        transfer(telephones[0].number)(event);
      } else {
        invite(telephones[0].number) // make new call
      }
    } else {
      // menu!
      handleClick('telephones')(event);
    }
  }

  const handleEmailFab = (email) => (event) => {
    if(!mounted) return;
    window.location = `mailto:${email}`;
  }

  const openPage = (...args) => event => {
    if(!mounted) return;
    event.preventDefault();
    navigate(...args) // open page...
  }

  const handleInvite = useCallback(
    (value) => (event) => {
      if(value) {
        if(hasActiveSession()) {
          transfer(value)(event);
        } else {
          invite(value) // make new call
        }
      }
    },
    [],
  );

  const handleEmail = useCallback(
    (value) => (event) => {
      if(value) {
        window.location = `mailto:${value}`;
      }
    },
    [],
  );


  return (
    <Card elevation={0} sx={{ display: 'flex', padding: 0, flexDirection: "column" }}>
      <Box display='flex' >
        <CustomAvatar variant="square" name={fullName} src={avatar?.image || image} bgcolor={avatar?.avatarColor} useDefault={id === 'new'} sx={{ height: "100px !important", width: "100px !important" }} style={{ margin: 0 }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 'min-content' }}>
         <CardContent sx={{ p: 0, paddingBottom: "0px !important" }}>
           <List
             disablePadding
           >
             <ListItem dense>
               <ListItemText
                  primary={
                    <Button variant="text" sx={{ textTransform: "none", height: 16 }} startIcon={<FaceIcon />} color='inherit' onClick={openPage({ pathname: `/contacts/${id}`, state: contact })}>
                      { caller_id_name || fullName || caller_id_number}
                    </Button>
                  }
               />
             </ListItem>

             <ListItem dense>
               <ListItemText
                 primary={
                   <Button variant="text" sx={{ textTransform: "none", height: 16 }} startIcon={<BusinessIcon />} color='inherit' onClick={openPage(`/organizations/${organization_uuid}`)}>
                     {contact?.organization}
                   </Button>
                 }
               />
             </ListItem>

             {
               emails.length > 0 &&
                 <ListItem dense>
                   <ListItemText
                     primary={
                       <Stack direction="column" spacing={1}>
                          {
                            emails.map((email, index) => {
                              return (
                                <Button key={index} variant="text" sx={{ textTransform: "none", height: 16 }} color='inherit' onClick={handleEmailFab}>
                                  <small>{email}</small>
                                </Button>
                              )
                            })
                          }
                       </Stack>


                     }
                   />
                 </ListItem>
             }
           </List>
         </CardContent>
        </Box>
      </Box>
     <CardActions sx={{ display: 'flex' }}>
       {
         telephones.length > 0 && (
           <Stack direction="row" spacing={1}>
              {
                [...telephones].map(({ number }, index) => {
                  return (
                    <Button
                       key={index}
                       size='small'
                       variant="contained"
                       startIcon={<PhoneNumberDeviceIcon type={type} />}
                       onClick={handleInvite(number)}
                    >
                       <small>{number}</small>
                    </Button>
                  )
                })
              }
           </Stack>
         )
       }
       {
         telephones.length == 0 &&
          <Button size='small' startIcon={<AddCircleIcon />} sx={{ textTransform: 'none' }} onClick={(e) => navigate({ pathname: `/contacts/${id}`, search: '?action=addNumber' })}>Add Number</Button>
       }

       {
         caller_id_number && telephones.length === 0 &&
           <Button
              size='small'
              variant="contained"
              startIcon={<PhoneNumberDeviceIcon />}
              onClick={handleInvite(caller_id_number)}
           >
              <small>{caller_id_number}</small>
           </Button>
       }

     </CardActions>
   </Card>
  )
}

// pitäis siirää callog ja friends

// could search for contact with id or number!


const INITIALSTATE = Object.freeze({
  status: 'INITIATE',
  contact: undefined,
});

function reducer(state = INITIALSTATE, { type, ...action}) {
  switch (type) {
    case 'LOADED': {

      return Object.assign(
        {...state},
        {
          status: 'LOADED',
          contact: action
        }
      )
    }
    case 'FAILURE': {
      return Object.assign(
        {...state},
        {
          status: 'FAILURE',
        }
      )
    }
    default:
      return {...state}
  }
}

const logicalComponent = (Component) => ({ state, ...props }) => {
  let mounted = true, subscription;
  let [{
    contact,
    status
  }, dispatch] = useReducer(reducer, INITIALSTATE);

  // should remount if state changes?
  let dataHandler = new service_contact_class(state?.contact?.id) // new / uid / contact id
  // const {
  //   add_subscriber,
  //   remove_subscriber
  // } = service_contact(state?.contact?.id) // new / uid / contact id

  useEffect(() => {
    if(!mounted) return;
    if(typeof dataHandler?.add_subscriber === 'function') {
      subscription = dataHandler.add_subscriber(
  			value => {
          if(mounted && value) {
            dispatch({ type: "LOADED", ...value })
          }
  			},
  			err => console.warn('ContactPopover', err)
  		)
    }

    return () => {
      if(typeof dataHandler?.remove_subscriber === 'function' && subscription) dataHandler.remove_subscriber(subscription);
      mounted = false;
    }
  }, [])


  switch (status) {
    case 'INITIATE':
      return <div>Loading...</div>
      break;
    case 'LOADED': {
      if(state?.contact?.id === 'new') {
        return <NewContactPopover {...props} caller_id_name={state.caller_id_name} caller_id_number={state.caller_id_number} />
      } else {
        return <Component {...props} state={state} contact={contact} />
      }
    }
    case 'FAILURE':
      return <div>Error Has Occurred...</div>
      break;
  }
}

export default logicalComponent(ContactPopover);
