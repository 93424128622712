import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { grey } from '@mui/material/colors';
import { Avatar, Box, TextField, ListItemText, InputAdornment, Checkbox, Autocomplete } from '@mui/material';

import AccountTreeIcon from  '@mui/icons-material/AccountTree';
import CheckBoxOutlineBlankIcon from  '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from  '@mui/icons-material/CheckBox';

function areEqual(prevProps, nextProps) {
  return prevProps.loading !== nextProps.loading || prevProps.options !== nextProps.options
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FormRinggroupsAutoComplete = (
  { input: { onChange, name }, label, meta: { touched, error }, textFieldProps, options, dispatch, ...custom },
) => {
  const handleChange = (event, value, reason) => {
    console.log(value)
    onChange(value)
  }

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect // dont close on item select
      name={name}
      onChange={handleChange}
      size="small"
      getOptionLabel={(option) => String(option?.ring_group_description || option?.ring_group_name || option)}
      defaultValue={[]}
      limitTags={3}
      ChipProps={{
        color: "primary"
      }}
      options={[...options]}
      getOptionSelected={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
      renderInput={({ InputProps: inputProps, ...params}) => (
        <TextField
          label={label}
          placeholder="-"
          {...params}
          {...textFieldProps}
          InputProps={{
            ...inputProps,
            startAdornment: (
              <React.Fragment>
                <InputAdornment position="start">
                  <AccountTreeIcon style={{ color: grey[500] }}/>
                </InputAdornment>
                { inputProps.startAdornment }
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />

          <Box pr={2}>
            <Avatar>
              <AccountTreeIcon />
            </Avatar>
          </Box>

          <ListItemText
            primary={String(option?.ring_group_extension)}
            secondary={String(option?.ring_group_description || option?.ring_group_name)}
          />
        </React.Fragment>
      )}
      {...custom}
    />
  );
}

FormRinggroupsAutoComplete.defaultProps = {
  id: 'FormRinggroupsAutoComplete',
};

const mapStateToProps = state => ({
  loading: state.ringgroups.loading,
  options: state.ringgroups.ringgroups
});

export default memo(
  connect(
    mapStateToProps,
    null
  )(FormRinggroupsAutoComplete),
  areEqual
);
