import React, { memo, useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import ExtensionIcon from  '@mui/icons-material/Extension';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

const FormExtensionRadioGroup = (
  { input: { onChange, onClose, name, value, ...input}, label, meta: { touched, error }, customTextField, options, disabled, helperText, ...custom },
) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" >
      { label && <FormLabel component="legend">{label}</FormLabel> }
      <RadioGroup
        aria-label={label}
        name={name}
        value={value}
        onChange={onChange}
        {...custom}
      >
        {
          [...options].map((option, index) => {
            return (
              <FormControlLabel key={index} value={String(option.value)} disabled={disabled} control={<Radio />} label={option.label} />
            )
          })
        }
      </RadioGroup>
      <FormHelperText>
        {helperText}
        {error}
      </FormHelperText>
    </FormControl>
  )
}

FormExtensionRadioGroup.defaultProps = {
  options: []
};


// function areEqual(prevProps, nextProps) {
//   return prevProps.disabled === nextProps.disabled || prevProps.input.value === nextProps.input.value;
// }

// export default memo(FormExtensionRadioGroup, areEqual);
export default FormExtensionRadioGroup;
