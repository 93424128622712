import React, { useState, useCallback, useEffect, useReducer } from 'react';

import { usePhone } from 'Providers';

import {
  Stack,
  Button,
  TextField,
  Fade,
  Box,
  Divider
} from '@mui/material';

import { Card } from 'components/Card';

function DialpadButton({ children, value, handleClick }) {
  return <Button variant="outlined" sx={{ borderRadius: '50%', minHeight: '64px', minWidth: '64px', padding: '6px'  }} onClick={handleClick(value.toString())}>{children}</Button>
}

function ButtonContent({ value, text = " " }) {
  return (
    <div style={{ lineHeight: '14px' }}>
      <div style={{ fontSize: '16px' }}>{value}</div>
      {
        text
          ? <div style={{ fontSize: '10px' }}>{text}</div>
          : <div style={{ fontSize: '10px' }}>&nbsp;</div>
      }
    </div>
  )
}

function reducer(state, action) {
  state.push(action) // only add
  return [...state]; // create new ref!
}

const Dialpad = (props) => {
  let mounted = true;
  const { state: session } = props;
  const [ digits, newDigit] = useReducer(reducer, []);
  const { dtmf } = usePhone();

  useEffect(() => {
    if(mounted) {
      window.addEventListener('keyup', handlekeyDown);
    }
    return () => {
      window.removeEventListener('keyup', handlekeyDown);
      mounted = false;
    }
  }, [])

  const handleClick = useCallback(
    (digit) => (event) => {
      if(mounted) {
        newDigit(digit);
        dtmf(session, digit.toString());
      }
    },
    [digits],
  );

  function validate(value) {
    return /^([0-9]|#|\*)$/.test(value);
  }

  // listen for digits!
  function handlekeyDown(event) {
    if(!validate(event.key)) return;
    dtmf(session, event.key);
    newDigit(event.key);
  }

  return (
    <div>
      <Box p={2} textAlign='center' fullWidth>
        {
          digits.map((digit, index) => {
            return <Fade in key={index}><span>{digit}</span></Fade>
          })
        }
      </Box>
      <Divider />
      <Stack
        spacing={2}
        direction="row"
        sx={{
          padding: '3px 4px',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DialpadButton handleClick={handleClick} value={1}>
          <ButtonContent value={1} />
        </DialpadButton>
        <DialpadButton handleClick={handleClick} value={2}>
          <ButtonContent value={2} text="ABC" />
        </DialpadButton>
        <DialpadButton handleClick={handleClick} value={3}>
          <ButtonContent value={3} text="DEF" />
        </DialpadButton>
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          padding: '3px 4px',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DialpadButton handleClick={handleClick} value={4}>
          <ButtonContent value={4} text="GHI" />
        </DialpadButton>
        <DialpadButton handleClick={handleClick} value={5}>
          <ButtonContent value={5} text="JKL" />
        </DialpadButton>
        <DialpadButton handleClick={handleClick} value={6}>
          <ButtonContent value={6} text="MNO" />
        </DialpadButton>
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          padding: '3px 4px',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DialpadButton handleClick={handleClick} value={7}>
          <ButtonContent value={7} text="PQRS" />
        </DialpadButton>
        <DialpadButton handleClick={handleClick} value={8}>
          <ButtonContent value={8} text="TUV" />
        </DialpadButton>
        <DialpadButton handleClick={handleClick} value={9}>
          <ButtonContent value={9} text="WXYZ" />
        </DialpadButton>
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          padding: '3px 4px',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DialpadButton handleClick={handleClick} value={"*"}>
          <ButtonContent value={"*"} />
        </DialpadButton>
        <DialpadButton handleClick={handleClick} value={0}>
          <ButtonContent value={0} />
          </DialpadButton>
        <DialpadButton handleClick={handleClick} value={"#"}>
          <ButtonContent value={"#"} />
        </DialpadButton>
      </Stack>
      <Box
        sx={{
          padding: '2px',
          width: '100%',
          textAlign: 'center'
        }}
      >
        <small>Hotkeys Enabled</small>
      </Box>
    </div>
  )
}

export default Dialpad;
