import { useState, useEffect } from 'react';

export default function useSingleAndDoubleClick(actionSimpleClick, actionDoubleClick, delay = 250) {
  let mounted = true;
  const [click, setClick] = useState(0);
  const [args, setArgs] = useState([]);

  useEffect(() => {
    return () => mounted = false;
  }, []);

  useEffect(() => {
    const timer = setTimeout(noneBlocking, delay);
    // the duration between this click and the previous one
    // is less than the value of delay = double-click
    if (click === 2) actionDoubleClick(...args);

    return () => {
      if(mounted) {
        clearTimeout(timer);
      }
    }
  }, [click]);

  async function noneBlocking() {
    if(mounted) {
      // simple click
      if (click === 1) actionSimpleClick(...args);
      setClick(0);
    }
  }

  return (a,b,c) => {
    if(mounted) {
      setArgs([a,b,c]);
      setClick(prev => prev + 1);
    }

  }
}
