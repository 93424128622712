import React, { useState, useEffect, useCallback, useReducer } from "react";

import { usePhone } from 'Providers';

import {
  Box,
  Avatar,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Switch,
  Button,
  IconButton,
  ListItemButton,
} from '@mui/material';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

//onChange={(event) => toggleDND(event.target.checked)}

const DNDOption = () => {
  const { doNotDisturb, toggleDND } = usePhone();
  const color = doNotDisturb ? 'red' : 'inherit';
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', color: color }}>
      <ListItemButton onClick={toggleDND}>
        <ListItemIcon sx={{ justifyContent: 'center'}}>
          <DoNotDisturbIcon sx={{ color: color }}/>
        </ListItemIcon>
        <ListItemText
          primary="Do Not Disturb"
          secondary={
            <small style={{ color: color }}>
              {" — Blocks calls if active"}
            </small>
          }
        />
        <Switch checked={Boolean(doNotDisturb)} color="warning" />
      </ListItemButton>
    </List>
  );
}

export default DNDOption;
