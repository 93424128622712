import React from 'react'
import { connect } from 'react-redux';
import { grey } from '@mui/material/colors';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form'

import { required } from 'components/Forms/Validators';

import { Card, CardBody, CardFooter } from 'components/Card';

import { MICROSOFT_VOICES, MICROSOFT_PITCH, MICROSOFT_RATE } from 'constants/index';

import CloseIcon from  '@mui/icons-material/Close';
import AddIcon from  '@mui/icons-material/Add';
import AddAlert from "@mui/icons-material/AddAlert";

import { TextField, MenuItem, Box, Grid, Tooltip, Divider, Button } from '@mui/material';
//import RegularButton from "components/CustomButtons/Button";
import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import SnackbarContent from 'components/Snackbar/SnackbarContent';

import FormTextField from '../FormTextField';

const FormAdvancedSpeakComponent = (
  { input, label, meta: { submitFailed, error }, ...custom },
) => {
  return (
    <div>
      <Grid container display='flex' justifyContent="center" direction='column' spacing={0}>
        <Grid item xs={12} style={{ marginBottom: 0 }}>
          <br />
          <SnackbarContent
            icon={AddAlert}
            color="rose"
            message={
              <div>
                We recomend using microsofts online editor to write SSML text.
                <Box paddingTop={2} display='flex' justifyContent='flex-end'>
                  <Button style={{ marginRight: '8px' }} variant="contained" color="primary" href="https://azure.microsoft.com/en-us/services/cognitive-services/text-to-speech/#features" target="_blank">
                    Microsoft Online Editor
                  </Button>
                  <Button variant="contained" color="primary" href="https://docs.microsoft.com/en-us/azure/cognitive-services/speech-service/speech-synthesis-markup?tabs=csharp#adjust-speaking-styles" target="_blank">
                    SSML Documentation
                  </Button>
                </Box>
              </div>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Card color='primary' style={{ margin: "0px 0px 12px 0px" }}>
            <CardBody>
              <Field
                component={FormTextField}
                themeColor='white'
                validate={[required]}
                label="Text To Speech Advanced"
                multiline
                minRows={12}
                maxRows={100}
                style={{ padding: 0, width: '100%' }}
                fullWidth
                variant="outlined"
                placeholder="Add Message Here"
                inputProps={{
                  spellCheck: false
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...input}
              />
            </CardBody>
          </Card>
        </Grid>
      </Grid>
      <div>
        {submitFailed && error && <span>{error}</span>}
      </div>
    </div>
  )
}

export default FormAdvancedSpeakComponent
