import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';
//import styles from "assets/jss/components/missedCallsStyle";

import { firestore_users_Actions } from 'store/profile/actions';

import Moment from "devtools/momentjs";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import { VerticalBarChart, HorizontalBarChart } from 'components/Charts/MissedCalls';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const SixMonthCharts = ({ unhandledCalls, handledCalls, loading, users, filters: filterProp, ...props}) => {
  const classes = useStyles();
  const [filters, setFilters] = useState(filterProp);

  useEffect(() => {
    if(filterProp !== filters) setFilters(filterProp);
  }, [filterProp]);

  const labelsHourly = Moment.hoursAsLabels('HH');
  const handledDataset = handledCalls.filter(call => Moment.command(moment => moment(call?.handledtime, "DD-MM-YYYY HH:mm:ss").isAfter( Moment.command(moment => moment().subtract(6, 'months').startOf('month')) )))

  const checkView = function(view, options) {
    return options.includes(view);
  }

  return (
    <GridContainer justifyContent={filters.gridLayout > 6 ? 'center' : 'flex-start'}>
      {
        checkView(filters.view, ['Hourly View', 'All Charts']) &&
        <GridItem xs={filters.gridLayout}>
          <VerticalBarChart
            footerText='Handled Calls'
            customLabels={labelsHourly}
            title='Handled Calls Hourly on Period of 6 Months'
            color='success'
            selectedDatasets={filters.selectedDatasets}
            subLabels={{
              x: 'Hour',
              y: 'Count'
            }}
            data={handledDataset}
            loading={loading}
            filterCallback={(item, dataset, increment) => {
              if(item?.handledtime) {
                const index = Moment.command(moment => moment(item.handledtime, "DD-MM-YYYY HH:mm:ss").format('HH'));
                increment(index)
              }
              return;
            }}
            datasetColors={'success'}
          />
        </GridItem>
      }

      {
        checkView(filters.view, ['Hourly View', 'All Charts']) &&
        <GridItem xs={filters.gridLayout}>
          <VerticalBarChart
            footerText='Missed Calls'
            customLabels={labelsHourly}
            title='Missed Calls Hourly on Period of 6 Months'
            color='danger'
            selectedDatasets={filters.selectedDatasets}
            subLabels={{
              x: 'Hour',
              y: 'Count'
            }}
            data={handledDataset}
            loading={loading}
            filterCallback={(item, dataset, increment) => {
              if(item?.aika) {
                const index = Moment.command(moment => moment(item.aika, "DD-MM-YYYY HH:mm:ss").format('HH'));
                increment(index)
              }
              return;
            }}
            datasetColors={'danger'}
          />
        </GridItem>
      }

      {
        checkView(filters.view, ['Monthly View', 'All Charts']) &&
        <GridItem xs={filters.gridLayout}>
          <VerticalBarChart
            footerText='Handled Calls'
            customLabels={Moment.chartLabels([6, 'months'], 'MMM')}
            title='Total Count of Handled Calls on Period of 6 Months'
            color='success'
            selectedDatasets={filters.selectedDatasets}
            subLabels={{
              x: 'Month',
              y: 'Count'
            }}
            data={handledDataset}
            loading={loading}
            filterCallback={(item, dataset, increment) => {
              if(item?.handledtime) {
                const index = Moment.command(moment => moment(item.handledtime, "DD-MM-YYYY HH:mm:ss").format('MMM'));
                increment(index)
              }
              return;
            }}
            datasetColors={'success'}
          />
        </GridItem>
      }

      {
        checkView(filters.view, ['Monthly View', 'All Charts']) &&
        <GridItem xs={filters.gridLayout}>
          <VerticalBarChart
            footerText='Missed Calls'
            customLabels={Moment.chartLabels([6, 'months'], 'MMM')}
            title='Total Count of Missed Calls on Period of 6 Months'
            color='danger'
            selectedDatasets={filters.selectedDatasets}
            subLabels={{
              x: 'Month',
              y: 'Count'
            }}
            data={handledDataset}
            loading={loading}
            filterCallback={(item, dataset, increment) => {
              if(item?.aika) {
                const index = Moment.command(moment => moment(item.aika, "DD-MM-YYYY HH:mm:ss").format('MMM'));
                increment(index)
              }
              return;
            }}
            datasetColors={'danger'}
          />
        </GridItem>
      }

      {
        checkView(filters.view, ['Handled By', 'All Charts']) &&
        <GridItem xs={filters.gridLayout}>
          <HorizontalBarChart
            footerText='Handled Calls'
            title='Total Count of Handled Calls on Period of 6 Months'
            color='success'
            subLabels={{
              x: 'Count',
              y: 'Person'
            }}
            data={handledDataset}
            loading={loading}
            filterCallback={(item, dataset, increment) => {
              if(item.handled === 1 && item.handledperson) {
                increment(item.handledperson)
              }
              return;
            }}
          />
        </GridItem>
      }

    </GridContainer>
  );
}


export default SixMonthCharts;
