import React, { useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';

import {
  useParams,
  useSearchParams,
  useLocation
} from "react-router-dom";

import { Card, CardBody } from 'components/Card';

import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  ListSubheader,
  Avatar,
  IconButton,
  Button,
  Divider,

  TextField
} from '@mui/material';

import TelephoneFormDialog from './TelephoneFormDialog';
import TelephoneFormDialogNew from './TelephoneFormDialogNew';

import TelephoneNumbersSplitButton from './TelephoneNumbersSplitButton';

import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

import CloseIcon from '@mui/icons-material/Close';
import CallIcon from '@mui/icons-material/Call';
import ExtensionIcon from '@mui/icons-material/Extension';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import { useFormContext, useFieldArray, useWatch, Controller } from "react-hook-form";

import {
  useUusiDialog
} from 'Providers';

const TelephoneAvatar = ({ type = 'business', ...props }) => {
  return {
    'extension': <ExtensionIcon fontSize="inherit" {...props} />,
    'mobile': <PhoneAndroidIcon fontSize="inherit" {...props} />,
    'business': <CallIcon fontSize="inherit" {...props} />
  }[type];
}

const ContactTelephonesList = ({ ...props }) => {
  const { dialog, DialogComponent } = useUusiDialog();
  const { control, } = useFormContext(); // retrieve all hook methods
  const { id: contact } = useParams();
  let location = useLocation();

  const { telephones, preferred_telephone, preferred_telephone_uuid } = useWatch(["telephones", "preferred_telephone", "preferred_telephone_uuid"]);

  useEffect(() => {
    if(location.search === "?action=addNumber") {
      // open new telephone dialog after page load
      setTimeout(dialog({ type: "OPEN", data: { number: "", submitCallback: (formData) => append({ ...formData, contact })}}, TelephoneFormDialogNew)(new Event('custom')), 250);
    }
  }, [location]);

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    keyName: 'key',
    name: "telephones", // unique name for your Field Array
  });

  return (
    <Card
      sx={{
        color: 'rgb(255, 255, 255)',
        background: 'linear-gradient(to right bottom, var(--muidocs-palette-primary-500), var(--muidocs-palette-primary-700) 120%)',
        boxShadow: 'rgb(0 0 0 / 10%) 0px 20px 25px, rgb(0 0 0 / 4%) 0px 10px 10px',
      }}
    >
      <List
        id='jaahas'
        sx={{
          width: '100%',
          backgroundColor: "inherit"
        }}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Telephone Numbers
            <TelephoneNumbersSplitButton
              addAction={(e) => {
                dialog({ type: "OPEN", data: { number: "", submitCallback: (formData) => append({ ...formData, contact })}}, TelephoneFormDialogNew)(e);
              }}
            />
          </ListSubheader>
        }
      >
        {
          fields.map(({ type, number, id }, index) => {
              const preffered = preferred_telephone_uuid === id;
              const item = `telephones.${index}`;

              return (
                <ListItemButton
                  key={index}
                  disableGutters
                  sx={{ py: 0 }}
                  onClick={dialog({ type: "OPEN", data: { item: item, number: number, onDelete: () => remove(index) }}, TelephoneFormDialog)}
                >
                  <ListItem
                    key={index}
                    selected={preffered}
                    secondaryAction={
                      preffered &&
                      <HelperTooltipBootstrap
                        title="This number is preffered when dialing!"
                        placement='left'
                        arrow
                      >
                        <div>
                          <IconButton edge="end" aria-label="Delete" disabled>
                            <CheckCircleIcon />
                          </IconButton>
                        </div>
                      </HelperTooltipBootstrap>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ height: 36, width: 36 }}>
                        <TelephoneAvatar type={type} sx={{ color: "black" }}/>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={number}
                      secondary={type}
                    />
                  </ListItem>
                </ListItemButton>
              )
            })
        }
        <DialogComponent />
      </List>
    </Card>
  );
}

export default ContactTelephonesList;
