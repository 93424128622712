import React, { useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import TooltipComponent from '../TooltipComponent';
import ActionButtonsMenuComponent from '../ActionButtonsMenuComponent';

//import TransferAutocomplete from '../TransferAutocomplete2';
//import Button from "components/CustomButtons/Button.js";

import {
  Button,
  ButtonGroup,
  Grid
} from '@mui/material';

// import {
//   handleHangUpOrCancel,
//   handleMute,
//   handleUnmute,
//   handleHold,
//   handleResume,
// } from 'WEBRTC/phoneManager';

import CallSplitIcon from '@mui/icons-material/CallSplit';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DialpadIcon from '@mui/icons-material/Dialpad';

import Dialpad from '../Dialpad';

import { usePhone, usePopover, withPopover } from 'Providers';

import styles from "assets/jss/views/buttonsStyle.js";
const useStyles = makeStyles(createStyles(styles));

const ActiveSessionButtons = (props) => {
  const classes = useStyles();
  const { handlePopoverClick } = usePopover();

  const {
    answer,
    hangup,
    hold,
    unHold,
    mute,
    unMute
  } = usePhone();

  const {
    session,
    localHold,
    muted
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <Grid container item justifyContent='flex-end' spacing={1} xs>
      <Grid item>
        <ButtonGroup size="small" variant="contained" aria-label="outlined primary button group">
          {
            muted
              ?   <Button style={{ backgroundColor: '#333' }} onClick={unMute(session)}>
                    <TooltipComponent title="unMute">
                      <MicOffIcon />
                    </TooltipComponent>
                  </Button>
              :   <Button style={{ backgroundColor: '#333' }} onClick={mute(session)}>
                    <TooltipComponent title="Mute">
                      <MicIcon />
                    </TooltipComponent>
                  </Button>

          }
          {
            localHold
              ? <Button style={{ backgroundColor: 'green' }} onClick={unHold(session)}>
                  <TooltipComponent title="unHold">
                    <PlayCircleIcon />
                  </TooltipComponent>
                </Button>
              : <Button style={{ backgroundColor: '#ff9800' }} onClick={hold(session)}>
                  <TooltipComponent title="Hold">
                    <PhonePausedIcon />
                  </TooltipComponent>
                </Button>
          }
          <Button style={{ backgroundColor: '#f44336' }} onClick={hangup(session)}>
            <TooltipComponent title="End call">
              <CallEndIcon />
            </TooltipComponent>
          </Button>
          {
            <Button color='success' variant='contained' onClick={handlePopoverClick(session)}>
              <TooltipComponent title="Dialpad">
                <DialpadIcon />
              </TooltipComponent>
            </Button>
          }


          {
            // <Button style={{ backgroundColor: '#333' }} onClick={handleClick}>
            //   <TooltipComponent title="Options">
            //     <MoreVertIcon />
            //   </TooltipComponent>
            // </Button>
            //<ActionButtonsMenuComponent open={open} anchorEl={anchorEl} handleClose={handleClose} {...props} />
          }
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}

ActiveSessionButtons.defaultProps = {
  session: {}
}

export default withPopover(
  ActiveSessionButtons,
  {
    component: Dialpad,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    }
  }
);
