import React, { useCallback, useEffect, useState } from 'react';
import { grey } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import { TextField, InputAdornment } from '@mui/material';

import { debounce } from 'lodash';


const WhiteTextField = withStyles({
  root: {
    color: grey[300],
    '& div': {
      color: grey[300],
      '& svg': {
        color: grey[300],
      }
    },
    '& label': {
      color: grey[300],
    },
    '& label.Mui-focused': {
      color: grey[300],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: grey[300],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: grey[300],
      },
      '&:hover fieldset': {
        borderColor: grey[300],
      },
      '&.Mui-focused fieldset': {
        borderColor: grey[300],
      },
    },
  },
})(TextField);


const FormTextFieldDebounced = (
  { input: { value: valueProp, onChange, ...input }, label, meta: { asyncValidating, touched, error }, value: dummy, helperText, debounceTime, InputProps: inputProps, themeColor, ...custom },
) => {
  const [value, setValue] = useState(valueProp);
  
  useEffect(() => {
    if(value !== valueProp) setValue(valueProp);
  }, [valueProp]);

  const handler = useCallback(debounce(value => onChange(value), debounceTime), []);

  const ThemeComponent = {
    white: WhiteTextField,
    default: TextField,
  }[themeColor || 'default']

  return (
    <ThemeComponent
      error={error && touched}
      helperText={ helperText ? helperText(touched, error) : touched && error ? error : null }
      label={label}
      value={value}
      onChange={e => {
        setValue(e.currentTarget.value);
        e.persist();
        handler(e);
      }}
      InputProps={{
        ...inputProps,
        endAdornment: (
          <InputAdornment position="end" style={{ visibility: asyncValidating ? 'visible' : 'hidden' }}>
            <i className={"fas fa-spinner fa-spin"} />
          </InputAdornment>
        )
      }}
      {...input}
      {...custom}
    />
  )
}

FormTextFieldDebounced.defaultProps = {
  value: '',
  debounceTime: 400,
}

export default FormTextFieldDebounced;
