import React, { lazy, Suspense, useState, useEffect } from "react";
import { connect } from 'react-redux';

import { Switch, Route, Redirect } from "react-router-dom";

import { Outlet } from "react-router-dom";

import {
  withAnimation
} from 'HOC';

// import {
//   ALLROUTES,
//   profileRoutes,
//   getActiveRoute,
// } from 'routes';

import { withAuthentication } from 'Session';

import { blue } from '@mui/material/colors';

// import withWebRTCApp from 'WEBRTC/components/WebRTCApp.js';

import { withCallogContext } from "Contexts/CalllogContext";
import { withSessionsContext } from "Contexts/SessionsContext";
import { withTabsContext } from "Contexts/TabsContext";
import { withCallingListContext } from "Contexts/CallingListContext";
import { withMenuContext } from "Contexts/MenuContext";

import _package from '../../package.json';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import {
  Badge,
  IconButton,
  Grid,
  Fade,
  CircularProgress
} from '@mui/material';

import {
	FriendsListComponent,
	CallLogComponent,
} from 'WEBRTC/components/Tabs';

import {
  MainNavbar,
  BottomNavigationBar
} from 'components/Navbars';

//import MainDrawer from 'WEBRTC/components/MainDrawer';

import { Loader } from 'routes';

import Pallet from '../Pallet';

import withWebRTCPhone from 'WEBRTC/components/WebRTCPhone'; //default
import WebRTCPhone from 'WEBRTC/components/WebRTCPhone';
import Phone from 'WEBRTC/components/Phone'; //default

import {
  usePhone,
  withDrawers,
  withScrollBar,
  withDialogs,
  withWebHID,
  withSessions,
  withJabraSignals,
  withDebugger,
  withMuiDialogs,
  withUusiDialog,
  withAudioProtection,
  //withDesktopNotifycations
} from 'Providers';

import StarIcon from '@mui/icons-material/Star';
import PhoneIcon from '@mui/icons-material/Phone';

import { DrawerPermanentRight } from 'components/CustomDrawers';
import { DrawerTabsComponent } from 'components/CustomTabs';

import Contacts from 'views/Contacts/Contacts';

const drawerWidth = 300;

function WithBadge({ children, badgeContent = 0, ...props}) {
  return (
    <Badge badgeContent={badgeContent} color="secondary">
      { children }
    </Badge>
  )
}


const Main = ({ dispatch, scrollRef, children, ...props}) => {
  const { currentUser, loading } = props;
  const { doNotDisturb } = usePhone();

  return (
    <Box sx={{ display: 'flex' }}>
      <MainNavbar />

      <Box
        component="main"
        //ref={scrollRef}
        sx={{
          flexGrow: 1,
          bgcolor: 'background.secondary',
          py: 3,
          height: "100vh",
          overflowY: "scroll",
        }}
      >
        <Toolbar />

        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>

        <Phone />
        <BottomNavigationBar />
      </Box>

      <DrawerPermanentRight>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          sx={{
            height: "100%"
          }}
        >
          <Grid item flexGrow={1} sx={{ width: "inherit" }}>
            <DrawerTabsComponent
              style={{ height: '100%' }}
              color={doNotDisturb ? 'error' : 'primary'}
              components={[
                {
                  label: 'Recents',
                  icon: (
                    <WithBadge badgeContent={0} >
                      <PhoneIcon fontSize='small' />
                    </WithBadge>
                  ),
                  component: <CallLogComponent {...props} />
                },
                {
                  label: 'Friends',
                  icon: (
                    <WithBadge badgeContent={0}>
                      <StarIcon fontSize='small' />
                    </WithBadge>
                  ),
                  component: <FriendsListComponent {...props} />
                }
              ]}
            />
          </Grid>
          <Grid item sx={{ width: "inherit", textAlign: "center", fontFamily: 'BellGothicBlack' }}>
            {
              _package?.version && <b>{`v${_package?.version}`}</b>
            }
          </Grid>
        </Grid>
      </DrawerPermanentRight>
    </Box>
  );
};

const mapStateToProps = state => ({
  currentUser: state.currentUser.authUser,
  loading: state.firebase.loading,
});

export default connect(
  mapStateToProps,
  null
)(
  //withDesktopNotifycations(
    withTabsContext(
      withCallingListContext(
        withCallogContext(
          withAuthentication(
            withDebugger(
              withAudioProtection(
                withWebHID(
                  withSessions(
                    withWebRTCPhone(
                      withJabraSignals(
                        withDrawers(
                          withMuiDialogs(
                            withUusiDialog(
                              withScrollBar(Main)
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  //)
);
