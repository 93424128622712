import React, { memo } from "react";
import { connect } from 'react-redux';
import { partition, orderBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { isLoading } from 'devtools/Helpers';

import { grey } from '@mui/material/colors';

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components

import AccountTreeIcon from  '@mui/icons-material/AccountTree';
import CheckBoxOutlineBlankIcon from  '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from  '@mui/icons-material/CheckBox';

import { Box, Checkbox, MenuItem, InputAdornment, ListItemAvatar, Avatar, ListItemText } from '@mui/material';

import {
  FormCreativeMultipleAutoComplete,
  getOptionSelected_ObjectMatch,
} from './AutoComplete';

import RenderGroup  from 'components/Forms/Components/AutoComplete/RenderGroup';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FormCreativeExtensionsAvailableAutoComplete = ({ dispatch, options, ...props}) => {
  const id = "autocomplete-" + uuidv4();
  options = options.map(item => ({ ...item, category: Boolean(item?.userid) ? 'Not Available' : 'Available'}))
  options = orderBy(options, 'category', 'asc')

  return (
    <FormCreativeMultipleAutoComplete
      id={id}
      options={options}
      getOptionLabel={(option) => String(option?.description || option?.extension)}
      getOptionDisabled={(option) => Boolean(option?.userid)} // disable Not Availables
      getOptionSelected={getOptionSelected_ObjectMatch}
      renderGroup={RenderGroup}
      renderOption={(option, { selected }) => {
        return (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <ListItemText
              primary={option?.extension}
              secondary={option?.description}
            />
          </React.Fragment>
        )
      }}
      startAdornment={(
        <AccountTreeIcon />
      )}
      {...props}
    />
  );
}

const mapStateToProps = state => ({
  loading: isLoading([state.extensions.loading,state.firebase.loading]),
  options: state.extensions.extensions
});

export default connect(
  mapStateToProps,
  null
)(FormCreativeExtensionsAvailableAutoComplete);
