import { firebase, store } from 'index.js';

import { values, merge, keyBy, groupBy, pick, isEmpty, isEqual, omit, partition } from "lodash";

import { v4 as uuidv4 } from 'uuid';

import DialerSipIcon from '@mui/icons-material/DialerSip';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import { ContactPopoverClass } from 'Classes';

// import { onError, enqueueSnackbar } from 'store/Notifier/actions';

import { ReplaySubject, combineLatest } from "rxjs";
import { map, distinctUntilChanged } from 'rxjs/operators';

import { makeCollection, sortCollection, sortByTime, parseTypes, compare } from 'devtools/Helpers';

import { service_contacts } from './service_contacts';

import { concatString } from 'Helpers/Text';

import { serviceCleanup } from './index';

const SORTORDER = ['extension','mobile','business'];

// class ColleagueListItem {
//   constructor(data, type) {
//     let obj;
//     switch (type) {
//       case 'user':
//         obj = {
//           category: "users",
//           description: data?.username || null,
//           more: {
//             email: data?.email || null,
//             line: data?.line || null,
//             avatarColor: data?.avatarColor || null,
//             altName: data?.altName || null,
//             roles: data?.roles || null,
//             image: data?.image || null,
//             organization: data?.organization || null,
//             // avatar: {
//             //   avatarColor: data?.avatarColor || null,
//             //   altName: data?.altName || null,
//             //   roles: data?.roles || null,
//             //   image: data?.image || null,
//             // },
//           },
//           numbers: (data?.extensions ?? []).map(item => ({
//             description: item?.description || null,
//             outbound_caller_id_number: item?.outbound_caller_id_number?.toString() || null,
//             alias_number: item?.alias_number?.toString() || null,
//             extension: item?.extension?.toString() || null,
//           })),
//         }
//         break;
//       case 'extension':
//         obj = {
//           category: "extensions",
//           description: data?.description,
//           icon: data?.sip_client ? DialerSipIcon : PhoneAndroidIcon,
//           numbers: [
//             {
//               description: data?.description,
//               outbound_caller_id_number: data?.outbound_caller_id_number?.toString() || null,
//               alias_number: data?.alias_number?.toString() || null,
//               extension: data?.extension?.toString() || null,
//             }
//           ]
//         }
//         break;
//     }
//     return obj;
//   }
// }

function sortByOrder(sortOrder = SORTORDER, prop = 'type') {
  return function (a, b) {
    return sortOrder.indexOf(a[prop]) - sortOrder.indexOf(b[prop])
  };
}

// colleagues component
export const service_colleagues = function () {

  const {
    observable: observable_contacts$
  } = service_contacts();

  const observable_extensions$ = new ReplaySubject();
  const observable_users$ = new ReplaySubject();
  const observable_currentUser$ = new ReplaySubject();

  const subscriptions = [];

  const refs = [
    firebase.doFirebaseObservableQuery2(firebase.users(), "on", "value", (snapshot) => observable_users$.next(makeCollection(parseTypes(snapshot.val(), true)))),
    firebase.doFirebaseObservableQuery2(firebase.extensions(), "on", "value", (snapshot) => observable_extensions$.next(makeCollection(parseTypes(snapshot.val(), true)))),
    firebase.doFirebaseObservableQuery2(firebase.contacts(), "on", "value", (snapshot) => observable_extensions$.next(makeCollection(parseTypes(snapshot.val(), true)))),
  ];

  observable_currentUser$.next(firebase.authUser); // mikä tää on!?

  const observable = combineLatest(observable_extensions$, observable_users$, observable_currentUser$, observable_contacts$)
    .pipe(
      map(([extensions, users, currentUser, contacts]) => {
        const organization_uuid = currentUser.organization.domain_uuid;

        return {
          colleagues: contacts.filter((contact) => contact.organization_uuid === organization_uuid).map(({ telephones = [], ...contact}) => {
            const { image = null, avatarColor = null, ...rest } = users.find(user => user.id === contact.id) || {};
            telephones.sort(sortByOrder())
            return new ContactPopoverClass({
              caller_id_name: contact.fullName || concatString([contact.firstName, contact.lastName]),
              caller_id_number: (contact?.preferred_telephone || telephones[0]?.number)?.toString() || null, // string or null
              contact: {
                avatar: {
                  image: image,
                  avatarColor: avatarColor,
                },
                status: users.find(user => user.id === contact.id)?.line || 'PAIKALLA',
                ...contact,
                telephones
              }
            });
          }),
          favorites: [],
        }
        // console.log("contacts", contacts, currentUser);
        // let _extensions = extensions
        // return groupBy([
        //   ...users.filter(user => user.id !== currentUser.uid).map((user) => {
        //       const [match, nomatch] = partition(_extensions, function(o) {
        //         return o?.userid === user?.id;
        //       });
        //
        //       _extensions = nomatch;
        //       return new ColleagueListItem({ ...user, extensions: match, organization: currentUser.organization.domain_description }, "user");
        //   }),
        //   ..._extensions.map((extension) => {
        //     return new ColleagueListItem(extension, "extension");
        //   })
        // ], "category");
      }),
      distinctUntilChanged(),
    );

  function add_subscriber(callback) {
    const subscription = observable.subscribe(
      callback,
      err => console.error('service_colleagues', err)
    )
    subscriptions.push(subscription);
    return subscription;
  }

  return {
    add_subscriber: add_subscriber,
    remove_subscriber: (subscription) => {
      serviceCleanup(subscription, refs, subscriptions)
    },
  }
}
