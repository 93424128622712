import React, { createContext, useCallback, useReducer, useContext, useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';

import { Dialogs } from 'components/CustomDialogs';

import ErrorBoundary from 'components/ErrorBoundary';

const uusidialogContext = createContext();

const INITIALSTATE = Object.freeze(
  {
    open: false,
    component: "div",
    data: {},
  }
);

function reducer(state = INITIALSTATE, { type, ...action}) {
  switch (type) {
    case 'OPEN': {
      return Object.assign(
        {...state},
        {
          open: true,
          ...action
        }
      )
    }
    case 'CLOSE': {
      return INITIALSTATE;
    }
    default:
      return state
  }
}

export function useUusiDialogProvider() {
  let mounted = true;
  const [{ component: DialogComponent, ...options }, dispatch] = useReducer(reducer, INITIALSTATE);

  useEffect(() => {
    return () => mounted = false;
  }, []);

  const dialog = useCallback(
    ({type = "CLOSE", ...props }, Component) => (event) => {
      if(!mounted) return;
      dispatch({ type: type, component: Component, ...props });
    },
    [],
  );

  // this should be an event .on ...
  const handleClose = useCallback(
    (event) => {
      if(!mounted) return;
      // could trigger callback here!
      dispatch({ type: 'CLOSE' });
    },
    [],
  );

  return {
    options, // open, data...
    handleClose,
    DialogComponent,
    dialog
  }
}

export function useUusiDialog() {
  return useContext(uusidialogContext);
}


export const withUusiDialog = (RootComponent) => (props) => {
  const { options, ...others } = useUusiDialogProvider();

  return (
    <uusidialogContext.Provider value={{ ...options, ...others }}>
      <RootComponent {...props} />
    </uusidialogContext.Provider>
  )
}



// export const withUusiDialog = (Component) => (props) => {
//   return (
//     <MuiDialogsProvider>
//       <Component {...props} />
//     </MuiDialogsProvider>
//   )
// }
