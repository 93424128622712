import React from 'react';
import { makeStyles, withStyles, createStyles } from '@mui/styles';

import { Badge, Tooltip, Avatar, Icon } from '@mui/material';

import classNames from "classnames";

// import DialerSipIcon from  '@mui/icons-material/DialerSip';
// import PhoneAndroidIcon from  '@mui/icons-material/PhoneAndroid';
// import HelpIcon from  '@mui/icons-material/Help';
// import styles from "assets/jss/components/badgeStyle";

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const CustomAvatarWithClient = ({ tooltipProps, badgeProps, avatarProps, defaultColor, size, user, ...props}) => {
  const classes = useStyles();

  const avatarClasses = classNames({
    [classes.avatar]: true,
    [classes['avatar-small']]: size === 'small',
    [classes['avatar-large']]: size === 'large',
    [classes['avatar-profile']]: size === 'profile'
  });

  function getComponent(sip_client) {
    return {
      'true': 'dialer_sip',
      'false': 'phone_android',
      'null': 'help'
    }[JSON.stringify(typeof sip_client === 'boolean' ? sip_client : 'null')]
  }

  return (
    <div className={classes.root}>
      <Tooltip {...tooltipProps} arrow>
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <SmallAvatar>
              <Icon style={{ fontSize: 18 }}>{getComponent(user?.sip_client)}</Icon>
            </SmallAvatar>
          }
        >
          <Avatar
            className={avatarClasses}
            style={{
              backgroundColor: user?.avatarColor || '#bdbdbd',
              color: '#fff'
            }}
            { ...user?.image && { src: user?.image } }
            {...avatarProps}
          >
            {user?.altName || '?' }
          </Avatar>
        </Badge>
      </Tooltip>
    </div>
  );
}

export default CustomAvatarWithClient;
