import React from 'react';

import CallIcon from '@mui/icons-material/Call';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ExtensionIcon from '@mui/icons-material/Extension';

const PhoneNumberDeviceIcon = ({ type, ...props }) => {
  return {
    'extension': <ExtensionIcon {...props} />,
    'business': <CallIcon {...props} />,
    'mobile': <PhoneAndroidIcon {...props} />,
  }[type ?? "business"]
}

PhoneNumberDeviceIcon.defaultProps = {
  type: "business"
}

export default PhoneNumberDeviceIcon;
