import React, { useContext, useEffect, createContext, useState } from "react";
import JsSIP from 'jssip';
import { merge, clone, take, uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { firebase } from 'index.js';

import { makeCollection } from 'devtools/Helpers';

import {
  useForceRender,
	ForceRenderProvider
} from 'Providers';

// import deepmerge from 'deepmerge';
import Logger from './Logger';

import { Subject } from "rxjs";

const logger = new Logger('settingsManager');

const STORAGE_NAME = 'calling-list';
const STORAGE_COUNT = 50;

const localstorage = {
  "get": () => JSON.parse(window.localStorage.getItem(STORAGE_NAME)) || {},
  "set": (values) => window.localStorage.setItem(STORAGE_NAME, JSON.stringify(take(values, STORAGE_COUNT))),
  "delete": () => window.localStorage.removeItem(STORAGE_NAME),
}

const INITIALSTATE = sortByTime(makeCollection(localstorage.get())); // object --> array

let outbound_calllog_render$ = new Subject();
let calllog = INITIALSTATE || [];

function sortByTime(values, prop = "time") {
  return values.sort(function(x, y){
    return x[prop] - y[prop];
  })
}

function reducer(state = INITIALSTATE, action) {
  switch (action.type) {
    case 'ADD': {
      const items = uniqBy(sortByTime([...state, action.payload]));
      localstorage.set(items)
      return items;
    }
    case 'REMOVE': {
      const items = state.filter(item => item !== action.payload);
      localstorage.set(items)
      return items;
    }
    case 'REMOVEALL': {
      localstorage.delete(); // store remove items
      return [];
    }
    default:
      return state;
  }
}

export const outbound_calllog_action = (action) => {
  calllog = reducer(calllog, action);
  outbound_calllog_render$.next(true);
}

export const outbound_calllog_get_item = (index = 0) => calllog[index];

export {
	calllog,
}

const withForcedRender = (Component) => (props) => {
	return (
		<ForceRenderProvider observable={outbound_calllog_render$}>
			<Component {...props} />
		</ForceRenderProvider>
	)
}

export const OutboundCalllogItems = withForcedRender(({ children }) => {
  const [state] = useForceRender();
  return children(calllog) // outgoing only
});
