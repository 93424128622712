import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';
import { Select, Box, Zoom, InputLabel, FormControl, FormHelperText, InputAdornment, MenuItem } from '@mui/material';

import CheckIcon from  '@mui/icons-material/Check';

import styles from "assets/jss/components/formInputStyle.js";

const useStyles = makeStyles(createStyles(styles));

// initial: ""
// invalid: true
// pristine: true
// submitFailed: false
// submitting: false
// visited: false

const FormCreativeSelectField = (
    { input: { onChange, value, onDragStart, onDrop, name, ...input }, meta: { asyncValidating, touched, dirty, error, warning, valid, ...custom }, inputLabelProps, updateOnChange, ...props }
) => {
  const classes = useStyles();
  let inputProps;
  const {
    dragable,
    formControlProps,
    label,
    id,
    labelProps,
    white,
    inputRootCustomClasses,
    helperText,
    endAdornment,
    children,
    ...rest
  } = props;

  const showError = dirty && error;
  const success = valid && dirty && !showError ? true : false;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: showError,
    [" " + classes.labelRootSuccess]: success && !showError
  });
  const underlineClasses = classNames({
    [classes.underlineError]: showError,
    [classes.underlineSuccess]: success && !showError,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.selectFormControl
    );
  } else {
    formControlClasses = classes.selectFormControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !showError
  });

  const handleChange = (event) => {
    const newValue = event.target.value;
    onChange(event)
  }

  // makeStyles-selectFormControl-224 > div:after {
  // border-bottom-color: #4caf50!important;

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      <InputLabel
        htmlFor={id}
        className={classes.selectLabel}
        {...inputLabelProps}
      >
        {label}
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select,
          // underline: underlineClasses Tätä ei ole!!!
        }}
        {...rest}
        name={name}
        value={value}
        onChange={handleChange}
        inputProps={{
          name: name,
          id: id
        }}
      >
        { children }
      </Select>
      {
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          { helperText && (!warning && !error) && <span>{helperText}</span>}
          { touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </FormHelperText>
      }
    </FormControl>
  )
}

FormCreativeSelectField.defaultProps = {
  id: 'FormCreativeTextField',
  dragable: false,
  updateOnChange: false
};

FormCreativeSelectField.propTypes = {
  label: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node
};

export default FormCreativeSelectField;
