import React from "react";
import { withStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

import { ToggleButton } from '@mui/material';

const GreyToggleButton = withStyles({
  root: {
    color: grey[100],
    backgroundColor: grey[500],
    border: '1px solid ' + grey[900] + ' !important',
    '&:hover': {
      color: grey[500],
      backgroundColor: grey[700],
      '&.Mui-selected': {
        color: grey[200],
        backgroundColor: grey[600],
      },
    },
    '&.Mui-selected': {
      color: grey[200],
      backgroundColor: grey[800],
      borderBottom: '4px solid '+ grey[900]+' !important',
    },
  },
})(ToggleButton);


export {
  GreyToggleButton
};
