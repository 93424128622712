import React from 'react';

import Logger from '../Logger';
import settingsManager from '../settingsManager';

import 'HID/devices';

import Notifier from './Notifier';
import Phone from './Phone';

import {
	Snackbar
} from '@mui/material/';

// import {
//   usePhone,
// 	SessionsProvider
// } from 'Providers';

import { withPopper } from 'HOC';

import { withFriendsContext } from '../Contexts/FriendsContext';
import { withCallogContext } from '../Contexts/CalllogContext';
import TabContext, { withTabsContext } from '../Contexts/TabsContext';
import { withCallingListContext } from '../Contexts/CallingListContext';
import { withMenuContext } from '../Contexts/MenuContext';

import { service_sipAccount } from 'Services';

import { withPhoneProvider } from 'Providers';

//import { sessions, phone, doRegister } from 'WEBRTC/phoneManager';

const logger = new Logger('App');

// const theme = createTheme();

export default (WrappedComponent) => {
  return withPhoneProvider(class extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				showSnackbar    : false,
				snackbarMessage : null
			};

			// Mounted flag
			this._mounted = false;
			// Timer for snackbar
			this._snackbarTimer = null;

			this.subscription = null;

			const { add_subscriber, remove_subscriber } = service_sipAccount();
			this.add_subscriber = add_subscriber;
			this.remove_subscriber = remove_subscriber;
		}

		render() {
			const {
				showSnackbar,
				snackbarMessage = ''
			} = this.state;

			return (
				<div data-component='App'>
					<Notifier ref='Notifier'/>
					<WrappedComponent
						{...this.props}
						onNotify={this.handleNotify.bind(this)}
						onHideNotification={this.handleHideNotification.bind(this)}
						onShowSnackbar={this.handleShowSnackbar.bind(this)}
						onHideSnackbar={this.handleHideSnackbar.bind(this)}
						onExit={this.handlePhoneExit.bind(this)}
					/>
					<Snackbar
						open={showSnackbar}
						message={snackbarMessage}
						bodyStyle={{ textAlign: 'center' }}
						onRequestClose={() => {}} // Avoid auto-hide on click away
					/>
				</div>
			);
		}

		componentDidMount() {
			this._mounted = true;

			// connect to rxjs and firebase
			this.subscription = this.add_subscriber(
				value => {
					if(value) {
						this.props.doRegister(value);
					}
				}
			);
		}

		componentWillUnmount() {
			this._mounted = false;
			this.remove_subscriber(this.subscription);
		}

		handleNotify(data) {
			this.refs.Notifier.notify(data);
		}

		handleHideNotification(uid) {
			this.refs.Notifier.hideNotification(uid);
		}

		handleShowSnackbar(message, duration) {
			clearTimeout(this._snackbarTimer);

			this.setState({
				showSnackbar    : true,
				snackbarMessage : message
			});

			if (duration) {
				this._snackbarTimer = setTimeout(() => {
					if (!this._mounted) return;
					this.setState({ showSnackbar: false });
				}, duration);
			}
		}

		handleHideSnackbar() {
			clearTimeout(this._snackbarTimer);

			this.setState({
				showSnackbar : false
			});
		}

		handlePhoneExit() {
			logger.debug('handlePhoneExit()');

			// Go to login
			// this.setState({
			// 	step         : 'login',
			// 	showSnackbar : false
			// });
		}
	});
};
