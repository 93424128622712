import React, { forwardRef, useState, useEffect } from 'react';
import { sleep } from 'Helpers';

const LoadingStateComponent = forwardRef(({children, ...props}, ref) => {
  let mounted = true;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => mounted = false;
  }, []);

  const handleClick = (callback) => (e) => {
    if(mounted) {
      setLoading(true);
      try {
        callback(e);
      } catch (err) {
        console.warn("handleClick", err);
      } finally {
        setTimeout(() => {
          if(mounted) setLoading(false)
        }, 200);
      }
    }
  }

  const handleClickPromise = (callback) => async (e) => {
    if(mounted) {
      setLoading(true);
      try {
        await callback(e);
      } catch (err) {
        console.warn("handleClick", err);
      } finally {
        await sleep(200);
        if(mounted) setLoading(false);
      }
    }
  }


  return children({ ref, loading, handleClick, handleClickPromise, ...props})
});

export default LoadingStateComponent;
