import ExtensionIcon from '@mui/icons-material/Extension';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import StarIcon from '@mui/icons-material/Star';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

const DEFAULT_VALUES = {
  "default": false,
  "dnd": false,
  "friends_options": {
    friendsOrderAndDisplay: [
      { id: 'item-0', content: 'Ring Groups', display: true },
      { id: 'item-2', content: 'Colleagues', display: true },
      { id: 'item-3', content: 'Extensions', display: false },
      // { id: 'item-1', content: 'Favorites', display: true },
    ],
    sortBy: 'Name',
  },
  "jabraConfig": {},
  debugging: {
    jssip: true, //"JsSIP:*"
  },
  //"debug": "* -engine* -socket* *ERROR* *WARN*",
}

const localstorage = {
  "get": function (name) {
    const value = window.localStorage.getItem(name);
    if(String(value) === "undefined" || value === "") {
      return DEFAULT_VALUES[name || 'default'];
    } else {
      return JSON.parse(value) || DEFAULT_VALUES[name || 'default']
    }
  },
  "set": function (name, values) {
    window.localStorage.setItem(name, typeof values !== 'string' ? JSON.stringify(values) : `${values}`)
    window.dispatchEvent(new Event(name)); // notify rxjs services store has updated!
    return;
  },
  "delete": function (name) {
    return window.localStorage.removeItem(name)
  },
}

// localstorage.set('dnd', DEFAULT_VALUES.dnd);
// localstorage.set('friends_options', DEFAULT_VALUES.friends_options);
// localstorage.set('jabraConfig', DEFAULT_VALUES.jabraConfig);
// localstorage.set('debug', DEFAULT_VALUES.debug);

// console.log(localstorage.get('debugging'))

// initial Default casts!
if(typeof localstorage.get('dnd') !== 'boolean') localstorage.set('dnd', DEFAULT_VALUES.dnd);
if(!localstorage.get('friends_options')) localstorage.set('friends_options', DEFAULT_VALUES.friends_options);
if(!localstorage.get('jabraConfig')) localstorage.set('jabraConfig', DEFAULT_VALUES.jabraConfig);
if(!localstorage.get('debugging')) localstorage.set('debugging', DEFAULT_VALUES.debugging);

export default localstorage;
