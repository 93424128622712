import React, { useState, useCallback, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { grey } from '@mui/material/colors';
import { makeStyles, withStyles, createStyles } from '@mui/styles';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import { Card, CardIcon, CardHeader, CardBody, CardFooter } from 'components/Card';

import { Grid, Badge, Checkbox, Tab, Tabs, Box, Chip, FormControlLabel, Switch, CircularProgress, Dialog, DialogContentText, ListItemText, MenuItem, List, InputAdornment, IconButton, Button, Divider, ListSubheader, ListItemIcon, ListItemAvatar, Avatar, ListItemSecondaryAction } from '@mui/material';
import { CardActions, CardActionArea, CardMedia, CardContent, Typography } from '@mui/material';

import RegularButton from "components/CustomButtons/Button";

import { SimpleLoader } from 'components/Loaders';

import AudioSample, { FileSaver, filenameSplitter } from 'Helpers/Announcements';

import { combinedArrayState } from 'components/Custom';

import { fileMetadata } from 'devtools/helper';

import { setFormValues } from 'store/form/reducer';

import { parse2ObjectAsync, parse2XMLSyncronous } from 'devtools/xml2js';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'components/Modals';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));


function generateDownload(canvas, crop) {
  if (!crop || !canvas) {
    return;
  }

  canvas.toBlob(
    (blob) => {
      const previewUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.download = 'cropPreview.png';
      anchor.href = URL.createObjectURL(blob);
      anchor.click();

      window.URL.revokeObjectURL(previewUrl);
    },
    'image/png',
    1
  );
}

const ImageCopper = ({ handleComplete, image, ...props}) => {
  const classes = useStyles();
  const { handleClose, open, scroll } = props;

  const [upImg, setUpImg] = useState();

  useEffect(() => {
    if(image) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(image);
    }
  }, [image]);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    // const scaleX = image.naturalWidth / image.width;
    // const scaleY = image.naturalHeight / image.height;
    // const ctx = canvas.getContext('2d');
    // const pixelRatio = window.devicePixelRatio;
    //
    // canvas.width = crop.width * pixelRatio * scaleX;
    // canvas.height = crop.height * pixelRatio * scaleY;
    //
    // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    // ctx.imageSmoothingQuality = 'high';
    //
    // ctx.drawImage(
    //   image,
    //   crop.x * scaleX,
    //   crop.y * scaleY,
    //   crop.width * scaleX,
    //   crop.height * scaleY,
    //   0,
    //   0,
    //   crop.width * scaleX,
    //   crop.height * scaleY
    // );
  }, [completedCrop]);



  return (
    <div style={{ display: open ? 'block' : 'none' }}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <CardHeader color="rose" style={{ width: '100%' }}>
          <Grid item container justifyContent='center' alignItems="center" spacing={2} style={{ color: 'black' }}>
            <Grid item xs>
              <h4 className={classes.cardTitleWhite}>Image cropper</h4>
            </Grid>
          </Grid>
        </CardHeader>
      </DialogTitle>
      <DialogContent dividers style={{ backgroundColor: grey[200] }}>
        <Grid container justifyContent="center" spacing={2} style={{ textAlign: 'center' }}>
          <Grid container item xs='auto' sm={6} justifyContent="center" direction="column">
            <Grid item>Loaded Image</Grid>
            <Grid item>
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
              />
            </Grid>
          </Grid>

          <Divider orientation="vertical" flexItem />

          <Grid container item xs sm={6} justifyContent="center" direction="column">
            <Grid item>Preview Image</Grid>
            <Grid item>
              <Box px='auto' display="flex" justifyContent="center" alignSelf="center">
                <Avatar ref={previewCanvasRef} style={{ height: 100, width: 100 }}></Avatar>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>

          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          disabled={!completedCrop?.width || !completedCrop?.height}
          onClick={() =>
            generateDownload(previewCanvasRef.current, completedCrop)
          }
        >
          Save
        </Button>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </div>
  );
}

export default ImageCopper;
