import React, { useState, useEffect, useReducer, Suspense } from 'react';
import { makeStyles } from '@mui/styles';

import { isEqual, omit } from 'lodash';

import {
  useDrawers,
} from 'Providers';

import cx from "classnames";

import {
  Box,
  Grid,
  Divider,
  Stack,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  ListSubheader,
  ListItemAvatar,
  Avatar,
  Chip,
  Fab,
  Collapse,
  Menu,
  MenuList,
  MenuItem,
  CircularProgress,
  ButtonGroup,
  Button,
} from '@mui/material';

import PersonAdd from '@mui/icons-material/PersonAdd';
import Logout from '@mui/icons-material/Logout';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallIcon from '@mui/icons-material/Call';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import EmailIcon from '@mui/icons-material/Email';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

// import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
// import CallMissedIcon from '@mui/icons-material/CallMissed';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import RinggroupsListComponent from './RinggroupsListComponent';
import ExtensionsListComponent from './ExtensionsListComponent';
import ColleaguesListComponent from './ColleaguesListComponent';

import { FriendsOptionsComponent } from '../Lists';

import { Service_localstorage } from 'Services';

import localstorage from 'localStorage';

const useStyles = makeStyles(theme => ({
  ButtonGroupDark: {
    "& button": {
      fontSize: '18px',
      color: '#e7e7e7',
      borderColor: '#000000 !important',
      backgroundColor: "#3a3a3a",
      '&:hover': {
        color: '#e7e7e7',
        backgroundColor: "#4c4c4c",
      }
    }
  }
}))

const ListActionsComponent = (props) => {
  const classes = useStyles();
  const { drawerAction } = useDrawers();

  return (
    <Box display='flex' justifyContent='center' py={2}>
      <ButtonGroup className={classes.ButtonGroupDark} variant="contained" size='small' aria-label="outlined primary button group">
        <HelperTooltipBootstrap title="Settings" placement='top' arrow>
          <Button
            onClick={drawerAction('OPEN', {}, FriendsOptionsComponent)}
          >
            <SettingsIcon fontSize='inherit' />
          </Button>
        </HelperTooltipBootstrap>
        {
          // <HelperTooltipBootstrap title="Add" placement='top' arrow>
          //   <Button>
          //     <PersonAddIcon fontSize='inherit' />
          //   </Button>
          // </HelperTooltipBootstrap>
          // <HelperTooltipBootstrap title="Order" placement='top' arrow>
          //   <Button>
          //     <FormatListNumberedIcon fontSize='inherit' />
          //   </Button>
          // </HelperTooltipBootstrap>
        }
      </ButtonGroup>
    </Box>
  )
}

const LoadingComponent = props => {
  return (
    <Box p={2} display="flex" justifyContent='center' spacing={2} alignItems='center'>
      <CircularProgress size={20}/>
      <Box pl={1}>Loading...</Box>
    </Box>
  )
}

const COMPONENTS = {
  'Ring Groups': <RinggroupsListComponent />,
  'Colleagues': <ColleaguesListComponent />,
  'Extensions': <ExtensionsListComponent />,
}


const ListComponents = ({ items }) => {
  return [...items].filter(item => item?.display).map(({ content }, index) => {
    return React.cloneElement(
      COMPONENTS[content],
      { key: index },
    )
  })
}


const FriendsListComponent = (props) => {
  const { data, isLoading } = props;

  return (
    <div>
      <Suspense fallback={<LoadingComponent />}>
        <ListActionsComponent />
        <ListComponents items={data?.friendsOrderAndDisplay ?? []} />
      </Suspense>
    </div>
  );
}

function areEqual(prev, next) {
  return JSON.stringify(omit(prev, ['data', 'isLoading'])) !== JSON.stringify(omit(next, ['data', 'isLoading']))
}

const INITIALSTATE = {
  loading: true,
  data: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET':
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    default:
      return state
  }
}

const logicalComponent = (Component) => (props) => {
  let mounted = true, service;
  const [state, dispatch] = useReducer(reducer, INITIALSTATE);

  useEffect(() => {
    service = new Service_localstorage("friends_options");
    subscribe();
    return () => {
      mounted = false;
      service?.unsubscribe();
    }
  }, [])

  const subscribe = () => {
    if(!mounted) return;
    try {
      service?.subscribe((value) => {
        if(mounted && value) dispatch({ type: "SET", payload: value });
      })
    } catch (e) {
      console.error(e)
    }
  }

  return <Component {...props} data={state.data ?? {}} isLoading={state.loading} />
}


export default logicalComponent(React.memo(FriendsListComponent, areEqual));
