import React from "react";
import { ProfileAvatar } from 'components/CustomAvatars';

import { PhoneStatusComponent } from 'Providers';

import { DarkerButton } from 'components/CustomButtons';

import {
  ProfileOptionsDrawer
} from '../Drawers';

import {
  Chip,
  Avatar,
  Button,
  CircularProgress
} from '@mui/material';

import {
  useDrawers,
} from 'Providers';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const ProfileButton = (props) => {
  const { drawerAction } = useDrawers();

  return (
    <PhoneStatusComponent>
      {
        (status) => {
          return (
            <DarkerButton
              id="demo-customized-button"
              variant="contained"
              startIcon={status !== 'Registered' ? <CircularProgress size={20} color="inherit" /> : <ProfileAvatar />}
              endIcon={<KeyboardArrowDownIcon />}
              onClick={drawerAction('OPEN', {}, ProfileOptionsDrawer)}
            >
              {status}
            </DarkerButton>
          )
        }
      }
    </PhoneStatusComponent>
  )
}

export default ProfileButton;
