import React, { useState } from 'react';

function reducer(state, action) {
  const storeValue = state.get(action.payload);

  switch (action.type) {
    case 'set': {
      state.set(action.payload, !storeValue);
    }
  }

  return new Map(state); // for rerenders
}

function useSimpleState(initialState = {}) {
  const [state, setState] = useState(() => {
    return new Map(Object.entries(initialState))
  });

  function dispatch(action) {
    return function (event) {
      const nextState = reducer(state, action);
      setState(nextState);
    }
  }

  return [
    Object.fromEntries(state),
    dispatch
  ];
}

export default useSimpleState;
