import React, { useEffect, useState } from 'react';
import { grey } from '@mui/material/colors';
import { connect } from 'react-redux';
// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import { merge } from 'lodash';

import { ThemeProvider } from '@mui/material/styles';
import MUIDataTable, { TableFooter } from "mui-datatables";

import { getMUIDataTableTheme, getMUIDataTableOptions } from 'constants/MUIDataTable';

import { service_missedcalls_unhandled } from 'Services';

import { Card, CardHeader, CardBody } from "components/Card";

import {
  Box,
  Chip,
  Avatar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  CircularProgress,
  Badge,
  Popover,
  Stack,
  Fab,
  Grid,
  Checkbox,
} from '@mui/material';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import TimelineComponent from "components/Timeline";
import { CallButton } from "components/CustomFabs";
import { SimpleLoader } from 'components/Loaders';
import { MissedCallsBadge } from 'components/CustomBadge';

//import Close from "@mui/icons-material/Close";
import CallIcon from  '@mui/icons-material/Call';
import AccessTimeIcon from  '@mui/icons-material/AccessTime';
import PhoneMissedIcon from  '@mui/icons-material/PhoneMissed';
import AssignmentTurnedInIcon from  '@mui/icons-material/AssignmentTurnedIn';

import { ContactPopoverClass } from 'Classes';

import ContactChip from './ContactChip';
import { ContactPopover } from 'WEBRTC/components/Popovers';

import { UnhandledMissedCallsCustomToolBarSelect } from 'components/DataTables';
import { LoadingStateComponent } from 'components/LoadingStates';

import { missedCallAction } from 'store/missedcalls/actions';
import { isLoading } from 'devtools/Helpers';

import { sleep } from 'Helpers';

import useSingleAndDoubleClick from 'Contexts/useSingleAndDoubleClick';

import { usePhone, useDropDown, withDropDown } from 'Providers';

import moment, { humanize } from 'Helpers/Momentjs';

import styles from "assets/jss/components/missedCallsStyle";
const useStyles = makeStyles(createStyles(styles));

function getTelNumber(str) {
  return `${str}`.match(/\+?\d+$/)?.pop() || null;
}

const UnhandledCalls = withDropDown(({ dispatch, ...props}) => {
  let mounted = true;
  const classes = useStyles();
  const { invite } = usePhone();
  const { handleClick } = useDropDown();
  const { data, isLoading } = props;

  const options = merge(getMUIDataTableOptions('missedcalls', data.length), {
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <UnhandledMissedCallsCustomToolBarSelect data={data} selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} />
    )
  })

  useEffect(() => {
    return () => mounted = false;
  }, [])

  const [anchorEl, setAnchorEl] = useState(null);
  const [id, setId] = useState(null);

  function call(target, contact, data) {
    if(mounted) {
      invite(getTelNumber(target), contact, { audio: true, video: false });
      dispatch(missedCallAction([data], { handled: 1, ok: 'Valmis' }))
    }
  }

  const onSingleClick = (event, target, contact, data) => {
    if(!mounted || !target) return;
    return;
  }

  const onDoubleClick = (event, target, contact, data) => {
    if(!mounted || !target) return;
    call(target, contact, data);
  }

  const handleClicks = useSingleAndDoubleClick(onSingleClick, onDoubleClick);


  const columns = [
    {
     name: "id",
     options: {
       display: false,
       //display: 'excluded',
       filter: false,
     }
    },
    {
      name: "caller_id_number",
      label: "From",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const dataset = data[tableMeta.currentTableData[tableMeta.rowIndex].index];
          const { caller_id_name, caller_id_number, soittaja, contact_uuid, id } = dataset;
          return (
            <ContactChip
              key={id + contact_uuid} // prevent rerenders on same key and unmounts!
              id={contact_uuid}
              title={(contact) => contact?.fullName || caller_id_name || soittaja}
              onAvatarClick={(event, contact) => handleClick(new ContactPopoverClass({ caller_id_name, caller_id_number, contact }))(event)}
            >
              {
                (contact, isLoading) => {
                  const useName = contact?.fullName || (caller_id_name && caller_id_number !== caller_id_name);

                  return (
                    <span>
                      { useName && <div style={{ fontWeight: 500 }}>{contact?.fullName || caller_id_name }</div>}
                      <a style={{ fontWeight: 500 }} onClick={event => handleClicks(event, caller_id_number || soittaja, contact, dataset)}>{caller_id_number || soittaja}</a>
                    </span>
                  )
                }
              }
            </ContactChip>
          )
        },
        filter: true,
        sort: true,
      }
    },
    {
      name: "soitettu",
      label: "To",
      options: {
        filter: true,
        sort: true,
      }
    },
   {
     name: "aika",
     label: "Datetime",
     filter: false,
     options: {
       customBodyRender: (value, tableMeta, updateValue) => {
         const dataset = data[tableMeta.currentTableData[tableMeta.rowIndex].index];
         let {
           callAttempts, // object || undefined
           aika
         } = dataset;
         const badgeContent = callAttempts && Object.keys(callAttempts).length > 0 ? `+${Object.keys(callAttempts).length}` : 0
         return (
           <MissedCallsBadge badgeContent={badgeContent}>
             <Box>
               {
                 aika &&
                   <HelperTooltipBootstrap
                     title={
                       <Box textAlign='center'>
                         <b>{ aika }</b>
                         <p>
                           <small>Click for more details</small>
                         </p>
                       </Box>
                     }
                     aria-label="aika"
                     placement='bottom'
                     arrow
                   >
                     <span>
                       <Chip
                        size='small'
                        onClick={(e) => handleClick(e, 'simple-popover' + aika)}
                        label={humanize(aika, "DD-MM-YYYY HH:mm:ss")}
                        sx={{
                          minWidth: '100px',
                          fontSize: '10px'
                        }}
                      />
                     </span>
                   </HelperTooltipBootstrap>
               }

               <Popover
                 id={'simple-popover' + aika }
                 open={'simple-popover' + aika === id}
                 anchorEl={anchorEl}
                 onClose={handleClose}
                 PaperProps={{
                   display: 'flex'
                 }}
                 anchorOrigin={{
                   vertical: 'bottom',
                   horizontal: 'right',
                 }}
                 transformOrigin={{
                   vertical: 'top',
                   horizontal: 'right',
                 }}
                 >
                   <Box py={1} px={2} style={{ minWidth: 400, backgroundColor: '#908a8ab5' }} >
                     <TimelineComponent
                       style={{ display: 'inline-block' }}
                       simple
                       stories={
                         [aika, ...Object.values(callAttempts || [])].map(attempt => {
                           return {
                             badgeColor: "danger",
                             badgeIcon: PhoneMissedIcon,
                             body: (
                               <Box width={1} display='block'>
                                 <Chip size='small' label={attempt}/>
                               </Box>
                             ),
                             footer: (
                               <Box width={1} fontSize={12} textAlign='right' display='flex' alignItems='center' justifyContent='space-between'>
                                 <AccessTimeIcon style={{ marginRight: 6 }} /> { humanize(attempt, "DD-MM-YYYY HH:mm:ss") }
                               </Box>
                             )
                           }
                         })
                       }
                     />
                   </Box>
                 </Popover>

             </Box>
           </MissedCallsBadge>
         )
       }
     },
     filter: false,
     sort: false,
   },
   {
    name: "actions",
    label: "Actions",
    filter: false, // Display column in filter list.
    options: {
      setCellHeaderProps: function (columnMeta) {
        return {
          style: {
            textAlign: 'center'
          }
        }
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        const dataset = data[tableMeta.currentTableData[tableMeta.rowIndex].index];
        const { soittaja, id } = dataset;
        return (
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={0}>
              {
                //<CallButton args={[id, { handled: 1, ok: 'Valmis' }]} href={soittaja}  />
              }
            <LoadingStateComponent>
              {
                ({ ref, loading, handleClickPromise, ...rest }) => (
                  <HelperTooltipBootstrap title={`Call | ${soittaja}`} placement='left'>
                    <span>
                      <Fab
                        ref={ref}
                        size="small"
                        color='primary'
                        aria-label="Call"
                        disabled={loading}
                        sx={{ width: 36, height: 36, zIndex: "auto" }}
                        onClick={(e) => {
                          invite(getTelNumber(soittaja), undefined, { audio: true, video: false}); // contact...
                          dispatch(missedCallAction([dataset], { handled: 1, ok: 'Poistettu' }));
                        }}
                        // onClick={
                        //   handleClickPromise(async(e) => {
                        //     return await new Promise((resolve, reject) => {
                        //       invite(
                        //         getTelNumber(soittaja),
                        //         undefined, // contact
                        //         { audio: true, video: false},
                        //         // {
                        //         //   onAccepted: resolve(),
                        //         //   onFailed: reject(),
                        //         // }
                        //       )
                        //     })
                        //     .then(() => dispatch(missedCallAction([dataset], { handled: 1, ok: 'Poistettu' })))
                        //   })
                        // }
                      >
                        {
                          loading
                            ? <CircularProgress color="inherit" size={16} />
                            : <CallIcon />
                        }
                      </Fab>
                    </span>
                  </HelperTooltipBootstrap>
                )
              }
            </LoadingStateComponent>

            <LoadingStateComponent>
              {
                ({ ref, loading, handleClickPromise, ...rest }) => (
                  <HelperTooltipBootstrap title="Mark as Handled" placement='right' arrow>
                    <IconButton
                      ref={ref}
                      onClick={
                        handleClickPromise(async(e) => {
                          return await dispatch(missedCallAction([dataset], { handled: 1, ok: 'Poistettu' }))
                        })
                      }
                    >
                      {
                        loading
                          ? <CircularProgress color="inherit" size={16} />
                          : <AssignmentTurnedInIcon />
                      }

                    </IconButton>
                  </HelperTooltipBootstrap>
                )
              }
            </LoadingStateComponent>


            <HelperTooltipBootstrap title="Mark as Handled" placement='right' arrow>
              <span>

              </span>
            </HelperTooltipBootstrap>
          </Stack>
        )
      },
      filter: false,
      sort: false,
    }
   }
  ];

  const handleasd = (event, id) => {
    setId(id)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setId(null)
    setAnchorEl(null);
  };

  return (
    <Card elevation={8}>
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>New Missed Calls</h4>
        <p className={classes.cardCategoryWhite}>
          Table shows list of calls that have not been handled within <b>30</b> days and Limited to <b>100</b> rows.
        </p>
      </CardHeader>
      <CardBody noPadding>
        <ThemeProvider theme={getMUIDataTableTheme('missedcalls')}>
          {
            isLoading
              ? (
                  <Grid container justifyContent="center" alignItems='center' spacing={3}>
                    <Grid item>
                      <Box p={2}>
                        <CircularProgress color='secondary' />
                      </Box>
                    </Grid>
                    <Grid item>
                      <b>Table Loading...</b>
                    </Grid>
                  </Grid>
                )
              : (
                  <MUIDataTable
                    data={data}
                    columns={columns}
                    options={options}
                    components={{
                      Checkbox: (props) => <Checkbox size='small' {...props} />,
                      Tooltip: (props) => <HelperTooltipBootstrap {...props} />,
                    }}
                  />
                )
          }
        </ThemeProvider>
      </CardBody>
    </Card>
  );
}, ContactPopover);

const logicalComponent = (Component) => (props) => {
  let mounted = true, subscription;
  const [data, setData] = useState(null);
  const { add_subscriber, remove_subscriber } = service_missedcalls_unhandled();

  useEffect(() => {
    (async() => {
      if(mounted) {
        subscription = add_subscriber(
    			value => {
    				if(mounted && value) setData(value)
    			},
    			err => console.warn("UnHandledCalls", err)
    		)
      }
    })();

    return () => {
      mounted = false;
      remove_subscriber(subscription);
    }
  }, [])

  return <Component {...props} data={data ?? []} isLoading={!Array.isArray(data)} />
}

// .filter(call => moment(call?.aika, "DD-MM-YYYY").isAfter( moment().subtract(30, 'days') )),

export default connect(
  null,
  null,
)(logicalComponent(UnhandledCalls))
