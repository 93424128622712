import React, { useEffect, useState, createRef, useRef } from 'react';
import { connect } from 'react-redux';

import { fromEvent } from 'rxjs';

import { Menu, MenuItem, Box, CircularProgress } from '@mui/material';

import { dids_Actions } from 'store/dids/actions';

const CalleridMenuPopper = ({ profile, classes, onChange, getNumbers, numbers, loading, clickElement, children, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const element = useRef(null);
  const menuRef = createRef();

  const useCountryCode = (str = '') => str.replace(/^0/, '+358');

  const handleClose = (event, value) => {
    if(value) onChange(value);
    setAnchorEl(null);
  }

  const parseTel = str => str.replace(/^0/, '+358');

  useEffect(() => {
    getNumbers();
    let click$ = fromEvent(
      clickElement.current,
      'click'
    ).subscribe(click => setAnchorEl(click.target));
    return () => click$.unsubscribe();
  }, []);

  const Loading = (props) => (
    <MenuItem
      disabled
    >
      <Box display="flex" justifyContent="center" px={'auto'}>
        Loading...
        <Box display="flex" justifyContent="center" pl={3}>
          <CircularProgress color="secondary" size={20} />
        </Box>
      </Box>
    </MenuItem>
  );

  const currentNumber = useCountryCode(profile?.number);

  return (
    <>
      <Menu
        ref={menuRef}
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(event) => handleClose(event)}
      >
        <MenuItem
          onClick={event => handleClose(event, currentNumber)}
          value={currentNumber}
        >
          <div style={{ width: '100%', padding: '0px 20px' }}>
            <span style={{ float: 'right' }}>{currentNumber}</span>
          </div>
        </MenuItem>

        {
          (() => {
            switch(numbers.length) {
              case 0:
                return loading ? <Loading {...props} /> : <MenuItem onClick={(event) => handleClose(event)}>No CallerIDs Found</MenuItem>
              default:
                return numbers.length > 0 ? numbers.map((option, index) => {
                  const value = parseTel(option.destination_number);
                  return (
                    <MenuItem
                      ref={menuRef}
                      key={index}
                      value={value}
                      onClick={(event) => handleClose(event, value)}
                    >
                      <div style={{ width: '100%', padding: '0px 20px' }}>
                        <span>{option.description}</span>
                        <span style={{ float: 'right' }}>{parseTel(option.destination_number)}</span>
                      </div>
                    </MenuItem>
                )}) : <MenuItem onClick={(event) => handleClose(event)}>No CallerIDs Found</MenuItem>
            }
          })()
        }

      </Menu>

      { children }

    </>
  );
};

const mapStateToProps = state => ({
  numbers: state.dids_State.numbers,
  loading: state.dids_State.loading
});

const mapDispatchToProps = dispatch => ({
  getNumbers: () => dispatch(dids_Actions())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalleridMenuPopper);
