import React, { useState } from 'react';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';

const STATUS = {
  PAIKALLA: 'success',
  POISSA: 'danger',
  KOKOUS: 'warning',
  LOMA: 'info',
  LOUNAS: 'warning',
}

const ProfileStateDropDown = ({ selected: selectedProp, onChange, ...props}) => {
  const [selected, setSelected] = useState(selectedProp);

  function handleClick (event, data) {
    setSelected(data);
    onChange(data);
  }

  return (
    <CustomDropdown
      hoverColor={STATUS[selected]}
      buttonText={selected}
      onClick={(e, data) => handleClick(e, data)}
      buttonProps={{
        round: true,
        fullWidth: true,
        style: { marginBottom: "0" },
        color: STATUS[selected]
      }}
      dropdownList={Object.keys(STATUS)}
    />
  );
};

ProfileStateDropDown.defaultProps = {
  selected: 'PAIKALLA'
};

export default ProfileStateDropDown;
