import {
  MODAL_OPEN,
  MODAL_CLOSE,
} from 'store/types';

import { onError } from 'store/Notifier/actions';

export const modal_Actions = object => {
  return async (dispatch, getState, firebase) => {
    try {
      if(object.open) {
        dispatch({ type:  MODAL_OPEN, payload: object });
      } else {
        dispatch({ type:  MODAL_CLOSE, payload: object });
      }
    } catch (err) {
      console.log(err);
      onError(dispatch, err);
    };
  }
};
