import React from 'react';
import { firebase, store } from 'index.js';

import { SubmissionError } from 'redux-form';

import { get, set, isEmpty } from 'lodash';

import { LOCAL_PHONENUMBER_REGEX } from 'constants/Regex';

import {
  isEmail,
  checkExtension
} from './synchronous';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

function extensionNotAvailable (value) {
  return store.getState().extensions.extensions.some(extension => extension.extension === Number(value));
}

export const asyncEmailAccountCheck = async ({ email }, dispatch, props, fieldName) => {
  try {
    const emailError = isEmail(email);
    if(emailError) throw emailError;

    const [{ value: response }] = await Promise.allSettled([
      firebase.accountExists(email),
      sleep(400)
    ])

    if(response.status !== 200) throw await response.json();
    const boolean = await response.text();

    if (boolean !== 'false') {
      throw 'That email is already taken!';
    }
  } catch (err) {
    throw { email: err };
  }
}

export const asyncExtensionCreatorCheck = async (values, dispatch, props, fieldName) => {
  const errors = {};
  let field, value;

  try {
    if(fieldName) {
      field = fieldName.split('.')[1];
      value = get(values, fieldName);
    }

    if(field === 'extension') {
      if (!value) {
        set(errors, fieldName, 'Required');
      } else if (!checkExtension(value)) {
        set(errors, fieldName, 'Extension not between 2000 - 2999');
      } else if (extensionNotAvailable(value)) {

        set(errors, fieldName, 'Extension already exists!');
      } else { }
    }

    if(field === 'number_alias') {
      if(!value) {
        set(errors, fieldName, 'Required');
      } else if (!LOCAL_PHONENUMBER_REGEX.test(value)) {
        set(errors, fieldName, 'Phonenumber does not match local format (050123456)');
      } else { }
    }

    if(!isEmpty(errors)) {
      return sleep(250).then(() => {
        throw { ...errors }
      })
    }
  } catch (err) {
    if(err instanceof TypeError) {
      console.log(err)
      return { submit: 'Submiting Error', _error: err };
    }
  }
}
