const success = [ 'background: green', 'color: white', 'display: block', 'text-align: center'].join(';');

class IceCandidates {
  static privateRanges = [
    /^10\..*/,
    /^192\.168\..*/,
    /^172\.(1[6-9]|2\d|3[01])\..*/,
    /^169\.254\..*/,
    /^\[.*\]$/
  ];
  static component = "rtp";
  static protocol = "udp";

  static isPublicAddess(ip) {
    if(!ip) return false;

    for (const range of this.privateRanges) {
      if (range.test(ip)) {
        return false;
      }
    }

    return true;
  };

  static isAcceptable(protocol, component) {
    return protocol === this.protocol && component === this.component;
  }

  static validateCandidate(candidate, ready) {
    try {
      if(this.isPublicAddess(candidate.address) && this.isAcceptable(candidate.protocol, candidate.component)) {
        console.info(`%c icecandidate VALID!", ${candidate.address}, ${candidate.relatedAddress}`, success)
        console.log(candidate)
        ready(); // use candidate
      } else {
        throw 'INVALID';
      }
    } catch (err) {
      console.warn("icecandidate: INVALID!", err, candidate.address, candidate.relatedAddress, candidate)
    }
  };
}

export default IceCandidates;
