import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { TextField, ListItemText, Checkbox, Avatar, ListItemAvatar, Autocomplete } from '@mui/material';

import CheckBoxOutlineBlankIcon from  '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from  '@mui/icons-material/CheckBox';
import DialerSipIcon from  '@mui/icons-material/DialerSip';
import PhoneAndroidIcon from  '@mui/icons-material/PhoneAndroid';

import AccountTreeIcon from  '@mui/icons-material/AccountTree';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FormExtensionRinggroupsAutocomplete = (
  { input: { onChange, value, onClose, name}, label, meta: { touched, error }, customTextField, loading, data, dispatch, ...custom },
) => {
  return (
    <Autocomplete
      multiple
      onChange={(event, value, reason) => {
        onChange(value)
      }}
      name={name}
      value={value}
      options={data}
      loading={loading}
      disableCloseOnSelect
      getOptionLabel={(option) => {
        if(option?.ring_group_description) {
          return `${option?.ring_group_description}`;
        } else if (option?.ring_group_name ) {
          return `${option?.ring_group_name}`;
        } else if (typeof option === 'object'){
          console.warn('fail', option);
          return `Error`;
        } else {
          return `${option}`;
        }
      }}
      renderInput={(params) =>
        <TextField
        {...params}
        label={label}
        {...customTextField} />
      }
      getOptionSelected={(option, val) => JSON.stringify(option) === JSON.stringify(val)}
      ChipProps={{
        color:"primary"
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <ListItemAvatar>
            <Avatar>
              <AccountTreeIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={option.ring_group_description || option.ring_group_name} secondary={option.ring_group_extension} />
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
        </React.Fragment>
      )}
      {...custom}
    />
  );
}

const mapStateToProps = state => ({
  loading: state.ringgroups.loading,
  data: state.ringgroups.ringgroups
});

export default connect(
  mapStateToProps,
  null
)(FormExtensionRinggroupsAutocomplete);
