import React from 'react';
import { grey } from '@mui/material/colors';
import { CHART_TITLES, CHART_LABELS, DATASET_SEARCH_PARAMS, DATASET_COLORS_PRIMARY, DATASET_COLORS_ADDITIONALS } from 'constants/charts';

import { v4 as uuidv4 } from 'uuid';

import {
  MISSEDCALLS_CHART_TITLES,
  MISSEDCALLS_CHART_LABELS,
  MISSEDCALLS_DATASET_SEARCH_PARAMS,
  MISSEDCALLS_DATASET_COLORS_PRIMARY,
  MISSEDCALLS_DATASET_COLORS_ADDITIONALS
} from 'constants/MissedCalls';

import { defaults } from 'react-chartjs-2';

import moment, { chartLabels, hoursAsLabels } from "Helpers/Momentjs";

import { mergeRecursive } from 'Helpers';

import { groupBy, pick, merge, partition, uniq } from 'lodash';

class DatasetsClass {
  constructor(datasets) {
    const datasetLabels = [];
    const datasetKeys = [];
    const useProps = [];

    for(let item of datasets) {
      datasetLabels.push(item?.label?.primary);
      datasetKeys.push(item?.label?.secondary || item?.label?.primary);
      useProps.push({
        'Any': '*',
        'Custom': '*',
        'Inbound Routes': 'caller_destination',
        'Extensions': 'extension_uuid'
      }[item.category]);
    }

    return {
      datasetLabels: datasetLabels,
      datasetKeys: datasetKeys,
      useProps: useProps
    }
  }
}


export function getSuggestedMaxFromDatasets(datasets) {
  const arr = datasets.reduce((accumulator, item) => {
    return [...accumulator, ...Object.values(item.data)];
  }, [])

  const max = Math.max(...arr);
  return Math.max(Math.ceil((max / 2) * 2.5), 10);
}

const ALL_CALLS_TEXT = 'All Calls';

function getFormat(view) {
  return {
    'Hourly View': 'HH[:00] [-] HH[:59]', // HH
    'Daily View': 'MMM[,] DD dd',
    'Monthly View': 'MMM',
  }[view];
}

function getLabels (format, view, { start, end }) {
  return {
    'Hourly View': hoursAsLabels(format),
    'Daily View': chartLabels([end.diff(start, 'days'), 'days'], format),
    'Monthly View': chartLabels([end.diff(start, 'months'), 'months'], format),
    'Handled By': ['Handled Calls'],
  }[view];
}

// function getLabelsHandledBy(data) {
//   return uniq(data.map(item => item.handledperson))
// }

function getTitle(page, { state, range: { start, end }, view }) {
  switch (state) {
    case 'Handled':
      return `Handled Calls with ${view} from ${start.format('MMMM[,] Do')} to ${end.format('MMMM[,] Do')}`;
      break;
    case 'Unhandled':
      return `All Missed Calls with ${view} from ${start.format('MMMM[,] Do')} to ${end.format('MMMM[,] Do')}`;
      break;
    default:
      return ``;
  }
}

function chartBackgroundColor(state) {
  return {
    'All': 'info',
    'Answered': 'success',
    'No Answer': 'danger',
    'Handled': 'success',
    'Unhandled': 'danger',
  }[state];
}

function getDatasetSearchParams(page, view, state) {
  const searchFormat = {
    missedcalls: 'DD-MM-YYYY HH:mm:ss',
    calllog: null,
  }[page];

  const prop = {
    'All': {
      missedcalls: 'aika',
      calllog: 'start_stamp',
    }[page],
    'Answered': 'answer_stamp',
    'No Answer': 'start_stamp',
    'Handled': 'handledtime',
    'Unhandled': 'aika',
  }[state];

  return { searchFormat, prop };
}

function* dataGenerator(data = [], all, prop) {
  const fullData = [...data];
  let search;
  if (!search) search = yield all ? data : [];

  while (true) {
    if(search === ALL_CALLS_TEXT) search = yield fullData;
    if (data.length < 1) return [];
    const [filtered, rest] = partition(data, (item) => {
      console.log(item, prop, search)
      return item[prop] === search;
    });

    data = rest;
    search = yield filtered || [];
  }
}

const CHART_OPTIONS = {
  plugins: {
    legend: {
      display: true,
      position: 'top',
      labels: {
        color: grey[400],
      }
    },
    title: {
      color: grey[300],
      fontSize: 16,
    }
  },
  scales: {
    x: {
      stacked: false,
      beginAtZero: true,
      suggestedMax: 10,
      display: true,
      ticks: {
        fontStyle: 'bold',
        stepSize: 1,
        display: true,
        color: grey[300],
      },
      grid: {
        display:true,
        color: '#e6e6e661',
      }
    },
    y: {
      stacked: false,
      beginAtZero: true,
      suggestedMax: 10,
      display: true,
      ticks: {
        fontStyle: 'bold',
        stepSize: 1,
        color: grey[300],
      },
      grid: {
        display:true,
        color: '#e6e6e661',
      }
    }
  },
  responsive: true,
}

export class CalllogChartOptions {
  constructor(filters, data = [], text_labels) {
    this.data = data;
    this.filters = filters;
    this.format = getFormat(filters.view);
    this.labels = getLabels(this.format, filters.view, filters.range);
    this.color = chartBackgroundColor(filters.state);
    this.text_labels = text_labels;

    return (async() => {
      try {
        const datasets = await this.dataset();

        const customOptions = {
          plugins: {
            title: {
              text: getTitle('calllog', filters),
              display: true,
            }
          },
          scales: {
            y: {
              suggestedMax: getSuggestedMaxFromDatasets(datasets)
            }
          }
        }

        return {
          id: uuidv4(),
          color: this.color,
          type: filters.type || 'bar',
          labels: this.labels,
          datasets: datasets,
          options: merge(JSON.parse(JSON.stringify(CHART_OPTIONS)), JSON.parse(JSON.stringify(customOptions))),
        };
      } catch (e) {
        console.log('err', e)
        throw e;
      }
    })()
  }

  datasetColors(index) {
    if( index === 0 ) {
      return DATASET_COLORS_PRIMARY[this.color];
    } else {
      return DATASET_COLORS_ADDITIONALS[index];
    }
  }

  labelText(label) {
    if(label === 'All Calls') return label;
    const result = this.text_labels.find(x => x?.value === label)?.label?.primary;
    return result ? result : label;
  }

  async dataset() {
    const { datasetLabels, datasetKeys, useProps }  = new DatasetsClass(this.filters.selectedDatasets);

    // const datasetLabels = this.filters.selectedDatasets.reduce((accumulator, item) => {
    //   return [...accumulator, item.label.primary];
    // }, [])
    //
    // const datasetKeys = this.filters.selectedDatasets.reduce((accumulator, item) => {
    //   return [...accumulator, item?.label?.secondary || item?.label?.primary];
    // }, [])

    const allCallsExists = datasetKeys.includes(ALL_CALLS_TEXT);

    // filter out all answered calls & No Answer
    // if(this.filters.state !== 'All') {
    //   this.data = this.data.filter(call => call.callstate === this.filters.state);
    // }

    // if(this.filters.state === 'No Answer') {
    //   this.data = this.data.filter(call => call.callstate === 'No Answer');
    // }

    const { searchFormat, prop: seacthProp } = getDatasetSearchParams('calllog', this.filters.view, this.filters.state);

    let [items, index] = [{}, 0];
    const generator = dataGenerator(this.data, allCallsExists, this.filters.direction === 'outbound' ? 'caller_id_number' : 'caller_destination');
    generator.next();  // iniates first empty

    for(let label of datasetKeys) {
      //const { value, done } = generator.next(label);

      console.log(label)

      items[label] = generator.next(label).value;
    }

    //console.log('items', items)

    const datasets = Object.entries(items).map(([datasetKey, calls], labelIndex) => {
      const dataset = [...this.labels].reduce((ac,a) => ({...ac,[a]: 0}),{});

      for (let item of calls) {
        const index = moment(item[seacthProp], searchFormat).format(this.format);
        //if(item[seacthProp] === null) console.log(item, seacthProp, this.filters.view, this.filters.state )

        if(index !== 'Invalid date') {
          //console.log('index', index, 'time',  Moment.command(moment => moment(item[seacthProp], searchFormat).format('DD MM YYYY')))
          //console.log(this.color, Moment.command(moment => moment(item[seacthProp], searchFormat || "").format('DD/MM/YYYY')))
          dataset[index]++ // increment obj seacthProp result calllog
        } else {
          console.log('error', item, index)
        }
      }

      let finalDataset = [];
      if(this.filters.view === 'Hourly View') {
        for (let [key, value] of new Map(Object.entries(dataset))) {
          finalDataset.splice( parseInt(key), 0, value )
        };
      } else {
        finalDataset = Object.values(dataset);
      }

      const { backgroundColor, borderColor } = this.datasetColors(labelIndex);
      return {
        label: datasetLabels[labelIndex],
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        data: finalDataset,
        fill: true,
        //data: [0, 10, 5, 2, 20, 30, 45],
      }
    })

    //console.log(datasets)
    return datasets;
  }
}

export class MissedCallsChartOptions {
  constructor(filters, data = [], text_labels) {
    //console.log(filters, data, text_labels)

    this.data = data;
    this.filters = filters;
    this.format = getFormat(filters.view);
    this.labels = getLabels(this.format, filters.view, filters.range);
    this.color = chartBackgroundColor(filters.state);
    this.text_labels = text_labels;

    return (async() => {
      try {
        const datasets = this.filters.view === 'Handled By' ? await this.handledByDataset() : await this.dataset();

        const customOptions = {
          plugins: {
            title: {
              text: getTitle('missedcalls', filters),
              display: true,
            },
            legend: {
              labels: {
                color: grey[300]
              }
            }
          },
          scales: {
            y: {
              suggestedMax: getSuggestedMaxFromDatasets(datasets),
              beginAtZero: true,
              step: 1,
            }
          }
        }

        return {
          id: uuidv4(),
          color: this.color,
          type: filters.type || 'bar',
          labels: this.labels,
          datasets: datasets,
          options: merge(JSON.parse(JSON.stringify(CHART_OPTIONS)), JSON.parse(JSON.stringify(customOptions))),
        };
      } catch (e) {
        console.log('err', e)
        throw e;
      }
    })()
  }

  datasetColors(index) {
    if( index === 0 ) {
      return MISSEDCALLS_DATASET_COLORS_PRIMARY[this.color];
    } else {
      return MISSEDCALLS_DATASET_COLORS_ADDITIONALS[index];
    }
  }

  labelText(label) {
    if(label === ALL_CALLS_TEXT) return label;
    const result = this.text_labels.find(x => x?.value === label)?.label?.primary;
    return result ? result : label;
  }

  async dataset() {
    const selectedKeys = this.filters.selectedDatasets.reduce((accumulator, item) => {
      return [...accumulator, item.value];
    }, []);

    const allCallsExists = selectedKeys.includes(ALL_CALLS_TEXT);


    //if(!selectedKeys.includes('All Calls')) this.data = this.data.filter(item => selectedKeys.includes(item.soitettu)) // filter calls out, if not 'All Calls'

    const { searchFormat, prop: seacthProp } = getDatasetSearchParams('missedcalls', this.filters.view, this.filters.state);
    this.data = this.data.filter(item => {
      // filter data out!!!
      return moment(item[seacthProp], searchFormat).isBetween(this.filters.range.start, this.filters.range.end);
    });

    let [items, index] = [{}, 0];

    const generator = dataGenerator(this.data, allCallsExists, 'soitettu');
    generator.next();  // iniates first empty

    for(let label of selectedKeys) {
      //const { value, done } = generator.next(label);
      items[label] = generator.next(label).value;
    }

    const datasets = Object.entries(items).map(([labelText, calls], labelIndex) => {
      const dataset = [...this.labels].reduce((ac,a) => ({...ac,[a]: 0}),{});

      for (let item of calls) {
        const index = moment(item[seacthProp], searchFormat).format(this.format);
        //if(item[seacthProp] === null) console.log(item, seacthProp, this.filters.view, this.filters.state )
        if(index !== 'Invalid date') {
          //console.log('index', index, 'time',  Moment.command(moment => moment(item[seacthProp], searchFormat || "").format('DD MM YYYY')))
          //console.log(this.color, Moment.command(moment => moment(item[seacthProp], searchFormat || "").format('DD/MM/YYYY')))
          dataset[index]++ // increment obj seacthProp result calllog
        } else {
          console.log('error', item, index)
        }
      }

      let finalDataset = [];
      if(this.filters.view === 'Hourly View') {
        for (let [key, value] of new Map(Object.entries(dataset))) {
          finalDataset.splice( parseInt(key), 0, value )
        };
      } else {
        finalDataset = Object.values(dataset);
      }

      const { backgroundColor, borderColor } = this.datasetColors(labelIndex);
      return {
        label: labelText,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        data: finalDataset,
        fill: true,
        //data: [0, 10, 5, 2, 20, 30, 45],
      }
    })
    return datasets;
  }

  async handledByDataset() {
    const { searchFormat, prop: seacthProp } = getDatasetSearchParams('missedcalls', this.filters.view, this.filters.state);
    this.data = this.data.filter(item => {
      // filter data out!!!
      return moment(item[seacthProp], searchFormat).isBetween(this.filters.range.start, this.filters.range.end);
    });

    const selectedKeys = uniq(this.data.map(item => item.handledperson));

    let dataset = selectedKeys.reduce((acc,curr)=> (acc[curr]=0,acc),{}); // { janne hailikari: 0 }
    for(let call of this.data) {
      dataset[call.handledperson] = dataset[call.handledperson] + 1;
    }

    const datasets = Object.entries(dataset).map(([labelText, calls], labelIndex) => {
      console.log(labelText, calls)
      const { backgroundColor, borderColor } = this.datasetColors(labelIndex);
      return {
        label: labelText,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        data: [calls],
        fill: true,
        //data: [0, 10, 5, 2, 20, 30, 45],
      }
    })
    return datasets;
  }
}

// {
//   "options":{
//     "plugins":{
//       "legend":{
//         "display":true,
//         "labels":{
//           "color":"#bdbdbd"
//         }
//       },
//       "title":{
//         "color":"#e0e0e0",
//         "fontSize":16,
//         "text":"All Calls with Hourly View from December, 8th to January, 7th",
//         "display":true
//       }
//     },
//     "scales":{
//       "x":{
//         "stacked":false,
//         "beginAtZero":true,
//         "suggestedMax":10,
//         "display":true,
//         "ticks":{
//           "fontStyle":"bold",
//           "stepSize":1,
//           "display":true,
//           "color":"#e0e0e0"
//         },
//         "grid":{
//           "display":true,
//           "color":"#e6e6e661"
//         }
//       },
//       "y":{
//         "stacked":false,
//         "beginAtZero":true,
//         "suggestedMax":10,
//         "display":true,
//         "ticks":{
//           "fontStyle":"bold",
//           "stepSize":1,
//           "color":"#e0e0e0"
//         },
//         "grid":{
//           "display":true,
//           "color":"#e6e6e661"
//         },
//         "step":1
//       }
//     },
//     "responsive":true
//   }
// }
