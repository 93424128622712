import { v4 as uuidv4 } from 'uuid';

const EXTENSION_START = 2000;
const EXTENSION_END = 2999;
//
// export function* getAvailableExtension(initials, extensionStart = EXTENSION_START, extensionEnd = EXTENSION_END) {
//   // required extensions as number, extensionStart = 2000
//   let arr = [...initials];
//   //let missing = new Array();
//
//   for (let i = extensionStart; i <= extensionEnd; i++) {
//     if (arr.indexOf(i) == -1) {
//       //missing.push(i);
//       console.log(i)
//       let removed = yield i;
//       if(removed) {
//         yield;
//         i = (removed - 2);
//
//       }
//     }
//   }
// }


export function* getAvailableExtension(initials, extensionStart = EXTENSION_START) {
  let list = [...initials];
  while (extensionStart <= EXTENSION_END) {
    if (!list.includes(extensionStart)) {
      list = [extensionStart, ...list]; // add item to array

      console.log(extensionStart, list)
      let reset = yield extensionStart;
      if (reset) {
        extensionStart = EXTENSION_START;
        yield list.splice(list.indexOf(reset), 1);
        console.log(list)
      }
    }
    extensionStart++
  }
}

// export function* getAvailableExtension(initials, extensionStart = EXTENSION_START) {
//   // required extensions as number, extensionStart = 2000
//   let list = [...initials];
//   while (extensionStart <= EXTENSION_END) {
//
//     console.log(extensionStart, list)
//
//     if (!list.includes(extensionStart)) {
//       list = [extensionStart, ...list];
//       let removed = yield extensionStart;
//       if(removed) {
//         console.log(removed)
//         list.splice(list.indexOf(removed), 1);
//         extensionStart = EXTENSION_START;
//         yield extensionStart;
//       }
//     }
//     extensionStart++;
//   }
// }

export class Extension {
  constructor(props = {}) {
    const { extension = EXTENSION_START } = props;
    return {
      id: uuidv4(),
      absolute_codec_string: null,
      accountcode: null,
      absolute_codec_string: null,
      accountcode: null,
      auth_acl: null,
      call_group: null,
      call_screen_enabled: false,
      call_timeout: 30,
      cidr: null,
      description: null,
      destination: null,
      dial_domain: null,
      dial_string: null,
      dial_user: null,
      directory_exten_visible: true,
      directory_first_name: null,
      directory_last_name: null,
      directory_visible: true,
      do_not_disturb: false,
      //domain_uuid": "feb1515b-f503-41aa-8bb2-e6f6549a0b5b",
      effective_caller_id_name: null,
      effective_caller_id_number: null,
      emergency_caller_id_name: null,
      emergency_caller_id_number: null,
      enabled: true,
      extension: extension,
      extension_uuid: uuidv4(),
      follow_me_destinations: null,
      follow_me_enabled: null,
      follow_me_uuid: uuidv4(),
      force_ping: null,
      forward_caller_id_uuid: null,
      hold_music: null,
      limit_destination: "error/user_busy",
      limit_max: 5,
      missed_call_app: null,
      missed_call_data: null,
      mwi_account: null,
      nibble_account: null,
      number_alias: null,
      outbound_caller_id_name: null,
      outbound_caller_id_number: null,
      sip_bypass_media: null,
      sip_client: false,
      sip_force_contact: null,
      sip_force_expires: null,
      sip_uri: null,
      toll_allow: null,
      unique_id: null,
      //user_context: "gbctest.vaihde.io",
      user_record: null,
    }
  }
}
