import React, { useState, useEffect, useCallback, useReducer } from "react";

import { useDrawers } from 'Providers';

import {
  Box,
  Avatar,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Switch,
  Button,
  IconButton,
  ListItemButton,
} from '@mui/material';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import PasswordChangeDrawer from '../PasswordChangeDrawer';

import FingerprintIcon from '@mui/icons-material/Fingerprint';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';


const PasswordChangeOption = () => {
  const { drawerAction } = useDrawers();

  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItemButton onClick={drawerAction('OPEN', { sx: { backgroundColor: "antiquewhite" }}, PasswordChangeDrawer)} >
        <ListItemIcon sx={{ justifyContent: 'center'}}>
          <FingerprintIcon/>
        </ListItemIcon>
        <ListItemText
          primary="Change Password"
          secondary={
            <small>
              {" — you are required to have a recent login"}
            </small>
          }
        />
      </ListItemButton>
    </List>
  );
}

export default PasswordChangeOption;
