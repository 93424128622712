import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';
import Button from "@mui/material/Button";

import styles from "assets/jss/components/paginationStyle.js";

const useStyles = makeStyles(createStyles(styles));

export default function Paginations(props) {
  const classes = useStyles();
  const { pages, color } = props;
  return (
    <ul className={classes.pagination} {...props}>
      {pages.map((prop, key) => {
        const paginationLink = cx({
          [classes.paginationLink]: true,
          [classes[color]]: prop.active,
          [classes.disabled]: prop.disabled
        });
        return (
          <li className={classes.paginationItem} key={key}>
            {prop.onClick !== undefined ? (
              <Button onClick={prop.onClick} className={paginationLink}>
                {prop.text}
              </Button>
            ) : (
              <Button
                onClick={() => alert("you've clicked " + prop.text)}
                className={paginationLink}
              >
                {prop.text}
              </Button>
            )}
          </li>
        );
      })}
    </ul>
  );
}

Paginations.defaultProps = {
  color: "info"
};

Paginations.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      disabled: PropTypes.bool,
      onClick: PropTypes.func
    })
  ).isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};
