import React, { useState } from 'react';

import {
  List,
  ListSubheader,
  MenuItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


const NestedList = ({ children, list, stopPropagation, ...props }) => {
  const [open, setOpen] = useState(false);
  const handleClick = event => {
    if(stopPropagation) event.stopPropagation(); // dont clone menu!
    setOpen(!open)
  };

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      {...props}
    >
      <MenuItem onClick={handleClick}>
        { children }
        {open ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        { list }
      </Collapse>
    </List>
  );
}

NestedList.defaultProps = {
  stopPropagation: true
}

export default NestedList;
