import React, { memo } from "react";
import { connect } from 'react-redux';
import { uniqBy } from 'lodash';

import { grey } from '@mui/material/colors';

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components

import AccountTreeIcon from  '@mui/icons-material/AccountTree';
import CheckBoxOutlineBlankIcon from  '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from  '@mui/icons-material/CheckBox';

import { Box, Checkbox, ListItemAvatar, Avatar, ListItemText } from '@mui/material';

import {
  FormCreativeAutoComplete,
  getOptionSelected_ObjectMatch
} from './AutoComplete';

function areEqual(prevProps, nextProps) {
  return prevProps.loading !== nextProps.loading || prevProps.options !== nextProps.options
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FormCreativeRinggroupsAutoComplete = ({ dispatch, ...props}) => {
  return (
    <FormCreativeAutoComplete
      multiple
      disableCloseOnSelect // dont close on item select
      defaultValue={[]}
      limitTags={3}
      ChipProps={{
        color: "primary"
      }}
      getOptionLabel={(option) => String(option?.ring_group_description || option?.ring_group_name || option)}
      getOptionSelected={getOptionSelected_ObjectMatch}
      renderOption={(option, { selected }) => {
        return (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <Box pr={2}>
              <Avatar>
                <AccountTreeIcon />
              </Avatar>
            </Box>
            <ListItemText
              primary={String(option?.ring_group_extension)}
              secondary={String(option?.ring_group_description || option?.ring_group_name)}
            />
          </React.Fragment>
        )
      }}
      startAdornment={(
        <AccountTreeIcon />
      )}
      {...props}
    />
  );
}


FormCreativeRinggroupsAutoComplete.defaultProps = {
  id: 'FormRinggroupsAutoComplete',
};

const mapStateToProps = state => ({
  loading: state.ringgroups.loading,
  options: state.ringgroups.ringgroups
});

export default memo(
  connect(
    mapStateToProps,
    null
  )(FormCreativeRinggroupsAutoComplete),
  areEqual
)
