import React, { useEffect, useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// import { webHidPairing } from '@gnaudio/jabra-js';
// import { initializeHID } from 'HID/devices';

import { useLocation, useNavigate } from 'react-router-dom';

import { withAnimation } from 'HOC';

import { NavLink } from "react-router-dom";

import _package from '../../../package.json';

import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';

// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';

import {
  Grid,
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Stack,
  Tabs,
  Tab,
  Button,
  Box,
  Zoom,
  Chip,
  Avatar,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography
} from '@mui/material';

// material-ui icons
import Menu from "@mui/icons-material/Menu";
import MenuIcon from '@mui/icons-material/Menu';
import MoreVert from "@mui/icons-material/MoreVert";
import ViewList from "@mui/icons-material/ViewList";
import StarIcon from '@mui/icons-material/Star';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import {
  WebhidConsentButton,
  WebhidButtons,
} from "HID";

import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import DarkModeContext from 'Contexts/DarkModeContext';

//import { withWebRTCPhone } from 'components/WebRTC';

import { routes } from 'routes';

import { Card } from 'components/Card';

// core components
import MainNavbarLinks from "./MainNavbarLinks";
import RegularButton from "components/CustomButtons";
import { DarkerButton } from 'components/CustomButtons';

import ProfileButton from 'WEBRTC/components/Buttons/ProfileButton';
import DNDButton from 'WEBRTC/components/DNDButton';

import useScrollTrigger from '@mui/material/useScrollTrigger';
import { usePhone } from 'Providers';

import localStorage from 'WEBRTC/localStorage';

import styles from "assets/jss/components/mainNavbarStyle.js";
const useStyles = makeStyles(styles);

const drawerWidth = 300;

function a11yProps(index, item) {
  const { name, route: {component, path, ...rest}} = item;

  return {
    id: `tab-${index}`,
    disableRipple: true,
    'aria-controls': `tabpanel-${index}`,
    label: name,
    icon: item?.icon && <item.icon/>,
    iconPosition: "start",
  };
}

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 9 : 6,
  });
}

function activeRoute(route) {
  return route && window.location.href.indexOf(route) > -1 ? true : false;
};

const croutes = [
  { path: "/", title: 'Missed Calls'},
  { path: "/contacts", title: 'Contacts'},
  { path: "/organizations", title: 'Organizations'},
  { path: "/logout", title: 'Logout'},
]

const MainNavbar = (props) => {
  let mounted = true;
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { doNotDisturb, toggleDND } = usePhone();

  const routesFiltered = routes.filter(route => route.display.MainNavbar);

  //const colorMode = React.useContext(DarkModeContext);

  const [value, setValue] = useState(() => croutes.findIndex((item) => {
    const pattern = pathname.split("/")[1];
    return item.path === "/" + pattern
  }));

  useEffect(() => {
    return () => mounted = false;
  }, [])

  const handleChange = async (event, index) => {
    navigate(routesFiltered[index].route.path);
    setValue(index);
  };

  return (
    <ElevationScroll {...props}>
      <AppBar
        position="fixed"
        color={doNotDisturb ? 'error' : 'primary'}
        classes={{
          colorPrimary: classes.somicHeader
        }}
        sx={{
          width: `calc(100% - ${drawerWidth}px)`, mr: `${drawerWidth}px`
        }}
        elevation={8}
      >
        <Toolbar>
          {
            routesFiltered && (
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="icon position tabs example"
                sx={{ mr: "auto" }}
                classes={{
                  indicator: classes.somicTabsIndicatorPill
                }}
              >
                {
                  routesFiltered.map((item, index) => {
                    return <Tab
                      key={index}
                      {...a11yProps(index, item)}
                      classes={{
                        root: classes.somicTab,
                        selected: classes.somicTabsIndicatorPillselected
                      }}
                    />
                  })
                }
                {
                  //<Tab value={-1} id={`tab--1`} />
                }
              </Tabs>
            )
          }

          <Stack direction="row" spacing={2} sx={{ marginRight: "20px" }}>
            {
              // {theme.palette.mode} mode
              // <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
              //   <Brightness7Icon />
              // </IconButton>
            }

            <DNDButton value={doNotDisturb} handleChange={toggleDND} />

            <ProfileButton />

            <Box sx={{ mx: 5, lineheight: 8 }}>
              <Typography
                gutterBottom
                sx={{ marginBlock: 0, fontFamily: "BellGothicBlack", fontSize: "30px", letterSpacing: "2px !important", textAlign: "center", lineHeight: "24px" }}
              >
                somic
              </Typography>
              <Typography
                gutterBottom
                sx={{  marginBlock: 0, fontFamily: "BellGothiclight", fontSize: "16px", letterSpacing: "2px !important", textAlign: "center" }}
              >
                connect
              </Typography>
            </Box>

          </Stack>


        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
}

export default MainNavbar;
