import React from 'react';
import { withStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

import { Fab } from '@mui/material';

export const WhiteFab = withStyles({
  root: {
    color: grey[800],
    backgroundColor: grey[100],
    "&:hover": {
      color: 'inherit',
      backgroundColor: grey[300],
    }
  },
  label: {
    display: 'block',
    top: '-5px'
  },
})(Fab);
