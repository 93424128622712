import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';
import { Grid, Input, Box, Zoom, InputLabel, FormControl, FormHelperText, InputAdornment, MenuItem, Autocomplete } from '@mui/material';

import CheckIcon from  '@mui/icons-material/Check';

import styles from "assets/jss/components/formInputStyle.js";
const useStyles = makeStyles(createStyles(styles));

const FormCreativeAutoComplete = (
  { input: { onChange, name, value, onClose, ...input }, meta: { asyncValidating, touched, dirty, error, warning, valid }, ...props }
) => {
  const classes = useStyles();
  const {
    endAdornment,
    startAdornment,
    formControlProps,
    label,
    id,
    labelProps,
    white,
    inputRootCustomClasses,
    multiple,
    dragable,
    disableCheck,
    updateOnChange,
    ...rest
  } = props;

  const showError = dirty && error;
  const success = valid && dirty && !showError ? true : false;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: showError,
    [" " + classes.labelRootSuccess]: success && !showError
  });
  const underlineClasses = classNames({
    [classes.underlineError]: showError,
    [classes.underlineSuccess]: success && !showError,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.multiple]: multiple,
    [classes.whiteInput]: white
  });

  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }

  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !showError
  });

  //input
  // onBlur: ƒ (event)
  // onDragStart: ƒ (event)
  // onDrop: ƒ (event)
  // onFocus: ƒ (event)

  return (
    <Grid container alignItems='center' spacing={2}>
      <Grid item xs>
        <Autocomplete
          multiple={multiple}
          onChange={(event, value, reason) => onChange(value)}
          name={name}
          value={
            multiple
              ? value || []
              : value
          }
          groupBy={(option) => option.category}
          renderInput={({ InputProps: { startAdornment: _startAdornment, ...InputProps}, InputLabelProps, ...params}) => {
            return (
              <FormControl {...formControlProps} className={formControlClasses}>
                <InputLabel
                  className={classes.labelRoot + " " + labelClasses}
                  htmlFor={id}
                  {...labelProps}
                  {...InputLabelProps}
                >
                  {label}
                </InputLabel>
                <Input
                  classes={{
                    input: inputClasses,
                    root: marginTop,
                    disabled: classes.disabled,
                    underline: underlineClasses
                  }}
                  id={id}
                  {...params}
                  {...InputProps}
                  startAdornment={(
                    <InputAdornment position="start">
                      {startAdornment}
                      {_startAdornment}
                    </InputAdornment>
                  )}
                />
              </FormControl>
            )
          }}
          {...rest}
        />
      </Grid>
      <Grid item xs='auto'>
        {
          asyncValidating
            ? <Zoom in>
                <CheckIcon className={classes.labelRootSuccess} />
              </Zoom>
            : !disableCheck && valid && touched &&
                <Zoom in>
                  <CheckIcon className={classes.labelRootSuccess} />
                </Zoom>
        }
      </Grid>
    </Grid>
  )
}

FormCreativeAutoComplete.defaultProps = {
  dragable: false,
  updateOnChange: false,
  disableCheck: false,
};

FormCreativeAutoComplete.propTypes = {
  label: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node
};

export default FormCreativeAutoComplete;
