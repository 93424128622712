import React, { memo } from "react";
import { connect } from 'react-redux';
import { uniqBy } from 'lodash';

import { grey } from '@mui/material/colors';

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components

import CallIcon from "@mui/icons-material/Call";

import { ListItemAvatar, Avatar, ListItemText } from '@mui/material';

import {
  FormCreativeAutoComplete,
  getOptionSelected_PropertyMatch
} from './AutoComplete';

function areEqual(prevProps, nextProps) {
  return prevProps.loading !== nextProps.loading || prevProps.options !== nextProps.options
}

const FormCreativeCallerIDAutoComplete = ({ dispatch, options, property, ...props}) => {
  options = uniqBy(options, property); // remove duplicates

  return (
    <FormCreativeAutoComplete
      options={options}
      getOptionLabel={(option) => String(option?.[property] || option)}
      getOptionSelected={getOptionSelected_PropertyMatch(property)}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <ListItemAvatar>
            <Avatar>
              <CallIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={option.description}
            secondary={option?.[property]}
          />
        </React.Fragment>
      )}
      startAdornment={(
        <CallIcon />
      )}
      {...props}
    />
  );
}


// const FormCalleridAutoComplete = (
//   { input: { onChange, onClose, name, ...input}, label, meta: { touched, error }, customTextField, options, dispatch, property, ...custom },
// ) => {
//   options = uniqBy(options, property); // remove duplicates
//
//   return (
//     <Autocomplete
//       onChange={(event, value, reason) => {
//         // onChange(event, value)
//         onChange(value)
//       }}
//       {...input}
//       name={name}
//       groupBy={(option) => option.category}
//       options={[...options]}
//       getOptionLabel={(option) => String(option?.[property] || option)}
//       getOptionSelected={(option, value) => {
//         //console.log(option, value)
//         return option[property] === value
//       }}
//       renderInput={({ InputProps, ...params}) => {
//         return (
//           <TextField
//             label={label}
//             placeholder="-"
//             {...params}
//             {...customTextField}
//             InputProps={{
//               ...InputProps,
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <CallIcon style={{ color: grey[500] }}/>
//                 </InputAdornment>
//               ),
//             }}
//           />
//         )
//       }}
//       renderOption={(option, { selected }) => (
//         <React.Fragment>
//           <ListItemAvatar>
//             <Avatar>
//               <CallIcon />
//             </Avatar>
//           </ListItemAvatar>
//           <ListItemText
//             primary={option.description}
//             secondary={option?.[property]}
//           />
//         </React.Fragment>
//       )}
//       {...custom}
//     />
//   );
// }


FormCreativeCallerIDAutoComplete.defaultProps = {
  property: 'cc_number',
};

const mapStateToProps = state => ({
  loading: state.numbers.loading,
  options: state.numbers.cids
});

export default memo(
  connect(
    mapStateToProps,
    null
  )(FormCreativeCallerIDAutoComplete),
  areEqual
)
