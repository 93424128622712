import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';
import { TextField, ListItemText, ListItemAvatar, Avatar, InputAdornment, Autocomplete } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 12
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const FormUserAccountAutoComplete = (
  { input: { onChange, onClose, name, ...input}, label, meta: { touched, error }, customTextField, loading, data, ...custom },
) => {
  const classes = useStyles();
  return (
    <Autocomplete
      onChange={(event, value, reason) => {
        onChange(value)
      }}
      {...input}
      name={name}
      options={data}
      loading={loading}
      // getOptionSelected={(option, value) => option?.id === value}
      getOptionLabel={(option) => option.username}
      // getOptionLabel={(option) => {
      //   const value = typeof option === 'object' ? option?.username : data.find(x => x.id === option)?.username || "";
      //   return String(value);
      // }}
      renderInput={(params) =>
        <TextField
          {...params}
          {...input}
          label={label}
          {...customTextField}
        />
      }
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <ListItemAvatar>
            <Avatar
              className={classes.small}
              style={{
                backgroundColor: option?.avatarColor || '#bdbdbd',
                color: '#fff'
              }}
              { ...option?.image && { src: option?.image } }
            >
              {option?.altName || '?' }
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={option.username} />
        </React.Fragment>
      )}
      {...custom}
    />
  );
}

FormUserAccountAutoComplete.defaultProps = {
  data: [],
  loading: false,
};

export default FormUserAccountAutoComplete;
