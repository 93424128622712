import React from 'react';

import ExpandMore from '@mui/icons-material/ExpandMore';

const ExpandIcons = ({ state }) => {
  return state
    ? <ExpandMore />
    : <ExpandMore sx={{ transform: 'rotate(90deg)' }} />
}

export default ExpandIcons;
