import React, { useState }  from 'react';
import { connect } from 'react-redux';

// import { withModal } from 'HOC';

import { Form, Field, change, reduxForm, SubmissionError, formValueSelector, submit } from 'redux-form';

import { Card, CardHeader, CardBody, CardFooter } from "components/Card";

import AudiotrackIcon from  '@mui/icons-material/Audiotrack';
import SearchIcon from  '@mui/icons-material/Search';
import DeleteIcon from  '@mui/icons-material/Delete';
import AddCircleIcon from  '@mui/icons-material/AddCircle';

import { Grid, Fade, Box, Chip, InputAdornment, TextField, Collapse, TableRow, TableCell, Checkbox } from '@mui/material';


import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import { FormTextField, RemoteSubmitAndUndoComponent } from 'components/Forms/Components';

import { AudioControls } from 'components/Custom';
import { CustomTextField } from 'components/CustomInput';
import RegularButton from 'components/CustomButtons';
import CustomTable from 'components/CustomTable';

import { SimpleLoader } from 'components/Loaders';

import { b64toBlob } from 'devtools/helper';

//const useStyles = makeStyles(createStyles(styles));
import Moment from 'devtools/momentjs';

import { modal_events_observable } from 'store/Observables';

import { updateAnnouncementsActions, removeAnnouncementsActions } from 'store/announcements/actions';

const FORM = 'AnnouncementsTable';

const EMPTY_FORM_STATE = {
  extensions: [],
  forward_all_destination: null,
  forward_all_enabled: false,
  forward_busy_destination: null,
  forward_busy_enabled: false,
  forward_no_answer_destination: null,
  forward_no_answer_enabled: false,
  forward_user_not_registered_destination: null,
  forward_user_not_registered_enabled: false,
}

let handleSubmit;

const AnnouncementsTable = ({ dispatch, rows, loading, classes, ...props}) => {
  const { pristine, dirty, reset, submitting, form, mediaQuery: { isMobile, isXlScreen }} = props;
  const searchRef = React.createRef();

  handleSubmit = async (formvalues, dispatch) => {
    const values = {...formvalues};
    try {
      console.log(values)
      await dispatch(updateAnnouncementsActions(values));
      return true;
    } catch (err) {
      throw new SubmissionError({
        submit: 'Submiting Error',
        _error: err,
      });
    } finally {
      //setTimeout(() => reset(), 500);
    }
  }

  const headCells = [
    { id: 'recording_name', alignment: 'left', display: true, padding: 'normal', sort: true, label: 'Recording Name' },
    { id: 'recording_filename', alignment: 'left', display: true, padding: 'normal', sort: true, label: 'Recording Filename' },
    { id: 'recording_description', alignment: 'left', display: true, padding: 'normal', sort: true, label: 'Recording Description' },
    { id: 'createdAt', alignment: 'center', display: true, padding: 'normal', sort: true, label: 'CreatedAt' },
    { id: 'audio', alignment: 'center', display: true, padding: 'normal', sort: false, label: 'Audio Player' },
  ];

  if(loading) return <SimpleLoader p={5} label="Loading"/>

  return (
    <Fade in timeout={{ appear: 100, enter: 700, exit: 1000}}>
      <form autoComplete='off'>
        <Card>
          <CardHeader color='success'>
            <Grid container justifyContent="flex-start" direction="row" spacing={2}>
              <Grid item xs>
                <h4 className={classes.cardTitleWhite}>Announcements</h4>
                <p className={classes.cardCategoryWhite}>
                  Table shows list of usable Announcements
                </p>
              </Grid>

              <Grid item>
                <RegularButton
                  color='github'
                  type='button'
                  round
                  size={isMobile ? 'sm' : 'md'}
                  onClick={(event) => modal_events_observable.next(event)}
                >
                  <AddCircleIcon /> Text To Speech
                </RegularButton>
              </Grid>

              <Grid item xs={12} lg={3}>
                <div ref={searchRef} id='searchBox' />
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <CustomTable
                data={rows}
                headCells={headCells}
                options={{
                  dense: {
                    forced: true,
                    scaleAt: 10
                  },
                  order: 'asc',
                  orderBy: 'createdAt',
                  searchComponent: true,
                  searchPortalRef: searchRef,
                  checkboxCollumn: {
                    index: 0,
                    display: true
                  },
                }}
                components={{
                  defaultRow: <Chip label="Missing Data!" />,
                  selectedToolbar: function(selected, clearSelected) {
                    return (
                      <Grid container justifyContent="center" alignItems="center" direction="row" spacing={1}>
                        <Grid item>
                          <RegularButton
                            title="Delete Selected Announcements?"
                            placement='top'
                            arrow
                            justIcon
                            round
                            color="white"
                            onClick={async(e) => {
                              try {
                                let values = {};
                                for(const { id, ...rest } of selected()) {
                                  values[id] = null;
                                }

                                await dispatch(removeAnnouncementsActions(values));
                                clearSelected();
                              } catch (err) {
                                console.log(err);
                              }
                            }}
                          >
                            <DeleteIcon style={{ color: 'black' }}/>
                          </RegularButton>
                        </Grid>
                      </Grid>
                    );
                  },
                  rowCells: function(row, index, rowClick, isRowSelected, labelId, disableRowSelectOnClick, collapse, toggleCollapse) {
                    return (
                      <TableRow
                        hover
                        onClick={(event) => rowClick(event, row)}
                        role="checkbox"
                        aria-checked={isRowSelected}
                        tabIndex={-1}
                        key={labelId}
                        classes={{ root: row.enabled ? classes.callForwardEnabled : classes.root }}
                        selected={isRowSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isRowSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>

                        <TableCell padding="none" align="left" onClick={disableRowSelectOnClick}>
                          <Field
                            component={FormTextField}
                            label="Recording Name"
                            name={`${row?.id}.recording_name`}
                            fullWidth
                            placeholder="-"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(event) => {
                              // set ring_group_timeout_app prop with value
                              const value = event.currentTarget.value;
                              if(value) change(`${row?.id}.recording_filename`, `${value}.wav`)
                            }}
                          />
                        </TableCell>


                        <TableCell padding="normal" align="left">
                          <Chip label={row.recording_filename} />
                        </TableCell>

                        <TableCell padding="normal" align="left" onClick={disableRowSelectOnClick}>
                          <Box p={1}>
                            <Field
                              component={FormTextField}
                              label="Recording Description"
                              name={`${row?.id}.recording_description`}
                              fullWidth
                              placeholder="-"
                              variant="outlined"
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Box>
                        </TableCell>

                        <TableCell padding='normal' align="center">
                          {
                            row.createdAt && <Chip label={Moment.firebaseSeconds(row.createdAt, 'simple', true)} />
                          }
                        </TableCell>

                        <TableCell padding='normal' align="center">
                          {
                            row.audio
                              ? <AudioControls audio={b64toBlob(row.audio, 'audio/wav')} />
                              : <Chip label="No Audio Content Available!" />
                          }
                        </TableCell>
                      </TableRow>
                    );
                  }
                }}
              />
          </CardBody>
          <RemoteSubmitAndUndoComponent message='Announcements' openOnDirty  {...props} />
          {
            // <Collapse in={dirty && !pristine} timeout="auto" unmountOnExit>
            //   <CardFooter>
            //     <Grid container justify="flex-end" spacing={1}>
            //       <Grid item>
            //         <RegularButton
            //           type="submit"
            //           size='sm'
            //           color="success"
            //           round
            //           disabled={pristine || submitting}
            //         >
            //           <Box pr={1} display={submitting ? 'block' : 'none' }>
            //             <i className={"fas fa-spinner fa-spin"} />
            //           </Box>
            //           Apply
            //         </RegularButton>
            //
            //       </Grid>
            //       <Grid item>
            //         <RegularButton
            //           type="button"
            //           size='sm'
            //           color="warning"
            //           round
            //           disabled={pristine || submitting}
            //           onClick={reset}
            //         >
            //           Undo
            //         </RegularButton>
            //       </Grid>
            //     </Grid>
            //   </CardFooter>
            // </Collapse>
          }
        </Card>
      </form>
    </Fade>
  )
}

AnnouncementsTable.defaultProps = {
  profile: {}
};

const mapStateToProps = state => ({
  initialValues: state.formState[FORM] || EMPTY_FORM_STATE,  // pull initial values from form reducer
});

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: FORM,
    enableReinitialize: true,
    destroyOnUnmount: true,
    onSubmit: (values, dispatch) => handleSubmit(values, dispatch)
    // onChange: (values) => console.log(values)
    // validate,
    // asyncValidate,
  })(AnnouncementsTable)
)
