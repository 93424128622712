import React, { useEffect, useState } from 'react';

import { pick, omit } from 'lodash';

import { withScrollBar } from 'Providers';

import {
  Drawer
} from '@mui/material';

const DrawerComponent = ({ variant, anchor, open, drawerProps: { sx, ...drawerProps }, scrollRef, handleClose, drawerWidth, ...props}) => {
  return (
    <Drawer
      variant={variant}
      anchor={anchor}
      open={open}
      onClose={handleClose}
      {...drawerProps}
      PaperProps={{
        ref: scrollRef,
        elevation: 8,
        sx: {
          border: 'none',
          width: drawerWidth,
          ...sx,
        }
      }}
    >
      <div>
        {props.component && <props.component state={drawerProps?.state} handleClose={handleClose} />}
      </div>
    </Drawer>
  )
}

const DRAWER_WIDTHS = [
  320,
  300,
  280
]

const Drawers = React.memo(({ drawers = [], drawerAction, ...props }) => {
  const handleClose = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    drawerAction('CLOSE')(event)
  };

  return drawers.map((drawer, index) => {
    return <DrawerComponent key={index} {...drawer} drawerWidth={DRAWER_WIDTHS[index]} {...props} handleClose={handleClose} />
  });
}, (prevProps, nextProps) => {
  //return JSON.stringify(omit(prevProps, ['id', 'isLoading', 'contact'])) !== JSON.stringify(omit(nextProps, ['id', 'isLoading', 'contact']))
  //return JSON.stringify(prevProps) === JSON.stringify(nextProps)
  return false;
})

export default withScrollBar(Drawers);
