import * as React from 'react';

import {
  Avatar
} from '@mui/material';


function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar({ bgcolor, height, width, fontSize, ...props }) {
  return {
    sx: {
      bgcolor: 'gray',
      boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
      fontSize,
      width,
      height,
    },
    children: <props.icon sx={{ fontSize: 'inherit' }} />

  };
}

const CustomIcon = (props) => <Avatar {...stringAvatar(props)} />

CustomIcon.defaultProps = {
  fontSize: 20,
  width: 30,
  height: 30,
}

export default CustomIcon;
