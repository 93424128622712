import React, { useState, useEffect } from 'react';

// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';

// react plugin for creating charts

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { Bar } from 'react-chartjs-2';

// import AccessTime from "@mui/icons-material/AccessTime";
// import Refresh from "@mui/icons-material/Refresh";
import Edit from "@mui/icons-material/Edit";

import Moment from "devtools/momentjs";

import { Fade, Box, Chip } from '@mui/material';

import { CustomAvatarWithBadge } from 'components/CustomAvatar';

import styles from "assets/jss/views/dashboardStyle.js";

import { parseChartData } from 'devtools/chartHelper';

import { reduce } from 'lodash';

const useStyles = makeStyles(createStyles(styles));

var delays = 80, durations = 500;

const LineChartSimple = ({ children, color, footerText, label, customLabels, filterCallback, sort, customDatasetLabelProps, loading, data: dataProp, filterProps, ...props}) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({
    labels: [],
    datasets: []
  });

  const getMax = (arr) => {
    const max = Math.max(...arr);
    let timesby;
    if(max <= 15) {
      timesby = 15;
    } else if (max <= 25) {
      timesby = 25;
    } else if (max <= 50) {
      timesby = 50;
    } else {
      timesby = 100;
    }
    return Math.ceil(max/100) * timesby;
  }

  const [options, setOptions] = useState({
    legend: {
      labels: {
        fontColor: 'rgba(255, 255, 255, 0.75)'
      }
    },
    scales: {
      xAxes: [{
        stacked: true,
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          fontColor: 'rgba(255, 255, 255, 0.75)',
          suggestedMax: 100,
        },
        gridLines: {
          display:true,
          color: 'rgba(255, 255, 255, 0.75)'
        }
      }],
      yAxes: [{
        stacked: true,
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          fontColor: 'rgba(255, 255, 255, 0.75)',
          suggestedMax: 10,
        },
        gridLines: {
          display:true,
          color: 'rgba(255, 255, 255, 0.75)'
        }
      }]
    }
  });

  useEffect(() => {
    if(loading === false) {
      (async() => {
        const { labels, dataSet } = await new Promise((resolve, reject) => {
          function doLabels(arr) {
            let myMap = new Map();
            arr.map(item => myMap.set(item, 0))
            return myMap;
          }

          let dataset = new Map(customLabels ? doLabels(customLabels) : null);

          dataProp.map(item =>
            filterCallback(item, dataset, (index) => {
              dataset.set(index, (dataset.get(index) || 0) + 1)
            })
          )

          resolve({
            labels: Array.from(dataset.keys()),
            dataSet: Array.from(dataset.values()),
          })
        });


        setData({
          labels: labels,
          datasets: [
            {
              display: true,
              label: label,
              borderWidth: 2,
              data: dataSet,
              ...customDatasetLabelProps || {}
            }
          ]
        })

        setOptions({
          legend: {
            labels: {
              fontColor: 'rgba(255, 255, 255, 0.75)'
            }
          },
          scales: {
            xAxes: [{
              stacked: true,
              beginAtZero: true,
              ticks: {
                stepSize: 1,
                fontColor: 'rgba(255, 255, 255, 0.75)',
                suggestedMax: 100,
              },
              gridLines: {
                display:true,
                color: 'rgba(255, 255, 255, 0.75)'
              }
            }],
            yAxes: [{
              stacked: true,
              beginAtZero: true,
              ticks: {
                stepSize: 1,
                fontColor: 'rgba(255, 255, 255, 0.75)',
                suggestedMax: getMax(dataSet),
              },
              gridLines: {
                display:true,
                color: 'rgba(255, 255, 255, 0.75)'
              }
            }]
          }
        });

      })();
    }
  }, [loading]);

  return (
    <Fade in timeout={{ appear: 1300, enter: 1000, exit: 1000}}>
      <Card chart className={classes.cardHover}>
        <CardHeader color={color}>
          <Bar
            data={data}
            options={options}
          />
        </CardHeader>
        <CardBody style={{ textAlign: 'right' }}>
          <Box width={1} display='flex' flexDirection='row' justifyContent="flex-start">
            <Box alignSelf='flex-end'>
              <p className={classes.cardCategory}>{footerText} <Chip component='span' label={dataProp.length}/></p>
            </Box>
          </Box>
        </CardBody>
      </Card>
    </Fade>
  );
};
export default LineChartSimple
