import React, { useEffect, useState } from 'react';
// import { connect } from 'react-redux';

import { useNavigate, useLocation, Navigate } from "react-router-dom";

//import { withFirebase } from '../Firebase';
import { isAdmin } from 'devtools/Helpers';

import { firebase } from 'index.js'

import { SimpleLoader } from 'components/Loaders';

const withAuthentication = (Component) => (props) => {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();

  let mounted = true, listener;
  useEffect(() => {
    if(mounted) {
      listener = firebase.onAuthUserListener(
        authUser => {
          setLoggedIn(true);
          setLoading(false); // render page
        },
        (err) => {
          console.warn('NO USER DETECTED, SHOULD FORCE LOGIN PAGE!')
          setLoading(false); // render page
        },
      );
    }
    return () => {
      if(mounted) listener();
    }
  }, []);

  if(loading) {
    return <div />;
  } else if(!loading && loggedIn) {
    return <Component {...props} />;
  } else {
    return (
      <Navigate
        to="/login"
        options={{
          state: { from: location }
        }}
      />
    )
  }
};

export default withAuthentication;
