import React, { useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import TooltipComponent from '../TooltipComponent';
import ActionButtonsMenuComponent from '../ActionButtonsMenuComponent';

//import Button from "components/CustomButtons/Button.js";

import {
  Button,
  Grid,
  Stack,
  Fab
} from '@mui/material';

import { usePhone } from 'Providers';

import RegularButton from 'components/CustomButtons';

import CallSplitIcon from '@mui/icons-material/CallSplit';
import CallIcon from '@mui/icons-material/Call';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

import styles from "assets/jss/views/buttonsStyle.js";
const useStyles = makeStyles(createStyles(styles));

const RingingButtons = (props) => {
  const classes = useStyles();
	const { answer, hangup } = usePhone();
  const {
    session,
    direction,
  } = props;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {
        direction === 'incoming' &&
          <Fab color="success" size="small" aria-label="Answer" onClick={answer(session)}>
            <CallIcon />
          </Fab>
      }
      <Fab color="error" size="small" aria-label="Hangup" onClick={hangup(session)}>
        <CallEndIcon />
      </Fab>
    </Stack>
  );
}

RingingButtons.defaultProps = {
  session: {}
}

export default RingingButtons;
