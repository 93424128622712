import React from 'react'
import { createTheme } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderMakeStyles } from '@mui/styles' // makeStyles
import { ThemeProvider as ThemeProviderStyled } from '@mui/material' // styled

import { theme } from "assets/jss/customTheme";

// You can spread routeProps to make them available to your rendered Component
export default (Component) => props => {
  return (
    <ThemeProviderMakeStyles theme={theme}>
      <ThemeProviderStyled theme={theme}>
        <Component {...props} />
      </ThemeProviderStyled>
    </ThemeProviderMakeStyles>
  )
}
