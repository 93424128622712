import React from 'react';
import { grey } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import { TextField, InputAdornment } from '@mui/material';

import { isNumberEvent } from '../../Validators';

const WhiteTextField = withStyles({
  root: {
    color: grey[300],
    '& div': {
      color: grey[300],
      '& svg': {
        color: grey[300],
      }
    },
    '& label': {
      color: grey[300],
    },
    '& label.Mui-focused': {
      color: grey[300],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: grey[300],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: grey[300],
      },
      '&:hover fieldset': {
        borderColor: grey[300],
      },
      '&.Mui-focused fieldset': {
        borderColor: grey[300],
      },
    },
  },
})(TextField);

// const REGEX = new RegExp('^(?!0)\\d{0,11}$');

const FormPhoneNumberTextField = (
  { input: { onChange, ...input}, label, meta: { asyncValidating, touched, error }, helperText, themeColor, InputProps: inputProps, ...custom },
) => {
  const ThemeComponent = {
    white: WhiteTextField,
    default: TextField,
  }[themeColor || 'default']

  // const handleChange = (event) => {
  //   if(REGEX.test(event.target.value)) onChange(event);
  // }

  return (
    <ThemeComponent
      error={error && touched}
      helperText={ helperText ? helperText(touched, error) : touched && error ? error : null }
      label={label}
      {...input}
      {...custom}
      onChange={onChange}
      InputProps={{
        ...inputProps,
        endAdornment: (
          <InputAdornment position="end" style={{ visibility: asyncValidating ? 'visible' : 'hidden' }}>
            <i className={"fas fa-spinner fa-spin"} />
          </InputAdornment>
        )
      }}
    />
  )
}

export default FormPhoneNumberTextField;
