import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import { makeStyles, createStyles } from '@mui/styles';

import MusicNoteIcon from  '@mui/icons-material/MusicNote';

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { Chip, Typography, IconButton, ListSubheader, ListItemAvatar, Avatar, Menu, MenuItem, MenuList } from '@mui/material';

// import { announcementsActions } from 'store/announcements/actions';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

const AnnouncementsMenu = ({ announcementsLoading, announcements, getAnnouncements, value: valueProp, ...props}) => {
  let { onChange, customProps } = props;
  // const classes = useStyles();

  const [selected, setSelected] = useState(valueProp || "");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (announcementsLoading === null) {
      getAnnouncements();
    }
  }, [announcements, announcementsLoading]);

  useEffect(() => {
    if(valueProp) setSelected(valueProp);
  }, [valueProp]);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, selectedValue) => {
    setAnchorEl(null);
    setSelected(selectedValue)
    onChange(selectedValue);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItem
        button
        aria-haspopup="true"
        aria-controls="lock-menu"
        aria-label={customProps.label}
        onClick={handleClickListItem}
      >
        <ListItemText
          disableTypography={true}
          primary={<Typography>{customProps.label}</Typography>}
          secondary={
            <Chip label={<small>{selected === '' ? 'None' : selected.split('=')[1]}</small>} />
          }
        />
      </ListItem>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuList>
          <MenuItem
            selected={"" === selected}
            onClick={(event) => handleMenuItemClick(event, "")}
          >
            No Announcement
          </MenuItem>
        </MenuList>
        <MenuList
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">Announcements</ListSubheader>
          }
        >
          { announcements ? announcements.map((option, index) => {
            const optionValue = 'lua=' + option.recording_filename;
            return (
              <MenuItem
                style={{ minWidth: '300px '}}
                key={option.recording_uuid}
                selected={optionValue === selected}
                onClick={(event) => handleMenuItemClick(event, optionValue)}
              >
                <ListItemAvatar>
                  <Avatar>
                    <MusicNoteIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<span>{option.recording_description}</span>}
                  secondary={<small>{option.recording_filename}</small>}
                />
              </MenuItem>
            )
          }) : <></>}
        </MenuList>
      </Menu>
    </>
  );
}

const mapStateToProps = state => ({
  announcements: state.announcements_State.announcements || [],
  announcementsLoading: state.announcements_State.loading,
});

const mapDispatchToProps = dispatch => ({
  getAnnouncements: () => {
    //dispatch(announcementsActions())
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementsMenu);
