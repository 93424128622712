import React from 'react'

import {
  Fade,
  Zoom,
  Grow
}  from '@mui/material';

// You can spread routeProps to make them available to your rendered Component
export default (Component, AnimationComponent = Fade) => props => {
  return (
    <AnimationComponent in>
      <div>
        <Component {...props} />
      </div>
    </AnimationComponent>
  )
}
