import React from 'react'
import { connect } from 'react-redux';
import { grey } from '@mui/material/colors';
import { Field, FieldArray, formValueSelector, change, reduxForm } from 'redux-form'
import { FormTextField, FormCalleridAutoComplete, FormPhoneNumberTextField, FormExtensionTextField, FormAutoComplete } from 'components/Forms/Components';
import { required, asyncExtensionCheck } from 'components/Forms/Validators';

import { makeStyles, createStyles } from '@mui/styles';
import styles from "assets/jss/components/missedCallsStyle";

import { Extension } from 'Helpers/Extensions';

import CustomTable from 'components/CustomTable';

import { Card, CardHeader, CardIcon, CardBody } from 'components/Card';
import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import { DarkChip } from 'components/CustomChips';

import CloseIcon from  '@mui/icons-material/Close';
import ExtensionIcon from  '@mui/icons-material/Extension';

import { Box, Grid, Button, Chip, IconButton, InputAdornment, Table, TableContainer, TableRow, TableHead, TableBody, TableCell, Divider, Alert } from '@mui/material';

import {
  DialogTitle,
  DialogContent,
} from 'components/Modals';

const useStyles = makeStyles(createStyles(styles));

const PrimaryChip = (props) => {
  return <Chip size='small' color="primary" {...props} />
}

const FormCreateExtensionFields = ({ fields, meta: { error, submitFailed }, getFormValues, ...props }) => {
  const classes = useStyles();
  const { handleClose, form, change, actions: { addRow, removeRow }} = props;

  return (
    <div>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Grid item xs container justifyContent='flex-end' alignItems="flex-start" spacing={2} style={{ color: 'black' }}>
          <Grid item xs>
            <Box p={2}>
              <CardIcon color="rose">
                <ExtensionIcon style={{ color: 'white' }}/>
              </CardIcon>
              <h4 className={classes.cardTitleWhite} style={{ color: 'black' }}>Create New Extensions</h4>
              <Alert severity="info" style={{ maxWidth: 'fit-content', alignItems: 'center' }}>Extensions between  <PrimaryChip label="2000" /> - <PrimaryChip label="2999" /> are available for use!</Alert>
            </Box>
          </Grid>

          {
            <Grid item xs="auto">
              <HelperTooltipBootstrap title="Add Row" placement='bottom' arrow>
                <Button color='primary' variant="outlined" onClick={() => fields.push(addRow())}>
                  Add Row
                </Button>
              </HelperTooltipBootstrap>
            </Grid>
          }
        </Grid>
      </DialogTitle>

      <DialogContent dividers style={{ margin: 0, padding: 0 }}>
        <TableContainer>
          <Table style={{ overflow: 'hidden' }} aria-label="simple table" size='small' padding='none'>
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ backgroundColor: 'black', color: 'white' }}>Extension</TableCell>
                <TableCell align="center" style={{ backgroundColor: 'black', color: 'white' }}>Description</TableCell>
                <TableCell align="center" style={{ backgroundColor: 'black', color: 'white' }}>GSM Number</TableCell>
                <TableCell align="center" style={{ backgroundColor: 'black', color: 'white' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                fields.map((row, key) => {
                  return (
                    <TableRow hover key={key}>
                      <TableCell padding="normal" align="left">
                        <Field
                          component={FormTextField}
                          label="Extension"
                          name={`${row}.extension`}
                          fullWidth
                          size="small"
                          validate={[required]}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <ExtensionIcon style={{ color: grey[500] }}/>
                              </InputAdornment>
                            ),
                          }}
                          placeholder={`200${key}`}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </TableCell>
                      <TableCell padding="normal" align="center">
                        <Field
                          component={FormTextField}
                          label="Description"
                          name={`${row}.description`}
                          fullWidth
                          placeholder="-"
                          variant="outlined"
                          size="small"
                          validate={[required]}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </TableCell>
                      <TableCell padding="normal" align="center">
                        <Field
                          component={FormPhoneNumberTextField}
                          label="GSM Number (050123456)"
                          name={`${row}.number_alias`}
                          fullWidth
                          placeholder="050123456"
                          variant="outlined"
                          size="small"
                          validate={[required]}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(event) => {
                            const value = event.target.value;
                            const str = `{fail_on_single_reject=LOSE_RACE:USER_BUSY:CALL_REJECTED:NO_ANSWER:NO_USER_RESPONSE:RECOVERY_ON_TIMER_EXPIRE:ORIGINATOR_CANCEL}`+
                            `sofia/gateway/5e4ac47f-6ce4-462d-9847-567791fe3d4e/${value}|`+
                            `sofia/gateway/0e6ff466-9006-478c-b5a0-c15ab24a4a80/${value}`;

                            change(`extensions[${key}].dial_string`, str);
                          }}
                        />
                      </TableCell>
                      <TableCell padding="normal" align="center">
                        <HelperTooltipBootstrap title='Remove' placement='right'>
                          <IconButton onClick={() => {
                            removeRow(getFormValues([`${row}.extension`]));
                            fields.remove(key);
                          }}>
                            <CloseIcon />
                          </IconButton>
                        </HelperTooltipBootstrap>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </div>
  )
}

export default FormCreateExtensionFields;
