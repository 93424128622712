import React, { useContext, useEffect, createContext, useState } from "react";

import {
  Snackbar,
  Button,
  Stack,
  SnackbarContent,
} from '@mui/material';

// import phone_answer from 'assets/img/phone-answer.png';
// import phone_hangup from 'assets/img/phone-hangup.png';

const audioProtectionContext = createContext();

const DESKTOP_ALERT_DELAY = 4000;

function getBrowserName() {
  const userAgent = navigator.userAgent;
  let browserName = 'Unknown';

  if (userAgent.indexOf('Chrome') !== -1) {
    browserName = 'Chrome';
  } else if (userAgent.indexOf('Firefox') !== -1) {
    browserName = 'Firefox';
  } else if (userAgent.indexOf('Safari') !== -1) {
    browserName = 'Safari';
  } else if (userAgent.indexOf('Edge') !== -1) {
    browserName = 'Edge';
  } else if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident/') !== -1) {
    browserName = 'Internet Explorer';
  }

  return browserName;
}

const browserName = getBrowserName();


function SnackbarAlert({ open, onClick, key = 'audioDisabledSnackbar' }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      message={`${browserName} Prevented Audio!`}
      ClickAwayListenerProps={{
        onClickAway: onClick
      }}
      key={key}
      action={
        <Button color="primary" variant='outlined' ize="small" onClick={onClick}>
          ENABLE!
        </Button>
      }
    />
  )
}


function useAudioProtection() {
  return useContext(audioProtectionContext);
}


function ProvideAudio({children}) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { enableAction, ...rest } = useProvider(setSnackbarOpen);

  return (
    <audioProtectionContext.Provider value={rest}>
      {children}
      <SnackbarAlert open={snackbarOpen} onClick={enableAction} />
    </audioProtectionContext.Provider>
  )
}

function useProvider(setSnackbarOpen) {
  let mounted = true, notifycation, timeoutId;
  const [isAudioEnabled, setAudioEnabled] = useState(false);

  useEffect(() => {
    return () => {
      closeNotifycation();
      mounted = false;
    }
  }, []);

 
  function enableAudioAndClearAlerts() {
    // clear setTimeout if possible
    clearTimeout(timeoutId)
    if (notifycation) closeNotifycation(); // close notifycation if already displayed!
    if (typeof setSnackbarOpen === 'function') setSnackbarOpen(() => false) // display snackbar!
    setAudioEnabled(true);
  }

  function closeNotifycation() {
    if(mounted && typeof notifycation?.close === 'function') notifycation.close();
  }

  function checkIsSuspended() {
    const audioContext = new AudioContext();
    return audioContext.state === 'suspended';
  }

  function eventAction(event) {
    if(mounted && !checkIsSuspended()) {
      enableAction(event); // audio activated
    }
  };

  function enableAction(event) {
    enableAudioAndClearAlerts();
  }

  function doNotifycation() {
    notifycation = new Notification(
      `${browserName} Prevented Audio!`,
      {
        tag: 'audio',
        body: 'Audio is Prevented by default by browsers, if no interaction with page is made after page load completed!',
        renotify: true,
        //requireInteraction: true,
      }
    );

    notifycation.onclick = eventAction;
    notifycation.onclose = eventAction;

  }

  function enableAudioIfBlocked(forcedState = false) {
    if(!mounted) 
      return;
    else if (forcedState) {
      enableAudioAndClearAlerts();
    } else if (!checkIsSuspended()) {
      if (!isAudioEnabled) setAudioEnabled(true);
    } else {
      // notify user of blocked audio
      if (typeof setSnackbarOpen === 'function')  setSnackbarOpen(() => true) // display snackbar immediatly!

      setTimeout(() => {
        if (checkIsSuspended()) {
          doNotifycation(); // do notify with some delay if needed
        }
      }, DESKTOP_ALERT_DELAY)
    }
    
  }

  return {
    enableAudioIfBlocked,
    isAudioEnabled,
    enableAction,
    enableAudioAndClearAlerts,
  }
}

const withAudioProtection = (Component) => (props) => {
  return (
    <ProvideAudio>
      <Component {...props} />
    </ProvideAudio>
  );
}

export {
  useAudioProtection,
  withAudioProtection
}
