import React, { useEffect, useState } from 'react';

import { pick, omit } from 'lodash';

import { withScrollBar } from 'Providers';

import Dialog, { DialogProps } from '@mui/material/Dialog';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const DialogComponent = ({ variant, anchor, open, dialogProps, childrenProps, scrollRef, handleClose, descriptionElementRef, ...props}) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      {...dialogProps}
    >
      {props.component && <props.component state={dialogProps?.state} {...childrenProps} handleClose={handleClose} descriptionElementRef={descriptionElementRef} />}
    </Dialog>
  )
}




const Dialogs = React.memo(({ dialogs = [], dialogAction, ...props }) => {
  const descriptionElementRef = React.useRef(null);
  // React.useEffect(() => {
  //   if (open) {
  //     const { current: descriptionElement } = descriptionElementRef;
  //     if (descriptionElement !== null) {
  //       descriptionElement.focus();
  //     }
  //   }

  const handleClose = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dialogAction('CLOSE')(event)
  };

  return dialogs.map((dialog, index) => {
    return <DialogComponent key={index} {...dialog} {...props} handleClose={handleClose} descriptionElementRef={descriptionElementRef} />
  });
}, (prevProps, nextProps) => {
  //return JSON.stringify(omit(prevProps, ['id', 'isLoading', 'contact'])) !== JSON.stringify(omit(nextProps, ['id', 'isLoading', 'contact']))
  //return JSON.stringify(prevProps) === JSON.stringify(nextProps)
  return false;
})







export default withScrollBar(Dialogs);
