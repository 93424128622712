import React, { memo } from 'react';

import AddIcon from  '@mui/icons-material/Add';
import RegularButton from "components/CustomButtons/Button.js";

import { AnnouncementCreatorModal, ExtensionsCreatorModal } from 'components/Modals'

import { modal_Actions } from 'store/modals/actions';
import { modal_events_observable } from 'store/Observables';

// memo prevent rerenders! (pure component)
const OpenModalButton = memo(({ children, template, ...props }) => {
  return (
    <div>
      <RegularButton
        onClick={(event) => {
          modal_events_observable.next(event);
          event.stopPropagation(); // stop menu close!
        }}
        {...props}
      >
        { children }
      </RegularButton>

      {
        {
          'AnnouncementCreatorModal': <AnnouncementCreatorModal />,
          'ExtensionsCreatorModal': <ExtensionsCreatorModal />,
          'default': <div />,
        }[template || 'default']
      }
    </div>
  );
}, (prevProps, nextProps) => true);

OpenModalButton.defaultProps = {
  children: <AddIcon />,
  color: 'github',
  size: 'sm',
  style: { float: 'left' },
  round: true,
};

export default OpenModalButton;
