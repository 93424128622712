import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';

import { withMediaQuery } from 'HOC';

import { Link } from "react-router-dom";

// import { Card, CardHeader, CardIcon, CardFooter } from 'components/Card';

import TrendingFlatIcon from  '@mui/icons-material/TrendingFlat';
import AccessTimeIcon from  '@mui/icons-material/AccessTime';
import PhoneMissedIcon from  '@mui/icons-material/PhoneMissed';

import { Box, Chip, Grid } from '@mui/material';

// import Moment from "devtools/momentjs";
import moment, { asHumanized } from 'Helpers/Momentjs';

import TimelineComponent from "components/Timeline";
import { CallButton } from "components/CustomFabs";
import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import { isLoading } from 'devtools/Helpers';

import styles from "assets/jss/views/dashboardStyle.js";
const useStyles = makeStyles(createStyles(styles));

const MissedCallsTimeline = ({ dispatch, mediaQuery, calls, loading, ...props}) => {
  const classes = useStyles();
  const { isMobile, isXlScreen } = mediaQuery;

  return !loading &&
    <TimelineComponent
      simple
      stories={
        [...calls].map((item, index) => {
          return {
            badgeColor: "danger",
            badgeIcon: PhoneMissedIcon,
            badgeProp: {
              fontSize: "small"
            },
            body: (
              <Grid container>
                <Grid item xs={12}>
                  <Box width={1} display='flex' alignItems='center' justifyContent="space-between">
                    <Chip size={isXlScreen ? 'medium' : 'small'} label={item?.soittaja} />
                    <TrendingFlatIcon />
                    <Chip size={isXlScreen ? 'medium' : 'small'} label={item?.soitettu} />
                  </Box>
                </Grid>
              </Grid>
            ),
            footer: (
              <Box width={1} fontSize={12} textAlign='right' display='flex' alignItems='center' justifyContent='space-between'>
                <HelperTooltipBootstrap title={item?.aika} placement='right' arrow>
                  <Box display='flex' alignItems='center'>
                    <AccessTimeIcon style={{ marginRight: 6 }} /> { asHumanized(item?.timestamp) }
                  </Box>
                </HelperTooltipBootstrap>
                <CallButton args={[item.id, { handled: 1, ok: 'Valmis' }]} href={item.soittaja} />
              </Box>
            )
          }
       })
    }
  />
}

const mapStateToProps = state => ({
  calls: state.missedcalls.unhandled_10recent,
  loading: isLoading([state.missedcalls.loading,state.additionals.loading])
});

export default connect(
  mapStateToProps,
  null
)(withMediaQuery(MissedCallsTimeline));
