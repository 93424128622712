import { firebase, store } from 'index.js';

import { isEmpty, uniqBy, keyBy, merge } from 'lodash';

//import { v4 as uuidv4 } from 'uuid';

import { onError, enqueueSnackbar } from 'store/Notifier/actions';

import { ReplaySubject, combineLatest } from "rxjs";
import { map, distinctUntilChanged, filter } from 'rxjs/operators';

import { makeCollection, sortCollection, sortByTime, parseTypes, compare } from 'devtools/Helpers';

import CleanupService from './service_cleanup';

// tabs friends ringgoups list
class Service_extensions extends CleanupService {
  constructor(filterCb) {
    super();
    this.observable_extensions$ = new ReplaySubject();

    const condition = typeof filterCb === 'function';

    this.observable = this.observable_extensions$
      .pipe(
        condition && map(filterCb), // transfor actual values
        distinctUntilChanged(),
      );

    this.firebaseRefs([
    	firebase.doFirebaseObservableQuery(firebase.extensions(), "on", "value", this.observable_extensions$, true), // true for collection
    ])
  }

  add_subscriber(cbSucess, cbError) {
    return this.subscriptions.push(
      this.observable.subscribe(
        cbSucess,
        err => {
          console.warn('service_extensions', err)
          if(typeof cbError === "function") cbError(err);
        }
      )
    ); // add to array and return subscription!
  }
}


export {
  Service_extensions
};
