import React, { createContext, useEffect, useReducer } from 'react';
import { makeCollection } from 'devtools/Helpers';
import { calllog_observable } from '../Observables';

const CalllogContext = createContext();

// function reducer(state, item) {
//   return [...state, item]
// }

const WEBRTC_CALLLOG_STORAGE_NAME = 'sipCalls';

const INITIALSTATE = sortByTime(makeCollection(JSON.parse(window.localStorage.getItem(WEBRTC_CALLLOG_STORAGE_NAME)) || {})); // object --> array

function sortByTime(array = [], prop = 'time') {
  return array.sort(({ time: a }, { time: b }) => a > b ? 1 : a < b ? -1 : 0)
}

function reducer(state = INITIALSTATE, action) {
  switch (action.type) {
    case 'CALLLOG_ADD': {
      const calls = sortByTime([...state, new CallLogItem(action.payload)]);
      window.localStorage.setItem(WEBRTC_CALLLOG_STORAGE_NAME, JSON.stringify(calls)); // store calls
      return calls;
    }
    case 'CALLLOG_CLEAR': {
      window.localStorage.removeItem(WEBRTC_CALLLOG_STORAGE_NAME); // store remove calls
      return [];
    }
    default:
      return state;
  }
}

class CallLogItem {
  // session
  constructor(session = {}) {
    //console.warn('CallLogItem', session)
    const { direction, data, start_time = null, end_time = null, remote_identity, local_identity, ...rest} = session;
    return {
      uid: data.uuid,
      direction: direction,
      disposition: session.isEnded() && data?.hasAnswer ? 'Answered' : 'No Answer',
      remote_identity: remote_identity,
      local_identity: local_identity,
      duration: this.#getDuration(end_time, start_time),
      start_time: start_time,
      end_time: end_time,
      time: new Date(),
    }
  }

  #getDuration(end, start) {
    if(!start || !end) return null;
    return end - start;
  }
}

// rest sessions setSessions
const withCallogContext = (Component) => (props) => {
  const [calls, setCallLog] = useReducer(reducer, INITIALSTATE);
  let mounted = true, subscription;

  useEffect(() => {
    if(mounted && calllog_observable) {
      subscription = calllog_observable.subscribe(
        value => setCallLog(value),
        err => console.log(err)
      );
    }
    return () => {
      if(mounted && calllog_observable) {
        subscription.unsubscribe();
        mounted = false;
      }
    }
  }, [])


  return (
    <CalllogContext.Provider value={{ calls, setCallLog }}>
      <Component {...props} />
    </CalllogContext.Provider>
  )
};

export {
  CalllogContext as default,
  withCallogContext
}
