import {
  PROFILE_ACTIONS_PENDING,
  PROFILE_ACTIONS_SUCCESS,
  PROFILE_ACTIONS_FAILURE,
  ACTIONS_UPDATE_PENDING,
  ACTIONS_UPDATE_SUCCESS,
  ACTION_WIZARD_IDLE,
  ACTION_WIZARD_PENDING,
  ACTION_WIZARD_SUCCESS,
  ACTION_WIZARD_FAILURE,
  USERS_PENDING,
  USERS_SUCCESS,
} from 'store/types';

import { user_observable } from 'store/firebase/Observables';

import { onError, enqueueSnackbar, } from 'store/Notifier/actions';

import { combineLatest, Subject } from 'rxjs';
import { merge, keyBy, values, isEmpty } from 'lodash';

import { sleep } from 'Helpers';

// import {
//   updateUserAction,
//   updatePassword
// } from 'store/functions';

import { sweetalerts } from 'sweetalerts';

import {
  uploadImage
} from 'store/functions';

// async function imageUpload({ firebase, dispatch }, file) {
//   return await firebase.storageImages().child(file.name).put(file).then(async (snapshot) => {
//     return await snapshot.ref.getDownloadURL().then(downloadURL => {
//       return downloadURL;
//     });
//   }).catch(err => {
//     console.log(err);
//     //dispatch({ type:  ACTIONS_UPDATE_FAILURE, payload: err });
//     return null;
//   });
// };

export const updateProfileAction = (id, data, completeObject) => {
  return async (dispatch, getState, firebase) => {
    try {
      dispatch({ type: ACTION_WIZARD_PENDING });
      await firebase.authStatePromise;
      // let { image, ...userData } = data;
      if(data.image) data.image = await uploadImage(firebase, data.image);
      // const dataObject = image ? { image, ...userData } : userData;

      await firebase.transaction(firebase.profile(id), (snapshot) => {
        return {...Object.assign(snapshot || {}, {...data})};
      });

      dispatch({ type:  ACTION_WIZARD_SUCCESS, payload: { redirect: true, response: completeObject }});
    } catch (err) {
      console.log(err)
      dispatch({ type:  ACTION_WIZARD_FAILURE, payload: { error: err }});
      onError(dispatch, err);
    } finally {
      await sleep(4000)
      dispatch({ type: ACTION_WIZARD_IDLE });
    }
  };
};

export const deleteProfilesAction = (array) => {
  return async (dispatch, getState, firebase) => {
    try {
       await sweetalerts.confirm();
       const loadingKey = enqueueSnackbar(dispatch, 'LOADING');
       await firebase.authStatePromise;
       const response = await firebase.httpQueryDeleteUsers(JSON.stringify(array.map(user => user.id)))
       await response.text();
       enqueueSnackbar(dispatch, 'SUCCESS', loadingKey);
       return true;
    } catch (err) {
      if(err !== undefined) onError(dispatch, err);
      return false;
    }
  };
};

// update password
export const passwordUpdateWizardAction = (newPassword, object) => {
  return async (dispatch, getState, firebase) => {
    dispatch({ type: ACTION_WIZARD_PENDING });
    try {
      await firebase.authStatePromise;

      // import {
      //   updatePassword
      // } from 'store/functions';

      const response = await firebase.doUpdatePassword(newPassword);
      if(response !== 'success') throw response;
      dispatch({ type:  ACTION_WIZARD_SUCCESS, payload: { redirect: true, response: object }});
    } catch (err) {
      dispatch({ type:  ACTION_WIZARD_FAILURE, payload: { error: err }});
      onError(dispatch, err);
    } finally {
      await sleep(4000)
      dispatch({ type: ACTION_WIZARD_IDLE });
    }
  };
};

export const updateDomainUserAction = (id, values) => {
  return async (dispatch, getState, firebase) => {
    const loadingKey = enqueueSnackbar(dispatch, 'LOADING')

    try {
      await firebase.authStatePromise;
      await firebase.transaction(firebase.users().child(id), (snapshot) => {
        return {...Object.assign(snapshot || {}, values)}
      })

      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey);
    } catch (err) {
      onError(dispatch, err);
    }
  }
}

// multi update roles
export const updateProfilesAction = (map) => {
  return async (dispatch, getState, firebase) => {
    let promises = [];
    try {
       await sweetalerts.confirm();
       const loadingKey = enqueueSnackbar(dispatch, 'LOADING');
       await firebase.authStatePromise;

       for(const [uid, value] of map.entries()) {
         promises.push(
           firebase.transaction(firebase.user(uid), (snapshot) => {
             return {...Object.assign(snapshot || {}, value)};
           })
         );
       }

       await Promise.allSettled(promises);
       enqueueSnackbar(dispatch, 'SUCCESS', loadingKey);
       return true;
    } catch (err) {
      if(err !== undefined) onError(dispatch, err);
      return false;
    }
  };
};

export const updateProviderAccount = (id, data = {}) => {
  return async (dispatch, getState, firebase) => {
    dispatch({ type:  ACTION_WIZARD_PENDING });
    try {
      if(isEmpty(data)) throw 'error data property!';
      await firebase.authStatePromise;
      const { language, username, email, password2  } = data;
      // let { language, password1, email, displayName } = data;

      // pitää luoda doLinkWithPhoneNumber
      //await firebase.doUpdatePhoneNumber(`${phoneNumber}`);

      // must be synchronous update!
      await firebase.doUpdateProfile({ displayName: `${username}` });
      await firebase.doUpdateEmail(`${email}`);
      if(password2) await firebase.doUpdatePassword(`${password2}`);

      await firebase.transaction(firebase.profile(id), (snapshot) => {
        return {...Object.assign(snapshot || {}, { language, email })}
      });

      dispatch({ type:  ACTION_WIZARD_SUCCESS, payload: { redirect: true, response: { returnPath: '/wizard/Validate' }}});
    } catch (err) {
      switch (err.code) {
        case 'auth/requires-recent-login':
          //const value = window.prompt('auth/requires-recent-login');
          dispatch({ type:  ACTION_WIZARD_FAILURE, payload: 'auth/requires-recent-login' });
          firebase.auth.onAuthStateChanged((user) => console.log(user));
          break;
        default:
         dispatch({ type:  ACTION_WIZARD_FAILURE, payload: err });
         onError(dispatch, err);
      }
   } finally {
     await sleep(4000)
     dispatch({ type: ACTION_WIZARD_IDLE });
   }
  }
};

// vaihdeapp callerid update!
export const updateUserAction = (id, data) => {
  return async (dispatch, getState, firebase) => {
    const loadingKey = enqueueSnackbar(dispatch, 'LOADING')
    try {
      await firebase.authStatePromise;

      // import {
      //   updateUserAction,
      // } from 'store/functions';

      await firebase.transaction(firebase.user(id), (snapshot) => {
        return {...Object.assign(snapshot || {}, data)};
      });
      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey);
    } catch (err) {
      onError(dispatch, err);
    }
  };
}
