import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';

import { sortCollection } from 'devtools/Helpers';

import { Form, Field, change, reduxForm, SubmissionError  } from 'redux-form';

import PhoneAndroidIcon from  '@mui/icons-material/PhoneAndroid';
import DialerSipIcon from  '@mui/icons-material/DialerSip';
import NewReleasesIcon from  '@mui/icons-material/NewReleases';

import RegularButton from 'components/CustomButtons';
import CustomTable from 'components/CustomTable';
import { GreenSwitch, GreenThemeSwitch, } from 'components/Custom/CustomSwitch';
import { OrangeThemeCheckBox, OrangeCheckBox } from 'components/Custom/CustomCheckBox';
import { Card, CardBody, CardFooter } from 'components/Card';
import { InputBadge } from 'components/CustomBadge';
import { CustomRinggroupNumberInput } from 'components/CustomInput';
import { HelperTooltip } from 'components/CustomTooltips';

import { DNDIcon, CallForwardIcon } from 'components/CustomIcons';

import { Avatar, Chip, Box, TableCell, TableRow, Checkbox, Tooltip, Grid, Collapse } from '@mui/material';

import { FormSwitch, FormCheckBox, FormRinggroupNumbers } from 'components/Forms/Components';

import { updateRinggroupExtensionsActions } from 'store/ringgroups/actions';

const useStyles = makeStyles({
  root: {
  },
  callForwardEnabled: {
    backgroundColor: '#ffc884',
    '&:hover': {
      backgroundColor: '#f1ad5a !important'
    }
  },
});

// const EMPTY_FORM_STATE = {
//   extensions: [],
//   forward_all_destination: null,
//   forward_all_enabled: false,
//   forward_busy_destination: null,
//   forward_busy_enabled: false,
//   forward_no_answer_destination: null,
//   forward_no_answer_enabled: false,
//   forward_user_not_registered_destination: null,
//   forward_user_not_registered_enabled: false,
// }

const FORM = 'UserRinggroupsTable';

const UserRinggroupsTable = ({ profile, initialValues, updateExtension, ...props }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { handleSubmit, pristine, dirty, reset, submitting, change, form } = props;

  useEffect(() => {
    const ringgroups = sortCollection(profile.ringgroups, 'OrderByRinggroup');
    setData(ringgroups)
  }, [profile])

  async function submit(formvalues) {
    try {
      let obj = {}
    	for(let {id, destination_delay, destination_number, destination_prompt, destination_timeout, domain_uuid, extension_uuid, isActive, ring_group_destination_uuid, ring_group_uuid} of Object.values(formvalues)) {
      	obj[id] = { destination_delay, destination_number, destination_prompt, destination_timeout, domain_uuid, extension_uuid, isActive, ring_group_destination_uuid, ring_group_uuid };
      }
      await updateExtension(obj);
      return true;
    } catch (err) {
      throw new SubmissionError({
        submit: 'Submiting Error',
        _error: err,
      });
    } finally {
      //setTimeout(() => reset(), 500);
    }
  }

  const headCells = [
    { id: 'states', alignment: 'center', display: true, padding: 'normal', sort: false, label: <Tooltip title="Active states that may affect call routing!" placement='top' arrow><NewReleasesIcon /></Tooltip> },
    { id: 'ringgroup', alignment: 'left', display: true, padding: 'normal', sort: false, label: 'Ringgroup' },
    { id: 'extension', alignment: 'left', display: true, padding: 'normal', sort: false, label: 'Extension' },
    { id: 'confirm_calls', alignment: 'center', display: true, padding: 'normal', sort: false, label: <HelperTooltip theme='warning' title='text_confirmcalls' badge><span>Confirm Calls</span></HelperTooltip> },
    { id: 'delay', alignment: 'center', display: true, padding: 'none', sort: false, label: 'Position' },
    { id: 'duration', alignment: 'center', display: true, padding: 'none', sort: false, label: 'Ring Duration' },
    { id: 'active', alignment: 'center', display: true, padding: 'normal', sort: false, label: 'Activated' },
  ];

  return (
    <Card>
      <form onSubmit={handleSubmit(submit)} autoComplete='off'>
        <CardBody noPadding>
          <CustomTable
            data={data}
            headCells={headCells}
            options={{
              dense: false,
              order: 'asc',
              orderBy: '',
              checkboxCollumn: {
                index: 0,
                display: true
              },
            }}
            components={{
              defaultRow: <Chip label="No Ringgroup Extensions" />,
              selectedToolbar: function(selected, clearSelected) {
                return (
                  <Grid container justifyContent="center" alignItems="center" direction="row" spacing={5}>
                    <Grid item>
                      <Tooltip title="Enable Confirm Calls" placement='top' arrow>
                        <div>
                          <OrangeCheckBox
                            checked={false}
                            onChange={(e, value) => {
                              for(let item of selected()) {
                                if(!item.extension.sip_client) change(`${item.ring_group_destination_uuid}.destination_prompt`, value ? 1 : null)
                              }
                            }}
                            name="toggleAllCheckbox"
                          />
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <InputBadge
                        badgeContent="Position"
                        theme="Light"
                      >
                        <CustomRinggroupNumberInput
                          name='toggleAllPosition'
                          onChange={(value) => {
                            for(let item of selected()) {
                              change(`${item.ring_group_destination_uuid}.destination_delay`, value)
                            }
                          }}
                        />
                      </InputBadge>
                    </Grid>

                    <Grid item>
                      <InputBadge
                        badgeContent="Duration"
                        theme="Light"
                      >
                        <CustomRinggroupNumberInput
                          name='toggleAllDuration'
                          onChange={(value) => {
                            for(let item of selected()) {
                              change(`${item.ring_group_destination_uuid}.destination_timeout`, value)
                            }
                          }}
                        />
                      </InputBadge>
                    </Grid>

                    <Grid item>
                      <Tooltip title="Enable Extensions" placement='top' arrow>
                        <div>
                          <GreenSwitch
                            name="toggleAllSwitch"
                            checked={false}
                            onChange={(event) => {
                              for(let item of selected()) {
                                change(`${item.ring_group_destination_uuid}.isActive`, event.currentTarget.checked)
                              }
                            }}
                            inputProps={{ 'aria-label': 'toggleAllSwitch checkbox' }}
                          />
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                );
              },
              rowCells: function(row, index, rowClick, isRowSelected, labelId, disableRowSelectOnClick, collapse, toggleCollapse) {
                return (
                  <TableRow
                    hover
                    color="danger"
                    onClick={(event) => rowClick(event, row)}
                    role="checkbox"
                    aria-checked={isRowSelected}
                    tabIndex={-1}
                    key={labelId}
                    classes={{ root: row.enabled ? classes.callForwardEnabled : classes.root }}
                    selected={isRowSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isRowSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>

                    <TableCell padding='normal' align="center" onClick={disableRowSelectOnClick}>
                      <Grid container justifyContent="center" alignItems="center" direction="row" spacing={0}>
                        {
                          row.extension.do_not_disturb &&
                            <Grid item>
                              <DNDIcon />
                            </Grid>
                        }

                        {
                          row.extension?.callforward?.forward_all_enabled &&
                            <Grid item>
                              <CallForwardIcon data={row.extension.callforward} />
                            </Grid>
                        }
                      </Grid>


                    </TableCell>

                    <TableCell padding='normal' align="left">
                      <InputBadge
                        badgeContent={row.ringgroup.ring_group_extension}
                        max={99999}
                        theme="Dark"
                      >
                        <Chip label={ row.ringgroup.ring_group_description || row.ringgroup.ring_group_name } />
                      </InputBadge>
                    </TableCell>

                    <TableCell padding='normal' align="left">
                      <InputBadge
                        badgeContent={row.destination_number}
                        max={99999}
                        theme="Dark"
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <Avatar>
                          {
                            row.extension.sip_client
                            ? <DialerSipIcon />
                            : <PhoneAndroidIcon />
                          }
                        </Avatar>
                      </InputBadge>


                    </TableCell>

                    <TableCell padding="checkbox" align="center" onClick={disableRowSelectOnClick}>
                      {
                        !row.extension.sip_client &&
                          <Field
                            component={FormCheckBox}
                            parse={(value, name) => value ? 1 : null}
                            name={`${row.ring_group_destination_uuid}.destination_prompt`}
                            useTheme={OrangeThemeCheckBox}
                            formControlLabelProps={{
                              style: { margin: 0 }
                            }}
                          />
                      }
                    </TableCell>

                    <TableCell padding='none' align="center" onClick={disableRowSelectOnClick}>
                      <InputBadge
                        badgeContent={row.ringgroup.ring_group_strategy === 'simultaneous' ? 'Delay' : 'Order' }
                        theme="Light"
                      >
                        <Field
                          component={FormRinggroupNumbers}
                          label="Delay"
                          name={`${row.ring_group_destination_uuid}.destination_delay`}
                          size="small"
                        />
                      </InputBadge>


                    </TableCell>


                    <TableCell padding='none' align="center" onClick={disableRowSelectOnClick}>
                      <InputBadge
                        badgeContent="Duration"
                        theme="Light"
                      >
                        <Field
                          component={FormRinggroupNumbers}
                          label="Duration"
                          name={`${row.ring_group_destination_uuid}.destination_timeout`}
                          size="small"
                        />
                      </InputBadge>

                    </TableCell>



                    <TableCell padding="checkbox" align="center" onClick={disableRowSelectOnClick}>
                      <Field
                        component={FormSwitch}
                        name={`${row.ring_group_destination_uuid}.isActive`}
                        useTheme={GreenThemeSwitch}
                        formControlLabelProps={{
                          style: { margin: 0 }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              }
            }}
          />
        </CardBody>
        <Collapse in={dirty && !pristine} timeout="auto" unmountOnExit>
          <CardFooter>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <RegularButton
                  type="submit"
                  size='sm'
                  color="success"
                  round
                  disabled={pristine || submitting}
                >
                  <Box pr={1} display={submitting ? 'block' : 'none' }>
                    <i className={"fas fa-spinner fa-spin"} />
                  </Box>
                  Apply
                </RegularButton>

              </Grid>
              <Grid item>
                <RegularButton
                  type="button"
                  size='sm'
                  color="warning"
                  round
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Undo
                </RegularButton>
              </Grid>
            </Grid>
          </CardFooter>
        </Collapse>
      </form>
    </Card>
  )
}

UserRinggroupsTable.defaultProps = {
  profile: {}
};


const mapStateToProps = state => ({
  initialValues: state.formState[FORM],  // pull initial values from form reducer
});

const mapDispatchToProps = dispatch => ({
  updateExtension: (data) => dispatch(updateRinggroupExtensionsActions(data)),
  change,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM,
    enableReinitialize: true,
    destroyOnUnmount: true,
    // onChange: (values) => console.log(values)
    // validate,
    // asyncValidate,
  })(UserRinggroupsTable)
)
