import { firebase, store } from 'index.js';

import { isEmpty } from 'lodash';

import { onError, enqueueSnackbar } from 'store/Notifier/actions';

import { uniqBy } from "lodash";

import { ReplaySubject, combineLatest } from "rxjs";
import { map, distinctUntilChanged } from 'rxjs/operators';

import { makeCollection, sortCollection, sortByTime, parseTypes, compare } from 'devtools/Helpers';

import { serviceCleanup } from './index';

const organizationsRef = function () {
  return firebase.contacts().child('organizations');
}

export const service_organizations = function () {
  const observable = new ReplaySubject();

  const subscriptions = [];

  const refs = [
    firebase.doFirebaseObservableQuery(organizationsRef().orderByChild("name"), "on", "value", observable, true), // true for collection
  ];

  //console.warn("account path:", arr.reverse().join('/'));

  const observable$ = observable
    .pipe(
      map((contacts) => {
        return uniqBy(contacts, 'name');
      }),
      distinctUntilChanged(),
    );

  function add_subscriber(callback) {
    return subscriptions.push(
      observable$.subscribe(
        callback,
        err => console.error('service_organizations', err)
      )
    ); // add to array and return subscription!
  }

  return {
    add_subscriber: add_subscriber,
    remove_subscriber: (subscription) => serviceCleanup(subscription, refs, subscriptions),
  }
}

export const add_organization = async (data = {}) => {
  if(isEmpty(data)) throw 'data missing';

  try {
    await firebase.authStatePromise;
    return await organizationsRef().push(data);
  } catch (err) {
    console.log(err);
    onError(store.dispatch, err?.response ? await err.response.text() : err);
  }
  return;
}

export const update_organization = async ({ id = 'new', ...newData }) => {
  if(isEmpty(newData))
    throw 'data missing';

  try {
    await firebase.authStatePromise;
    return await firebase.transaction(organizationsRef().child(id), function(data = {}) {
      return Object.assign(data, newData);
    })
  } catch (err) {
    console.log(err);
    onError(store.dispatch, err?.response ? await err.response.text() : err);
  }
  return;
}

export const remove_organization = async (id) => {
  if(!id) throw 'id missing';

  try {
    await firebase.authStatePromise;
    return await organizationsRef().child(id).remove();
  } catch (err) {
    console.log(err);
    onError(store.dispatch, err?.response ? await err.response.text() : err);
  }
  return;
}

// check for mathing name or y-tunnus!
export const organization_check_value = async (prop, value) => {
  if(!prop)
    throw 'prop missing';
  else if (!value)
    return false;

  try {
    await firebase.authStatePromise;
    return organizationsRef().orderByChild(prop).equalTo(value).once('value')
      .then((snapshots) => {
        return snapshots.exists();
      })
  } catch (err) {
    console.log("organization_check_value", err)
  }
  return false;
}
