import React from 'react';
import { styled } from '@mui/material/styles';

import {
  Badge,
} from '@mui/material';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '&.success': {
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700 !important',
    }
  },
  '&.warning': {
    '& .MuiBadge-badge': {
      backgroundColor: '#ffc215 !important',
    }
  },
  '&.danger': {
    '& .MuiBadge-badge': {
      backgroundColor: '#ed350b; !important',
    }
  },
  '& .MuiBadge-badge': {
    backgroundColor: '#898989',
    color: '#44b700',
    width: '9px',
    height: '9px',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""',
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

export default StyledBadge;
