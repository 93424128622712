import React, { useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import { Button, Tabs, Tab, Icon } from '@mui/material';

import { Card, CardBody } from 'components/Card';

import ViewModuleIcon from  '@mui/icons-material/ViewModule';
import MenuIcon from  '@mui/icons-material/Menu';

const gridOptions = [
  { value:6, label: 'Grid Layout', icon: 'view_module' },
  { value:10, label: 'List Layout', icon: 'menu' }
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 6px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  tab: {
    minWidth: 'inherit !important'
  }
}));

const GridSelectionBar = ({ onChange, values, ...props}) => {
  const classes = useStyles();

  return (
    <Card style={{ width: 'max-content', marginBottom: 0 }}>
      <CardBody style={{ padding: '8px 4px' }}>
        <Tabs
          value={values.gridLayout}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, value) => onChange(event, { gridLayout: value })}
          aria-label="disabled tabs example"
        >
          {
            gridOptions.map((item, index) => (
              <Tab key={index} component={Button} classes={{ root: classes.tab }}  size='small' value={item.value} label={<Icon>{item.icon}</Icon>} />
            ))
          }
        </Tabs>
      </CardBody>
    </Card>
  );
}

export default GridSelectionBar;
