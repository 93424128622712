import {
  RINGGROUPS_PENDING,
  RINGGROUPS_SUCCESS,
  RINGGROUP_SUCCESS,
  ACTION_PENDING,
  ACTION_SUCCESS,
  ACTION_FAILURE,
} from 'store/types';

import { onError, enqueueSnackbar } from 'store/Notifier/actions';
import { merge } from 'lodash';
import { firebaseNulls } from 'devtools/Helpers';

export const ringgroups_Actions = () => {
  return async (dispatch, getState, firebase) => {
    dispatch({ type: RINGGROUPS_PENDING });

    try {
      await firebase.authStatePromise;
      await firebase.ringgroups().on('value',
        snapshot => {
          dispatch({ type: RINGGROUPS_SUCCESS, payload: asCollection(snapshot.val()) });
        },
        err => {
          throw err;
        }
      );
    } catch (err) {
      onError(dispatch, err);
    }
  };
};

export const ringgroup_Actions = id => {
  return async (dispatch, getState, firebase) => {
    dispatch({ type: RINGGROUPS_PENDING });

    try {
      await firebase.authStatePromise;
      await firebase.ringgroups().child(id).on('value',
        snapshot => {
          dispatch({ type: RINGGROUP_SUCCESS, payload: { id: snapshot.key, ...snapshot.val() } });
        },
        err => {
          throw err;
        }
      );
    } catch (err) {
      onError(dispatch, err);
    }
  };
};

export const setUsersForGroupsActions = (users, id) => {
  return async (dispatch, getState, firebase) => {
    dispatch({ type:  ACTION_PENDING });

    try {
      await firebase.authStatePromise;

      // error need to use promise all.
      users.map(async(user) => {
        const response = await firebase.transaction(firebase.userRinggroup(user.uid, id), (snapshot) => {
          return false;
        });

        if(response.committed === true) {
          dispatch({ type:  ACTION_SUCCESS });
        } else {
          throw response.error;
        }
      });
    } catch (err) {
      dispatch({ type:  ACTION_FAILURE, payload: err });
      onError(dispatch, err);
    }
  }
};

export const unsetUsersForGroupsActions = (users, id) => {
  return async (dispatch, getState, firebase) => {
    dispatch({ type:  ACTION_PENDING });

    try {
      await firebase.authStatePromise;

      // error need to use promise all.
      users.map(async(user) => {
        const response = await firebase.transaction(firebase.userRinggroup(user.uid, id), (snapshot) => {
          return null;
        });

        if(response.committed === true) {
          dispatch({ type:  ACTION_SUCCESS });
        } else {
          console.log(response);
          throw response.error;
        }
      })
    } catch (err) {
      dispatch({ type:  ACTION_FAILURE, payload: err });
      onError(dispatch, err);
    }
  }
};

export const ringgroups_update_Actions = (newData) => {
  return async (dispatch, getState, firebase) => {
    const loadingKey = enqueueSnackbar(dispatch, 'LOADING')

    try {
      await firebase.transaction(firebase.ringgroups(), (data) => {
        return {
          // merge objects deep!
          ...merge(data || {}, firebaseNulls(newData, true))
        };
      });
      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey);
      return true;
    } catch (err) {
      onError(dispatch, err);
      return false;
    }
  }
};

export const ringgroups_update_visibilty_Actions = (data) => {
  return async (dispatch, getState, firebase) => {
    const loadingKey = enqueueSnackbar(dispatch, 'LOADING')

    console.log(data)

    try {
      await firebase.ringgroup_extensions().set(firebaseNulls(data, true)); // override all data!
      // await firebase.transaction(firebase.ringgroup_extensions(), (snapshotData) => {
      //   return {...Object.assign(snapshotData || {}, firebaseNulls(data, true))};
      // });
      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey)
    } catch (err) {
      onError(dispatch, err);
    }
  }
};


export const updateRinggroupExtensionsActions = (data) => {
  return async (dispatch, getState, firebase) => {
    const loadingKey = enqueueSnackbar(dispatch, 'LOADING')

    try {
      await firebase.transaction(firebase.ringgroup_extensions(), (snapshotData) => {
        return {...Object.assign(snapshotData || {}, firebaseNulls(data, true))};
      });
      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey)
    } catch (err) {
      onError(dispatch, err);
    }
  }
};


export const ringgroup_update_Actions = (id, newData) => {
  return async (dispatch, getState, firebase) => {
    const loadingKey = enqueueSnackbar(dispatch, 'LOADING')

    try {
      await firebase.transaction(firebase.ringgroups().child(id), (data) => {
        return {...Object.assign(data || {}, firebaseNulls(newData, true))}
      });

      enqueueSnackbar(dispatch, 'SUCCESS', loadingKey);
    } catch (err) {
      console.log(err);
      onError(dispatch, err);
    };
  }
};

const asCollection = object => Object.keys(object || {}).map(key => ({ ...object[key], id: key}));
