import React, { Suspense, useEffect } from "react";
import { connect } from 'react-redux';
import { SimpleLoader } from 'components/Loaders';
import { useNavigate } from "react-router-dom";
import { Box, Grid, Zoom } from '@mui/material';
//import LockScreenPage from "views/Pages/LockScreenPage";

import Button from "components/CustomButtons/Button.js";

import WarningIcon from  '@mui/icons-material/Warning';
import DoneIcon from  '@mui/icons-material/Done';

const ShowLoading = (props) => (
  <Zoom in={true} timeout={250}>
    <Box display="flex" flexDirection="row" justifyContent="center">
      <SimpleLoader p={2} />
      <h3 style={{ margin: 0, alignSelf: 'center' }}>Updating Profile</h3>
    </Box>
  </Zoom>
);

const ShowErrors = ({errors, wizardState, ...props}) => (
  <Zoom in={true} timeout={250}>
    <Box display="flex" flexDirection="row" justifyContent="center">
      <Button
        justIcon
        round
        color="danger"
        style={{ marginRight: '8px' }}
      >
        <WarningIcon />
      </Button>
      <h3 style={{ margin: 0, alignSelf: 'center' }}>Task Failed!{ wizardState.redirect ? ', Redirecting!' : ''}</h3>
    </Box>
  </Zoom>
);

const ShowSuccess = ({ wizardState, ...props }) => (
  <Zoom in={true} timeout={250}>
    <Box display="flex" flexDirection="row" justifyContent="center">
      <Button
        justIcon
        round
        color="success"
        style={{ marginRight: '8px' }}
      >
        <DoneIcon />
      </Button>
      <h3 style={{ margin: 0, alignSelf: 'center' }}>Update Complete!{ wizardState.redirect ? ', Redirecting!' : ''}</h3>
    </Box>
  </Zoom>
);

const LoginNeeded = (props) => (
  //<LockScreenPage />
  <div />
)

const WizardLoadingState = ({children, ...props }) => {
  const navigate = useNavigate();
  const { wizardState } = props;

  useEffect(() => {
    switch (wizardState.type) {
      case 'success':
        if(wizardState.redirect) {
          setTimeout(() => {
            if(wizardState?.response?.returnPath) {
              navigate(wizardState.response.returnPath);
            } else {
              navigate(-1);
            }
          }, 3000);
        }
        break;
      default:
    }
  }, [wizardState]);

  return (
    <Grid container justifyContent="center" style={{ zIndex: 5 }}>
      {
        (() => {
          switch (wizardState.type) {
            case 'pending':
              return (<ShowLoading {...props}/>);
            case 'failure':
              switch (wizardState.error) {
                case '':
                  return (<LoginNeeded {...props}/>);
                default:
                  return (<ShowErrors {...props} errors={wizardState.error}/>);
              }
            case 'success':
              return (<ShowSuccess {...props}/>);
            default:
              return (
                <>
                  <Suspense fallback={<ShowLoading {...props}/>}>
                    {children}
                  </Suspense>
                </>
              );
          }
        })()
      }
    </Grid>
  );
}

const mapStateToProps = state => ({
  wizardState: state.wizardState
});

export default connect(
  mapStateToProps,
  null
)(WizardLoadingState);
