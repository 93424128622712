import React, { useContext, useEffect, createContext, useState } from "react";
import JsSIP from 'jssip';
import { merge, clone } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { firebase } from 'index.js';

import { makeCollection } from 'devtools/Helpers';

import {
  useForceRender,
	ForceRenderProvider
} from 'Providers';

// import deepmerge from 'deepmerge';
import Logger from './Logger';

import storage from './storage';
import { outbound_calllog_action } from './outbound_calllogManager';

import { Subject } from "rxjs";

const logger = new Logger('settingsManager');

let calllog_render$ = new Subject();
let phone = null;
let status = "connecting";

const WEBRTC_CALLLOG_STORAGE_NAME = 'sipCalls';

function getStoreValue(store = WEBRTC_CALLLOG_STORAGE_NAME) {
  return sortByTime(makeCollection(JSON.parse(window.localStorage.getItem(store)) || {})); // object --> array
}

const INITIALSTATE = getStoreValue();
let calllog = INITIALSTATE;

class CallLogItem {
  // session
  constructor(session = {}) {
    //console.warn('CallLogItem', session)
    const { id, direction, data, start_time = null, end_time = null, remote_identity, local_identity, ...rest} = session;
    const { uid, hasAnswer, contact, contact_uuid } = data;

    return {
      uid: uid,
      direction: direction,
      disposition: session.isEnded() && hasAnswer ? 'Answered' : 'No Answer',
      remote_identity: remote_identity,
      local_identity: local_identity,
      duration: this.#getDuration(end_time, start_time),
      start_time: start_time,
      end_time: end_time,
      time: new Date(),
      contact,
      contact_uuid,
    }
  }

  #getDuration(end, start) {
    if(!start || !end) return null;
    return end - start;
  }
}

function sortByTime(array = [], prop = 'time') {
  return array.sort(({ time: a }, { time: b }) => a > b ? 1 : a < b ? -1 : 0)
}

function reducer(state = INITIALSTATE, action) {
  switch (action.type) {
    case 'CALLLOG_ADD': {
      const calls = sortByTime([...state, action.payload]);
      window.localStorage.setItem(WEBRTC_CALLLOG_STORAGE_NAME, JSON.stringify(calls)); // store calls
      return calls;
    }
    case 'CALLLOG_CLEAR': {
      window.localStorage.removeItem(WEBRTC_CALLLOG_STORAGE_NAME); // store remove calls
      return [];
    }
    case 'CALLLOG_FIX': {
      window.localStorage.removeItem(WEBRTC_CALLLOG_STORAGE_NAME); // store remove calls
      return [];
    }
    default:
      return state;
  }
}


export const calllog_action = (action) => {
  if(action.type === "CALLLOG_ADD") {
    action.payload = new CallLogItem(action.payload);
    if(action.payload.direction === 'outgoing') outbound_calllog_action({ type: "ADD", payload: action.payload });
  }

  calllog = reducer(calllog, action);
  calllog_render$.next(true);
}

export {
	calllog,
}

const withForcedRender = (Component) => (props) => {
	return (
		<ForceRenderProvider observable={calllog_render$}>
			<Component {...props} />
		</ForceRenderProvider>
	)
}

export const OutboundCalllogItems = withForcedRender(({ children }) => {
  const [state] = useForceRender();
  return children(calllog.filter(item => item.direction === 'outgoing')) // outgoing only
});

export const CalllogItems = withForcedRender(({ children }) => {
  const [state] = useForceRender();
  return children(calllog)
});
