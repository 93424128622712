import React from 'react';

import ExtensionIcon from  '@mui/icons-material/Extension';
import MusicNoteIcon from  '@mui/icons-material/MusicNote';
import AccountTreeIcon from  '@mui/icons-material/AccountTree';

import { Subject, ReplaySubject, combineLatest } from "rxjs";
import { map, distinctUntilChanged, filter } from 'rxjs/operators';

import { groupBy, partition, keyBy, merge, values, uniqBy, uniqWith, isEqual } from 'lodash'

export const rawuser_observable = new Subject();
export const announcements_observable = new Subject();
export const numbers_observable = new Subject();
export const handled_observable = new Subject();
export const unhandled_observable = new Subject();
export const callerids_observable = new Subject();
export const extensions_observable = new Subject();
// export const timeconditions_observable = new Subject();
export const timeconditions_observable = new ReplaySubject();
export const users_observable = new Subject();
//export const user_observable = new Subject();
export const currentUser_observable = new Subject();
export const views_observable = new Subject();
export const ringgroups_observable = new Subject();
export const ringgroupExtensions_observable = new Subject();
export const extensions_with_user_observable = new Subject();
export const fusionpbx_userAccount_observable = new Subject();
export const voicemails_observable = new Subject();
export const voicemail_greetings_observable = new Subject();
export const filters_observable = new Subject();
export const select_timecondition_observable = new Subject();
export const select_ringgroup_observable = new Subject();
export const select_profile_observable = new Subject();

export const snackbars_observable = new Subject();

export const missedcalls_available_datasets_observable = combineLatest(unhandled_observable, handled_observable)
  .pipe(
    distinctUntilChanged(),
    map(([unhandled, handled]) => uniqWith(uniqBy([...unhandled, ...handled], 'soitettu').map(item => ({ label: { primary: item.soitettu }, value: item.soitettu, category: 'Destinations' })), isEqual))
  );

export const users_with_extensions_observable = combineLatest(users_observable, extensions_observable)
  .pipe(
    distinctUntilChanged(),
    map(([users, extensions]) => {
      const users_with_extensions = users.map(data => ({ ...data, extensions: extensions.filter(item => item.userid === data.id)}));
      const extensions_with_user = extensions.map(data => ({ ...data, user: users.find(user => data.userid === user.id)}));
      extensions_with_user_observable.next(extensions_with_user);
      return [users_with_extensions, extensions_with_user];
    })
  );

export const freeswitch_destinations_observable = combineLatest([ringgroups_observable, extensions_observable, announcements_observable])
    .pipe(
      distinctUntilChanged(),
      map(([ringgroups, extensions, annnoucements]) => {
        return [
          { primary: 'None', app: 'null', value: 'null', category: 'Other' },
          { primary: 'Hangup', app: 'hangup', value: 'hangup', category: 'Other' },
          // ...extensions.map(({ extension, destination, description, ...data}) => ({ value: destination, primary: description, secondary: extension, app: 'transfer', icon: <ExtensionIcon />, category: 'Extensions' })),
          ...extensions.map(({ extension, destination, description, user_context, ...data}) => ({ value: destination || `${extension} XML ${user_context}`, primary: description || `${extension}`, secondary: extension, app: 'transfer', icon: <ExtensionIcon />, category: 'Extensions' })),
          ...annnoucements.map(({ recording_description, recording_filename, ...data }) => ({ value: recording_filename, primary: recording_description, app: 'lua', icon: <MusicNoteIcon />, category: 'Annoucements' })),
          ...ringgroups.map(({ ring_group_extension, dialplan_destination, ring_group_name, ring_group_description, ...data }) => ({ primary: ring_group_description || ring_group_name, secondary: ring_group_extension, value: dialplan_destination, app: 'transfer', icon: <AccountTreeIcon />, category: 'Ringgroups' })),
        ];
      })
    );

export const freeswitch_destinations_objects_observable = combineLatest([ringgroups_observable, extensions_observable, announcements_observable])
    .pipe(
      distinctUntilChanged(),
      map(([ringgroups, extensions, annnoucements]) => {
        return [
          { primary: 'None', app: 'null', value: null, category: 'Other'},
          { primary: 'Hangup', app: 'hangup', value: { application: null, data: 'hangup' }, category: 'Other' },
          ...annnoucements.map(({ recording_description, recording_filename, ...data }) => ({ value: { application: 'lua', data: `streamfile.lua ${recording_filename}`}, primary: recording_description, icon: <MusicNoteIcon />, category: 'Annoucements' })),
          ...ringgroups.map(({ ring_group_extension, dialplan_destination, ring_group_name, ring_group_description, ...data }) => ({ value: { application: 'transfer', data: dialplan_destination }, primary: ring_group_description || ring_group_name, secondary: ring_group_extension, icon: <AccountTreeIcon />, category: 'Ringgroups' })),
          ...extensions.map(({ extension, destination, description, user_context, ...data}) => ({ value: { application: 'transfer', data: destination || `${extension} XML ${user_context}`}, primary: description || `${extension}`, secondary: extension, icon: <ExtensionIcon />, category: 'Extensions' })),
        ];
      })
    );


export const cids_observable = combineLatest([numbers_observable, callerids_observable])
    .pipe(
      distinctUntilChanged(),
      map(([numbers, cids]) => {
        return [
          ...numbers.map(({ cc_number, destination_number, destination_uuid, destination_description }) => ({ cc_number: cc_number || `+358${String(destination_number).substring(1)}`, description: destination_description, destination_uuid: destination_uuid, number: destination_number })),
          ...cids
        ];
      })
    );

export const currentRinggroup_observable =
  combineLatest([ringgroups_observable, select_ringgroup_observable])
    .pipe(
      distinctUntilChanged(),
      map(([ringgroups, id]) => {
        return ringgroups.find(x => String(x.id) === String(id))
      })
    );

export const currentTimecondition_observable =
  combineLatest([timeconditions_observable, select_timecondition_observable])
    .pipe(
      distinctUntilChanged(),
      map(([timeconditions, id]) => {
        return timeconditions.find(x => String(x.id) === String(id))
      })
    );

export const extensions_with_ringgroups_and_user_observable =
  combineLatest([users_observable, extensions_observable, ringgroups_observable, ringgroupExtensions_observable])
    .pipe(
      distinctUntilChanged(),
      map(([users, extensions, ringgroups, rgExtensions]) => {
        const ringgroupExtensions = rgExtensions.map(item => ({ position: item, ...ringgroups.find(x => String(x.ring_group_uuid) === String(item.ring_group_uuid))}))
        return extensions.map(extension => {
          // add ringgroups
          return {
            ...extension,
            ringgroups: ringgroupExtensions.filter(item => String(extension.extension_uuid) === String(item.position.extension_uuid)),
            userAccount: users.find(x => String(x.id) === String(extension.userid))
          }
        })
      })
    );


export const search_options_observable =
  combineLatest([numbers_observable, extensions_observable])
    .pipe(
      distinctUntilChanged(),
      map(([numbers, extensions]) => {
        return [
          ...numbers.map(({ cc_number, destination_number, destination_description }) => {
            const number = cc_number || `+358${String(destination_number).substring(1)}`;
            return { label: { primary: destination_description, secondary: number }, value: number, category: 'Inbound Routes' }
          }),
          ...extensions.map(({ extension, description, extension_uuid }) => ({ label: { primary: description, secondary: extension }, value: extension_uuid, category: 'Extensions' }))
        ];
      })
    );


export const available_datasets_observable =
  search_options_observable
    .pipe(
      map((values) => {
        return [
          { label: { primary: 'All Calls' }, category: 'Any' }, // default row
          ...values
        ];
      })
    );

export const phonenumbers_observable =
  combineLatest(numbers_observable, extensions_observable)
    .pipe(
      distinctUntilChanged(),
      map(([numbersRaw, extensionsRaw]) => {
        const numbers = numbersRaw.map(({ cc_number, destination_number, destination_description: description  }) => ({ cc_number: cc_number || `+358${String(destination_number).substring(1)}`, description, short_number: `${destination_number}`.substring(1), category: 'Inbound Routes' }));
        const gsm = extensionsRaw.filter(item => item.number_alias).map(({ number_alias, description }) => ({ cc_number: `+358${String(number_alias).substring(1)}`, short_number: `${number_alias}`.substring(1), description, category: 'Extensions' }));
        return [numbers,gsm].flat();
      })
    )

export const ringgroupTable_observable = combineLatest(currentRinggroup_observable, ringgroupExtensions_observable, extensions_with_user_observable)
  .pipe(
    distinctUntilChanged(),
    map(([ringgroup, rgExtensions, extensions]) => {
      return rgExtensions.filter(extension => extension.ring_group_uuid === ringgroup.ring_group_uuid).map(extension => {
        // attach extensions
        return {
          ...extensions.find(x => x.extension_uuid === extension.extension_uuid), // find correct extension
          ringgroupState: extension
        };
      })
    })
  );

export const voicemails_with_greetings_observable = combineLatest(voicemails_observable, voicemail_greetings_observable)
  .pipe(
    distinctUntilChanged(),
    map(([voicemails, greetings]) => {
      const greetingsObject = groupBy(greetings, 'voicemail_id');
      return voicemails.map(item => ({ ...item, greetings: greetingsObject[item.voicemail_id] || [] }))
    })
  );

export const user_observable = combineLatest(select_profile_observable, users_observable)
  .pipe(
    map(([id, users]) => {
      return users.find(item => item.id === id)
    })
  );

export const ringgroups_combinedData_observable = combineLatest(ringgroups_observable, ringgroupExtensions_observable, extensions_with_user_observable)
  .pipe(
    map(([ringgroups, rgExtensions, extensions]) => {
      // let combinedData = [];
      // let combinedData2 = [];

      const combinedExtensions = rgExtensions.map(data => ({ ...data, extension: extensions.find(extension => extension.extension_uuid === data.extension_uuid)}));
      const collections = groupBy(combinedExtensions, 'ring_group_uuid');

      const combinedData = ringgroups.map(group => {
        const [activated, disabled] = partition(collections[group.ring_group_uuid], function (item) { return item.isActive });

        return {
          ...group,
          extensions: {
            enabled: activated,
            disabled: disabled
          }
        }
      })

      // for(const [key, value] of Object.entries(collections)) {
      //   combinedData = ringgroups.map(group => {
      //     const [activated, disabled] = partition(collections[group.ring_group_uuid], function (item) { return item.isActive });
      //     return {
      //       ...group,
      //       extensions: {
      //         enabled: activated,
      //         disabled: disabled
      //       }
      //     }
      //   })
      // }
      //
      // console.log(combinedData, combinedData2)

      return [ringgroups, rgExtensions, combinedData];
    })
  )

export const profile_observable = combineLatest(user_observable, extensions_observable, ringgroups_observable, ringgroupExtensions_observable)
  .pipe(
    map(([user, extensions, ringgroups, rgExtensions]) => {
      const profileExtensions = extensions.filter(item => item.userid === user.id);

      const merged = {
        ...user,
        extensions: profileExtensions,
        ringgroups: (function() {
          const data = rgExtensions.filter(
            item => profileExtensions.some(extension => extension.extension_uuid === item.extension_uuid)
          ).map(data => ({
            ...data,
            extension: extensions.find(item => item.extension_uuid === data.extension_uuid),
            ringgroup: ringgroups.find(item => item.ring_group_uuid === data.ring_group_uuid)
          }));
          return data;
        })()
      }

      return [user, merged];
    })
  )

export const callforward_list_observable = combineLatest(numbers_observable, extensions_observable, ringgroups_observable)
  .pipe(
    map(([numbersRaw, extensionsRaw, ringgroupsRaw]) => {
      const numbers = numbersRaw.map(({ cc_number, destination_number, destination_description: description  }) => ({ cc_number: cc_number || `+358${String(destination_number).substring(1)}`, description, short_number: `${destination_number}`.substring(1), category: 'Inbound Routes' }));
      const gsm = extensionsRaw.map(({ extension, description }) => ({ icon: <ExtensionIcon />, cc_number: extension, description, category: 'Extensions' }))
      const ringgroups = ringgroupsRaw.map(({ ring_group_extension, ring_group_description, ring_group_name }) => ({ icon: <AccountTreeIcon />, cc_number: ring_group_extension, description: ring_group_name || ring_group_description, category: 'Ringgroups' }))

      return [numbers,gsm,ringgroups].flat() // return []
    })
  )
