import CheckboxSelectionModal from './CheckboxSelectionModal';
import DayConditionModal from './DayConditionModal';
import ModalState from './ModalState';

import withModal from './withModal';

import AnnouncementCreator from './AnnouncementCreator';
import ExtensionsCreator from './ExtensionsCreator';
import ImageCopper from './ImageCopper';

export const AnnouncementCreatorModal = withModal(AnnouncementCreator);
export const ExtensionsCreatorModal = withModal(ExtensionsCreator);
export const ImageCopperModal = withModal(ImageCopper);

export * from './Components';

export {
  CheckboxSelectionModal,
  DayConditionModal,
  ModalState,
};
