import React, { Suspense } from "react";
import { connect } from 'react-redux';

import { Dialog, DialogTitle } from '@mui/material';

import { modal_Actions } from 'store/modals/actions';

import { NewAnnouncementDialog }  from 'components/Announcements';

const ModalState = ({children, open, component, customProps, dispatch, ...props }) => {
  const { title, onClose, modalProps, ...componentProps } = customProps;

  const handleClose = (event, value) => {
    onClose(event, value);
    dispatch(modal_Actions({ open: false, component, customProps })) // dispatch close event for store!
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        { ...modalProps}
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <Suspense fallback={<div />}>
            {
              (() => {
                switch (component) {
                  case 'NewAnnouncementDialog':
                    return <NewAnnouncementDialog { ...componentProps} handleClose={handleClose} />;
                  default:
                    return <div>Error no component found!</div>
                }
              })()
            }

        </Suspense>
      </Dialog>
      {children}
    </div>
  )
}

const mapStateToProps = state => ({
  open: state.modal_State.open,
  component: state.modal_State.component,
  customProps: state.modal_State.customProps
});

export default connect(
  mapStateToProps,
  null
)(ModalState);
