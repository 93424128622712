import React, { useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import classNames from "classnames";
import { makeStyles, createStyles } from '@mui/styles';

import MusicNoteIcon from  '@mui/icons-material/MusicNote';
import AccountTreeIcon from  '@mui/icons-material/AccountTree';

import { ListSubheader, ListItemAvatar, Avatar, MenuItem, MenuList, ListItemText } from '@mui/material';

//import { announcementsActions } from 'store/announcements/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  secondaryAction: {
    paddingRight: '80px'
  },
  primary: {
    fontSize: '12px'
  },
  normal: {
    display: 'flex',
    width: theme.spacing(4),
    height: theme.spacing(4),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  }
}));

const AnnouncementsMenuItems = ({ announcementsLoading, announcements, getAnnouncements, ...props}) => {
  const classes = useStyles();

  useEffect(() => {
    if (announcementsLoading === null) {
      getAnnouncements();
    }
  }, [announcements, announcementsLoading]);

  const CustomMenuItem = React.useMemo(
    () =>
      ({ prefix, option, handleMenuItemClick, selected, ...props}) => {
        // const optionValue = prefix ? prefix +' '+ option.recording_filename : option.recording_filename;
        const optionValue = prefix ? `${prefix}${option.recording_filename}` : option.recording_filename;
        return (
          <MenuItem
            button
            style={{ minWidth: '300px '}}
            key={option.recording_uuid}
            selected={optionValue === selected}
            onClick={(event) => handleMenuItemClick(event, optionValue)}
          >
            <ListItemAvatar>
              <Avatar classes={{ root: classes.normal }}>
                <MusicNoteIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={<span>{option.recording_description}</span>}
              secondary={<small>{option.recording_filename}</small>}
            />
          </MenuItem>
        )
      },
    [],
  );

  return (
    <>
      <ListSubheader component="div" id="nested-list-subheader" style={{ backgroundColor: 'white'}}>Announcements</ListSubheader>
      {
        announcements && announcements.map((option, index) => <CustomMenuItem key={index} option={option} {...props}/>)
      }
    </>
  )
}

const mapStateToProps = state => ({
  announcements: state.announcements_State.announcements || [],
  announcementsLoading: state.announcements_State.loading,
});

const mapDispatchToProps = dispatch => ({
  getAnnouncements: () => {
    //dispatch(announcementsActions())
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementsMenuItems);
