import React from 'react';
import { TextField, MenuList, MenuItem, ListSubheader, ListItemText, ListItemIcon } from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const FormSelectField = (
  { input: { onChange, value, name }, label, meta: { touched, error }, id, helperText, options, menuItemProps, formControlProps, InputLabelProps, header, variant, ...custom },
) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel {...InputLabelProps} id={id + '-label'}>{label}</InputLabel>
      <Select
        labelId={id + '-label'}
        id={id}
        value={value}
        label={label}
        {...custom}
      >
        <MenuList
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              { header }
            </ListSubheader>
          }
        >
          <MenuItem value={null} onClick={(event) => onChange(null)}><em>None</em></MenuItem>

          {
            options.map(({ label: { primary, secondary }, icon, value: optionValue }) => {
              return (
                <MenuItem key={optionValue} value={optionValue} {...menuItemProps} onClick={(event) => onChange(optionValue)} selected={optionValue === value}>
                  {
                    icon &&
                      <ListItemIcon>
                        {icon}
                      </ListItemIcon>
                  }
                  <ListItemText primary={primary} secondary={secondary} />
                </MenuItem>
              )
            })
          }
        </MenuList>
      </Select>
    </FormControl>
  )
}

FormSelectField.defaultProps = {
  id: 'demo-simple-select-outlined',
  header: 'Options',
  options: [],
};

export default FormSelectField;
