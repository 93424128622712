import React, { useState } from 'react';
import { grey } from '@mui/material/colors';

// @mui/material components
import { makeStyles, createStyles } from '@mui/styles';

// react plugin for creating charts

//import ExpandMoreIcon from  '@mui/icons-material/ExpandMore';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
//import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { CardBody } from "components/Card";

import { Collapse, CardActions, IconButton, Button } from '@mui/material';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';


// import Moment from "devtools/momentjs";

import { Fade, Box, Chip, Badge } from '@mui/material';

import { total, avarage, max, percentage } from 'Helpers/Math';

import { COLORS } from "constants/index.js";

import styles from "assets/jss/views/dashboardStyle.js";
const useStyles = makeStyles(createStyles(styles));

// const HelperBadge = withStyles((theme) => ({
//   badge: {
//     right: -10,
//     top: 5,
//     lineHeight: '1.5 !important',
//   },
//   colorError: {
//     backgroundColor: grey[800],
//   }
// }))(Badge);

const ChartAdditionalInformation = (props) => {
  const classes = useStyles();
  const { id, data, color, datasets, labels, type, options, filters } = props;

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const fullDataset = datasets.map(dataset => dataset.data).flat();

  return (
    <div>
      <CardActions disableSpacing style={{ justifyContent: 'center' }}>
        <Button size="small" color="primary" onClick={handleExpandClick}>
          Additional Information
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardBody style={{ textAlign: 'right' }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Datasets</TableCell>
                  {
                    filters.view !== 'Handled By'
                      ? <React.Fragment>
                          <TableCell align="center">
                            <HelperTooltipBootstrap badge title="Avarage is calculated between datasets that have higher value than 0" >
                              Avarage
                            </HelperTooltipBootstrap>
                          </TableCell>
                          <TableCell align="center">Highest</TableCell>
                        </React.Fragment>
                      : <React.Fragment>
                          <TableCell align="center">
                            <HelperTooltipBootstrap badge title="Percentage of all Handled Calls!" >
                              Percentage
                            </HelperTooltipBootstrap>
                          </TableCell>
                        </React.Fragment>

                  }
                <TableCell align="center">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                datasets.map((item, index) => {
                  const dataset = Object.values(item.data);
                  const count = total(dataset);
                  const avgCountWithoutZore = avarage(dataset, false);
                  const percentageOf = percentage(total(fullDataset), count);
                  const highest  = max(dataset);

                  return (
                    <TableRow key={item.label}>
                      <TableCell align="left" component="th" scope="row"><Chip label={item.label} /></TableCell>
                        {
                          filters.view !== 'Handled By'
                            ? <React.Fragment>
                                <TableCell align="center"><b>{String(avgCountWithoutZore)}</b></TableCell>
                                <TableCell align="center"><b>{String(highest )}</b></TableCell>
                              </React.Fragment>
                            : <React.Fragment>
                                <TableCell align="center"><b>{String(percentageOf) + '%'}</b></TableCell>
                              </React.Fragment>

                        }
                      <TableCell align="center"><b>{String(count)}</b></TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </CardBody>
      </Collapse>
    </div>
  );
};

export default ChartAdditionalInformation;
