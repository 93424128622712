import React  from 'react';

import GroupIcon from  '@mui/icons-material/Group';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';
import RegularButton from "components/CustomButtons/Button.js";

function getColor(active, total) {
  const missing = total - active;

  if(total === 0) {
    return 'github';
  } else if(active === 1 && total >  2) {
    return 'warning';
  } else {
    return 'success';
  }
}

const ActiveExtensionsButton = ({active, disabled, width, ...props}) => {
  const combined = active + disabled;

  return (
    <RegularButton
      round
      type="button"
      size='sm'
      color={getColor(active, combined)}
      title={`${active} / ${combined} Active Extensions`}
      style={{ width: width }}
      {...props}
    >
      <strong>{active} / {combined}</strong> <GroupIcon style={{ marginLeft: 6, marginTop: 0 }} />
    </RegularButton>
  )
}

ActiveExtensionsButton.defaultProps = {
  active: 0,
  disabled: 0,
  arrow: true,
  placement: 'top',
  width: '110px',
}

export default ActiveExtensionsButton;
