import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { Modal, Backdrop, Fade, Grid,  Button, Divider, Box, Container, Step, Stepper, StepLabel } from '@mui/material';

import { DTimePicker, DestinationPicker } from 'components/Pickers';

//import MusicNoteIcon from  '@mui/icons-material/MusicNote';
import HeightIcon from  '@mui/icons-material/Height';
import SaveIcon from  '@mui/icons-material/Save';

import Moment from 'devtools/momentjs';
import { getDestination } from 'devtools/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const DayConditionModal = ({modalOpen, emitData, ...props}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    'Time Range',
    'Destination'
  ];

  const handleNext = () => {
    if(activeStep === steps.length - 1) handleComplete();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleComplete = () => {
    emitData(data);
    handleClose();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  useEffect(() => {
    console.log(modalOpen);
    if(modalOpen) {
      setData(modalOpen.data || null);
      setOpen(true);
    }
  }, [modalOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  const newDestination = value => {
    Object.assign(data[1].action[0].$, getDestination(value));
    setData(data);
  }

  const timeHandler = (state, event) => {
    const newMonth = Moment.command(moment => moment(event).format('M'));
    const newDay = Moment.command(moment => moment(event).format('D'));
    const newTime = Moment.TimetoMinutes(Moment.command(moment => moment(event).format('HH:mm')));
    let object;

    if(state === 'Starttime') {
      const month = Number(data[1]?.$?.mon?.split('-')[1]) || '' ;
      const day = Number(data[1]?.$?.mday?.split('-')[1]) || '' ;
      const time = data[1]?.$?.['minute-of-day'].split('-')[1] || '';

      object = { mon: `${newMonth}-${month}`, mday: `${newDay}-${day}`, ['minute-of-day']: `${newTime}-${time}` };
    } else {
      const month = Number(data[1]?.$?.mon?.split('-')[0]) || '';
      const day = Number(data[1]?.$?.mday?.split('-')[0]) || '';
      const time = data[1]?.$?.['minute-of-day'].split('-')[0] || '';

      object = { mon: `${month}-${newMonth}`, mday: `${day}-${newDay}`, ['minute-of-day']: `${time}-${newTime}` };
    }

    Object.assign(data[1], { '$': { ...data[1]?.$, ...object } });
    setData(data);
  }

  const getDateTime = index => {
    if(!Array.isArray(data)) return null;

    const year = Moment.command(moment => moment().year());
    const month = Number(data[1]?.$?.mon?.split('-')[index]);
    const day = Number(data[1]?.$?.mday?.split('-')[index]);
    const time = Moment.minutesToTime(data[1]?.$?.['minute-of-day'].split('-')[index]);

    return new Date(`${year}-${month}-${day} ${time}`);
  }

  const getSelectedAnnouncement = () => {
    return data[1].action[0].$.data.split(' ')[1] || null;
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Container maxWidth="md">
          <Card>
            <CardHeader color="primary" icon style={{ alignSelf: 'center' }}>
              <CardIcon color="primary">
                <Box paddingX={5}>
                  <h4 className={classes.cardTitleWhite}>Day Condition?</h4>
                </Box>
              </CardIcon>
            </CardHeader>
            <CardBody style={{ color: 'black' }}>

              <div className={classes.root}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div>
                    <div>
                      {(() => {
                        switch (activeStep) {
                          case 0:
                            return (
                              <Fade in>
                                <Box paddingY={2}>
                                  <Grid container spacing={2} justify="center" className={classes.root}>
                                    <Grid item>
                                      <DTimePicker onChange={time => timeHandler('Starttime', time)} label='Start' datetime={getDateTime(0)} />
                                    </Grid>
                                    <Grid item style={{ alignSelf: 'center' }}>
                                      <HeightIcon fontSize='large' style={{ transform: 'rotate(90deg)' }} />
                                    </Grid>
                                    <Grid item>
                                      <DTimePicker onChange={time => timeHandler('Endtime', time)} label='End' datetime={getDateTime(1)} />
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Fade>
                            );
                          case 1:
                            return (
                              <Fade in>
                                <Grid container spacing={2} justify="center" className={classes.root}>
                                  <Grid item xs={12} md={8}>
                                    <DestinationPicker selected={data[1].action[0].$.data} onChange={(val) => newDestination(val)} />
                                  </Grid>
                                </Grid>
                              </Fade>
                            );
                          default:
                            return 'Unknown stepIndex';
                        }
                      })()}
                  </div>
                </div>
              </div>
            </CardBody>
            <Divider />
            <CardFooter style={{ padding: '16px' }}>
              <Grid container spacing={5} justify="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<SaveIcon />}
                    onClick={handleClose}
                  >
                    Exit
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Grid>
              </Grid>
            </CardFooter>
          </Card>
        </Container>
      </Fade>
    </Modal>
  );
}

export default DayConditionModal;
