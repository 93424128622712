import React from 'react'
import { connect } from 'react-redux';
import { grey } from '@mui/material/colors';
import { Field, FieldArray, formValueSelector, change, reduxForm } from 'redux-form'

import { required } from 'components/Forms/Validators';

import { Card, CardBody, CardFooter } from 'components/Card';

import { MICROSOFT_VOICES, MICROSOFT_PITCH, MICROSOFT_RATE } from 'constants/index';

import CloseIcon from  '@mui/icons-material/Close';
import AddIcon from  '@mui/icons-material/Add';

import { TextField, MenuItem, Box, Grid, Tooltip, Divider } from '@mui/material';
import RegularButton from "components/CustomButtons/Button";
import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import FormTextField from '../FormTextField';

import { parse2ObjectAsync, parse2XMLSyncronous } from 'devtools/xml2js';

const FormSpeakComponent = ({ fields, meta: { error, submitFailed }, form, initialValues = null, getFormValues, ...props }) => {

  // console.log(getFormValues(form, [`text.speak.voice[0]`]));
  // console.log(fields);



  return initialValues &&
    <div>
      {
        fields.map((speak, index) => {
          // speak.voice[0]
          return (
            <Grid key={index} container display='flex' justifyContent="center" direction='column' spacing={2}>
              <Grid item xs={12}>
                <Card color='primary' style={{ margin: "0px 0px 12px 0px" }}>
                  <CardBody>
                    <Field
                      component={FormTextField}
                      name={`${speak}.prosody[0]._`}
                      themeColor='white'
                      label="Text To Speech Simple"
                      validate={[required]}
                      multiline
                      rows={8}
                      style={{ padding: 0, width: '100%' }}
                      fullWidth
                      variant="outlined"
                      placeholder="Add Message Here"
                      inputProps={{
                        spellCheck: false
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </CardBody>
                  <Divider />
                  <CardFooter>
                    <Box padding={2} width={1}>
                      <Grid container justifyContent="flex-start" spacing={2}>
                        <Grid item>
                          <Field
                            component={FormTextField}
                            name={`${speak}.prosody[0].$.pitch`}
                            validate={[required]}
                            themeColor='white'
                            fullWidth
                            select
                            size="small"
                            label="Pitch"
                            variant="outlined"
                            style={{ marginLeft: 'auto', width: 'fit-content', color: grey[50], borderColor: grey[50] }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            SelectProps={{
                              MenuProps: {
                                PaperProps:{
                                  style: {
                                    maxHeight: 60 * 4.5,
                                    width: '20ch',
                                  }
                                }
                              }
                            }}
                          >
                            {
                              MICROSOFT_PITCH.map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                            }
                          </Field>
                        </Grid>

                        <Grid item>
                          <Field
                            component={FormTextField}
                            name={`${speak}.prosody[0].$.rate`}
                            validate={[required]}
                            themeColor='white'
                            select
                            size="small"
                            label="Speed"
                            variant="outlined"
                            style={{ marginLeft: 'auto', width: 'fit-content', color: grey[50], borderColor: grey[50] }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            SelectProps={{
                              MenuProps: {
                                PaperProps:{
                                  style: {
                                    maxHeight: 60 * 4.5,
                                    width: '20ch',
                                  }
                                }
                              }
                            }}
                          >
                            {
                              MICROSOFT_RATE.map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                            }
                          </Field>
                        </Grid>

                        <Grid item style={{ width: '50%'}}>
                          <Field
                            component={FormTextField}
                            name={`${speak}.$.name`}
                            validate={[required]}
                            themeColor='white'
                            select
                            size="small"
                            label="Voice"
                            variant="outlined"
                            style={{ marginLeft: 'auto', width: 'fit-content', color: grey[50], borderColor: grey[50] }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ minWidth: 260 }}
                            SelectProps={{
                              MenuProps: {
                                PaperProps:{
                                  style: {
                                    maxHeight: 60 * 4.5,
                                    width: '20ch',
                                  }
                                }
                              }
                            }}
                          >
                            {
                              MICROSOFT_VOICES.map((option, index) => {
                                return (
                                  <MenuItem key={index} value={option.voice}>
                                    <>
                                      <Box component='span' mr={2}>{option.code.toUpperCase()}</Box>
                                      {option.voice}
                                    </>
                                  </MenuItem>
                                )
                              })
                            }
                          </Field>
                        </Grid>

                        {
                          index > 0 &&
                            <Grid item style={{ marginLeft: 'auto'}}>
                              <HelperTooltipBootstrap title="Remove Component" placement="left">
                                <RegularButton
                                  justIcon
                                  round
                                  color="github"
                                  onClick={() => fields.remove(index)}
                                >
                                  <CloseIcon />
                                </RegularButton>
                              </HelperTooltipBootstrap>
                            </Grid>
                        }
                      </Grid>
                    </Box>
                  </CardFooter>
                </Card>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <RegularButton
                  color='success'
                  round
                  onClick={() => {
                    const clone = JSON.parse(JSON.stringify(getFormValues(form, [`text.speak.voice[${index}]`])));
                    clone.prosody[0]._ = "";
                    fields.splice(index + 1, 0, clone)
                  }}
                >
                  <AddIcon /> Voice Component
                </RegularButton>
              </Grid>
            </Grid>
          )
        })
      }
      <div>
        { submitFailed && error && <span>{error}</span> }
      </div>
    </div>
}

const mapStateToProps = state => {
  return {
    getFormValues: (form, values) => {
      const selector = formValueSelector(form)
      return selector(state, ...values);
    }
  }
};

export default connect(
  mapStateToProps,
  null
)(FormSpeakComponent);
