import React, { useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import classNames from "classnames";
import { makeStyles, createStyles } from '@mui/styles';

import MusicNoteIcon from  '@mui/icons-material/MusicNote';
import AccountTreeIcon from  '@mui/icons-material/AccountTree';

import { ListSubheader, ListItemAvatar, Avatar, MenuItem, MenuList, ListItemText } from '@mui/material';

import { ringgroups_Actions } from 'store/ringgroups/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  secondaryAction: {
    paddingRight: '80px'
  },
  primary: {
    fontSize: '12px'
  },
  normal: {
    display: 'flex',
    width: theme.spacing(4),
    height: theme.spacing(4),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  }
}));

const RinggroupsMenuItems = ({ ringgroupsLoading, ringgroups, getRinggroups, ...props}) => {
  const classes = useStyles();

  useEffect(() => {
    if (ringgroupsLoading === null) {
      getRinggroups();
    }
  }, [ringgroups, ringgroupsLoading]);

  const CustomMenuItem = React.useMemo(
    () =>
      ({ prefix, option, handleMenuItemClick, selected, ...props}) => {
        //const optionValue = prefix ? prefix +' '+ option.dialplan_destination : option.dialplan_destination;
        const optionValue = `transfer ${option.dialplan_destination}`;

        return (
          <MenuItem
            button
            key={option.ring_group_uuid}
            selected={optionValue === selected}
            onClick={(event) => handleMenuItemClick(event, optionValue)}
          >
            <ListItemAvatar>
              <Avatar classes={{ root: classes.normal }}>
                <AccountTreeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={<span>{option.ring_group_name}</span>}
              secondary={<small>{option.ring_group_extension}</small>}
            />
          </MenuItem>
        )
      },
    [],
  );

  return (
    <>
      <ListSubheader component="div" id="nested-list-subheader" style={{ backgroundColor: 'white'}}>Ring Groups</ListSubheader>
      {
        ringgroups && ringgroups.map((option, index) => <CustomMenuItem key={index} option={option} {...props}/>)
      }
    </>
  )
}

const mapStateToProps = state => ({
  ringgroups: state.ringgroups_State.ringgroups || [],
  ringgroupsLoading: state.ringgroups_State.loading
});

const mapDispatchToProps = dispatch => ({
  getRinggroups: () => { dispatch(ringgroups_Actions()) }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RinggroupsMenuItems);
