import React, { cloneElement } from 'react';

const withModal = (Component, modals = []) => (props) => {
  return (
    <div>
      <Component {...props} />
      {
        modals.map((component, index) => {
          return cloneElement(component, { key:  index });
        })
      }
    </div>
  )
};

export default withModal;
