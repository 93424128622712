import React from 'react';
import { Badge, Box, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';

import vaihdeLogoXS from "assets/img/Icon-Small.png";

const withBadgeDialer = (WrappedComponent) => {
  class HOC extends React.Component {
    render() {
      return (
        <Tooltip title="VaihdeApp" placement="bottom">
          <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={
              <Box fontSize={12} borderRadius="50%" overflow="hidden"><img alt='' style={{ height: '20px' }} src={vaihdeLogoXS} /></Box>
            }
          >
            <WrappedComponent
              {...this.props}
            />
          </Badge>
        </Tooltip>
      );
    }
  }

  return HOC;
}

export default withBadgeDialer;
