import JsSIP from 'jssip';

class SipAccount {
	constructor({user_context, extension, password}, wss_port) {
		this.socket = new JsSIP.WebSocketInterface(`wss:/${user_context}:${wss_port}`);
		this.socket.via_transport = "wss";

		this.configuration = {
			display_name        : `${extension}`,
			uri                 : `sip:${extension}@${user_context}`,
			password            : `${password}`,
			sockets             : [ this.socket ],
			registrar_server    : `${user_context}`,

			contact_uri         : null,
			authorization_user  : null,
			instance_id         : null,
			session_timers      : false,
			use_preloaded_route : false,
			// pcConfig            : {
		  //   rtcpMuxPolicy : 'negotiate',
		  //   iceServers    :
		  //   [
		  //     {
		  //       urls : [
		  //         'stun:stun.gbc.fi:3478',
		  //         'stun:stun.l.google.com:19302',
		  //       ]
		  //     }
		  //   ]
		  // },
		}
	}
}

export default SipAccount
