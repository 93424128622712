import React from 'react';
import cx from "classnames";
import { makeStyles, withStyles, createStyles } from '@mui/styles';
import withBadge from './withBadge.js';
import withBadgeDialer from './withBadgeDialer.js';
import Avatar from '@mui/material/Avatar';

import CustomAvatarWithBadge from './CustomAvatarWithBadge';
import UsersAvatarGroupUser from './UsersAvatarGroupUser';
import CustomAvatarWithClient from './CustomAvatarWithClient';
import NewCustomAvatar from './NewCustomAvatar';
import AvatarCustom from './AvatarCustom';
import CustomAvatarz from './CustomAvatarz';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  profile: {
    height: '120px',
    width: '120px'
  }
}));

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);


const AvatarLight = withStyles((theme) => ({
  root: {
    backgroundColor: '#abaaaa'
  },
}))(Avatar);

const AvatarDark = withStyles((theme) => ({
  root: {
    backgroundColor: '#585858'
  },
}))(Avatar);


const CustomAvatar = (props) => {
  const classes = useStyles();
  let { defaultColor, size, user, ...rest } = props;

  if(size) {
    size = cx(
      {[classes.large]: size === 'large' },
      {[classes.small]: size === 'small' },
      {[classes.profile]: size === 'profile' }
    );
  }

  const style = {
    backgroundColor: user?.avatarColor || '#bdbdbd',
    color: '#fff'
  };


  return (
    <Avatar style={style} className={size} src={user?.image || ''} {...rest}>{user?.altName}</Avatar>
  );
}

//const CustomAvatarWithBadge = withBadge(CustomAvatar);
const AvatarWithDialerBadge = withBadgeDialer(CustomAvatar);
export {
  CustomAvatar as default,
  CustomAvatarz,
  AvatarCustom,
  UsersAvatarGroupUser,
  NewCustomAvatar,
  CustomAvatarWithBadge,
  AvatarWithDialerBadge,
  SmallAvatar,
  CustomAvatarWithClient,
  AvatarLight,
  AvatarDark
};
