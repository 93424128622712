import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

function useFunctions() {
  const navigate = useNavigate();

  const openContact = useCallback(
    (contact = {}) => (event) => {
      //goto contact page
      const { id = 'new', ...rest } = contact;
      navigate(`${window.location.pathname}/${id}`, { state: rest ?? {} });
    },
    [],
  );

  return {
    openContact
  }
}


export default useFunctions;
