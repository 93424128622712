import React, { useRef, forwardRef, useState, useEffect, useContext, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Logger from 'WEBRTC/Logger';

import { v4 as uuidv4 } from 'uuid';

import { styled } from '@mui/material/styles';

import { invite } from 'WEBRTC/phoneManager';
import { outbound_calllog_action, outbound_calllog_get_item, OutboundCalllogItems } from 'WEBRTC/outbound_calllogManager';

import moment from 'Helpers/Momentjs';

//import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import useAutocomplete from '@mui/material/useAutocomplete';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
// import CallingListContext from 'Contexts/CallingListContext';
// import useSessionStorage from '../GlobalStates/useSessionStorage';

import { RenderGroup } from 'components/CustomAutoComplete';

import {
  Box,
  Paper,
  Divider,
  IconButton,
  InputBase,
  TextField,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  Popper,
  Menu,
  MenuItem,
  Avatar
} from '@mui/material';

import {
  StyledPopper,
  //ListboxComponent
} from '../ListBoxComponent';

import { useForm, Controller } from "react-hook-form";

import { Card } from 'components/Card';
import RegularButton from 'components/CustomButtons';
import { TransparentTextField } from 'components/CustomInput';

import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CallIcon from '@mui/icons-material/Call';
import CloseIcon from '@mui/icons-material/Close';

import DialingComponent from './DialingComponent';

import { usePhone } from 'Providers';

const logger = new Logger('Dialer');

const DEFAULTVALUES = {
  target: null,
}

const TransferForm = (props) => {
  let mounted = true;
  const { transfer } = usePhone();

  const id = uuidv4();

  useEffect(() => {
    return () => mounted = false;
  }, [])

  const { control, handleSubmit, getValues, reset, watch, setValue, formState, ...formProps } = useForm({
    defaultValues: {
      ...DEFAULTVALUES,
    }
  });

  useEffect(() => {
    // reset form after its submited!
    if(mounted && formState.isSubmitSuccessful) {
      reset(DEFAULTVALUES);
    }
  }, [formState, reset])

  const onSubmit = (values, event) => {
    if(mounted) {
      try {
        transfer(values.target)(event);
      } catch(e) {
        console.log("err", e)
      }
    }
  };

  const handleRedialClick = (event) => {
    const target = getValues("target");

    if(target) {
      handleSubmit(onSubmit)(event); // try calling by form submit
    } else {
      if(JSON.stringify(control._defaultValues) !== JSON.stringify(DEFAULTVALUES)) reset(); // form reset
    }
  }

  return (
    <form name={`transfer-form-${id}`} style={{ width: "100%" }} onSubmit={(event) => event.preventDefault()}>
      <Paper id={`transfer-bar-component-${id}`} sx={{ p: '2px 4px' }} elevation={8}>
        <Controller
          name="target"
          control={control}
          render={({ field }) => <DialingComponent onSubmit={handleSubmit(onSubmit)} placeholder="Enter Target For Blind Transfer" watch={watch} setValue={setValue} formState={formState} {...field} />}
        />
      </Paper>
    </form>
  );
}


export default TransferForm;
