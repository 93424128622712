import React, { useEffect, useCallback, useState } from "react";

import {
  Box,
  Stack,
  Button,
  IconButton,
  Divider,
  Typography,
  Grid,
} from '@mui/material';

import { useForm, Controller, FormProvider, useFormContext } from "react-hook-form";
import { disableFormSubmitByEnterKey, sleep } from 'Helpers/Forms';
import { LoadingButton } from 'components/HookForm/Buttons';

import { HelperTooltipBootstrap } from 'components/CustomTooltips';

import { ProfileAvatar } from 'components/CustomAvatars';

import ExtensionIcon from '@mui/icons-material/Extension';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import StarIcon from '@mui/icons-material/Star';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountTreeIcon from '@mui/icons-material/AccountTree';


import {
  RinggroupOptions,
  AccountOptionsList
} from './Components';

import { service_account } from 'Services/service_account';

import localstorage from 'localStorage';

const INITIALVALUES = () => localstorage.get('friends_options');

const FormActionButtons = ({ onSubmit, ...props }) => {
  const methods = useFormContext(); // retrieve all hook methods
  const { watch, formState, handleSubmit } = methods;
  watch(); // watch for form changes

  return (
    <Box display='flex' flexGrow={1}>
      <LoadingButton type="submit" onSubmit={handleSubmit(onSubmit)} {...methods} {...props}>
        Apply
      </LoadingButton>
      <LoadingButton undo {...methods} {...props}>
        Undo
      </LoadingButton>
    </Box>
  )
}

const ProfileOptionsDrawer = ({ handleClose, data, isLoading, ...props }) => {
  let mounted = true;

  useEffect(() => {
    return () => mounted = false;
  }, []);

  const methods = useForm({
    defaultValues: {...INITIALVALUES()}
  });

  const { handleSubmit, control, watch, reset, setValue, formState: { errors, isDirty, isSubmitting }} = methods;

  // SUBMIT
  const onSubmit = useCallback(
    // async (data) => {
    //   try {
    //     console.log('onSubmit', data);
    //     localstorage.set("friends_options", data);
    //     reset(data);
    //   } catch (err) {
    //     console.warn("onSubmit",  err);
    //   } finally {
    //     await sleep(250); // little delay for loader!
    //   }
    // },
    // [],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormSubmitByEnterKey}>
        <Grid container justifyContent='center' alignItems="center" spacing={0}>
          <Grid container item justifyContent='flex-end' xs={12}>
            <Grid item>
              <Stack direction="row" justifyContent='space-around' px={2} py={1} spacing={5}>
                {
                  //<FormActionButtons onSubmit={onSubmit} handleClose={handleClose} />
                }
                <IconButton onClick={(event) => setTimeout(handleClose(event))} aria-label="delete" size="large">
                  <CloseRoundedIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>


          <Grid container item justifyContent='center' xs={12}>
            <Grid item>
              <ProfileAvatar height={100} width={100} />
            </Grid>
          </Grid>

          <Grid container item xs={12} justifyContent='center'>
            <Typography variant="h6" component="h2" sx={{ width: "100%", textAlign: 'center' }} >{data?.userdata?.username}</Typography>
            <Typography variant="caption" component="h2" sx={{ width: "100%", textAlign: 'center' }} >{data?.userdata?.email}</Typography>
          </Grid>


          <Grid container item xs={12} justifyContent='center' pt={3}>
            <AccountOptionsList handleClose={handleClose} />
          </Grid>

          {
            // <Grid container item xs={12} justifyContent='center' pt={3}>
            //   <RinggroupOptions />
            // </Grid>
          }

        </Grid>





      </form>
    </FormProvider>
  );
}


const logicalComponent = (Component) => (props) => {
  let mounted = true, subscription;
  const [data, setData] = useState(null);
  const { add_subscriber, remove_subscriber } = service_account();

  useEffect(() => {
    if(mounted) {
      subscription = add_subscriber(
        value => {
          if(mounted && value) {
            setData(value);
          }
        },
        err => console.log("TelphoneNumberDialog", err)
      )
    }

    return () => mounted = false;
  }, []);

  return <Component {...props} data={data ?? {}} isLoading={!Array.isArray(data)} />
}


export default logicalComponent(ProfileOptionsDrawer);
