export const sleep = (ms = 1000) => new Promise(resolve => setTimeout(resolve, ms))

export const isPromise = v => typeof v === 'object' && typeof v.then === 'function';

export function mergeRecursive(obj1, obj2) {
  for (var p in obj2) {
    try {
      // Property in destination object set; update its value.
      if ( obj2[p].constructor==Object ) {
        obj1[p] = mergeRecursive(obj1[p], obj2[p]);

      } else {
        obj1[p] = obj2[p];

      }

    } catch(e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p];

    }
  }
  return obj1;
};


export function removeProperties(data = [], props = []) {
  const deleteItems = function(item) {
    for(let prop of props) {
      delete item[prop];
    }
    return item;
  }

  if(Array.isArray(data)) {
    //array
    return data.map(item => {
    	return deleteItems(item);
    })
  } else {
    //object
    let object = {};

    for(const [key, values] of Object.entries(data)) {
      object[key] = deleteItems(values);
    }
    return object;
  }
}

export function reduceObject(obj = {}, props = []) {
  let object = {};

  for(const prop of props) {
    object[prop] = obj[prop];
  }

  return object;
}


export function randomArrayItem(items) {
  return items[Math.floor(Math.random()*items.length)]
}

export function getFunctionName() {
  return getFunctionName.caller.name;
};


export function copyToClipboard(value) {
  if(navigator?.clipboard) navigator.clipboard.writeText(value);
  // should do snackbar `Clipboard: '' `
}
